import * as React from "react";
import { Grid, GridCellProps, GridColumn as Column, GridFilterChangeEvent, GridToolbar, GridPageChangeEvent } from "@progress/kendo-react-grid";
import { DragAndDrop } from "@progress/kendo-react-common";

import { DraggableRow } from "./DraggableRow";
import { SelectionCell } from "./SelectionCell";
import { DragHint } from "./DragHint";

import GBKendoToolbar from "../../Shared/Components/Table/GBKendoToolbar";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { PointReadTableDto } from "../../../Models/Point/PointReadTableDto";
import { CompositeFilterDescriptor, filterBy, orderBy } from "@progress/kendo-data-query";
import useKendoTableState from "../../Shared/Hooks/useKendoTableState";
import { useEffect, useMemo } from "react";
import { CustomTextFilterCell } from "../../Shared/Components/Table/CustomTextFilterCell";
import { DragHandleCell } from "./DragHandleCell";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";

interface Props {
  points: Array<PointReadTableDto>;
  updatePoints: (points: Array<PointReadTableDto>) => void;
  height: number;
}

const initialFilter: CompositeFilterDescriptor = {
  logic: "and",
  filters: [],
};

export const ReorderContext = React.createContext<{
  reorder: (dataItem: PointReadTableDto, direction: "before" | "after" | null) => void;
  dragStart: (dataItem: PointReadTableDto) => void;
  dragEnd: (dataItem?: PointReadTableDto) => void;
}>({ reorder: () => {}, dragStart: () => {}, dragEnd: () => {} });
export const SelectionContext = React.createContext<[PointReadTableDto[], any]>([[], () => {},]);
export const IsSelectedContext = React.createContext<boolean>(false);
export const DragHintContext = React.createContext<React.RefObject<HTMLElement> | null>(null);
export const GridContext = React.createContext<React.RefObject<Grid> | null>(null);
export var IsTableEditable = React.createContext<boolean>(false);

function RouteTable(props: Props) {

  const localizationService = useLocalization();

  const grid = React.useRef(null);
  const hint = React.useRef(null);
  const [selection, setSelection] = React.useState<PointReadTableDto[]>([]);
  const [activeItem, setActiveItem] = React.useState<PointReadTableDto | null>(null);

  const _export = React.useRef<ExcelExport | null>(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  const updateAction = () => {
    props.updatePoints(tableState.assets);
  
  }

  const discardChanges = () => {
    // Reset the route order back to its initial state
    for(var a = 0; a < tableState.assets.length; a++)
    {
      tableState.assets[a].routeOrder = tableState.assets[a].initialRouteOrder;
    }
    tableState.resetState();
  }

  const [filter, setFilter] = React.useState(initialFilter);

  const tableState = useKendoTableState<PointReadTableDto>({
    key: "RouteEditor",
    initialAssets: props.points,
  });

  const reorder = (dataItem: any, direction: "before" | "after" | null) => {
    
    if (activeItem === dataItem) {
      setSelection([]);
      return;
    }
    let reorderedData = tableState.assets.slice();
    reorderedData = reorderedData.filter(
      (item) =>
        !selection.some(
          (selectedItem) => selectedItem.id === item.id
        )
    );
    let nextIndex = reorderedData.findIndex((p) => p.id === dataItem.id);

    reorderedData.splice(Math.max(nextIndex + (direction === "before" ? 0 : 1), 0), 0, ...selection);

    let ids : string[] = [];
    for(var i = 0; i < reorderedData.length; i++) {
      const index = tableState.assets.findIndex(x => x.id === reorderedData[i].id);
      tableState.assets[index].routeOrder = i + 1;
      ids.push(reorderedData[i].id);
    }
    
    tableState.setModifiedRowIds(ids);
    setSelection([]);
  };

  const dragStart = (dataItem: any) => {
    setActiveItem(dataItem);
  };

  const dragEnd = () => {
    setActiveItem(null);
  };

  const processedData = useMemo(() => {

    let initialRows = tableState.assets;

    //If filterable, then filter
    if (filter) {
      initialRows = filterBy(initialRows, filter);
    }

    initialRows = orderBy(initialRows, [{field: "routeOrder", dir: "asc"}]); 

    return initialRows;
  }, [tableState.assets, filter, reorder]);

  useEffect(() => {
   
    if (!tableState.canModifyRow)
    {
      // Clear all selected rows
      setSelection([]);
    }

  }, [tableState.canModifyRow]);

  const CustomSelectionCell = (props: GridCellProps) => (
    <SelectionCell {...props} isTableEditable={tableState.canModifyRow} />
  );

  const CustomDragHandleCell = (props: GridCellProps) => (
    <DragHandleCell {...props} isTableEditable={tableState.canModifyRow} />
  );

  return (
    <GridContext.Provider value={grid}>
      <DragHintContext.Provider value={hint}>
        <ReorderContext.Provider value={{ reorder, dragStart, dragEnd }}>
          <SelectionContext.Provider value={[selection, setSelection]}>
            <ExcelExport data={processedData} ref={_export} fileName={localizationService.toLanguageString('custom.routeEditor', enMessages.custom.routeEditor)}>
            <ExcelExportColumn field="routeOrder" title="Route Order" />
            <ExcelExportColumn field="name" title={localizationService.toLanguageString('custom.tagName', enMessages.custom.tagName)} />
            <ExcelExportColumn field="machineName" title={localizationService.toLanguageString('custom.asset', enMessages.custom.asset)} />
            <ExcelExportColumn field="areaName" title={localizationService.toLanguageString('custom.location', enMessages.custom.location)} />
            <ExcelExportColumn field="consumableName" title={localizationService.toLanguageString('custom.grease', enMessages.custom.grease)} />
            </ExcelExport>
            <DragAndDrop>
              <Grid
                style={{
                  height: props.height
                }}
                ref={grid}
                data={processedData}
                filterable={true}
                rowHeight={46}
                pageSize={processedData.length}
                dataItemKey={"id"}
                filter={filter}
                total={processedData.length}
                onFilterChange={(e: GridFilterChangeEvent) => setFilter(e.filter)}
                rowRender={(row, rowProps) => (
                  <DraggableRow elementProps={row.props} {...rowProps} />
                )}
                scrollable={"scrollable"}
              >
                <GridToolbar>  
                  <GBKendoToolbar
                    name={localizationService.toLanguageString('custom.routeEditor', enMessages.custom.routeEditor)}
                    tableState={tableState}
                    exportAction={excelExport}
                    importAction={null}
                    addAction={null}
                    updateAction={updateAction}
                    deleteAction={null}
                    discardChanges={discardChanges}
                  />
                </GridToolbar>
            
                <Column field="" width="50px" cell={CustomSelectionCell} title="" filterable={false} />
                <Column field="" width="50px" cell={CustomDragHandleCell} title="" filterable={false} />
                <Column field="routeOrder" width="80px" title="#" filterable={false} />
                <Column field="name" title={localizationService.toLanguageString('custom.tagName', enMessages.custom.tagName)} filter="text" filterCell={CustomTextFilterCell} />
                <Column field="machineName" title={localizationService.toLanguageString('custom.asset', enMessages.custom.asset)} filter="text" filterCell={CustomTextFilterCell} />
                <Column field="areaName" title={localizationService.toLanguageString('custom.location', enMessages.custom.location)} filter="text" filterCell={CustomTextFilterCell} />
                <Column field="consumableName" title={localizationService.toLanguageString('custom.grease', enMessages.custom.grease)} filter="text" filterCell={CustomTextFilterCell}/>
              </Grid>
              <DragHint
                ref={hint}
                portal={grid}
                className="k-card"
                style={{ display: activeItem ? undefined : "none" }}
              >
                {activeItem && activeItem.name}
                {selection.length > 1 && (
                  <div
                    style={{
                      position: "absolute",
                      pointerEvents: "none",
                      bottom: 0,
                      right: 0,
                      background: "red",
                      padding: 8,
                      width: 36,
                      color: "white",
                      borderRadius: "50%",
                      transform: "translate(50%, 50%)",
                    }}
                  >
                    +{selection.length - 1}
                  </div>
                )}
              </DragHint>
            </DragAndDrop>
            
          </SelectionContext.Provider>
        </ReorderContext.Provider>
      </DragHintContext.Provider>
    </GridContext.Provider>
  );
}

export default RouteTable;