import { useToast } from "@chakra-ui/react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../../messages/en-US";
import {TextArea } from "@progress/kendo-react-inputs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import AreaReadDto from "../../../../Models/Area/AreaReadDto";
import EndpointReadDto from "../../../../Models/Endpoint/EndpointReadDto";
import { MachineReadDto } from "../../../../Models/Machine/MachinesReadDto";
import { PointReadDto } from "../../../../Models/Point/PointReadDto";

interface Props {
  onClose: () => void;
  notes: AreaReadDto | EndpointReadDto | MachineReadDto | PointReadDto |null
  updateNotes: (item: string) => void
  asset?: string 
}

export default function NotesDialog(props: Props) {
  const localizationService = useLocalization()

  const handleSubmit = (dataItem) => {
    props.updateNotes(dataItem.notes)
    props.onClose()
  };

  const Title = () => {
    let title
    if(props.notes?.name != '') {
      if(props.asset == undefined) {
        title = ` - ${props.notes?.name}`
     } else {
        title = ` - ${props.notes?.name} (${props.asset})`
     }
     return `${localizationService.toLanguageString('custom.notes', enMessages.custom.notes)}` + title
    } else {
      return `${localizationService.toLanguageString('custom.notes', enMessages.custom.notes)}`
    }
  }

  return (
    <div>
      <Dialog title={Title()} onClose={props.onClose} className="notes-dialog" >
        <Form
          onSubmit={handleSubmit}
          initialValues={{notes: props.notes?.description}}
          render={(formRenderProps) => (
          <FormElement style={{width: 500,}}>
            <fieldset className={"k-form-fieldset"} style={{padding:'10px'}}>
              <legend>
                {`${localizationService.toLanguageString('custom.addNote', enMessages.custom.addNote)}`}
              </legend>
              <div className="mb-3">
              <Field
                  name={"notes"}
                  type={"text"}
                  component={TextArea}
                  label={"Notes"}
                  rows={10}
                />
              </div>
            </fieldset>
            <DialogActionsBar>
              <Button
                type={"submit"}
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                style={{background:'#35AC00', color:'white'}}
                disabled={!formRenderProps.allowSubmit}
              >
                {localizationService.toLanguageString('custom.save', enMessages.custom.save)}
              </Button>
              <Button onClick={props.onClose}>{localizationService.toLanguageString('custom.cancel', enMessages.custom.cancel)}</Button>
            </DialogActionsBar>
          </FormElement>
          
          )}
        />
        
      </Dialog>
    </div>
  )
}
