import { ReactElement, useEffect, useMemo, useState } from "react";
import sitesApiService from "../Utilities/AdministrationAPIService";
import { ReactQueryKeys } from "../../../Constants/ClientRoutingConstants";
import { useQuery, useQueryClient } from "react-query";
import ErrorCard from "../../Shared/Components/Cards/ErrorCard";
import LoadingSpinner from "../../Shared/Components/LoadingSpinner";
import { SiteReadDto } from "../../../Models/Site/SiteReadDto";
import { useToast } from "@chakra-ui/react";
import { useLocalization } from '@progress/kendo-react-intl';
import {enMessages} from '../../../messages/en-US';
import endpointsApiService from "../../Endpoints/Utilities/EndpointsApiService";
import AdministrationEndpointTable from "../Components/Tables/AdministrationEndpointTable";
import AdminEndpointReadDto from "../../../Models/Endpoint/AdminEndpointReadDto";
import AdminEndpointUpdateDto from "../../../Models/Endpoint/AdminEndpointUpdateDto";
import AdminEndpointReadTableDto from "../../../Models/Endpoint/AdminEndpointReadTableDto";
import { useWindowSize } from "usehooks-ts";

interface Props {}

/**
 * This component facilitates the device registration flow for super admin users - here they can view all devices registered in the system, assign them
 * to a site, and eventually manage firmware updates etc.
 */
export default function SuperAdministrationEndpointsPage({}: Props): ReactElement {
  const toast = useToast({
    position: "bottom",
    duration: 5000,
    isClosable: true,
  });
  const localizationService = useLocalization();
  const client = useQueryClient();
  //get window size
  const { width, height } = useWindowSize()

  const endpointsQuery = useQuery<Array<AdminEndpointReadDto>>(ReactQueryKeys.ManageEndpointsQuery, async () => {
    return endpointsApiService.getAllEndpointsManage();
  });

  const sitesQuery = useQuery<Array<SiteReadDto>>(ReactQueryKeys.AllSitesQuery, async () => {
    return sitesApiService.getAllSites();
  });

  const createEndpoint = async (values: any, setStatus: any) => {
    // Attempt to create the machine
    try {
      // const castValues = deviceValidationSchema.cast(values) as DeviceCreateDto;
      //Submit values to api
      const result = await endpointsApiService.createEndpointDevice(values);
      await endpointsQuery.refetch();

      //On success, show success toast, then refresh data
      toast({
        status: "success",
        description: `${localizationService.toLanguageString('custom.endpointCreated', enMessages.custom.endpointCreated)}`
      });
      return true;
    } catch (e: any) {
      //On failure show error toast, set status messages
      setStatus(e?.response?.data?.errors);

      toast({
        title: `${localizationService.toLanguageString('custom.error', enMessages.custom.error)}`,
        status: "error",
        description: `${localizationService.toLanguageString('custom.endpointCreatedError', enMessages.custom.endpointCreatedError)}`,
      });
      return false;
    }
  };

  const deleteEndpoints = async (deleteIds: Array<string>) => {
    try {
      const result = await endpointsApiService.deleteEndpointDevices(deleteIds);
      endpointsQuery.refetch();
      toast({
        title: `${localizationService.toLanguageString('custom.success', enMessages.custom.success)}`,
        status: "success",
        description:`${localizationService.toLanguageString('custom.endpointDeleted', enMessages.custom.endpointDeleted)}`,
      });
    } catch (e: any) {
      toast({
        title: `${localizationService.toLanguageString('custom.error', enMessages.custom.error)}`,
        status: "error",
        description: `${localizationService.toLanguageString('custom.endpointDeletedError', enMessages.custom.endpointDeletedError)}`,
      });
    }
  };

  const updateEndpoints = async (endpoints: Array<AdminEndpointUpdateDto>) => {
    try {
      let response;
      if (endpoints.length === 1) {
        response = await endpointsApiService.updateEndpointSuper(endpoints[0], endpoints[0].id);
      } else if (endpoints.length > 1) {
        response = await endpointsApiService.updateEndpointsSuper(endpoints);
      }
      //If update is successful, refetch the updated list of areas and show success message
      endpointsQuery.refetch();
      toast({
        status: "success",
        description: `${localizationService.toLanguageString('custom.endpointUpdated', enMessages.custom.endpointUpdated)}`,
      });
      return true;
    } catch (e: any) {
      var errorMessage;
      if (endpoints.length === 1) {
        errorMessage = `${localizationService.toLanguageString('custom.endpointUpdatedError', enMessages.custom.endpointUpdatedError)}\n
        ${e?.response?.data?.errors ? Object.values(e?.response?.data?.errors)[0] : ""}`;
      } else {
        errorMessage = `${localizationService.toLanguageString('custom.endpointIncorrectInput', enMessages.custom.endpointIncorrectInput)}\n
          ${e?.response?.data?.map((consumableId: string) => endpoints.find((a) => a.id === consumableId)?.id)}
        `;
      }
      toast({
        status: "error",
        description: errorMessage,
      });
      return false;
    }
  };

  const endpointData = useMemo(() => {
    //Create hashmap of areaId -> name pairs.
    const siteIdToName: { [id: string]: string } = {};
    sitesQuery.data?.forEach((site) => {
      siteIdToName[site.id] = site.name;
    });

    //Map over machines to inject relevant data
    return endpointsQuery.data?.map((endpoint) => {
      return { ...endpoint, siteName: siteIdToName[endpoint.siteId] };
    }) as Array<AdminEndpointReadTableDto>;
  }, [sitesQuery.data, endpointsQuery.data]);

   //Set up state for the upload dialog
   const [visibleUpload, setVisibleUpload] = useState(false);
   const onDialogClose = (isSuccess) => {
    if(isSuccess) {
      client.refetchQueries(ReactQueryKeys.ManageEndpointsQuery);
    }
    setVisibleUpload(false);
   }

  if (endpointsQuery.isLoading || sitesQuery.isLoading || endpointsQuery.isFetching || sitesQuery.isFetching) {
    return <LoadingSpinner />;
  }
  if (endpointsQuery.error || sitesQuery.error) {
    return <ErrorCard />;
  } else {
    return (
      <div className="m-5">
        <h1 className="gb-h1">{localizationService.toLanguageString('custom.manageEndpoints', enMessages.custom.manageEndpoints)}</h1>
        <h2 className="gb-text-normal mb-6">
        {localizationService.toLanguageString('custom.manageendpointsText', enMessages.custom.manageendpointsText)}
        </h2>

        <AdministrationEndpointTable
          createEndpoint={createEndpoint}
          deleteEndpoints={deleteEndpoints}
          updateEndpoints={updateEndpoints}
          endpoints={endpointData!}
          sites={sitesQuery.data!}
          height={height - 200}  
          visibleUpload={visibleUpload}
          setVisibleUpload={setVisibleUpload}
          onDialogClose={onDialogClose}
          />

      </div>
    );
  }
}
