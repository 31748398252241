import { DeviceCreateDto } from "./../../../Models/Device/DeviceCreateDto";
import { DeviceReadDto } from "./../../../Models/Device/DeviceReadDto";
import { ApiRoutes } from "../../../Constants/ApiRoutingConstants";
import axios, { AxiosInstance } from "axios";
import authorizeService from "../../Identity/Utilities/AuthorizeService";
import AdminDeviceReadDto from "../../../Models/Device/AdminDeviceReadDto";
import AdminDeviceCreateDto from "../../../Models/Device/AdminDeviceCreateDto";
import AdminDeviceUpdateDto from "../../../Models/Device/AdminDeviceUpdateDto";

/**
 * This service provides implementations for http calls to the api pertaining to device crud functionality
 */

class DevicesApiService {
  axiosInstance: AxiosInstance;
  constructor() {
    //Subscribe to identity store the get token
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_DEVICE_MANAGEMENT_SERVICE_URL,
    });
  }

  async getAllDevices(): Promise<DeviceReadDto[]> {
    let response = await this.axiosInstance({
      method: "GET",
      url: `${ApiRoutes.Devices}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data.map((x: any) => {
      return {
        ...x,
        areaId: x.areaIds[0] ?? null,
      };
    });
  }

  async getAllDevicesManage(): Promise<AdminDeviceReadDto[]> {
    let response = await this.axiosInstance({
      method: "GET",
      url: `${ApiRoutes.ManageDevices}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    //temp fix for area ids array
    return response.data.map((x: any) => {
      return {
        ...x,
        areaId: x.areaIds[0] ?? null,
      };
    });
  }

  async createDevice(device: AdminDeviceCreateDto): Promise<{ guid: string; apiKey: string }> {
    let response = await this.axiosInstance({
      method: "POST",
      url: `${ApiRoutes.ManageDevices}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: device,
    });
    return response.data;
  }

  async updateDevice(device: DeviceCreateDto, deviceID: string) {
    let response = await this.axiosInstance({
      method: "PUT",
      url: `${ApiRoutes.Devices}/${deviceID}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: {
        ...device,
        areaIds: device.areaId == null ? [] : [device.areaId],
      },
    });
    return response.data;
  }

  async updateDevices(devices: Array<DeviceCreateDto>) {
    let devicesToSend = devices.map((device) => {
      return {
        ...device,
        areaIds: device.areaId == null ? [] : [device.areaId],
      };
    });

    let response = await this.axiosInstance({
      method: "PUT",
      url: `${ApiRoutes.Devices}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: {
        devices: devicesToSend,
      },
    });
    return response.data;
  }

  async updateDeviceSuper(device: AdminDeviceCreateDto, deviceID: string) {
    let response = await this.axiosInstance({
      method: "PUT",
      url: `${ApiRoutes.ManageDevices}/${deviceID}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: device,
    });
    return response.data;
  }

  async updateDevicesSuper(devices: Array<AdminDeviceUpdateDto>) {
    let response = await this.axiosInstance({
      method: "PUT",
      url: `${ApiRoutes.ManageDevices}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: { devices },
    });
    return response.data;
  }

  async deleteDevices(deleteDeviceIds: Array<string>) {
    let response = await this.axiosInstance({
      method: "DELETE",
      url: `${ApiRoutes.Devices}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: {
        ids: deleteDeviceIds,
      },
    });
    return response.data;
  }

  static get instance() {
    return devicesApiService;
  }
}

const devicesApiService = new DevicesApiService();
export default devicesApiService;
