import { RRule } from "rrule";

export const FrequencyTypes = {
  daily: "RRule.DAILY",
  weekly: "RRule.WEEKLY",
  monthly: "RRule.MONTHLY",
};

export const ByWeekday = [
  RRule.SU,
  RRule.MO,
  RRule.TU,
  RRule.WE,
  RRule.TH,
  RRule.FR, 
  RRule.SA,
];

export const ByMonthDay1 = [
  RRule.SU.nth(+1),
  RRule.MO.nth(+1),
  RRule.TU.nth(+1),
  RRule.WE.nth(+1),
  RRule.TH.nth(+1),
  RRule.FR.nth(+1), 
  RRule.SA.nth(+1),
];

export const ByMonthDay2 = [
  RRule.SU.nth(+2),
  RRule.MO.nth(+2),
  RRule.TU.nth(+2),
  RRule.WE.nth(+2),
  RRule.TH.nth(+2),
  RRule.FR.nth(+2), 
  RRule.SA.nth(+2),
];

export const ByMonthDay3 = [
  RRule.SU.nth(+3),
  RRule.MO.nth(+3),
  RRule.TU.nth(+3),
  RRule.WE.nth(+3),
  RRule.TH.nth(+3),
  RRule.FR.nth(+3), 
  RRule.SA.nth(+3),
];

export const ByMonthDay4 = [
  RRule.SU.nth(+4),
  RRule.MO.nth(+4),
  RRule.TU.nth(+4),
  RRule.WE.nth(+4),
  RRule.TH.nth(+4),
  RRule.FR.nth(+4), 
  RRule.SA.nth(+4),
];


