import { useLocalization } from "@progress/kendo-react-intl";
import { enMessages } from "../../../messages/en-US";
import { Button } from "@progress/kendo-react-buttons";
import { Form, Field, FormElement} from "@progress/kendo-react-form";
import { FormDropDownList, FormInput, FormMultiEmail, FormSwitch, FormTextArea} from "../../Shared/Components/Forms/FormsComponents";
import { isEmail } from "react-multi-email";
import { useState } from "react";
import { DropDownListChangeEvent} from "@progress/kendo-react-dropdowns";
import { AlertUpdateDto } from "../../../Models/Alerts/AlertUpdateDto";
import { AlertCreateDto } from "../../../Models/Alerts/AlertCreateDto";
import { useToast } from "@chakra-ui/react";
import { requiredValidator } from "../Utilities/validators";
import { dataField, metricOperators, valueRenderCriticality } from "../Utilities/AddEditFormUtils";
import { ReservoirConditionComponent } from "./ReservoirCondition";
import { RequirementConditionComponent } from "./RequirementCondition";

interface Props {
  alert: any;
  onClose: () => void;
  isAddModal: boolean;
  alertId?: string;
  updateAlert?: (alertId: string, alert: AlertUpdateDto) => void;
  createAlert?: (alert: AlertCreateDto) => void;
}

export const AddEditAlertForm = (props: Props) => {
  const localizationService = useLocalization();
  const toast = useToast({
    position: "bottom",
    duration: 5000,
    isClosable: true,
  });

  const [emails, setEmails] = useState<string[]>(props.alert.emails);
  const emailRequiredValidator = (value: string) =>
  {
    return emails.length > 0 ? "" : "Required";
  } 

  const dataType = [{ typeName: "Reservoir", typeId: 1 }, { typeName: "Requirement", typeId: 2 }]
  const [selectedType, setSelectedType] = useState<string>()

  const typeChange = (event: DropDownListChangeEvent) => {
    const type = event.target.value;
    setSelectedType(type.typeName)
  };

  const handleSubmit = async (dataItem) => {
    try {
      if (!props.isAddModal && props.updateAlert && props.alertId) {
        const castValues = {
          name: dataItem.name,
          description: dataItem.description,
          enabled: dataItem.enabled,
          conditions: dataItem.condition.conditions,
          message: dataItem.message,
          emails: emails,
          assignedTo: dataItem.assignedTo,
          criticality: dataItem.criticality,
          type: props.alert.type,
        } as AlertUpdateDto;

        await props.updateAlert(props.alertId, castValues);
        toast({
          status: "success",
          description: `'${
            dataItem.name
          }' ${localizationService.toLanguageString("custom.successfullyUpdated", enMessages.custom.successfullyUpdated
          )}`,
        });
      } else if (props.isAddModal && props.createAlert) {
        const castValues = {
          name: dataItem.name,
          description: dataItem.description,
          enabled: dataItem.enabled,
          conditions: dataItem.condition.conditions,
          message: dataItem.message,
          emails: emails,
          assignedTo: dataItem.condition.assignedTo,
          criticality: dataItem.criticality,
          type: dataItem.type.typeName,
        } as AlertCreateDto;

        await props.createAlert(castValues);

        toast({
          status: "success",
          description: `'${
            dataItem.name
          }'  ${localizationService.toLanguageString("custom.successfullyCreated", enMessages.custom.successfullyCreated
          )}`,
        });
      }
    } catch (e) {
      console.log(e)
      toast({
        title: `${localizationService.toLanguageString("custom.error", enMessages.custom.error
        )}`,
        status: "error",
        description: `${localizationService.toLanguageString("custom.somethingWentWrong", enMessages.custom.somethingWentWrong
        )}`,
      });
    }

    props.onClose();
  };

  return (
    <Form
      key={props.alert.name}
      initialValues={{
        name: props.alert.name,
        description: props.alert.description,
        enabled: props.alert.enabled,
        type: props.alert.type,
        criticality: props.alert.criticality,
        fieldCondition:
          props.alert.conditions[0].field === undefined ? dataField.find((item) => item.fieldName === JSON.parse(props.alert.conditions[0]).field) : dataField.find((item) => item.fieldName === props.alert.conditions[0].field),
        operator:
          props.alert.conditions[0].operator === undefined
            ? metricOperators.find(
                (item) =>
                  item.value === JSON.parse(props.alert.conditions[0]).operator
              )
            : metricOperators.find(
                (item) => item.value === props.alert.conditions[0].operator
              ),
        value:
          props.alert.conditions[0].value === undefined
            ? JSON.parse(props.alert.conditions[0]).value
            : props.alert.conditions[0].value,
        unit:
          props.alert.conditions[0].unit === undefined
            ? { unitName: JSON.parse(props.alert.conditions[0]).unit }
            : { unitName: props.alert.conditions[0].unit },
        emails: props.alert.emails,
        message: props.alert.message,
        assignedTo: props.alert.assignedTo,
      }}
      onSubmit={handleSubmit}
      render={(formRenderProps) => (
        <FormElement>
          <fieldset
            className={"k-form-fieldset"}
            style={{ padding: "0 10px 5px 5px" }}
          >
            <div>
              <Field
                label={localizationService.toLanguageString("custom.name", enMessages.custom.name)}
                name={"name"}
                type={"text"}
                component={FormInput}
                style={{ height: 26 }}
                validator={requiredValidator}
              />
            </div>
            <div className="mt-3">
              <Field
                label={localizationService.toLanguageString(
                  "custom.description",
                  enMessages.custom.description
                )}
                name={"description"}
                type={"text"}
                component={FormTextArea}
                style={{ height: 28 }}
                required={false}
              />
            </div>
            <div className="flex">
              <div className="mt-3">
                <Field
                  label={localizationService.toLanguageString("custom.enabled", enMessages.custom.enabled)}
                  name={"enabled"}
                  type={"text"}
                  component={FormSwitch}
                  trackRounded={"medium"}
                  thumbRounded={"medium"}
                  size={"small"}
                  defaultChecked={props.alert.enabled}
                />
              </div>
              <div className="mt-3 ml-10" style={{ width: "300px" }}>
                {props.isAddModal ? (
                    <Field
                      label={localizationService.toLanguageString("custom.type",enMessages.custom.type)}
                      name="type"
                      component={FormDropDownList}
                      data={dataType}
                      textField="typeName"
                      validator={requiredValidator}
                      size={"small"}
                      onChange={typeChange}
                    />
                ) : (
                  <>
                    <label>{localizationService.toLanguageString("custom.type",enMessages.custom.type)}</label>
                    <div>{props.alert.type}</div>
                  </>
                )}
              </div>
              <div className="mt-3 ml-10" style={{ width: "208px" }}>
                  <Field
                    label={localizationService.toLanguageString("custom.criticality",enMessages.custom.criticality)}
                    name={"criticality"}
                    component={FormDropDownList}
                    data={[localizationService.toLanguageString("custom.low",enMessages.custom.low),
                      localizationService.toLanguageString("custom.medium", enMessages.custom.medium),
                      localizationService.toLanguageString("custom.high", enMessages.custom.high),
                      localizationService.toLanguageString("custom.critical", enMessages.custom.critical),
                    ]}
                    validator={requiredValidator}
                    valueRender={valueRenderCriticality}
                    size={"small"}
                  />
              </div>
            </div>

            <div className="gb-h4 mb-3 mt-4" style={{ borderBottom: "1px solid #EBEBEB" }}>
              {localizationService.toLanguageString("custom.condition",enMessages.custom.condition)}
            </div>

             {
              (selectedType === "Requirement" || props.alert.type === "Requirement") ?(
        
                <Field
                  key={'RequirementCondition'}
                  name={'condition'}
                  component={RequirementConditionComponent}
                  condition={{assignedTo: props.alert.assignedTo, conditions: props.alert.conditions}}
                  isAddModal={props.isAddModal}>
                </Field>
              ) : 
              (selectedType === "Reservoir" || props.alert.type === "Reservoir")  ?(
                <Field
                  key={'ReservoirCondition'}
                  name={'condition'}
                  component={ReservoirConditionComponent}
                  condition={{assignedTo: props.alert.assignedTo, conditions: props.alert.conditions}}
                  isAddModal={props.isAddModal}>
                </Field>
              ) : (
                <div>Select a Type...</div>
              )
            } 

            <div className="mt-4">
              <div
                className="gb-h4 mb-3"
                style={{ borderBottom: "1px solid #EBEBEB" }}
              >
                {localizationService.toLanguageString("custom.notifications",enMessages.custom.notifications)}
              </div>
              <Field
                name={"emails"}
                label={localizationService.toLanguageString("custom.notifyUsers", enMessages.custom.notifyUsers)}
                component={FormMultiEmail}
                validator={emailRequiredValidator}
                placeholder={localizationService.toLanguageString("custom.emails", enMessages.custom.emails)}
                emails={emails}
                onChange={(_to: string[]) => {
                  setEmails(_to);
                }}
                validateEmail={(email) => {
                  return isEmail(email);
                }}
                getLabel={(email, index, removeEmail) => {
                  return (
                    <div data-tag key={index}>
                      {email}
                      <span data-tag-handle onClick={() => removeEmail(index)}>
                        ×
                      </span>
                    </div>
                  );
                }}
              />

              <div className="mt-3">
                <Field
                  label={localizationService.toLanguageString("custom.message", enMessages.custom.message)}
                  name={"message"}
                  type={"text"}
                  component={FormTextArea}
                  rows={2}
                  required={false}
                />
              </div>
            </div>

          </fieldset>
          <span className={"k-form-separator"} />
          <div className="p-2" style={{ textAlign: "right" }}>
            <Button
              type={"submit"}
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              style={{
                background: "#35AC00",
                color: "white",
                width: " 62px",
                marginRight: "5px",
              }}
              disabled={!formRenderProps.allowSubmit}
            >
              {localizationService.toLanguageString("custom.save", enMessages.custom.save)}
            </Button>
            <Button onClick={props.onClose}>
              {localizationService.toLanguageString("custom.cancel", enMessages.custom.cancel)}
            </Button>
          </div>
        </FormElement>
      )}
    />
  );
};