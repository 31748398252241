import { useToast } from "@chakra-ui/react";
import { Field, FieldProps, Form, Formik } from "formik";
import { useState } from "react";
import { ScheduleCreateDto } from "../../../Models/Schedule/ScheduleCreateDto";
import { ScheduleReadDto } from "../../../Models/Schedule/ScheduleReadDto";
import FieldErrorMessage from "../../Shared/Components/Forms/TableErrorMessage";
import DayPickerWeek from "../../Shared/Components/Forms/DayPickerWeek";
import DayPickerMonth from "../../Shared/Components/Forms/DayPickerMonth";
import { FrequencyTypes } from "../../../Constants/SchedulerConstants";
import { RRule } from "rrule";
import React from "react";
import moment from "moment";
import { timezonesToComboboxItems, timezoneToComboboxItem } from "../../Area/Utilities/AreaUtilities";
import TableErrorMessage from "../../Shared/Components/Forms/TableErrorMessage";
import {
  rruleFrequencyToTextRRule,
  minEndDate,
  rRuleGenerator,
  getValidStartDateForFrequency,
  FrequencyStringToDisplayName,
} from "../Utilities/ScheduleUtilities";
import SchedulesValidationSchema from "../Utilities/SchedulesValidationSchema";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBox, ComboBoxFilterChangeEvent, DropDownList } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";
import { FilterDescriptor, filterBy } from "@progress/kendo-data-query";

interface Props {
  createSchedule: (scheduleCreateDto: ScheduleCreateDto) => void;
  schedule: ScheduleReadDto | null;
  schedules: Array<ScheduleReadDto>;
  updateSchedule: (scheduleId: string, schedule: ScheduleCreateDto) => void;
  addSchedule: boolean;
  onClose: () => void;
}

export default function ScheduleAddModal(props: Props) {
  const toast = useToast({
    position: "bottom",
    duration: 5000,
    isClosable: true,
  });
  const [frequency, setFrequency] = useState<string>(FrequencyTypes.daily);
  const [showSelectionErrorMessage, setShowSelectionErrorMessage] = useState<boolean>(false);
  const [daysSelected, setDaysSelected] = useState<Array<number>>([]);
  const [week1Selected, setWeek1Selected] = useState<Array<number>>([]);
  const [week2Selected, setWeek2Selected] = useState<Array<number>>([]);
  const [week3Selected, setWeek3Selected] = useState<Array<number>>([]);
  const [week4Selected, setWeek4Selected] = useState<Array<number>>([]);

  const [dataFilter, setDataFilter] = useState(timezonesToComboboxItems().slice());

  const localizationService = useLocalization();

  React.useEffect(() => {
    if (props.schedule) {
      setFrequency(rruleFrequencyToTextRRule(props.schedule?.rRule!));
      setDaysSelected([]);
    }
  }, [props.schedule]);

  React.useEffect(() => {
    if (frequency === FrequencyTypes.daily) {
      setShowSelectionErrorMessage(false);
    }

    if (frequency === FrequencyTypes.weekly) {
      if (daysSelected.length === 0) {
        setShowSelectionErrorMessage(true);
      } else {
        setShowSelectionErrorMessage(false);
      }
    }
  }, [frequency, daysSelected]);

  React.useEffect(() => {
    if (frequency === FrequencyTypes.monthly) {
      if (
        week1Selected.length === 0 &&
        week2Selected.length === 0 &&
        week3Selected.length === 0 &&
        week4Selected.length === 0
      ) {
        setShowSelectionErrorMessage(true);
      } else {
        setShowSelectionErrorMessage(false);
      }
    }
  }, [frequency, week1Selected, week2Selected, week3Selected, week4Selected]);

  const handleSubmit = async (values: any, setStatus: any) => {
    let rule = await rRuleGenerator(
      values,
      frequency,
      daysSelected,
      week1Selected,
      week2Selected,
      week3Selected,
      week4Selected
    );

    if (showSelectionErrorMessage) {
      toast({
        status: "error",
        title: `${localizationService.toLanguageString('custom.error', enMessages.custom.error)}`,
        description: `${localizationService.toLanguageString('custom.mustSelectDay', enMessages.custom.mustSelectDay)}`,
      });
    } else {
      try {
        //Set start date to something valid based on the frequency
        const validDate = getValidStartDateForFrequency(values.startDate, frequency);

        //If editing an existing schedule (props.schedule is provided...)
        if (props.schedule) {
          const castValues = {
            name: values.name,
            description: values.description,
            rRule: rule.toString(),
            timezone: values.timezone,
            recurrence: values.recurrence,
            startDate: validDate,
            endDate: values.endDate,
          } as ScheduleCreateDto;
          const updateId = props.schedule.id;
          await props.updateSchedule(updateId, castValues);
        }
        //If creating an entirely new schedule
        else {
          const castValues = {
            name: values.name,
            description: values.description,
            rRule: rule.toString(),
            timezone: values.timezone,
            recurrence: values.recurrence,
            startDate: validDate,
            endDate: values.endDate,
          } as ScheduleCreateDto;

          await props.createSchedule(castValues);
        }

        toast({
          status: "success",
          description: `'${values.name}' ${localizationService.toLanguageString('custom.scheduleSuccessfully', enMessages.custom.scheduleSuccessfully)} ${
            props.schedule ? localizationService.toLanguageString('custom.updated', enMessages.custom.updated) : localizationService.toLanguageString('custom.created', enMessages.custom.created)
          }`,
        });
      } catch (e: any) {
        //On failure show error toast, set status messages
        setStatus(e?.response?.data?.errors);

        toast({
          title: `${localizationService.toLanguageString('custom.error', enMessages.custom.error)}`,
          status: "error",
          description: `${localizationService.toLanguageString('custom.somethingWentWrong', enMessages.custom.somethingWentWrong)} \n
          ${e?.response?.data?.errors ? Object.values(e?.response?.data?.errors)[0] : ""}`,
        });
      }
    }
  };

  const initialValueGenerator = (update: boolean) => {
    if (update) {
      return {
        name: props.schedule?.name,
        rRule: props.schedule?.rRule,
        recurrence: RRule.fromString(props.schedule?.rRule!).origOptions.interval || 1,
        timezone: props.schedule?.timezone,
        startDate: new Date(props.schedule?.startDate!),
        endDate: props.schedule?.endDate != null ? new Date(props.schedule?.endDate!) : null,
      } as ScheduleCreateDto;
    } else {
      return {
        name: "",
        rRule: "",
        recurrence: 1,
        timezone: moment.tz.guess(),
        startDate: new Date(),
        endDate: null,
      } as ScheduleCreateDto;
    }
  };

  const RenderRecurrence = () => {
    if (frequency === FrequencyTypes.daily) {
      return (
        <div className="p-2">
          <label htmlFor="dailyRecurrence">{localizationService.toLanguageString('custom.dailyRecurrenceInterval', enMessages.custom.dailyRecurrenceInterval)}</label>
          <Field
            type="number"
            name="recurrence"
            className="k-input k-input-md k-rounded-md k-input-solid h-8"
            placeholder={localizationService.toLanguageString('custom.recurranceXDays', enMessages.custom.recurranceXDays)}
          />
          <FieldErrorMessage name="recurrence" />
        </div>
      );
    }
    if (frequency === FrequencyTypes.weekly) {
      return (
        <div className="p-2">
          <label htmlFor="weeklyRecurrence">{localizationService.toLanguageString('custom.weeklyRecurrenceInterval', enMessages.custom.weeklyRecurrenceInterval)}</label>
          <DayPickerWeek daysSelected={daysSelected} setDaysSelected={setDaysSelected} />
          {showSelectionErrorMessage && (
            <div className="text-xs text-red-600">* {localizationService.toLanguageString('custom.mustSelectWeek', enMessages.custom.mustSelectWeek)}</div>
          )}
          <label htmlFor="recurrenceInterval">{localizationService.toLanguageString('custom.recurrenceInterval', enMessages.custom.recurrenceInterval)}</label>
          <Field
            type="number"
            name="recurrence"
            className="k-input k-input-md k-rounded-md k-input-solid h-8"
            placeholder={localizationService.toLanguageString('custom.recurranceXWeeks', enMessages.custom.recurranceXWeeks)}
          />
          <FieldErrorMessage name="recurrence" />
        </div>
      );
    }
    if (frequency === FrequencyTypes.monthly) {
      return (
        <div className="p-2">
          <label htmlFor="weeklyRecurrence">{localizationService.toLanguageString('custom.monthlyRecurrence', enMessages.custom.monthlyRecurrence)}</label>
          <DayPickerMonth
            week1Selected={week1Selected}
            setWeek1Selected={setWeek1Selected}
            week2Selected={week2Selected}
            setWeek2Selected={setWeek2Selected}
            week3Selected={week3Selected}
            setWeek3Selected={setWeek3Selected}
            week4Selected={week4Selected}
            setWeek4Selected={setWeek4Selected}
          />
          {showSelectionErrorMessage && (
            <div className="text-xs text-red-600">* {localizationService.toLanguageString('custom.mustSelectMonth', enMessages.custom.mustSelectMonth)}</div>
          )}
          <label htmlFor="recurrenceInterval">{localizationService.toLanguageString('custom.recurrenceInterval', enMessages.custom.recurrenceInterval)}</label>
          <Field type="number" name="recurrence" className="form-field" placeholder={localizationService.toLanguageString('custom.recurranceXMonths', enMessages.custom.recurranceXMonths)}/>
          <FieldErrorMessage name="recurrence" />
        </div>
      );
    } else {
      return <></>;
    }
  };
  return (
    <>
      {(props.addSchedule || props.schedule !== null) && (
        <Dialog title={props.schedule ? `${localizationService.toLanguageString('custom.updateSchedule', enMessages.custom.updateSchedule)}` : `${localizationService.toLanguageString('custom.createSchedule', enMessages.custom.createSchedule)}`} onClose={props.onClose}>
          <Formik
            onSubmit={async (values, { setStatus }) => {
              await handleSubmit(values, setStatus);
            }}
            validationSchema={SchedulesValidationSchema}
            initialValues={initialValueGenerator(props.schedule ? true : false)}
          >
            {({ submitForm, values, initialValues, setFieldValue }) => (
              <>
                <Form
                  style={{
                    minWidth: "500px",
                  }}
                >
                  {/* {Email input */}
                  <div className="p-2">
                    <label htmlFor="name">{localizationService.toLanguageString('custom.scheduleName', enMessages.custom.scheduleName)}</label>
                    <Field
                      className="k-input k-input-md k-rounded-md k-input-solid h-8"
                      type="text"
                      name="name"
                      placeholder={localizationService.toLanguageString('custom.name', enMessages.custom.name)}
                      autocomplete="off"
                    />
                    <FieldErrorMessage name="name" />
                  </div>

                  <div className="p-2">
                    <label htmlFor="frequency">{localizationService.toLanguageString('custom.frequency', enMessages.custom.frequency)}</label>
                    <Field
                      name="frequency"
                      render={({ field, form, meta }: FieldProps) => (
                        <DropDownList
                          style={{ height: "30px" }}
                          textField="displayName"
                          defaultValue={{
                            value: frequency,
                            displayName: FrequencyStringToDisplayName(frequency),
                          }}
                          onChange={(e: any) => setFrequency(e.target.value.value)}
                          data={[
                            {
                              displayName: `${localizationService.toLanguageString('custom.daily', enMessages.custom.daily)}`,
                              value: "RRule.DAILY",
                            },
                            {
                              displayName: `${localizationService.toLanguageString('custom.weekly', enMessages.custom.weekly)}`,
                              value: "RRule.WEEKLY",
                            },
                            {
                              displayName: `${localizationService.toLanguageString('custom.monthly', enMessages.custom.monthly)}`,
                              value: "RRule.MONTHLY",
                            },
                          ]}
                        />
                      )}
                    ></Field>
                    <FieldErrorMessage name="frequency" />
                  </div>

                  <RenderRecurrence />

                  <div className="p-2 w-full">
                    <label htmlFor="timezone">{localizationService.toLanguageString('custom.timezone', enMessages.custom.timezone)}</label>
                    <Field
                      name="timezone"
                      render={({ form }: FieldProps) => {
                        
                        const filterData = (filter: FilterDescriptor) => {
                        const data = timezonesToComboboxItems().slice();
                        return filterBy(data, filter);
                        };

                        const filterChange = (event: ComboBoxFilterChangeEvent) => {
                          setDataFilter(filterData(event.filter));
                        };
                        return <ComboBox
                          defaultValue={timezoneToComboboxItem(initialValues.timezone)}
                          data={dataFilter}
                          textField="displayName"
                          name="timezone"
                          className="form-field"
                          placeholder={localizationService.toLanguageString('custom.timezone', enMessages.custom.timezone)}
                          onChange={(newValue) => {
                            newValue.value === null ? setFieldValue("timezone", '') :
                            form.setFieldValue("timezone", newValue.value.value);
                          }}
                          filterable={true}
                          onFilterChange={filterChange}
                        />
                        }}
                    />
                    <TableErrorMessage name="timezone" />
                  </div>

                  <div className="p-2 w-full">
                    <label htmlFor="startDate">{localizationService.toLanguageString('custom.startDate', enMessages.custom.startDate)}</label>

                    <DatePicker
                      onChange={(date) => {
                        //If weekly, get start of week, if Monthly, get start of month
                        setFieldValue("startDate", date.value);
                      }}
                      defaultValue={props.schedule != null ? new Date(props.schedule?.startDate!) : new Date()}
                    />
                  </div>

                  <div className="p-2 w-full">
                    <label htmlFor="endDate">{localizationService.toLanguageString('custom.endDate', enMessages.custom.endDate)}</label>
                    <DatePicker
                      onChange={(date) => setFieldValue("endDate", date.value)}
                      defaultValue={
                        props.schedule && props.schedule.endDate != null ? new Date(props.schedule?.endDate!) : null
                      }
                      placeholder={localizationService.toLanguageString('custom.endDate', enMessages.custom.endDate)}
                      min={minEndDate()}
                    />
                  </div>
                </Form>

                <DialogActionsBar>
                  <Button
                    themeColor={"success"}
                    onClick={() => {
                      submitForm();
                    }}
                  >
                    {localizationService.toLanguageString('custom.save', enMessages.custom.save)}
                  </Button>
                  <Button onClick={props.onClose}>{localizationService.toLanguageString('custom.cancel', enMessages.custom.cancel)}</Button>
                </DialogActionsBar>
              </>
            )}
          </Formik>
        </Dialog>
      )}
    </>
  );
}
