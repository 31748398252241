
import { Field } from '@progress/kendo-react-form';
import { FormDropDownList, FormInput } from './FormComponents';
import { consumableValidator, nameValidator } from '../../Utilities/Validators';

export const RightGrease = (props)  => {
  return(
  <div style={{height: "450px",}} className='py-2'>
    <div className='pt-2 pb-5'>
    <Field
      key={'name'}
      id={'name'}
      name={'name'}
      label={"Requirement Name"}
      component={FormInput}
      validator={nameValidator}
    />
    </div>
     <Field
      key={'consumable'}
      id={'consumable'}
      name={'consumable'}
      label={"Consumable"}
      component={FormDropDownList}
      data={props.consumables}
      textField={"name"}
      dataItemKey={"id"}
      validator={consumableValidator}
      required={true}
    />
  </div>
)};