import { ReactElement, useEffect, useMemo, } from "react";
import { useLocalization } from '@progress/kendo-react-intl';
import { EventFilter } from "../../../../Events/Utilities/EventsApiService";
import { Splitter, SplitterOnChangeEvent, TileLayout, TileLayoutItem } from "@progress/kendo-react-layout";
import React from "react";
import ReservoirViewPage from "../../../../Reservoir/Pages/ReservoirViewPage";
import ReservoirChart from "../../../../Reservoir/Components/ReservoirChart";
import { ReservoirStatusDto } from "../../../../../Models/Reservoirs/ReservoirStatusDto";
import { useQueryClient, useQuery } from "react-query";
import { ReactQueryKeys } from "../../../../../Constants/ClientRoutingConstants";
import { ReservoirReadDto } from "../../../../../Models/Reservoirs/ReservoirReadDto";
import { ReservoirResetDto } from "../../../../../Models/Reservoirs/ReservoirResetDto";
import analysisApiService from "../../../../Reservoir/Utilities/AnalysisApiService";
import greaseReservoirsApiService from "../../../../Reservoir/Utilities/GreaseReservoirApiService";


interface Props {
  selectedItem: string
  reservoirId: string
}

export default function EndpointReservoirPage(props: Props): ReactElement {
  const localizationService = useLocalization();

  const [panes, setPanes] = React.useState<Array<any>>([
    { size: "60%", min: "20px", collapsible: true, resizable:true  },
    {},
    { size: "40%", min: "20px", collapsible: true, resizable:true  },
  ]);

  const onChange = (event: SplitterOnChangeEvent) => {
    setPanes(event.newState);
  };

  const client = useQueryClient();

  const analysisReservoirsQuery = useQuery<ReservoirStatusDto>([ReactQueryKeys.GreaseReservoirsAnalysisQuery, props.reservoirId], async () => {
    return analysisApiService.getReservoirStatus(props.reservoirId);
  });

  const key = "Reservoir" + props.reservoirId;

  const greaseReservoirsQuery = useQuery<ReservoirReadDto>(key, async () => {
    return greaseReservoirsApiService.getReservoir(props.reservoirId);
  });

  const onReset = async (reservoirResetDto: ReservoirResetDto) => {
    let response = await greaseReservoirsApiService.resetReservoir(props.reservoirId, reservoirResetDto);
    client.refetchQueries(ReactQueryKeys.GreaseReservoirsAnalysisQuery);
    client.refetchQueries(key);
  };

  return (
    <>
    {!analysisReservoirsQuery.isLoading && !greaseReservoirsQuery.isLoading && (
      <div className="flex gap-10">
        <div style={{minWidth:"400px"}}>
      <ReservoirViewPage reservoir={greaseReservoirsQuery.data} reservoirStatus={analysisReservoirsQuery.data!} onReset={onReset} viewReservoir={true}/>
      </div>
      <div className="flex" style={{width:"400px"}}>
        <ReservoirChart reservoir={greaseReservoirsQuery.data} reservoirStatus={analysisReservoirsQuery.data!}/>
      </div>
    </div>
    )}
    </>
  )
}