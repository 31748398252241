import { ComboBoxItem } from "./../../Shared/Components/Forms/ComboBox";
import { ScheduleReadDto } from "../../../Models/Schedule/ScheduleReadDto";
import { Frequency, rrulestr, RRule } from "rrule";
import {
  FrequencyTypes,
  ByWeekday,
} from "../../../Constants/SchedulerConstants";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";
import { validateYupSchema } from "formik";

const date = new Date();

export const schedulesToComboboxItems = (existingSchedules: Array<ScheduleReadDto>) => {
  return existingSchedules
    ?.filter((data) => {
      return data.id;
    })
    .map((schedule) => {
      return {
        displayName: schedule.name,
        value: schedule.id,
      };
    }) as Array<ComboBoxItem>;
};

export const scheduleToComboboxItem = (scheduleId: string, existingSchedules: Array<ScheduleReadDto>) => {
  return scheduleId != null
    ? ({
        displayName: existingSchedules?.find((p) => p?.id === scheduleId)?.name,
        value: scheduleId,
      } as ComboBoxItem)
    : null;
};

export const rruleFrequencyToText = (rRule: string) => {
  let rule = rrulestr(rRule);
    if (rule.origOptions.freq === Frequency.WEEKLY) {
    return "Weekly";
  } else if (rule.origOptions.freq === Frequency.DAILY) {
    return "Daily";
  } else return "";
};

export const rruleFrequencyToTextRRule = (rRule: string) => {
  let rule = rrulestr(rRule);
    if (rule.origOptions.freq === Frequency.WEEKLY) {
    return "RRule.WEEKLY";
  } else if (rule.origOptions.freq === Frequency.DAILY) {
    return "RRule.DAILY";
  } else return "";
};

export const rruleRecurrenceToText = (rRule: string) => {
  let rule = rrulestr(rRule);
    if (rule.origOptions.freq === Frequency.WEEKLY) {
    return `Every ${rule.origOptions.interval ?? 1} Weeks`;
  } else if (rule.origOptions.freq === Frequency.DAILY) {
    return `Every ${rule.origOptions.interval ?? 1} Days`;
  } else return "";
};

export const rruleSummaryToText = (rRule: string) => {
  let rule = rrulestr(rRule);
  return rule.toText().replace(/^\w/, (c) => c.toUpperCase());
};

/**
 * This method returns a valid start date based on the frequency of a schedule if weekly, get start of week, if Monthly, get start of month
 * @param date The suggested start date
 * @param frequency The frequency of the RRULE for the schedule
 * @returns a valid start date
 */
export const getValidStartDateForFrequency = (date: Date | null | undefined, frequency: string) => {
  if (frequency === FrequencyTypes.weekly && date != null && date !== undefined) {
    let day = date!.getDay();
    let diff = date!.getDate() - day; //Assumes sunday is start of week
    date = new Date(date.setDate(diff));
  } else if (frequency === FrequencyTypes.monthly && date != null && date !== undefined) {
    date = new Date();
    const y = date.getFullYear();
    const m = date.getMonth();
    date = new Date(y, m, 1);
  }

  return date;
};

export const minEndDate = () => {
  const date = new Date();
  date.setDate(date.getDate() + 1);

  return date;
};

export const FrequencyStringToDisplayName = (frequency: string) => {
  const localizationService = useLocalization();
  if (frequency === "RRule.DAILY") {
    return `${localizationService.toLanguageString('custom.daily', enMessages.custom.daily)}`;
  }
  if (frequency === "RRule.WEEKLY") {
    return `${localizationService.toLanguageString('custom.weekly', enMessages.custom.weekly)}`;
  }
  return "";
};

export const rRuleGenerator = async (
  values: any,
  frequency: string,
  daysSelected: Array<number>,

) => {
  let rule = new RRule({});

  if (frequency === FrequencyTypes.daily) {
    rule = new RRule({
      freq: RRule.DAILY,
    });
  } else if (frequency === FrequencyTypes.weekly) {
    let daySelect = daysSelected.sort().map((i: number) => ByWeekday[i]);

    rule = new RRule({
      freq: RRule.WEEKLY,
      interval: values.recurrence,
      byweekday: daySelect,
    });
  }

  return rule;
};
