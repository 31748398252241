
import * as React from 'react';
import { PanelBar, PanelBarItem, PanelBarSelectEventArguments } from '@progress/kendo-react-layout';
import { Outlet } from 'react-router-dom';
import SideBar from '../Components/SideBar';
import { Header } from '@progress/kendo-react-data-tools';
import HeaderBar from '../Components/HeaderBar';
import { ClientRoutes } from '../../../Constants/ClientRoutingConstants';
import useLayoutStore from '../../../Zustand/layoutStore';


const PanelBarNavContainer = (props: any) => {
  const store = useLayoutStore();

  /**
   * On mount, listen on page resize to determine if mobile responsive mode should be enabled.
   * set to switch at 768px which is tailwind definition for md screen size
   */
  React.useEffect(() => {
    // Declare resize function
    function handleResize() {
      if (window.innerWidth < 768) {
        store.setIsSidebarOpen(false);
      } else {
        store.setIsSidebarOpen(true);
      }
    }
    //Set up listener
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  });

  const handleOutOfSidebarClick = () => {
    if (window.innerWidth < 768) {
      store.setIsSidebarOpen(false);
    }
  };

  return (
    <div className='flex flex-col min-h-screen custom-toolbars'>
      <HeaderBar/>
      <div className="flex w-full h-full flex-grow ">

        <SideBar/>
        {store.isSidebarOpen && window.innerWidth < 768 && (
          <div
            onClick={handleOutOfSidebarClick}
            className="gb-overlay"
          />
        )}

      <div className="w-full h-full">{props.children}<Outlet/></div>
    </div>
    </div>
  );
}

export default PanelBarNavContainer;
