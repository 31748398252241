export const deMessages = {
  custom: {
    language: "Sprache",
    profile: "Gehen Sie zu Profil",
    changeAccount: "Account wechseln",
    logout: "Ausloggen",
    accountSetup: "Kontoeinrichtung",
    uploadData: "Kontodaten herunterladen",
    step1:
      "Schritt 1: Laden Sie die Konto-Upload-Vorlage herunter und füllen Sie sie aus",
    step2: "Schritt 2: Kontovorlagendatei hochladen",
    seeUploadTemplate:
      "Weitere Informationen finden Sie unter Vorlage hochladen",
    downloadTemplate: "Vorlage herunterladen",
    errors: "Fehler",
    downloadData: "Kontodaten exportieren",
    inventory: "Inventar",
    schedules: "Zeitpläne",
    locations: "Standorte",
    greaseTags: "Schmieretiketten",
    routeEditor: "Routeneditor",
    devices: "Geräte",
    assets: "Vermögenswerte",
    events: "Veranstaltungen",
    compliance: "Einhaltung",
    administration: "Verwaltung",
    manageUsers: "Benutzer verwalten",
    manageDevices: "Geräte verwalten",
    manageAccounts: "Konten verwalten",
    supportCentre: "Support-Center",
    cancel: "Stornieren",
    confirm: "Bestätigen",
    confirmLeave: "Möchten Sie diese Seite wirklich verlassen?",
    unsavedChanges:
      "Es können noch nicht gespeicherte Änderungen auf dieser Seite vorhanden sein, wenn Sie auf Bestätigen drücken, werden Sie wegnavigiert und alle nicht gespeicherten Änderungen gehen verloren.",
    unsavedChangesShort: "Alle nicht gespeicherten Änderungen gehen verloren.",
    howTo: "How-to-Video-Anleitungen",
    howToView: "Sehen Sie sich unsere neuesten Anleitungsvideos an",
    howToBtn: "Anleitungsvideos ansehen",
    password: "Passwort",
    supportDocumentation: "Support-Dokumentation",
    supportDocumentationView:
      "Sehen Sie sich unsere neueste Support-Dokumentation an, die online gehostet wird.",
    supportDocumentatioBtn: "Support-Dokumentation anzeigen",
    support: "Unterstützung",
    supportText:
      "Wenden Sie sich an unser Support-Team und lassen Sie sich bei Problemen oder Fragen zu Ihren Produkten beraten.",
    supportRequest: "Stellen Sie eine Support-Anfrage",
    supportEmail: "senden Sie uns eine Email",
    greaseTypes: "Schmierfett Typ",
    greaseTypesText:
      "Bitte registrieren Sie die verschiedenen Fettsorten, die von Maschinen benötigt werden, in Ihrem Konto.",
    schedulesText:
      "Zeitpläne definieren die Häufigkeit und die Daten, an denen Tags in Ihrem Konto geschmiert werden müssen.",
    locationsText:
      "Verschiedene Zonen innerhalb Ihres Kontos, die Vermögenswerte und Punkte zum Schmieren enthalten.",
    assetsText:
      "Assets sind Pflanzenteile in Ihrem Konto, die mit Schmieretiketten versehen sind.",
    greaseTagsText:
      "Schmierpunkte, die mit Vermögenswerten in Ihrem Konto verbunden sind, die mit Greaseboss-Schmier-Tags verbunden sind.",
    routeEditorText:
      "Gibt die Reihenfolge an, in der Schmierstellen geschmiert werden sollten.",
    devicesText:
      "Repräsentiert Greaseboss-Haupteinheiten, die in Ihrem Konto registriert sind.",
    name: "Name",
    description: "Beschreibung",
    manufacturer: "Hersteller",
    consumables: "Verbrauchsmaterial",
    actions: "Aktionen",
    add: "Hinzufügen",
    exit: "Ausfahrt",
    edit: "Bearbeiten",
    delete: "Löschen",
    discardChanges: "Änderungen verwerfen",
    save: "Speichern",
    saveChanges: "Änderungen speichern?",
    saveChangesConfirmation:
      "Möchten Sie die an den ausgewählten Elementen vorgenommenen Änderungen wirklich speichern?",
    continue: "Weitermachen",
    deleteItems: "Elemente löschen?",
    deleteItemsConfirmation:
      "Möchten Sie die ausgewählten Elemente wirklich löschen?",
    exportCSV: "Als CSV exportieren",
    search: "Suchen...",
    frequency: "Frequenz",
    recurrence: "Wiederauftreten",
    timezone: "Zeitzone",
    startDate: "Startdatum",
    summary: "Zusammenfassung",
    addSchedule: "Zeitplan hinzufügen",
    mustSelectDay:
      "Für einen wöchentlichen oder monatlichen Zeitplan muss ein Tag für eine Woche ausgewählt werden.",
    error: "Fehler",
    scheduleSuccessfully: "Zeitplan wurde erfolgreich durchgeführt",
    updated: "Aktualisiert",
    created: "erstellt",
    somethingWentWrong:
      "Beim Erstellen des Zeitplans ist etwas schief gelaufen.",
    dailyRecurrenceInterval: "Tägliches Wiederholungsintervall",
    recurranceXDays: "Wiederkehrend alle X Tage",
    weeklyRecurrenceInterval: "Wöchentliche Wiederholung",
    recurranceXWeeks: "Wiederkehrend alle X Wochen",
    recurranceXMonths: "Wiederkehrend alle X Monate",
    recurrenceInterval: "Wiederholungsintervall",
    mustSelectWeek: "Für die Woche muss mindestens ein Tag ausgewählt werden",
    monthlyRecurrence: "Monatliche Wiederholung",
    mustSelectMonth: "Für den Monat muss mindestens ein Tag ausgewählt werden",
    updateSchedule: "Zeitplan aktualisieren",
    createSchedule: "Zeitplan erstellen",
    scheduleName: "Zeitplanname",
    daily: "Täglich",
    weekly: "Wöchentlich",
    monthly: "Monatlich",
    endDate: "Endtermin",
    parentLocation: "Übergeordneter Standort",
    descriptionNotes: "Beschreibung/Anmerkungen",
    model: "Modell",
    parentAsset: "Übergeordnetes Asset",
    numTags: "# Tags",
    location: "Standort",
    order: "Befehl",
    asset: "Anlage",
    consumable: "Verbrauchsmaterial",
    volume: "Volumen (cc)",
    rfid: "RFID",
    schedule: "Zeitplan",
    tagName: "Verlinke den Namen",
    grease: "Schmierfett",
    modelNumber: "Modell-Nr",
    serialNumber: "Seriennummer",
    firmwareVersion: "Firmware Version",
    occurred: "Geschah",
    point: "Punkt",
    volumeDispensed: "Dispensiertes Volumen (cc)",
    eventsText:
      "Zeigen Sie Ereignisse an, die von Geräten in Ihrem Greasecloud-Netzwerk gepostet wurden.",
    showEventsFrom: "Alle Veranstaltungen anzeigen Von",
    until: "Bis",
    email: "Email",
    currentRole: "Momentane Rolle",
    confirmed: "Bestätigt",
    currentAccount: "Aktuelles Konto",
    user: "Benutzer",
    updateRoles: "Rollen aktualisieren",
    resendInvite: "Einladung erneut senden",
    manageUsersText:
      "Gewähren Sie neuen Benutzern Zugriff, entziehen Sie den Zugriff und weisen Sie Benutzerrollen zu.",
    id: "AUSWEIS",
    account: "Konto",
    device: "Gerät",
    manageDevicesText:
      "Erstellen Sie Identitäten für neue Geräte, aktualisieren Sie deren Konten und überwachen Sie die Nutzung.",
    manageAccountsText:
      "Konten mit Zugriff auf das GreaseCloud-System hinzufügen, entfernen und aktualisieren.",
    deviceCreation: "Erfolg bei der Geräteerstellung",
    deviceCreationText1:
      "Das Gerät wurde erfolgreich mit den folgenden Anmeldeinformationen erstellt. Bitte überprüfen Sie die folgenden Details und bewahren Sie sie an einem sicheren Ort auf.",
    deviceCreationText2:
      "Sie können diese Details nach dem Schließen dieses Fensters nicht mehr anzeigen.",
    secretKey: "GEHEIMER SCHLÜSSEL",
    close: "Schließen",
    roleRemoved: "Die Benutzerrolle wurde erfolgreich entfernt",
    roleRemovedError:
      "Beim Versuch, die Benutzerrolle zu entfernen, ist ein Fehler aufgetreten.",
    roleAdded: "Die Benutzerrolle wurde erfolgreich hinzugefügt",
    roleAddedError:
      "Beim Versuch, die Benutzerrolle hinzuzufügen, ist ein Fehler aufgetreten.",
    updateUserRoles: "Benutzerrollen aktualisieren",
    role: "Rolle",
    remove: "Entfernen",
    userInvitation: "Benutzereinladung wurde erfolgreich gesendet",
    userInvitationError: "Fehler beim Senden der Benutzereinladung.",
    userReinvitation: "Benutzer erfolgreich erneut zum Konto eingeladen.",
    userReinvitationError:
      "Nutzer konnte nicht erneut zum Konto eingeladen werden.",
    success: "Erfolg",
    userDeleted: "Benutzer erfolgreich aus Konto gelöscht.",
    userDeletedError:
      "Beim Versuch, den Benutzer aus dem Konto zu löschen, ist ein Fehler aufgetreten. Möglicherweise fehlen Ihnen die erforderlichen Berechtigungen.",
    accountCreated: "Konto wurde erfolgreich erstellt.",
    accountCreatedError:
      "Beim Erstellen eines neuen Kontos ist ein Fehler aufgetreten.",
    consumableCreated: "Das Konto wurde erfolgreich erstellt",
    consumableCreatedError:
      "Beim Erstellen des Verbrauchsmaterials ist etwas schief gelaufen",
    consumableDeleted:
      "Die ausgewählten Verbrauchsmaterialien wurden erfolgreich gelöscht.",
    consumableDeletedError:
      "Beim Versuch, die ausgewählten Verbrauchsmaterialien zu löschen, ist ein Fehler aufgetreten.",
    consumableUpdated:
      "Das ausgewählte Verbrauchsmaterial wurde erfolgreich aktualisiert.",
    consumableUpdatedError:
      "Das geänderte Verbrauchsmaterial wurde nicht erfolgreich aktualisiert.",
    cosnumableIncorrectInput:
      "Die folgenden Verbrauchsmaterialien sind aufgrund falscher Eingabe ausgefallen",
    deviceUpdated: "Das ausgewählte Gerät wurde erfolgreich aktualisiert.",
    deviceUpdatedError:
      "Das geänderte Gerät wurde nicht erfolgreich aktualisiert.",
    assetsDeleted: "Die ausgewählten Assets wurden erfolgreich gelöscht.",
    deviceIncorrectInput:
      "Die folgenden Geräte sind aufgrund falscher Eingaben fehlgeschlagen",
    assetsDeletedError:
      "Beim Versuch, die ausgewählten Assets zu löschen, ist ein Fehler aufgetreten.",
    assetsUpdated: "Die ausgewählten Assets wurden erfolgreich aktualisiert.",
    assetsUpdatedError:
      "Das geänderte Asset wurde nicht erfolgreich aktualisiert",
    assetsCreatedError: "Beim Erstellen des Inhalts ist etwas schief gelaufen",
    assetsIncorrectInput:
      "Die folgenden Assets sind aufgrund falscher Eingaben fehlgeschlagen",
    pointsCreatedError: "Beim Erstellen des Punktes ist etwas schief gelaufen",
    pointsDeleted: "Die ausgewählten Punkte wurden erfolgreich gelöscht.",
    pointsDeletedError:
      "Beim Versuch, die ausgewählten Punkte zu löschen, ist ein Fehler aufgetreten.",
    pointsUpdated: "Die ausgewählten Punkte wurden erfolgreich aktualisiert.",
    pointsUpdatedError:
      "Der geänderte Punkt wurde nicht erfolgreich aktualisiert.",
    routeUpdated: "Die Routenreihenfolge wurde erfolgreich aktualisiert.",
    pointsIncorrectInput:
      "Die folgenden Punkte sind aufgrund falscher Eingaben fehlgeschlagen",
    routeUpdatedError:
      "Die Routenreihenfolge konnte nicht aktualisiert werden.",
    schedulesDeleted: "Die ausgewählten Zeitpläne wurden erfolgreich gelöscht.",
    schedulesDeletedError:
      "Beim Versuch, die ausgewählten Zeitpläne zu löschen, ist ein Fehler aufgetreten.",
    errorOccured: "Ein Fehler ist aufgetreten.",
    errorPermissions:
      "Der Server hat einen Fehler zurückgegeben, der besagt, dass Sie nicht über die erforderlichen Berechtigungen verfügen, um auf die Ressourcen für diese Seite zuzugreifen.",
    deleteItemLabel:
      "Sind Sie sicher, dass Sie die ausgewählten löschen möchten",
    selectOption: "Wähle eine Option",
    occurringAfter: "Auftreten nach ...",
    notFound: "Nicht gefunden",
    fieldIsRequired: "Feld ist erforderlich",
    volumeRequirementValidation:
      "Der Wert muss -1, größer oder gleich 0 sein oder auf 'Purge' oder 'P' gesetzt sein. Nachkommastelle muss angegeben werden",
    createNewAccount: "Ein neues Konto erstellen",
    addAccount: "Fügen Sie Ihrem Konto ein neues Konto hinzu.",
    createAccount: "Benutzerkonto erstellen",
    accountName: "Kontobezeichnung",
    addUser: "Benutzer hinzufügen",
    addNewUser: "Fügen Sie Ihrem Konto einen neuen Benutzer hinzu.",
    siteId: "Konto-ID",
    roleId: "Rollen-ID",
    userId: "Benutzer-ID",
    inviteUser: "Benutzer einladen",
    currentFeed: "Ihr aktueller Feed",
    selectAccountId: "Für ein Gerät muss eine Konto-ID ausgewählt werden.",
    emailRequired: "Email (erforderlich",
    invalidEmail: "Ungültige E-Mail",
    roleRequired: "Rolle erforderlich",
    noSpecialCharacters: "Darf keine Sonderzeichen enthalten.",
    locationCreatedError:
      "Beim Erstellen des Standorts ist etwas schief gelaufen",
    locationDeleted: "Die ausgewählten Standorte wurden erfolgreich gelöscht.",
    locationDeletedError:
      "Beim Versuch, die ausgewählten Standorte zu löschen, ist ein Fehler aufgetreten.",
    locationUpdated:
      "Alle geänderten Standorte wurden erfolgreich aktualisiert!",
    locationUpdatedError:
      "Der geänderte Speicherort wurde nicht erfolgreich aktualisiert",
    invalidAction: "Ungültige Aktion",
    locationIncorrectInput:
      "Die folgenden Standorte sind aufgrund falscher Eingaben fehlgeschlagen",
    invalidStatusResult: "Ungültiges Statusergebnis",
    noRedirect: "Sollte nicht umleiten.",
    invalidAuthentication:
      "Ungültiger Status des Authentifizierungsergebnisses",
    deviceCreated: "Das Gerät wurde erfolgreich erstellt",
    deviceCreatedError: "Beim Erstellen des Geräts ist etwas schief gelaufen",
    deviceDeleted: "Die angegebenen Geräte wurden erfolgreich gelöscht",
    deviceDeletedError: "Beim Löschen der Geräte ist etwas schief gelaufen",
    sunday: "Su",
    monday: "M",
    tuesday: "T",
    wednesday: "W",
    thursday: "Th",
    friday: "F",
    saturday: "S",
    TRUE: "TRUE",
    FALSE: "FALSE",
    superAdmin: "Höchster Vorgesetzter",
    accountAdmin: "AccountAdmin",
    manager: "Manager",
    generalUser: "AllgemeinBenutzer",
    onboardApp: "Onboard-App",
    goToLink: "Verbindung öffnen",
    copyLink: "Link Adresse kopieren",
    reports: "Berichte",
    complianceReport: "Compliance Bericht",
    selectAll: "Wählen Sie Alle",
    points: "Punkte",
    generateReport: "Bericht generieren",
    clearFilters: "Filter löschen",
    selected: "Ausgewählt",
    loading: "Wird geladen...",
    pendingActions: "Bericht über ausstehende Aktionen",
    scheduleEmail: "E-Mail planen",
    emailScheduleText: "Planen Sie die E-Mail-Zustellung für diesen Bericht.",
    from: "Aus",
    to: "Zu",
    subject: "Thema",
    period: "Zeitraum",
    time: "Zeit",
    endpointReport: "Endpunktbericht",
    setFiltersText: "Legen Sie die Filter fest, um den Bericht zu erstellen.",
    endpoints: "Endpunkte",
    uploaded: "Hochgeladen",
    detailTreeList: "Detail TreeList",
    detailView: "Detailansicht",
    manualSystem: "Manuelles System",
    automaticSystem: "Automatisches System",
    site: "Grundstück",
    expectedConsumable: "Erwartetes Verbrauchsmaterial",
    volumeDelivered: "Vol. Geliefert",
    dueDate: "Geburtstermin",
    expiryDate: "Verfallsdatum",
    compliant: "Konform",
    volumeRequirement: "Vol. Erfordernis",
    volumeTolerance: "Vol. Toleranz",
    status: "Status",
    viewActionsText: "Zeigen Sie Aktionen in Ihrem GreaseCloud-Netzwerk an",
    showAllActionsFrom: "Alle Aktionen anzeigen Von",
    pending: "Ausstehend",
    closed: "Geschlossen",
    endpointCreated: "Der Endpunkt wurde erfolgreich erstellt",
    endpointCreatedError:
      "Beim Erstellen des Endpunkts ist etwas schief gelaufen",
    endpointDeleted: "Die angegebenen Endpunkte wurden erfolgreich gelöscht",
    endpointDeletedError:
      "Beim Löschen der Endpunkte ist etwas schief gelaufen",
    endpointUpdated: "Der ausgewählte Endpunkt wurde erfolgreich aktualisiert.",
    endpointUpdatedError:
      "Der geänderte Endpunkt wurde nicht erfolgreich aktualisiert.",
    endpointIncorrectInput:
      "Die folgenden Endpunkte sind aufgrund falscher Eingaben fehlgeschlagen:",
    manageEndpoints: "Endpunkte verwalten",
    manageendpointsText: "Erstellen Sie Identitäten für neue Endpunkte",
    endpointsText:
      "Stellt Greaseboss-Endpunkte dar, die in Ihrem Konto registriert sind.",
    hardwareVersion: "Hardware Version",
    schedulePattern: "Zeitplanmuster",
    notes: "Anmerkungen",
    temperature: "Temperatur",
    temperatureInC: "Temperatur (°C)",
    voltage: "Spannung (V)",
    rssiDbm: "RSSI (dBm)",
    endpointRssi: "Endpunkt RSSI (dBm)",
    cumulative: "Kumulativ (cc)",
    list: "Aufführen",
    noSchedule: "Kein plan",
    pointActivity: "Punktaktivität",
    endpointActivity: "Endpunktaktivität",
    activity: "Aktivität",
    metrics: "Metriken",
    selectPointEndpoint: "Wählen Sie ein Asset oder einen Punkt/Endpunkt aus.",
    noDataFound: "Keine Daten gefunden",
    changeDateRangeAndPoint:
      "Ändern Sie den Datumsbereich oder wählen Sie einen anderen Punkt/Endpunkt aus.",
    diagnostics: "Diagnose",
    changeDateRange: "Ändern Sie den Datumsbereich.",
    thisCouldBeBecause: "Dies könnte daran liegen",
    trySelectingDates:
      "Der Endpunkt hat seit einiger Zeit keine Diagnose gepostet. Versuchen Sie, andere Daten auszuwählen.",
    unableTocommunicate:
      "Der Endpunkt kann nicht mit der GreaseCloud kommunizieren. Bitte wenden Sie sich an den Support.",
    siteExplorer: "Site-Explorer",
    endpointMapping: "Endpunktzuordnung",
    tagMapping: "Tag-Karte",
    sessionExpired: "Ihre Sitzung ist abgelaufen, Sie werden jetzt abgemeldet.",
    none: "Keiner",
    aggregator: "Aggregator",
    complianceSent: "Compliance-Bericht erfolgreich gesendet.",
    unableToSendEmail: "Bericht kann nicht per E-Mail gesendet werden.",
    sendEmail: "E-Mail senden",
    send: "Senden",
    sendEmailText: "E-Mail für diesen Bericht senden.",
    reportDate: "Berichtsdatum",
    processing: "Verarbeiten...",
    last: "Letzten",
    days: "Tage",
    today: "Heute",
    yesterday: "Gestern",
    reservoirs: "Stauseen",
    reservoirSuccessfully: "Reservoir war erfolgreich",
    reservoirDeleted: "Das ausgewählte Reservoir wurde erfolgreich gelöscht.",
    reservoirDeletedError:
      "Beim Versuch, das ausgewählte Reservoir zu löschen, ist ein Fehler aufgetreten.",
    addReservoir: "Reservoir hinzufügen",
    updateReservoir: "Reservoir aktualisieren",
    deleteReservoir: "Reservoir löschen",
    linkedPoints: "Verknüpfte Endpunkte",
    addNote: "Fügen Sie eine Notiz hinzu oder bearbeiten Sie sie:",
    reservoirText:
      "Behälter können zur Überwachung des Volumenniveaus von automatischen Fettschmiervorrichtungen, Eimern, Fässern und Behältern verwendet werden.",
    deviceNotConnected:
      "Das Gerät hat keine Verbindung zu GreaseCloud hergestellt.",
    lastSeen: "Zuletzt gesehen",
    never: "Niemals",
    requirement: "Anforderung",
    requirements: "Anforderungen",
    requirementsText:
      "Anforderungen definieren das Volumen des erforderlichen Schmierfetts, das von einem Endpunkt abgegeben werden muss.",
    createRequirements: "Anforderungen erstellen",
    requirementName: "Anforderungsname",
    rightAmount: "Richtige Menge",
    rightTime: "Richtige Zeit",
    rightGrease: "Richtiges Schmierfett",
    next: "Nächster",
    autoThreshold: "Auto-Schwellenwert",
    on: "An",
    off: "Aus",
    "Over-greased": "Überfettet",
    "Under-greased": "Unterfettet",
    submit: "Senden",
    autoRange: "Auto-Bereich",
    thresholds: "Schwellenwerte",
    barChart: "Balkendiagramm",
    lineChart: "Liniendiagramm",
    capacity: "Kapazität",
    estimatedVolume: "Geschätztes Volumen",
    averageDailyConsumption: "Durchschnittlicher täglicher Verbrauch",
    projectedDepletionDate: "Voraussichtliches Entleerungsdatum",
    lastReset: "Letzter Reset",
    available: "Verfügbar",
    consumed: "Verbraucht",
    lastResetLevel: "Letzter Reset-Level",
    verifyLastResetDate:
      "vergangen leer. Überprüfen Sie das Datum des letzten Resets des Behälters",
    viewReservoir: "Behälter anzeigen",
    noteReservoir:
      "Hinweis: Alle Berechnungen werden in Kubikzentimetern (cc) durchgeführt.",
    outlets: "Ausgänge",
    inlets: "Eingänge",
    date: "Datum",
    resetLevel: "Reset-Level",
    resetBy: "Zurückgesetzt von(Datum)",
    comments: "Kommentare",
    resetDate: "Reset-Datum",
    resetReservoir: "Behälter zurücksetzen",
    override: "Überschreiben",
    reset: "Zurücksetzen",
    averageDailyConsumptionTooltip:
      "Der durchschnittliche tägliche Verbrauch wird auf Basis der letzten",
    daysOfActivity: "Tage der Aktivität berechnet.",
    requirementSuccessfully: "Anforderung wurde erfolgreich erstellt.",
    requirementDeleted:
      "Die ausgewählte Anforderung wurde erfolgreich gelöscht.",
    requirementDeletedError:
      "Es ist ein Fehler aufgetreten beim Versuch, die ausgewählte Anforderung zu löschen.",
    updateRequirements: "Anforderungen aktualisieren",
    requirementSummary: "Anforderungszusammenfassung",
    linkReservoir: "Behälter verknüpfen",
    noReservoirLinked: "Es wurde kein Behälter verknüpft.",
    addLinkReservoir: "Behälter hinzufügen und verknüpfen",
    unplannedVolumeDispensed: "Ungeplantes abgegebenes Volumen",
    noRequirementAssigned: "Keine Anforderung zugewiesen",
    pleaseAssignRequirement:
      "Bitte weisen Sie eine Anforderung zu, um die Konformität anzuzeigen.",
    reservoirsReport: "Bericht über Stauseen",
    manageAuthTokens: "Authentifizierungstoken verwalten",
    noRequirementWithinTime: "Keine Anforderung innerhalb des Zeitraums",
    noRequirement: "Keine Anforderung",
    estimated: "Voraussichtliches",
    depletionDate: "Entleerungsdatum",
    average: "Durchschnitt",
    perDay: "pro Tag",
    linked: "Verknüpfte",
    futureReservoirRequirements: "Zukünftige Reservoiranforderungen",
    currentReservoirUsage: "Aktuelle Reservoirnutzung",
    currentMonth: "Aktueller Monat",
    nextMonth: "Nächsten Monat",
    nextNextMonth: "2 Monate",
    january: "Januar",
    february: "Februar",
    march: "März",
    april: "April",
    may: "Mai",
    june: "Juni",
    july: "Juli",
    august: "August",
    september: "September",
    october: "Oktober",
    november: "November",
    december: "Dezember",
    alerts: "Benachrichtigungen",
    alert: "Alarm",
    assignedTo: "Zugewiesen an",
    criticality: "Kritikalität",
    type: "Typ",
    enabled: "Aktiviert",
    deleteAlert: "Alarm löschen",
    createAlert: "Alarm erstellen",
    hours: "Stunden",
    weeks: "Wochen",
    months: "Monate",
    successfullyUpdated: "erfolgreich aktualisiert",
    successfullyCreated: "erfolgreich erstellt",
    low: "Niedrig",
    medium: "Mittel",
    high: "Hoch",
    critical: "Kritisch",
    assignTo: "Zuweisen an",
    condition: "Bedingung",
    field: "Feld",
    value: "Wert",
    operator: "Operator",
    unit: "Einheit",
    notifications: "Benachrichtigungen",
    notifyUsers: "Folgende Benutzer benachrichtigen",
    emails: "E-Mails",
    message: "Nachricht",
    updateAlert: "Alarm aktualisieren",
    showAllNotifications: "Alle Benachrichtigungen anzeigen",
    result: "Ergebnis",
    emailSent: "E-Mail gesendet",
    acknowledged: "Bestätigt",
    noDataDepletion:
      "Keine Daten – Zur Berechnung der Erschöpfung sind mindestens 3 Tage erforderlich",
    equalTo: "Gleich",
    notEqualTo: "Nicht gleich",
    greaterThan: "Größer als",
    greaterThanOrEqualTo: "Größer oder gleich",
    lessThan: "Kleiner als",
    lessThanOrEqualTo: "Kleiner oder gleich",
    alertSuccessfullydeleted: "Die ausgewählte Warnung wurde erfolgreich gelöscht.",
    errorDeletingAlert: "Beim Versuch, die ausgewählte Warnung zu löschen, ist ein Fehler aufgetreten.",
    flowEvents: "Durchflussereignisse",
    pressureEvents: "Druckereignisse",
    singleOrMultipleChart: "Einzel- oder Mehrfachdiagramm",
    pressureMean: "Mittlerer Druck",
    preassureMinMax: "Druck Min/Max",
    pressureTemperature: "Druck Temperatur",
    max: "Max",
    min: "Min",
    mean: "Durchschnitt",
    pressureMin: "Druck Min",
    pressureMax: "Druck Max",
    pressure: "Druck",
    flow: "Fließen",
    diagnostic: "Diagnose",
    heartbeat: "Schlagen",
    endpointsEvents: "Endpunktereignisse",
    pointsEvents: "Punktereignisse",
    resetHistory: "Verlauf zurücksetzen",
    triggered: "Ausgelöst",
    exportToExcel: "Nach Excel herunterladen",
    reservoir: "Reservoir",
    endpoint: "Endpunkt",
    noTagsAssigned: "Diesem Konto sind keine Grease-Tags zugewiesen.",
    uploadCsv: "CSV-Datei hochladen",
    uploadEndpoints: "Endpunkte hochladen",
    uploadCSVText: "CSV-Datei hochladen:",
    online: "Online",
    offline: "Offline",
    offlinePeriod: "Offline-Zeitraum",
    eventType: "Ereignistyp",
    comment: "Kommentar",
    reasonCode: "Grundcode",
    addOnlineOffline: "Online-/Offline-Ereignis hinzufügen",
    manageOperationalState: "Betriebszustand verwalten",
    maintenancePlanned: "Wartung (Geplant)",
    maintenanceUnplanned: "Wartung (Ungeplant)",
    operationalShutdownPlanned: "Betriebsschließung (Geplant)",
    operationalShutdownUnplanned: "Betriebsschließung (Ungeplant)",
    other: "Andere",
    dashboard: "Dashboard",
    automaticCompliance: "Automatische Einhaltung",
    manualCompliance: "Manuelle Einhaltung",
    inLast7Days: "in den letzten 7 Tagen",
    noDataAvailable: "Keine Daten verfügbar.",
    seeAll: "Alle anzeigen",
    withoutRequirement: "Ohne Anforderung",
    tags: "Tags",
    unmapped: "Nicht zugeordnet",
    withoutSchedule: "Ohne Zeitplan",
    nonCompliant: "Nicht konform",
    settingsNotConfigured: "Einstellungen sind für dieses Konto nicht konfiguriert.",
    contactSupport: "Bitte kontaktieren Sie den Support über den unten stehenden Link:",
    noEndpointDeviceAssigned: "Kein Endgerät zugewiesen",
    noEventsToDetermine: "Keine Ereignisse zur Bestimmung des letzten Auftretens vorhanden",
    minFlowRate: "Mindestflussrate (L/min)",
    avgFlowRate: "Durchschnittliche Flussrate (L/min)",
    maxFlowRate: "Maximale Flussrate (L/min)",
    windowSize: "Fenstergröße (s)",
    samplePeriod: "Abtastperiode (s)",
    volumeInL: "Volumen (L)",
    minMaxFlowRate: "Min./Max. Flussrate (L/min)",
    flowRate: "Flussrate",
    copyToken: "Bitte kopieren und bewahren Sie diesen Token auf. Sie werden es nicht mehr sehen können.",
    tokenCreated: "Authentifizierungstoken erstellt",
    latitude: "Breitengrad",
    longitude: "Längengrad",
    altitude: "Höhe",
    accuracy: "Genauigkeit",
    speed: "Geschwindigkeit",
    speedAccuracy: "Geschwindigkeitsgenauigkeit",
    heading: "Richtung",
    gps: "GPS",
    gpsEvents: "GPS-Ereignisse",
    tasks: "Aufgaben",
    viewTasksText: "Aufgaben innerhalb Ihres GreaseCloud-Netzwerks anzeigen",
    showAllTasksFrom: "Alle Aufgaben von anzeigen",
    pendingTasks: "Bericht über ausstehende Aufgaben",
    untrackedGreasing: "UNERFASSTES SCHMIEREN",

  },

  grid: {
    groupPanelEmpty:
      "Ziehen Sie einen Spaltentitel hierher um nach dieser Spalte zu gruppieren",
    pagerItemsPerPage: "Einträge pro Seite",
    pagerInfo: "{0} - {1} von {2} Einträgen",
    pagerFirstPage: "Zur ersten Seite",
    pagerPreviousPage: "Seite zurück",
    pagerNextPage: "Nächste Seite",
    pagerLastPage: "Zur letzten Seite",
    pagerPage: "Seite",
    pagerOf: "von",
    filterClearButton: "Löschen",
    filterEqOperator: "Ist gleich",
    filterNotEqOperator: "Ist nicht gleich",
    filterIsNullOperator: "Ist leer",
    filterIsNotNullOperator: "Ist nicht leer",
    filterIsEmptyOperator: "Ist leer",
    filterIsNotEmptyOperator: "Ist nicht leer",
    filterStartsWithOperator: "Beginnt mit",
    filterContainsOperator: "Enthält",
    filterNotContainsOperator: "Enthält nicht",
    filterEndsWithOperator: "Endet mit",
    filterGteOperator: "Größer oder gleich",
    filterGtOperator: "Größer als",
    filterLteOperator: "Kleiner oder gleich",
    filterLtOperator: "Kleiner als",
    filterIsTrue: "Wahr",
    filterIsFalse: "Falsch",
    filterBooleanAll: "(Alle)",
    filterAfterOrEqualOperator: "Danach oder gleich",
    filterAfterOperator: "Danach",
    filterBeforeOperator: "Davor",
    filterBeforeOrEqualOperator: "Davor oder gleich",
    noRecords: "Keine Einträge vorhanden.",
    lastEvaluated: "Zuletzt bewertet",
    lastNotified: "Zuletzt benachrichtigt",
    lastTriggered: "Zuletzt ausgelöst",
    triggeredBy: "Ausgelöst durch",
  },
  filter: {
    eqOperator: "Ist gleich zu",
    notEqOperator: "Ist ungleich zu",
    isNullOperator: "Ist null",
    isNotNullOperator: "Ist nicht null",
    isEmptyOperator: "Ist leer",
    isNotEmptyOperator: "Ist nicht leer",
    startsWithOperator: "Beginnt mit",
    containsOperator: "Enthält",
    notContainsOperator: "Enthält nicht",
    endsWithOperator: "Endet mit",
    gteOperator: "Ist gleich oder größer wie",
    gtOperator: "Ist größer wie",
    lteOperator: "Ist gleich oder kleiner wie",
    ltOperator: "Ist kleiner wie",
    isTrue: "Ist wahr",
    isFalse: "Ist falsch",
    afterOrEqualOperator: "Ist gleich zu oder nach",
    afterOperator: "Ist nach",
    beforeOperator: "Ist vor",
    beforeOrEqualOperator: "Ist gleich zu oder vor",
    andLogic: "Und",
    orLogic: "Oder",
    addExpression: "Ausdruck hinzufügen",
    addGroup: "Gruppe hinzufügen",
    close: "Schließen",
  },
  dateinput: {
    increment: "Wert erhöhen",
    decrement: "Wert verringern",
  },
  calendar: {
    today: "Heute",
  },
  datepicker: {
    toggleCalendar: "Kalender ein-/ausblenden",
  },
  daterangepicker: {
    swapStartEnd: "Start- und Enddatum tauschen",
    start: "Start",
    end: "Ende",
    separator: " ",
  },
  datetimepicker: {
    date: "Datum",
    time: "Uhrzeit",
    toggleDateTimeSelector: "Datum/Uhrzeit-Auswahl anzeigen/verstecken",
    cancel: "Abbrechen",
    set: "Übernehmen",
  },
  editor: {
    bold: "Fett",
    italic: "Kursiv",
    underline: "Unterstreichen",
    strikethrough: "Durchstreichen",
    subscript: "Tiefgestellt",
    superscript: "Hochgestellt",
    unlink: "Link entfernen",
    undo: "Rückgängig",
    redo: "Wiederholen",
    fontSize: "Schriftgröße",
    fontName: "Schriftart",
    format: "Format",
    alignLeft: "Linksbündig",
    alignRight: "Rechtsbündig",
    alignCenter: "Zentriert",
    alignJustify: "Blocksatz",
    indent: "Einzug vergrößern",
    outdent: "Einzug verkleinern",
    orderedList: "Nummerierung",
    bulletList: "Aufzählungszeichen",
    createTable: "Tabelle hinzufügen",
    insertTableHint: "{0} x {1} Tabelle erzeugen",
    addRowBefore: "Zeile oberhalb einfügen",
    addRowAfter: "Zeile unterhalb einfügen",
    addColumnBefore: "Spalte davor einfügen",
    addColumnAfter: "Spalte danach einfügen",
    deleteRow: "Zeile löschen",
    deleteColumn: "Spalte löschen",
    deleteTable: "Tabelle löschen",
    mergeCells: "Zellen verbinden",
    splitCell: "Zellen teilen",
    hyperlink: "Link einfügen",
    "hyperlink-dialog-title": "Link einfügen",
    "hyperlink-dialog-content-address": "Webadresse",
    "hyperlink-dialog-content-title": "Titel",
    "hyperlink-dialog-content-newwindow": "Link in neuem Fenster öffnen",
    "hyperlink-dialog-cancel": "Abbrechen",
    "hyperlink-dialog-insert": "Einfügen",
    image: "Bild einfügen",
    "image-dialog-title": "Bild einfügen",
    "image-address": "Webadresse",
    "image-title": "Titel",
    "image-altText": "Alternativtext",
    "image-width": "Breite (px)",
    "image-height": "Höhe (px)",
    "image-cancel": "Abbrechen",
    "image-insert": "Einfügen",
    viewHtml: "HTML anzeigen",
    "viewHtml-dialog-title": "HTML anzeigen",
    "viewHtml-cancel": "Abbrechen",
    "viewHtml-update": "Aktualisieren",
  },
  timepicker: {
    now: "JETZT",
    selectNow: "Aktuelle Uhrzeit auswählen",
    cancel: "Abbrechen",
    set: "Übernehmen",
    toggleTimeSelector: "Uhrzeit-Auswahl anzeigen/verstecken",
    toggleClock: "Uhr umschalten",
  },
  dropdowns: {
    nodata: "Keine Daten vorhanden",
    clear: "Löschen",
  },
  numerictextbox: {
    increment: "Wert erhöhen",
    decrement: "Wert verringern",
  },
  upload: {
    cancel: "Abbrechen",
    clearSelectedFiles: "Löschen",
    dropFilesHere: "Dateien zum Hochladen hier ablegen",
    headerStatusUploaded: "Abgeschlossen",
    headerStatusUploading: "Hochladen...",
    invalidFileExtension: "Dateityp nicht erlaubt.",
    invalidFiles:
      "Ungültige Datei(en). Bitte prüfen Sie die Voraussetzungen zum Hochladen.",
    invalidMaxFileSize: "Dateigröße überschritten.",
    invalidMinFileSize: "Dateigröße unterschritten.",
    remove: "Entfernen",
    retry: "Wiederholen",
    select: "Dateien auswählen...",
    uploadSelectedFiles: "Hochladen",
    total: "Gesamt",
    files: "Dateien",
  },
  sortable: {
    noData: "Keine Daten",
  },
  pager: {
    itemsPerPage: "Einträge pro Seite",
    info: "{0} - {1} von {2} Einträgen",
    firstPage: "Zur ersten Seite",
    previousPage: "Zur vorherigen Seite",
    nextPage: "Zur nächsten Seite",
    lastPage: "Zur letzten Seite",
    page: "Seite",
    of: "von",
  },
  treelist: {
    filterClearButton: "Zurücksetzen",
    filterEqOperator: "Ist gleich zu",
    filterNotEqOperator: "Ist ungleich zu",
    filterIsNullOperator: "Ist null",
    filterIsNotNullOperator: "Ist nicht null",
    filterIsEmptyOperator: "Ist leer",
    filterIsNotEmptyOperator: "Ist nicht leer",
    filterStartsWithOperator: "Beginnt mit",
    filterContainsOperator: "Enthält",
    filterNotContainsOperator: "Enthält nicht",
    filterEndsWithOperator: "Endet mit",
    filterGteOperator: "Ist gleich oder größer wie",
    filterGtOperator: "Ist größer wie",
    filterLteOperator: "Ist kleiner oder gleich wie",
    filterLtOperator: "Ist kleiner wie",
    filterIsTrue: "Ist wahr",
    filterIsFalse: "Ist falsch",
    filterBooleanAll: "(Alle)",
    filterAfterOrEqualOperator: "Ist gleich zu oder nach",
    filterAfterOperator: "Ist nach",
    filterBeforeOperator: "Ist vor",
    filterBeforeOrEqualOperator: "Ist gleich zu oder vor",
    noRecords: "Keine Einträge vorhanden",
  },
};
