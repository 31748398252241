import { ReactElement, useMemo, } from "react";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../../messages/en-US";
import PointEventsTableAssets from "../../Components/Points/PointEventsTableAssets";
import EventReadTableDto from "../../../../Models/Event/EventReadTableDto";
import { useQuery } from "react-query";
import EventReadDto from "../../../../Models/Event/EventReadDto";
import { ReactQueryKeys } from "../../../../Constants/ClientRoutingConstants";
import eventApiService, { EventFilter } from "../../../Events/Utilities/EventsApiService";
import { PointReadDto } from "../../../../Models/Point/PointReadDto";
import { MachineReadDto } from "../../../../Models/Machine/MachinesReadDto";
import AreaReadDto from "../../../../Models/Area/AreaReadDto";
import { ConsumableReadDto } from "../../../../Models/Consumable/ConsumableReadDto";
import { ScheduleReadDto } from "../../../../Models/Schedule/ScheduleReadDto";
import { DeviceReadDto } from "../../../../Models/Device/DeviceReadDto";
import { TaskReadDto } from "../../../../Models/Task/TaskReadDto";
import tasksApiService from "../../../Tasks/Utilities/TasksApiService";
import devicesApiService from "../../../Devices/Utilities/DevicesApiService";
import consumablesApiService from "../../../Consumables/Utilities/ConsumablesApiService";
import areasApiService from "../../../Area/Utilities/AreasApiService";
import machinesApiService from "../../../Machines/Utilities/MachinesApiService";
import pointsApiService from "../../../Points/Utilities/PointsApiService";
import scheduleApiService from "../../../Schedules/Utilities/ScheduleApiService";
import LoadingSpinner from "../../../Shared/Components/LoadingSpinner";
import ErrorCard from "../../../Shared/Components/Cards/ErrorCard";
import NoDataPage from "../NoDataPage";

interface Props {
  points: string[];
  eventFilter: EventFilter;
}

export default function AllPointsActivityTabPage(props: Props): ReactElement {
  const localizationService = useLocalization();

    //QueriesForPage
  const eventsQuery = useQuery<Array<EventReadDto>>([ReactQueryKeys.AllEventsQuery, props.eventFilter], async () => {
    return eventApiService.getAllPointsEvents(props.eventFilter);
  });

  const pointsQuery = useQuery<Array<PointReadDto>>(ReactQueryKeys.AllPointsQuery, async () => {
    return pointsApiService.getAllPoints();
  });

  const machinesQuery = useQuery<Array<MachineReadDto>>(ReactQueryKeys.AllMachinesQuery, async () => {
    return machinesApiService.getAllMachines();
  });

  const areasQuery = useQuery<Array<AreaReadDto>>(ReactQueryKeys.AllAreasQuery, async () => {
    return areasApiService.getAllAreas();
  });

  const consumablesQuery = useQuery<Array<ConsumableReadDto>>(ReactQueryKeys.AllConsumablesQuery, async () => {
    return consumablesApiService.getAllConsumables();
  });

  const devicesQuery = useQuery<Array<DeviceReadDto>>(ReactQueryKeys.AllDevicesQuery, async () => {
    return devicesApiService.getAllDevices();
  });

  const schedulesQuery = useQuery<Array<ScheduleReadDto>>(ReactQueryKeys.AllSchedulesQuery, async () => {
    return scheduleApiService.getAllSchedules();
  });

  const tasksQuery = useQuery<Array<TaskReadDto>>(ReactQueryKeys.AllTasksQuery, async () => {
    return tasksApiService.getAllTasks();
  });
  
  const eventData = useMemo(() => {
    //Create hashmap of relevant id -> name pairs.
    const areaIdtoName: { [id: string]: string } = {};
    const consumableIdtoName: { [id: string]: string } = {};
    const machineIdtoName: { [id: string]: string } = {};
    const pointIdtoName: { [id: string]: string } = {};
    const deviceIdtoName: { [id: string]: string } = {};
    const scheduleIdToName: { [id: string]: string } = {};
    const pointIdToTask: { [id: string]: TaskReadDto } = {};
    areasQuery.data?.forEach((area) => {
      areaIdtoName[area.id] = area.name;
    });
    consumablesQuery.data?.forEach((consumable) => {
      consumableIdtoName[consumable.id] = consumable.name;
    });
    machinesQuery.data?.forEach((machine) => {
      machineIdtoName[machine.id] = machine.name;
    });
    pointsQuery.data?.forEach((point) => {
      pointIdtoName[point.id] = point.name;
    });
    devicesQuery.data?.forEach((device) => {
      deviceIdtoName[device.id] = device.serialNumber;
    });
    schedulesQuery.data?.forEach((schedule) => {
      scheduleIdToName[schedule.id] = schedule.name;
    });
    tasksQuery.data?.forEach((task) => {
      pointIdToTask[task.point.id] = task;
    });
    
    //Map over machines to inject relevant data
    const results = eventsQuery.data?.map((event) => {
      return {
        ...event,
        occurred: new Date(event.occurred),
        areaName: areaIdtoName[event.areaId] ?? null,
        consumableName: consumableIdtoName[event.consumableId] ?? null,
        machineName: machineIdtoName[event.machineId] ?? null,
        pointName: pointIdtoName[event.pointId] ?? null,
        serialNumber: deviceIdtoName[event.deviceId] ?? null,
        scheduleId: pointIdToTask[event.pointId]?.scheduleId === null ? "" : pointIdToTask[event.pointId]?.scheduleId,
        scheduleName: scheduleIdToName[pointIdToTask[event.pointId]?.scheduleId] === undefined ? `${localizationService.toLanguageString('custom.noSchedule', enMessages.custom.noSchedule)}` : scheduleIdToName[pointIdToTask[event.pointId]?.scheduleId],
      } as EventReadTableDto;
    });
    

    return results;
  }, [eventsQuery.data, areasQuery.data, consumablesQuery.data, machinesQuery.data, pointsQuery.data, devicesQuery.data, schedulesQuery.data, tasksQuery.data]);

  const selectedPoints = eventData?.map(item => item.pointId).filter(item => props.points.includes(item))
  const eventDataItem = eventData?.filter(item => selectedPoints?.includes(item.pointId))

  const eventDataPoints = useMemo(() => {
    const results = eventDataItem?.map((event) => { 
      const dateTime = event.occurred
        return {
          ...event,
          timestamp: dateTime,
          occurredWithoutTime: new Date(new Date(dateTime).setHours(0,0,0)),
          createdWithoutTime: new Date(new Date(event.created).setHours(0,0,0)),
          volumeDispensedRound: Number(event.volumeDispensed).toFixed(2)
      }

    })
    return results;
  }, [eventDataItem]);

   if (eventsQuery.isLoading || areasQuery.isLoading || pointsQuery.isLoading || machinesQuery.isLoading || consumablesQuery.isLoading || devicesQuery.isLoading ||schedulesQuery.isLoading || tasksQuery.isLoading || eventsQuery.isFetching || areasQuery.isFetching || pointsQuery.isFetching || machinesQuery.isFetching || consumablesQuery.isFetching || devicesQuery.isFetching ||schedulesQuery.isFetching || tasksQuery.isFetching) {
    return <LoadingSpinner />;
  } else {
    if (eventsQuery.error || areasQuery.error || pointsQuery.error || machinesQuery.error || consumablesQuery.error || devicesQuery.error ||schedulesQuery.error || tasksQuery.error ) {
      return <ErrorCard />;
    } else {
  return (
    <div>
      {eventDataPoints?.length === 0 ? 
         <NoDataPage />
         :
         <div className="">
         <PointEventsTableAssets 
           events={eventDataPoints}       
         />
       </div>

      }
     
    </div>
  )
}}}
