import * as yup from "yup";

const today = new Date();

export default yup.object().shape({
  name: yup.string().min(2).max(48).required(),
  description: yup.string().min(2).max(48),
  recurrence:  yup
  .number()
  .positive()
  .min(1)
  .max(20),
  timezone: yup.string(),
  startDate: yup.date().required(),
  endDate: yup.date().nullable(true),

});