import { ReactElement, useMemo } from "react";
import { useQuery, useQueryClient } from "react-query";
import { ReactQueryKeys } from "../../../Constants/ClientRoutingConstants";
import ErrorCard from "../../Shared/Components/Cards/ErrorCard";
import LoadingSpinner from "../../Shared/Components/LoadingSpinner";
import RequirementsTable from "../Components/RequirementsTable";
import requirementApiService from "../Utilities/RequirementApiService";
import { RequirementReadDto } from "../../../Models/Requirement/RequirementReadDto";
import { RequirementReadTableDto } from "../../../Models/Requirement/RequirementReadTableDto";
import { RequirementCreateDto } from "../../../Models/Requirement/RequirementCreateDto";
import { rruleFrequencyToText, rruleRecurrenceToText, rruleSummaryToText } from "../Utilities/RequirementUtilities";
import { ConsumableReadDto } from "../../../Models/Consumable/ConsumableReadDto";
import consumablesApiService from "../../Consumables/Utilities/ConsumablesApiService";
import { useWindowSize } from "usehooks-ts";
import { Dialog } from "@progress/kendo-react-dialogs";
import { useLocalization } from "@progress/kendo-react-intl";
import { enMessages } from "../../../messages/en-US";
import moment from "moment";

interface Props {
  isOpenDialog: any;
  setIsOpenDialog: any;
}

export default function RequirementPage(props: Props): ReactElement {
  //Server side state
  const client = useQueryClient();
  const localizationService = useLocalization();

  //get window size
  const { width, height } = useWindowSize()

  const requirementsQuery = useQuery<Array<RequirementReadDto>>(ReactQueryKeys.AllRequirementQuery, async () => {
    return requirementApiService.getAllRequirements();
  });

    const consumablesQuery = useQuery<Array<ConsumableReadDto>>(ReactQueryKeys.AllConsumablesQuery, async () => {
    return consumablesApiService.getAllConsumables();
  });

  const createRequirement = async (createRequirement: RequirementCreateDto) => {
    const result = await requirementApiService.createRequirement(createRequirement);
    client.refetchQueries(ReactQueryKeys.AllRequirementQuery);
  };


  const updateRequirements = async (patternId: string, updatePattern: RequirementCreateDto) => {
    let response = await requirementApiService.updateRequirement(updatePattern, patternId);
    //If update is successful, refetch the updated list of areas and show success message
    client.refetchQueries(ReactQueryKeys.AllRequirementQuery);
  };

  /**
   * This function prepares data for schedules table, including injecting additional metadata.
   */
  const requirementData = useMemo(() => {
    const consumableIdToName: { [id: string]: string } = {};
    consumablesQuery.data?.forEach((consumable) => {
      consumableIdToName[consumable.id] = consumable.name;
    });
    //Map over areas to inject relevant data, currently requires not extra metadata
    return requirementsQuery.data?.map((pattern) => {
      return {
        ...pattern,
        frequency: rruleFrequencyToText(pattern.requirementDetails.rightTimeDetails.rRule),
        recurrence: rruleRecurrenceToText(pattern.requirementDetails.rightTimeDetails.rRule),
        summary: `${consumableIdToName[pattern.requirementDetails.rightGreaseDetails.consumableId]}, ${pattern.requirementDetails.rightAmountDetails.volumeRequirement} cc, ${rruleSummaryToText(pattern.requirementDetails.rightTimeDetails.rRule)}`,
        consumableName: consumableIdToName[pattern.requirementDetails.rightGreaseDetails.consumableId],
        startDate: moment(pattern.requirementDetails.rightTimeDetails.startDate).format("DD/MM/YYYY"),
      } as RequirementReadTableDto;
    });
  }, [requirementsQuery.data, consumablesQuery.data]);

  //Render the Schedules Table
  const RenderQuery = () => {
    if (requirementsQuery.error || consumablesQuery.error) {
      return <ErrorCard />;
    } else {
      return (
        <div>
          <RequirementsTable
            requirements={requirementData!}
            consumables={consumablesQuery.data!}
            createRequirement={createRequirement}
            updateRequirements={updateRequirements}
            height={690}
          />
          {props.isOpenDialog.requirements && (
            <Dialog
              title={localizationService.toLanguageString('custom.requirements', enMessages.custom.requirements)}
              onClose={() => props.setIsOpenDialog({...props.isOpenDialog, requirements: false})}
            >
              <RequirementsTable
                requirements={requirementData!}
                consumables={consumablesQuery.data!}
                createRequirement={createRequirement}
                updateRequirements={updateRequirements}
                height={height - 200}
              />
            </Dialog>
          )}
        </div>
      );
    }
  };

  return requirementsQuery.isLoading || consumablesQuery.isLoading || requirementsQuery.isFetching || consumablesQuery.isFetching ? <LoadingSpinner /> : <RenderQuery />;
}
