import { ReactElement } from "react";
import { WarningTwoIcon } from "@chakra-ui/icons";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../../messages/en-US";

interface Props {}

export default function ErrorCard(props: Props): ReactElement {
  const localizationService = useLocalization();
  return (
        <div className="px-4 py-5 bg-red-200 shadow rounded-md">
          {/* Form */}
          <div className="bg-red-200 p-6">
            <div className="flex flex-wrap justify-center md:flex-nowrap md:justify-start">
              <WarningTwoIcon w={8} h={8} color="red.500" className="md:col-span-2"/>
              <div className="ml-4 font-bold w-100">
              {localizationService.toLanguageString('custom.errorOccured', enMessages.custom.errorOccured)}
              </div>
            </div>
          </div>

          {/* Description */}
          <div className="justify-start bg-red-200 text-left px-6">
            <p className="mt-1 text-sm">
            {localizationService.toLanguageString('custom.errorPermissions', enMessages.custom.errorPermissions)}
            </p>
          </div>
        </div>
  );
}
