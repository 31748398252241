import React, { Fragment, ReactElement } from "react";
import {ErrorMessage,useFormikContext} from 'formik';
import { objectKeysToLowerCase } from "../../Utilities/ObjectUtilities";

interface Props {
  name: string;
}

/**
 * This component renders an error message for a formik field with a matching name.
 * NOTE: This function is designed to work within a formik context (child of a formik field). If used
 * without access to a formik context it will not work correctly.
 */

export default function TableErrorMessage({ name }: Props): ReactElement {
  //Provides a specialised GB Table Form Field, providing field, and error message support for both server side and client side error messages.
  //Relies on validation error message format matching that provided in grease cloud api specification
  const { status } = useFormikContext();

  const getStatusMessageForName = (status: any, name: string) => {
    //Convert all object keys to lower case
    const lowerCaseName = name.toLowerCase();

    //Attempt to get error message array from status
    if (status != null) {
      const statusLowerCase = objectKeysToLowerCase(status);
      if (Array.isArray(statusLowerCase[lowerCaseName])) {
        return statusLowerCase[lowerCaseName][0];
      }
    }

    return null;
  };

  return (
    <Fragment>
      {/* Client side validation */}
      <ErrorMessage name={name}>
        {(error) => {
          return <div className="text-xs text-red-600">* {error}</div>;
        }}
      </ErrorMessage>
      {/* Server side validation */}
      {getStatusMessageForName(status, name) ? (
        <div className="text-xs text-red-600">*{getStatusMessageForName(status, name)}</div>
      ) : (
        ""
      )}
    </Fragment>
  );
}
