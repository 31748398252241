import { ReactElement, useMemo } from "react";
import { useQuery } from "react-query";
import { UserReadDto } from "../../../Models/Admin/UserReadDto";
import AdministrationUserTable from "../Components/Tables/AdministrationUserTable";
import { ReactQueryKeys } from "../../../Constants/ClientRoutingConstants";
import LoadingSpinner from "../../Shared/Components/LoadingSpinner";
import { OrganisationRole } from "../../../Models/Admin/Role";
import administrationQueryService from "../Utilities/AdministrationAPIService";
import ErrorCard from "../../Shared/Components/Cards/ErrorCard";
import adminApiService from "../Utilities/AdministrationAPIService";
import { useToast } from "@chakra-ui/react";
import useIdentityStore from "../../../Zustand/identityStore";
import { UserReadTableDto } from "../../../Models/Admin/UserReadTableDto";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";
import { useWindowSize } from "usehooks-ts";

interface Props {}

export default function SiteAdministrationUsersPage({}: Props): ReactElement {

  const localizationService = useLocalization();
  const { width, height } = useWindowSize()

  const userProfile = useIdentityStore((x) => x.user?.profile);
  const toast = useToast({
    position: "bottom",
    duration: 5000,
    isClosable: true,
  });

  const usersQuery = useQuery<Array<UserReadDto>>(ReactQueryKeys.AllUsersQuery, async () => {
    return await administrationQueryService.getAllSiteUsers();
  });

  const rolesQuery = useQuery<Array<OrganisationRole>>(ReactQueryKeys.AllRolesQuery, async () => {
    return await administrationQueryService.getAllSiteRoles();
  });

  const inviteUser = async (values: any, setStatus: any) => {
    //Post the form.
    try {
      //Parse details from input into correct format
      const siteRoleMappings = [
        {
          roleId: values.roleId,
          siteId: values.siteId,
        },
      ];

      //Assuming it works, refetch the users
      const result = await administrationQueryService.postUserInviteSite(values.email, siteRoleMappings[0].roleId); //On success, show success toast, then refresh data
      usersQuery.refetch();

      toast({
        status: "success",
        description: `${localizationService.toLanguageString('custom.userInvitation', enMessages.custom.userInvitation)}`,
      });
      return true;
    } catch (e: any) {
      setStatus(e?.response?.data?.errors);
      toast({
        title: `${localizationService.toLanguageString('custom.error', enMessages.custom.error)}`,
        description: `${localizationService.toLanguageString('custom.userInvitationError', enMessages.custom.userInvitationError)}\n`,
        status: "error",
      });
      return false;
    }
  };

  const reinviteUser = async (id: string) => {
    try {
      const result = await administrationQueryService.postUserReinviteSite(id);
      toast({
        title: `${localizationService.toLanguageString('custom.success', enMessages.custom.success)}`,
        description: `${localizationService.toLanguageString('custom.userReinvitation', enMessages.custom.userReinvitation)}.\n`,
        status: "success",
      });
    } catch (e: any) {
      console.log(e);
      toast({
        title: `${localizationService.toLanguageString('custom.error', enMessages.custom.error)}`,
        description: `${localizationService.toLanguageString('custom.userReinvitationError', enMessages.custom.userReinvitationError)}\n`,
        status: "error",
      });
    }
  };

  const deleteUser = async (id: string) => {
    try {
      const result = await administrationQueryService.postUserDeleteSite(id);
      usersQuery.refetch();
      toast({
        title: `${localizationService.toLanguageString('custom.success', enMessages.custom.success)}`,
        description: `${localizationService.toLanguageString('custom.userDeleted', enMessages.custom.userDeleted)}\n`,
        status: "success",
      });
    } catch (e: any) {
      toast({
        title: `${localizationService.toLanguageString('custom.error', enMessages.custom.error)}`,
        description: `${localizationService.toLanguageString('custom.userDeletedError', enMessages.custom.userDeletedError)}\n`,
        status: "error",
      });
    }
  };

  const userData = useMemo(() => {
    //Create hashmap of areaId -> name pairs.
    const roleIdToName: { [id: string]: string } = {};
    rolesQuery.data?.forEach((role) => {
      roleIdToName[role.id] = role.name;
    });

    //Map over machines to inject relevant data
    const results = usersQuery.data?.map((user) => {
      return {
        ...user,
        roleId: user.siteRoleMappings[0].roleId,
        siteId: user.siteRoleMappings[0].siteId,
        siteName: userProfile?.current_site_name,
        roleName: roleIdToName[user.siteRoleMappings[0].roleId],
        siteRoleMappingsWithNames: user.siteRoleMappings.map((x) => {
          return {
            ...x,
            siteName: userProfile?.current_site_name,
            roleName: roleIdToName[x.roleId],
          };
        }),
      } as UserReadTableDto;
    });

    return results;
  }, [rolesQuery.data, usersQuery.data, userProfile?.current_site_name]);

  const RenderQuery = () => {
    if (rolesQuery.error || usersQuery.error) {
      return <ErrorCard />;
    } else {
      return (
        <div className="m-5">
          {/* TODO: Create Page Title Component - take description and title as props */}
          <div>
            <h1 className="gb-h1">{localizationService.toLanguageString('custom.manageUsers', enMessages.custom.manageUsers)}</h1>
            <h2 className="gb-text-normal mb-6">{localizationService.toLanguageString('custom.manageUsersText', enMessages.custom.manageUsersText)}</h2>
            <AdministrationUserTable
              removeUserRole={async (id: string, siteId: string, roleId: string) =>
                await adminApiService.removeUserRoleSite(id, siteId, roleId)
              }
              addUserRole={async (id: string, siteId: string, roleId: string) => await adminApiService.addUserRoleSite(id, siteId, roleId)}
              deleteUser={deleteUser}
              inviteUser={inviteUser}
              reinviteUser={reinviteUser}
              roles={rolesQuery.data ?? []}
              users={userData!}
              height={height - 200}
            />
          </div>
        </div>
      );
    }
  };

  return usersQuery.isLoading || rolesQuery.isLoading || usersQuery.isFetching || rolesQuery.isFetching ? <LoadingSpinner /> : <RenderQuery />;
}
