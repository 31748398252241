import { ReactElement, useRef, useState, } from "react";
import { useLocalization } from '@progress/kendo-react-intl';
import { StatusChart } from "../../../Components/Charts/Status/StatusChart";
import { IInitResult, SciChartGroup } from "scichart-react";
import { ModifierGroup } from "../../../../Shared/SciChart/ModifierGroup";
import { ChartModifierBase2D, ISciChartSurfaceBase, SciChartVerticalGroup } from "scichart";
import { synchronizeXVisibleRanges } from "../../../../Shared/SciChart/ChartConfiguration";
import { DiagnosticsChart } from "../../../Components/Charts/Diagnostics/DiagnosticsChart";
import ChartWaitLoader from "../../../../Shared/Components/ChartWaitLoader";
import useChartStore from "../../../../../Zustand/chartStore";
import { GetVisibleRangeDiagnosticSeries } from "../../../../../Utilities/ChartUtilities";
import moment from "moment";


interface Props {
  statusChartData: any;
  diagnosticsDataChart: any;
}

export default function EndpointStatusDiagnostics(props: Props): ReactElement {
  const localizationService = useLocalization();

  const [modifierGroup] = useState(new ModifierGroup());
  const verticalGroup = new SciChartVerticalGroup();
  const [isChartsInitialised, setIsChartsInitialised] = useState(false);

// Fetch the initial state, but we don't need to re-render when it changes as the chart will update itself
  const visibleRangeRef = useRef(useChartStore.getState().visibleRange);
  
  let visibleRange = GetVisibleRangeDiagnosticSeries(
    visibleRangeRef.current,
    props.diagnosticsDataChart.timestampsDiagnostics.length > 0 ? props.diagnosticsDataChart.timestampsDiagnostics[props.diagnosticsDataChart.timestampsDiagnostics.length - 1] : 0,
    props.diagnosticsDataChart.timestampsMetrics.length > 0 ? props.diagnosticsDataChart.timestampsMetrics[props.diagnosticsDataChart.timestampsMetrics.length - 1] : 0,
    props.diagnosticsDataChart.timestamps.length > 0 ? props.diagnosticsDataChart.timestamps[props.diagnosticsDataChart.timestamps.length - 1] : 0,
    moment(props.diagnosticsDataChart.dateFilter.startDate).unix(), 
    moment(props.diagnosticsDataChart.dateFilter.endDate).unix());

  const statusChartData = {...props.statusChartData, ...{modifierGroup: modifierGroup}, visibleRange};
  const diagnosticsDataChart = {...props.diagnosticsDataChart, ...{modifierGroup: modifierGroup}, visibleRange};

  const configureStatusAndDiagnosticsDataBindings = (initResults: IInitResult<ISciChartSurfaceBase>[] | [any, any]) => {
    const [statusChart, diagnosticsChart] = initResults;
    
    modifierGroup.clear();

    var statusRollOverModifier = statusChart.sciChartSurface.chartModifiers.getById("StatusRollOver");
    modifierGroup.add(statusRollOverModifier as ChartModifierBase2D);

    const diagnosticsModifierGroupModifier = diagnosticsChart.sciChartSurface.chartModifiers.getById("DiagnosticsModifierGroup");
    const diagnosticsRollOverModifier = diagnosticsChart.sciChartSurface.chartModifiers.getById("DiagnosticsRollOver");
    modifierGroup.add(diagnosticsModifierGroupModifier as ChartModifierBase2D, diagnosticsRollOverModifier as ChartModifierBase2D);

    synchronizeXVisibleRanges([statusChart.sciChartSurface, diagnosticsChart.sciChartSurface], () => true);

    verticalGroup.addSurfaceToGroup(statusChart.sciChartSurface);
    verticalGroup.addSurfaceToGroup(diagnosticsChart.sciChartSurface);

  };

  return (
    <>
      <SciChartGroup
        onInit={(initResults: IInitResult[]) => {
          configureStatusAndDiagnosticsDataBindings(initResults);
          setIsChartsInitialised(true);
        }}
      >
        {isChartsInitialised ? null : <ChartWaitLoader />}
        <StatusChart key={"status-chart-diagnostics"} chartData={statusChartData}/>
        <DiagnosticsChart key={"diagnostics-chart"} chartData={diagnosticsDataChart}/>

        <div className="flex flex-wrap">
          <div id="legend-diagnostics"></div>
          <div id="legend-status" ></div>
        </div>
      </SciChartGroup>
    </>
  )
}