import { useLocalization } from "@progress/kendo-react-intl";
import { enMessages } from "../../../messages/en-US";
import { Button } from "@progress/kendo-react-buttons";
import { AutoComplianceChart } from "./AutoComplianceChart";
import { useNavigate } from "react-router-dom";
import { ClientRoutes, ReactQueryKeys } from "../../../Constants/ClientRoutingConstants";
import moment from "moment";
import { useQuery } from "react-query";
import dashboardApiService from "../Utilities/DashboardApiService";
import AutoComplianceReadDto from "../../../Models/Dashboard/AutoComplianceReadDto";
import NoDataAvailablePage from "../Utilities/NoDataAvailablePage";
import { Loader } from "@progress/kendo-react-indicators";
import { useAccountSettingsStore } from "../../../Zustand/accountSettingsStore";

export default function AutoComplianceTile( ) {
  const localizationService = useLocalization();
  let navigate = useNavigate(); 

  const accountTimezone = useAccountSettingsStore(state => state.accountTimezone);

  const startDate = moment().subtract(30, 'days');
  const endDate = moment().subtract(1, 'days');

  const dateRangeFormatted = startDate.format('DD/MM/YYYY') + " - " + endDate.format('DD/MM/YYYY')

  //Convert date to Utc using account timezone
  const dateRangeUtc = {
    startDate: moment.tz(startDate.format("YYYY-MM-DDT00:00:00"), accountTimezone).utc(), 
    endDate: moment.tz(endDate.format("YYYY-MM-DDT23:59:59"), accountTimezone).utc()
  };

  const autoComplianceQuery = useQuery<AutoComplianceReadDto>([ReactQueryKeys.DashboardAutoComplianceQuery, dateRangeUtc], async () => {
    return dashboardApiService.getAutoCompliance(dateRangeUtc);
  });

  if(autoComplianceQuery.isLoading || autoComplianceQuery.isFetching) {
    return <Loader size="medium" type={"pulsing"} className="loading-dashboard"/>
  } else if (autoComplianceQuery.isError || autoComplianceQuery.data?.count === 0) {
    return <NoDataAvailablePage title={localizationService.toLanguageString('custom.automaticCompliance', enMessages.custom.automaticCompliance)}  period={dateRangeFormatted} clientRoute={ClientRoutes.EndpointReportPage}/>
  } else {
  return (
    <div className="m-2">
      <div className="flex justify-between align-middle">
        <div className="text-xl font-bold">{localizationService.toLanguageString('custom.automaticCompliance', enMessages.custom.automaticCompliance)}</div>
        <Button fillMode={"link"} style={{color:"blue", textDecoration: "underline" }} onClick={() => navigate(ClientRoutes.EndpointReportPage)}>{localizationService.toLanguageString('custom.seeAll', enMessages.custom.seeAll)}</Button>
      </div>
        <div>
          <div className="mt-1">
            <div className="ml-1">{dateRangeFormatted}</div>
          </div>
          <div className="w-full ">
            <AutoComplianceChart chartData={{autoComplianceData: autoComplianceQuery.data, localizationService: localizationService}} />
          </div>
        </div>
    </div>
  );
}}
