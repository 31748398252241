import { ReactElement } from "react";
import { useQuery, useQueryClient } from "react-query";
import { ReactQueryKeys } from "../../../Constants/ClientRoutingConstants";
import { useToast } from "@chakra-ui/react";
import greaseReservoirApiService from "../Utilities/GreaseReservoirApiService";
import ErrorCard from "../../Shared/Components/Cards/ErrorCard";
import LoadingSpinner from "../../Shared/Components/LoadingSpinner";
import { ReservoirCreateDto } from "../../../Models/Reservoirs/ReservoirCreateDto";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";
import ReservoirsTable from "../Components/ReservoirsTable";
import { ConsumableReadDto } from "../../../Models/Consumable/ConsumableReadDto";
import consumablesApiService from "../../Consumables/Utilities/ConsumablesApiService";
import { PointReadDto } from "../../../Models/Point/PointReadDto";
import pointsApiService from "../../Points/Utilities/PointsApiService";
import EndpointReadDto from "../../../Models/Endpoint/EndpointReadDto";
import endpointsApiService from "../../Endpoints/Utilities/EndpointsApiService";
import greaseReservoirsApiService from "../Utilities/GreaseReservoirApiService";
import { ReservoirReadDto } from "../../../Models/Reservoirs/ReservoirReadDto";
import { ReservoirUpdateDto } from "../../../Models/Reservoirs/ReservoirUpdateDto";
import { useWindowSize } from "usehooks-ts";
import { Dialog } from "@progress/kendo-react-dialogs";

interface Props {
  isOpenDialog: any;
  setIsOpenDialog: any;
}

export default function ReservoirsPage(props: Props): ReactElement {
  //Server side state
  const client = useQueryClient();
  const toast = useToast({
    position: "bottom",
    duration: 5000,
    isClosable: true,
  });

  const localizationService = useLocalization();

  //get window size
  const { width, height } = useWindowSize()

  const greaseReservoirsQuery = useQuery<Array<ReservoirReadDto>>(ReactQueryKeys.AllGreaseReservoirsQuery, async () => {
    return greaseReservoirsApiService.getAllReservoirs();
  });

  const consumablesQuery = useQuery<Array<ConsumableReadDto>>(ReactQueryKeys.AllConsumablesQuery, async () => {
    return consumablesApiService.getAllConsumables();
  });

  const pointsQuery = useQuery<Array<PointReadDto>>(ReactQueryKeys.AllPointsQuery, async () => {
    return pointsApiService.getAllPoints();
  });

  const endpointsQuery = useQuery<Array<EndpointReadDto>>(ReactQueryKeys.AllEndpointsQuery, async () => {
    return endpointsApiService.getAllEndpoints();
  });

  const createReservoir = async (reservoirCreateDto: ReservoirCreateDto) => {
    const result = await greaseReservoirApiService.createReservoir(reservoirCreateDto);
    client.refetchQueries(ReactQueryKeys.AllGreaseReservoirsQuery);
  };

  const deleteReservoir = async (reservoirId: string) => {
    try {
      let response = await greaseReservoirApiService.deleteReservoir(reservoirId);

      //If delete is successful, refetch the updated list of devices and show success message
      client.refetchQueries(ReactQueryKeys.AllGreaseReservoirsQuery);

      toast({
        status: "success",
        description: `${localizationService.toLanguageString('custom.reservoirDeleted', enMessages.custom.reservoirDeleted)}`,
      });
    } catch (e) {
      toast({
        status: "error",
        description: `${localizationService.toLanguageString('custom.reservoirDeletedError', enMessages.custom.reservoirDeletedError)}`,
      });
    }
  };

  const updateReservoir = async (reservoirId: string, updateReservoir: ReservoirUpdateDto) => {
    let response = await greaseReservoirApiService.updateReservoir(updateReservoir, reservoirId);
    //If update is successful, refetch the updated list of areas and show success message
    client.refetchQueries(ReactQueryKeys.AllGreaseReservoirsQuery);
  };

  //Render the Reservoir Table
  const RenderQuery = () => {
    if (greaseReservoirsQuery.error, consumablesQuery.error, pointsQuery.error) {
      return <ErrorCard />;
    } else {
      return (
        <div>
          {/* Reservoir Table */}
          <ReservoirsTable
            reservoirs={greaseReservoirsQuery.data!}
            deleteReservoir={deleteReservoir}
            createReservoir={createReservoir}
            updateReservoir={updateReservoir} 
            consumables={consumablesQuery.data!}
            points={pointsQuery.data!}
            endpoints={endpointsQuery.data!}
            handleSubmit={undefined} initialSort={undefined} initialSortDescriptor={undefined} extraInlineActions={null}
            height={690}
          />

          {props.isOpenDialog.reservoirs && (
            <Dialog
              title={localizationService.toLanguageString('custom.reservoirs', enMessages.custom.reservoirs)}
              onClose={() => props.setIsOpenDialog({...props.isOpenDialog, reservoirs: false})}
            >
              <ReservoirsTable
                reservoirs={greaseReservoirsQuery.data!}
                deleteReservoir={deleteReservoir}
                createReservoir={createReservoir}
                updateReservoir={updateReservoir} 
                consumables={consumablesQuery.data!}
                points={pointsQuery.data!}
                endpoints={endpointsQuery.data!}
                handleSubmit={undefined} initialSort={undefined} initialSortDescriptor={undefined} extraInlineActions={null}
                height={height - 200}
              />
            </Dialog>
          )}
        </div>
      );
    }
  };

  return greaseReservoirsQuery.isLoading || consumablesQuery.isLoading || pointsQuery.isLoading || endpointsQuery.isLoading || greaseReservoirsQuery.isFetching || consumablesQuery.isFetching || pointsQuery.isFetching || endpointsQuery.isFetching ? <LoadingSpinner /> : <RenderQuery />;
}
