export const BREAKPOINTS = {
    mobile: 0,
    tablet: 768,
    desktop: 1280,
  };
  
  export const GRID_CONFIG = {
    mobile: {
      outerGrid: {
        cols: [
          {
            width: "1fr",
          },
        ],
        rows: [
          {
            width: "repeat(2, 1fr)",
          },
        ],
      },
      mainContainer: {
        row: 1,
        col: 1,
      },
      supportCardContainer: {
        cols: [
          {
            width: "1fr",
          },
        ],
      },
      supportCard: {
        divider: 1,
      },
      supportCardGap: {
        row: "20px",
        col: "20px",
      }
    },
    tablet: {
      outerGrid: {
        cols: [
          {
            width: "100%",
          },
        ],
        rows: [
          {
            width: "repeat(2, 1fr)",
          },
        ],
      },
      mainContainer: {
        row: 1,
        col: 1,
      },
      supportCardContainer: {
        cols: [
          {
            width: "1fr 1fr",
          },
        ],
      },
      supportCard: {
        divider: 2,
      },
      supportCardGap: {
        row: "20px",
        col: "20px",
      }
    },
    desktop: {
      outerGrid: {
        cols: [
          {
            width: "100%",
          },
        ],
        rows: [],
      },
      mainContainer: {
        row: 1,
        col: 1,
      },
      supportCardContainer: {
        cols: [
          {
            width: "repeat(2, 1fr)",
          },
        ],
      },
      supportCard: {
        divider: 2,
      },
      supportCardGap: {
        row: "80px",
        col: "20px",
      }
    },
  };