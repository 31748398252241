import { useLocalization } from "@progress/kendo-react-intl";
import LoadingSpinner from "../../Shared/Components/LoadingSpinner";
import authorizeService from "../../Identity/Utilities/AuthorizeService";
import { useEffect, useState } from "react";


export default function ComplianceReportJobsPage() {
  const localizationService = useLocalization();
  const urlBase = process.env.REACT_APP_HANGFIRE_COMPLIANCE_REPORT_URL

  const [token, setToken] = useState(''); 

  useEffect(() => {
    async function getToken() {
      const user = await authorizeService.getUser();
      setToken(user.access_token);
    }

    getToken();
  }, []);

  if (!token) {
    return <LoadingSpinner />;
  } else if (urlBase === undefined) {
    return(
      <div className='m-5'>
        <h2 className="gb-h2 mb-5">Compliance Report Jobs</h2>
        <div>
        Compliance Report Jobs Dashboard can only be viewed in Staging or Production.
        </div>
      </div>
      );
  }
  else
  {
  return (
    <div className='m-5'>
      <h2 className="gb-h2 mb-5">Compliance Report Jobs</h2>
      <div>
        <iframe title="ComplianceReportJobs" src={`${urlBase}?jwt=${token}`} width={"100%"} height={750} ></iframe>
      </div>
      
    </div>
    );
}}
