export const locales = [
    {
        locale: 'English',
        localeId: 'en-AU' || 'en-GB'
    },
    {
        locale: 'Spanish',
        localeId: 'es'
    },
    {
        locale: 'Portuguese',
        localeId: 'pt-BR' || 'pt-PT'
    },
    {
        locale: 'German',
        localeId: 'de-DE' || 'de-AT' || 'de-LI' || 'de-LU' || 'de-CH'
    },
    
];