
import { ClientRoutes } from "../../../Constants/ClientRoutingConstants";
import { enMessages } from "../../../messages/en-US";

interface PanelItem {
    id: string;
    title: string;
    icon?: string;
    route: string;
    role: string[];
    className?: string;
    children?: PanelItem[];
}

export function GetSideBarPanelItems(localizationService:any) : PanelItem[] {

    const items: PanelItem[] = [{
    id: "Dashboard", 
    title: localizationService.toLanguageString("custom.dashboard",enMessages.custom.dashboard), 
    icon: "grid",
    route: ClientRoutes.DashboardPage,
    role:[]
    },
    {
      id: "Activity", 
      title: localizationService.toLanguageString("custom.activity",enMessages.custom.activity), 
      icon: "check",
      route: "",
      role: [],
      children: [
        {
          id: "Events", 
          title: localizationService.toLanguageString("custom.events",enMessages.custom.events), 
          route: ClientRoutes.EventsPage,
          role: [],
          className: "pl-4"
        },
        {
          id: "Actions", 
          title: localizationService.toLanguageString("custom.tasks",enMessages.custom.tasks), 
          route: ClientRoutes.ActionsPage,
          role: [],
          className: "pl-4"
        }
      ]
    },
    {
      id: "Reports", 
      title: localizationService.toLanguageString("custom.reports",enMessages.custom.reports), 
      icon: "file-txt",
      route: "",
      role: [],
      children: [
        {
          id: "ComplianceReport", 
          title: localizationService.toLanguageString("custom.complianceReport",enMessages.custom.complianceReport), 
          route: ClientRoutes.ComplianceReportPage,
          role: [],
          className: "pl-4"
        },
        {
          id: "PendingActions", 
          title: localizationService.toLanguageString("custom.pendingTasks",enMessages.custom.pendingTasks), 
          route: ClientRoutes.PendingActionsPage,
          role: [],
          className: "pl-4"
        },
        {
          id: "EndpointReport", 
          title: localizationService.toLanguageString("custom.endpointReport",enMessages.custom.endpointReport), 
          route: ClientRoutes.EndpointReportPage,
          role: ["SuperAdmin","SiteAdmin","Manager"],
          className: "pl-4"
        },
        {
          id: "ReservoirsReport", 
          title: localizationService.toLanguageString("custom.reservoirsReport",enMessages.custom.reservoirsReport), 
          route: ClientRoutes.ReservoirsReportPage,
          role: ["SuperAdmin","SiteAdmin","Manager"],
          className: "pl-4"
        }
      ]
    },
    {
      id: "SiteExplorer", 
      title: localizationService.toLanguageString("custom.siteExplorer",enMessages.custom.siteExplorer), 
      icon: "zoom-in",
      route: ClientRoutes.SiteExplorerPage,
      role:[]
    },
    {
      id: "AccountSetup", 
      title: localizationService.toLanguageString("custom.accountSetup",enMessages.custom.accountSetup), 
      icon: "cog",
      route: "",
      role:[],
      children: [
      {
        id: "Site", 
        title: localizationService.toLanguageString("custom.site",enMessages.custom.site), 
        route: "",
        role: [],
        className: "pl-4 font-bold",
        children: [
          {
            id: "Consumables", 
            title: localizationService.toLanguageString("custom.consumables",enMessages.custom.consumables), 
            route: `${ClientRoutes.SiteSetupPage}#inventory`,
            role: [],
            className: "pl-4 cursor-pointer font-normal",
          },
          {
            id: "Locations", 
            title: localizationService.toLanguageString("custom.locations",enMessages.custom.locations), 
            route: `${ClientRoutes.SiteSetupPage}#areas`,
            role: [],
            className: "pl-4 cursor-pointer font-normal"
          },
          {
            id: "Assets", 
            title: localizationService.toLanguageString("custom.assets",enMessages.custom.assets), 
            route: `${ClientRoutes.SiteSetupPage}#machines`,
            role: [],
            className: "pl-4 cursor-pointer font-normal"
          }
          ,
          {
            id: "Reservoirs", 
            title: localizationService.toLanguageString("custom.reservoirs",enMessages.custom.reservoirs), 
            route: `${ClientRoutes.SiteSetupPage}#reservoir`,
            role: [],
            className: "pl-4 cursor-pointer font-normal"
          }
        ]
      },
      {
        id: "ManualSystem", 
        title: localizationService.toLanguageString("custom.manualSystem",enMessages.custom.manualSystem), 
        route: "",
        role: [],
        className: "pl-4 font-bold",
        children: [
          {
            id: "Schedules", 
            title: localizationService.toLanguageString("custom.schedules",enMessages.custom.schedules), 
            route: `${ClientRoutes.SiteSetupPage}#schedules`,
            role: [],
            className: "pl-4 cursor-pointer font-normal",
            
          },
          {
            id: "GreaseTags", 
            title: localizationService.toLanguageString("custom.greaseTags",enMessages.custom.greaseTags), 
            route: `${ClientRoutes.SiteSetupPage}#greaseTags`,
            role: [],
            className: "pl-4 cursor-pointer font-normal"
          },
          {
            id: "RouteEditor", 
            title: localizationService.toLanguageString("custom.routeEditor",enMessages.custom.routeEditor), 
            route: `${ClientRoutes.SiteSetupPage}#routeEditor`,
            role: [],
            className: "pl-4 cursor-pointer font-normal"
          }
          ,
          {
            id: "Devices", 
            title: localizationService.toLanguageString("custom.devices",enMessages.custom.devices), 
            route: `${ClientRoutes.SiteSetupPage}#devices`,
            role: [],
            className: "pl-4 cursor-pointer font-normal"
          }
        ]
      },
      {
        id: "AutomaticSystem", 
        title: localizationService.toLanguageString("custom.automaticSystem",enMessages.custom.automaticSystem), 
        route: "",
        role: [],
        className: "pl-4 font-bold",
        children: [
          {
            id: "Requirements", 
            title: localizationService.toLanguageString("custom.requirements",enMessages.custom.requirements), 
            route: `${ClientRoutes.SiteSetupPage}#requirements`,
            role: [],
            className: "pl-4 cursor-pointer font-normal",
            
          },
          {
            id: "Endpoints", 
            title: localizationService.toLanguageString("custom.endpoints",enMessages.custom.endpoints), 
            route: `${ClientRoutes.SiteSetupPage}#endpoints`,
            role: [],
            className: "pl-4 cursor-pointer font-normal"
          }
        ]
      }
      ]
    },
    {
      id: "Alerts", 
      title: localizationService.toLanguageString("custom.alerts",enMessages.custom.alerts), 
      icon: "warning",
      route: ClientRoutes.AlertsPage,
      role: [],
      className: ""
    },
    {
      id: "Administration", 
      title: localizationService.toLanguageString("custom.administration",enMessages.custom.administration), 
      icon: "edit",
      route: "",
      role: ["SuperAdmin","SiteAdmin"],
      className: "",
      children: [
        {
          id: "ManageUsersSuperAdmin", 
          title: localizationService.toLanguageString("custom.manageUsers",enMessages.custom.manageUsers), 
          route: ClientRoutes.SuperAdministrationUsersPage,
          role: ["SuperAdmin"],
          className: "pl-4 cursor-pointer font-normal",
          
        },
        {
          id: "ManageUsersSiteAdmin", 
          title: localizationService.toLanguageString("custom.manageUsers",enMessages.custom.manageUsers), 
          route: ClientRoutes.SiteAdministrationUsersPage,
          role: ["SiteAdmin"],
          className: "pl-4 cursor-pointer font-normal",
        },
        {
          id: "ManageDevices", 
          title: localizationService.toLanguageString("custom.manageDevices",enMessages.custom.manageDevices), 
          route: ClientRoutes.SuperAdministrationDevicesPage,
          role: ["SuperAdmin"],
          className: "pl-4 cursor-pointer font-normal"
        },
        {
          id: "ManageEndpoints", 
          title: localizationService.toLanguageString("custom.manageEndpoints",enMessages.custom.manageEndpoints), 
          route: ClientRoutes.SuperAdministrationEndpointsPage,
          role: ["SuperAdmin"],
          className: "pl-4 cursor-pointer font-normal"
        },
        {
          id: "ManageAccounts", 
          title: localizationService.toLanguageString("custom.manageAccounts",enMessages.custom.manageAccounts), 
          route: ClientRoutes.SuperAdministrationSitesPage,
          role: ["SuperAdmin"],
          className: "pl-4 cursor-pointer font-normal"
        }
        ,
        {
          id: "ManageAuthToken", 
          title: localizationService.toLanguageString("custom.manageAuthTokens",enMessages.custom.manageAuthTokens), 
          route: ClientRoutes.AuthToken,
          role: ["SuperAdmin", "SiteAdmin"],
          className: "pl-4 cursor-pointer font-normal"
        }
      ]
    },
    {
      id: "SupportCentre", 
      title: localizationService.toLanguageString("custom.support",enMessages.custom.support), 
      icon: "help",
      route: ClientRoutes.SupportPage,
      role: [],
      className: ""
    },
    {
      id: "System", 
      title: "System", 
      icon: "settings",
      route: "",
      role: ["SuperAdmin"],
      className: "",
      children: [
        {
          id: "AlertJobs", 
          title: "Alert Jobs", 
          route: ClientRoutes.AlertsJobsPage,
          role: ["SuperAdmin"],
          className: "pl-4 cursor-pointer font-normal",
          
        },
        {
          id: "ComplianceReportJobs", 
          title: "Compliance Report Jobs", 
          route: ClientRoutes.ComplianceReportJobsPage,
          role: ["SuperAdmin"],
          className: "pl-4 cursor-pointer font-normal"
        }
      ]
    }
  ];

  return items;

}