import { ReactElement, useMemo } from "react";
import { useQuery, useQueryClient } from "react-query";
import { ReactQueryKeys } from "../../../Constants/ClientRoutingConstants";
import { useToast } from "@chakra-ui/react";
import machinesApiService from "../../Machines/Utilities/MachinesApiService";
import areasApiService from "../../Area/Utilities/AreasApiService";
import consumablesApiService from "../../Consumables/Utilities/ConsumablesApiService";
import ErrorCard from "../../Shared/Components/Cards/ErrorCard";
import LoadingSpinner from "../../Shared/Components/LoadingSpinner";
import AreaReadDto from "../../../Models/Area/AreaReadDto";

import { MachineReadDto } from "../../../Models/Machine/MachinesReadDto";
import { ConsumableReadDto } from "../../../Models/Consumable/ConsumableReadDto";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";
import EndpointTable from "../Components/EndpointTable";
import endpointsApiService from "../Utilities/EndpointsApiService";
import EndpointReadDto from "../../../Models/Endpoint/EndpointReadDto";
import EndpointReadTableDto from "../../../Models/Endpoint/EndpointReadTableDto";
import AdminEndpointReadDto from "../../../Models/Endpoint/AdminEndpointReadDto";
import EndpointUpdateDto from "../../../Models/Endpoint/EndpointUpdateDto";
import { RequirementReadDto } from "../../../Models/Requirement/RequirementReadDto";
import requirementApiService from "../../Requirements/Utilities/RequirementApiService";
import { rruleSummaryToText } from "../../Requirements/Utilities/RequirementUtilities";
import { useWindowSize } from "usehooks-ts";
import { Dialog } from "@progress/kendo-react-dialogs";

interface Props {
  isOpenDialog: any;
  setIsOpenDialog: any;
}

export default function EndpointsPage(props: Props): ReactElement {

  const localizationService = useLocalization();
  //get window size
  const { width, height } = useWindowSize()
 
  const client = useQueryClient();
  const toast = useToast({
    position: "bottom",
    duration: 5000,
    isClosable: true,
  });

  const endpointsQuery = useQuery<Array<EndpointReadDto>>(ReactQueryKeys.AllEndpointsQuery, async () => {
    return endpointsApiService.getAllEndpoints();
  });

  const siteEndpointsQuery = useQuery<Array<AdminEndpointReadDto>>(ReactQueryKeys.ManageEndpointsQuery, async () => {
    return endpointsApiService.getAllSiteEndpointDevices();
  });

  const machinesQuery = useQuery<Array<MachineReadDto>>(ReactQueryKeys.AllMachinesQuery, async () => {
    return machinesApiService.getAllMachines();
  });

  const areasQuery = useQuery<Array<AreaReadDto>>(ReactQueryKeys.AllAreasQuery, async () => {
    return areasApiService.getAllAreas();
  });

  const consumablesQuery = useQuery<Array<ConsumableReadDto>>(ReactQueryKeys.AllConsumablesQuery, async () => {
    return consumablesApiService.getAllConsumables();
  });

  const requirementsQuery = useQuery<Array<RequirementReadDto>>(ReactQueryKeys.AllRequirementQuery, async () => {
    return requirementApiService.getAllRequirements();
  });

  const createEndpoint = async (values: any, setStatus: any) => {
    //Attempt to create the machine
    try {
      values.endpointDeviceSerialNumber = siteEndpointsQuery.data?.find(x => x.id === values.endpointDeviceId)?.serialNumber
      values.consumableId = null
      //Submit values to api
      const result = await endpointsApiService.createEndpoint(values);
      await endpointsQuery.refetch();

      //On success, show success toast, then refresh data
      toast({
        status: "success",
        description: `'${values.name}'${localizationService.toLanguageString('custom.endpointCreated', enMessages.custom.endpointCreated)}`,
      });
      return true;
    } catch (e: any) {
      //On failure show error toast, set status messages
      setStatus(e?.response?.data?.errors);

      toast({
        title: `${localizationService.toLanguageString('custom.error', enMessages.custom.error)}`,
        status: "error",
        description: `${localizationService.toLanguageString('custom.endpointCreatedError', enMessages.custom.endpointCreatedError)}`,
      });
      return false;
    }
  };

  //delete query
  const deleteEndpoints = async (selectedPointsId: Array<string>) => {
    try {
      let response = await endpointsApiService.deleteEndpoints(selectedPointsId);
      //If delete is successful, refetch the updated list of areas and show success message
      client.refetchQueries(ReactQueryKeys.AllEndpointsQuery);
      toast({
        status: "success",
        description: `${localizationService.toLanguageString('custom.endpointDeleted', enMessages.custom.endpointDeleted)}`,
      });
    } catch (e) {
      toast({
        status: "error",
        description: `${localizationService.toLanguageString('custom.endpointDeletedError', enMessages.custom.endpointDeletedError)}`,
      });
    }
  };

  //update Query
  const updateEndpoints = async (endpoints: Array<EndpointUpdateDto>) => {
    try {

      endpoints.forEach(item => {
        let serialNumber = siteEndpointsQuery.data?.find(x => x.id === item.endpointDeviceId)?.serialNumber;
        
        if (serialNumber !== undefined)
        {
          item.endpointDeviceSerialNumber = serialNumber;
        }
      });

      let response;
      if (endpoints.length === 1) {
        response = await endpointsApiService.updateEndpoint(endpoints[0], endpoints[0].id);
      } else if (endpoints.length > 1) {
        response = await endpointsApiService.updateEndpoints(endpoints);
      }
      //If update is successful, refetch the updated list of areas and show success message
      endpointsQuery.refetch();
      toast({
        status: "success",
        description: `${localizationService.toLanguageString('custom.endpointUpdated', enMessages.custom.endpointUpdated)}`,
      });
      return true;
    } catch (e: any) {
      var errorMessage;
      if (endpoints.length === 1) {
        errorMessage = `${localizationService.toLanguageString('custom.endpointUpdatedError', enMessages.custom.endpointUpdatedError)}\n
        ${e?.response?.data?.errors ? Object.values(e?.response?.data?.errors)[0] : ""}`;
      } else {
        errorMessage = `${localizationService.toLanguageString('custom.endpointIncorrectInput', enMessages.custom.endpointIncorrectInput)}\n
          ${e?.response?.data?.map((consumableId: string) => endpoints.find((a) => a.id === consumableId)?.id)}
        `;
      }
      toast({
        status: "error",
        description: errorMessage,
      });
      return false;
    }
  };

  //Collate and flatten data required for points table
  const endpointData = useMemo(() => {
    //Create hashmap of id -> name pairs for foreign references.
    const areaIdtoName: { [id: string]: string } = {};
    const requirementIdtoName: { [id: string]: string } = {};
    const requirementIdtoSummary: { [id: string]: string } = {};
    const requirementIdtoConsumable: { [id: string]: string } = {};
    const requirementIdtoVolume: { [id: string]: string } = {};
    const machineIdToName: { [id: string]: string } = {};
    const consumableIdToName: { [id: string]: string } = {};
    const endpointIdToSerial: { [id: string]: string } = {};
    areasQuery.data?.forEach((area) => {
      areaIdtoName[area.id] = area.name;
    });
    machinesQuery.data?.forEach((machine) => {
      machineIdToName[machine.id] = machine.name;
    });
    consumablesQuery.data?.forEach((consumable) => {
      consumableIdToName[consumable.id] = consumable.name;
    });
    siteEndpointsQuery.data?.forEach((endpoint) => {
      endpointIdToSerial[endpoint.id] = endpoint.serialNumber;
    });
    requirementsQuery.data?.forEach((requirement) => {
      requirementIdtoName[requirement.id] = requirement.name;
      requirementIdtoConsumable[requirement.id] = requirement.requirementDetails.rightGreaseDetails.consumableId;
      requirementIdtoVolume[requirement.id] = requirement.requirementDetails.rightAmountDetails.volumeRequirement.toString();
      requirementIdtoSummary[requirement.id] = rruleSummaryToText(requirement.requirementDetails.rightTimeDetails.rRule);
    });

    //Construct Point Read Table dtos from data
    return endpointsQuery.data?.map((point) => {
      return {
        ...point,
        areaName: areaIdtoName[point.areaId],
        consumableName: consumableIdToName[point.consumableId],
        machineName: machineIdToName[point.machineId],
        endpointDeviceSerialNumber: endpointIdToSerial[point.endpointDeviceId],
        requirementName: requirementIdtoName[point.requirementId] === undefined ? "No Requirement" : requirementIdtoName[point.requirementId],
        requirementSummary: requirementIdtoName[point.requirementId] === undefined ? "" : consumablesQuery.data?.find(x=> x.id === requirementIdtoConsumable[point.requirementId])?.name + ", " + requirementIdtoVolume[point.requirementId] + " cc, "+ requirementIdtoSummary[point.requirementId],
      } as EndpointReadTableDto;
    });
  }, [
    endpointsQuery.data,
    machinesQuery.data,
    areasQuery.data,
    consumablesQuery.data,
    siteEndpointsQuery.data,
    requirementsQuery.data,
  ]);

  if (
    machinesQuery.isLoading ||
    areasQuery.isLoading ||
    endpointsQuery.isLoading ||
    consumablesQuery.isLoading ||
    siteEndpointsQuery.isLoading ||
    requirementsQuery.isLoading || 
    machinesQuery.isFetching ||
    areasQuery.isFetching ||
    endpointsQuery.isFetching ||
    consumablesQuery.isFetching ||
    siteEndpointsQuery.isFetching ||
    requirementsQuery.isFetching
  ) {
    return <LoadingSpinner />;
  } else {
    if (endpointsQuery.error) {
      return <ErrorCard />;
    } else {
      return (
        <div>
          {/* Areas Table */}
          <div className="mt-4">
            <EndpointTable
              endpoints={endpointData!}
              endpointDevices={siteEndpointsQuery.data!}
              requirements={requirementsQuery.data!}
              machines={machinesQuery.data!}
              areas={areasQuery.data!}
              consumables={consumablesQuery.data!}
              deleteEndpoints={deleteEndpoints}
              createEndpoint={createEndpoint}
              updateEndpoints={updateEndpoints}
              initialSortDescriptor={null}
              initialSort={null}
              height={690}
            />
          </div>
          {props.isOpenDialog.endpoints && (
            <Dialog
              title={localizationService.toLanguageString('custom.endpointMapping', enMessages.custom.endpointMapping)}
              onClose={() => props.setIsOpenDialog({...props.isOpenDialog, endpoints: false})}
            >
              <EndpointTable
                endpoints={endpointData!}
                endpointDevices={siteEndpointsQuery.data!}
                requirements={requirementsQuery.data!}
                machines={machinesQuery.data!}
                areas={areasQuery.data!}
                consumables={consumablesQuery.data!}
                deleteEndpoints={deleteEndpoints}
                createEndpoint={createEndpoint}
                updateEndpoints={updateEndpoints}
                initialSortDescriptor={null}
                initialSort={null}
                height={height - 200}
              />
            </Dialog>
            )}
        </div>
      );
    }
  }
}
