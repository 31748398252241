import { ComboBoxItem } from "./../../Shared/Components/Forms/ComboBox";
import { formatTimezone } from "../../Shared/Utilities/index";
import * as moment from "moment-timezone";
import AreaReadDto from "../../../Models/Area/AreaReadDto";

//Move to area utility
export const timezonesToComboboxItems = () => {
  return moment.tz.names().map((timezone) => {
    return {
      displayName: formatTimezone(timezone),
      value: timezone,
    };
  }) as Array<ComboBoxItem>;
};

export const timezoneToComboboxItem = (timezone: string) => {
  return {
    displayName: formatTimezone(timezone),
    value: timezone,
  } as ComboBoxItem;
};

//Move to utility function ?
export const areasToComboboxItems = (existingAreas: Array<AreaReadDto>) => {
  return existingAreas
    ?.filter((data) => {
      return data.id!;
    })
    .map((area) => {
      return {
        displayName: area.name!,
        value: area.id!,
      };
    }) as Array<ComboBoxItem>;
};

//Move to utility function ?
export const areaToComboboxItem = (areaId: string, existingAreas: Array<AreaReadDto>) => {
  return areaId != null
    ? ({
        displayName: existingAreas?.find((a) => a?.id === areaId)?.name,
        value: areaId,
      } as ComboBoxItem)
    : null;
};
