import { ReactElement, useMemo } from "react";
import { useQuery, useQueryClient } from "react-query";
import areaValidationSchema from "../Utilities/AreaValidationSchema";
import { ReactQueryKeys } from "../../../Constants/ClientRoutingConstants";
import AreaReadDto from "../../../Models/Area/AreaReadDto";
import ErrorCard from "../../Shared/Components/Cards/ErrorCard";
import LoadingSpinner from "../../Shared/Components/LoadingSpinner";
import areasApiService from "../Utilities/AreasApiService";
import AreaTable from "../Components/AreaTable";
import AreaCreateDto from "../../../Models/Area/AreaCreateDto";
import { useToast } from "@chakra-ui/react";
import AreaUpdateDto from "../../../Models/Area/AreaUpdateDto";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";
import { useWindowSize } from "usehooks-ts";
import { Dialog } from "@progress/kendo-react-dialogs";

interface Props {
  isOpenDialog: any;
  setIsOpenDialog: any;
}

export default function AreasPage(props: Props): ReactElement {
  //Server side state
  const client = useQueryClient();
  const toast = useToast({
    position: "bottom",
    duration: 5000,
    isClosable: true,
  });

  const localizationService = useLocalization();

  //get window size
  const { width, height } = useWindowSize()

  const areasQuery = useQuery<Array<AreaReadDto>>(ReactQueryKeys.AllAreasQuery, async () => {
    return areasApiService.getAllAreas();
  });

  const createArea = async (values: any, setStatus: any) => {
    //Attempt to create the area
    try {
      //Apply validationSchemaTransform
      const castValues = areaValidationSchema.cast(values) as AreaCreateDto;

      //Submit values to api
      const result = await areasApiService.createArea(castValues);
      client.refetchQueries(ReactQueryKeys.AllAreasQuery);

      //On success, show success toast, then refresh data
      toast({
        status: "success",
        description: `'${values.name}' ${localizationService.toLanguageString('custom.consumableCreated', enMessages.custom.consumableCreated)}`,
      });
      return true;
    } catch (e: any) {
      //On failure show error toast, set status messages
      setStatus(e?.response?.data?.errors);

      toast({
        title: `${localizationService.toLanguageString('custom.error', enMessages.custom.error)}`,
        status: "error",
        description: `${localizationService.toLanguageString('custom.locationCreatedError', enMessages.custom.locationCreatedError)}`,
      });
      return false;
    }
  };

  const deleteAreas = async (selectedAreaIds: Array<string>) => {
    try {
      let response = await areasApiService.deleteAreas(selectedAreaIds);
      //If delete is successful, refetch the updated list of areas and show success message
      client.refetchQueries(ReactQueryKeys.AllAreasQuery);
      toast({
        status: "success",
        description: `${localizationService.toLanguageString('custom.locationDeleted', enMessages.custom.locationDeleted)}`,
      });
    } catch (e) {
      toast({
        status: "error",
        description:`${localizationService.toLanguageString('custom.locationDeletedError', enMessages.custom.locationDeletedError)}`,
      });
    }
  };

  const updateAreas = async (areas: Array<AreaUpdateDto>) => {
    try {
      if (areas.length === 1) {
        let response = await areasApiService.updateArea(areas[0], areas[0].id);
      } else if (areas.length > 1) {
        let response = await areasApiService.updateAreas(areas);
      }
      //If update is successful, refetch the updated list of areas and show success message
      client.refetchQueries(ReactQueryKeys.AllAreasQuery);
      toast({
        status: "success",
        description: `${localizationService.toLanguageString('custom.locationUpdated', enMessages.custom.locationUpdated)}`,
      });
    } catch (e: any) {
      //Error message is dependent on the number of updated areas.
      var errorMessage;
      if (areas.length === 1) {
        errorMessage = `${localizationService.toLanguageString('custom.locationUpdatedError', enMessages.custom.locationUpdatedError)}\n
        ${e?.response?.data?.errors ? Object.values(e?.response?.data?.errors)[0] : ""}`;
      } else {
        errorMessage = `${localizationService.toLanguageString('custom.locationIncorrectInput', enMessages.custom.locationIncorrectInput)}\n
          ${e?.response?.data?.map((areaId: string) => areas.find((a) => a.id === areaId)?.name)}
        `;
      }

      toast({
        status: "error",
        description: errorMessage,
      });
    }
  };

  /**
   * This function prepares data for areas table, including injecting additional metadata.
   */
  const areaData = useMemo(() => {
    //Create hashmap of areaId -> name pairs.
    const areaIdtoName: { [id: string]: string } = {};
    areasQuery.data?.forEach((area) => {
      areaIdtoName[area.id] = area.name;
    });

    //Map over areas to inject relevant data
    return areasQuery.data?.map((area) => {
      return {
        ...area,
        parentName: areaIdtoName[area.parentId] ?? null,
      };
    });
  }, [areasQuery.data]);

  const RenderQuery = () => {
    if (areasQuery.error) {
      return <ErrorCard />;
    } else {
      return (
        <div>
          {/* Areas Table */}
          <div className="mt-4">
            <AreaTable createArea={createArea} areas={areaData!} deleteAreas={deleteAreas} updateAreas={updateAreas} height={690} />
          </div>

          {props.isOpenDialog.areas && (
              <Dialog
                title={localizationService.toLanguageString('custom.locations', enMessages.custom.locations)}
                onClose={() => props.setIsOpenDialog({...props.isOpenDialog, areas: false})}
              >
               <AreaTable createArea={createArea} areas={areaData!} deleteAreas={deleteAreas} updateAreas={updateAreas} height={height - 200} />
              </Dialog>
            )}
        </div>
      );
    }
  };

  return areasQuery.isLoading || areasQuery.isFetching ? <LoadingSpinner /> : <RenderQuery />;
}
