import React, { ReactElement, useRef, useEffect, useMemo } from "react";
import { UserReadDto } from "../../../../Models/Admin/UserReadDto";
import { OrganisationRole } from "../../../../Models/Admin/Role";
import { useQueryClient } from "react-query";
import { ReactQueryKeys } from "../../../../Constants/ClientRoutingConstants";
import UpdateUserRolesModal from "../Modals/UpdateUserRolesModal";
import { SiteReadDto } from "../../../../Models/Site/SiteReadDto";
import { UserReadTableDto } from "../../../../Models/Admin/UserReadTableDto";
import useKendoTableState from "../../../Shared/Hooks/useKendoTableState";
import { ColumnMetaData } from "../../../Shared/Interfaces/ColumnMetaData";
import GBKendoDataCell from "../../../Shared/Components/Table/GBKendoDataCell";
import UserValidationSchema from "../../Utilities/UserValidationSchema";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../../messages/en-US";
import { CompositeFilterDescriptor, filterBy, orderBy } from "@progress/kendo-data-query";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Grid, GridFilterChangeEvent, GridPageChangeEvent, GridSortChangeEvent, GridToolbar, GridColumn as Column, GridRowProps } from "@progress/kendo-react-grid";
import { isRequiredBySchema } from "../../../Shared/Utilities/YupValidationUtilities";
import { Formik } from "formik";
import GBKendoToolbar from "../../../Shared/Components/Table/GBKendoToolbar";
import { Checkbox } from "@progress/kendo-react-inputs";
import { CustomDateFilterCell } from "../../../Shared/Components/Table/CustomDateFilterCell";
import { CustomTextFilterCell } from "../../../Shared/Components/Table/CustomTextFilterCell";
import { Button } from "@progress/kendo-react-buttons";
import { boolean } from "yup";

interface Props {
  inviteUser: (values: any, setStatus: any) => void;
  reinviteUser: (id: string) => void;
  deleteUser: (id: string) => void;
  addUserRole: (id: string, siteId: string, roleId: string) => Promise<any>;
  removeUserRole: (id: string, siteId: string, roleId: string) => Promise<any>;
  users: Array<UserReadTableDto>;
  roles: Array<OrganisationRole>;
  sites?: Array<SiteReadDto>;
  height?: number;
}

const initialFilter: CompositeFilterDescriptor = {
  logic: "and",
  filters: [],
};

export default function AdministrationUserTable(props:any): ReactElement {
  const tableState = useKendoTableState<UserReadTableDto>({
    key: "AdminUsers",
    initialAssets: props.users,
  });
  const client = useQueryClient();
  const [userToUpdate, setUserToUpdate] = React.useState<UserReadDto | null>(null);

  const localizationService = useLocalization();

  const stateRef = useRef(tableState);

  useEffect(() => {
    stateRef.current = tableState
   }, [tableState]);

  const [filter, setFilter] = React.useState(initialFilter);
  const [sort, setSort] = React.useState(props.initialSortDescriptor ?? props.initialSort);

  const columns: any = React.useMemo(() => {
    var columns: Array<ColumnMetaData<UserReadTableDto>> = [
      {
        header: `${localizationService.toLanguageString('custom.email', enMessages.custom.email)}`,
        displayField: "email",
        filterType: "text",
        Cell: (cellProps) => (
          <GBKendoDataCell<UserReadTableDto>
            dataFieldName="email"
            cellProps={cellProps}
            fieldAccessor={(x) => x.email}
            isAddMode={cellProps.dataIndex < tableState.tempRows.length}
            isEditMode={false}
            onCellChanged={tableState.onCellChanged}
          />
        ),
      },
      {
        header:  `${localizationService.toLanguageString('custom.currentRole', enMessages.custom.currentRole)}`,
        displayField: "roleName",
        filterType: "text",
        Cell: (cellProps) => (
          <GBKendoDataCell<UserReadTableDto>
              dataFieldName="roleId"
              fieldAccessor={(x) => x.roleId}
              displayTextAccessor={(x) => x.name === 'SiteAdmin' ? x.name = 'AccountAdmin': x.name}
              cellProps={cellProps}
              isAddMode={cellProps.dataIndex < tableState.tempRows.length}
              isEditMode={false}
              onCellChanged={tableState.onCellChanged}
              assets={props.roles.filter((x: { name: string; }) => x.name !== "Organisation")}
              inputType="combobox"
              initialDisplayText={cellProps.dataItem.roleName === 'SiteAdmin'? cellProps.dataItem.roleName = 'AccountAdmin': cellProps.dataItem.roleName }
            />
        ),
      },
      {
        header: `${localizationService.toLanguageString('custom.confirmed', enMessages.custom.confirmed)}`,
        displayField: "emailConfirmed",
        filterType: "boolean",
        Cell: (cellProps) => (
          <GBKendoDataCell<UserReadTableDto>
            className={`${
              cellProps.dataItem.emailConfirmed ? "text-green-500" : "text-primary-300"
            } font-bold uppercase`}
            dataFieldName="emailConfirmed"
            cellProps={cellProps}
            fieldAccessor={(x) => x.emailConfirmed ? `${localizationService.toLanguageString('custom.true', enMessages.custom.true)}` : `${localizationService.toLanguageString('custom.false', enMessages.custom.false)}`}
            isAddMode={false}
            isEditMode={false}
            onCellChanged={tableState.onCellChanged}
          />
        ),
      },
    ];
    if (props.sites != null) {
      columns.splice(1, 0, {
        header: `${localizationService.toLanguageString('custom.currentAccount', enMessages.custom.currentAccount)}`,
        displayField: "siteName",
        filterType: "text",
        Cell: (cellProps) => (
          <GBKendoDataCell<UserReadTableDto>
              dataFieldName="siteId"
              fieldAccessor={(x) => x.siteId}
              displayTextAccessor={(x) => x.name}
              cellProps={cellProps}
              isAddMode={cellProps.dataIndex < tableState.tempRows.length}
              isEditMode={false}
              onCellChanged={tableState.onCellChanged}
              assets={props.sites}
              inputType="combobox"
              initialDisplayText={cellProps.dataItem.siteName}
            />
        ),
      });
    }
    return columns;
  }, [tableState.canModifyRow, tableState.tempRows.length, filter, sort]);

  const _export = React.useRef<ExcelExport | null>(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };
  const ref = useRef<any>();

  /**The following use effects are required to fix a race condition created by updating formik fields
   * Programatically. They ensure that validation is performed after the values update instead of before.
   **/
   useEffect(() => {
    ref.current.validateForm();
  }, [ref.current?.values]);
  useEffect(() => {
    ref.current.validateForm();
  }, [ref.current]);

  /**
   * Set the skip amount to zero if the number of temp rows (indicating an add row has been added).
   * This effectively ensures the add row is always scrolled into view
   */
  useEffect(() => {
    if (tableState.tempRows.length === 1) {
      setSkip(0);
    }
  }, [tableState.tempRows.length]);

  //The following state is required for dynamic scrolling
  const [skip, setSkip] = React.useState<number>(0);
  const pageChange = (event: GridPageChangeEvent) => {
    setSkip(event.page.skip);
  };

  const isColumnForRequiredField = (displayField: string | undefined): boolean => {
    return (
      isRequiredBySchema(displayField?.replace("Name", "Id"), UserValidationSchema(localizationService)) &&
      (tableState.tempRows.length > 0 || tableState.canModifyRow)
    );
  };

  useEffect(() => {
    // Reset skip when filter or sort changes
    setSkip(0);
  }, [filter, sort]);

  //Data is API data + current temporary rows that have not yet been persisted using the API
  const processedData = useMemo(() => {
     
    let initialRows = tableState.assets;

    if (filter) {
      initialRows = filterBy(initialRows, filter);
    }

    if (sort) {
      initialRows = orderBy(initialRows, sort);
    }
    //Add any temp rows to beggining
    return tableState.tempRows.concat(initialRows);
  }, [tableState.assets, tableState.tempRows, filter, sort]);

  const initialisedRowData = {
    siteId: "",
    areaId: "",
    guid: "",
    modelNumber: "",
    serialNumber: "",
    description: "",
    firmwareVersion: "",
    type: "Grease",
    status: "Active",
    id: "",
    userName : "",
    email: "",
    emailConfirmed: false,
    roleId: "",
    siteRoleMappingsWithNames : [],
    siteName: "",
    roleName: "",
    siteRoleMappings : []
  };

  return (
    <div>
    <Formik
      innerRef={ref}
      key={props.users.length}
      onSubmit={async (values, { setStatus, resetForm }) => {
        const res = await props.inviteUser(values, setStatus);
        if (res) {
          resetForm();
          tableState.setTempRows([]);
        }
      }}
      validationSchema={UserValidationSchema(localizationService)}
      initialValues={initialisedRowData}
    >
      {({ submitForm, values, resetForm, isSubmitting }) => (
        <ExcelExport data={processedData} ref={_export} collapsible={true} fileName={localizationService.toLanguageString("custom.manageUsers", enMessages.custom.manageUsers)}>
          <Grid
            key={tableState.tempRows.length.toString()}
            rowHeight={46}
            total={processedData.length}
            pageSize={30}
            data={processedData}
            scrollable="virtual"
            skip={skip}
            onPageChange={pageChange}
            filterable={true}
            filter={filter}
            onFilterChange={(e: GridFilterChangeEvent) => setFilter(e.filter)}
            sortable={true}
            sort={sort}
            onSortChange={(e: GridSortChangeEvent) => {
              setSort(e.sort);
            }}
            style={{
              height: props.height,
            }}
            className="w-full  z-0"
            //Custom render row,
            rowRender={(trElement: ReactElement, props: GridRowProps) => {
              let baseStyle = { ...trElement.props.style };
              if (tableState.modifiedRowIds.includes(props.dataItem.id)) {
                baseStyle.backgroundColor = "rgba(255, 252, 88, 0.25)";
              }
              if (tableState.selectedRowsIds.includes(props.dataItem.id)) {
                baseStyle.backgroundColor = "rgba(255, 99, 88, 0.25)";
              }

              return React.cloneElement(
                trElement,
                {
                  ...trElement,
                  style: { ...baseStyle },
                },
                trElement.props.children
              );
            }}
          >
            <GridToolbar>  
              <GBKendoToolbar
                name={localizationService.toLanguageString('custom.user', enMessages.custom.user)}
                tableState={tableState}
                exportAction={excelExport}
                importAction={null}
                addAction={
                  props.inviteUser
                      ? () => {
                        if (tableState.tempRows.length === 1) {
                          tableState.setTempRows([]);
                          return;
                        }
                        tableState.setTempRows([...tableState.tempRows, initialisedRowData]);
                        }
                      : null
                }
                updateAction={{}}
                deleteAction={
                  props.deleteUser ? async () => await props.deleteUser(tableState.selectedRowsIds) : null
                }
                discardChanges={() => tableState.resetState()}
              />
            </GridToolbar>

            {/* Selection - only if in edit mode*/}
            {tableState.canModifyRow && (
              <Column  
                key={"Selection"}
                cell={(props: { dataItem: { id: string; }; }) => (
                  <td>
                      <Checkbox
                        onChange={() => {
                          if (tableState.selectedRowsIds.includes(props.dataItem.id)) {
                            tableState.setSelectedRowIds(
                              tableState.selectedRowsIds.filter((id) => id !== props.dataItem?.id)
                            );
                          } else {
                            tableState.setSelectedRowIds([...tableState.selectedRowsIds, props.dataItem?.id]);
                          }
                        }}
                        checked={tableState.selectedRowsIds.includes(props.dataItem.id)}
                      />
                  </td>
                )}
                width="50px"
                filterable={false}
                sortable={false} />)}

             {/* From Column Array */}
             {columns.map((column:any, index:any) => {
              if(column.header === 'Confirmed') {
                return (
                  <Column
                  width="auto"
                  key={index}
                  cell={column.Cell}
                  // Show asterix in header if required TODO: pretty hacky - assumes that the name of the data field is the same as the display field with Name replaced.
                  title={`${column.header}${isColumnForRequiredField(column.displayField) ? "*" : ""}`}
                  field={column.displayField}
                  filter={column.filterType}
                />
                )
              }
              return (
                //Create wrapper/HOC with editable cell and add cell definitions,
                <Column
                  width="auto"
                  key={index}
                  cell={column.Cell}
                  // Show asterix in header if required TODO: pretty hacky - assumes that the name of the data field is the same as the display field with Name replaced.
                  title={`${column.header}${isColumnForRequiredField(column.displayField) ? "*" : ""}`}
                  field={column.displayField}
                  filter={column.filterType}
                  filterCell={column.filterType === "date" ? CustomDateFilterCell : CustomTextFilterCell}
                />
              );
            })}

            <Column
              title={localizationService.toLanguageString('custom.actions', enMessages.custom.actions)}
              width="auto"
              sortable={false}
              filterable={false}
              cell={(cellProps) => {
                return (
                  <td className="flex flex-wrap justify-around gap-1">
                  <Button
                    className=""
                    themeColor={"primary"}
                    onClick={
                      async () => {
                        setUserToUpdate(tableState.assets.find((user) => user.id === cellProps.dataItem.id) ?? null);
                      }
                     }
                  >
                    {localizationService.toLanguageString('custom.updateRoles', enMessages.custom.updateRoles)}
                  </Button>
                  <Button
                    className=""
                    themeColor={"base"}
                    onClick={
                      async () => {
                        await props.reinviteUser(cellProps.dataItem.id);
                      }
                     }
                  >
                    {localizationService.toLanguageString('custom.resendInvite', enMessages.custom.resendInvite)}
                  </Button>
                  </td>
                );
              }}
            />

            {/* Command Cell (For add rows only)*/}
            {tableState.tempRows.length > 0 && (
              <Column
                width="150px"
                sortable={false}
                filterable={false}
                key={"Commands"}
                cell={(props) => {
                  // Add Button for new assets
                  return (
                    <td>
                      {props.dataIndex === 0 && (
                        <div className="flex space-x-1">
                          <Button onClick={submitForm} disabled={isSubmitting} themeColor={"primary"}>
                          {localizationService.toLanguageString('custom.add', enMessages.custom.add)}
                          </Button>
                          <Button
                            onClick={() => {
                              tableState.setTempRows([]);
                              resetForm();
                            }}
                            themeColor={"base"}
                          >
                            {localizationService.toLanguageString('custom.exit', enMessages.custom.exit)}
                          </Button>
                        </div>
                      )}
                    </td>
                  );
                }}
              />
            )}
          </Grid>
        </ExcelExport>
        )}
    </Formik>
    <UpdateUserRolesModal
        roles={props.roles}
        sites={props.sites}
        user={userToUpdate}
        removeUserRole={async (id: string, siteId: string, roleId: string) => {
          await client.refetchQueries(ReactQueryKeys.AllUsersQuery);
          return await props.removeUserRole(id, siteId, roleId);
        }}
        updateUserRole={async (id: string, siteId: string, roleId: string) => {
          await client.refetchQueries(ReactQueryKeys.AllUsersQuery);
          return await props.addUserRole(id, siteId, roleId);
        }}
        onClose={() => setUserToUpdate(null)}
      />
  </div>
  );
}