import * as React from "react";
import { Checkbox } from "@progress/kendo-react-inputs";
import { SelectionContext, IsSelectedContext } from "./RouteTable";
import { GridCellProps } from "@progress/kendo-react-grid";

interface CustomCellProps extends GridCellProps {
  isTableEditable: boolean;
}

// export const SelectionCell = (props: GridCellProps) => {
export const SelectionCell = (props: CustomCellProps) => {
  const [selection, setSelection] = React.useContext(SelectionContext);
  const isSelected = React.useContext(IsSelectedContext);

  const handleChange = () => {
    if (isSelected) {
      setSelection([...selection.filter((item) => item.id !== props.dataItem.id),]);
    } else {
      setSelection([...selection, props.dataItem]);
    }
  };

  return (
    (props.isTableEditable ?
    <td
      style={{textAlign:"left", height:46, cursor:"move"}}
      className={props.className}
      colSpan={props.colSpan}
      role={"gridcell"}
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
    >
      <Checkbox checked={isSelected} onChange={handleChange} />
    </td>
    : <td></td>)
  );
};