import { cloneElement } from "react";

//Metric Operators
interface Operator {
    text: string;
    value: string;
  }

export const metricOperators: Operator[] = [
    { text: "=", value: "eq" },
    { text: "≠", value: "neq" },
    { text: ">", value: "gt" },
    { text: "<", value: "lt" },
    { text: "≥", value: "gte" },
    { text: "≤", value: "lte" },
  ];

export const dataField = [
    { fieldName: "EstimatedVolume", fieldId: 1, typeId: 1, operatorId: 1 },
    { fieldName: "ProjectedDepletionDate", fieldId: 2, typeId: 1, operatorId: 1 },
    { fieldName: "EstimatedVolumeAsPercentage", fieldId: 3, typeId: 1, operatorId: 1 },
    { fieldName: "NumberOfConsecutiveFailures", fieldId: 4, typeId: 2, operatorId: 2 },
  ];

 export const dataOperator = [
    { text: "=", value: "eq", operatorId: 1},
    { text: "≠", value: "neq", operatorId: 1},
    { text: ">", value: "gt", operatorId: 1},
    { text: "<", value: "lt", operatorId: 1},
    { text: "≥", value: "gte", operatorId: 1},
    { text: "≤", value: "lte", operatorId: 1},
    { text: "≥", value: "gte", operatorId: 2 },
  ]


  //Criticality
  export const valueRenderCriticality = (element: any, value: any) => {
    if (!value) {
      return element;
    }
    const colour = value === "Critical" ? "#780000" : value === "High" ? "#dc0000" : value === "Medium" ? "#fd8c00" : "#fdc500";
    const icon = value === "Critical" ? "k-i-chevron-double-up" : value === "High" ? "k-i-arrow-chevron-up" : value === "Medium" ? "k-i-equal" : "k-i-arrow-chevron-down";

    const children = [
      <span
        key={1}
        className={`k-icon ${icon}`}
        style={{ color: `${colour}` }}
      ></span>,
      <span key={2}>&nbsp; {element.props.children}</span>,
    ];

    return cloneElement(element, { ...element.props }, children);
  };