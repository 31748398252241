import { Field, FieldRenderProps} from "@progress/kendo-react-form";
import RecurrenceRuleInput from "./RecurrenceComponent";
import { FormInput } from "./FormComponents";
import { nameValidator } from "../../Utilities/Validators";

const RightTimeValidator = (values: any) => {
  if(values.timezone === '') {
    return "Timezone is required"
  } 

  if (values.endDate != null && values.startDate > values.endDate) {
    return "End Date must be greater than Start Date";
  } 
  
  return "";

}

export const RightTime = () => {
 
  return (
    <div style={{height:'450px'}}>
      <Field
      key={'name'}
      id={'name'}
      name={'name'}
      label={"Requirement Name"}
      component={FormInput}
      validator={nameValidator}
    />
      <Field key={"recurrenceRule"} name={"recurrenceRule"} component={RecurrenceRuleInput} validator={RightTimeValidator}/>
    </div>
  );
};
