import { SciChartJSLightTheme, SciChartSurface, SeriesInfo } from "scichart";
import { locales } from "../../../messages/locales";
import moment from "moment";

/** Synchronizes first X axes of the surfaces */
export const synchronizeXVisibleRanges = (surfaces: SciChartSurface[], shouldSync: () => boolean) => {
    const [firstSurface, ...otherSurfaces] = surfaces;
    const currentXAxis = firstSurface.xAxes.get(0);
    otherSurfaces.forEach((otherSurface) => {
        const otherXAxis = otherSurface.xAxes.get(0);
        // subscribe secondary axis  main axis
        currentXAxis.visibleRangeChanged.subscribe((data1) => {
            if (shouldSync()) {
                otherXAxis.visibleRange = data1!.visibleRange;
            }
        });
        // subscribe main axis to secondary axis
        otherXAxis.visibleRangeChanged.subscribe((data1) => {
            if (shouldSync()) {
                currentXAxis.visibleRange = otherXAxis.visibleRange;
            }
        });
    });
};


export const getCurrentLanguage = (localeId: string) => {
    const currentLanguage = locales.find(item => item.localeId === localeId); 

    if (!currentLanguage) {
        console.error("Locale not found!");
    }

    return !currentLanguage ? locales[0] : currentLanguage;
}

export const getTooltipDataTemplate = (seriesInfo: any, tooltipTitle: string) => {
    // Lines here are returned to the tooltip and displayed as text-line per tooltip
    const lines: string[] = [];
    lines.push(tooltipTitle);
    lines.push(`x: ${seriesInfo.formattedXValue}`)
    tooltipTitle !== 'Last Seen' && lines.push(`y: ${seriesInfo.formattedYValue}`);
    return lines;
};

export function changeOpacity(rgbaColor: string, opacity: number): string {
    const rgbaArray = rgbaColor.replace(/[^\d,.]/g, "").split(",");
    const [r, g, b] = rgbaArray.slice(0, 3);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export const GetChartTheme = () => {
    const theme = {...new SciChartJSLightTheme()};   
    theme.sciChartBackground = 'transparent';
    theme.loadingAnimationForeground = "rgba(255, 54, 48, 1)";
    theme.loadingAnimationBackground = '#FAFAFA'; 
    return theme;
}