import { useQuery } from "react-query";
import { ReactQueryKeys } from "../../../Constants/ClientRoutingConstants";
import { PointReadDto } from "../../../Models/Point/PointReadDto";
import pointsApiService from "../../Points/Utilities/PointsApiService";
import { useLocalization } from '@progress/kendo-react-intl';
import LoadingSpinner from "../../Shared/Components/LoadingSpinner";
import ErrorCard from "../../Shared/Components/Cards/ErrorCard";
import ComplianceReport from "../Components/ComplianceReport";
import { enMessages } from "../../../messages/en-US";
import NoDataPage from "./NoDataPage";


interface Props {}

export default function ComplianceReportPage(props: Props, ) {

  const localizationService = useLocalization();
 
  const pointsQuery = useQuery<Array<PointReadDto>>(ReactQueryKeys.AllPointsQuery, async () => {
    return pointsApiService.getAllPoints();
  });

  if ( pointsQuery.isLoading ) {
    return <LoadingSpinner />;
  } else {
    if (pointsQuery.error) {
      return <ErrorCard />;
    } else {
      return (
        <div >
          {pointsQuery.data?.length === 0 ? 
          <div>
            <h2 className="m-5 gb-h2">{localizationService.toLanguageString('custom.complianceReport', enMessages.custom.complianceReport)}</h2>
            <NoDataPage/>
          </div>
          :
          <ComplianceReport/>
        }
        </div>
    );
    }
  }
}