import create from "zustand";
import zukeeper from 'zukeeper';


interface IChartStore {
  isAutoRange: boolean;
  isBarChartOn: boolean;
  isSinglePressureChart: boolean;
  isThresholdsOn: boolean;
  aggregator: string;
  visibleRange: { min: number | undefined; max: number | undefined; };
  visibleSeriesActivity: { [key: string]: boolean;}
  visibleSeriesStatus: { [key: string]: boolean;}
  visibleSeriesCompliance: { [key: string]: boolean;}
  visibleSeriesActivityPressure: { [key: string]: boolean;}
  visibleSeriesActivityFlowRate: { [key: string]: boolean;}
  visibleSeriesDiagnostics: { [key: string]: boolean;}
  visibleSeriesAsset: { [key: string]: boolean;}
  setAutoRange: (isAutoRange: boolean) => void;
  setBarChartOn: (isBarChartOn: boolean) => void;
  setAggregator: (aggregator: string) => void;
  setSinglePressureChart: (isSinglePressureChart: boolean) => void;
  setThresholdsOn: (isThresholdsOn: boolean) => void;
  setVisibleRange: (min: number | undefined, max: number | undefined) => void;
  setVisibleSeriesActivity: (name: string, isVisible: boolean) => void;
  setVisibleSeriesStatus: (name: string, isVisible: boolean) => void;
  setVisibleSeriesCompliance: (name: string, isVisible: boolean) => void;
  setVisibleSeriesActivityPressure: (name: string, isVisible: boolean) => void;
  setVisibleSeriesActivityFlowRate: (name: string, isVisible: boolean) => void;
  setVisibleSeriesDiagnostics: (name: string, isVisible: boolean) => void;
  setVisibleSeriesAsset: (name: string, isVisible: boolean) => void;
}

/**
 * This store allows us to access the current user identity anywhere in the application.
 */
const useChartStore = create<IChartStore>(zukeeper((set) => ({
  isAutoRange: false,
  setAutoRange: (isAutoRange: boolean) => {
    set(() => {
      return {
        isAutoRange: isAutoRange,
      };
    });
  },
  isBarChartOn: true,
  setBarChartOn: (isBarChartOn: boolean) => {
    set(() => {
      return {
        isBarChartOn: isBarChartOn,
      };
    });
  },
  aggregator: 'none',
  setAggregator: (aggregator: string) => {
    set(() => {
      return {
        aggregator: aggregator,
      };
    });
  },
  isSinglePressureChart: true,
  setSinglePressureChart: (isSinglePressureChart: boolean) => {
    set(() => {
      return {
        isSinglePressureChart: isSinglePressureChart,
      };
    });
  },
  isThresholdsOn: true,
  setThresholdsOn: (isThresholdsOn: boolean) => {
    set(() => {
      return {
        isThresholdsOn: isThresholdsOn,
      };
    });
  },
  visibleRange: { min: undefined, max: undefined },
  setVisibleRange: (min: number | undefined, max: number | undefined) => {
    set(() => {
      return {
        visibleRange: { min: min, max: max },
      };
    });
  },
  visibleSeriesActivity: {},
  setVisibleSeriesActivity: (name: string, isVisible: boolean) => {
    set((state) => {
      return {
        visibleSeriesActivity: {...state.visibleSeriesActivity, [name]: isVisible },
      };
    });
  },
  visibleSeriesStatus: {},
  setVisibleSeriesStatus: (name: string, isVisible: boolean) => {
    set((state) => {
      return {
        visibleSeriesStatus: {...state.visibleSeriesStatus, [name]: isVisible },
      };
    });
  },
  visibleSeriesCompliance: {},
  setVisibleSeriesCompliance: (name: string, isVisible: boolean) => {
    set((state) => {
      return {
        visibleSeriesCompliance: {...state.visibleSeriesCompliance, [name]: isVisible },
      };
    });
  },
  visibleSeriesActivityPressure: {},
  setVisibleSeriesActivityPressure: (name: string, isVisible: boolean) => {
    set((state) => {
      return {
        visibleSeriesActivityPressure: {...state.visibleSeriesActivityPressure, [name]: isVisible },
      };
    });
  },
  visibleSeriesActivityFlowRate: {},
  setVisibleSeriesActivityFlowRate: (name: string, isVisible: boolean) => {
    set((state) => {
      return {
        visibleSeriesActivityFlowRate: {...state.visibleSeriesActivityFlowRate, [name]: isVisible },
      };
    });
  },
  visibleSeriesDiagnostics: {},
  setVisibleSeriesDiagnostics: (name: string, isVisible: boolean) => {
    set((state) => {
      return {
        visibleSeriesDiagnostics: {...state.visibleSeriesDiagnostics, [name]: isVisible },
      };
    });
  },
  visibleSeriesAsset: {},
  setVisibleSeriesAsset: (name: string, isVisible: boolean) => {
    set((state) => {
      return {
        visibleSeriesAsset: {...state.visibleSeriesAsset, [name]: isVisible },
      };
    });
  },
})));

export default useChartStore;
