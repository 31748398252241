import { SciChartNestedOverview, SciChartReact } from "scichart-react";
import React, {  } from "react";
import { EndpointReportOverviewChartData, getChartAPI } from "./EndpointReportOverviewChartApi";
import { AppContext } from "../../../../AppContext";
import { getCurrentLanguage } from "../../../Shared/SciChart/ChartConfiguration";
import { endpointReportOverviewOptions } from "../../../Shared/SciChart/Overview";

export const EndpointReportOverviewChart = (props: EndpointReportOverviewChartData) => {

  const {localeId} = React.useContext<any>(AppContext);
  const currentLanguage = getCurrentLanguage(localeId);
  const [EndpointReportOverviewChartApi] = React.useState(getChartAPI(props.dateFilter, props.chartSynchroniser, currentLanguage));

  return (
    <div className="flex">
      <SciChartReact
        initChart={EndpointReportOverviewChartApi.initChart}
        onDelete={EndpointReportOverviewChartApi.onDelete}
        style={{aspectRatio: 2, width:"100%", height: "60px"}}
        // customize size of the inner chart wrapper
        innerContainerProps={{ style: { height: "45%" } }}
        fallback={<div/>}
      >
         <SciChartNestedOverview style={{ height: "55%" }} options={endpointReportOverviewOptions}/>
      </SciChartReact>
    </div>
  );
}