import { DeviceCreateDto } from "./../../../Models/Device/DeviceCreateDto";
import { DeviceReadDto } from "./../../../Models/Device/DeviceReadDto";
import { ApiRoutes } from "../../../Constants/ApiRoutingConstants";
import axios, { AxiosInstance } from "axios";
import authorizeService from "../../Identity/Utilities/AuthorizeService";
import AdminEndpointReadDto from "../../../Models/Endpoint/AdminEndpointReadDto";
import AdminEndpointCreateDto from "../../../Models/Endpoint/AdminEndpointCreateDto";
import AdminEndpointUpdateDto from "../../../Models/Endpoint/AdminEndpointUpdateDto";
import EndpointReadDto from "../../../Models/Endpoint/EndpointReadDto";
import EndpointCreateDto from "../../../Models/Endpoint/EndpointCreateDto";
import EndpointUpdateDto from "../../../Models/Endpoint/EndpointUpdateDto";

/**
 * This service provides implementations for http calls to the api pertaining to device crud functionality
 */

class EndpointsApiService {
  axiosInstance: AxiosInstance;
  constructor() {
    //Subscribe to identity store the get token
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_DEVICE_MANAGEMENT_SERVICE_URL,
    });
  }

  async getAllEndpoints() {
    let response = await this.axiosInstance({
      method: "GET",
      url: `${ApiRoutes.Endpoints}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data as Array<EndpointReadDto>
  }

  async getAllEndpointsManage(){
    let response = await this.axiosInstance({
      method: "GET",
      url: `${ApiRoutes.ManageEndpoints}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data as Array<AdminEndpointReadDto>
  }

  async getAllSiteEndpointDevices(){
    let response = await this.axiosInstance({
      method: "GET",
      url: `${ApiRoutes.SiteEndpointDevices}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data as Array<AdminEndpointReadDto>
  }

  async createEndpointDevice(endpoint: AdminEndpointCreateDto){
    let response = await this.axiosInstance({
      method: "POST",
      url: `${ApiRoutes.ManageEndpoints}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: endpoint,
    });
    return response.data;
  }

  async createEndpoint(endpoint: EndpointCreateDto){
    let response = await this.axiosInstance({
      method: "POST",
      url: `${ApiRoutes.Endpoints}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: endpoint,
    });
    return response.data;
  }

  async updateEndpoint(endpoint: EndpointUpdateDto, endpointID: string) {
    let response = await this.axiosInstance({
      method: "PUT",
      url: `${ApiRoutes.Endpoints}/${endpointID}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: endpoint
    });
    return response.data;
  }

  async updateEndpoints(endpoints: Array<EndpointUpdateDto>) {
    let response = await this.axiosInstance({
      method: "PUT",
      url: `${ApiRoutes.Endpoints}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: {endpoints },
    });
    return response.data;
  }

  async updateEndpointSuper(endpoint: AdminEndpointCreateDto, endpointID: string) {
    let response = await this.axiosInstance({
      method: "PUT",
      url: `${ApiRoutes.ManageEndpoints}/${endpointID}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: endpoint,
    });
    return response.data;
  }

  async updateEndpointsSuper(endpoints: Array<AdminEndpointUpdateDto>) {
    let response = await this.axiosInstance({
      method: "PUT",
      url: `${ApiRoutes.ManageEndpoints}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: { endpoints },
    });
    return response.data;
  }

  async deleteEndpointDevices(deleteEndpointIds: Array<string>) {
    let response = await this.axiosInstance({
      method: "DELETE",
      url: `${ApiRoutes.ManageEndpoints}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: {
        ids: deleteEndpointIds,
      },
    });
    return response.data;
  }

  async deleteEndpoints(deleteEndpointIds: Array<string>) {
    let response = await this.axiosInstance({
      method: "DELETE",
      url: `${ApiRoutes.Endpoints}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: {
        ids: deleteEndpointIds,
      },
    });
    return response.data;
  }

  async endpointsCompliance(endpointId: string, eventFilters){
    //Covnert EventFilters to use Iso date strings
    const isoEventFilters = {...eventFilters, 
      startDate : eventFilters.startDate?.toISOString(),
      endDate : eventFilters.endDate?.toISOString()}

    let response = await this.axiosInstance({
      method: "GET",
      url: `${ApiRoutes.EndpointsCompliance}${endpointId}?${new URLSearchParams(isoEventFilters).toString()}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data;
  }

  static get instance() {
    return endpointsApiService;
  }
}

const endpointsApiService = new EndpointsApiService();
export default endpointsApiService;
