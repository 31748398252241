import {
  XyDataSeries,
  SciChartSurface,
  EAxisAlignment,
  NumericAxis,
  StackedColumnCollection,
  StackedColumnRenderableSeries,
  Thickness,
  } from "scichart";
import { TInitFunction } from "scichart-react";
import { GetChartTheme } from "../../../Shared/SciChart/ChartConfiguration";

export type TReservoirReportChartConfigFunc = TInitFunction<SciChartSurface>;

export interface ReservoirChartData {
  reservoirId: string;
  estimatedVolumeAsPercentage: number;
}

export const getChartAPI = (reservoir:ReservoirChartData) => {

  const theme = GetChartTheme();  

  const initChart : TReservoirReportChartConfigFunc = async (rootElement: string | HTMLDivElement) => {
  
      const {sciChartSurface, wasmContext} = await SciChartSurface.create(rootElement, {theme});

      sciChartSurface.padding = Thickness.fromNumber(-10);
  
      const xAxis = new NumericAxis(wasmContext, { axisAlignment: EAxisAlignment.Left, autoTicks:false},); 
      const yAxis = new NumericAxis(wasmContext,{isVisible:false,axisAlignment: EAxisAlignment.Top});

      sciChartSurface.xAxes.add(xAxis);
      sciChartSurface.yAxes.add(yAxis);

      const percentage = reservoir.estimatedVolumeAsPercentage < 0 ? 0 : reservoir.estimatedVolumeAsPercentage ;
      const xValues = [0];
      const yValues1 = [1 - percentage];
      const yValues2 = [percentage];

      const reservoirLevel = new StackedColumnCollection(wasmContext,{isOneHundredPercent:true , dataPointWidth:0.9});
      reservoirLevel.add(new StackedColumnRenderableSeries(wasmContext, {
        dataSeries: new XyDataSeries(wasmContext, { xValues: xValues, yValues:yValues1, dataSeriesName: reservoir.reservoirId }),
        fill: "lightGrey",
        stackedGroupId: "StackedGroupId-First"
      }));
      

      reservoirLevel.add(new StackedColumnRenderableSeries(wasmContext, {
        dataSeries: new XyDataSeries(wasmContext, { xValues, yValues: yValues2, dataSeriesName: reservoir.reservoirId }),
        fill: "rgba(26, 179, 148, 1)",
        stackedGroupId: "StackedGroupId-First"
      }));

    sciChartSurface.renderableSeries.add(reservoirLevel);         

    return { sciChartSurface};
  }

  return {initChart};
}

