import useIdentityStore from "../../../../Zustand/identityStore";
import GenericDialog from "../Dialogs/GenericConfirmationDialog";
import { Button, DropDownButton, DropDownButtonItem } from "@progress/kendo-react-buttons";
import { HasId, KendoTableState } from "../../Hooks/useKendoTableState";
import { ToolbarActionDefinition } from "../../Interfaces/ActionDefinition";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../../messages/en-US";
import { Tooltip } from "@progress/kendo-react-tooltip";

interface Props<Type extends HasId> {
  name: string;
  tableState: KendoTableState<Type>;
  updateAction?: any;
  deleteAction?: any;
  discardChanges?: any;
  exportAction?: any;
  addAction?: any;
  onPauseAction?: () => Promise<void>;
  hideAddRow?: boolean;
  hideDelete?: boolean;
  importAction?: any;
  extraToolbarActions?: Array<ToolbarActionDefinition>;
}

/**
 * This component contains common functions for controlling a GB kendo table (export, edit, add buttons etc.)
 */
export default function GBKendoToolbar<Type extends HasId>({
  name,
  tableState,
  updateAction,
  exportAction,
  deleteAction,
  discardChanges,
  addAction,
  hideAddRow,
  hideDelete,
  importAction,
  extraToolbarActions: extraActions,
}: Props<Type>) {
  //Get current user from zustand, hide editing if user is general user.
  var currentUserRole = useIdentityStore((x) => x.user?.profile.role);

  const localizationService = useLocalization();

  //Only show GB Table controls if user is manager or higher role.
  if (currentUserRole === "GeneralUser") {
    return <div></div>;
  }

  return (
    //Only show GB Table controls if user is manager or higher role.
    <div className="flex flex-row gap-x-2 w-full">
      <div className="gb-h3 pl-1">{`${name}`}</div>
      <div className="flex-1" />
      {!tableState.canModifyRow && updateAction && (
        <>
          <Button
            themeColor={"primary"}
            icon="pencil"
            disabled={tableState.tempRows.length !== 0}
            onClick={() => tableState.setCanModifyRow(!tableState.canModifyRow)}
          >
            {localizationService.toLanguageString('custom.edit', enMessages.custom.edit)}
          </Button>
        </>
      )}

      {tableState.canModifyRow && (
        <>
          {tableState.selectedRowsIds.length > 0 && !hideDelete && deleteAction && (
            <Button
              themeColor="base"
              icon="trash"
              disabled={!tableState.canModifyRow}
              onClick={() => tableState.setDeleteModalOpen(true)}
            >
              {localizationService.toLanguageString('custom.delete', enMessages.custom.delete)} {name}
            </Button>
          )}

          {tableState.modifiedRowIds.length > 0 ? (
            <Button icon="close" themeColor={"base"} onClick={() => tableState.setCanDiscard(!tableState.canDiscard)}>
              {localizationService.toLanguageString('custom.discardChanges', enMessages.custom.discardChanges)}
            </Button>
          ) : (
            <Button
              onClick={() => {
                tableState.setSelectedRowIds([]);
                tableState.setCanModifyRow(!tableState.canModifyRow);
              }}
            >
              {localizationService.toLanguageString('custom.cancel', enMessages.custom.cancel)}
            </Button>
          )}

          {tableState.canModifyRow && (
            <Button
              icon="check"
              themeColor={"base"}
              disabled={tableState.modifiedRowIds.length < 1}
              onClick={() => tableState.setCanSave(!tableState.canSave)}
            >
              {localizationService.toLanguageString('custom.save', enMessages.custom.save)}
            </Button>
          )}
        </>
      )}

      {!hideAddRow && addAction && (
        <Button
          icon="add"
          themeColor={"primary"}
          onClick={() => {
            addAction();
          }}
        >
          {localizationService.toLanguageString('custom.add', enMessages.custom.add)} {name}
        </Button>
      )}

      {/* Loop through extra actions */}
      {extraActions?.map((action, index) => {
        return (
          <Button icon={action.icon} key={index} themeColor={action.themeColor} onClick={() => action.onClick()}>
            {action.text}
          </Button>
        );
      })}

      {/* Action Confirmation Modals */}

      {/* Save changes modal */}
      <GenericDialog
        header={`${localizationService.toLanguageString('custom.saveChanges', enMessages.custom.saveChanges)}`}
        confirmButtonTheme={"success"}
        open={tableState.canSave}
        onClose={tableState.onClose}
        onConfirm={() => {
          updateAction();
        }}
        body={`${localizationService.toLanguageString('custom.saveChangesConfirmation', enMessages.custom.saveChangesConfirmation)}`}
        cancel={`${localizationService.toLanguageString('custom.cancel', enMessages.custom.cancel)}`}
        confirm={`${localizationService.toLanguageString('custom.continue', enMessages.custom.continue)}`}
      />
      {/* Discard changes modal */}
      <GenericDialog
        header={`${localizationService.toLanguageString('custom.discardChanges', enMessages.custom.discardChanges)}?`}
        confirmButtonTheme={"primary"}
        open={tableState.canDiscard}
        onClose={() => {
          tableState.onClose()
        }}
        onConfirm={() => {
          discardChanges();
        }}
        body={`${localizationService.toLanguageString('custom.unsavedChangesShort', enMessages.custom.unsavedChangesShort)}`}
        cancel={`${localizationService.toLanguageString('custom.cancel', enMessages.custom.cancel)}`}
        confirm={`${localizationService.toLanguageString('custom.continue', enMessages.custom.continue)}`}
      />
      {/* Delete items modal */}
      <GenericDialog
        header={`${localizationService.toLanguageString('custom.deleteItems', enMessages.custom.deleteItems)}`}
        confirmButtonTheme={"primary"}
        open={tableState.deleteModalOpen}
        onClose={tableState.onClose}
        onConfirm={() => {
          deleteAction();
        }}
        body={`${localizationService.toLanguageString('custom.deleteItemsConfirmation', enMessages.custom.deleteItemsConfirmation)}`}
        cancel={`${localizationService.toLanguageString('custom.cancel', enMessages.custom.cancel)}`}
        confirm={`${localizationService.toLanguageString('custom.delete', enMessages.custom.delete)}`}
      />
      {exportAction !== null && (
        <Tooltip anchorElement="target" position="bottom">
          <Button
            title={localizationService.toLanguageString('custom.exportToExcel', enMessages.custom.exportToExcel)}
            className="pl-2 border-l-2"
            icon="download"
            disabled={tableState.tempRows.length !== 0}
            onClick={exportAction}
          />
      </Tooltip>
      )}

      {importAction !== null && (
        <Tooltip anchorElement="target" position="bottom">
          <Button
            title={localizationService.toLanguageString('custom.uploadCsv', enMessages.custom.uploadCsv)}
            className="pl-2 border-l-2"
            icon="upload"
            disabled={tableState.tempRows.length !== 0}
            onClick={importAction}
          />
      </Tooltip>
      )}
      
    </div>
  );
}
