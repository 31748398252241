import { Button, ButtonGroup } from "@chakra-ui/button";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../../messages/en-US";

interface Props {
  daysSelected: any;
  setDaysSelected: any;
}

function DayPickerWeek(props: Props) {
  const addDay = (day: number) => {
    if (props.daysSelected.includes(day)) {
      const newDays = props.daysSelected.filter((item: number) => item !== day);
      props.setDaysSelected(newDays);
    } else {
      props.setDaysSelected([...props.daysSelected, day]);
    }
  };

  const localizationService = useLocalization();

  return (
    <div className="grid grid-cols-7 gap-x-3  py-2 ">
      <Button
        onClick={() => addDay(0)}
        colorScheme="red"
        variant={props.daysSelected.includes(0) ? "solid" : "outline"}
      >
        {localizationService.toLanguageString('custom.sunday', enMessages.custom.sunday)}
      </Button>
      <Button
        onClick={() => addDay(1)}
        colorScheme="red"
        variant={props.daysSelected.includes(1) ? "solid" : "outline"}
      >
        {localizationService.toLanguageString('custom.monday', enMessages.custom.monday)}
      </Button>
      <Button
        onClick={() => addDay(2)}
        colorScheme="red"
        variant={props.daysSelected.includes(2) ? "solid" : "outline"}
      >
        {localizationService.toLanguageString('custom.tuesday', enMessages.custom.tuesday)}
      </Button>
      <Button
        onClick={() => addDay(3)}
        colorScheme="red"
        variant={props.daysSelected.includes(3) ? "solid" : "outline"}
      >
        {localizationService.toLanguageString('custom.wednesday', enMessages.custom.wednesday)}
      </Button>
      <Button
        onClick={() => addDay(4)}
        colorScheme="red"
        variant={props.daysSelected.includes(4) ? "solid" : "outline"}
      >
        {localizationService.toLanguageString('custom.thursday', enMessages.custom.thursday)}
      </Button>
      <Button
        onClick={() => addDay(5)}
        colorScheme="red"
        variant={props.daysSelected.includes(5) ? "solid" : "outline"}
      >
        {localizationService.toLanguageString('custom.friday', enMessages.custom.friday)}
      </Button>
      <Button
        onClick={() => addDay(6)}
        colorScheme="red"
        variant={props.daysSelected.includes(6) ? "solid" : "outline"}
      >
        {localizationService.toLanguageString('custom.saturday', enMessages.custom.saturday)}
      </Button>
    </div>
  );
}

export default DayPickerWeek;
