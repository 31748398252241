import { useEffect } from "react";
import AccountSettingsReadDto from "../../Models/Dashboard/AccountSettingsReadDto";
import { useQuery } from "react-query";
import dashboardApiService from "../Dashboard/Utilities/DashboardApiService";
import { ClientRoutes, ReactQueryKeys } from "../../Constants/ClientRoutingConstants";
import LoadingPage from "../Shared/Pages/LoadingPage";
import { useAccountSettingsStore } from "../../Zustand/accountSettingsStore";
import { Navigate } from "react-router-dom";
import ErrorPage from "../Shared/Pages/ErrorPage";

/**
 * This component is responsible for dealing with the account settings, it will fetch the account settings 
 * from the server and store them in the account settings store.
 */
export default function AccountSettingsPage() {

  const accountSettingsStore = useAccountSettingsStore((store) => store);

  const accountSettingsQuery = useQuery<AccountSettingsReadDto>([ReactQueryKeys.SoftwareFeaturesQuery], async () => {
    return dashboardApiService.getAccountSettings();
  });

  useEffect(() => {
    if (accountSettingsQuery.data) {
      if (accountSettingsQuery.data.timezone !== "") {
        accountSettingsStore.setAccountTimeZone(accountSettingsQuery.data.timezone);
      }
      accountSettingsStore.setSoftwareFeatures(accountSettingsQuery.data.softwareFeatures);
    }
  }, [accountSettingsQuery.data, accountSettingsStore])

  if(accountSettingsQuery.isLoading || accountSettingsQuery.isFetching){
    return <LoadingPage />
  } else if(accountSettingsQuery.isError){
    return <ErrorPage />
  } else {
    return <Navigate to={ClientRoutes.DefaultPage} replace/>
  }
}
