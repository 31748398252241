import * as React from 'react';
import { Card, CardBody, CardActions } from '@progress/kendo-react-layout';
import { ColorPicker, ColorPickerChangeEvent, Input, InputChangeEvent, InputPrefix, InputSuffix, NumericTextBox, NumericTextBoxChangeEvent } from '@progress/kendo-react-inputs';
import { ListViewItemProps } from '@progress/kendo-react-listview';
import { Button } from "@progress/kendo-react-buttons";

interface MyItemRenderProps extends ListViewItemProps {
  cardData: Array<any>;
  updateCard: (item) => void;
  checked: boolean;
  volumeRequirement: number
  percentage: number
  isVisible: boolean;
}

const MyItemRender = (props: MyItemRenderProps) => {
  const [item, setItem] = React.useState(props.dataItem);

  React.useEffect(()=>{
    
    if(item.id != props.dataItem.id){
      setItem(props.dataItem);
    }

  },[props.dataItem, item.id, ])

  const handleDelete = () => {
      props.updateCard(props.cardData.filter((e) => e.id !== item.id))
  }

  const handleOnChange = (e: ColorPickerChangeEvent) => {
    setItem({...item,colour: e.value})
    props.cardData.forEach(x => {
      if(x.id == item.id){
        x.colour = e.value
      }
    })
    changeDelay(item, e)
  };

  let max
  let min = 0
  const itemIdex = props.cardData.findIndex(x => x.id == item.id)
  if(itemIdex != 0) {
    max = props.cardData[itemIdex - 1].value -0.1
    
  }
  if( itemIdex != props.cardData.length - 1) {
    min =props.cardData[itemIdex + 1].value + 0.1
  }


  const onValueChange = (e: NumericTextBoxChangeEvent) => {
    setItem({...item, value: e.value})
    props.cardData.forEach(x => {
      if(x.id == item.id){
        x.value = e.value
      }
    })
    changeDelay(item, e)
  };

  

  const [timer, setTimer] = React.useState<ReturnType<typeof setInterval> | null>(null)

  function changeDelay(item, e) {
      if (timer) {
        clearTimeout(timer);
        setTimer(null);
      }
      setTimer(
        setTimeout(() => {
          props.updateCard(props.cardData)
          
         
        }, 350)
      );
  }

  const handleChange = (e: InputChangeEvent) => {
    setItem({...item,name: e.target.value})
    props.cardData.forEach(x => {
      if(x.id == item.id){
        x.name = e.target.value
      }
    })
  };

  let isDisabled
  if(props.checked) {
    isDisabled = true
  } else {
    isDisabled = item.id == 1 ? true : false
  }

  const prefix = () => (<InputPrefix>&nbsp;&nbsp;&ge;</InputPrefix>);
  
  const suffix = () => (<InputSuffix>cc&nbsp;&nbsp;</InputSuffix>);

  const paletteSettings = {
    palette: [
      "rgba(255, 80, 52, 1)",
      "rgba(26, 179, 148, 1)",
      "rgba(127, 127, 127, 1)",
      "rgba(136, 0, 21, 1)",
      "rgba(255, 127, 39, 1)",
      "rgba(255, 242, 0, 1)",
      "rgba(0, 162, 232, 1)",
      "rgba(63, 72, 204, 1)",
      "rgba(163, 73, 164, 1)",
      "rgba(0, 0, 0, 1)",
      "rgba(255, 174, 201, 1)",
      "rgba(181, 230, 29, 1)",
      "rgba(217, 217, 217, 1)",
      "rgba(185, 122, 87, 1)",
      "rgba(255, 201, 14, 1)",
      "rgba(239, 228, 176, 1)",
      "rgba(153, 217, 234, 1)",
      "rgba(112, 146, 190, 1)",
      "rgba(200, 191, 231, 1)",
      "rgba(255, 255, 255, 1)"
    ],
    columns: 10,
    tileSize: 25.5,
  };

  return (
    <div style={{display: 'flex', alignItems:'flex-end', marginBottom:'5px'}} >
        <div key={props.dataItem.id}>
            <div className={"k-card-list requirements-cards " } >
                {item.thresoldNo!= 0 && ( 
                <Card style={{  display:'flex'}}>
                    <CardBody style={{display:"flex", alignItems:"center", justifyContent:'space-between', height:'45px'}}>
                    <div style={{display:'flex',  alignItems:"center"}}>
                    <div className="col-sm-12 col-md-3 " >
                    <ColorPicker
                      view={"combo"}
                      value={`${item.colour}`}
                      onChange={handleOnChange}
                      paletteSettings={paletteSettings}      
                      defaultValue={"#FF5034"}
                    />
                    </div>
                  
                    <Input
                        style={{width:"290px", border:'none'}}
                        defaultValue={item.name}
                        onChange={handleChange}
                        maxLength={50}
                        placeholder={'Enter a threshold label...'}
                        readOnly={true}
                        unselectable={"on"}
                    />
                    </div>
                    <div style={{display:'flex',  alignItems:"center"}}>
                        <div>
                        <NumericTextBox
                            width={160}
                            prefix={prefix}
                            suffix={suffix}
                            placeholder="please enter value"
                            defaultValue={item.value}
                            max={max}
                            min={min}
                            value={ item.value}

                            onChange={onValueChange}
                            format={"n2"}
                            step={0.1}
                            disabled={isDisabled}
                        />
                        </div>
                        {props.isVisible && (
                         <CardActions>
                         <div style={{ width: "25%", padding: "5 0", marginLeft:'20px' }}>
                             <Button
                                 themeColor={"primary"} size={"small"}
                                 onClick={handleDelete}
                                 disabled={isDisabled}
                             >
                                 X
                             </Button>
                         </div>
                     </CardActions>
                      )}
                       
                    </div>
                </CardBody>
                </Card>
                )}
            </div>
        </div>
    </div>
  )
}

export default MyItemRender