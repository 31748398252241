import { ReactElement } from "react";
import LogoImage from "../../../Assets/logo-blue.png";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";

export default function LoadingSpinner(): ReactElement {
  const localizationService = useLocalization();
  return (
    <>
      <div className="flex justify-center items-center h-screen ">
        <img src={LogoImage} className="h-20 w-20 relative"/>
        <div className="absolute animate-spin rounded-full h-32 w-32 border-b-2 border-primary-400"></div>
        <div className=" absolute mt-44">{localizationService.toLanguageString('custom.loading', enMessages.custom.loading)}</div>
      </div>
    </>
  );
}
