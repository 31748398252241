import { SiteUpdateDto } from "./../../../Models/Site/SiteUpdateDto";
import { SiteCreateDto } from "./../../../Models/Site/SiteCreateDto";
import { ApiRoutes } from "../../../Constants/ApiRoutingConstants";
import axios, { AxiosInstance } from "axios";
import authorizeService from "../../Identity/Utilities/AuthorizeService";
import AdminAuthTokenReadDto from "../../../Models/AuthToken/AdminAuthTokenReadDto";


/**
 * This service provides implementations for http calls to the api pertaining to administration functionality
 */

class AdministrationAPIService {
  axiosInstance: AxiosInstance;
  constructor() {
    //Subscribe to identity store the get token
    this.axiosInstance = axios.create({
      baseURL: `${process.env.REACT_APP_BACKEND_URL}`,
    });
  }
  async createSite(site: SiteCreateDto) {
    let response = await this.axiosInstance({
      method: "POST",
      url: `${ApiRoutes.SuperAdministration}${ApiRoutes.SuperAdministrationRoutes.CreateSite}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: site,
    });
    return response.data;
  }

  async updateSite(site: SiteUpdateDto) {
    let response = await this.axiosInstance({
      method: "PUT",
      url: `${ApiRoutes.SuperAdministration}${ApiRoutes.SuperAdministrationRoutes.UpdateSite}/${site.id}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: site,
    });
    return response.data;
  }

  async getAllSiteUsers() {
    let response = await this.axiosInstance({
      method: "GET",
      url: `${ApiRoutes.SiteAdministration}${ApiRoutes.SiteAdministrationRoutes.GetAllUsers}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data;
  }

  async getAllSiteRoles() {
    let response = await this.axiosInstance({
      method: "GET",
      url: `${ApiRoutes.SiteAdministration}${ApiRoutes.SiteAdministrationRoutes.GetAllRoles}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data;
  }

  async getAllRoles() {
    let response = await this.axiosInstance({
      method: "GET",
      url: `${ApiRoutes.SuperAdministration}${ApiRoutes.SuperAdministrationRoutes.GetAllRoles}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data;
  }

  async getAllSites() {
    let response = await this.axiosInstance({
      method: "GET",
      url: `${ApiRoutes.SuperAdministration}${ApiRoutes.SuperAdministrationRoutes.GetAllSites}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data;
  }

  async getAllUsers() {
    let response = await this.axiosInstance({
      method: "GET",
      url: `${ApiRoutes.SuperAdministration}${ApiRoutes.SuperAdministrationRoutes.GetAllUsers}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data;
  }

  async addUserRoleSuper(id: string, siteId: string, roleId: string) {
    //Post the resend invite
    let response = await this.axiosInstance({
      method: "POST",
      url: `${process.env.REACT_APP_BACKEND_URL}${ApiRoutes.SuperAdministration}/users/${id}/roles`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: {
        siteId,
        roleId,
      },
    });
    return response.data;
  }

  async removeUserRoleSuper(id: string, siteId: string, roleId: string) {
    //Post the resend invite
    let response = await this.axiosInstance({
      method: "DELETE",
      url: `${process.env.REACT_APP_BACKEND_URL}${ApiRoutes.SuperAdministration}/users/${id}/roles`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: {
        siteId,
        roleId,
      },
    });
    return response.data;
  }

  async addUserRoleSite(id: string, siteId: string, roleId: string) {
    //Post the resend invite
    let response = await this.axiosInstance({
      method: "POST",
      url: `${process.env.REACT_APP_BACKEND_URL}${ApiRoutes.SiteAdministration}/users/${id}/roles/${roleId}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data;
  }

  async removeUserRoleSite(id: string, siteId: string, roleId: string) {
    //Post the resend invite
    let response = await this.axiosInstance({
      method: "DELETE",
      url: `${process.env.REACT_APP_BACKEND_URL}${ApiRoutes.SiteAdministration}/users/${id}/roles/${roleId}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data;
  }

  async postUserInviteSuper(email: string, siteRoleMappings: Array<{ siteId: string; roleId: string }>) {
    //Post the resend invite
    let response = await this.axiosInstance({
      method: "POST",
      url: `${process.env.REACT_APP_BACKEND_URL}${ApiRoutes.SuperAdministration}${ApiRoutes.SuperAdministrationRoutes.PostInviteUser}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: {
        email: email,
        siteRoleMappings: siteRoleMappings,
      },
    });
    return response.data;
  }

  async postUserInviteSite(email: string, roleId: string) {
    //Post the resend invite
    let response = await this.axiosInstance({
      method: "POST",
      url: `${process.env.REACT_APP_BACKEND_URL}${ApiRoutes.SiteAdministration}${ApiRoutes.SiteAdministrationRoutes.PostInviteUser}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: {
        email: email,
        roleId: roleId,
      },
    });
    return response;
  }

  async postUserReinviteSuper(userId: string) {
    //Post the resend invite
    let response = await this.axiosInstance({
      method: "POST",
      url: `${process.env.REACT_APP_BACKEND_URL}${ApiRoutes.SuperAdministration}${ApiRoutes.SuperAdministrationRoutes.PostResendUserInvite}/${userId}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: {
        userId: userId,
      },
    });
    return response.data;
  }

  async postUserReinviteSite(userId: string) {
    //Post the resend invite
    let response = await this.axiosInstance({
      method: "POST",
      url: `${process.env.REACT_APP_BACKEND_URL}${ApiRoutes.SiteAdministration}${ApiRoutes.SiteAdministrationRoutes.PostResendUserInvite}/${userId}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: {
        userId: userId,
      },
    });
    return response.data;
  }

  async postUserDeleteSuper(userId: string) {
    //Post the resend invite
    let response = await this.axiosInstance({
      method: "POST",
      url: `${process.env.REACT_APP_BACKEND_URL}${ApiRoutes.SuperAdministration}${ApiRoutes.SuperAdministrationRoutes.DeleteUser}/${userId}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: {
        userId: userId,
      },
    });
    return response.data;
  }

  async postUserDeleteSite(userId: string) {
    //Post the resend invite
    let response = await this.axiosInstance({
      method: "POST",
      url: `${process.env.REACT_APP_BACKEND_URL}${ApiRoutes.SiteAdministration}${ApiRoutes.SiteAdministrationRoutes.DeleteUser}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: {
        userId: userId,
      },
    });
    return response.data;
  }

  async getAllAuthTokens() {
    let response = await this.axiosInstance({
      method: "GET",
      url: `${ApiRoutes.AuthTokens}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data as Array<AdminAuthTokenReadDto>;
  }

  async createAuthToken(descriptions: string) {
    let response = await this.axiosInstance({
      method: "POST",
      url: `${ApiRoutes.AuthTokens}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: {name: descriptions},
    });
    return response.data
  }

  async deleteAuthToken(authTokenId: string) {
    let response = await this.axiosInstance({
      method: "DELETE",
      url: `${ApiRoutes.AuthTokens}/${authTokenId}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      }
    });
    return response.data;
  }

  static get instance() {
    return adminApiService;
  }
}

const adminApiService = new AdministrationAPIService();
export default adminApiService;
