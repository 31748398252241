export const nameValidator = (value: string) =>
!value
? "Name is required"
: value.length < 2
? "Name should be at least 2 characters long."
: "";

export const consumableValidator = (value: string) =>
!value
? "Consumable is required"
: "";