
import { SciChartReact } from "scichart-react";
import React, {  } from "react";
import { EndpointReportChartData, getChartAPI } from "./EndpointReportChartApi";
import { AppContext } from "../../../../AppContext";
import { getCurrentLanguage } from "../../../Shared/SciChart/ChartConfiguration";

export const EndpointReportChart = (props: EndpointReportChartData) => {

  const {localeId} = React.useContext<any>(AppContext);
  const currentLanguage = getCurrentLanguage(localeId);
  const [EndpointReportChartApi] = React.useState(getChartAPI(props.requirementsData, props.statusData, props.chartSynchroniser, props.dateFilter, currentLanguage));

  return (
    <div className="flex">
      <SciChartReact
        initChart={EndpointReportChartApi.initChart}
        onDelete={EndpointReportChartApi.onDelete}
        style={{aspectRatio: 2, width:"100%", height: "40px"}}
        // customize size of the inner chart wrapper
        innerContainerProps={{ style: { height: "100%" } }}
        fallback={<div/>}
      >
      </SciChartReact>
    </div>
  );
}