import { ScheduleUpdateDto } from "./../../../Models/Schedule/ScheduleUpdateDto";
import { ScheduleCreateDto } from "./../../../Models/Schedule/ScheduleCreateDto";
import { ScheduleReadDto } from "./../../../Models/Schedule/ScheduleReadDto";
import { ApiRoutes } from "../../../Constants/ApiRoutingConstants";
import axios, { AxiosInstance } from "axios";
import authorizeService from "../../Identity/Utilities/AuthorizeService";

/**
 * This service provides implementations for http calls to the api pertaining to schedule crud functionality
 */

class SchedulesApiService {
  axiosInstance: AxiosInstance;
  constructor() {
    //Subscribe to identity store the get token
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_SCHEDULE_SERVICE_URL,
    });
  }

  async getAllSchedules(): Promise<ScheduleReadDto[]> {
    let response = await this.axiosInstance({
      method: "GET",
      url: `${ApiRoutes.Schedules}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data;
  }

  async createSchedule(schedule: ScheduleCreateDto) {
    let response = await this.axiosInstance({
      method: "POST",
      url: `${ApiRoutes.Schedules}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: schedule,
    });
    return response.data as ScheduleReadDto;
  }

  async updateSchedule(schedule: ScheduleCreateDto, scheduleId: string) {
    let response = await this.axiosInstance({
      method: "PUT",
      url: `${ApiRoutes.Schedules}/${scheduleId}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: schedule,
    });
    return response.data as ScheduleReadDto;
  }

  async updateSchedules(schedules: Array<ScheduleUpdateDto>) {
    let response = await this.axiosInstance({
      method: "PUT",
      url: `${ApiRoutes.Schedules}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: { schedule: schedules },
    });
    return response.data;
  }

  async deleteSchedules(scheduleDeleteIds: Array<string>) {
    let response = await this.axiosInstance({
      method: "DELETE",
      url: `${ApiRoutes.Schedules}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: {
        scheduleIds: scheduleDeleteIds,
      },
    });
    return response.data;
  }

  static get instance() {
    return schedulesApiService;
  }
}

const schedulesApiService = new SchedulesApiService();
export default schedulesApiService;
