import { SciChartNestedOverview, SciChartReact } from "scichart-react";
import { useLocalization } from "@progress/kendo-react-intl";
import React, { useEffect, useRef } from "react";
import { AppContext } from "../../../../../AppContext";
import { ChartOptions, Props, getChartAPI } from "./ComplianceChartApi";
import { getCurrentLanguage } from "../../../../Shared/SciChart/ChartConfiguration";
import { overviewOptions } from "../../../../Shared/SciChart/Overview";
import useChartStore from "../../../../../Zustand/chartStore";

export const ComplianceChart = (props: Props) => {

  const localizationService = useLocalization();
  const {localeId} = React.useContext<any>(AppContext);
  const currentLanguage = getCurrentLanguage(localeId);
  const isAutoRange = useChartStore(state => state.isAutoRange);
  const isBarChartOn = useChartStore(state => state.isBarChartOn);
  const isThresholdsOn = useChartStore(state => state.isThresholdsOn);
  const setVisibleRangeChartStore = useChartStore(state => state.setVisibleRange);
  const setVisibleSeriesChartStore = useChartStore(state => state.setVisibleSeriesCompliance);

  // Fetch the initial state, but we don't need to re-render when it changes as the chart will update itself
  const visibleSeriesRef = useRef(useChartStore.getState().visibleSeriesCompliance);

  const setVisibleRange = (range:  {min: number | undefined; max: number | undefined; }) => {
    setVisibleRangeChartStore(range.min, range.max);
  };

  const setVisibleSeries = (id: string, isVisible: boolean) => {
    setVisibleSeriesChartStore(id, isVisible);
  };

  var chartOptions : ChartOptions = {isAutoRange: isAutoRange, isBarChartOn: isBarChartOn, setVisibleRange: setVisibleRange, visibleRange: props.chartData.visibleRange, setVisibleSeriesCompliance: setVisibleSeries, visibleSeriesCompliance: visibleSeriesRef.current, isThresholdsOn: isThresholdsOn};
  
  const [complianceChartAPI] = React.useState(getChartAPI(props, chartOptions, localizationService, currentLanguage));

  useEffect(() => {
    complianceChartAPI.changeChartType(isBarChartOn,visibleSeriesRef.current);
  }, [complianceChartAPI, isBarChartOn]);

  useEffect(() => {
    complianceChartAPI.showThresholds(isThresholdsOn);
  }, [complianceChartAPI, isThresholdsOn]);

  useEffect(() => {
    complianceChartAPI.setAutoRange(isAutoRange);
  }, [complianceChartAPI, isAutoRange]);

  return (
    <div className="flex">
      <SciChartReact
        onInit={complianceChartAPI.configAfterInit}
        initChart={complianceChartAPI.initChart}
        style={{aspectRatio: 2, width:"100%", height: "450px"}}
          //  customize size of the inner chart wrapper
          innerContainerProps={{ style: { height: "85%" } }}
          // Create a fallback component with an empty div to prevent a black background initially appearing. 
          fallback={<div/>}
      >
        <SciChartNestedOverview style={{ height: "14%" }} options={overviewOptions}/>
      </SciChartReact>
    </div>
  );
}
