import React, { useRef, useEffect, useMemo, ReactElement } from "react";
import useIdentityStore from "../../../Zustand/identityStore";
import useKendoTableState from "../../Shared/Hooks/useKendoTableState";
import { Grid, GridFilterChangeEvent, GridPageChangeEvent, GridSortChangeEvent, GridToolbar, GridColumn as Column,  GridRowProps } from "@progress/kendo-react-grid";
import { CompositeFilterDescriptor, filterBy, orderBy } from "@progress/kendo-data-query";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import GBKendoToolbar from "../../Shared/Components/Table/GBKendoToolbar";
import { Button } from "@progress/kendo-react-buttons";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";
import { useInternationalization } from "@progress/kendo-react-intl";
import ReservoirAddModal from "./ReservoirAddModal";
import { ConsumableReadTableDto } from "../../../Models/Consumable/ConsumableReadTableDto";
import { PointReadDto } from "../../../Models/Point/PointReadDto";
import EndpointReadDto from "../../../Models/Endpoint/EndpointReadDto";
import ReservoirViewModal from "./ReservoirViewModal";
import { Popover, PopoverActionsBar } from "@progress/kendo-react-tooltip";
import ReservoirLinked from "./ReservoirLinked";
import { ReservoirReadDto } from "../../../Models/Reservoirs/ReservoirReadDto";
import { ReservoirCreateDto } from "../../../Models/Reservoirs/ReservoirCreateDto";
import { ReservoirUpdateDto } from "../../../Models/Reservoirs/ReservoirUpdateDto";
import DeleteDialog from "../../Shared/Components/Dialogs/DeleteDialog";


interface Props {
  extraInlineActions: null;
  handleSubmit: any;
  initialSort: any;
  initialSortDescriptor: any;
  reservoirs: Array<ReservoirReadDto>;
  consumables: Array<ConsumableReadTableDto>;
  points:  Array<PointReadDto>;
  endpoints: Array<EndpointReadDto>;
  createReservoir: (reservoirCreateDto: ReservoirCreateDto) => void;
  updateReservoir: (reservoirId: string, updateReservoir: ReservoirUpdateDto) => void;
  deleteReservoir: (reservoirId: string) => void;
  height: number;
}

const initialFilter: CompositeFilterDescriptor = {
  logic: "and",
  filters: [],
};

function ReservoirsTable(props: Props) {
  const [addReservoir, setAddReservoir] = React.useState<boolean>(false);
  const [viewReservoir, setViewReservoir] = React.useState<boolean>(false);
  const [reservoirToUpdate, setReservoirToUpdate] = React.useState<ReservoirReadDto | null>(null);
  const [reservoirToDelete, setReservoirToDelete] = React.useState<ReservoirReadDto | null>(null);
  const [reservoirId, setReservoirId] = React.useState<string>('');

  const currentUserRole = useIdentityStore((x) => x.user?.profile.role);
  const tableState = useKendoTableState<ReservoirReadDto>({
    key: "Reservoirs",
    initialAssets: props.reservoirs,
  });
  const localizationService = useLocalization();
  const intl = useInternationalization();
  

  const closeHandler = () => {
    setReservoirToUpdate(null);
    setReservoirId('')
    setAddReservoir(false);
    setViewReservoir(false)
  };

  const stateRef = useRef(tableState);

  useEffect(() => {
    stateRef.current = tableState

   }, [tableState]);


  const [filter, setFilter] = React.useState(initialFilter);
  const [sort, setSort] = React.useState(props.initialSortDescriptor ?? props.initialSort);
  const _export = React.useRef<ExcelExport | null>(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };
  const ref = useRef<any>();

  /**
   * Set the skip amount to zero if the number of temp rows (indicating an add row has been added).
   * This effectively ensures the add row is always scrolled into view
   */
  useEffect(() => {
    if (tableState.tempRows.length === 1) {
      setSkip(0);
    }
  }, [tableState.tempRows.length]);

  //The following state is required for dynamic scrolling
  const [skip, setSkip] = React.useState<number>(0);
  const pageChange = (event: GridPageChangeEvent) => {
    setSkip(event.page.skip);
  };

  useEffect(() => {
    // Reset skip when filter or sort changes
    setSkip(0);
  }, [filter, sort]);

  //Data is API data + current temporary rows that have not yet been persisted using the API
  const processedData = useMemo(() => {

    let initialRows = tableState.assets;

    if (filter) {
      initialRows = filterBy(initialRows, filter);
    }

    if (sort) {
      initialRows = orderBy(initialRows, sort);
    }
    //Add any temp rows to beggining
    return tableState.tempRows.concat(initialRows);
  }, [tableState.assets, tableState.tempRows, filter, sort]);

  const LinkedCell = (props: any) => {
    const anchor = React.useRef<Button>(null);
    const [show, setShow] = React.useState(false);
    return (
      <td>
        <Button
          fillMode={'link'}
          ref={anchor}
          style={{ color: '#388DF9' }}
          onClick={() => setShow(!show)}
        >
          {props.dataItem.linkedPointCount}
        </Button>
        <Popover
          show={show}
          anchor={anchor.current && anchor.current.element}
          position={'bottom'}
          collision={{vertical: "flip", horizontal:"flip"}}
        >
          <ReservoirLinked reservoirId={props.dataItem.id}/>
          <PopoverActionsBar>
            <div className="grid justify-items-end">
              <Button onClick={() => setShow(false)}>{localizationService.toLanguageString('custom.close', enMessages.custom.close)}</Button>
            </div>
          </PopoverActionsBar>
        </Popover>
      </td>
    );
  };

  const NameCell = (props: any) => {
    return (
      <td>
       <Button fillMode={"link"} style={{color:"#388DF9"}} onClick={() => {
              setViewReservoir(true)
              setReservoirId(props.dataItem.id)}} >{props.dataItem.name}</Button>
      </td>
    );
  };

  const CapacityCell = (props: any) => {
    return (
      <td>
        {props.dataItem.capacity} {props.dataItem.displayUnit}
      </td>
    );
  };

  const onDelete = () => {
  
    if (reservoirToDelete != null)
    {
      props.deleteReservoir(reservoirToDelete.id);
    }
    
    setReservoirToDelete(null);
  };

  return (
    <div>
      <ExcelExport data={processedData} ref={_export} collapsible={true} fileName={localizationService.toLanguageString('custom.reservoirs', enMessages.custom.reservoirs)}>
        <Grid
          key={tableState.tempRows.length.toString()}
          rowHeight={46}
          total={processedData.length}
          data={processedData}
          scrollable="virtual"
          skip={skip}
          onPageChange={pageChange}
          expandField="expanded"
          filterable={true}
          pageSize={30}
          filter={filter}
          onFilterChange={(e: GridFilterChangeEvent) => setFilter(e.filter)}
          sortable={true}
          sort={sort}
          onSortChange={(e: GridSortChangeEvent) => {
            setSort(e.sort);
          }}
          style={{
            height: props.height,
          }}
          className="w-full  z-0 extra-filter-button"
          //Custom render row,
          rowRender={(trElement: ReactElement, props: GridRowProps) => {
            let baseStyle = { ...trElement.props.style };
            if (tableState.modifiedRowIds.includes(props.dataItem.id)) {
              baseStyle.backgroundColor = "rgba(255, 252, 88, 0.25)";
            }
            if (tableState.selectedRowsIds.includes(props.dataItem.id)) {
              baseStyle.backgroundColor = "rgba(255, 99, 88, 0.25)";
            }

            return React.cloneElement(
              trElement,
              {
                ...trElement,
                style: { ...baseStyle },
              },
              trElement.props.children
            );
          }}
        >
          <GridToolbar>  
            <GBKendoToolbar
              name="Reservoirs"
              tableState={tableState}
              exportAction={excelExport}
              importAction={null}
              addAction={null}
              updateAction={null}
              deleteAction={null}
              discardChanges={null}
              extraToolbarActions={[
                {
                  icon: "add",
                  text: "Add Reservoir",
                  onClick: () => setAddReservoir(true),
                  themeColor: "primary",
                },
              ]}
            />
          </GridToolbar>
          <Column field="name" title="Name" cell={NameCell} ></Column>
          <Column field="description" title="Description" ></Column>
          <Column field="capacity" title="Capacity" cell={CapacityCell}></Column>
          <Column field="consumableName" title="Consumable" ></Column>
          <Column field="linkedPointCount" title={localizationService.toLanguageString('custom.linkedPoints', enMessages.custom.linkedPoints)} cell={LinkedCell} ></Column>

          {currentUserRole !== "GeneralUser" && (
          <Column
            title={localizationService.toLanguageString('custom.actions', enMessages.custom.actions)}
            width="110px"
            sortable={false}
            filterable={false}
            cell={(cellProps) => {
              return (
                <td className="flex justify-around gap-1">
                <Button
                  className=""
                  themeColor={"primary"}
                  onClick={async () => {setReservoirToUpdate(cellProps.dataItem)}}
                >
                  {localizationService.toLanguageString('custom.edit', enMessages.custom.edit)}
                </Button>
                <Button
                  className=""
                  themeColor={"primary"}
                  onClick={async () => {setReservoirToDelete(cellProps.dataItem)}}
                >
                  {localizationService.toLanguageString('custom.delete', enMessages.custom.edit)}
                </Button>
                </td>
              );
            }}
          />)}

        </Grid>
      </ExcelExport>
    
      {reservoirToDelete != null && (
        <DeleteDialog 
          title={`${localizationService.toLanguageString('custom.deleteReservoir', enMessages.custom.deleteReservoir)}`}
          deletingItemLabel={reservoirToDelete?.name} itemCount={0} open={reservoirToDelete != null} 
          onClose={() => setReservoirToDelete(null)}
          deleteCallback={onDelete}
          children={undefined}
        />
      )}
    
      {(addReservoir || reservoirToUpdate != null) && (
        <ReservoirAddModal
          key={(reservoirToUpdate || addReservoir).toString()}
          createReservoir={props.createReservoir}
          reservoir={reservoirToUpdate}
          updateReservoir={props.updateReservoir}
          addReservoir={addReservoir}
          onClose={() => closeHandler()}
          consumables={props.consumables}
          points={props.points}
          endpoints={props.endpoints}
          linkedPoints={[]}
          selectedItem={null}
        />
      )}

      {viewReservoir && (
        <ReservoirViewModal
        key={'ReservoirView'}
        reservoirId={reservoirId}
        viewReservoir={viewReservoir}
        onClose={() => closeHandler()}/>
      )}
    </div>
  );
}

export default ReservoirsTable;