import * as yup from "yup";

export default yup.object().shape({
  name: yup.string().min(2).max(48).required(),
  //Transform parent to null if empty string
  parentId: yup
    .string()
    .nullable()
    .min(2)
    .max(48)
    .transform((value, original) => {
      //We transform an empty string in parent to null.
      return value === "" ? null : value;
    }),
  status: yup.string().required(),
});
