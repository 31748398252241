import {Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useClipboard} from '@chakra-ui/react';
import {ReactElement, useState} from 'react'
import exportToCsv from '../../../Shared/Utilities/CsvUtilities';
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../../messages/en-US";

interface Props {
    newDeviceCredentials: {
        guid: string,
        key: string
    } | null,
    setNewDeviceCredentials: (newCredentials: any) => void
}

export default function DeviceCreatedModal({newDeviceCredentials, setNewDeviceCredentials}: Props): ReactElement {
    const [showCredentials, setShowCredentials] = useState<boolean>(false);
    const {hasCopied, onCopy} = useClipboard(newDeviceCredentials?.key ?? "")
    const exportDeviceCredentials = () => {
        const headers = ["GUID", "API KEY", "EXPORTED AT"];
        const data = [[newDeviceCredentials?.guid ?? "", newDeviceCredentials?.key ?? "", Date.now().toLocaleString().replace(",","")]]
        // eslint-disable-next-line no-restricted-globals
        exportToCsv(headers, data, `${newDeviceCredentials!.guid}.csv`, location)
    }

    const localizationService = useLocalization();

    return (
        <Modal
            closeOnOverlayClick={false}
            isOpen={newDeviceCredentials != null}
            onClose={() => {
                setNewDeviceCredentials(null)
            }}>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader className="gb-h2">{localizationService.toLanguageString('custom.deviceCreation', enMessages.custom.deviceCreation)}</ModalHeader>

                <ModalBody>
                    <div className="gb-text-normal mb-2">
                    {localizationService.toLanguageString('custom.deviceCreationText1', enMessages.custom.deviceCreationText1)}
                        <strong>{localizationService.toLanguageString('custom.deviceCreationText2', enMessages.custom.deviceCreationText2)}</strong>
                    </div>
                    <div>
                        {/*GUID*/}
                        <label htmlFor="GUID">GUID</label>
                        <input className="form-field" name="GUID" value={newDeviceCredentials?.guid}/>
                        {/*Secret Key*/}
                        <div className="flex flex-row">
                            <label htmlFor="KEY">{localizationService.toLanguageString('custom.secretKey', enMessages.custom.secretKey)}</label>
                            <button className="px-1 text-sm text-blue-900 hover:underline"
                                    onClick={onCopy}>
                                {hasCopied ? "Copied" : "Copy"}
                            </button>
                        </div>
                        <div className="flex flex-row">
                            <input className="form-field flex-1" name="KEY" value={newDeviceCredentials?.key}
                                   type={showCredentials ? "text" : "password"}/>
                            <button className="text-sm px-1 text-blue-900 hover:underline"
                                    onClick={() => {
                                        setShowCredentials(!showCredentials);
                                    }}>
                                {showCredentials ? "Hide" : "Show"}
                            </button>
                        </div>
                    </div>
                </ModalBody>

                <ModalFooter>
                    <button className="gb-button mr-2"
                            onClick={() => {
                                exportDeviceCredentials()
                            }}>
                        {localizationService.toLanguageString('custom.exportCSV', enMessages.custom.exportCSV)}
                    </button>
                    <button className="gb-button-warning" onClick={() => {
                        setNewDeviceCredentials(null)
                    }}>
                        {localizationService.toLanguageString('custom.close', enMessages.custom.close)}
                    </button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
