import {
    XyDataSeries,
    SciChartSurface,
    ZoomPanModifier,
    MouseWheelZoomModifier,
    ZoomExtentsModifier,
    RolloverModifier,
    EXyDirection,
    LegendModifier,
    TrianglePointMarker,
    NumberRange,
    NumericAxis,
    DateTimeNumericAxis,
    FastLineRenderableSeries,
    XyScatterRenderableSeries,
    Thickness,
    IRenderableSeries,
} from "scichart";
import { TInitFunction } from "scichart-react";
import { enMessages } from "../../../../../messages/en-US";
import { LinePaletteProvider, TriangleProvider } from "../PaletteProvider";
import moment from "moment";
import { axisLabelDateFormatter } from "../../../../Shared/SciChart/SmartAxis/axisLabelDateFormatter";
import { GetChartTheme } from "../../../../Shared/SciChart/ChartConfiguration";
import { DateTickProvider } from "../../../../Shared/SciChart/SmartAxis/DateTickProvider";
import { SmartDateTZLabelProvider } from "../../../../Shared/SciChart/SmartAxis/SmartDateTZLabelProvider";
import { createDateTzConverter } from "../../../../Shared/SciChart/SmartAxis/timezones";

  export type TStatusChartConfigFunc = TInitFunction<SciChartSurface>;

  export interface Props {
    chartData: any
  }

  export interface ChartOptions {
    setVisibleSeriesStatus: (series: any, isChecked: boolean) => void;
    visibleRange: { min: number | undefined; max: number | undefined; };
    visibleSeriesStatus: { [key: string]: boolean;}
  }

  export const getChartAPI = (props:Props, chartOptions:ChartOptions, localizationService: { toLanguageString: (arg0: string, arg1: string) => any; }, currentLanguage: { locale: string; localeId: string; }) => {
  
    const startFilterDateUnix = moment(props.chartData.dateFilter.startDate).unix();
    const endFilterDateUnix = moment(props.chartData.dateFilter.endDate).unix();
    const theme = GetChartTheme();  
  
    // TODO: If we have a visible range specified then we need to check if its valid for the endpoint and if no make adjustments
   /* if(visibleRange.min === undefined || visibleRange.max === undefined) {
      visibleRange = GetVisibleRange(props.chartData.timestamps, endFilterDateUnix, startFilterDateUnix);
    }*/

    const configAfterInit = () => {/* Do nothing */} 

    const initChart : TStatusChartConfigFunc = async (rootElement: string | HTMLDivElement) => {
      const {sciChartSurface, wasmContext} = await SciChartSurface.create(rootElement, {theme: theme});

      // #region Setup Tooltip Template
        const getTooltipTemplateStatus = (seriesInfo: any, tooltipTitle: string, tooltipLabelX: string, tooltipLabelY: string) => {
        // Lines here are returned to the tooltip and displayed as text-line per tooltip
        const lines: string[] = [];
        const codeReasonData = [
          { text: `${localizationService.toLanguageString('custom.other', enMessages.custom.other)}`, id: 1},
          { text: `${localizationService.toLanguageString('custom.maintenancePlanned', enMessages.custom.maintenancePlanned)}`, id: 2},
          { text: `${localizationService.toLanguageString('custom.maintenanceUnplanned', enMessages.custom.maintenanceUnplanned)}`, id: 3},
          { text: `${localizationService.toLanguageString('custom.operationalShutdownPlanned', enMessages.custom.operationalShutdownPlanned)}`, id: 4},
          { text: `${localizationService.toLanguageString('custom.operationalShutdownUnplanned', enMessages.custom.operationalShutdownUnplanned)}`, id: 5}, 
          { text: `${localizationService.toLanguageString('custom.online', enMessages.custom.online)}`, id: 0},
        ];

        const reasonText = codeReasonData.filter(x => x.id === seriesInfo.pointMetadata?.reasonCode)[0].text;  

        let textColour = "white"
        let fillColour = "#00A2E8";
        if(seriesInfo.pointMetadata?.reasonCode !== 0) {
          textColour = "black"
          fillColour = "#C3C3C3";
        }

        lines.push(`${reasonText} `)
        lines.push(`x: ${seriesInfo.formattedXValue}`)

        seriesInfo.renderableSeries.rolloverModifierProps.tooltipTextColor = textColour; 
        seriesInfo.renderableSeries.rolloverModifierProps.tooltipColor = fillColour; 
        
        return lines
      };

      const getTooltipTemplateAlerts = (seriesInfo: any, tooltipTitle: string, tooltipLabelX: string, tooltipLabelY: string) => {
        // Lines here are returned to the tooltip and displayed as text-line per tooltip
        const lines: string[] = [];
        lines.push(`${tooltipTitle} - ${seriesInfo.pointMetadata?.assignedToName}`);
        lines.push(`x: ${seriesInfo.formattedXValue} ${seriesInfo.pointMetadata?.text ?? null} `)
  
          let fillColour
          let textColour = "white"
  
          if (seriesInfo.pointMetadata?.alert === "Critical") {
            fillColour = "rgba(120, 0, 0, 1)";
          } else if(seriesInfo.pointMetadata?.alert === "High") {
            fillColour = "rgba(220, 0, 0, 1)";
          } else if(seriesInfo.pointMetadata?.alert === "Medium") {
            fillColour = "rgba(253, 140, 0, 1)";
          } else {
            fillColour = "rgba(253, 197, 0, 1)";
            textColour = "black"
          }
  
          seriesInfo.renderableSeries.rolloverModifierProps.tooltipTextColor = textColour; 
          seriesInfo.renderableSeries.rolloverModifierProps.markerColor = fillColour;
          seriesInfo.renderableSeries.rolloverModifierProps.tooltipColor = fillColour; 
  
        return lines;
      };
      // #endregion

      // #region Setup the XAxis
      const xAxis = new DateTimeNumericAxis(wasmContext, { 
        drawLabels: false,
        drawMajorBands: false, 
        drawMinorGridLines: false, 
        drawMajorGridLines: false, 
        isVisible: true,
        visibleRange: new NumberRange(chartOptions.visibleRange?.min, chartOptions.visibleRange?.max),
        visibleRangeLimit: new NumberRange(startFilterDateUnix,endFilterDateUnix),
        growBy: new NumberRange(0.05, 0.05),
        labelStyle: {fontFamily:"Roboto", fontSize:12,}
      },);
  
      // Overrides getDeltaFromRange to get nice major and minor deltas for the ticks
      xAxis.deltaCalculator.getDeltaFromRange = axisLabelDateFormatter.getDeltaFromRange;
    
      const updateSettings = (xAxis: NumericAxis) => {
        const timezone = moment.tz.guess();
        // Calculate ticks which respect the time zone
        xAxis.tickProvider = new DateTickProvider(wasmContext, timezone);
        // Draws nice axis labels timezone and locale specific
        xAxis.labelProvider = new SmartDateTZLabelProvider(timezone,true, currentLanguage?.localeId);
        const dateTzConverter = createDateTzConverter(timezone);
        // This is needed for Cursor and Rollover chart modifiers
        xAxis.labelProvider.formatCursorLabel = timestamp =>
            axisLabelDateFormatter.toFullDateTime(timestamp, dateTzConverter, currentLanguage?.localeId);
        };
      // Setting timezone to local system
      updateSettings(xAxis);

      sciChartSurface.xAxes.add(xAxis);
      // #endregion

      // #region Setup the YAxis
        const yAxisOnOff = new NumericAxis(wasmContext,{
          isVisible: false,
          drawMajorBands: false,
          drawMinorGridLines: false, 
          drawMajorGridLines: false, 
          visibleRange: new NumberRange(-1, 2),
          growBy: new NumberRange(0, 0.2),
          labelStyle: {fontFamily:"Roboto", fontSize:12}
        });
          
        sciChartSurface.yAxes.add(yAxisOnOff);
        // #endregion
  
      // #region Setup the Chart Modifers
      const legend = new LegendModifier({
        placementDivId: "legend-status",
        showCheckboxes: true,
        showSeriesMarkers: true,
        showLegend: true,
        isCheckedChangedCallback: (series: IRenderableSeries, isChecked: boolean) => {chartOptions.setVisibleSeriesStatus(series.id, isChecked);}
      });
      sciChartSurface.chartModifiers.add(legend);

      sciChartSurface.chartModifiers.add(new ZoomPanModifier({ xyDirection: EXyDirection.XDirection }));
      sciChartSurface.chartModifiers.add(new ZoomExtentsModifier({ xyDirection: EXyDirection.XDirection }));
      sciChartSurface.chartModifiers.add(new MouseWheelZoomModifier({ xyDirection: EXyDirection.XDirection }));
      sciChartSurface.chartModifiers.add(new RolloverModifier({ hitTestRadius: 10, allowTooltipOverlapping: true, id: "StatusRollOver" }));

      sciChartSurface.padding = Thickness.fromString("10 0 0 0");

      // #endregion
      
      // #region Setup the Renderable Series
      if(props.chartData.statusData.timestamps.length > 0) {
        const onOffChart= new FastLineRenderableSeries(wasmContext, { paletteProvider: new LinePaletteProvider(), id:"OnlineOfflineId", stroke:"rgba(0,162,232,1)", strokeThickness: 15, isVisible: chartOptions.visibleSeriesStatus.OnlineOfflineId});
        onOffChart.dataSeries = new XyDataSeries(wasmContext, {
          xValues: props.chartData.statusData.timestamps,
          yValues: props.chartData.statusData.yValues,
          metadata: props.chartData.statusData.metadata,
          dataSeriesName: "Online/Offline",
        })
        onOffChart.rolloverModifierProps.tooltipTextColor = "white"
        onOffChart.rolloverModifierProps.tooltipDataTemplate = getTooltipTemplateStatus;
        sciChartSurface.renderableSeries.add(onOffChart);
      }
  
      // ANNOTATIONS CHART TEMPORARY DISABLED
        // const annotationsY =[-0.5, -0.5, -0.5, -0.5, ]
        // const annotationsTimestamps = [1698011778, 1697838978, 1698789378, 1699221378,]
  
        //   const annotationsChart =  new XyScatterRenderableSeries(wasmContext, {
        //     dataSeries: new XyDataSeries(wasmContext, {xValues:annotationsTimestamps, yValues:annotationsY, dataSeriesName: "Annotations" }),
        //     pointMarker: new EllipsePointMarker(wasmContext, {
        //         width: 5,
        //         height: 5,
        //         strokeThickness: 0,
        //         fill: "rgba(0,0,0,1)",
        //         stroke: "rgba(0,0,0,1)"
        //     }),
        //     id:"AnnotationsId",
        //     stroke: "rgba(0,0,0,1)",
        // })
        // annotationsChart.rolloverModifierProps.tooltipTextColor = "white"
        // sciChartSurface.renderableSeries.add(annotationsChart);
        // annotationsChart.isVisible = visibleSeriesCompliance.AnnotationsId;
  
      if(props.chartData?.alerts?.timestamps?.length > 0) {
  
        const alertsChart =   new XyScatterRenderableSeries(wasmContext, {
          dataSeries: new XyDataSeries(wasmContext, {xValues: props.chartData.alerts.timestamps, yValues: props.chartData.alerts.yValues, dataSeriesName: "Alerts", metadata: props.chartData.alerts.metadata, }),
          paletteProvider: new TriangleProvider(),
          pointMarker: new TrianglePointMarker(wasmContext, {
            width:8,
            height:8,
        }),
          id:"AlertsId",
          stroke: "rgba(253, 140, 0, 1)",
          isVisible: chartOptions.visibleSeriesStatus.AlertsId,
        })
        alertsChart.rolloverModifierProps.tooltipDataTemplate = getTooltipTemplateAlerts;
        sciChartSurface.renderableSeries.add(alertsChart);
      }
     
      // #endregion
  
      return { sciChartSurface: sciChartSurface };
    }

    return {initChart, configAfterInit};
}