import * as React from 'react';
import { GridCellProps } from "@progress/kendo-react-grid";
import { SelectionContext, IsSelectedContext } from "./RouteTable";
import { Button } from '@progress/kendo-react-buttons';

interface CustomCellProps extends GridCellProps {
  isTableEditable: boolean;
}

export const DragHandleCell = (props: CustomCellProps) => {

  const [selection, setSelection] = React.useContext(SelectionContext);
  const isSelected = React.useContext(IsSelectedContext);

  const handleMouseDown = () => {
    if (!isSelected) {
      setSelection([...selection, props.dataItem]);
    }
  };

  const handleClick = () => {
    if (isSelected) {
    setSelection([...selection.filter((item) => item.id !== props.dataItem.id),]);
    }
  };

  const handleMouseUp = () => {
    if (isSelected) {
    setSelection([...selection.filter((item) => item.id !== props.dataItem.id),]);
    }
  };

   return (
    (props.isTableEditable ? 
    <td >
      <div style={{textAlign:"center"}}>
        <Button 
          className="buttons-container-button" 
          iconClass="k-icon k-i-reorder"
          fillMode="flat"
          onMouseDown={handleMouseDown}
          onClick={handleClick}
          onMouseUp={handleMouseUp}>
          </Button>
      </div>
    </td> 
    : <td></td>)
  );
};

