import { SciChartNestedOverview, SciChartReact } from "scichart-react";
import { useLocalization } from "@progress/kendo-react-intl";
import React, { useEffect, useRef } from "react";
import { AppContext } from "../../../../../AppContext";
import { ChartOptions, Props, getChartAPI } from "./ActivityPressureChartApi";
import { getCurrentLanguage } from "../../../../Shared/SciChart/ChartConfiguration";
import { overviewOptions } from "../../../../Shared/SciChart/Overview";
import useChartStore from "../../../../../Zustand/chartStore";

export const ActivityPressureChart = (props: Props) => {

  const localizationService = useLocalization();
  const {localeId} = React.useContext<any>(AppContext);
  const currentLanguage = getCurrentLanguage(localeId);
  const isBarChartOn = useChartStore(state => state.isBarChartOn);
  const isAutoRange = useChartStore(state => state.isAutoRange);
  const aggregator = useChartStore(state => state.aggregator);
  const isSinglePressureChart = useChartStore(state => state.isSinglePressureChart);
  const setVisibleRangeChartStore = useChartStore(state => state.setVisibleRange);
  const setVisibleSeriesChartStore = useChartStore(state => state.setVisibleSeriesActivityPressure);
  
  const visibleSeriesRef = useRef(useChartStore.getState().visibleSeriesActivityPressure);

  const setVisibleRange = (range:  {min: number | undefined; max: number | undefined; }) => {
    setVisibleRangeChartStore(range.min, range.max);
  };

  const setVisibleSeries = (id: string, isVisible: boolean) => {
    setVisibleSeriesChartStore(id, isVisible);
  };


  var chartOptions : ChartOptions = {isAutoRange: isAutoRange, isBarChartOn: isBarChartOn, setVisibleRange: setVisibleRange, visibleRange: props.chartData.visibleRange, setVisibleSeriesActivityPressure: setVisibleSeries, visibleSeriesActivityPressure: visibleSeriesRef.current, isSinglePressureChart: isSinglePressureChart, aggregator: aggregator};

  const [activityPressureChartAPI] = React.useState(getChartAPI(props, chartOptions, localizationService, currentLanguage));


  useEffect(() => {
    activityPressureChartAPI.setAutoRange(isAutoRange);
  }, [activityPressureChartAPI, isAutoRange]);

  useEffect(() => {
    activityPressureChartAPI.setIsSingleMode(isSinglePressureChart);
  }, [activityPressureChartAPI ,isSinglePressureChart]);

  return (
    <div className="flex">
      <SciChartReact
        key={"activityAssetChart"}
        onInit={activityPressureChartAPI.configAfterInit}
        initChart={activityPressureChartAPI.initChart}
        style={{aspectRatio: 2, width:"100%", height: "500px"}}
          //  customize size of the inner chart wrapper
          innerContainerProps={{ style: { height: "85%" } }}
      >
        <SciChartNestedOverview style={{ height: "14%" }} options={overviewOptions}/>
      </SciChartReact>
    </div>
  );
}