import React, { ReactElement, useEffect, useState } from "react";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";
import moment from "moment";
import { SortDescriptor, process, State } from "@progress/kendo-data-query";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { Grid, GridColumn as Column, GridToolbar} from "@progress/kendo-react-grid";
import ActionReadDto from "../../../Models/Action/ActionReadDto";
import { CustomTextFilterCell } from "../../Shared/Components/Table/CustomTextFilterCell";
import { Button } from "@progress/kendo-react-buttons";
import { Popover, PopoverActionsBar, Tooltip } from "@progress/kendo-react-tooltip";
import EventsPerAction from "./EventsPerActionTable";

interface Props {
  actions: Array<ActionReadDto>
  height: number
}

interface Page {
  skip: number;
  take: number;
}

const page: Page = { skip: 0, take: 30 };

export default function ActionTable(props: Props): ReactElement {
  const localizationService = useLocalization();
  const initialSort: Array<SortDescriptor> = [{ field: "dueDate", dir: "desc" }];
  const [sort] = React.useState(initialSort);
  const _export = React.useRef<ExcelExport | null>(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  //Creating dataState to prevent issues with virtual scrolling
  const createAppState = (dataState: State) => {
    return {
      result: process(props.actions, dataState),
      dataState: dataState,
    };
  };

  const [dataState, setDataState] = useState<any>(
    createAppState({
      skip: page.skip,
      take: page.take,
      sort: sort
    })
  );
  
  const dataStateChange = (event) => {
    setDataState(createAppState(event.dataState));
  };

   useEffect(() => {
    setDataState( createAppState({
      skip: page.skip,
      take: page.take,
      sort: sort
    }));
  }, [props]);

  //Formating Cells
  const DueDateCell = (props: any) => {
    return (
      <td>
        <div>{moment(props.dataItem.dueDate).format('DD/MM/YYYY')}</div>
      </td>
      );
  }

  const ExpiryDateCell = (props: any) => {
    if(props.dataItem.rightTime) {
      return (
        <td style={{textAlign: 'center',}}>
          <div style={{width:"95%", color:"white", background:"#1ab394", display:"inline-block", borderRadius:"5px"}} >{moment(props.dataItem.expiryDate).format('DD/MM/YYYY')}</div>
        </td>
        );
    } else {
      return (
        <td style={{textAlign: 'center',}}>
        <div style={{width:"95%", color:"white", background:"#ff5034", display:"inline-block", borderRadius:"5px"}}>{moment(props.dataItem.expiryDate).format('DD/MM/YYYY')}</div>
      </td>
      )
    }
  }

  const CompliantCell = (props: any) => {
    if(props.dataItem.compliant) {
      return (
        <td>
            <div className="k-icon  k-i-check compliant-icon" style={{color:"#FFFFFF", background: '#1ab394', width: '26px', height: '26px', borderRadius:"5px"}} ></div> 
        </td>
        );
    } else {
      return (
        <td>
          <div className="k-icon  k-i-x compliant-icon" style={{color: "white", background: '#ff5034', width: '26px', height: '26px', borderRadius:"5px"}} ></div>
        </td>
      )
    }
  }

  const ConsumableCell = (props: any) => {
    if(props.dataItem.rightGrease) {
      return (
        <td style={{textAlign: 'center',}}>
            <div style={{width:"95%", color:"white", background:"#1ab394", display:"inline-block", borderRadius:"5px"}}>{props.dataItem.expectedConsumableName}</div> 
        </td>
        );
    } else {
      return (
        <td style={{textAlign: 'center',}}>
            <div style={{width:"95%", color:"white", background:"#ff5034", display:"inline-block", borderRadius:"5px"}}>{props.dataItem.expectedConsumableName}</div>
        </td>
      )
    }
  }

  const VolDeliveredCell = (props: any) => {
    if(props.dataItem.rightAmount) {
      return (
        <td style={{textAlign: 'center',}}>
            <div style={{width:"95%", color:"white", background:"#1ab394", display:"inline-block", borderRadius:"5px"}}>{props.dataItem.cumulativeVolumeDeliveredRounded}</div> 
        </td>
        );
    } else {
      return (
        <td style={{textAlign: 'center',}} >
            <div style={{width:"95%", color:"white", background:"#ff5034", display:"inline-block", borderRadius:"5px"}}>{props.dataItem.cumulativeVolumeDeliveredRounded}</div>
        </td>
      )
    }
  }

  const EventsCell = (props: any) => {
    const anchor = React.useRef<Button>(null);
    const [show, setShow] = React.useState(false);
    const fillMode = props.dataItem.eventCount === 0 ? 'flat' : 'link'
    const colour = props.dataItem.eventCount === 0 ? '' : '#388DF9' 
    const disableButton = props.dataItem.eventCount === 0 ? true : false 
    return (
      
      <td>
        <Button
          fillMode={fillMode}
          ref={anchor}
          style={{ color: colour }}
          onClick={() => setShow(!show)}
          disabled={disableButton}
        >
          {props.dataItem.eventCount}
        </Button>
        <Popover
          show={show}
          anchor={anchor.current && anchor.current.element}
          position={'bottom'}
          collision={{vertical: "flip", horizontal:"flip"}}
        >
          <EventsPerAction actionId={props.dataItem.id}/>
          <PopoverActionsBar>
            <div className="grid justify-items-end">
              <Button onClick={() => setShow(false)}>{localizationService.toLanguageString('custom.close', enMessages.custom.close)}</Button>
            </div>
          </PopoverActionsBar>
        </Popover>
      </td>
    );
  };

  const getExcelExportData = () => {
    return process(props.actions, {filter: dataState.dataState.filter, sort: dataState.dataState.sort}).data;
  };

  return (
    <div>
      <ExcelExport data={getExcelExportData()} ref={_export} collapsible={true} fileName={localizationService.toLanguageString('custom.tasks', enMessages.custom.tasks)}>
        <ExcelExportColumn field="areaName" title="Location"/>
        <ExcelExportColumn field="machineName" title="Asset"/>
        <ExcelExportColumn field="pointName" title="Point"/>
        <ExcelExportColumn field="scheduleName" title="Schedule"/>     
        <ExcelExportColumn field="volumeRequirement" title="Vol. Requirement"/>
        <ExcelExportColumn field="volumeTolerance" title="Vol. Tolerance" cellOptions={{format: "0%"}}/>
        <ExcelExportColumn field="status" title="Status"/>
        <ExcelExportColumn field="dueDate" title="Start Date"cellOptions={{ format: "dd/mm/yyyy" }} width={100}/>
        <ExcelExportColumn field="expiryDate" title="End Date" cellOptions={{ format: "dd/mm/yyyy" }} width={100}/>
        <ExcelExportColumn field="expectedConsumableName" title="Consumable"/>
        <ExcelExportColumn field="cumulativeVolumeDelivered" title="Vol. Delivered"/>
        <ExcelExportColumn field="compliant" title="Compliant"/>
      </ExcelExport>
      <Grid
        key={props.actions.length.toString()}
        rowHeight={46}
        take={page.take}
        data={dataState.result}
        scrollable={"virtual"}
        filterable={true}
        onDataStateChange={dataStateChange}
        {...dataState.dataState}
        sortable={true}
        style={{
          height: props.height - 55,
        }}
        className="w-full  z-0 extra-filter-button"
      >
        <GridToolbar>  
        <div style={{width:'100%', display:'flex', justifyContent:'space-between'}}>
            <div className="gb-h3 pl-1">{localizationService.toLanguageString('custom.tasks', enMessages.custom.tasks)}</div>
              <Tooltip anchorElement="target" position="bottom">
                <Button
                  title={localizationService.toLanguageString('custom.exportToExcel', enMessages.custom.exportToExcel)}
                  icon="download"
                  disabled={dataState.result.data.length === 0}
                  onClick={excelExport}
                />
              </Tooltip>
          </div>
        </GridToolbar>
        <Column field="areaName" title={localizationService.toLanguageString('custom.location', enMessages.custom.location)} filterCell={CustomTextFilterCell} />
        <Column field="machineName" title={localizationService.toLanguageString('custom.asset', enMessages.custom.asset)} filterCell={CustomTextFilterCell} />
        <Column field="pointName" title={localizationService.toLanguageString('custom.point', enMessages.custom.point)} filterCell={CustomTextFilterCell} />
        <Column field="scheduleName" title={localizationService.toLanguageString('custom.schedule', enMessages.custom.schedule)} filterCell={CustomTextFilterCell} />
        
        <Column field="volumeRequirement" title={localizationService.toLanguageString('custom.volumeRequirement', enMessages.custom.volumeRequirement)} filterCell={CustomTextFilterCell} />
        <Column field="volumeToleranceRounded" title={localizationService.toLanguageString('custom.volumeTolerance', enMessages.custom.volumeTolerance)} filterCell={CustomTextFilterCell} />
        <Column field="statusCell" title={localizationService.toLanguageString('custom.status', enMessages.custom.status)} filterCell={CustomTextFilterCell}/>
        <Column field="eventCount" title={"Events"} cell={EventsCell} filterCell={CustomTextFilterCell}/>
        <Column field="dueDateWithoutTime" title={localizationService.toLanguageString('custom.startDate', enMessages.custom.startDate)} filterable={false} cell={DueDateCell}/>
        <Column title={localizationService.toLanguageString('custom.compliance', enMessages.custom.compliance)} >
          <Column field="expiryDateWithoutTime" title={localizationService.toLanguageString('custom.endDate', enMessages.custom.endDate)}  filterable={false} cell={ExpiryDateCell}/>
          <Column field="expectedConsumableName" title={localizationService.toLanguageString('custom.consumable', enMessages.custom.consumable)} cell={ConsumableCell} filterCell={CustomTextFilterCell}/>
          <Column field="cumulativeVolumeDeliveredRounded" title={localizationService.toLanguageString('custom.volumeDelivered', enMessages.custom.volumeDelivered)} cell={VolDeliveredCell}  filterCell={CustomTextFilterCell}/>
          
          <Column field="compliant" title={localizationService.toLanguageString('custom.compliant', enMessages.custom.compliant)} cell={CompliantCell} filter="boolean"/>
        </Column>
      </Grid>  
  </div>
  );
}