import { SiteReadDto } from './../../../Models/Site/SiteReadDto';
import { ComboBoxItem } from './../../Shared/Components/Forms/ComboBox';
import { OrganisationRole, Role } from '../../../Models/Admin/Role';
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";

export const sitesToComboboxItems = (existingSites: Array<SiteReadDto>) => {
    return existingSites?.filter((data) => {
        return data.id!;
      })
      .map((site) => {
        return {
          displayName: site.name!,
          value: site.id!,
        };
      }) as Array<ComboBoxItem>;
  };

  export const siteToComboboxItem = (siteId: string, existingSites: Array<SiteReadDto>) => {
    return siteId != null
      ? ({
          displayName: existingSites?.find((m) => m?.id === siteId)?.name,
          value: siteId,
        } as ComboBoxItem)
      : null;
  };

  export const roleToComboboxItem = (roleId: string, existingRoles: Array<OrganisationRole>) => {
    return roleId != null
      ? ({
          displayName: existingRoles?.find((role) => role?.id === roleId)?.name,
          value: roleId,
        } as ComboBoxItem)
      : null;
  };