import { ReactElement, useEffect, useState } from "react";
import { useLocalization } from '@progress/kendo-react-intl';
import React from "react";
import { TreeListReadDto } from "../../../Models/TreeList/TreeListReadDto";
import {
TreeList, 
filterBy,
mapTree,
extendDataItem,
getSelectedState,
TreeListSelectionChangeEvent,
TreeListExpandChangeEvent,
TreeListFilterChangeEvent,
TreeListTextFilter,createDataTree, 
TREELIST_COL_INDEX_ATTRIBUTE,
TreeListSortChangeEvent, orderBy} from '@progress/kendo-react-treelist';
import { getter } from "@progress/kendo-data-query";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { Icon } from '@progress/kendo-react-common';
import { SvgIcon } from "@progress/kendo-react-common";
import { classNames } from '@progress/kendo-react-common';
import { blurIcon } from "@progress/kendo-svg-icons";
import { GetEndpointIcon, GetPointIcon } from "../../../Utilities/CustomIcons";

interface TreeViewDataItem {
    treeListData: Array<TreeListReadDto>
    selectedState: any
    expandState: any
    onSelectionChange: (selectedItem: TreeListReadDto, selectedState: any) => void
    onExpandChange: (expandState: any) => void
}

function GetReservoirIcon (node: TreeListReadDto)
{
  switch (node.type)
  {
    case "Point":
    case "Endpoint":

      if (node.reservoirId !== "")
      {
        return <SvgIcon icon={blurIcon} style={{width: '16px', height: '16px'}} />
      }
      else
      {
        return "";
      }

    default: 
      return "";
  }
}

function GetTreeNodeIcon (type: string)
{
    switch(type)
    {
      case "Location":
        return <Icon name="k-icon  k-i-globe" style={{width: '16px', height: '16px'}} />


      case "Asset":
        return <Icon name="k-icon k-i-gear" style={{width: '16px', height: '16px'}} />

      case "Point":

        return GetPointIcon(14,14)
                
      case "Endpoint":
        return GetEndpointIcon(16,16)

      case "Points":
      case "Endpoints":
        return <Icon name="k-icon k-i-folder-more" style={{width: '16px', height: '16px'}} />
  
      default:
        return <Icon name="k-icon k-i-question" style={{width: '16px', height: '16px'}} />
    }
  }

const TreeCell = (props: any) => {

  const field = props.field || "";
  const value = props.dataItem[field];
  const navigationAttributes = useTableKeyboardNavigation(props.id);
  const {isSelected } = props;
  const icons = new Array();
  
  if (props.expandable) {
    icons.push(
      ...props.level
        .slice(1)
        .map((_x: any, i: React.Key | null | undefined) => <span className="k-icon k-i-none" key={i} />)
    );

    if (props.hasChildren) {
      icons.push(
        <span
          className={`k-icon k-i-${props.expanded ? 'collapse' : 'expand'}`}
          key="expand-collapse"
          onClick={(event) => props.onExpandChange(event, props.dataItem, props.level)}
          {...{ [TREELIST_COL_INDEX_ATTRIBUTE]: props.colIndex }}
        />
      );
    } else {
      icons.push(<span className="k-icon k-i-none" key={icons.length} />);
    }
  }

  return (
    <td 
      aria-selected={props.isSelected} 
      className={classNames(
        'k-table-td',
        props.className,
        {
            ['k-text-nowrap']: props.expandable,
            ['k-selected']: isSelected
        })}
      {...navigationAttributes}
      {...{[TREELIST_COL_INDEX_ATTRIBUTE]: props.colIndex }}
    >
      {icons}
      {GetTreeNodeIcon(props.dataItem.type)}
      {" "}
      {value === null ? "" : props.dataItem[field].toString()}
      {" "}
      {GetReservoirIcon(props.dataItem)}
    </td>
  );
};

export default function TreeListComponent(props: TreeViewDataItem): ReactElement {
  const localizationService = useLocalization();

  const DATA_ITEM_KEY: string = 'id';
  const SUB_ITEMS_FIELD: string = 'treeListData';
  const EXPAND_FIELD: string = 'expanded';
  const SELECTED_FIELD: string = 'selected';
  const idGetter = getter(DATA_ITEM_KEY);

  const dataTree = createDataTree(
    props.treeListData,
    (i) => i.id,
    (i) => i.parentId,
    SUB_ITEMS_FIELD
  );

  const extendData: (
    dataState: any[],
    selectedState: { [id: string]: boolean },
    expandedState: { [n: number]: boolean }
  ) => any[] = (dataState, selectedState, expandedState) => {
    return mapTree(dataState, SUB_ITEMS_FIELD, (item) =>
      extendDataItem(item, SUB_ITEMS_FIELD, {
        selected: selectedState[idGetter(item)],
        expanded: expandedState[idGetter(item)],
      })
    );
  };

   useEffect(() => {
    setSelectedState(props.selectedState);
   }, [props.selectedState]); 

   useEffect(() => {
    setExpandedState(props.expandState);
   }, [props.expandState]); 

  const [selectedState, setSelectedState] = React.useState(props.selectedState);

  const [expandedState, setExpandedState] = React.useState<{
    [n: number]: boolean;
  }>(props.expandState);

  useEffect(() => {
    props.onExpandChange(expandedState);
   }, [expandedState]);

   const [dataState, setDataState] = useState<any>({
    data: [...dataTree],
    filter: [],
    expanded: [],
    sort: [{ field: "name", dir: "asc" }],

  });

  const onExpandChange = React.useCallback(
    (e: TreeListExpandChangeEvent) => {
      setExpandedState({ ...expandedState, [idGetter(e.dataItem)]: !e.value });
    },
    [expandedState]
  );

  const onSelectionChange = React.useCallback(
    (event: TreeListSelectionChangeEvent) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      setSelectedState(newSelectedState);
    
      var selectedItem = props.treeListData.filter((item) => item.id === Object.keys(newSelectedState)[0])
      props.onSelectionChange(selectedItem[0], newSelectedState)
    },
    [selectedState]
  );

  const handleFilterChange = (event: TreeListFilterChangeEvent) => {
    setDataState({
      ...dataState,
      filter: event.filter,
    });
  };

  const addExpandField = (dataTree) => {
    const expanded = dataState.expanded;
    return mapTree(dataTree, SUB_ITEMS_FIELD, (item) =>
      extendDataItem(item, SUB_ITEMS_FIELD, {
        [EXPAND_FIELD]: expanded.includes(item.id),
      })
    );
  };

  const processData = () => {
    const { data, filter, sort } = dataState;

    const dataTree = orderBy(
      filterBy(data, filter, SUB_ITEMS_FIELD),
      sort,
      SUB_ITEMS_FIELD
    );
    return addExpandField(dataTree);
  };

  const onSortChange = (event: TreeListSortChangeEvent) => {
    setDataState({
      ...dataState,
      sort: event.sort,
    });
  };

  return (
    <div style={{height: '100%',}} className={"extra-filter-button"}>
        <TreeList
            data={extendData(processData(), selectedState, expandedState)}
            selectedField={SELECTED_FIELD}
            expandField={EXPAND_FIELD}
            subItemsField={SUB_ITEMS_FIELD}
            dataItemKey={DATA_ITEM_KEY}
            onExpandChange={onExpandChange}
            onSelectionChange={onSelectionChange}
            onFilterChange={handleFilterChange}
            onSortChange={onSortChange}
            filter={dataState.filter}
            sort={dataState.sort}
            sortable={true}
            selectable={{
              enabled: true,
              mode: 'single',
            }}
            navigatable={true}
            columns={[
            {
                field: 'name',
                title: 'Site Explorer',
                expandable: true,
                filterCell: TreeListTextFilter,
                cell: TreeCell
            },
            ]}
        />
    </div>
  )
}
