import { ReactElement, useMemo } from "react";
import consumablesValidationSchema from "../Utilities/ConsumableValidationSchema";
import { useQuery, useQueryClient } from "react-query";
import { ReactQueryKeys } from "../../../Constants/ClientRoutingConstants";
import { useToast } from "@chakra-ui/react";
import consumablesApiService from "../Utilities/ConsumablesApiService";
import ErrorCard from "../../Shared/Components/Cards/ErrorCard";
import LoadingSpinner from "../../Shared/Components/LoadingSpinner";
import { ConsumableCreateDto } from "../../../Models/Consumable/ConsumableCreateDto";
import { ConsumableReadDto } from "../../../Models/Consumable/ConsumableReadDto";
import ConsumablesTable from "../Components/ConsumablesTable";
import { ConsumableUpdateDto } from "../../../Models/Consumable/ConsumableUpdateDto";
import { ConsumableReadTableDto } from "../../../Models/Consumable/ConsumableReadTableDto";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";
import { useWindowSize } from "usehooks-ts";
import { Dialog } from "@progress/kendo-react-dialogs";

interface Props {
  isOpenDialog: any;
  setIsOpenDialog: any;
}

export default function ConsumablesPage(props: Props): ReactElement {
  //Server side state
  const client = useQueryClient();
  const toast = useToast({
    position: "bottom",
    duration: 5000,
    isClosable: true,
  });

  const localizationService = useLocalization();

  //get window size
  const { width, height } = useWindowSize()

  //query get's all Device's
  const consumablesQuery = useQuery<Array<ConsumableReadDto>>(ReactQueryKeys.AllConsumablesQuery, async () => {
    return consumablesApiService.getAllConsumables();
  });

  //Create query
  const createConsumable = async (values: any, setStatus: any) => {
    //Attempt to create the consumable
    try {
      //Apply validationSchemaTransform
      const castValues = consumablesValidationSchema.cast(values) as ConsumableCreateDto;

      //Submit values to api
      const result = await consumablesApiService.createConsumable(castValues);
      client.refetchQueries(ReactQueryKeys.AllConsumablesQuery);

      //On success, show success toast, then refresh data
      toast({
        status: "success",
        description: `'${values.name}' ${localizationService.toLanguageString('custom.consumableCreated', enMessages.custom.consumableCreated)}`,
      });

      return true;
    } catch (e: any) {
      //On failure show error toast, set status messages
      setStatus(e?.response?.data?.errors);

      toast({
        title: `${localizationService.toLanguageString('custom.error', enMessages.custom.error)}`,
        status: "error",
        description: `${localizationService.toLanguageString('custom.consumableCreatedError', enMessages.custom.error)}`,
      });

      return false;
    }
  };

  //delete query
  const deleteConsumable = async (selectedConsumableId: Array<string>) => {
    try {
      let response = await consumablesApiService.deleteConsumables(selectedConsumableId);
      //If delete is successful, refetch the updated list of devices and show success message
      client.refetchQueries(ReactQueryKeys.AllConsumablesQuery);
      toast({
        status: "success",
        description: `${localizationService.toLanguageString('custom.consumableDeleted', enMessages.custom.consumableDeleted)}`,
      });
    } catch (e: any) {
      toast({
        status: "error",
        description: `${localizationService.toLanguageString('custom.consumableDeletedError', enMessages.custom.consumableDeletedError)} \n
        ${e?.response?.data?.errors ? Object.values(e?.response?.data?.errors)[0] : ""}`,
      });
    }
  };

  //Update Query
  const updateConsumables = async (consumables: Array<ConsumableUpdateDto>) => {
    try {
      let response;
      if (consumables.length === 1) {
        response = await consumablesApiService.updateConsumable(consumables[0], consumables[0].id);
      } else if (consumables.length > 1) {
        response = await consumablesApiService.updateConsumables(consumables);
      }
      //If update is successful, refetch the updated list of areas and show success message
      client.refetchQueries(ReactQueryKeys.AllConsumablesQuery);
      toast({
        status: "success",
        description: `${localizationService.toLanguageString('custom.consumableUpdated', enMessages.custom.consumableUpdated)}`,
      });
    } catch (e: any) {
      var errorMessage;
      if (consumables.length === 1) {
        errorMessage = `${localizationService.toLanguageString('custom.consumableUpdatedError', enMessages.custom.consumableUpdatedError)}\n
        ${e?.response?.data?.errors ? Object.values(e?.response?.data?.errors)[0] : ""}`;
      } else {
        errorMessage = `${localizationService.toLanguageString('custom.cosnumableIncorrectInput', enMessages.custom.cosnumableIncorrectInput)}\n
          ${e?.response?.data?.map((consumableId: string) => consumables.find((a) => a.id === consumableId)?.name)}
        `;
      }

      toast({
        status: "error",
        description: errorMessage,
      });
    }
  };

  /**
   * This function prepares data for the consumables table, including injecting additional metadata.
   */
  const consumableData = useMemo(() => {
    //Create hashmap of areaId -> name pairs.
    const consumablesIdtoName: { [id: string]: string } = {};
    consumablesQuery.data?.forEach((area) => {
      consumablesIdtoName[area.id] = area.name;
    });

    //Map over machines to inject relevant data
    const results = consumablesQuery.data?.map((consumable) => {
      return {
        ...consumable,
      } as ConsumableReadTableDto;
    });

    return results;
  }, [consumablesQuery.data]);

  if (!consumablesQuery.isLoading || !consumablesQuery.isFetching) {
    if (consumablesQuery.error) {
      return <ErrorCard />;
    } else {
      return (
        <div>
          {/* Consumables Table */}
          <div className="mt-4">
            <ConsumablesTable
              createConsumable={createConsumable}
              consumables={consumableData!}
              deleteConsumable={deleteConsumable}
              updateConsumable={updateConsumables}
              height={690}
            />
          </div>

          {props.isOpenDialog.consumables && (
              <Dialog
                title={localizationService.toLanguageString('custom.consumables', enMessages.custom.consumables)}
                onClose={() => props.setIsOpenDialog({...props.isOpenDialog, consumables:false})}
              >
                <ConsumablesTable
                  createConsumable={createConsumable}
                  consumables={consumableData!}
                  deleteConsumable={deleteConsumable}
                  updateConsumable={updateConsumables}
                  height={height - 200}
                />
              </Dialog>
            )}
        </div>
      );
    }
  } else {
    return <LoadingSpinner />;
  }
}
