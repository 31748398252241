import { useLocalization } from "@progress/kendo-react-intl";
import { useQuery } from "react-query";
import { ReactQueryKeys } from "../../../Constants/ClientRoutingConstants";
import notificationsApiService from "../Utilities/NotificationsApiService";
import ErrorCard from "../../Shared/Components/Cards/ErrorCard";
import { useMemo } from "react";
import moment from "moment";
import { NotificationReadDto } from "../../../Models/Notifications/NotificationReadDto";
import NotificationsPopUp from "../Components/NotificationsPopUp";
import LoadingSpinnerSmallNotifications from "../../Shared/Components/LoadingSpinnerSmallNotifications";


interface Props {
  handleShow: () => void;
}

export default function NotificationsPopUpPage(props: Props, ) {

  const localizationService = useLocalization();

  const notificationsQuery = useQuery<Array<NotificationReadDto>>(ReactQueryKeys.AllNotificationsSummartQuery, async () => {
    return notificationsApiService.getAllNotificationsSummary();
  });

  const notifications = useMemo(() => {
    return notificationsQuery.data?.map((notification) => {
      return {
        ...notification,
        created: moment(notification.created).fromNow(),
      };
    });
  }, [notificationsQuery.data]);

  if(notificationsQuery.isLoading || notificationsQuery.isFetching) {
    return <LoadingSpinnerSmallNotifications/>
  } else if (notificationsQuery.isError) {
    return <ErrorCard/>
  } else {
    return (
      <div className='m-2'>
        <NotificationsPopUp
          notifications={notifications}
          handleShow={props.handleShow}
        />
      </div>
    );
}}
