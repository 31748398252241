import { ReactElement, useMemo } from "react";
import PointTable from "../Components/PointTable";
import { useQuery, useQueryClient } from "react-query";
import { ReactQueryKeys } from "../../../Constants/ClientRoutingConstants";
import { useToast } from "@chakra-ui/react";
import pointsApiService from "../Utilities/PointsApiService";
import machinesApiService from "../../Machines/Utilities/MachinesApiService";
import areasApiService from "../../Area/Utilities/AreasApiService";
import tasksApiService from "../../Tasks/Utilities/TasksApiService";
import consumablesApiService from "../../Consumables/Utilities/ConsumablesApiService";
import scheduleApiService from "../../Schedules/Utilities/ScheduleApiService";
import ErrorCard from "../../Shared/Components/Cards/ErrorCard";
import LoadingSpinner from "../../Shared/Components/LoadingSpinner";
import AreaReadDto from "../../../Models/Area/AreaReadDto";
import { PointWithScheduleCreateDto } from "../../../Models/Point/PointWithScheduleCreateDto";
import { PointReadDto } from "../../../Models/Point/PointReadDto";
import { MachineReadDto } from "../../../Models/Machine/MachinesReadDto";
import { ConsumableReadDto } from "../../../Models/Consumable/ConsumableReadDto";
import { TaskReadDto } from "../../../Models/Task/TaskReadDto";
import { ScheduleReadDto } from "../../../Models/Schedule/ScheduleReadDto";
import { PointWithScheduleUpdateDto } from "../../../Models/Point/PointWithScheduleUpdateDto";
import { PointReadTableDto } from "../../../Models/Point/PointReadTableDto";
import { PointUpdateDto } from "../../../Models/Point/PointUpdateDto";
import { PointCreateDto } from "../../../Models/Point/PointCreateDto";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";
import { Dialog } from "@progress/kendo-react-dialogs";
import { useWindowSize } from 'usehooks-ts'

interface Props {
  isOpenDialog: any;
  setIsOpenDialog: any;
}

export default function PointsPage(props: Props): ReactElement {

  const localizationService = useLocalization();
  
  //get window size
  const { width, height } = useWindowSize()
 
  const PURGE_POINT = -1;
  const client = useQueryClient();
  const toast = useToast({
    position: "bottom",
    duration: 5000,
    isClosable: true,
  });

  const pointsQuery = useQuery<Array<PointReadDto>>(ReactQueryKeys.AllPointsQuery, async () => {
    return pointsApiService.getAllPoints();
  });

  const schedulesQuery = useQuery<Array<ScheduleReadDto>>(ReactQueryKeys.AllSchedulesQuery, async () => {
    return scheduleApiService.getAllSchedules();
  });

  const tasksQuery = useQuery<Array<TaskReadDto>>(ReactQueryKeys.AllTasksQuery, async () => {
    return tasksApiService.getAllTasks();
  });

  const machinesQuery = useQuery<Array<MachineReadDto>>(ReactQueryKeys.AllMachinesQuery, async () => {
    return machinesApiService.getAllMachines();
  });

  const areasQuery = useQuery<Array<AreaReadDto>>(ReactQueryKeys.AllAreasQuery, async () => {
    return areasApiService.getAllAreas();
  });

  const consumablesQuery = useQuery<Array<ConsumableReadDto>>(ReactQueryKeys.AllConsumablesQuery, async () => {
    return consumablesApiService.getAllConsumables();
  });

  const createPoint = async (values: any, setStatus: any) => {
    //Attempt to create the machine
    try {

      // Apply validationSchemaTransform
      //Cast the values into the structure expected by the api
      let pointsWithSchedule: PointWithScheduleCreateDto = {
        point: { ...values } as PointCreateDto,
        scheduleId: values.scheduleId,
      };

      if (String(pointsWithSchedule.point.volumeRequirement).toLowerCase() === "purge" || String(pointsWithSchedule.point.volumeRequirement).toLowerCase() === "p")
      {
        pointsWithSchedule.point.volumeRequirement = PURGE_POINT;
      }

      //Submit values to api
      const result = await pointsApiService.createPointWithSchedule(pointsWithSchedule);
      client.refetchQueries(ReactQueryKeys.AllPointsQuery);
      client.refetchQueries(ReactQueryKeys.AllTasksQuery);

      //On success, show success toast, then refresh data
      toast({
        status: "success",
        description: `'${values.name}'${localizationService.toLanguageString('custom.consumableCreated', enMessages.custom.consumableCreated)}`,
      });
      return true;
    } catch (e: any) {
      //On failure show error toast, set status messages
      console.log(e?.response?.data?.errors);
      //Loop through each key in error bag and remove "point." part 
      Object.keys(e?.response?.data?.errors).forEach((oldKey) => {
        return Object.assign(e?.response?.data?.errors, { [oldKey.replace("point.","")]: e?.response?.data?.errors[oldKey] })[oldKey];
      });
      console.log(e?.response?.data?.errors)
      setStatus(e?.response?.data?.errors);

      toast({
        title: `${localizationService.toLanguageString('custom.error', enMessages.custom.error)}`,
        status: "error",
        description: `${localizationService.toLanguageString('custom.pointsCreatedError', enMessages.custom.pointsCreatedError)}`,
      });
      return false;
    }
  };

  //delete query
  const deletePoints = async (selectedPointsId: Array<string>) => {
    try {
      let response = await pointsApiService.deletePoints(selectedPointsId);
      //If delete is successful, refetch the updated list of areas and show success message
      client.refetchQueries(ReactQueryKeys.AllPointsQuery);
      toast({
        status: "success",
        description: `${localizationService.toLanguageString('custom.pointsDeleted', enMessages.custom.pointsDeleted)}`,
      });
    } catch (e) {
      toast({
        status: "error",
        description: `${localizationService.toLanguageString('custom.pointsDeletedError', enMessages.custom.pointsDeletedError)}`,
      });
    }
  };

  //update Query
  const updatePoints = async (points: Array<PointReadTableDto>) => {
    try {

      // Check and update the volume to -1 if the points has been identified as a purge points
      points.forEach(point => {
        if (String(point.volumeRequirement).toLowerCase() === "purge" || String(point.volumeRequirement).toLowerCase() === "p")
        {
          point.volumeRequirement = PURGE_POINT;
        }
        if (point.scheduleId?.length === 0) {
          point.scheduleId = null;
        };
      })
     
      //Cast the values into the structure expected by the api
      let pointsWithSchedule: Array<PointWithScheduleUpdateDto> = points.map((x) => {
        return {
          point: { ...x } as PointUpdateDto,
          scheduleId: x.scheduleId === undefined ? null : x.scheduleId
        };
      });

      let response;
      if (points.length === 1) {
        response = await pointsApiService.updatePointWithSchedule(
          pointsWithSchedule[0],
          pointsWithSchedule[0].point.id
        );
      } else if (points.length > 1) {
        response = await pointsApiService.updatePointsWithSchedule(pointsWithSchedule);
      }
      //If update is successful, refetch the updated list of areas and show success message
      client.refetchQueries(ReactQueryKeys.AllPointsQuery);
      client.refetchQueries(ReactQueryKeys.AllTasksQuery);
      toast({
        status: "success",
        description: `${localizationService.toLanguageString('custom.pointsUpdated', enMessages.custom.pointsUpdated)}`,
      });
      return true;
    } catch (e: any) {
      var errorMessage;
      if (points.length === 1) {
        errorMessage = `${localizationService.toLanguageString('custom.pointsUpdatedError', enMessages.custom.pointsUpdatedError)}\n
        ${e?.response?.data?.errors ? Object.values(e?.response?.data?.errors)[0] : ""}`;
      } else {
        errorMessage = `${localizationService.toLanguageString('custom.pointsIncorrectInput', enMessages.custom.pointsIncorrectInput)}\n
          ${
            e?.response?.data?.map &&
            e?.response?.data?.map((pointId: string) => points.find((a) => a.id === pointId)?.name)
          }
        `;
      }
      toast({
        status: "error",
        description: errorMessage,
      });
      return false;
    }
  };

  //Collate and flatten data required for points table
  const pointData = useMemo(() => {
    //Create hashmap of id -> name pairs for foreign references.
    const areaIdtoName: { [id: string]: string } = {};
    const machineIdToName: { [id: string]: string } = {};
    const scheduleIdToName: { [id: string]: string } = {};
    const consumableIdToName: { [id: string]: string } = {};
    const pointIdToTask: { [id: string]: TaskReadDto } = {};
    areasQuery.data?.forEach((area) => {
      areaIdtoName[area.id] = area.name;
    });
    machinesQuery.data?.forEach((machine) => {
      machineIdToName[machine.id] = machine.name;
    });
    schedulesQuery.data?.forEach((schedule) => {
      scheduleIdToName[schedule.id] = schedule.name;
    });
    consumablesQuery.data?.forEach((consumable) => {
      consumableIdToName[consumable.id] = consumable.name;
    });
    tasksQuery.data?.forEach((task) => {
      pointIdToTask[task.point.id] = task;
    });

    //Construct Point Read Table dtos from data
    return pointsQuery.data?.map((point) => {
      return {
        ...point,
        scheduleId: pointIdToTask[point.id]?.scheduleId === null ? "" : pointIdToTask[point.id]?.scheduleId,
        scheduleName: scheduleIdToName[pointIdToTask[point.id]?.scheduleId] === undefined ? "No Schedule" : scheduleIdToName[pointIdToTask[point.id]?.scheduleId],
        areaName: areaIdtoName[point.areaId],
        consumableName: consumableIdToName[point.consumableId],
        machineName: machineIdToName[point.machineId],
      } as PointReadTableDto;
    });
  }, [
    schedulesQuery.data,
    tasksQuery.data,
    pointsQuery.data,
    machinesQuery.data,
    areasQuery.data,
    consumablesQuery.data,
  ]);

  if (
    schedulesQuery.isLoading ||
    machinesQuery.isLoading ||
    areasQuery.isLoading ||
    pointsQuery.isLoading ||
    consumablesQuery.isLoading ||
    schedulesQuery.isFetching ||
    machinesQuery.isFetching ||
    areasQuery.isFetching ||
    pointsQuery.isFetching ||
    consumablesQuery.isFetching
  ) {
    return <LoadingSpinner />;
  } else {
    if (pointsQuery.error) {
      return <ErrorCard />;
    } else {
      return (
        <div>
          {/* Areas Table */}
          <div className="mt-4">
            <PointTable
              points={pointData!}
              areas={areasQuery.data!}
              consumables={consumablesQuery.data!}
              machines={machinesQuery.data!}
              schedules={schedulesQuery.data!}
              deletePoints={deletePoints}
              createPoint={createPoint}
              updatePoints={updatePoints}
              height={690}
            />

            {props.isOpenDialog.points && (
              <Dialog
                title={localizationService.toLanguageString('custom.tagMapping', enMessages.custom.tagMapping)}
                onClose={() => props.setIsOpenDialog({...props.isOpenDialog, points: false})}
              >
                <PointTable
                  points={pointData!}
                  areas={areasQuery.data!}
                  consumables={consumablesQuery.data!}
                  machines={machinesQuery.data!}
                  schedules={schedulesQuery.data!}
                  deletePoints={deletePoints}
                  createPoint={createPoint}
                  updatePoints={updatePoints}
                  height={height - 200}
                />
              </Dialog>
            )}
          </div>
        </div>
      );
    }
  }
}
