import React, { ReactElement, useMemo, useState } from "react";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";
import { Calendar, CalendarProps, DatePicker } from "@progress/kendo-react-dateinputs";
import ActionTable from "../Components/ActionsTable";
import actionsApiService, { ActionFilter } from "../Utilities/ActionsApiService";
import { useQuery } from "react-query";
import { ReactQueryKeys } from "../../../Constants/ClientRoutingConstants";
import LoadingSpinner from "../../Shared/Components/LoadingSpinner";
import ErrorCard from "../../Shared/Components/Cards/ErrorCard";
import ActionReadDto from "../../../Models/Action/ActionReadDto";
import { useWindowSize } from "usehooks-ts";

interface Props {}

// Workaround: Disable Fast Navigational Bar until this critical bug is fixed by Telerik (https://github.com/telerik/kendo-react/issues/1566)
const CustomCalendar = (props: CalendarProps) => {
  return <Calendar {...props} navigation={false} />
};

export default function ActionsPage({}: Props): ReactElement {

  const localizationService = useLocalization();
  //get window size
  const { width, height } = useWindowSize()
  
  const [actionFilter, setActionFilter] = useState<ActionFilter>({
    endDate: new Date(), //From now
    startDate: (() => {
      //get current date
      const currentDate = new Date();
      const startDate = currentDate.getDate() - 60; //Sixty days ago
      currentDate.setDate(startDate);
      return currentDate;
    })(), //Until two weeks ago
  });

  //QueriesForPage
  const actionsQuery = useQuery<Array<ActionReadDto>>([ReactQueryKeys.AllActionsQuery, actionFilter], async () => {
    return actionsApiService.getAllActions(actionFilter);
  });
  
    const eventData = useMemo(() => {
    const results = actionsQuery.data?.map((event) => { 
        return {
          ...event,
          dueDateWithoutTime: new Date(new Date(event.dueDate).setHours(0,0,0)),
          expiryDateWithoutTime: new Date(new Date(event.expiryDate).setHours(0,0,0)),
          statusCell: event.status === "Active" ? localizationService.toLanguageString('custom.pending', enMessages.custom.pending): localizationService.toLanguageString('custom.closed', enMessages.custom.closed),
          cumulativeVolumeDeliveredRounded: event.cumulativeVolumeDelivered.toFixed(1),
          volumeToleranceRounded: ((event.volumeTolerance * 100).toFixed(0)).toString() + "%",
      }

    })
    return results as Array<ActionReadDto>;
  }, [actionsQuery.data]);


  if (
    actionsQuery.isLoading || actionsQuery.isFetching
  ) {
    return <LoadingSpinner />;
  } else {
    if (actionsQuery.error) {
      return <ErrorCard />;
    } else {
      return (
        <div className="m-5">
          {/* Header */}
          <h2 className="gb-h2">{localizationService.toLanguageString('custom.tasks', enMessages.custom.tasks)}</h2>
          <div className="gb-text-normal">{localizationService.toLanguageString('custom.viewTasksText', enMessages.custom.viewTasksText)}</div>

          {/* Date Picker for returned range */}
          <div className="flex flex-wrap my-5">
            <div>
              <div className="gb-text-normal font-bold">{localizationService.toLanguageString('custom.showAllTasksFrom', enMessages.custom.showAllTasksFrom)}</div>
                <DatePicker calendar={CustomCalendar} max={actionFilter.endDate!}
                  onChange={(date) => {
                    setActionFilter((prev: any) => {
                      return { ...prev, startDate: date.target.value };
                    });
                  }}
                  value={actionFilter.startDate}
                  className="w-72"
                />
              </div>
            <div className="ml-4">
              <div className="gb-text-normal font-bold">{localizationService.toLanguageString('custom.until', enMessages.custom.until)}</div>
              <DatePicker calendar={CustomCalendar} min={actionFilter.startDate!}
                onChange={(date) => {
                  setActionFilter((prev) => {
                    return { ...prev, endDate: date.target.value };
                  });
                }}
                value={actionFilter.endDate}
                className="w-72"
              />
            </div>
          </div>
          {/* Events Table */}
          <div className="mt-4">
                <ActionTable
                  actions={eventData}
                  height={height - 200}
                />        
          </div>     
        </div>
      );
    }
  }
}
