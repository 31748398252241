import { ReactElement, useEffect, useMemo, } from "react";
import { useQuery } from "react-query";
import moment from "moment";
import { useLocalization } from "@progress/kendo-react-intl";
import { EventFilter } from "../../Events/Utilities/EventsApiService";
import { RequirementAnalysis } from "../../../Models/Requirement/RequirementAnalysis";
import requirementApiService from "../../Requirements/Utilities/RequirementApiService";
import { ConsumableReadDto } from "../../../Models/Consumable/ConsumableReadDto";
import { ReactQueryKeys } from "../../../Constants/ClientRoutingConstants";
import consumablesApiService from "../../Consumables/Utilities/ConsumablesApiService";
import { rruleSummaryToText } from "../Utilities/ReportUtilities";
import LoadingSpinner from "../Components/ProcessingSpinner";
import NoRequirementPage from "../../TreeList/Pages/NoRequirementPage";
import { enMessages } from "../../../messages/en-US";
import treeListApiService from "../../TreeList/Utilities/TreeListApiService";
import EndpointStatusCompliance from "../../TreeList/Pages/Endpoints/Compliance/EndpointStatusCompliance";
import { ChartToolbar } from "../../TreeList/Utilities/ChartToolbar";

interface Props {
  eventFilter: EventFilter
  endpointId: string
}

export default function EndpointPvADialogPage(props: Props): ReactElement {
  const localizationService = useLocalization();
  
  const requirementsQuery = useQuery<RequirementAnalysis>('endpointReportRequirement', async () => {
    return requirementApiService.getAnalysisRequirement(props.endpointId, props.eventFilter);
  });

  const consumablesQuery = useQuery<Array<ConsumableReadDto>>(ReactQueryKeys.AllConsumablesQuery, async () => {
    return consumablesApiService.getAllConsumables();
  });

  const requirementResult = useMemo(() => {
    const consumableIdToName: { [id: string]: string } = {};
    consumablesQuery.data?.forEach((consumable) => {
      consumableIdToName[consumable.id] = consumable.name;
    });
    return {
      timestamps: requirementsQuery.data?.results?.map(x => moment(x.start).unix()) === undefined ? [] : requirementsQuery.data?.results?.map(x => moment(x.start).unix()),
      volumeDispensedData: requirementsQuery.data?.results?.map(item => item.volumeDispensed) === undefined ? [] : requirementsQuery.data?.results?.map(item => item.volumeDispensed),
      volumeRequirement: requirementsQuery.data?.requirementDetails?.rightAmountDetails?.volumeRequirement,
      thresholds: requirementsQuery.data?.requirementDetails?.rightAmountDetails?.thresholds,
      lastSeen: moment(requirementsQuery.data?.lastSeen).unix(),
      summary: requirementsQuery.data?.requirementDetails?.rightTimeDetails?.rRule === undefined || requirementsQuery.data?.requirementDetails?.rightTimeDetails?.rRule === '' ? "No Requirement" : rruleSummaryToText(requirementsQuery.data?.requirementDetails?.rightTimeDetails?.rRule),
      consumableName: requirementsQuery.data?.requirementDetails.rightGreaseDetails?.consumableId === undefined ? '' : consumableIdToName[requirementsQuery.data?.requirementDetails.rightGreaseDetails?.consumableId],
      startDate: requirementsQuery.data?.requirementDetails?.rightTimeDetails?.startDate === undefined ? '' : moment(requirementsQuery.data?.requirementDetails?.rightTimeDetails?.startDate).unix() ,
      endDate: requirementsQuery.data?.requirementDetails?.rightTimeDetails?.endDate === undefined || moment(requirementsQuery.data?.requirementDetails?.rightTimeDetails?.endDate).unix() === moment(new Date('0001-01-01T00:00:00Z')).unix() ? moment(props.eventFilter.endDate).unix() : moment(requirementsQuery.data?.requirementDetails?.rightTimeDetails?.endDate).unix(),
      unplannedEventsTimestamps: requirementsQuery.data?.unplannedEvents.map(x => moment(x.timestamp).unix()),
      unplannedEventsVolumes: requirementsQuery.data?.unplannedEvents.map(x => x.volume),
    }
  },[requirementsQuery.data])


  useEffect(() => {
    requirementsQuery.refetch();
  }, [props.endpointId, props.eventFilter])

  const notificationsQuery = useQuery<any>("notificationsStatus", async () => {
    return treeListApiService.getEndpointNotifications(props.endpointId, props.eventFilter, 1);
  });

  const statusQuery = useQuery<any>("statusChart", async () => {
    return treeListApiService.getEndpointStatus(props.endpointId, props.eventFilter, 0);
  });

  

  const chartData = useMemo(() => {

    const lastSeen = moment(requirementsQuery.data?.lastSeen).year() === 1970 ? localizationService.toLanguageString('custom.noEndpointDeviceAssigned', enMessages.custom.noEndpointDeviceAssigned) : moment.unix(requirementResult.lastSeen).format("DD/MM/YYYY @ HH:mm:ss");

    return {
      statusDataChart: {dateFilter: props.eventFilter, timestamps: requirementResult.timestamps, statusData:statusQuery.data,  alerts:notificationsQuery.data },
      complianceDataChart: {dateFilter: props.eventFilter, strokeThickness: 1 ,requirements:requirementResult, lastSeen:moment(requirementsQuery.data?.lastSeen) ,lastSeenFormatted:lastSeen}
    }
  }, [notificationsQuery.data, statusQuery.data, requirementResult])
  

  if (requirementsQuery.isLoading || requirementsQuery.isFetching ||notificationsQuery.isLoading || notificationsQuery.isFetching || statusQuery.isLoading || statusQuery.isFetching || consumablesQuery.isLoading || consumablesQuery.isFetching) {
    return <LoadingSpinner />;
  } else {
    if (requirementsQuery.isError || requirementsQuery.data?.results.length === 0 || notificationsQuery.isError || statusQuery.isError) {
      return <NoRequirementPage />;
    } else { 
  return (
    <div>
      <div className="mt-2">
        <ChartToolbar 
          title={localizationService.toLanguageString('custom.events', enMessages.custom.events)}
          lastSeen={chartData.complianceDataChart.lastSeenFormatted}
          showAutoRange={true} 
          showAggregatorOptions={false} 
          showChartOptions={true}
          showThresholds={true} 
          showSingleMultipleChart={false}
        /> 
      </div>
      
      <EndpointStatusCompliance statusChartData={chartData.statusDataChart} complianceDataChart={chartData.complianceDataChart}/>
      
    </div>
  )
}}}