import {
    XyDataSeries,
    HorizontalLineAnnotation,
    ELabelPlacement,
    EAnnotationLayer,
    VerticalLineAnnotation,
    SciChartSurface,
    ZoomPanModifier,
    MouseWheelZoomModifier,
    ZoomExtentsModifier,
    RolloverModifier,
    RubberBandXyZoomModifier,
    EXyDirection,
    EExecuteOn,
    FastImpulseRenderableSeries,
    LegendModifier,
    EAutoRange,
    NumberRange,
    EAxisAlignment,
    NumericAxis,
    DateTimeNumericAxis,
    CursorModifier,
    FastLineRenderableSeries,
    EllipsePointMarker,
    IDataSeries,
    ISciChartSurfaceBase,
    UpdateSuspender,
    FastBandRenderableSeries,
    XyyDataSeries,
    EDataSeriesType,
    } from "scichart";
  import { IInitResult, TInitFunction } from "scichart-react";
  import { enMessages } from "../../../../../messages/en-US";
  import { ColumnPaletteProviderPvA } from "../PaletteProvider";
  import moment from "moment";
  import { axisLabelDateFormatter } from "../../../../Shared/SciChart/SmartAxis/axisLabelDateFormatter";
  import { GetChartTheme, getTooltipDataTemplate } from "../../../../Shared/SciChart/ChartConfiguration";
import { LocalizationService } from "@progress/kendo-react-intl";
import { DateTickProvider } from "../../../../Shared/SciChart/SmartAxis/DateTickProvider";
import { SmartDateTZLabelProvider } from "../../../../Shared/SciChart/SmartAxis/SmartDateTZLabelProvider";
import { createDateTzConverter } from "../../../../Shared/SciChart/SmartAxis/timezones";
  
  export interface TActivityChartConfigResult<TSurface extends ISciChartSurfaceBase> extends IInitResult<TSurface> {
    setAutoRange: (value: boolean) => void
    setChartType: (barChartOn: boolean) => void
  }
  
  export type TActivityConfigFunc = TInitFunction<SciChartSurface, TActivityChartConfigResult<SciChartSurface>>;

  export interface Props {
    chartData: any;
  }

  export interface ChartOptions {
    isAutoRange: boolean;
    isBarChartOn: boolean;
    visibleRange: { min: number | undefined; max: number | undefined; };
    setVisibleRange: (range: any) => void;
    setVisibleSeriesActivityFlowRate: (series: any, isChecked: boolean) => void;
    visibleSeriesActivityFlowRate: { [key: string]: boolean;}
    aggregator: string;
  }
  
  export const getChartAPI = (props:Props, chartOptions:ChartOptions, localizationService: LocalizationService, currentLanguage: { locale: string; localeId: string; }) => {

    const endFilterDateUnix = moment(props.chartData.dateFilter.endDate).unix();
    const startFilterDateUnix = moment(props.chartData.dateFilter.startDate).unix();

    const theme = GetChartTheme();  
    let setAutoRangeCallback: (arg0: boolean) => void;
    let changeChartTypeCallback: (arg0: boolean) => void;
  
    const configAfterInit = (initResult) => {
        setAutoRangeCallback = initResult.setAutoRange;
        changeChartTypeCallback = initResult.setChartType;
    } ;
  
    const setAutoRange = (value: boolean) => {
        if (setAutoRangeCallback !== undefined) {
            setAutoRangeCallback(value);
        }
    };

    const setChartType = (barChartOn: boolean) => {
        if (changeChartTypeCallback !== undefined) {
            changeChartTypeCallback(barChartOn);
        }
    };

    const initChart : TActivityConfigFunc = async (rootElement: string | HTMLDivElement) => {
        const {sciChartSurface, wasmContext} = await SciChartSurface.create(rootElement, {theme: theme});

      // #region Tooltip Template
      const getTooltipFlowRateLegendTemplate = (seriesInfo: any, tooltipTitle: string, tooltipLabelX: string, tooltipLabelY: string) => {
        const lines: string[] = [];
        if (seriesInfo.dataSeriesType === EDataSeriesType.Xy) {
        const hlcSeriesInfo = seriesInfo as any;
        lines.push(`x: ${seriesInfo.formattedXValue}`);
        if(hlcSeriesInfo.pointMetadata !== null) {
        if (typeof hlcSeriesInfo.pointMetadata === 'object' && 'label' in hlcSeriesInfo.pointMetadata) {
            const labelParts =  hlcSeriesInfo.pointMetadata.label.split(',');
            lines.push(`${labelParts[0]}`);
            lines.push(`${localizationService.toLanguageString('custom.average', enMessages.custom.average)}: ${seriesInfo.formattedYValue}`);
            lines.push(`${labelParts[1]}`);
        }
        }}
        return lines;
      };

      // #endregion
  
        // #region Setup the XAxis
        const xAxis = new DateTimeNumericAxis(wasmContext, { 
          drawMajorBands: false, 
          drawMinorGridLines: false, 
          drawMajorGridLines: false, 
          visibleRange: new NumberRange(chartOptions.visibleRange?.min, chartOptions.visibleRange?.max),
          visibleRangeLimit: new NumberRange(startFilterDateUnix, endFilterDateUnix),
          growBy: new NumberRange(0.05, 0.05),
          labelStyle: {fontFamily:"Roboto", fontSize:12,}},
          );
  
        // Overrides getDeltaFromRange to get nice major and minor deltas for the ticks
        xAxis.deltaCalculator.getDeltaFromRange = axisLabelDateFormatter.getDeltaFromRange;
  
        const updateSettings = (xAxis: NumericAxis) => {
          const timezone = moment.tz.guess();
          // Calculate ticks which respect the time zone
          xAxis.tickProvider = new DateTickProvider(wasmContext, timezone);
          // Draws nice axis labels timezone and locale specific
          xAxis.labelProvider = new SmartDateTZLabelProvider(timezone,true, currentLanguage?.localeId);
          const dateTzConverter = createDateTzConverter(timezone);
          // This is needed for Cursor and Rollover chart modifiers
          xAxis.labelProvider.formatCursorLabel = timestamp =>
              chartOptions.aggregator === 'none' ? axisLabelDateFormatter.toFullDateTime(timestamp, dateTzConverter, currentLanguage?.localeId) : axisLabelDateFormatter.toFullDateOnly(timestamp, dateTzConverter, currentLanguage?.localeId);
          };
          // Setting timezone to local system
          updateSettings(xAxis);
  
        xAxis.visibleRangeChanged.subscribe((args) => { chartOptions.setVisibleRange(args?.visibleRange); });

        sciChartSurface.xAxes.add(xAxis);
        // #endregion

        // #region Setup the YAxis
        const yAxisVolume = new NumericAxis(wasmContext,{
          id: "yAxisVolume",
          axisAlignment: EAxisAlignment.Right, 
          autoRange: EAutoRange.Never,
          drawMajorBands: false,
          drawMinorGridLines: false, 
          drawMajorGridLines: true, 
          labelPostfix: " L", 
          labelPrecision: 2,
          growBy: new NumberRange(0, 0.2),
          labelStyle: {fontFamily:"Roboto", fontSize:12}});

          yAxisVolume.visibleRangeChanged.subscribe(args => {
            if (args?.visibleRange.min !== 0) {
              yAxisVolume.visibleRange = new NumberRange(0, args?.visibleRange.max);
            }
          });

          const setAutoRangeVolume = (value: boolean) => {
  
            yAxisVolume.autoRange = value ? EAutoRange.Always : EAutoRange.Never;
    
            if (!value) {
              yAxisVolume.visibleRange = new NumberRange(0, Math.max(...props.chartData.volume) * 1.20);
            }
        }
  
        sciChartSurface.yAxes.add(yAxisVolume);

        // #region Setup the YAxis
        const yAxisFlowRate = new NumericAxis(wasmContext,{
          axisAlignment: EAxisAlignment.Left, 
          autoRange: EAutoRange.Never,
          drawMajorBands: false,
          drawMinorGridLines: false, 
          drawMajorGridLines: true, 
          labelPostfix: " L/min", 
          labelPrecision: 2,
          growBy: new NumberRange(0, 0.2),
          labelStyle: {fontFamily:"Roboto", fontSize:12}});

          const setAutoRangeFlowRate = (value: boolean) => {
      
            yAxisFlowRate.autoRange = value ? EAutoRange.Always : EAutoRange.Never;
    
            if (!value) {
              yAxisFlowRate.visibleRange = new NumberRange(0, Math.max(...props.chartData.maxFlowRate) * 1.20)
            }
        }
      
        yAxisFlowRate.visibleRangeChanged.subscribe(args => {
            if (args?.visibleRange.min !== 0) {
              yAxisFlowRate.visibleRange = new NumberRange(0, args?.visibleRange.max);
            }
        });   
        
        sciChartSurface.yAxes.add(yAxisFlowRate);

        if(!!props.chartData.temperatureData && props.chartData.temperatureData.timestamps.length > 0) {
          const yAxisTemperature = new NumericAxis(wasmContext,{
            id: "yAxisTemperature",
            axisAlignment: EAxisAlignment.Left, 
            autoRange: EAutoRange.Never,
            drawMajorBands: false,
            drawMinorGridLines: false, 
            drawMajorGridLines: true, 
            labelPostfix: " °C", 
            labelPrecision: 2,
            growBy: new NumberRange(0, 0.2),
            visibleRange: new NumberRange(0, Math.max(...props.chartData.temperatureData.temperatures) * 1.20),
            labelStyle: {fontFamily:"Roboto", fontSize:12}});
    
            sciChartSurface.yAxes.add(yAxisTemperature);
        }

        // #endregion

        // #region Setup the Chart Modifers
        const legend = new LegendModifier({
          placementDivId: "legend-activity-flowRate",
          showCheckboxes: true,
          showSeriesMarkers: true,
          showLegend: true,
          isCheckedChangedCallback(series, isChecked) {
            chartOptions.setVisibleSeriesActivityFlowRate(series.id, isChecked);
          },
        });
        sciChartSurface.chartModifiers.add(legend);
 
        sciChartSurface.chartModifiers.add(
          new CursorModifier({ showAxisLabels: false,  id: "ActivityModifierGroup" }),
          new ZoomPanModifier({xyDirection: EXyDirection.XDirection}),
          new MouseWheelZoomModifier({xyDirection:EXyDirection.XDirection}),
          new ZoomExtentsModifier({xyDirection:EXyDirection.XDirection}),
          new RolloverModifier({tooltipDataTemplate: getTooltipDataTemplate, id: "ActivityRollOver" }),
          new RubberBandXyZoomModifier({ executeOn: EExecuteOn.MouseRightButton }),
        );
        // #endregion

        // #region Create the Data Series 

        const volumeDataSeries = new XyDataSeries(wasmContext, {
          xValues: props.chartData.timestamps, 
          yValues: props.chartData.volume, 
          dataSeriesName: localizationService.toLanguageString('custom.volumeInL', enMessages.custom.volumeInL)
        });

        const filterPeriodDataSeries = new XyDataSeries(wasmContext, {
          xValues: [startFilterDateUnix, endFilterDateUnix],
          yValues: [0,0], 
          dataSeriesName: "filterPeriodSeries"
        });
        // #endregion

        // #region Setup the RenderableSeries
  
        // Create a series that represents the entire filter period so the chart displays the entire range. 
        const filterPeriodSeries = new FastLineRenderableSeries(wasmContext, {
          id: "FilterPeriodSeriesId", 
          isVisible: false,
          dataSeries: filterPeriodDataSeries
        });  
        sciChartSurface.renderableSeries.add(filterPeriodSeries);
        legend.includeSeries(filterPeriodSeries, false);

        const createFlowRateSeries = () => {
          const metadataFlowRate= props.chartData.maxFlowRate.map((maxValue, index) => ({
            label: `${localizationService.toLanguageString('custom.max', enMessages.custom.max)}: ${maxValue.toFixed(2)} (L/min),${localizationService.toLanguageString('custom.min', enMessages.custom.min)}: ${(props.chartData.minFlowRate[index]).toFixed(2)} (L/min)`
          }));

          const avgFlowRateDataSeries = new XyDataSeries(wasmContext, {
            xValues: props.chartData.timestamps, 
            yValues: props.chartData.avgFlowRate, 
            dataSeriesName: localizationService.toLanguageString('custom.avgFlowRate', enMessages.custom.avgFlowRate),
            metadata: metadataFlowRate
          })

          const avgFlowRate = new FastLineRenderableSeries(wasmContext, {
            stroke: "#3F48CC", 
            id: "AvgFlowRateId",
            dataSeries: avgFlowRateDataSeries,
            isVisible: chartOptions.visibleSeriesActivityFlowRate.AvgFlowRateId
          });
          avgFlowRate.rolloverModifierProps.tooltipTextColor = "white"

          const flowRateMinMax = new FastBandRenderableSeries(wasmContext, {
            dataSeries:  new XyyDataSeries(wasmContext, { 
                xValues:  props.chartData.timestamps, 
                yValues:  props.chartData.maxFlowRate, 
                y1Values: props.chartData.minFlowRate , 
                dataSeriesName:  localizationService.toLanguageString('custom.minMaxFlowRate', enMessages.custom.minMaxFlowRate)}),
            isVisible: chartOptions.visibleSeriesActivityFlowRate.FlowRateMinMaxId,
            stroke: "#C3C3C3",
            strokeY1: "#C3C3C3",
            fill: "#C3C3C3",
            fillY1: "#C3C3C3",
            strokeThickness: 2,
            id: "FlowRateMinMaxId"
          });

          flowRateMinMax.rolloverModifierProps.showRollover = false;
          avgFlowRate.rolloverModifierProps.tooltipDataTemplate = getTooltipFlowRateLegendTemplate;

          return {avgFlowRate, flowRateMinMax};
        }

        const flowRateSeries = createFlowRateSeries();
        sciChartSurface.renderableSeries.add(flowRateSeries.flowRateMinMax);
        sciChartSurface.renderableSeries.add(flowRateSeries.avgFlowRate);

        const createVolumeSeries = (barChartOn: boolean, isVisible: boolean, dataSeries: IDataSeries) => {
  
          const volume = barChartOn === true ? new FastImpulseRenderableSeries(wasmContext, {
            stroke: "rgba(50, 53, 67,1)", 
            fill: "rgba(50, 53, 67,1)", 
            strokeThickness:props.chartData.strokeThickness,
            paletteProvider: new ColumnPaletteProviderPvA(),
            id: "VolumeId",
            yAxisId: "yAxisVolume",
            dataSeries: dataSeries,
            isVisible: isVisible
          }) : new FastLineRenderableSeries(wasmContext, {
            dataSeries: dataSeries,
            stroke: "rgba(50, 53, 67,1)", 
            id: "VolumeId",
            yAxisId: "yAxisVolume",
            isVisible: isVisible,
            pointMarker:  new EllipsePointMarker(wasmContext, {width: 6, height: 6, stroke: "rgba(50, 53, 67,1)", fill: "rgba(50, 53, 67,1)" 
          }),
          })
  
          volume.rolloverModifierProps.tooltipTextColor = 'White'
          volume.rolloverModifierProps.showRollover = true
      
          return volume;
        }

        const volume = createVolumeSeries(chartOptions.isBarChartOn, chartOptions.visibleSeriesActivityFlowRate.VolumeId, volumeDataSeries);
        sciChartSurface.renderableSeries.add(volume);

        // create temperature series
        if(!!props.chartData.temperatureData && props.chartData.temperatureData.timestamps.length > 0) {
          const temperatureDataSeries = new XyDataSeries(wasmContext, {
            xValues: props.chartData.temperatureData.timestamps, 
            yValues: props.chartData.temperatureData.temperatures, 
            dataSeriesName: localizationService.toLanguageString('custom.temperatureInC', enMessages.custom.temperatureInC)
          });

          const temperature = new FastLineRenderableSeries(wasmContext, {
            stroke: "rgba(136,0,21,1)", 
            id: "TemperatureId",
            yAxisId: "yAxisTemperature",
            dataSeries: temperatureDataSeries,
            isVisible: chartOptions.visibleSeriesActivityFlowRate.TemperatureId
          });

          sciChartSurface.renderableSeries.add(temperature);
          temperature.rolloverModifierProps.tooltipTextColor = 'White'
        }

        const lastSeenSeries = new FastImpulseRenderableSeries(wasmContext, {
          stroke: "grey", 
          fill: "grey", 
          strokeThickness: props.chartData.strokeThickness,
          id: "lastSeenId",
          dataSeries: new XyDataSeries(wasmContext, {
            xValues: [props.chartData.lastSeen], 
            yValues: [0], 
            dataSeriesName: `${localizationService.toLanguageString('custom.lastSeen', enMessages.custom.lastSeen)}` 
          })
        });

       lastSeenSeries.rolloverModifierProps.tooltipTextColor = 'White'
        lastSeenSeries.rolloverModifierProps.showRollover = true

        // #endregion

        // #region Setup Annotations

        // Add 0 line annotation
        const horizontalLine = new HorizontalLineAnnotation({
          labelPlacement: ELabelPlacement.Axis,
          stroke: 'grey',
          strokeThickness: 3,
          y1: 0,
          annotationLayer: EAnnotationLayer.BelowChart
          });
          sciChartSurface.annotations.add(horizontalLine)
    
          // Add LastSeen line annotation
          const lastSeenLine = new VerticalLineAnnotation({
            labelPlacement: ELabelPlacement.TopRight,
            stroke: 'grey',
            strokeThickness: 3,
            strokeDashArray: [12,5],
            x1: props.chartData.lastSeen,
            showLabel:true,
            labelValue: `${localizationService.toLanguageString('custom.lastSeen', enMessages.custom.lastSeen)}`,
            axisLabelFill: 'grey',
            annotationLayer: EAnnotationLayer.BelowChart
            });
         
         lastSeenSeries.dataSeries = new XyDataSeries(wasmContext, {
           xValues: [ props.chartData.lastSeen], 
           yValues: [0], 
           dataSeriesName: `${localizationService.toLanguageString('custom.lastSeen', enMessages.custom.lastSeen)}` 
         });
         
         lastSeenSeries.rolloverModifierProps.tooltipTextColor = 'White'
         lastSeenSeries.rolloverModifierProps.showRollover = true
      
         if(props.chartData.lastSeen >= startFilterDateUnix && props.chartData.lastSeen <= endFilterDateUnix) {
          sciChartSurface.annotations.add(lastSeenLine)
          sciChartSurface.renderableSeries.add(lastSeenSeries);
          legend.includeSeries(lastSeenSeries, false);
        }
  
          // #endregion

        // #region Setup the API's 
        const setAutoRange = (value: boolean) => {

          UpdateSuspender.using(sciChartSurface, () => {
            setAutoRangeVolume(value);
            setAutoRangeFlowRate(value);
        })
        };
        setAutoRangeVolume(chartOptions.isAutoRange);
        setAutoRangeFlowRate(chartOptions.isAutoRange);

        const setChartType = (barChartOn: boolean) => {
          UpdateSuspender.using(sciChartSurface, () => {
            const index = sciChartSurface.renderableSeries.asArray().findIndex(x => x.id === "VolumeId");
            const newVolumeSeries = createVolumeSeries(barChartOn, volume.isVisible, volume.dataSeries);
            sciChartSurface.renderableSeries.set(index, newVolumeSeries);
          });
        }
        // #endregion
  
      return { sciChartSurface: sciChartSurface, setAutoRange, setChartType};
    }

    return {initChart, configAfterInit, setAutoRange, setChartType};
}