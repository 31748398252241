import { ReactElement, useEffect, useMemo, } from "react";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../../messages/en-US";
import PointEventsTable from "../../Components/Points/PointEventsTable";
import React from "react";
import { AggregateDescriptor, DataResult, State, process } from "@progress/kendo-data-query";
import { setGroupIds } from "@progress/kendo-react-data-tools";
import PointEventsAggregatedTable from "../../Components/Points/PointEventsAggregatedTable";
import moment from "moment";
import { EventFilter } from "../../../Events/Utilities/EventsApiService";
import { ReactQueryKeys } from "../../../../Constants/ClientRoutingConstants";
import { useQuery } from "react-query";
import treeListApiService from "../../Utilities/TreeListApiService";
import LoadingSpinner from "../../../Shared/Components/LoadingSpinner";
import { ChartToolbar } from "../../Utilities/ChartToolbar";
import useChartStore from "../../../../Zustand/chartStore";
import PointStatusActivity from "./PointStatusActivity";

interface Props {
  events: any
  selectedItemId: any
  eventFilter: EventFilter
  assetId: string;
}

export default function PointActivityTabPage(props: Props): ReactElement {
  const localizationService = useLocalization();

  const aggregator = useChartStore((state) => state.aggregator);

  const notificationsQuery = useQuery<any>(ReactQueryKeys.AllAlertsQuery, async () => {
    return treeListApiService.getEndpointsAssetNotifications(props.assetId, props.eventFilter, 1);
  });
  
  const statusQuery = useQuery<any>("StatusOnOffQuery", async () => {
    return treeListApiService.getAssetStatus(props.assetId, props.eventFilter, 0);
  });

  useEffect(() => {
    statusQuery.refetch();
  }, [props.eventFilter]);

  const eventData = useMemo(() => {
    const results = props.events?.map((event) => { 
      const dateTime = event.occurred

      switch (aggregator) {
        case 'none':
          return {
            ...event,
            timestamp: dateTime,
            occurredWithoutTime: new Date(new Date(dateTime).setHours(0,0,0)),
            createdWithoutTime: new Date(new Date(event.created).setHours(0,0,0)),
            volumeDispensedRound: Number(event.volumeDispensed).toFixed(2)
        }
        case 'daily':
          return {
            ...event,
            date: new Date(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate()),
            occurredWithoutTime: new Date(new Date(dateTime).setHours(0,0,0)),
            createdWithoutTime: new Date(new Date(event.created).setHours(0,0,0)),
        }
        default:
          return
    }});

    return results;
  }, [props.events, aggregator]);

  const initialDataState: State = {
    skip: 0,
    group: [{ field: 'date' }],
  };
  
  const aggregates: AggregateDescriptor[] = [
    { field: 'volumeDispensed', aggregate: 'sum' },
  ];
  
  const processWithGroups = (data, dataState: State) => {
    const groups = dataState.group;
    if (groups) {
      groups.map((group) => (group.aggregates = aggregates));
    }
    dataState.group = groups;
    const newDataState = process(data, dataState);
  
    setGroupIds({ data: newDataState.data, group: dataState.group });
    return newDataState;
  };

  const [result, setResult] = React.useState<DataResult>(processWithGroups(eventData, initialDataState))

  useEffect(() => {
    setResult(processWithGroups(eventData, initialDataState))
  }, [aggregator, props.selectedItemId])

  const aggregatedResult = useMemo(() => result.data.map(x => {
    return(
    {
      volumeDispensedSum: Number(x.aggregates.volumeDispensed.sum),
      volumeDispensedSumRound: (Number(x.aggregates.volumeDispensed.sum).toFixed(2)),
      consumableName: x.items[0].consumableName,
      serialNumber: x.items[0].device,
      scheduleName: x.items[0].schedule,
      rfid: x.items[0].rfid,
      date: x.value,
      machineName: x.items[0].machineName,
      pointName: x.items[0].pointName
    })
  }),[result])

  const selectedAggregatorTable = () => {
    switch (aggregator) {
      case 'none':
        return <PointEventsTable events={eventData}/>
      case 'daily':   
        return <PointEventsAggregatedTable events={aggregatedResult}/>
      default:
        return
    }
  }

  const selectedAggregatorChart =  useMemo(() => {
    let timestamps
    let volumeDispensed
    let volumeDispensedData
    switch (aggregator) {
      case 'none':
        eventData.sort((a,b) => a.timestamp > b.timestamp ? 1 : -1)
        timestamps = eventData.map(x => Math.floor(moment(x.timestamp).valueOf()/1000))
        volumeDispensed = eventData.map(x => Number(x.volumeDispensed))
        volumeDispensedData =  volumeDispensed.map(item => item === 'NaN' ? NaN :  item)

        const dataChartNone = {
          statusDataChart: {dateFilter: props.eventFilter, timestamps: timestamps, statusData:statusQuery.data, alerts:notificationsQuery.data},
          activityDataChart: {dateFilter: props.eventFilter, timestamps:  timestamps, volumeDispensedData: volumeDispensedData, strokeThickness: 1, aggregator: "none", lastSeen: 0}
        }

        return  <PointStatusActivity key={aggregator} statusChartData={dataChartNone.statusDataChart} activityChartData={dataChartNone.activityDataChart}/>
      
      case 'daily':   
        aggregatedResult.sort((a,b) => a.date > b.date ? 1 : -1)
        timestamps = aggregatedResult.map(x => Math.floor(moment(x.date).valueOf()/1000))
        volumeDispensed = aggregatedResult.map(x => Number(x.volumeDispensedSum))
        volumeDispensedData =  volumeDispensed.map(item => item === 'NaN' ? NaN :  item)

        const dataChartDaily = {
          statusDataChart: {dateFilter: props.eventFilter, timestamps: timestamps, statusData:statusQuery.data, alerts:notificationsQuery.data},
          activityDataChart: {dateFilter: props.eventFilter, timestamps:  timestamps, volumeDispensedData: volumeDispensedData, strokeThickness: 1, aggregator: "daily", lastSeen: 0}
        }

        return  <PointStatusActivity key={aggregator} statusChartData={dataChartDaily.statusDataChart} activityChartData={dataChartDaily.activityDataChart}/>
          
      default:
        return
    }
  }, [result, notificationsQuery.data, statusQuery.data, props.eventFilter,  props.selectedItemId])
   


  if (notificationsQuery.isLoading || notificationsQuery.isFetching || statusQuery.isLoading || statusQuery.isFetching ) {
    return <LoadingSpinner />;
  } else {
  return (
    <div>
      <ChartToolbar 
        title={localizationService.toLanguageString('custom.events', enMessages.custom.events)}
        lastSeen={null}
        showAutoRange={true} 
        showAggregatorOptions={true} 
        showChartOptions={false}
        showThresholds={false} 
        showSingleMultipleChart={false}
      />
    <div>
      {selectedAggregatorChart} 
    </div>
    <div className="mt-12">
      {selectedAggregatorTable()}     
    </div>
  </div>
  )
}}
