import { Dialog } from "@progress/kendo-react-dialogs";
import { useLocalization } from "@progress/kendo-react-intl";
import { AlertCreateDto } from "../../../Models/Alerts/AlertCreateDto";
import { AddEditAlertForm } from "./AddEditAlertForm";
import { enMessages } from "../../../messages/en-US";

interface Props {
  onClose: () => void;
  createAlert: (alert: AlertCreateDto) => void;
}

export default function AddAlertViewModal(props: Props) {
  const localizationService = useLocalization();

  const defaultAlert = {
    name: "",
    description: "",
    enabled: true,
    type: "",
    criticality: "",
    assignedTo: [],
    conditions: [
      {
        field: "",
        operator: "",
        value: null,
        unit: "",
        dataType: "DoubleCondition",
      },
    ],
    message: "",
    emails: [],
  };

  return (
    <div className="alerts-page">
      <Dialog
        key={"AddAlertViewModal"}
        title={localizationService.toLanguageString(
          "custom.createAlert",
          enMessages.custom.createAlert
        )}
        onClose={props.onClose}
        className="reservoir-view"
        width="700px"
        height="717px"
      >
        <AddEditAlertForm
          alert={defaultAlert}
          onClose={props.onClose}
          isAddModal={true}
          createAlert={props.createAlert}
        />
      </Dialog>
    </div>
  );
}
