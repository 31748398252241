import {
    XyDataSeries,
    HorizontalLineAnnotation,
    ELabelPlacement,
    EAnnotationLayer,
    SciChartSurface,
    ZoomPanModifier,
    MouseWheelZoomModifier,
    ZoomExtentsModifier,
    RolloverModifier,
    RubberBandXyZoomModifier,
    EXyDirection,
    EExecuteOn,
    FastImpulseRenderableSeries,
    LegendModifier,
    EAutoRange,
    NumberRange,
    EAxisAlignment,
    NumericAxis,
    DateTimeNumericAxis,
    CursorModifier,
    IRenderableSeries,
    FastLineRenderableSeries,
    IDataSeries,
    ISciChartSurfaceBase,
    AUTO_COLOR,
    TrianglePointMarker,
    UpdateSuspender,
    } from "scichart";
  import { IInitResult, TInitFunction } from "scichart-react";
  import { ColumnPaletteProviderActivityChartAsset } from "../PaletteProvider";
  import moment from "moment";
  import { axisLabelDateFormatter } from "../../../../Shared/SciChart/SmartAxis/axisLabelDateFormatter";
  import { GetChartTheme } from "../../../../Shared/SciChart/ChartConfiguration";
import { LocalizationService } from "@progress/kendo-react-intl";
import { DateTickProvider } from "../../../../Shared/SciChart/SmartAxis/DateTickProvider";
import { SmartDateTZLabelProvider } from "../../../../Shared/SciChart/SmartAxis/SmartDateTZLabelProvider";
import { createDateTzConverter } from "../../../../Shared/SciChart/SmartAxis/timezones";
  
  export interface TAssetActivityChartConfigResult<TSurface extends ISciChartSurfaceBase> extends IInitResult<TSurface> {
    setAutoRange: (value: boolean) => void,
    changeChartType: (barChartOn: boolean, visibleSeriesCompliance) => void
  }
  
  export type TAssetActivityConfigFunc = TInitFunction<SciChartSurface, TAssetActivityChartConfigResult<SciChartSurface>>;

  export interface Props {
    chartData: any
  }

  export interface ChartOptions {
    isAutoRange: boolean;
    isBarChartOn: boolean;
    visibleRange: { min: number | undefined; max: number | undefined; };
    setVisibleRange: (range: any) => void;
    setVisibleSeriesAsset: (series: any, isChecked: boolean) => void;
    visibleSeriesAsset: { [key: string]: boolean;}
    aggregator: string;
  }
  
  export const getChartAPI = (props:Props, chartOptions:ChartOptions,  localizationService: LocalizationService, currentLanguage: { locale: string; localeId: string; }) => {

    const endFilterDateUnix = moment(props.chartData.dateFilter.endDate).unix();
    const startFilterDateUnix = moment(props.chartData.dateFilter.startDate).unix();

    const theme = GetChartTheme();  
    theme.strokePalette = ["red", "green", "pink", "blue"];
    theme.fillPalette = ["blue", "pink", "green", "red"];

    let setAutoRangeCallback: (arg0: boolean) => void;
    let changeChartTypeCallback: (arg0: boolean, arg1:any) => void;
  
    const configAfterInit = (initResult) => {
        setAutoRangeCallback = initResult.setAutoRange;
        changeChartTypeCallback = initResult.changeChartType;
    } 
  
    const setAutoRange = (value: boolean) => {
        if (setAutoRangeCallback !== undefined) {
            setAutoRangeCallback(value);
        }
    }
  
    const changeChartType = (barChartOn: boolean, visibleSeries) => {
      if (changeChartTypeCallback !== undefined) {
        changeChartTypeCallback(barChartOn, visibleSeries);
      }
    }

    const initChart : TAssetActivityConfigFunc = async (rootElement: string | HTMLDivElement) => {
        const {sciChartSurface, wasmContext} = await SciChartSurface.create(rootElement, {theme: theme});
    
      // #region Setup the XAxis
       const xAxis = new DateTimeNumericAxis(wasmContext, { 
         drawMajorBands: false, 
         drawMinorGridLines: false, 
         drawMajorGridLines: false, 
         visibleRange: new NumberRange(chartOptions.visibleRange?.min, chartOptions.visibleRange?.max),
         visibleRangeLimit: new NumberRange(startFilterDateUnix, endFilterDateUnix),
         growBy: new NumberRange(0.05, 0.05),
         labelStyle: {fontFamily:"Roboto", fontSize:12,}},
         );
     
        // Overrides getDeltaFromRange to get nice major and minor deltas for the ticks
        xAxis.deltaCalculator.getDeltaFromRange = axisLabelDateFormatter.getDeltaFromRange;
       
        // Setting timezone to local system
        const updateSettings = (xAxis$: NumericAxis) => {
          const timezone = moment.tz.guess();
          // Calculate ticks which respect the time zone
          xAxis$.tickProvider = new DateTickProvider(wasmContext, timezone);
          // Draws nice axis labels timezone and locale specific
          xAxis$.labelProvider = new SmartDateTZLabelProvider(timezone,true, currentLanguage?.localeId);
          const dateTzConverter = createDateTzConverter(timezone);
          // This is needed for Cursor and Rollover chart modifiers
          xAxis$.labelProvider.formatCursorLabel = timestamp =>
            chartOptions.aggregator === 'none' ? axisLabelDateFormatter.toFullDateTime(timestamp, dateTzConverter, currentLanguage?.localeId) : axisLabelDateFormatter.toFullDateOnly(timestamp, dateTzConverter, currentLanguage?.localeId);
          };
          // Setting timezone to local system
          updateSettings(xAxis);
     
        xAxis.visibleRangeChanged.subscribe((args) => { chartOptions.setVisibleRange(args?.visibleRange); });

        sciChartSurface.xAxes.add(xAxis);
        // #endregion
        
      // #region Setup the YAxis
      const yAxis = new NumericAxis(wasmContext,{
        axisAlignment: EAxisAlignment.Right, 
        drawMajorBands: false,
        drawMinorGridLines: false, 
        drawMajorGridLines: true, 
        labelPostfix: " cc", 
        labelPrecision: 2,
        growBy: new NumberRange(0, 0.05),
        labelStyle: {fontFamily:"Roboto", fontSize:12}});

      sciChartSurface.yAxes.add(yAxis);
      // #endregion

      // #region Setup the Chart Modifers
      const legend = new LegendModifier({
        placementDivId: "legend-asset",
        showCheckboxes: true,
        showSeriesMarkers: true,
        showLegend: true,
        // Subscribe to checked changed here
        isCheckedChangedCallback: (series: IRenderableSeries, isChecked: boolean) => {
         sciChartSurface.zoomExtentsY();
         chartOptions.setVisibleSeriesAsset(series.id, isChecked)  
        }
      });
    
      sciChartSurface.chartModifiers.add(legend);

      sciChartSurface.chartModifiers.add(
        new ZoomPanModifier({xyDirection: EXyDirection.XDirection}),
        new MouseWheelZoomModifier({xyDirection:EXyDirection.XDirection}),
        new ZoomExtentsModifier({xyDirection:EXyDirection.XDirection}),
        new RubberBandXyZoomModifier({ executeOn: EExecuteOn.MouseRightButton }),
        new CursorModifier({showTooltip:true, hitTestRadius: 10, tooltipContainerBackground:"#424242", axisLabelFill: "#424242", id: "AssetModifierGroup",
         }),
        new RolloverModifier({hitTestRadius:10, showTooltip:false, snapToDataPoint:true, showRolloverLine:false , id: "AssetRollOver"}),
      );
      
      // #endregion
      
      // #region Create Data Series and Setup RenderableSeries

      // Create a series that represents the entire filter period so the chart displays the entire range. 
      const filterPeriodSeries = new FastLineRenderableSeries(wasmContext, {
        id: "FilterPeriodSeriesId", 
        isVisible: false,
        dataSeries: new XyDataSeries(wasmContext, {xValues: [startFilterDateUnix, endFilterDateUnix],yValues: [0,0], dataSeriesName: "filterPeriodSeries"})
      });  

      sciChartSurface.renderableSeries.add(filterPeriodSeries);
      legend.includeSeries(filterPeriodSeries, false);

      const createVolumeDispensedSeries = ((id: string, barChartOn: boolean, isVisible: boolean, dataSeries: IDataSeries) => {

        const volumeDispensed = barChartOn ? new FastImpulseRenderableSeries(wasmContext, {
          stroke: AUTO_COLOR, fill: AUTO_COLOR,
          pointMarker: new TrianglePointMarker(wasmContext, {width: 12, height: 12, stroke: "rgba(50, 53, 67,1)", fill: "rgba(50, 53, 67,1)"}),
          paletteProvider: new ColumnPaletteProviderActivityChartAsset(),
          id: id,
          dataSeries: dataSeries,
          isVisible: isVisible
        }) : new FastLineRenderableSeries(wasmContext, {
          stroke: AUTO_COLOR,
          pointMarker: new TrianglePointMarker(wasmContext, {width: 12, height: 12, stroke: "rgba(50, 53, 67,1)", fill: "rgba(50, 53, 67,1)"}),
          paletteProvider: new ColumnPaletteProviderActivityChartAsset(),
          id: id,
          dataSeries: dataSeries,
          isVisible: isVisible
        });

        volumeDispensed.rolloverModifierProps.tooltipTextColor = 'White'
        volumeDispensed.rolloverModifierProps.showRollover = true
        volumeDispensed.rolloverModifierProps.markerColor = 'red'

        return volumeDispensed;
      });

      for(let i = 0; i < props.chartData.dataChart.length ; i++){

          const volumeDispensedDataSeries = new XyDataSeries(wasmContext, {
              xValues: props.chartData.dataChart[i].timestamps, 
              yValues: props.chartData.dataChart[i].volumeDispensedData,
              dataSeriesName: props.chartData.dataChart[i].endpointName 
            });
          
          const volumeDispensed = createVolumeDispensedSeries(props.chartData.dataChart[i].endpointId, chartOptions.isBarChartOn, chartOptions.visibleSeriesAsset[props.chartData.dataChart[i].endpointId], volumeDispensedDataSeries);

          sciChartSurface.renderableSeries.add(volumeDispensed);
      }

      // #endregion

      // #region Setup Annotation
      // Add 0 line annotation
      const horizontalLine = new HorizontalLineAnnotation({
        labelPlacement: ELabelPlacement.Axis,
        stroke: 'grey',
        strokeThickness: 3,
        y1: 0,
        annotationLayer: EAnnotationLayer.BelowChart
        });
        sciChartSurface.annotations.add(horizontalLine);
      // #endregion
      
      // #region Setup the API's 
      const setAutoRange = (value: boolean) => {

      const yAxisCurrent = sciChartSurface.yAxes.getById(NumericAxis.DEFAULT_AXIS_ID);
          
        if (yAxisCurrent !== undefined) {
          if (value) {
            yAxisCurrent.autoRange = EAutoRange.Always;
           // yAxisCurrent.visibleRangeLimit = new NumberRange(a.min, a.max);
          } else {
            yAxisCurrent.autoRange = EAutoRange.Never;

            const visibleSeries = sciChartSurface.renderableSeries.asArray().filter(x => x.isVisible);

            if (visibleSeries.length === 0) {
              yAxisCurrent.visibleRange = new NumberRange(0, 10 * 1.20);
            } else {

              var minYRange = 0
              var maxYRange = 0;
              
              visibleSeries.forEach((series) => {
                const dataSeries = series.dataSeries as XyDataSeries;
                const yRange = dataSeries.getWindowedYRange(dataSeries.xRange, true, false);
              
                if (yRange !== undefined) {
                  if (yRange.min < minYRange) {
                    minYRange = yRange.min;
                  }

                  if (yRange.max > maxYRange) {
                    maxYRange = yRange.max;
                  }
                }
              });

              yAxisCurrent.visibleRange = new NumberRange(minYRange, maxYRange * 1.20);
            }
          }
        }
      };

      setAutoRange(chartOptions.isAutoRange);
    

       const changeChartType = (barChartOn: boolean) => {

            UpdateSuspender.using(sciChartSurface, () => {
                const renderableSeriesLength = sciChartSurface.renderableSeries.asArray().length;

                for(let i = 0; i < renderableSeriesLength ; i++){
                    const series = sciChartSurface.renderableSeries.get(i);

                    if (series.id !== "FilterPeriodSeriesId") {
                      const newVolumeDispensedSeries = createVolumeDispensedSeries(series.id, barChartOn, series.isVisible, series.dataSeries);
                      sciChartSurface.renderableSeries.set(i, newVolumeDispensedSeries);
                    }
                }
            });
        }
        // #endregion
     
        return { sciChartSurface, changeChartType, setAutoRange };
    };

    return {initChart, configAfterInit, setAutoRange, changeChartType};
  }

