import moment from "moment";
import { NumberRange } from "scichart";


const GetDaysInSeconds = (days: number) => {
  return days * 24 * 60 * 60;
}

const GetVisibleRange = (lastestDataTimestampUnix: number,  filterStartDateUnix: number, filterEndDateUnix: number, ) => {

  let startDateUnix = moment.unix(lastestDataTimestampUnix - GetDaysInSeconds(14)).startOf('day').unix();

  if (startDateUnix < filterStartDateUnix) {
    startDateUnix = filterStartDateUnix;
  }

  return new NumberRange(startDateUnix, filterEndDateUnix);
}

export const GetVisibleRangeSingleSeries = (
  currentVisibleRange: { min: number | undefined; max: number | undefined; }, 
  timestamps:number[], 
  filterStartDateUnix : number, 
  filterEndDateUnix : number ) => {

  var visibleRange: { min: number | undefined; max: number | undefined; } = currentVisibleRange;
  
  if(visibleRange.min === undefined || visibleRange.max === undefined || visibleRange.max > filterEndDateUnix || visibleRange.min < filterStartDateUnix) {

    visibleRange = GetVisibleRange(timestamps[timestamps.length - 1], filterStartDateUnix, filterEndDateUnix);

  }

  return visibleRange;
}

export const GetVisibleRangeMultipleSeries = (currentVisibleRange: { min: number | undefined; max: number | undefined; }, dataCharts:any, filterStartDateUnix, filterEndDateUnix ) => {

  var visibleRange: { min: number | undefined; max: number | undefined; } = currentVisibleRange;
  
  if(visibleRange.min === undefined || visibleRange.max === undefined || visibleRange.max > filterEndDateUnix || visibleRange.min < filterStartDateUnix) {

    // Find the max timestamp from all the data charts
    let lastTimestamp = 0;
    for (let i = 0; i < dataCharts.length; i++) {
      if (dataCharts[i].timestamps[dataCharts[i].timestamps.length-1] > lastTimestamp) {
        lastTimestamp = dataCharts[i].timestamps[dataCharts[i].timestamps.length-1];
      }
    }

    visibleRange =  GetVisibleRange(lastTimestamp, filterStartDateUnix, filterEndDateUnix);
  }

  return visibleRange;
}

export const GetVisibleRangePressureSeries = (currentVisibleRange: { min: number | undefined; max: number | undefined; }, 
  latestPressureTimestampUnix: number,
  latestVolumeTimestampUnix: number,
  filterStartDateUnix, filterEndDateUnix ) => {

    var visibleRange: { min: number | undefined; max: number | undefined; } = currentVisibleRange;
  
    if(visibleRange.min === undefined || visibleRange.max === undefined || visibleRange.max > filterEndDateUnix || visibleRange.min < filterStartDateUnix) {
      if (latestPressureTimestampUnix > latestVolumeTimestampUnix) {
        visibleRange = GetVisibleRange(latestPressureTimestampUnix, filterStartDateUnix, filterEndDateUnix);
      } else {
        visibleRange = GetVisibleRange(latestVolumeTimestampUnix, filterStartDateUnix, filterEndDateUnix);
      }
    }

    return visibleRange

  };

  export const GetVisibleRangeComplianceSeries = (currentVisibleRange: { min: number | undefined; max: number | undefined; }, 
    latestVoulmeDispensendTimestampUnix: number,
    latestUnplannedVolumeTimestampUnix: number,
    filterStartDateUnix, filterEndDateUnix ) => {
  
      var visibleRange: { min: number | undefined; max: number | undefined; } = currentVisibleRange;
    
      if(visibleRange.min === undefined || visibleRange.max === undefined || visibleRange.max > filterEndDateUnix || visibleRange.min < filterStartDateUnix) {
        if (latestVoulmeDispensendTimestampUnix > latestUnplannedVolumeTimestampUnix) {
          visibleRange = GetVisibleRange(latestVoulmeDispensendTimestampUnix, filterStartDateUnix, filterEndDateUnix);
        } else {
          visibleRange = GetVisibleRange(latestUnplannedVolumeTimestampUnix, filterStartDateUnix, filterEndDateUnix);
        }
      }
  
      return visibleRange
  
    };

  export const GetVisibleRangeDiagnosticSeries = (
    currentVisibleRange: { min: number | undefined; max: number | undefined; }, 
    latestDiagnosticTimestampUnix: number,
    latestMetricTimestampUnix: number,
    lastDataPointTimestampUnix: number,
    filterStartDateUnix : number, 
    filterEndDateUnix : number ) => {
  
      var visibleRange: { min: number | undefined; max: number | undefined; } = currentVisibleRange;
    
      if(visibleRange.min === undefined || visibleRange.max === undefined || visibleRange.max > filterEndDateUnix || visibleRange.min < filterStartDateUnix) {

        if (latestDiagnosticTimestampUnix > latestMetricTimestampUnix && latestDiagnosticTimestampUnix > lastDataPointTimestampUnix) {
          visibleRange = GetVisibleRange(latestDiagnosticTimestampUnix, filterStartDateUnix, filterEndDateUnix);
        } else if (latestMetricTimestampUnix > lastDataPointTimestampUnix){
          visibleRange = GetVisibleRange(latestMetricTimestampUnix, filterStartDateUnix, filterEndDateUnix);
        } else {
          visibleRange = GetVisibleRange(lastDataPointTimestampUnix, filterStartDateUnix, filterEndDateUnix);
        }
      }
  
      return visibleRange
  
};