import { MachineUpdateDto } from "./../../../Models/Machine/MachineUpdateDto";
import { ApiRoutes } from "../../../Constants/ApiRoutingConstants";
import axios, { AxiosInstance } from "axios";
import authorizeService from "../../Identity/Utilities/AuthorizeService";
import { MachineReadDto } from "../../../Models/Machine/MachinesReadDto";
import { MachineCreateDto } from "../../../Models/Machine/MachineCreateDto";
/**
 * This service provides implementations for http calls to the api pertaining to machine crud functionality
 */

class MachinesApiService {
  axiosInstance: AxiosInstance;
  constructor() {
    //Subscribe to identity store the get token
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_ASSET_SERVICE_URL,
    });
  }

  async getAllMachines() {
    let response = await this.axiosInstance({
      method: "GET",
      url: `${ApiRoutes.Machines}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data as Array<MachineReadDto>;
  }

  async createMachine(machine: MachineCreateDto) {
    let response = await this.axiosInstance({
      method: "POST",
      url: `${ApiRoutes.Machines}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: machine,
    });
    return response.data as MachineReadDto;
  }

  async updateMachine(updatedMachine: MachineCreateDto, machineId: string) {
    let response = await this.axiosInstance({
      method: "PUT",
      url: `${ApiRoutes.Machines}/${machineId}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: updatedMachine,
    });
    return response.data;
  }

  async updateMachines(machines: Array<MachineUpdateDto>) {
    let response = await this.axiosInstance({
      method: "PUT",
      url: `${ApiRoutes.Machines}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: { machines },
    });
    return response.data;
  }

  async deleteMachines(deleteMachineIds: Array<string>) {
    let response = await this.axiosInstance({
      method: "DELETE",
      url: `${ApiRoutes.Machines}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: {
        ids: deleteMachineIds,
      },
    });
    return response.data;
  }

  static get instance() {
    return machinesApiService;
  }
}

const machinesApiService = new MachinesApiService();
export default machinesApiService;
