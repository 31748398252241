import Oidc from "oidc-client";
import { BaseUrl } from "./ApiRoutingConstants";

//Configuration values required for instantiating a instance of an OIDC User manager class
export const UserManagerConfig: Oidc.UserManagerSettings = {
  authority: BaseUrl,
  client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_REDIRECT_URL,
  response_type: "code",
  response_mode: "query",
  scope: "openid profile grease-cloud-api grease-cloud-site-management",
  post_logout_redirect_uri: process.env.REACT_APP_LOGOUT_REDIRECT_URL,
  includeIdTokenInSilentRenew: true,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
};

export const LoginActions = {
  Login: "login",
  LoginCallback: "login-callback",
  LoginFailed: "login-failed",
};
