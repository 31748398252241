import { SciChartNestedOverview, SciChartReact } from "scichart-react";
import { useLocalization } from "@progress/kendo-react-intl";
import React, { useEffect, useRef } from "react";
import { AppContext } from "../../../../../AppContext";
import { ChartOptions, Props, getChartAPI } from "./DiagnosticsChartApi";
import { getCurrentLanguage } from "../../../../Shared/SciChart/ChartConfiguration";
import { overviewOptions } from "../../../../Shared/SciChart/Overview";
import useChartStore from "../../../../../Zustand/chartStore";

export const DiagnosticsChart = (props: Props) => {

  const localizationService = useLocalization();
  const {localeId} = React.useContext<any>(AppContext);
  const currentLanguage = getCurrentLanguage(localeId);
  const aggregator = useChartStore(state => state.aggregator);
  const setVisibleRangeChartStore = useChartStore(state => state.setVisibleRange);
  const setVisibleSeriesChartStore = useChartStore(state => state.setVisibleSeriesDiagnostics);
  
  // Fetch the initial state, but we don't need to re-render when it changes as the chart will update itself
  const visibleSeriesRef = useRef(useChartStore.getState().visibleSeriesDiagnostics);

  const setVisibleRange = (range:  {min: number | undefined; max: number | undefined; }) => {
    setVisibleRangeChartStore(range.min, range.max);
  };

  const setVisibleSeries = (id: string, isVisible: boolean) => {
    setVisibleSeriesChartStore(id, isVisible);
  };
  
  var chartOptions : ChartOptions = {setVisibleRange: setVisibleRange, visibleRange: props.chartData.visibleRange, setVisibleSeriesDiagnostics: setVisibleSeries, visibleSeriesDiagnostics: visibleSeriesRef.current, aggregator: aggregator};
  const [diagnosticChartAPI] = React.useState(getChartAPI(props, chartOptions, localizationService, currentLanguage));

  return (
    <div className="flex">
      <SciChartReact
        onInit={diagnosticChartAPI.configAfterInit}
        initChart={diagnosticChartAPI.initChart}
        style={{aspectRatio: 2, width:"100%", height: "450px"}}
          //  customize size of the inner chart wrapper
          innerContainerProps={{ style: { height: "85%" } }}
      >
        <SciChartNestedOverview style={{ height: "14%" }} options={overviewOptions}/>
      </SciChartReact>
    </div>
  );
}