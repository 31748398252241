import {
  AutoComplete,
  AutoCompleteChangeEvent,
  ComboBox,
  ComboBoxChangeEvent,
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { Input, InputChangeEvent, TextArea, TextAreaChangeEvent } from "@progress/kendo-react-inputs";
import { Field, FieldProps, FormikProps } from "formik";
import { HasId } from "../../Hooks/useKendoTableState";
import { GBGridCellProps } from "../../Interfaces/GBGridCellProps";
import { objectKeysToLowerCase } from "../../Utilities";
import { FilterDescriptor, filterBy } from "@progress/kendo-data-query";
import React from "react";

/**
 * This component manages logic for rendering a cell inside the GB kendo react data grid.
 */

const getStatusMessageForField = (status: any, name: string) => {
  //Convert all object keys to lower case
  const lowerCaseName = name.toLowerCase();

  //Attempt to get error message array from status
  if (status != null) {
    const statusLowerCase = objectKeysToLowerCase(status);
    if (Array.isArray(statusLowerCase[lowerCaseName])) {
      return statusLowerCase[lowerCaseName][0];
    }
  }

  return null;
};

type Props<T extends HasId> = {
  dataFieldName: string; //Field name for form input
  fieldAccessor: (asset: T) => any; //Function for accessing the value from the parent asset
  isEditMode: boolean; //True if cell can be modified
  isAddMode: boolean; //True if add row is active
  cellProps: GBGridCellProps<T>; //Kendo cell props for the parent grid cell
  onCellChanged: (rowId: string, initialValue: any, value: any, accessor: (x: any) => any) => void; //Function invoked when input is changed
  inputType?: "text" | "combobox" | "autocomplete" | "select" | "textarea"; // The type of input data cell on edit - text, combobox or autocomplete
  assets?: Array<any>; //Table data used for populating combobox items
  displayTextAccessor?: (asset: any) => any; // The text that is shown for the field
  onChange?: (newValue: any, form: FormikProps<any>) => any; //Function invoked when a input changes
  initialDisplayText?: string; // Initial text to be displayed in the cell
  className?: string; //CSS classes applied to top level div for styling
  placeholder?: string;
};

export default function GBKendoDataCell<T extends HasId>(props: Props<T>) {

  // This is used to filter the combobox data as a temporary solution for the GBKendoDataCell
  const [comboboxData, setComboboxData] = React.useState(props.assets);

  async function inputChanged(
    newValue: InputChangeEvent | ComboBoxChangeEvent | AutoCompleteChangeEvent | DropDownListChangeEvent | TextAreaChangeEvent,
    form: FormikProps<any>,
    fieldName: string
  ) {
    //If edit row, set onCellChanged
    props.onCellChanged(
      props.cellProps.dataItem.id,
      props.fieldAccessor(props.cellProps.dataItem),
      newValue.value?.value ?? newValue.value,
      props.fieldAccessor
    );

    // If add row then update form (From formik context.)
    if (props.isAddMode) {
      //If add row, call form
      form.setFieldValue(fieldName, newValue.value?.value ?? newValue.value, true);
      form.setFieldTouched(fieldName);
    }
  }

  if (props.cellProps.rowType === 'groupHeader') {
    return null;
  }

  const filterData = (filter: FilterDescriptor) => {  
    
    if(props.assets == null) return [];

    filter.field = "name";
    return filterBy(props.assets, filter);
  };

  return (
    <td className={props.className}>
      {/* //If in edit mode, ie. no add row and edit mode selected, display edit cell. */}
      {props.isEditMode || props.isAddMode ? (
        <>
          <Field
            render={({ field, form, meta }: FieldProps) => (
              <>
                {/* Render input based on input type */}
                {props.inputType === "text" ||
                  (props.inputType == null && (
                    <Input
                      placeholder={props.placeholder}
                      onChange={(e) => {
                        props.onChange && props.onChange(e.target.value, form);
                        inputChanged(e, form, props.dataFieldName);
                      }}
                      defaultValue={props.fieldAccessor(props.cellProps.dataItem)?.toString()}
                    />
                  ))}
                {props.inputType === "combobox" && (
                  <ComboBox
                    onFocus={(event) => {
                      event.target.setState((prev) => {
                        return { ...prev, opened: true };
                      });
                    }}
                    placeholder={props.placeholder}
                    textField="displayName"
                    filterable={true}
                    value={(function () {
                      let display;
                      let value;

                      let asset = props.assets?.find((x) => x.id === props.fieldAccessor(props.cellProps.dataItem));
                      if (asset) {
                        display = props.displayTextAccessor!(asset);
                        value = asset.id;
                      } else {
                        display = null;
                        value = null;
                      }

                      return {
                        displayName: display,
                        value: value,
                      };
                    })()}
                    onChange={(e) => {
                      props.onChange && props.onChange(e.target.value?.value ?? e.target.value, form);
                      inputChanged(e, form, props.dataFieldName);
                    }}
                    onFilterChange={(event) => {
                      const newData = event.filter.value.length >= 1 ? filterData(event.filter) : props.assets?.slice();
                      setComboboxData(newData);
                    }}
                    data={comboboxData?.map((a) => {
                      return {
                        displayName: props.displayTextAccessor!(a),
                        value: a.id,
                      };
                    })}
                  />
                )}
                {props.inputType === "autocomplete" && (
                  <AutoComplete
                    onFocus={(event) => {
                      event.target.setState((prev) => {
                        return { ...prev, opened: true };
                      });
                    }}
                    placeholder={props.placeholder}
                    style={{ height: "30px" }}
                    value={props.fieldAccessor(props.cellProps.dataItem)}
                    onChange={(e) => {
                      props.onChange && props.onChange(e.target.value, form);
                      inputChanged(e, form, props.dataFieldName);
                    }}
              
                    data={Array.from(
                      new Set(
                        props.assets
                          ?.filter((x) => props.fieldAccessor(x) !== "" && props.fieldAccessor(x) !== null)
                          .map((x) => props.fieldAccessor(x))
                      )
                    )}
                  />
                )}

                {props.inputType === "textarea" && (
                  <TextArea
                    placeholder={props.placeholder}
                    autoSize={true} rows={2}
                    value={props.fieldAccessor(props.cellProps.dataItem)}
                    onChange={(e) => {
                      props.onChange && props.onChange(e.value, form);
                      inputChanged(e, form, props.dataFieldName);
                    }}
                    defaultValue={props.fieldAccessor(props.cellProps.dataItem)?.toString()}
                  />
                )}

                {props.inputType === "select" && (
                  <DropDownList
                    style={{ height: "30px" }}
                    value={props.fieldAccessor(props.cellProps.dataItem)}
                    onChange={(e) => {
                      inputChanged(e.target.value, form, props.dataFieldName);
                      props.onChange && props.onChange(e.target.value, form);
                    }}
                    data={props.assets}
                  />
                )}

                {/* Client Side Error Message*/}
                {form.errors[props.dataFieldName] && form.touched[props.dataFieldName] && (
                  <div className="text-xs text-red-600">* <>{form.errors[props.dataFieldName]}</></div>
                )}

                {/* Server Side Error Message */}
                {getStatusMessageForField(form.status, props.dataFieldName) && (
                  <div className="text-xs text-red-600">
                    *{getStatusMessageForField(form.status, props.dataFieldName)}
                  </div>
                )}
              </>
            )}
          />
        </>
      ) : props.fieldAccessor(props.cellProps.dataItem) === "" ||
        props.fieldAccessor(props.cellProps.dataItem) === null ? (
        props.placeholder
        
      ) : (
        <>
          <>
            {(props.inputType === "text" ||
              props.inputType === "textarea" ||
              props.inputType == null ||
              props.inputType === "autocomplete" ||
              props.inputType === "select") &&
              props.fieldAccessor(props.cellProps.dataItem)}
          </>
          <>{props.inputType === "combobox" && props.initialDisplayText}</>
        </>
      )}
    </td>
  );
}