import { ReactElement, } from "react";
import { useLocalization } from '@progress/kendo-react-intl';
import { EventFilter } from "../../../../Events/Utilities/EventsApiService";
import { useQuery } from "react-query";
import treeListApiService from "../../../Utilities/TreeListApiService";
import LoadingSpinner from "../../../../Shared/Components/LoadingSpinner";
import ErrorCard from "../../../../Shared/Components/Cards/ErrorCard";
import EndpointFeatureDto from "../../../../../Models/Endpoint/EndpointFeatureDto";
import EndpointGpsEventsTable from "../../../Components/Endpoints/EndpointGpsEventsTable";
import EndpointGpsEventsTableDto from "../../../../../Models/Endpoint/EndpointGpsEventsTableDto";
import { enMessages } from "../../../../../messages/en-US";


interface Props {
  eventFilter: EventFilter
  selectedItem: string
  aggregator: string;
  endpointFeature: EndpointFeatureDto;

}

export default function EndpointActivityTabPageGpsOnly(props: Props): ReactElement {
  const localizationService = useLocalization();

  const endpointGpsEventsTableQuery = useQuery<EndpointGpsEventsTableDto[]>([`endpointGpsEventsTableQuery${props.selectedItem}`, props.selectedItem, props.eventFilter, props.aggregator], async () => {
    return  treeListApiService.getEndpointGpsEventsTable(props.selectedItem, props.eventFilter, 'none') 
  });

  if (endpointGpsEventsTableQuery.isLoading || endpointGpsEventsTableQuery.isFetching) {
    return <LoadingSpinner />;
  } else if (endpointGpsEventsTableQuery.isError) {
    return <ErrorCard />;
  } else {
  return (
    <>
      <div className="mt-5">
        <EndpointGpsEventsTable events={endpointGpsEventsTableQuery.data!} aggregator={'none'} title={localizationService.toLanguageString('custom.gpsEvents', enMessages.custom.gpsEvents)}/>   
      </div>
    </>
  )
}}