import { ReactElement, useMemo, useState, } from "react";
import { useLocalization } from '@progress/kendo-react-intl';
import { EventFilter } from "../../../../Events/Utilities/EventsApiService";
import { useQuery } from "react-query";
import treeListApiService from "../../../Utilities/TreeListApiService";
import LoadingSpinner from "../../../../Shared/Components/LoadingSpinner";
import ErrorCard from "../../../../Shared/Components/Cards/ErrorCard";
import EndpointPressureEventsTableDto from "../../../../../Models/Endpoint/EndpointPressureEventsTableDto";
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from "@progress/kendo-react-layout";
import EndpointPressureEventsTable from "../../../Components/Endpoints/EndpointPressureEventsTable";
import { enMessages } from "../../../../../messages/en-US";
import EndpointEventsTable from "../../../Components/Endpoints/EndpointEventsTable";
import EndpointFlowRateEventsTable from "../../../Components/Endpoints/EndpointFlowRateEventsTable";
import EndpointFlowRateEventsTableDto from "../../../../../Models/Endpoint/EndpointFlowRateEventsTableDto";
import EndpointFeatureDto from "../../../../../Models/Endpoint/EndpointFeatureDto";
import EndpointGpsEventsTable from "../../../Components/Endpoints/EndpointGpsEventsTable";
import EndpointGpsEventsTableDto from "../../../../../Models/Endpoint/EndpointGpsEventsTableDto";
import EndpointTemperatureEventsTable from "../../../Components/Endpoints/EndpointTemperatureEventsTable";
import EndpointTemperatureEventsTableDto from "../../../../../Models/Endpoint/EndpointTemperatureEventsTableDto";


interface Props {
  eventFilter: EventFilter
  selectedItem: string
  aggregator: string;
  endpointFeature: EndpointFeatureDto;

}

export default function EndpointActivityTabPageForTables(props: Props): ReactElement {
  const localizationService = useLocalization();

  const [selected, setSelected] = useState<number>(0);

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected);
  };

  const endpointsQueriesTable = useQuery<any>(['endpointTable', props.selectedItem, props.eventFilter, props.aggregator], 
    async () => treeListApiService.getAllEndpointsEventsFlowTable(props.eventFilter, props.selectedItem, props.aggregator), {enabled: props.endpointFeature.isFlowSupported} );


  const endpointPressureEventsTableQuery = useQuery<EndpointPressureEventsTableDto[]>([`endpointPressureEventsTableQuery${props.selectedItem}`, props.selectedItem, props.eventFilter, props.aggregator], async () => {
    return  treeListApiService.getEndpointPressureEventsTable(props.selectedItem, props.eventFilter, props.aggregator) 
  }, {enabled: props.endpointFeature.isPressureSupported});

  const endpointFlowRateEventsTableQuery = useQuery<EndpointFlowRateEventsTableDto[]>([`endpointFlowRateEventsTableQuery${props.selectedItem}`, props.selectedItem, props.eventFilter, props.aggregator], async () => {
    return  treeListApiService.getEndpointFlowRateEventsTable(props.selectedItem, props.eventFilter, 'none') 
  }, {enabled: props.endpointFeature.isFlowRateSupported});

  const endpointGpsEventsTableQuery = useQuery<EndpointGpsEventsTableDto[]>([`endpointGpsEventsTableQuery${props.selectedItem}`, props.selectedItem, props.eventFilter, props.aggregator], async () => {
    return  treeListApiService.getEndpointGpsEventsTable(props.selectedItem, props.eventFilter, 'none') 
  }, {enabled: props.endpointFeature.isGpsSupported});

  const endpointTemperatureEventsTableQuery = useQuery<EndpointTemperatureEventsTableDto[]>([`endpointTemperatureEventsTableQuery${props.selectedItem}`, props.selectedItem, props.eventFilter, props.aggregator], async () => {
    return  treeListApiService.getEndpointTemperatureEventsTable(props.selectedItem, props.eventFilter, 'none') 
  }, {enabled: props.endpointFeature.isTemperatureSupported});

  if (endpointPressureEventsTableQuery.isLoading || endpointPressureEventsTableQuery.isFetching || 
      endpointsQueriesTable.isLoading || endpointsQueriesTable.isFetching || 
      endpointFlowRateEventsTableQuery.isLoading || endpointFlowRateEventsTableQuery.isFetching ||
      endpointGpsEventsTableQuery.isLoading || endpointGpsEventsTableQuery.isFetching || 
      endpointTemperatureEventsTableQuery.isLoading || endpointTemperatureEventsTableQuery.isFetching) {
    return <LoadingSpinner />;
  } else if (endpointPressureEventsTableQuery.isError || endpointsQueriesTable.isError || endpointFlowRateEventsTableQuery.isError || endpointGpsEventsTableQuery.isError || endpointTemperatureEventsTableQuery.isError) {
    return <ErrorCard />;
  } else {
  return (
    <>
      <div className="mt-5">
      {props.endpointFeature.isPressureSupported ? 
      <TabStrip selected={selected} onSelect={handleSelect} className="h-full">
        <TabStripTab title={localizationService.toLanguageString('custom.flow', enMessages.custom.flow)} >
          <EndpointEventsTable events={endpointsQueriesTable.data!} aggregator={props.aggregator}/>
        </TabStripTab>
        <TabStripTab title={localizationService.toLanguageString('custom.pressure', enMessages.custom.pressure)} >
          <EndpointPressureEventsTable events={endpointPressureEventsTableQuery.data!} aggregator={props.aggregator}/>   
        </TabStripTab>
      </TabStrip>
      :
      props.endpointFeature.isFlowRateSupported || props.endpointFeature.isGpsSupported ? (
        
      <TabStrip selected={selected} onSelect={handleSelect}>
        {props.endpointFeature.isFlowRateSupported &&
          <TabStripTab title={localizationService.toLanguageString('custom.flowRate', enMessages.custom.flowRate)} >
            <EndpointFlowRateEventsTable events={endpointFlowRateEventsTableQuery.data!} aggregator={'none'}/>   
          </TabStripTab>
        }
        {(props.endpointFeature.isGpsSupported && !!endpointGpsEventsTableQuery.data) &&
          <TabStripTab title={localizationService.toLanguageString('custom.gps', enMessages.custom.gps)} >
            <EndpointGpsEventsTable events={endpointGpsEventsTableQuery.data!} aggregator={'none'} title={localizationService.toLanguageString('custom.gps', enMessages.custom.gps)}/>   
          </TabStripTab>
        }
         {(props.endpointFeature.isTemperatureSupported && !!endpointTemperatureEventsTableQuery.data) &&
          <TabStripTab title={localizationService.toLanguageString('custom.temperature', enMessages.custom.temperature)} >
            <EndpointTemperatureEventsTable events={endpointTemperatureEventsTableQuery.data!} aggregator={'none'}/>   
          </TabStripTab>
        }
      </TabStrip>
      )
      :
      <EndpointEventsTable events={endpointsQueriesTable.data!} aggregator={props.aggregator}/>
      }
     
    </div>

  </>
    
  )
}}