/**
 * This method takes header and body fields and constructs a csv context blob that may be downloaded by the user.
 * This has not been tested with large CSV files. Does not support IE.
 * Src:
 * https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
 * @param headers
 * @param body
 */
function createCsvContent(headers: Array<string>, dataRows: Array<Array<string>>): string {
    var csvContent = '';

    //Add the header row first
    const headerString = headers.join(",");
    csvContent += headerString + '\n'

    //Append each row to the string
    dataRows.forEach((dataRow, index) => {
        let dataString = dataRow.join(',');
        csvContent += index < dataRows.length ? dataString + '\n' : dataString;
    });

    return csvContent;
}

function exportToCsv(headers: Array<string>, dataRows: Array<Array<string>>, fileName: string, location: Location) {
    var a = document.createElement('a');
    const mimeType = 'text/csv;encoding:utf-8';
    const csvContent = createCsvContent(headers, dataRows);

    if (URL && 'download' in a) { //html5 A[download]
        a.href = URL.createObjectURL(new Blob([csvContent], {
            type: mimeType
        }));
        a.setAttribute('download', fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    } else {
        location.href = 'data:application/octet-stream,' + encodeURIComponent(csvContent); // only this mime type is supported
    }
}

export default exportToCsv;