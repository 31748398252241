import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useLocalization } from "@progress/kendo-react-intl";
import {
  Upload,
  UploadOnRemoveEvent,
  UploadOnStatusChangeEvent,
} from "@progress/kendo-react-upload";
import { useEffect, useState } from "react";
import { enMessages } from "../../../../messages/en-US";
import { ImportErrorDto } from "../../../../Models/Import/ImportErrorDto";
import ProcessingSpinner from "../../../Reporting/Components/ProcessingSpinner";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { Button, ToolbarSpacer } from "@progress/kendo-react-buttons";
import React from "react";
import authorizeService from "../../../Identity/Utilities/AuthorizeService";

interface Props {
  onDialogClose: (isSuccess?: boolean) => () => void;
  url: string;
  title: string;

}

export default function UploadCSVDialog(props: Props) {
  const localizationService = useLocalization();

  const _export = React.useRef<ExcelExport | null>(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  const [token, setToken] = useState("");
  const [disableCloseButton, setDisableCloseButton] = useState(false);
  const [importErrors, setImportErrors] = useState<ImportErrorDto[]>([]);

  const getToken = async () => {
    try {
      const user = await authorizeService.getUser();
      setToken(user.access_token);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getToken();
  }, []);

  const [isSuccess, setIsSuccess] = useState(false);

  const onStatusChange = (event: UploadOnStatusChangeEvent) => {
    // Once the file has been uploaded, we need to refetch the assets which forces an update of the tables.
    if (event.affectedFiles[0].status === 4) {
      setIsSuccess(true);
      setDisableCloseButton(false);
    } else if (event.affectedFiles[0].status === 0) {
      // Enable the dialog close button if the upload failed
      setDisableCloseButton(false);
      setImportErrors(event.response?.response?.data);
    } else {
      // Disable the dialog close button while the upload is in progress
      setDisableCloseButton(true);
    }
  };

  const clearImportErrors = () => {
    setImportErrors([]);
  };

  const onRemove = (event: UploadOnRemoveEvent) => {
    clearImportErrors();
    setDisableCloseButton(false);
  };

  return (
    <div>
      <Dialog
        title={props.title}
        onClose={() => props.onDialogClose(isSuccess)}
        width={800} 
        height="70%"
      >
        <div className="gb-text-normal my-3">
          <strong>{localizationService.toLanguageString('custom.uploadCSVText', enMessages.custom.uploadCSVText)}</strong>
        </div>
        <Upload
          multiple={false}
          batch={false}
          autoUpload={false}
          actionsLayout={"start"}
          restrictions={{
            allowedExtensions: [".csv"],
          }}
          defaultFiles={[]}
          saveUrl={props.url}
          saveHeaders={{ ["Authorization"]: `Bearer ${token}` }}
          withCredentials={false}
          onStatusChange={onStatusChange}
          onRemove={onRemove}
          onBeforeUpload={clearImportErrors}
          onAdd={clearImportErrors}
        />

        {disableCloseButton && <ProcessingSpinner />}

        {importErrors?.length > 0 && (
          <div style={{ height: "70%" }}>
            <div className="k-flex k-vbox my-2" style={{ height: "100%" }}>
              <ExcelExport data={importErrors} ref={_export}>
                <Grid
                  style={{ height: "100%" }}
                  data={importErrors}
                  rowHeight={46}
                >
                  <GridToolbar>
                    <strong>
                      {localizationService.toLanguageString(
                        "custom.errors",
                        enMessages.custom.errors
                      )}
                      :
                    </strong>
                    <ToolbarSpacer />
                    <Button icon="download" onClick={excelExport} />
                  </GridToolbar>
                  <GridColumn field="row" width="55px" title="Row" />
                  <GridColumn field="error" title="Reason" />
                </Grid>
              </ExcelExport>
            </div>
          </div>
        )}

        <DialogActionsBar layout="end">
          <button
            disabled={disableCloseButton}
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
            onClick={() => props.onDialogClose(isSuccess)}
          >
            {localizationService.toLanguageString(
              "filter.close",
              enMessages.filter.close
            )}
          </button>
        </DialogActionsBar>
      </Dialog>
    </div>
  );
}
