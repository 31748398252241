import * as yup from "yup";
import { enMessages } from "../../../messages/en-US";

export default function UserValidationSchema(locationService:any):any{ yup.object().shape({
  email: yup.string().required(`${locationService.toLanguageString('custom.emailRequired', enMessages.custom.emailRequired)}`).email(`${locationService.toLanguageString('custom.invalidEmail', enMessages.custom.invalidEmail)}`),
  roleId: yup
    .string()
    .required(`${locationService.toLanguageString('custom.roleRequired', enMessages.custom.roleRequired)}`)
    .matches(/^[ A-Za-z0-9_@./#&+-]*$/, `${locationService.toLanguageString('custom.noSpecialCharacters', enMessages.custom.noSpecialCharacters)}`),
  siteId: yup.string().matches(/^[ A-Za-z0-9_@./#&+-]*$/, `${locationService.toLanguageString('custom.noSpecialCharacters', enMessages.custom.noSpecialCharacters)}`),
})};
