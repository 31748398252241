import { ReactElement, useRef, useState, } from "react";
import { useLocalization } from '@progress/kendo-react-intl';
import { IInitResult, SciChartGroup } from "scichart-react";
import { ChartModifierBase2D, ISciChartSurfaceBase, SciChartVerticalGroup } from "scichart";
import { ModifierGroup } from "../../../Shared/SciChart/ModifierGroup";
import { synchronizeXVisibleRanges } from "../../../Shared/SciChart/ChartConfiguration";
import ChartWaitLoader from "../../../Shared/Components/ChartWaitLoader";
import { StatusChart } from "../../Components/Charts/Status/StatusChart";
import { ActivityChart } from "../../Components/Charts/Activity/ActivityChart";
import useChartStore from "../../../../Zustand/chartStore";
import { GetVisibleRangeSingleSeries } from "../../../../Utilities/ChartUtilities";
import moment from "moment";


interface Props {
  statusChartData: any;
  activityChartData: any;
}

export default function PointStatusActivity(props: Props): ReactElement {
  const localizationService = useLocalization();

  const [modifierGroup] = useState(new ModifierGroup());
  const verticalGroup = new SciChartVerticalGroup();
  const visibleRangeRef = useRef(useChartStore.getState().visibleRange);
 
  const [isChartsInitialised, setIsChartsInitialised] = useState(false);

  let visibleRange = GetVisibleRangeSingleSeries(
    visibleRangeRef.current, 
    props.activityChartData.timestamps, 
    moment(props.activityChartData.dateFilter.startDate).unix(), 
    moment(props.activityChartData.dateFilter.endDate).unix());

  const statusChartData = {...props.statusChartData, ...{modifierGroup: modifierGroup}, visibleRange};
  const activityChartData = {...props.activityChartData, ...{modifierGroup: modifierGroup}, visibleRange};

  const configureStatusAndActivityDataBindings = (initResults: IInitResult<ISciChartSurfaceBase>[] | [any, any]) => {
    const [statusChart, activityChart] = initResults;
    
    modifierGroup.clear();

    var statusRollOverModifier = statusChart.sciChartSurface.chartModifiers.getById("StatusRollOver");
    modifierGroup.add(statusRollOverModifier as ChartModifierBase2D);

    const activityModifierGroupModifier = activityChart.sciChartSurface.chartModifiers.getById("ActivityModifierGroup");
    const activityRollOverModifier = activityChart.sciChartSurface.chartModifiers.getById("ActivityRollOver");
    modifierGroup.add(activityModifierGroupModifier as ChartModifierBase2D, activityRollOverModifier as ChartModifierBase2D);

    synchronizeXVisibleRanges([statusChart.sciChartSurface, activityChart.sciChartSurface], () => true);

    verticalGroup.addSurfaceToGroup(statusChart.sciChartSurface);
    verticalGroup.addSurfaceToGroup(activityChart.sciChartSurface);

  };

  return (
    <>
      <SciChartGroup
        onInit={(initResults: IInitResult[]) => {
          configureStatusAndActivityDataBindings(initResults);
          setIsChartsInitialised(true);
        }}
      >
        {isChartsInitialised ? null : <ChartWaitLoader />}
        <StatusChart key={"status-chart"} chartData={statusChartData}/>
        <ActivityChart key={"activity-chart"} chartData={activityChartData}/>

        <div className="flex flex-wrap">
          <div id="legend-activity-flow"></div>
          <div id="legend-status" ></div>
        </div>

      </SciChartGroup>
    </>
  )
}