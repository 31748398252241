import { ReactElement } from "react";
import {TabStrip,TabStripSelectEventArguments,TabStripTab,} from '@progress/kendo-react-layout';
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../../messages/en-US";
import React from "react";
import { EventFilter } from "../../../Events/Utilities/EventsApiService";
import AllPointsActivityTabPage from "./AllPointsActivityTabPage";
import NoDataPage from "../NoDataPage";

interface Props {
  selectedItem: string
  eventFilter: EventFilter
  ids: string[]
}

export default function AllPointsDetailsPage(props: Props): ReactElement {
  const localizationService = useLocalization();

  //TabStrip
  const [selected, setSelected] = React.useState<number>(0);

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected);
  };

  return (
    <div className="tabstrip h-full">
      {props.ids?.length !== 0 ?
      <TabStrip selected={selected} onSelect={handleSelect} className="h-full">
        <TabStripTab title={`${localizationService.toLanguageString('custom.activity', enMessages.custom.activity)}`}>
          <AllPointsActivityTabPage 
            points={props.ids}  eventFilter={props.eventFilter} />
        </TabStripTab>
      </TabStrip>
      :
      <NoDataPage />
    }
    </div>
  )
}
