
import * as yup from "yup";
import { enMessages } from "../../../messages/en-US";

export default function PointValidationSchema(locationService:any) {
    return yup.object().shape({
    consumableId: yup.string().required(`${locationService.toLanguageString('custom.fieldIsRequired', enMessages.custom.fieldIsRequired)}`).nullable(),
    machineId: yup.string().required(`${locationService.toLanguageString('custom.fieldIsRequired', enMessages.custom.fieldIsRequired)}`).nullable(),
    areaId: yup.string().required(`${locationService.toLanguageString('custom.fieldIsRequired', enMessages.custom.fieldIsRequired)}`).nullable(),
    volumeRequirement: yup.string().matches(/^(Purge|purge|P|p|-1|\d+([\.]\d+)?)$/, `${locationService.toLanguageString('custom.fieldIsRequired', enMessages.custom.fieldIsRequired)}`).required(`${locationService.toLanguageString('custom.volumeRequirementValidation', enMessages.custom.volumeRequirementValidation)}`),
    name: yup.string().min(2).max(48).required(`${locationService.toLanguageString('custom.fieldIsRequired', enMessages.custom.fieldIsRequired)}`),
    //@ts-ignore
    currentTagRfid: yup.string().max(24).optional(),
})};
