import { ApiRoutes } from "../../../Constants/ApiRoutingConstants";
import axios, { AxiosInstance } from "axios";
import authorizeService from "../../Identity/Utilities/AuthorizeService";
import AlertsMetricsReadDto from "../../../Models/Dashboard/AlertsMetricsReadDto";
import AutoComplianceReadDto from "../../../Models/Dashboard/AutoComplianceReadDto";
import EndpointsMetricsReadDto from "../../../Models/Dashboard/EndpointsMetricsReadDto";
import TagsMetricsReadDto from "../../../Models/Dashboard/TagsMetricsReadDto";
import ManualComplianceReadDto from "../../../Models/Dashboard/ManualComplianceReadDto";
import AccountSettingsReadDto from "../../../Models/Dashboard/AccountSettingsReadDto";

/**
 * This service provides implementations for http calls to the api pertaining to event crud functionality
 */

class DashboardApiService {
  axiosInstance: AxiosInstance;
  constructor() {
    //Subscribe to identity store the get token
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_EVENT_SERVICE_URL,
    });
  }

  async getEndpointsMetrics(): Promise<EndpointsMetricsReadDto> {
    const response = await this.axiosInstance({
      method: "GET",
      //@ts-ignore
      url: `${ApiRoutes.Dashboard}/endpoint-metrics`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    })
    return response.data;
  }

  async getTagsMetrics(): Promise<TagsMetricsReadDto> {
    const response = await this.axiosInstance({
      method: "GET",
      //@ts-ignore
      url: `${ApiRoutes.Dashboard}/point-metrics`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    })
    return response.data;
  }

  async getAlertsMetrics(dateRange ): Promise<AlertsMetricsReadDto> {
     //Covnert EventFilters to use Iso date strings
     const isoDateRange = {...dateRange, 
      startDate : dateRange.startDate?.toISOString(),
      endDate : dateRange.endDate?.toISOString()}

    const response = await this.axiosInstance({
      method: "GET",
      //@ts-ignore
      url: `${ApiRoutes.Dashboard}/alert-metrics?${new URLSearchParams(isoDateRange).toString()}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    })
    return response.data;
  }

  async getAutoCompliance(dateRange ): Promise<AutoComplianceReadDto> {
    //Covnert EventFilters to use Iso date strings
    const isoDateRange = {...dateRange, 
      startDate : dateRange.startDate?.toISOString(),
      endDate : dateRange.endDate?.toISOString()}

    const response = await this.axiosInstance({
      method: "GET",
      //@ts-ignore
      url: `${ApiRoutes.Dashboard}/automatic-compliance?${new URLSearchParams(isoDateRange).toString()}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    })
    return response.data;
  }

  async getManualCompliance(dateRange ): Promise<ManualComplianceReadDto> {
    //Covnert EventFilters to use Iso date strings
    const isoDateRange = {...dateRange, 
      startDate : dateRange.startDate?.toISOString(),
      endDate : dateRange.endDate?.toISOString()}

    const response = await this.axiosInstance({
      method: "GET",
      //@ts-ignore
      url: `${ApiRoutes.Dashboard}/manual-compliance?${new URLSearchParams(isoDateRange).toString()}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    })
    return response.data;
  }

  async getAccountSettings(): Promise<AccountSettingsReadDto> {
    const response = await this.axiosInstance({
      method: "GET",
      //@ts-ignore
      url: `${ApiRoutes.AccountSettings}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    })
    return response.data;
  }

}

const dashboardApiService = new DashboardApiService();
export default dashboardApiService;
