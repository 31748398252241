/**
 * This store makes global layout state accessible globally throughout the application.
 *
 * Author: Max Wroe
 * Date: 30/03/2022
 */

import create from "zustand";

interface ILayoutStore {
  isSidebarOpen: boolean;
  setIsSidebarOpen: (isOpen: boolean) => void;
  tableModifiedDataCounts: {
    [tablekey: string]: number;
  };
  setModifiedDataCounts: (newCount: { [tablekey: string]: number }) => void;
  isModifiedData: () => boolean;
}

const useLayoutStore = create<ILayoutStore>((set, get) => ({
  isSidebarOpen: true,
  setIsSidebarOpen: (isOpen: boolean) => {
    set((state) => {
      return {
        isSidebarOpen: isOpen,
      };
    });
  },
  tableModifiedDataCounts: {},
  setModifiedDataCounts: (newCount) => {
    set((state) => {
      return { ...state, tableModifiedDataCounts: newCount };
    });
  },
  /**
   * Returns boolean representing if there is any unsaved data in mounted components.
   */
  isModifiedData: () => {
    if (Object.values(get()?.tableModifiedDataCounts).length > 0) {
      const totalModified = Object.values(get()?.tableModifiedDataCounts).reduce((prev, current) => {
        return prev + current;
      }, 0);
      return totalModified > 0;
    } else {
      return false;
    }
  },
}));

export default useLayoutStore;
