import { ReactElement, useState } from "react";
import { Dialog, DialogActionsBar, Window } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import React from "react";
interface Props {
  onClose: () => void;
  deleteCallback: any
  deletingItemLabel: string | undefined;
}

export default function AuthTokenDeleteDialog(props: Props): ReactElement {
  
  const [deleteToken, setDeleteToken] = React.useState<string>('');

  return (
    <Dialog title={`Delete Auth Token`} onClose={props.onClose}>
      <div className="text-lg max-w-md mb-7">Are you sure you want to delete <strong>"{props.deletingItemLabel}"</strong> Auth Token?</div>
      <div className="text-lg max-w-md mb-2">Type <strong>delete-token</strong> to delete it.</div>
      <Input onChange={(e) => setDeleteToken(e.value)}></Input>
      {/* Footer */}
      <DialogActionsBar>
        <Button
          themeColor={"base"}
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
          onClick={props.onClose}
        >
          Cancel
        </Button>
        <Button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
          themeColor={"primary"}
          onClick={() => {props.deleteCallback();}}
          disabled={deleteToken === 'delete-token' ? false : true}
        >
          Delete
        </Button>
      </DialogActionsBar>
    </Dialog>
  );
}
