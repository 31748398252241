import {
  Grid,
  GridColumn,
  GridDataStateChangeEvent,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { ReactElement, useEffect, useRef, useState } from "react";
import { useLocalization } from "@progress/kendo-react-intl";
import {
  CompositeFilterDescriptor,
  DataResult,
  FilterDescriptor,
  SortDescriptor,
  State,
  process,
} from "@progress/kendo-data-query";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import { CustomColumnMenu } from "../Utilities/customColumnMenu";
import { enMessages } from "../../../messages/en-US";
import { Button } from "@progress/kendo-react-buttons";
import moment from "moment";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { useRefState } from "../../Shared/Hooks/useRefState";
import { InputClearValue, InputSeparator, TextBox, TextBoxProps } from "@progress/kendo-react-inputs";
import React from "react";
import { MultiSelect, MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
import { Icon } from "@progress/kendo-react-common";

interface Props {
  notifications: any;
}

interface Item {
  text: string;
  fields: string[]; 
}

const ColumnsOptions: Item[] = [
  { text: 'Alert', fields: ["name", "description"] },
  { text: 'Assigned To', fields: ["assignedTo.name", "assignedTo.details"] },
  { text: 'Criticality', fields: ["criticality"] },
  { text: 'Type', fields: ["type"] }
];

export default function AlertsTable(props: Props): ReactElement {
  const localizationService = useLocalization();
  const notifications = props.notifications;

  const initialSort: Array<SortDescriptor> = [
    { field: "created", dir: "desc" }
  ];
  const [sort, setSort] = useState(initialSort);

  const _export = useRef<ExcelExport | null>(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  //Creating dataState to prevent issues with virtual scrolling
  const createDataState = (dataState: State) => {
    return {
      result: process(notifications.slice(0), dataState),
      dataState: dataState,
    };
  };

  let initialState = createDataState({
    take: 30,
    skip: 0,
    sort: sort,
  });

  const [result, setResult] = useState<DataResult>(initialState.result);
  const [dataState, setDataState] = useState<State>(initialState.dataState);

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    let updatedState = createDataState(event.dataState);
    setResult(updatedState.result);
    setDataState(updatedState.dataState);
  };

  const AlertCell = (props) => {
    return (
      <td>
        <div>{props.dataItem.name}</div>
        <div className="text-xs">{props.dataItem.description}</div>
      </td>
    );
  };

  const EmailSentCell = (props) => {
    const icon = props.dataItem.isEmailSent ? "k-i-check" : "k-i-close";
    return (
      <td>
        <div className={`k-icon ${icon}`}></div>
      </td>
    );
  };

  const CreatedCell = (props) => {
    return (
      <td>
        <Tooltip position={"bottom"} anchorElement={"target"}>
          <div 
            className="cursor-pointer"
            title={moment(props.dataItem.created).format("DD/MM/YYYY @ HH:mm:ss")}>
            {moment(props.dataItem.created).fromNow()}</div>
        </Tooltip>
      </td>
    );
  };

  const CriticalityCell = (props) => {
    let item = props.dataItem;
    const colour =
      item.criticality === "Critical"
        ? "#780000"
        : item.criticality === "High"
        ? "#dc0000"
        : item.criticality === "Medium"
        ? "#fd8c00"
        : "#fdc500";
    const icon =
      item.criticality === "Critical"
        ? "k-i-chevron-double-up"
        : item.criticality === "High"
        ? "k-i-arrow-chevron-up"
        : item.criticality === "Medium"
        ? "k-i-equal"
        : "k-i-arrow-chevron-down";
    return (
      <td className="">
        <Tooltip position={"bottom"} anchorElement={"target"}>
          <span
            className={`k-icon ${icon} cursor-pointer k-icon-xl`}
            style={{
              color: `${colour}`,
            }}
            title={item.criticality}
          ></span>
        </Tooltip>
      </td>
    );
  };

  const AssignedToCell = (props) => {
    return (
      <td>
        <div>{props.dataItem.assignedTo.name}</div>
        <div className="text-xs">{props.dataItem.assignedTo.details}</div>
      </td>
    );
  }

  const HeaderAssignedToCell = (props) => {
    const TooltipContentTemplate = (props: any) => {
      return (
        <div>
          <div className="underline">{localizationService.toLanguageString("custom.requirement", enMessages.custom.requirement)} {localizationService.toLanguageString("custom.alert", enMessages.custom.alert)}</div>
         <div>{localizationService.toLanguageString("custom.endpoint", enMessages.custom.endpoint)} ({localizationService.toLanguageString("custom.serialNumber", enMessages.custom.serialNumber)})</div>
         <div>{localizationService.toLanguageString("custom.location", enMessages.custom.location)} &gt; {localizationService.toLanguageString("custom.asset", enMessages.custom.asset)}</div>
        </div>
      );
    };
    return (
      <span onClick={props.onClick} className={'k-cell-inner'}>
        <span className={'k-link'}>
          <span className={'k-column-title'}>{props.title}</span>{' '}
          <Tooltip anchorElement="target" position="bottom" content={(props) => <TooltipContentTemplate title={props.title}  />}>
          <span className="k-icon k-i-information ml-3" style={{cursor:"pointer"}} title="title"></span>
          </Tooltip>
          {props.children}
        </span>       
      </span>);
  };

  const HeaderCriticalityCell = (props) => {

    const TooltipContentTemplate = (props: any) => {
      return (
        <div>
          <div>
            <span className="k-icon k-i-chevron-double-up"></span>
            <span>: {localizationService.toLanguageString("custom.critical",enMessages.custom.critical)}</span>
          </div>
          <div>
            <span className="k-icon k-i-arrow-chevron-up"></span>
            <span>: {localizationService.toLanguageString("custom.high",enMessages.custom.high)}</span>
          </div>
          <div>
            <span className="k-icon k-i-equal"></span>
            <span>: {localizationService.toLanguageString("custom.medium",enMessages.custom.medium)}</span>
          </div>
          <div>
            <span className="k-icon k-i-arrow-chevron-down"></span>
            <span>: {localizationService.toLanguageString("custom.low",enMessages.custom.low)}</span>
          </div>
        </div>
      );
    };
    return (
      <span onClick={props.onClick} className={'k-cell-inner'}>
        <span className={'k-link'}>
          <span className={'k-column-title'}>{props.title}</span>{' '}
          <Tooltip anchorElement="target" position="bottom" content={(props) => <TooltipContentTemplate title={props.title}  />}>
          <span className="k-icon k-i-information ml-3" style={{cursor:"pointer"}} title="title"></span>
          </Tooltip>
          {props.children}
        </span>       
      </span>);
  };

  const HeaderTypeCell = (props) => {

    const TooltipContentTemplate = (props: any) => {
      return (
        <div>
          <div>
            <span className="k-icon k-i-blur"></span>
            <span>: {localizationService.toLanguageString("custom.reservoir", enMessages.custom.reservoir)} {localizationService.toLanguageString("custom.alert", enMessages.custom.alert)}</span>
          </div>
          <div>
            <span className="k-icon k-i-track-changes-accept"></span>
            <span>: {localizationService.toLanguageString("custom.requirement", enMessages.custom.requirement)} {localizationService.toLanguageString("custom.alert", enMessages.custom.alert)}</span>
          </div>
        </div>
      );
    };
    return (
      <span onClick={props.onClick} className={'k-cell-inner'}>
        <span className={'k-link'}>
          <span className={'k-column-title'}>{props.title}</span>{' '}
          <Tooltip anchorElement="target" position="bottom" content={(props) => <TooltipContentTemplate title={props.title}  />}>
          <span className="k-icon k-i-information ml-3" style={{cursor:"pointer"}} title="title"></span>
          </Tooltip>
          {props.children}
        </span>       
      </span>);
  };

  const TypeCell = (props) => {

    return (
      <td>
         <Tooltip position={"bottom"} anchorElement={"target"}>
          {props.dataItem.type === "Reservoir" ?
            <span className="k-icon k-i-blur k-icon-xl cursor-pointer" title={`${localizationService.toLanguageString("custom.reservoir", enMessages.custom.reservoir)} ${localizationService.toLanguageString("custom.alert", enMessages.custom.alert)}`}></span> :
            <span className="k-icon k-i-track-changes-accept k-icon-xl cursor-pointer"  title={`${localizationService.toLanguageString("custom.requirement", enMessages.custom.requirement)} ${localizationService.toLanguageString("custom.alert", enMessages.custom.alert)}`}></span>
          }
        </Tooltip>
      </td>
    );
  };

  const ADJUST_PADDING: number = 4;
  const COLUMN_MIN: number = 4;

  const minGridWidth = useRef<number>(0);
  const grid = useRef<any>(null);
  const [applyMinWidth, setApplyMinWidth] = useState(false);
  const [gridCurrent, setGridCurrent] = useState(0);

  useEffect(() => {
    let updatedState = createDataState(dataState);
    setResult(updatedState.result);
    setDataState(updatedState.dataState);
    // setGridWidth(document.getElementById("notificationsTable")?.offsetWidth);
  }, [notifications]);

  // Columns for the table
  const columns: Array<any> = [
    {
      field: "name",
      title: localizationService.toLanguageString(
        "custom.alert",
        enMessages.custom.alert
      ),
      filterable: true,
      show: true,
      minWidth: 200,
      cell: AlertCell,
    },
    {
      field: "assignedTo.name",
      title: localizationService.toLanguageString(
        "custom.triggeredBy",
        enMessages.custom.triggeredBy
      ),
      filterable: true,
      show: true,
      minWidth: 200,
      cell: AssignedToCell,
      headerCell: HeaderAssignedToCell,
    },
    {
      field: "created",
      title: localizationService.toLanguageString(
        "custom.created",
        enMessages.custom.created
      ),
      filterable: true,
      show: true,
      cell: CreatedCell,
      minWidth: 100,
    },
    {
      title: localizationService.toLanguageString(
        "custom.criticality",
        enMessages.custom.criticality
      ),
      field: "criticality",
      filterable: true,
      show: true,
      cell: CriticalityCell,
      minWidth: 100,
      headerCell: HeaderCriticalityCell,
    },
    {
      field: "type",
      title: `${localizationService.toLanguageString("custom.type",enMessages.custom.type)}`,
      cell: TypeCell,
      minWidth: 100,
      show: true,
      headerCell: HeaderTypeCell
    },
    {
      field: "result",
      title: localizationService.toLanguageString(
        "custom.result",
        enMessages.custom.result
      ),
      filterable: false,
      show: true,
      minWidth: 100,
    },
    {
      field: "isEmailSent",
      title: localizationService.toLanguageString(
        "custom.emailSent",
        enMessages.custom.emailSent
      ),
      filterable: true,
      show: true,
      cell: EmailSentCell,
      minWidth: 100,
    },
  ];

  const [stateColumns, stateColumnsRef, setStateColumns] = useRefState<any>(columns);

  useEffect(() => {
    grid.current = document.querySelector(".k-grid");
    window.addEventListener("resize", handleResize);
    columns.map((item) =>
      item.minWidth !== undefined
        ? (minGridWidth.current += item.minWidth)
        : minGridWidth.current
    );
    setGridCurrent(grid.current.offsetWidth);
    setApplyMinWidth(grid.current.offsetWidth < minGridWidth.current);
  }, []);

  const handleResize = () => {
    let columns = [...stateColumnsRef.current];
    setStateColumns(columns);

    if (grid.current.offsetWidth < minGridWidth.current && !applyMinWidth) {
      setApplyMinWidth(true);
    } else if (grid.current.offsetWidth > minGridWidth.current) {
      setGridCurrent(grid.current.offsetWidth);
      setApplyMinWidth(false);
    }
  };

  const setWidth = (minWidth: number | undefined) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    minWidth === undefined ? (minWidth = 0) : null;
    let width = applyMinWidth
      ? minWidth
      : minWidth + (gridCurrent - minGridWidth.current) / columns.length;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    width < COLUMN_MIN ? width : (width -= ADJUST_PADDING);
    return width;
  };

  const getExcelExportData = () => {
    return process(props.notifications, {
      filter: dataState.filter,
      sort: dataState.sort,
    }).data;
  };

  const [fieldsData, setFieldsData] = useState<string[]>(["name", "description", "assignedTo.name", "assignedTo.details", "criticality", "type"]);
  const [searchText, setSearchText] = useState<TextBoxProps["value"]>("");
  const [value, setValue] = useState<Item[]>(ColumnsOptions);

  useEffect(() => {
  
    if (searchText !== undefined && searchText !== null) {
      let filters: FilterDescriptor[] = [];

      fieldsData.map((field) => filters.push({field: field, operator: "contains", value: searchText}))

      let compositeFilter: CompositeFilterDescriptor = {
        logic: "or",
        filters: filters,
      };

      var newState = dataState; 
      newState.filter = compositeFilter;

      let updatedState = createDataState(newState);
      setResult(updatedState.result);
      setDataState(updatedState.dataState);
    }

   }, [fieldsData, searchText]);

  const handleFieldChange = (event: MultiSelectChangeEvent) => {
    const values = event.target.value;
        
    var selectedFields : string[] = []; 

    values.map((selectedItem) => {
      return selectedItem.fields?.map((field) => {
        return selectedFields.push(field);
      })
    })
      setFieldsData(selectedFields);
      setValue(values)
      
  };

  const handleSeachChange = React.useCallback((event) => setSearchText(event.target.value), []);

  const handleClear = React.useCallback(() => {
    setSearchText("");
  }, []);


  return (
    <div>
      <ExcelExport
        data={getExcelExportData()}
        ref={_export}
        collapsible={true}
        fileName={localizationService.toLanguageString(
          "custom.notifications",
          enMessages.custom.notifications
        )}
      >
        <ExcelExportColumn field="name" title={localizationService.toLanguageString("custom.alert", enMessages.custom.alert)}/>
        <ExcelExportColumn field="description" title={localizationService.toLanguageString("custom.description", enMessages.custom.description)} />
        <ExcelExportColumn field="assignedTo.name" title={localizationService.toLanguageString("custom.triggered", enMessages.custom.triggered)} />
        <ExcelExportColumn field="assignedTo.details" title={`${localizationService.toLanguageString("custom.location", enMessages.custom.location)} > ${localizationService.toLanguageString("custom.asset", enMessages.custom.asset)}`} />
        <ExcelExportColumn field="createdFormatted" title={localizationService.toLanguageString("custom.created", enMessages.custom.created)} />
        <ExcelExportColumn field="criticality" title={localizationService.toLanguageString("custom.criticality", enMessages.custom.criticality)} />
        <ExcelExportColumn field="type" title={localizationService.toLanguageString("custom.type", enMessages.custom.type)} />
        <ExcelExportColumn field="result" title={localizationService.toLanguageString("custom.result", enMessages.custom.result)} />
        <ExcelExportColumn field="isEmailSent" title={localizationService.toLanguageString("custom.emailSent", enMessages.custom.emailSent)} />
      </ExcelExport>

      <Grid
        key={"NotificationsTable"}
        id="notificationsTable"
        data={result}
        scrollable={"virtual"}
        take={30}
        rowHeight={50}
        onDataStateChange={dataStateChange}
        {...dataState}
        sortable={{
          mode: "multiple",
        }}
        style={{
          height: "690px",
        }}
      >
        <GridToolbar className="">
        <div className="w-full flex justify-between items-center">
            <div>
              <TextBox placeholder="Search..." onChange={handleSeachChange} style={{width:300, height: 38}}
              value={searchText} 
              suffix={() => (
                <React.Fragment>
                        {searchText !== "" && (
                          <InputClearValue onClick={handleClear}>
                            <Icon name="x" />
                          </InputClearValue>
                        )}
                        <InputSeparator />
                      </React.Fragment>
              )} />
              <MultiSelect
                data={ColumnsOptions}
                textField="text"
                onChange={handleFieldChange}
                value={value}
                size={"large"}
                style={{width:470, overflow:"auto", marginLeft: "5px"}}
              />
            </div>
            <Tooltip anchorElement="target" position="bottom">
              <Button
                title={localizationService.toLanguageString('custom.exportToExcel', enMessages.custom.exportToExcel)}
                onClick={excelExport}
                themeColor={"base"}
                icon="download"
                style={{ marginRight: "10px" }}
                
              />
            </Tooltip>
          </div>
        </GridToolbar>

        {stateColumns.map(
          (column, idx) =>
            column.show && (
              <GridColumn
                key={idx}
                field={column.field}
                title={column.title}
                filter={column.filter}
                cell={column.cell}
                width={setWidth(column.minWidth)}
                filterable={column.filterable}
                headerCell={column.headerCell}
                sortable={column.sortable}
              />
            )
        )}
      </Grid>
    </div>
  );
}
