import React, { ReactElement, useEffect, useState } from "react";
import EventReadTableDto from "../../../../Models/Event/EventReadTableDto";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../../messages/en-US";
import moment from "moment";
import { SortDescriptor, process, State } from "@progress/kendo-data-query";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { Grid, GridColumn as Column, GridToolbar} from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { Tooltip } from "@progress/kendo-react-tooltip";

interface Props {
  events: Array<EventReadTableDto> | any;
}

interface Page {
  skip: number;
  take: number;
}

const page: Page = { skip: 0, take: 30 };

export default function PointEventsAggregatedTable(props: Props): ReactElement {
  const localizationService = useLocalization();
  const initialSort: Array<SortDescriptor> = [{ field: "date", dir: "desc" },];
  const [sort, setSort] = React.useState(initialSort);
  const _export = React.useRef<ExcelExport | null>(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

     //Creating dataState to prevent issues with virtual scrolling
  const createAppState = (dataState: State) => {
    return {
      result: process(props.events, dataState),
      dataState: dataState,
    };
  };

  const [dataState, setDataState] = useState<any>(
    createAppState({
      skip: page.skip,
      take: page.take,
      sort: sort
    })
  );
  
  const dataStateChange = (event) => {
    setDataState(createAppState(event.dataState));
  };

   useEffect(() => {
    setDataState( createAppState({
      skip: page.skip,
      take: page.take,
      sort: sort
    }));
  }, [props]);

  //Formating Cells
  const OccurredCell = (props: any) => {
    return (
      <td>
        <div>{moment(props.dataItem.date).format('DD/MM/YYYY')}</div>
      </td>
      );
  }

  const SerialNumberCell = (props: any) => {
    return (
      <td>
        <div>{props.dataItem.serialNumber}</div>
      </td>
      );
  }

  const ScheduleNameCell = (props: any) => {
    return (
      <td>
        <div>{props.dataItem.scheduleName}</div>
      </td>
      );
  }

  const ConsumableCell = (props: any) => {
    return (
      <td>
        <div>{props.dataItem.consumableName}</div>
      </td>
      );
  }

  const RfidCell = (props: any) => {
    return (
      <td>
        <div>{props.dataItem.rfid}</div>
      </td>
      );
  }

  const VolumeDispensedCell = (props: any) => {
    return (
      <td>
        <div>{props.dataItem.volumeDispensedSumRound}</div>
      </td>
      );
  }

  const getExcelExportData = () => {
    return process(props.events, {filter: dataState.dataState.filter, sort: dataState.dataState.sort}).data;
  };
  
  return (
    <div>
      <ExcelExport data={getExcelExportData()} ref={_export} collapsible={true} fileName={`${localizationService.toLanguageString('custom.flowEvents', enMessages.custom.flowEvents)} - ${props.events[0]?.pointName}`}>
        <ExcelExportColumn field="date" title={`${localizationService.toLanguageString('custom.occurred', enMessages.custom.occurred)}`}  cellOptions={{ format: "dd/mm/yyyy hh:mm:ss" }} width={150}/>
        <ExcelExportColumn field="rfid" title={`${localizationService.toLanguageString('custom.rfid', enMessages.custom.rfid)}`}  />
        <ExcelExportColumn field="consumableName" title={`${localizationService.toLanguageString('custom.consumable', enMessages.custom.consumable)}`}  />
        <ExcelExportColumn field="volumeDispensedSum" title={`${localizationService.toLanguageString('custom.volumeDispensed', enMessages.custom.volumeDispensed)}`} cellOptions={{ format: "0.0000" }} />
      </ExcelExport>
      <Grid
         key={props.events.length.toString()}
         rowHeight={46}
         take={page.take}
         data={dataState.result}
         scrollable={"virtual"}
         filterable={true}
         onDataStateChange={dataStateChange}
         {...dataState.dataState}
         sortable={true}
         style={{
           height: `600px`,
         }}
        className=" w-full z-0 extra-filter-button "
      >
        <GridToolbar>  
        <div style={{width:'100%', display:'flex', justifyContent:'space-between'}}>
            <div className="gb-h3 pl-1">{localizationService.toLanguageString('custom.events', enMessages.custom.events)}</div>
              <Tooltip anchorElement="target" position="bottom">
                <Button
                  title={localizationService.toLanguageString('custom.exportToExcel', enMessages.custom.exportToExcel)}
                  icon="download"
                  disabled={dataState.result.data.length === 0}
                  onClick={excelExport}
                />
              </Tooltip>
          </div>
        </GridToolbar>
        <Column field="date" title={localizationService.toLanguageString('custom.occurred', enMessages.custom.occurred)} cell={OccurredCell} filterable={false}/>
        <Column field="rfid" title={localizationService.toLanguageString('custom.rfid', enMessages.custom.rfid)} cell={RfidCell} />
        <Column field="consumableName" title={localizationService.toLanguageString('custom.consumable', enMessages.custom.consumable)} cell={ConsumableCell} />
        <Column field="volumeDispensedSumRound" title={localizationService.toLanguageString('custom.volumeDispensed', enMessages.custom.volumeDispensed)} cell={VolumeDispensedCell} />
      </Grid>     
  </div>
  );
}