import React, { useRef, useEffect, useMemo, ReactElement } from "react";
import { ConsumableReadDto } from "../../../Models/Consumable/ConsumableReadDto";
import { ConsumableUpdateDto } from "../../../Models/Consumable/ConsumableUpdateDto";
import useKendoTableState from "../../Shared/Hooks/useKendoTableState";
import { ColumnMetaData } from "../../Shared/Interfaces/ColumnMetaData";
import GBKendoDataCell from "../../Shared/Components/Table/GBKendoDataCell";
import { CompositeFilterDescriptor, filterBy, orderBy } from "@progress/kendo-data-query";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Grid, GridFilterChangeEvent, GridPageChangeEvent, GridSortChangeEvent, GridToolbar, GridColumn as Column, GridRowProps } from "@progress/kendo-react-grid";
import { isRequiredBySchema } from "../../Shared/Utilities/YupValidationUtilities";
import ConsumableValidationSchema from "../Utilities/ConsumableValidationSchema";
import { Formik } from "formik";
import GBKendoToolbar from "../../Shared/Components/Table/GBKendoToolbar";
import { Checkbox } from "@progress/kendo-react-inputs";
import { CustomDateFilterCell } from "../../Shared/Components/Table/CustomDateFilterCell";
import { CustomTextFilterCell } from "../../Shared/Components/Table/CustomTextFilterCell";
import { Button } from "@progress/kendo-react-buttons";
import { ConsumableReadTableDto } from "../../../Models/Consumable/ConsumableReadTableDto";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";

interface Props {
  consumables: Array<ConsumableReadTableDto>;
  createConsumable: (values: any, setStatus: any) => void;
  deleteConsumable: (deleteIds: Array<string>) => void;
  updateConsumable: (consumables: Array<ConsumableUpdateDto>) => void;
  height: number;
}

const initialFilter: CompositeFilterDescriptor = {
  logic: "and",
  filters: [],
};

function ConsumablesTable(props: any) {
  const tableState = useKendoTableState<ConsumableReadTableDto>({
    key: "Consumables",
    initialAssets: props.consumables,
  });

  const localizationService = useLocalization();

  const stateRef = useRef(tableState);

  useEffect(() => {
    stateRef.current = tableState
   }, [tableState]);

  const [filter, setFilter] = React.useState(initialFilter);
  const [sort, setSort] = React.useState(props.initialSortDescriptor ?? props.initialSort);

  // Table columns
  const columns: Array<ColumnMetaData<ConsumableReadTableDto>> = React.useMemo(
      () =>
        [
          {
            header: `${localizationService.toLanguageString('custom.name', enMessages.custom.name)}`,
            displayField: "name",
            filterType: "text",
            Cell: (cellProps) => (
              <GBKendoDataCell<ConsumableReadDto>
                dataFieldName="name"
                cellProps={cellProps}
                fieldAccessor={(x) => x.name}
                isAddMode={cellProps.dataIndex < tableState.tempRows.length}
                isEditMode={tableState.canModifyRow}
                onCellChanged={tableState.onCellChanged}
              />
            ),
          },
          {
            header: `${localizationService.toLanguageString('custom.description', enMessages.custom.description)}`,
            displayField: "description",
            filterType: "text",
            Cell: (cellProps) => (
              <GBKendoDataCell<ConsumableReadDto>
                dataFieldName="description"
                cellProps={cellProps}
                fieldAccessor={(x) => x.description}
                isAddMode={cellProps.dataIndex < tableState.tempRows.length}
                isEditMode={tableState.canModifyRow}
                onCellChanged={tableState.onCellChanged}
              />
            ),
          },
          {
            header: `${localizationService.toLanguageString('custom.manufacturer', enMessages.custom.manufacturer)}`,
            displayField: "manufacturer",
            Cell: (cellProps) => (
              <GBKendoDataCell<ConsumableReadDto>
                inputType="autocomplete"
                dataFieldName="manufacturer"
                cellProps={cellProps}
                fieldAccessor={(x) => x.manufacturer}
                isAddMode={cellProps.dataIndex < tableState.tempRows.length}
                isEditMode={tableState.canModifyRow}
                onCellChanged={tableState.onCellChanged}
                assets={tableState.assets}
              />
            ),
          },
        ],
        [tableState.canModifyRow, tableState.tempRows.length, filter, sort]
      );

  const _export = React.useRef<ExcelExport | null>(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };
  const ref = useRef<any>();

  /**The following use effects are required to fix a race condition created by updating formik fields
   * Programatically. They ensure that validation is performed after the values update instead of before.
   **/
  useEffect(() => {
    ref.current.validateForm();
  }, [ref.current?.values]);
  useEffect(() => {
    ref.current.validateForm();
  }, [ref.current]);

  /**
   * Set the skip amount to zero if the number of temp rows (indicating an add row has been added).
   * This effectively ensures the add row is always scrolled into view
   */
  useEffect(() => {
    if (tableState.tempRows.length === 1) {
      setSkip(0);
    }
  }, [tableState.tempRows.length]);

  //The following state is required for dynamic scrolling
  const [skip, setSkip] = React.useState<number>(0);
  const pageChange = (event: GridPageChangeEvent) => {
    setSkip(event.page.skip);
  };

  const isColumnForRequiredField = (displayField: string | undefined): boolean => {
    return (
      isRequiredBySchema(displayField?.replace("Name", "Id"), ConsumableValidationSchema) &&
      (tableState.tempRows.length > 0 || tableState.canModifyRow)
    );
  };

  useEffect(() => {
    // Reset skip when filter or sort changes
    setSkip(0);
  }, [filter, sort]);

  //Data is API data + current temporary rows that have not yet been persisted using the API
  const processedData = useMemo(() => {
    
    let initialRows = tableState.assets;

    if (filter) {
      initialRows = filterBy(initialRows, filter);
    }

    if (sort) {
      initialRows = orderBy(initialRows, sort);
    }
    //Add any temp rows to beggining
    return tableState.tempRows.concat(initialRows);
  }, [tableState.assets, tableState.tempRows, filter, sort]);

  //Initial row added
  const initialisedRowData = {
    type: "Grease",
    name: "",
    cost: "",
    photoUrl : "",
    description : "",
    partNumber : "11",
    manufacturer : "",
    id: "",
  };

  return (
    <div>
      <Formik
        innerRef={ref}
        key={props.consumables.length}
        onSubmit={async (values, { setStatus, resetForm }) => {
          const res = await props.createConsumable(values, setStatus);
          if (res) {
            resetForm();
            tableState.setTempRows([]);
          }
        }}
        validationSchema={ConsumableValidationSchema}
        initialValues={initialisedRowData}
      >
        {({ submitForm, values, resetForm, isSubmitting }) => (
          <ExcelExport data={processedData} ref={_export} collapsible={true} fileName={localizationService.toLanguageString('custom.consumables', enMessages.custom.consumables)}>
            <Grid
              key={tableState.tempRows.length.toString()}
              rowHeight={46}
              pageSize={30}
              total={processedData.length}
              data={processedData}
              scrollable="virtual"
              skip={skip}
              onPageChange={pageChange}
              filterable={true}
              filter={filter}
              onFilterChange={(e: GridFilterChangeEvent) => setFilter(e.filter)}
              sortable={true}
              sort={sort}
              onSortChange={(e: GridSortChangeEvent) => {
                setSort(e.sort);
              }}
              style={{
                height: props.height,
              }}
              className="w-full  z-0"
              //Custom render row,
              rowRender={(trElement: ReactElement, props: GridRowProps) => {
                let baseStyle = { ...trElement.props.style };
                if (tableState.modifiedRowIds.includes(props.dataItem.id)) {
                  baseStyle.backgroundColor = "rgba(255, 252, 88, 0.25)";
                }
                if (tableState.selectedRowsIds.includes(props.dataItem.id)) {
                  baseStyle.backgroundColor = "rgba(255, 99, 88, 0.25)";
                }

                return React.cloneElement(
                  trElement,
                  {
                    ...trElement,
                    style: { ...baseStyle },
                  },
                  trElement.props.children
                );
              }}
            >
              <GridToolbar>  
                <GBKendoToolbar
                  name={localizationService.toLanguageString('custom.consumables', enMessages.custom.consumables)}
                  tableState={tableState}
                  exportAction={excelExport}
                  importAction={null}
                  addAction={
                    props.createConsumable
                      ? () => {
                        if (tableState.tempRows.length === 1) {
                          tableState.setTempRows([]);
                          return;
                        }
                        tableState.setTempRows([...tableState.tempRows, initialisedRowData]);
                        }
                      : null
                  }
                  updateAction={
                    props.updateConsumable
                      ? async () => {
                          let updatedAssets = tableState.assets.filter((x) => tableState.modifiedRowIds.includes(x.id));
                          await props.updateConsumable(updatedAssets);
                        }
                      : null
                  }
                  deleteAction={
                    props.deleteConsumable ? async () => await props.deleteConsumable(tableState.selectedRowsIds) : null
                  }
                  discardChanges={() => tableState.resetState()}
                />
              </GridToolbar>

              {/* Selection - only if in edit mode*/}
              {tableState.canModifyRow && (
                <Column  
                  key={"Selection"}
                  cell={(props) => (
                    <td>
                        <Checkbox
                          onChange={() => {
                            if (tableState.selectedRowsIds.includes(props.dataItem.id)) {
                              tableState.setSelectedRowIds(
                                tableState.selectedRowsIds.filter((id) => id !== props.dataItem?.id)
                              );
                            } else {
                              tableState.setSelectedRowIds([...tableState.selectedRowsIds, props.dataItem?.id]);
                            }
                          }}
                          checked={tableState.selectedRowsIds.includes(props.dataItem.id)}
                        />
                    </td>
                  )}
                  width="50px"
                  filterable={false}
                  sortable={false} />)}

               {/* From Column Array */}
               {columns.map((column, index) => {
                return (
                  //Create wrapper/HOC with editable cell and add cell definitions,
                  <Column
                    width="auto"
                    key={index}
                    cell={column.Cell}
                    // Show asterix in header if required TODO: pretty hacky - assumes that the name of the data field is the same as the display field with Name replaced.
                    title={`${column.header}${isColumnForRequiredField(column.displayField) ? "*" : ""}`}
                    field={column.displayField}
                    filter={column.filterType}
                    filterCell={column.filterType === "date" ? CustomDateFilterCell : CustomTextFilterCell}
                  />
                );
              })}


              {/* Inline Action options (If ActionDefinitions are provided) */}
              {props.extraInlineActions != null && props.extraInlineActions.length > 0 && (
                <Column
                  title={localizationService.toLanguageString('custom.actions', enMessages.custom.actions)}
                  sortable={false}
                  filterable={false}
                  cell={(cellProps) => {
                    return (
                      <td className="flex flex-wrap justify-around gap-1">
                        {tableState.tempRows.length <= cellProps.dataIndex &&
                          props.extraInlineActions.map((action: any) => {
                            return (
                              <Button
                                className=""
                                themeColor={action.themeColor}
                                onClick={() => action.onClick(cellProps.dataItem)}
                              >
                                {action.text}
                              </Button>
                            );
                          })}
                      </td>
                    );
                  }}
                />
              )}

              {/* Command Cell (For add rows only)*/}
              {tableState.tempRows.length > 0 && (
                <Column
                  width="150px"
                  sortable={false}
                  filterable={false}
                  key={"Commands"}
                  cell={(props) => {
                    // Add Button for new assets
                    return (
                      <td>
                        {props.dataIndex === 0 && (
                          <div className="flex space-x-1">
                            <Button onClick={submitForm} disabled={isSubmitting} themeColor={"primary"}>
                            {localizationService.toLanguageString('custom.add', enMessages.custom.add)}
                            </Button>
                            <Button
                              onClick={() => {
                                tableState.setTempRows([]);
                                resetForm();
                              }}
                              themeColor={"base"}
                            >
                              {localizationService.toLanguageString('custom.exit', enMessages.custom.exit)}
                            </Button>
                          </div>
                        )}
                      </td>
                    );
                  }}
                />
              )}
            </Grid>
          </ExcelExport>
          )}
      </Formik>
    </div>
  );
};

export default ConsumablesTable;