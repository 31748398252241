import React, { ReactElement, useState } from "react";
import { EventFilter } from "../Utilities/EventsApiService";
import { Calendar, CalendarProps, DatePicker } from "@progress/kendo-react-dateinputs";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";
import moment from "moment";
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from "@progress/kendo-react-layout";
import PointsEventsPage from "./PointsEventsPage";
import EndpointsEventsPage from "./EndpointsEventsPage";
import { GetEndpointIcon, GetPointIcon } from "../../../Utilities/CustomIcons";
import { SvgIcon } from '@progress/kendo-react-common';
import * as svgIcons from '@progress/kendo-svg-icons';
import FlowRateEventsPage from "./FlowRateEventsPage";
import GpsEventsPage from "./GpsEventsPage";
import TemperatureEventsPage from "./TemperatureEventsPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTemperatureHalf } from "@fortawesome/free-solid-svg-icons";
import AccountSettingsReadDto from "../../../Models/Dashboard/AccountSettingsReadDto";
import { ReactQueryKeys } from "../../../Constants/ClientRoutingConstants";
import dashboardApiService from "../../Dashboard/Utilities/DashboardApiService";
import { useQuery } from "react-query";
import LoadingSpinner from "../../Shared/Components/LoadingSpinner";
import ErrorCard from "../../Shared/Components/Cards/ErrorCard";

// Disable Fast Navigational Bar until this critical bug is fixed by Telerik (https://github.com/telerik/kendo-react/issues/1566)
const CustomCalendar = (props: CalendarProps) => {
  return <Calendar {...props} navigation={false} />
};

export default function EventsPage(): ReactElement {

  const localizationService = useLocalization();

  const accountSettingsQuery = useQuery<AccountSettingsReadDto>([ReactQueryKeys.SoftwareFeaturesQuery], async () => {
    return dashboardApiService.getAccountSettings();
  });

  // Use lazy initial state to ensure the function is only executed once on the initial render
  const [eventFilter, setEventFilter] = useState<EventFilter>(() => {
    let initalState: EventFilter = {
    endDate: new Date(), //From now
    startDate: moment().subtract(30,"days").toDate()};
    return initalState;
  });

  //TabStrip
  const [selected, setSelected] = React.useState<number>(0);

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected);
  };

  const PointsTitle = () => { return(
    <div className="flex gap-2 items-center">
      {GetPointIcon(15,15)}
      <div>{localizationService.toLanguageString('custom.points', enMessages.custom.points)}</div>
    </div>)}
  
  const EndpointsTitle = () => { return(
    <div className="flex gap-2 items-center">
      {GetEndpointIcon(19,19)}
      <div>{localizationService.toLanguageString('custom.endpoints', enMessages.custom.endpoints)}</div>
    </div>)}

const FlowRateTitle = () => { return(
  <div className="flex gap-1 items-center">
    <SvgIcon icon={svgIcons.dropletIcon} style={{width: "20px"}} />
    <div>{localizationService.toLanguageString('custom.flowRate', enMessages.custom.flowRate)}</div>
  </div>)}

const GpsTitle = () => { return(
  <div className="flex gap-1 items-center">
    <SvgIcon icon={svgIcons.mapMarkerIcon} style={{width: "20px"}} />
    <div>{localizationService.toLanguageString('custom.gps', enMessages.custom.gps)}</div>
  </div>)}

const TemperatureTitle = () => { return(
  <div className="flex gap-1 items-center">
    <FontAwesomeIcon icon={faTemperatureHalf} size={"xl"}/>
    <div>{localizationService.toLanguageString('custom.temperature', enMessages.custom.temperature)}</div>
  </div>)}

  if(accountSettingsQuery.isLoading || accountSettingsQuery.isFetching){
    return <LoadingSpinner />
  } else if(accountSettingsQuery.isError){
    return <ErrorCard />
  }
  return (
    <div className="m-5">
      {/* Header */}
      <h2 className="gb-h2">{localizationService.toLanguageString('custom.events', enMessages.custom.events)}</h2>
      <div className="gb-text-normal">{localizationService.toLanguageString('custom.eventsText', enMessages.custom.eventsText)}</div>

      {/* Date Picker for returned range */}
      <div className="flex flex-wrap my-5">
        <div>
          <div className="gb-text-normal font-bold">{localizationService.toLanguageString('custom.showEventsFrom', enMessages.custom.showEventsFrom)}</div>
          <DatePicker calendar={CustomCalendar} max={eventFilter.endDate!}
            onChange={(date) => {
              setEventFilter((prev) => {
                return { ...prev, startDate: date.target.value };
              });
            }}
            value={eventFilter.startDate}
            className="w-72"
          />
        </div>
        <div className="ml-4">
          <div className="gb-text-normal font-bold">{localizationService.toLanguageString('custom.until', enMessages.custom.until)}</div>
          <DatePicker calendar={CustomCalendar} min={eventFilter.startDate!}
            onChange={(date) => {
              setEventFilter((prev) => {
                return { ...prev, endDate: date.target.value };
              });
            }}
            value={eventFilter.endDate}
            className="w-72"
          />
        </div>
      </div>

      {/* Events Table */}
      <div className="mt-4" >
        <TabStrip selected={selected} onSelect={handleSelect}>
          {accountSettingsQuery.data?.softwareFeatures.isManualSystemEnabled &&
            <TabStripTab title={PointsTitle()}>
              <PointsEventsPage eventFilter={eventFilter} />
            </TabStripTab>
          }
          {accountSettingsQuery.data?.softwareFeatures.isAutomaticSystemEnabled &&
            <TabStripTab title={EndpointsTitle()}>
              <EndpointsEventsPage eventFilter={eventFilter} />
            </TabStripTab>
          }
          {accountSettingsQuery.data?.softwareFeatures.isFlowRateSystemEnabled &&
            <TabStripTab title={FlowRateTitle()}>
              <FlowRateEventsPage eventFilter={eventFilter} />
            </TabStripTab>
          }
          {accountSettingsQuery.data?.softwareFeatures.isFlowRateSystemEnabled &&
            <TabStripTab title={GpsTitle()}>
              <GpsEventsPage eventFilter={eventFilter} />
            </TabStripTab>
          }
           {accountSettingsQuery.data?.softwareFeatures.isFlowRateSystemEnabled &&
            <TabStripTab title={TemperatureTitle()}>
              <TemperatureEventsPage eventFilter={eventFilter} />
            </TabStripTab>
          }
        </TabStrip>
        
        </div>
      </div>
  );
}
