import create from "zustand";
import zukeeper from 'zukeeper';
import moment from "moment";


interface SoftwareFeatures {
  isManualSystemEnabled: boolean;
  isAutomaticSystemEnabled: boolean;
  isFlowRateSystemEnabled: boolean;
  }

interface IAccountSettingsStore {
  accountTimezone: string;
  softwareFeatures: SoftwareFeatures;
  setAccountTimeZone: (timezone: string) => void;
  setSoftwareFeatures: (softwareFeatures: SoftwareFeatures) => void;
}

/**
 * This store allows us to access the current account settings anywhere in the application.
 */
export const useAccountSettingsStore = create<IAccountSettingsStore>(zukeeper((set) => ({
  accountTimezone: moment.tz?.guess(),
  setAccountTimeZone: (timezone: string) => {
    set(() => {
      return {
        accountTimezone: timezone,
      };
    });
  },
  softwareFeatures: {
    isManualSystemEnabled: true,
    isAutomaticSystemEnabled: true,
    isFlowRateSystemEnabled: true,
  },
  setSoftwareFeatures: (softwareFeatures: SoftwareFeatures) => {
    set(() => {
      return {
        softwareFeatures: softwareFeatures,
      };
    });
  },
})));