import { ApiRoutes } from "../../../Constants/ApiRoutingConstants";
import axios, { AxiosInstance } from "axios";
import authorizeService from "../../Identity/Utilities/AuthorizeService";
import { ReservoirStatusDto } from "../../../Models/Reservoirs/ReservoirStatusDto";

/**
 * This service provides implementations for http calls to the api pertaining to schedule crud functionality
 */

class AnalysisApiService {
  axiosInstance: AxiosInstance;
  constructor() {
    //Subscribe to identity store the get token
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_ANALYSIS_SERVICE_URL,
    });
  }

  async getReservoirStatus(reservoirId: string): Promise<ReservoirStatusDto> {
    let response = await this.axiosInstance({
      method: "GET",
      url: `${ApiRoutes.GreaseReservoirsAnalysis}/${reservoirId}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data;
  }

  static get instance() {
    return AnalysisApiService;
  }
}

const analysisApiService = new AnalysisApiService();
export default analysisApiService;
