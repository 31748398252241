import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../../messages/en-US";
import moment from "moment";
import { SortDescriptor, process, State } from "@progress/kendo-data-query";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { Grid, GridColumn as Column, GridToolbar} from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { CustomTextFilterCell } from "../../../Shared/Components/Table/CustomTextFilterCell";
import { Tooltip } from "@progress/kendo-react-tooltip";

interface Props {
  events: any;
  aggregator: string;
  endpointSerialNo?: string;
}

interface Page {
  skip: number;
  take: number;
}

const page: Page = { skip: 0, take: 30 };

export default function EndpointMetricsTable(props: Props): ReactElement {
  const localizationService = useLocalization();
  const aggregator = props.aggregator;
  const initialSort: Array<SortDescriptor> = [{ field: "timestamp", dir: "desc" }];
  const [sort, setSort] = React.useState(initialSort);
  const _export = React.useRef<ExcelExport | null>(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  //Creating dataState to prevent issues with virtual scrolling
  const createAppState = (dataState: State) => {
    return {
      result: process(props.events, dataState),
      dataState: dataState,
    };
  };

  const [dataState, setDataState] = useState<any>(
    createAppState({
      skip: page.skip,
      take: page.take,
      sort: sort
    })
  );
  
  const dataStateChange = (event) => {
    setDataState(createAppState(event.dataState));
  };

   useEffect(() => {
    setDataState( createAppState({
      skip: page.skip,
      take: page.take,
      sort: sort
    }));
  }, [props]);

  //Formating Cells
  const OccurredCell = (props: any) => {
    return (
      <td>
        <div>{moment(props.dataItem.timestamp).format('DD/MM/YYYY @ HH:mm:ss')}</div>
      </td>
      );
  }

  const UploadedCell = (props: any) => {
    return (
      <td>
        <div>{moment(props.dataItem.synced).format('DD/MM/YYYY @ HH:mm:ss')}</div>
      </td>
      );
  }

  const exportData = useMemo(() => {
    return props.events.map((item) => ({
      ...item,
      timestampExport: moment(item.timestamp).format('DD/MM/YYYY HH:mm:ss'),
      syncedExport: moment(item.synced).format('DD/MM/YYYY HH:mm:ss'),
      
    }));
  }, [props.events]);

  const getExcelExportData = () => {
    return process(exportData, {filter: dataState.dataState.filter, sort: dataState.dataState.sort}).data;
  };
  
  return (
    <div>
      <ExcelExport data={getExcelExportData()} ref={_export} collapsible={true} fileName={`Heartbeat Events - ${props.endpointSerialNo !== undefined && props.endpointSerialNo}.xlsx`}>
        <ExcelExportColumn field="timestampExport" title={`${localizationService.toLanguageString('custom.occurred', enMessages.custom.occurred)}`} width={150}/>
        {props.aggregator=== 'none' && (
        <ExcelExportColumn field="syncedExport" title={`${localizationService.toLanguageString('custom.uploaded', enMessages.custom.uploaded)}`} width={150}/>
        )}
        <ExcelExportColumn field="rssi" title={`${localizationService.toLanguageString('custom.rssiDbm', enMessages.custom.rssiDbm)}`}/>
        <ExcelExportColumn field="endpointRssi" title={`${localizationService.toLanguageString('custom.endpointRssi', enMessages.custom.endpointRssi)}`}/>
        <ExcelExportColumn field="cumulative" title={`${localizationService.toLanguageString('custom.cumulative', enMessages.custom.cumulative)}`}/>
      </ExcelExport>
      <Grid
        key={props.events.length.toString()}
        rowHeight={46}
        take={page.take}
        data={dataState.result}
        scrollable={"virtual"}
        filterable={true}
        onDataStateChange={dataStateChange}
        {...dataState.dataState}
        sortable={true}
        style={{
          height: `600px`,
        }}
        className=" w-full z-0 extra-filter-button "
      >
        <GridToolbar>  
        <div style={{width:'100%', display:'flex', justifyContent:'space-between'}}>
            <div className="gb-h3 pl-1">{localizationService.toLanguageString('custom.events', enMessages.custom.events)}</div>
              <Tooltip anchorElement="target" position="bottom">
                <Button
                  title={localizationService.toLanguageString('custom.exportToExcel', enMessages.custom.exportToExcel)}
                  icon="download"
                  disabled={dataState.result.data.length === 0}
                  onClick={excelExport}
                />
              </Tooltip>
          </div>
        </GridToolbar>
        <Column field="timestamp" title={`${localizationService.toLanguageString('custom.occurred', enMessages.custom.occurred)}`} cell={OccurredCell} filterable={false}/>
        {props.aggregator=== 'none' && (
           <Column field="synced" title={`${localizationService.toLanguageString('custom.uploaded', enMessages.custom.uploaded)}`}  cell={UploadedCell} filterable={false}/>
        )}
        <Column field="rssi" title={`${localizationService.toLanguageString('custom.rssiDbm', enMessages.custom.rssiDbm)}`} filterCell={CustomTextFilterCell}/>
        <Column field="endpointRssi" title={`${localizationService.toLanguageString('custom.endpointRssi', enMessages.custom.endpointRssi)}`} filterCell={CustomTextFilterCell}/>
        <Column field="cumulative" title={`${localizationService.toLanguageString('custom.cumulative', enMessages.custom.cumulative)}`} filterCell={CustomTextFilterCell}/>
      </Grid>     
  </div>
  );
}