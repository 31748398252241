import { useToast } from "@chakra-ui/react";
import { Button } from "@progress/kendo-react-buttons";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";
import { ListView, ListViewItemProps } from "@progress/kendo-react-listview";
import React from "react";
import { Popover, PopoverActionsBar, Tooltip } from "@progress/kendo-react-tooltip";
import ReservoirResetHistory from "../Components/ReservoirResetHistory";
import ReservoirLinked from "../Components/ReservoirLinked";
import ReservoirResetModal from "../Components/ReservoirResetModal";
import moment from "moment";
import { ReservoirStatusDto } from "../../../Models/Reservoirs/ReservoirStatusDto";
import { ReservoirReadDto } from "../../../Models/Reservoirs/ReservoirReadDto";
import { ReservoirResetDto } from "../../../Models/Reservoirs/ReservoirResetDto";


interface Props {
  reservoir: any;
  reservoirStatus: any;
  onClose?: () => void | null;
  onReset: (reservoirResetDto: ReservoirResetDto) => void;
  viewReservoir: boolean;
}

interface Combine {
  res : ReservoirReadDto;
  status : ReservoirStatusDto | undefined;
}

const TooltipContentTemplate = (props: any) => {
  return (
    <div>{props.title}</div>
  );
};

export default function ReservoirViewPage(props: Props) {
  const toast = useToast({
    position: "bottom",
    duration: 5000,
    isClosable: true,
  });

  const tooltip = React.useRef<Tooltip>(null);

  const localizationService = useLocalization();
  const [resetReservoir, setResetReservoir] = React.useState<boolean>(false);

  const c : Combine = {res: props.reservoir, status : props.reservoirStatus};
  
  const closeHandler = () => {
    setResetReservoir(false);
  };

  const onReset = async (reservoirResetDto: ReservoirResetDto) => {
    setResetReservoir(false);
    props.onReset(reservoirResetDto);
  };

  const EstimatedVolume = (reservoirStatus: ReservoirStatusDto) => {

    if (reservoirStatus !== undefined)
    {
      return (
        <div style={{ fontSize: 20, color: "#a0a0a0" }}>
          {reservoirStatus.estimatedVolume.toFixed(2)} {reservoirStatus.displayUnit} ({(reservoirStatus.estimatedVolumeAsPercentage * 100).toFixed(0)} %)
        </div>
      );
    }

    return <div style={{ fontSize: 20, color: "#a0a0a0" }}>-</div>;
  }

  const AverageDailyConsumption = (reservoirStatus: ReservoirStatusDto) => {
    
    if (reservoirStatus !== undefined)
    {
      if(isNaN(reservoirStatus.averageDailyConsumption) === false)
      {
        return (
          <div style={{ fontSize: 20, color: "#a0a0a0" }}>
            {reservoirStatus.averageDailyConsumption.toFixed(2)} {reservoirStatus.displayUnit}
          </div>
        );
      }
    }
  
    return <div style={{ fontSize: 20, color: "#a0a0a0" }}>-</div>;
  };

  const ProjectedDepletionDate = (reservoirStatus: ReservoirStatusDto) => {

    if (reservoirStatus !== undefined)
    {
      var projectedDate = moment(reservoirStatus.estimatedEmptyDate);

      if (projectedDate.year() !== 1970)
      {
        const differenceInYears = projectedDate.diff(moment(), "years", false);

        // Display projected date differently if it exceeds a number of years
        if (differenceInYears > 5)
        {
          return <div style={{ fontSize: 20, color: "#a0a0a0" }}>5 years+</div>
        }

        return <div style={{ fontSize: 20, color: "#a0a0a0" }}>{projectedDate.format('DD/MM/YYYY')} ({projectedDate.fromNow()})</div>
      }
    }

    return <div style={{ fontSize: 20, color: "#a0a0a0" }}>{localizationService.toLanguageString('custom.noDataDepletion', enMessages.custom.noDataDepletion)}</div>;
  };


  const MyItemRender = (props: ListViewItemProps) => {
    let item = props.dataItem;
    const anchor = React.useRef<Button>(null);
    const [resetHistory, setResetHistory] = React.useState<boolean>(false);
    const [linked, setLinked] = React.useState<boolean>(false);
    const anchor1 = React.useRef<Button>(null);

    var lastResetDate = moment(new Date(item.res.lastResetDate)).format('DD/MM/YYYY');
    var resetSinceNow = moment(new Date(item.res.lastResetDate)).fromNow();
    const averageDailyConsumptionTooltip = `${localizationService.toLanguageString('custom.averageDailyConsumptionTooltip', enMessages.custom.averageDailyConsumptionTooltip)} ${item.res.depletionMovingAverageConfig.value} ${localizationService.toLanguageString('custom.daysOfActivity', enMessages.custom.daysOfActivity)}`;

    return (
      <div
        className="k-listview-item row p-2 border-bottom align-middle"
        style={{ margin: 0 }}
      >
        <div className="py-2">
          <div className="text-uppercase">{localizationService.toLanguageString('custom.name', enMessages.custom.name)}</div>
          <div style={{ fontSize: 20, color: "#a0a0a0" }}>{item.res.name}</div>
        </div>
        <div className="py-2">
          <div className="text-uppercase">{localizationService.toLanguageString('custom.capacity', enMessages.custom.capacity)}</div>
          <div style={{ fontSize: 20, color: "#a0a0a0" }}>{item.res.capacity} {item.res.displayUnit}</div>
        </div>
        <div className="py-2">
          <div className="text-uppercase">{localizationService.toLanguageString('custom.consumable', enMessages.custom.consumable)}</div>
          <div style={{ fontSize: 20, color: "#a0a0a0" }}>{item.res.consumableName}</div>
        </div>
        <div className="py-2">
          <div className="text-uppercase">{localizationService.toLanguageString('custom.estimatedVolume', enMessages.custom.estimatedVolume)}</div>{EstimatedVolume(item.status)}</div>
        <div className="py-2">
          <div className="flex text-uppercase" style={{alignItems:"center"}}>
            <div>{localizationService.toLanguageString('custom.averageDailyConsumption', enMessages.custom.averageDailyConsumption)}</div>
            <Tooltip ref={tooltip} anchorElement="target" position="right" openDelay={2} content={(props) => <TooltipContentTemplate title={props.title} />}>
            <Button icon="info" fillMode={"flat"} title={averageDailyConsumptionTooltip}
              onMouseOver={(event) => tooltip.current && tooltip.current.handleMouseOver(event)}
              onMouseOut={(event) => tooltip.current && tooltip.current.handleMouseOut(event)}
            />
           </Tooltip>
          </div>
          <div>{AverageDailyConsumption(item.status)}</div>
        </div>
        <div className="py-2">
          <div className="text-uppercase">{localizationService.toLanguageString('custom.projectedDepletionDate', enMessages.custom.projectedDepletionDate)}</div>{ProjectedDepletionDate(item.status)}</div>
        <div className="py-2">
          <div className="text-uppercase">{localizationService.toLanguageString('custom.lastReset', enMessages.custom.lastReset)}</div>
          <div className="flex gap-2" >
            <div style={{ fontSize: 20, color: "#a0a0a0" }}>{lastResetDate} ({resetSinceNow})</div>
            <Button onClick={() => setResetReservoir(true)}>{localizationService.toLanguageString('custom.reset', enMessages.custom.reset)}</Button>
            <Button onClick={() => setResetHistory(!resetHistory)} ref={anchor}><span className="k-icon k-i-clock"/><span className="k-icon k-i-arrow-60-down"/></Button>
                <Popover
                  show={resetHistory}
                  anchor={anchor.current && anchor.current.element}
                  position={"top"}
                  collision={{vertical: "flip", horizontal:"flip"}}
                >
                  <ReservoirResetHistory reservoirId={item.res.id} reservoirDisplayUnit={item.res.displayUnit}/>
                  <PopoverActionsBar>
                    <div className="grid justify-items-end">
                      <Button onClick={() => setResetHistory(false)}>{localizationService.toLanguageString('custom.close', enMessages.custom.close)}</Button>
                    </div>
                  </PopoverActionsBar>
                </Popover>
          </div>
        </div>
        <div className="py-2">
          <div className="text-uppercase">{localizationService.toLanguageString('custom.linkedPoints', enMessages.custom.linkedPoints)}</div>
          <div className="flex gap-2">
            <div style={{ fontSize: 20, color: "#a0a0a0" }}>{item.res.linkedPointCount}</div>
            <Button onClick={() => setLinked(!linked)} ref={anchor1}><span className="k-icon k-i-list-bulleted"/><span className="k-icon k-i-arrow-60-down"/></Button>
                <Popover
                  show={linked}
                  anchor={anchor1.current && anchor1.current.element}
                  position={"top"}
                  collision={{vertical: "flip", horizontal:"flip"}}
                >
                  <ReservoirLinked reservoirId={item.res.id}/>
                  <PopoverActionsBar>
                    <div className="grid justify-items-end">
                      <Button onClick={() => setLinked(false)}>{localizationService.toLanguageString('custom.close', enMessages.custom.close)}</Button>
                    </div>
                  </PopoverActionsBar>
                </Popover>
           
          </div>
        </div>
      </div>
    );
  };
  
  return (
    <>
    {props.viewReservoir && (
      <div style={{ height: "100%",width: "100%"  }}>
            <ListView
              data={[c]}
              item={MyItemRender}
              style={{ width: "100%" , height:'100%'}}
            />
            {resetReservoir && (
          <ReservoirResetModal onClose={closeHandler} onReset={onReset} reservoir={props.reservoir} resetReservoir={resetReservoir}/>
        )}
      </div> 
    )}
    </>
   
  );
}
