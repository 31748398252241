import { useLocalization } from "@progress/kendo-react-intl";
import { enMessages } from "../../../messages/en-US";
import { Button } from "@progress/kendo-react-buttons";
import { ClientRoutes, ReactQueryKeys } from "../../../Constants/ClientRoutingConstants";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import dashboardApiService from "../Utilities/DashboardApiService";
import TagsMetricsReadDto from "../../../Models/Dashboard/TagsMetricsReadDto";
import { useMemo } from "react";
import NoDataAvailablePage from "../Utilities/NoDataAvailablePage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { Loader } from "@progress/kendo-react-indicators";

export default function GreaseTagsTile( ) {
  const localizationService = useLocalization();
  let navigate = useNavigate(); 

  const redColour = "#ff5034";
  const greenColour = "#1ab394";
  const greyColour = "rgba(211,211,211,0.2)";

  const tagsMetricsQuery = useQuery<TagsMetricsReadDto>([ReactQueryKeys.DashboardTagsMetricsQuery], async () => {
    return dashboardApiService.getTagsMetrics();
  });

  const tagsMetrics = useMemo(() => {
    if(tagsMetricsQuery.data !== undefined) {
      return {
        pointCount: tagsMetricsQuery.data.pointCount,
        pointCountWithoutRfid: tagsMetricsQuery.data.pointCountWithoutRfid,
        pointCountWithoutSchedule: tagsMetricsQuery.data.pointCountWithoutSchedule,
        textColourWithoutRfid: "black",
        textColourWithoutSchedule: "black",
        backgroundColourWithoutRfid: tagsMetricsQuery.data.pointCountWithoutRfid > 0 ? redColour : greenColour,
        backgroundColourWithoutSchedule: tagsMetricsQuery.data.pointCountWithoutSchedule > 0 ? redColour : greenColour
      }
    } else {
      return {}
    }
  }, [tagsMetricsQuery.data]);

  const getIcon = (backgroundColour: string | undefined) => {
    if(backgroundColour === redColour) {
      return <FontAwesomeIcon icon={faExclamationCircle} style={{color: backgroundColour, fontSize: "30px"}} />
    } else {
      return <FontAwesomeIcon icon={faCheckCircle} style={{color: backgroundColour, fontSize: "30px"}} />
    }
  }

  if(tagsMetricsQuery.isLoading || tagsMetricsQuery.isFetching) {
    return <Loader size="medium" type={"pulsing"} className="loading-dashboard"/>
  } else if (tagsMetricsQuery.isError || tagsMetrics.pointCount === -1) {
    return <NoDataAvailablePage title={localizationService.toLanguageString('custom.greaseTags', enMessages.custom.greaseTags)} period={null} clientRoute={`${ClientRoutes.SiteSetupPage}#greaseTags`} />
  } else {
  return (
    <div className="m-2">
      <div className="flex justify-between align-middle">
        <div className="text-xl font-bold">{localizationService.toLanguageString('custom.greaseTags', enMessages.custom.greaseTags)}</div>
        <Button fillMode={"link"} style={{color:"blue", textDecoration: "underline" }} onClick={() => navigate(`${ClientRoutes.SiteSetupPage}#greaseTags`)}>{localizationService.toLanguageString('custom.seeAll', enMessages.custom.seeAll)}</Button>
      </div>
        <div>
          <div className="mt-2">
            <div className="text-5xl">{tagsMetrics.pointCount}</div>
            <div className="ml-1 lowercase">{localizationService.toLanguageString('custom.tags', enMessages.custom.tags)}</div>
          </div>
          <div className="rounded m-auto mt-5 px-2 py-3" style={{color: tagsMetrics.textColourWithoutRfid, background: greyColour}}>
            <div className="" >{localizationService.toLanguageString('custom.unmapped', enMessages.custom.unmapped)}</div>
            <div className="flex justify-between items-center">
              <div className=" text-3xl">{tagsMetrics.pointCountWithoutRfid === 0 ? "-" : tagsMetrics.pointCountWithoutRfid}</div>
              {getIcon(tagsMetrics.backgroundColourWithoutRfid)}   
            </div>  
          </div>
          <div className="rounded m-auto mt-2 px-2 py-3" style={{color: tagsMetrics.textColourWithoutSchedule, background: greyColour}}>
            <div className="">{localizationService.toLanguageString('custom.withoutSchedule', enMessages.custom.withoutSchedule)}</div>
            <div className="flex gap-3 justify-between  items-center">
              <div className=" text-3xl">{tagsMetrics.pointCountWithoutSchedule === 0 ? "-" : tagsMetrics.pointCountWithoutSchedule}</div>
              {getIcon(tagsMetrics.backgroundColourWithoutSchedule)}   
            </div>
          </div> 
        </div>
      </div>
    );
}}