import { ApiRoutes } from "../../../Constants/ApiRoutingConstants";
import axios, { AxiosInstance } from "axios";
import authorizeService from "../../Identity/Utilities/AuthorizeService";
import ActionReadDto from "../../../Models/Action/ActionReadDto";
import EventPerActionReadDto from "../../../Models/Action/EventPerActionReadDto";
import { useInternationalization } from "@progress/kendo-react-intl";
import moment from "moment";
/**
 * This service provides implementations for http calls to the api pertaining to event crud functionality
 */

export interface ActionFilter {
  startDate?: Date | null;
  endDate?: Date | null;
}


class ActionsApiService {
  axiosInstance: AxiosInstance;
  constructor() {
    //Subscribe to identity store the get token
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_EVENT_SERVICE_URL,
    });
  }

  async getAllActions(actionFilters: ActionFilter) {
    //Covnert EventFilters to use Iso date strings
    const isoActionFilters = {...actionFilters, 
      startDate : actionFilters.startDate?.toISOString(),
      endDate : actionFilters.endDate?.toISOString()}

    const response = await this.axiosInstance({
      method: "GET",
      //@ts-ignore
      url: `${ApiRoutes.Actions}?${new URLSearchParams(isoActionFilters).toString()}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    })
    
    let actionsData = response.data as Array<ActionReadDto>
    actionsData.forEach(item => {
      item.dueDate = new Date(item.dueDate)
      item.expiryDate = new Date(item.expiryDate)
    })

    return actionsData;
  }

  async getEventsPerAction(actionId: string) {
    const response = await this.axiosInstance({
      method: "GET",
      //@ts-ignore
      url: `${ApiRoutes.EventsPerAction}?actionId=${actionId}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data as Array<EventPerActionReadDto>;
  }
}

const actionsApiService = new ActionsApiService();
export default actionsApiService;
