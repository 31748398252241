import { ReactElement } from "react";
import { useQuery, useQueryClient } from "react-query";
import { ReactQueryKeys } from "../../../Constants/ClientRoutingConstants";
import { useToast } from "@chakra-ui/react";
import ErrorCard from "../../Shared/Components/Cards/ErrorCard";
import LoadingSpinner from "../../Shared/Components/LoadingSpinner";
import { useLocalization } from '@progress/kendo-react-intl';
import AdministrationAuthTokenTable from "../Components/Tables/AdministrationAuthTokenTable";
import AdminAuthTokenReadDto from "../../../Models/AuthToken/AdminAuthTokenReadDto";
import adminApiService from "../Utilities/AdministrationAPIService";
import AuthTokenCreateModal from "../Components/Modals/AuthTokenCreateModal";
import React from "react";
import AuthTokenConfirmationModal from "../Components/Modals/AuthTokenConfirmationModal";
import { useWindowSize } from "usehooks-ts";

interface Props {}

export default function AdministrationAuthTokenPage({}: Props): ReactElement {
  //Server side state
  const client = useQueryClient();
  const toast = useToast({
    position: "bottom",
    duration: 5000,
    isClosable: true,
  });

  const localizationService = useLocalization();
  //get window size
  const { width, height } = useWindowSize()

  const authTokenQuery = useQuery<Array<AdminAuthTokenReadDto>>(ReactQueryKeys.ManageAuthTokens, async () => {
    return adminApiService.getAllAuthTokens();
  });

  const createAuthToken = async (name: string) => {
    try {
      const result = await adminApiService.createAuthToken(name);

      //If delete is successful, refetch the updated list of devices and show success message
      client.refetchQueries(ReactQueryKeys.ManageAuthTokens);
      setToken(result)

      toast({
        status: "success",
        description: `Auth Token deleted.`,
      });
    } catch (e) {
      toast({
        status: "error",
        description: `Auth Token not deleted.`,
      });
    }
  };

  const deleteAuthToken = async (authTokenId: string) => {
    try {
      let response = await adminApiService.deleteAuthToken(authTokenId);

      //If delete is successful, refetch the updated list of devices and show success message
      client.refetchQueries(ReactQueryKeys.ManageAuthTokens);

      toast({
        status: "success",
        description: `Auth Token deleted.`,
      });
    } catch (e) {
      toast({
        status: "error",
        description: `Auth Token not deleted.`,
      });
    }
  };
  
  const [addAuthToken, setAddAuthToken] = React.useState<boolean>(false);
  const [token, setToken] = React.useState<string>('');

  const closeAdd = () => {
    setAddAuthToken(false);
  };

  const closeConfirmation = () => {
    setToken('');
  };

  const addTokenDialog = (add:boolean) => {
    setAddAuthToken(add);
  };

  //Render the Reservoir Table
  const RenderQuery = () => {
    if (authTokenQuery.error) {
      return <ErrorCard />;
    } else {
      return (
        <div>
          {/* Reservoir Table */}
          <AdministrationAuthTokenTable
            authTokens={authTokenQuery.data!}
            deleteAuthToken={deleteAuthToken}
            createAuthToken={createAuthToken}
            initialSort={undefined} 
            initialSortDescriptor={undefined}
            addTokenDialog={addTokenDialog}
            height={height - 200}
            />

          {(addAuthToken) && (
            <AuthTokenCreateModal
              key={(addAuthToken).toString()}
              createAuthToken={createAuthToken}
              onClose={() => closeAdd()}
            />
          )}

          {(token !== '') && (
            <AuthTokenConfirmationModal
              key={'AuthTokenConfirmationModal'}
              onClose={() => closeConfirmation()} 
              token={token}
            />
          )}
        </div>
      );
    }
  };

  return authTokenQuery.isLoading || authTokenQuery.isFetching ? <LoadingSpinner /> : <RenderQuery />;
}
