import {
    XyDataSeries,
    HorizontalLineAnnotation,
    ELabelPlacement,
    EAnnotationLayer,
    VerticalLineAnnotation,
    SciChartSurface,
    ZoomPanModifier,
    MouseWheelZoomModifier,
    ZoomExtentsModifier,
    RolloverModifier,
    RubberBandXyZoomModifier,
    EXyDirection,
    EExecuteOn,
    FastImpulseRenderableSeries,
    LegendModifier,
    EAutoRange,
    NumberRange,
    EAxisAlignment,
    NumericAxis,
    DateTimeNumericAxis,
    CursorModifier,
    IRenderableSeries,
    FastLineRenderableSeries,
    EllipsePointMarker,
    AxisMarkerAnnotation,
    BoxAnnotation,
    ECoordinateMode,
    IDataSeries,
    ISciChartSurfaceBase,
    UpdateSuspender,
    } from "scichart";
  import { IInitResult, TInitFunction } from "scichart-react";
  import { enMessages } from "../../../../../messages/en-US";
  import { ColumnPaletteProviderPvA, ColumnPaletteProviderPvAUnplanned } from "../PaletteProvider";
  import moment from "moment";
  import { axisLabelDateFormatter } from "../../../../Shared/SciChart/SmartAxis/axisLabelDateFormatter";
  import { GetChartTheme, changeOpacity, getTooltipDataTemplate } from "../../../../Shared/SciChart/ChartConfiguration";
import { LocalizationService } from "@progress/kendo-react-intl";
import { DateTickProvider } from "../../../../Shared/SciChart/SmartAxis/DateTickProvider";
import { SmartDateTZLabelProvider } from "../../../../Shared/SciChart/SmartAxis/SmartDateTZLabelProvider";
import { createDateTzConverter } from "../../../../Shared/SciChart/SmartAxis/timezones";
  
  export interface TComplianceChartConfigResult<TSurface extends ISciChartSurfaceBase> extends IInitResult<TSurface> {
    setAutoRange: (value: boolean) => void,
    showThresholds: (value: boolean) => void
    changeChartType: (barChartOn: boolean, visibleSeriesCompliance) => void
  }
  
  export type TComplianceChartConfigFunc = TInitFunction<SciChartSurface, TComplianceChartConfigResult<SciChartSurface>>;

  export interface Props {
    chartData: any
  }

  export interface ChartOptions {
    isAutoRange: boolean;
    isBarChartOn: boolean;
    visibleRange: { min: number | undefined; max: number | undefined; };
    setVisibleRange: (range: any) => void;
    setVisibleSeriesCompliance: (series: any, isChecked: boolean) => void;
    visibleSeriesCompliance: { [key: string]: boolean;};
    isThresholdsOn: boolean;
  }
  
  
  export const getChartAPI = (props:Props, chartOptions:ChartOptions, localizationService: LocalizationService, currentLanguage: { locale: string; localeId: string; }) => {
  
    const endFilterDateUnix = moment(props.chartData.dateFilter.endDate).unix();
    const startFilterDateUnix = moment(props.chartData.dateFilter.startDate).unix();
    
    const theme = GetChartTheme();  
    let setAutoRangeCallback: (arg0: boolean) => void;
    let showThresholdsCallback: (arg0: boolean) => void;
    let changeChartTypeCallback: (arg0: boolean, arg1:any) => void;
  
    const configAfterInit = (initResult) => {
      setAutoRangeCallback = initResult.setAutoRange;
      showThresholdsCallback = initResult.showThresholds;
      changeChartTypeCallback = initResult.changeChartType;
    } 
  
    const setAutoRange = (value: boolean) => {
      if (setAutoRangeCallback !== undefined) {
        setAutoRangeCallback(value);
      }
    }
  
    const showThresholds = (value: boolean) => {
      if (showThresholdsCallback !== undefined) {
        showThresholdsCallback(value);
      }
    }
  
    const changeChartType = (barChartOn: boolean, visibleSeriesCompliance) => {
      if (changeChartTypeCallback !== undefined) {
        changeChartTypeCallback(barChartOn, visibleSeriesCompliance);
      }
    }
  
    const initChart : TComplianceChartConfigFunc = async (rootElement: string | HTMLDivElement) => {
  
       const {sciChartSurface, wasmContext} = await SciChartSurface.create(rootElement, {theme: theme, disableAspect: true});
  
       // #region Setup the XAxis
       const xAxis = new DateTimeNumericAxis(wasmContext, { 
        drawMajorBands: false, 
        drawMinorGridLines: false, 
        drawMajorGridLines: false, 
        visibleRange: new NumberRange(chartOptions.visibleRange.min, chartOptions.visibleRange.max),
        visibleRangeLimit: new NumberRange(startFilterDateUnix, endFilterDateUnix),
        growBy: new NumberRange(0.05, 0.05),
        labelStyle: {fontFamily:"Roboto", fontSize:12}
      },);
    
       // Overrides getDeltaFromRange to get nice major and minor deltas for the ticks
       xAxis.deltaCalculator.getDeltaFromRange = axisLabelDateFormatter.getDeltaFromRange;
      
       const updateSettings = (xAxis: NumericAxis) => {
        const timezone = moment.tz.guess();
        // Calculate ticks which respect the time zone
        xAxis.tickProvider = new DateTickProvider(wasmContext, timezone);
        // Draws nice axis labels timezone and locale specific
        xAxis.labelProvider = new SmartDateTZLabelProvider(timezone,true, currentLanguage?.localeId);
        const dateTzConverter = createDateTzConverter(timezone);
        // This is needed for Cursor and Rollover chart modifiers
        xAxis.labelProvider.formatCursorLabel = timestamp =>
            axisLabelDateFormatter.toFullDateOnly(timestamp, dateTzConverter, currentLanguage?.localeId);
      };
      // Setting timezone to local system
      updateSettings(xAxis);

      xAxis.visibleRangeChanged.subscribe((args) => { chartOptions.setVisibleRange(args?.visibleRange); });

      sciChartSurface.xAxes.add(xAxis);
      // #endregion
        
      // #region Setup the YAxis
      const yAxis = new NumericAxis(wasmContext,{
        axisAlignment: EAxisAlignment.Right, 
        drawMajorBands: false,
        drawMinorGridLines: false, 
        drawMajorGridLines: true, 
        labelPostfix: " cc", 
        labelPrecision: 2,
        growBy: new NumberRange(0.0, 0.2),
        labelStyle: {fontFamily:"Roboto", fontSize:12}
      });

      sciChartSurface.yAxes.add(yAxis);
      
      // #endregion

      // #region Setup Chart Modifiers
      const legend = new LegendModifier({
        id: "ComplianceLegend",
        placementDivId: "legend-compliance",
        showCheckboxes: true,
        showSeriesMarkers: true,
        showLegend: true,
        isCheckedChangedCallback: (series: IRenderableSeries, isChecked: boolean) => {
          chartOptions.setVisibleSeriesCompliance(series.id, isChecked)  
        }
      });

      sciChartSurface.chartModifiers.add(legend);

      sciChartSurface.chartModifiers.add(
        new CursorModifier({ showAxisLabels: false,  id: "ComplianceModifierGroup" }),
        new ZoomPanModifier({xyDirection: EXyDirection.XDirection}),
        new MouseWheelZoomModifier({xyDirection:EXyDirection.XDirection}),
        new ZoomExtentsModifier({xyDirection:EXyDirection.XDirection}),
        new RolloverModifier({tooltipDataTemplate: getTooltipDataTemplate, id: "ComplianceRollOver"}),
        new RubberBandXyZoomModifier({ executeOn: EExecuteOn.MouseRightButton }),
      );
      // #endregion

      // #region Create the Data Series 

      const volumeDispensedDataSeries = new XyDataSeries(wasmContext, {
        xValues: props.chartData.requirements.timestamps, 
        yValues: props.chartData.requirements.volumeDispensedData, 
        dataSeriesName: `${localizationService.toLanguageString('custom.volumeDispensed', enMessages.custom.volumeDispensed)}` 
      });

      const unplannedVolumeDispensedDataSeries = new XyDataSeries(wasmContext, {
        xValues: props.chartData.requirements.unplannedEventsTimestamps, 
        yValues: props.chartData.requirements.unplannedEventsVolumes, 
        dataSeriesName: `${localizationService.toLanguageString('custom.unplannedVolumeDispensed', enMessages.custom.unplannedVolumeDispensed)} (cc)` 
      });

      const filterPeriodDataSeries = new XyDataSeries(wasmContext, {
        xValues: [startFilterDateUnix, endFilterDateUnix],
        yValues: [0,0], 
        dataSeriesName: "filterPeriodSeries"
      });
    
      // Create a series that represents the entire filter period so the chart displays the entire range. 
      const filterPeriodSeries = new FastLineRenderableSeries(wasmContext, {
        id: "FilterPeriodSeriesId", 
        isVisible: false,
        dataSeries: filterPeriodDataSeries
      });  
      sciChartSurface.renderableSeries.add(filterPeriodSeries);
      legend.includeSeries(filterPeriodSeries, false);

      const createVolumeDispensedSeries = (barChartOn: boolean, isVisible: boolean, dataSeries: IDataSeries) => {
  
        const volumeDispensed = barChartOn === true ? new FastImpulseRenderableSeries(wasmContext, {
          stroke: "rgba(50, 53, 67,1)", 
          fill: "rgba(50, 53, 67,1)", 
          strokeThickness:props.chartData.strokeThickness,
          paletteProvider: new ColumnPaletteProviderPvA(),
          id: "VolumeDispensedId",
          dataSeries: dataSeries,
          isVisible: isVisible
        }) : new FastLineRenderableSeries(wasmContext, {
          dataSeries: dataSeries,
          stroke: "rgba(50, 53, 67,1)", 
          id: "VolumeDispensedId",
          isVisible: isVisible,
          pointMarker:  new EllipsePointMarker(wasmContext, {width: 6, height: 6, stroke: "rgba(50, 53, 67,1)", fill: "rgba(50, 53, 67,1)" 
        }),
        })

        volumeDispensed.rolloverModifierProps.tooltipTextColor = 'White'
        volumeDispensed.rolloverModifierProps.showRollover = true
        volumeDispensed.rolloverModifierProps.markerColor = 'red'

        return volumeDispensed;
      }
      const volumeDispensed = createVolumeDispensedSeries(chartOptions.isBarChartOn, chartOptions.visibleSeriesCompliance.VolumeDispensedId, volumeDispensedDataSeries);
      sciChartSurface.renderableSeries.add(volumeDispensed);
    
   
      const createUnplannedVolumeDispensedSeries = (barChartOn: boolean, isVisible: boolean, dataSeries: IDataSeries) => {

        const unplannedVolumeDispensed = barChartOn === true ? new FastImpulseRenderableSeries(wasmContext, {
          stroke: "darkGrey", 
          fill: "darkGrey", 
          strokeThickness:props.chartData.strokeThickness,
          paletteProvider: new ColumnPaletteProviderPvAUnplanned(),
          id: "UnplannedVolumeDispensedId",
          dataSeries: dataSeries,
          isVisible: isVisible
        }) : new FastLineRenderableSeries(wasmContext, {
          dataSeries: dataSeries,
          stroke: "darkGrey", 
          id: "UnplannedVolumeDispensedId",
          isVisible: isVisible,
          pointMarker:  new EllipsePointMarker(wasmContext, {width: 6,height: 6,stroke: "darkGrey", fill: "darkGrey", 
        }),
        })

        unplannedVolumeDispensed.rolloverModifierProps.tooltipTextColor = 'White';
        unplannedVolumeDispensed.rolloverModifierProps.showRollover = true;
        unplannedVolumeDispensed.rolloverModifierProps.markerColor = 'red';

        return unplannedVolumeDispensed
      }

      const unplannedVolumeDispensed = createUnplannedVolumeDispensedSeries(chartOptions.isBarChartOn, chartOptions.visibleSeriesCompliance.UnplannedVolumeDispensedId, unplannedVolumeDispensedDataSeries);
      sciChartSurface.renderableSeries.add(unplannedVolumeDispensed);

      const lastSeenSeries = new FastImpulseRenderableSeries(wasmContext, {
        stroke: "grey", fill: "grey", strokeThickness:props.chartData.strokeThickness, paletteProvider: new ColumnPaletteProviderPvA(), id: "lastSeenId"});
      
      lastSeenSeries.dataSeries = new XyDataSeries(wasmContext, {
        xValues: [props.chartData.requirements.lastSeen], 
        yValues: [0], 
        dataSeriesName: `${localizationService.toLanguageString('custom.lastSeen', enMessages.custom.lastSeen)}` 
      });
      
      lastSeenSeries.rolloverModifierProps.tooltipTextColor = 'White'
      lastSeenSeries.rolloverModifierProps.showRollover = true
    
      const volumeRequirementLine = new FastLineRenderableSeries(wasmContext, {
        stroke: "red", 
        strokeThickness:2, 
        strokeDashArray: [6, 4],
        id: `VolumeRequirementId`,
        isVisible: chartOptions.visibleSeriesCompliance.VolumeRequirementId
      });
  
      const annotationStartDate = startFilterDateUnix > props.chartData.requirements.startDate ? startFilterDateUnix : props.chartData.requirements.startDate;
      const annotationEndDate = endFilterDateUnix < props.chartData.requirements.endDate ? endFilterDateUnix : props.chartData.requirements.endDate;
    

      volumeRequirementLine.dataSeries = new XyDataSeries(wasmContext, {
        xValues: [annotationStartDate, annotationEndDate], 
        yValues: [props.chartData.requirements.volumeRequirement, props.chartData.requirements.volumeRequirement], 
        dataSeriesName: `${localizationService.toLanguageString('custom.volumeRequirement', enMessages.custom.volumeRequirement)} (cc)` 
      });
      
      volumeRequirementLine.rolloverModifierProps.showRollover = false
      sciChartSurface.renderableSeries.add(volumeRequirementLine);

      // #endregion
      
      // #region Setup the API's 
      const getMaxYRange = () => {

        const visibleSeries = sciChartSurface.renderableSeries.asArray().filter(x => x.isVisible);

        var minYRange = 0
        var maxYRange = 0;

          visibleSeries.forEach((series) => {
            const dataSeries = series.dataSeries as XyDataSeries;
            const yRange = dataSeries.getWindowedYRange(dataSeries.xRange, true, false);
          
            if (yRange !== undefined) {
              if (yRange.min < minYRange) {
                minYRange = yRange.min;
              }

              if (yRange.max > maxYRange) {
                maxYRange = yRange.max;
              }
            }
          });

          return {min: minYRange, max: maxYRange};
      };

      const setAutoRange = (value: boolean) => {

        const yAxisCurrent = sciChartSurface.yAxes.get(0);
          
        if (yAxisCurrent !== undefined) {
          if (value) {
            yAxisCurrent.autoRange = EAutoRange.Always;
          } else {
            yAxisCurrent.autoRange = EAutoRange.Never;

            const visibleSeries = sciChartSurface.renderableSeries.asArray().filter(x => x.isVisible);

            if (visibleSeries.length === 0) {
              yAxisCurrent.visibleRange = new NumberRange(0, 10 * 1.20);
            } else {

              var minYRange = 0;
              var maxYRange = 0;
              
              visibleSeries.forEach((series) => {
                const dataSeries = series.dataSeries as XyDataSeries;
                const yRange = dataSeries.getWindowedYRange(dataSeries.xRange, true, false);
              
                if (yRange !== undefined) {
                  if (yRange.min < minYRange) {
                    minYRange = yRange.min;
                  }

                  if (yRange.max > maxYRange) {
                    maxYRange = yRange.max;
                  }
                }
              });

              yAxisCurrent.visibleRange = new NumberRange(minYRange, maxYRange * 1.20);
            }
          }
        }
      };

      setAutoRange(chartOptions.isAutoRange);
      
      const changeChartType = (barChartOn: boolean) => {

        UpdateSuspender.using(sciChartSurface, () => {
            const index = sciChartSurface.renderableSeries.asArray().findIndex(x => x.id === "VolumeDispensedId");
            const newVolumeDispensedSeries = createVolumeDispensedSeries(barChartOn, volumeDispensed.isVisible, volumeDispensed.dataSeries);
            sciChartSurface.renderableSeries.set(index, newVolumeDispensedSeries);

            const index2 = sciChartSurface.renderableSeries.asArray().findIndex(x => x.id === "UnplannedVolumeDispensedId");
            const newUnplannedVolumeDispensedSeries = createUnplannedVolumeDispensedSeries(barChartOn, unplannedVolumeDispensed.isVisible, unplannedVolumeDispensed.dataSeries);
            sciChartSurface.renderableSeries.set(index2, newUnplannedVolumeDispensedSeries);
        });
    }
  
      const showThresholds = (value: boolean) => {
        UpdateSuspender.using(sciChartSurface, () => {
          sciChartSurface.annotations.asArray().forEach(annotation => {
            if (annotation.id.includes("Threshold")) {
              annotation.isHidden = !value;
            }
          })
      });
      }
      // #endregion

      // #region Setup Annotations
      // Add 0 line annotation
      const horizontalLine = new HorizontalLineAnnotation({
        labelPlacement: ELabelPlacement.Axis,
        stroke: 'grey',
        strokeThickness: 3,
        y1: 0,
        annotationLayer: EAnnotationLayer.BelowChart
        });
        sciChartSurface.annotations.add(horizontalLine)
    
      // Add LastSeen line annotation
      const lastSeenLine = new VerticalLineAnnotation({
        labelPlacement: ELabelPlacement.TopRight,
        stroke: 'grey',
        strokeThickness: 3,
        strokeDashArray: [12,5],
        x1: props.chartData.requirements.lastSeen,
        showLabel:true,
        labelValue: `${localizationService.toLanguageString('custom.lastSeen', enMessages.custom.lastSeen)}`,
        axisLabelFill: 'grey',
        annotationLayer: EAnnotationLayer.AboveChart
        });

      // Descending sort thresholds by value
      props.chartData.requirements.thresholds.sort((a, b) => (a.value > b.value) ? -1 : 1);
  
      for(let i = 0; i < props.chartData.requirements.thresholds.length; i++) {
        let thresholdStart = 0;
        let thresholdEnd = 0;
  
        if (i === 0) {
          thresholdStart = props.chartData.requirements.thresholds[i].value;
          //set max to a higher value to fill the chart
          thresholdEnd = getMaxYRange().max * 2;
        } else  {
          thresholdStart = props.chartData.requirements.thresholds[i].value;
          thresholdEnd = props.chartData.requirements.thresholds[i-1].value;
        }
  
        const boxAnnotation = new BoxAnnotation({
          id: `Threshold${i}`,
          x1: annotationStartDate, x2: annotationEndDate,
          y1: thresholdStart, y2: thresholdEnd,
          fill: changeOpacity(props.chartData.requirements.thresholds[i].colour, 0.4) ,  // add opacity to fill 
          strokeThickness: 0,
          xCoordinateMode: ECoordinateMode.DataValue,
          annotationLayer: EAnnotationLayer.BelowChart,
          isHidden: !chartOptions.isThresholdsOn
        })
        
        sciChartSurface.annotations.add(boxAnnotation);
      }
  
      sciChartSurface.annotations.add(new AxisMarkerAnnotation({y1: props.chartData.requirements.volumeRequirement,fontSize: 12,backgroundColor: "red"}));
    
      if(props.chartData.requirements.lastSeen >= startFilterDateUnix && props.chartData.requirements.lastSeen <= endFilterDateUnix) {
        sciChartSurface.annotations.add(lastSeenLine)
        sciChartSurface.renderableSeries.add(lastSeenSeries);
        legend.includeSeries(lastSeenSeries, false);
      }
    
       // #endregion

        return { sciChartSurface: sciChartSurface, setAutoRange, showThresholds, changeChartType};
      
    };
  
    return {initChart, configAfterInit, setAutoRange, showThresholds, changeChartType};
  
  }
  