import { useLocalization } from "@progress/kendo-react-intl";
import { enMessages } from "../../../messages/en-US";
import { Button } from "@progress/kendo-react-buttons";
import { useNavigate } from "react-router-dom";
import { ClientRoutes, ReactQueryKeys } from "../../../Constants/ClientRoutingConstants";
import { useQuery } from "react-query";
import dashboardApiService from "../Utilities/DashboardApiService";
import EndpointsMetricsReadDto from "../../../Models/Dashboard/EndpointsMetricsReadDto";
import { useMemo } from "react";
import NoDataAvailablePage from "../Utilities/NoDataAvailablePage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { Loader } from "@progress/kendo-react-indicators";

export default function EndpointsTile( ) {
  const localizationService = useLocalization();
  let navigate = useNavigate(); 

  const redColour = "#ff5034";
  const greenColour = "#1ab394";
  const greyColour = "rgba(211,211,211,0.2)";

  const endpointsMetricsQuery = useQuery<EndpointsMetricsReadDto>([ReactQueryKeys.DashboardEndpointsMetricsQuery], async () => {
    return dashboardApiService.getEndpointsMetrics();
  });


  const endpointsMetrics = useMemo(() => {
    if(endpointsMetricsQuery.data !== undefined && endpointsMetricsQuery.data !== null) {
      return {
        endpointCount: endpointsMetricsQuery.data.endpointCount,
        endpointCountWithoutRequirement: endpointsMetricsQuery.data.endpointCountWithoutRequirement,
        textColour: "black",
        backgroundColour: endpointsMetricsQuery.data.endpointCountWithoutRequirement > 0 ? redColour : greenColour,
      }
    } else {
      return {}
    }
  }, [endpointsMetricsQuery.data]);

  const getIcon = (backgroundColour: string | undefined) => {
    if(backgroundColour === redColour) {
      return <FontAwesomeIcon icon={faExclamationCircle} style={{color: backgroundColour, fontSize: "30px"}} />
    } else {
      return <FontAwesomeIcon icon={faCheckCircle} style={{color: backgroundColour, fontSize: "30px"}} />
    }
  }

  if(endpointsMetricsQuery.isLoading || endpointsMetricsQuery.isFetching) {
    return <Loader size="medium" type={"pulsing"} className="loading-dashboard"/>
  } else if (endpointsMetricsQuery.isError || endpointsMetrics.endpointCount === -1) {
    return <NoDataAvailablePage title={localizationService.toLanguageString('custom.endpoints', enMessages.custom.endpoints)} period={null} clientRoute={`${ClientRoutes.SiteSetupPage}#endpoints`} />
  } else {
    return (
      <div className="m-2">
        <div className="flex justify-between align-middle">
          <div className="text-xl font-bold">{localizationService.toLanguageString('custom.endpoints', enMessages.custom.endpoints)}</div>
          <Button fillMode={"link"} style={{color:"blue", textDecoration: "underline" }} onClick={() => navigate(`${ClientRoutes.SiteSetupPage}#endpoints`)}>{localizationService.toLanguageString('custom.seeAll', enMessages.custom.seeAll)}</Button>
        </div>
          <div>
            <div className="mt-2">
              <div className="text-5xl">{endpointsMetrics.endpointCount}</div>
              <div className="ml-1 lowercase">{localizationService.toLanguageString('custom.devices', enMessages.custom.devices)}</div>
            </div>
            <div className="rounded m-auto mt-5 px-2 py-3" style={{color: endpointsMetrics.textColour, background: greyColour}}>
              <div className="">{localizationService.toLanguageString('custom.withoutRequirement', enMessages.custom.withoutRequirement)}</div>
              <div className="flex justify-between items-center">
                <div className=" text-3xl">{endpointsMetrics.endpointCountWithoutRequirement === 0 ? "-" : endpointsMetrics.endpointCountWithoutRequirement}</div>
                {getIcon(endpointsMetrics.backgroundColour)}   
              </div>
            </div>    
          </div>
      </div>
    );
}}
