import { ReactElement } from "react";
import { useLocalization } from "@progress/kendo-react-intl";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { enMessages } from "../../../../messages/en-US";
import { Button } from "@progress/kendo-react-buttons";
import { Form, Field, FormElement, } from "@progress/kendo-react-form";
import { FormDateTimePicker, FormDropDownList, FormTextArea } from "../../../Shared/Components/Forms/FormsComponents";
import React from "react";
import { useToast } from "@chakra-ui/react";
import { requiredValidator } from "../../../Alerts/Utilities/validators";
import moment from "moment";

interface Props {
  onClose: () => void;
  updateStatus: (id: string, item: any) => void;
  selectedId: any;
}

// Enum for representing the different event types
enum EventType {
  Unspecified = 0,
  Online = 1,
  Offline = 2,
  OfflinePeriod = 3
}

// Enum for representing the different reason codes
enum ReasonCode {
  Unspecified = 0,
  Other = 1,
  MaintenancePlanned = 2,
  MaintenanceUnplanned = 3,
  OperationalShutdownPlanned = 4,
  OperationalShutdownUnplanned = 5
}

export default function StatusDialog(props: Props): ReactElement {
  const localizationService = useLocalization();

  const offlinePeriodIndex = EventType.OfflinePeriod - 1;

  const toast = useToast({
    position: "bottom",
    duration: 5000,
    isClosable: true,
  });

  const eventTypeData = [
    { text: `${localizationService.toLanguageString('custom.online', enMessages.custom.online)}`, id: EventType.Online},
    { text: `${localizationService.toLanguageString('custom.offline', enMessages.custom.offline)}`, id: EventType.Offline},
    { text: `${localizationService.toLanguageString('custom.offlinePeriod', enMessages.custom.offlinePeriod)}`, id: EventType.OfflinePeriod}
  ];
  const [eventType, setEventType] = React.useState<any>(eventTypeData[offlinePeriodIndex]);

  const [codeReason, setCodeReason] = React.useState<any>({text:"", id: ReasonCode.Unspecified});
  const codeReasonData = [
    { text: `${localizationService.toLanguageString('custom.other', enMessages.custom.other)}`, id: ReasonCode.Other},
    { text: `${localizationService.toLanguageString('custom.maintenancePlanned', enMessages.custom.maintenancePlanned)}`, id: ReasonCode.MaintenancePlanned},
    { text: `${localizationService.toLanguageString('custom.maintenanceUnplanned', enMessages.custom.maintenanceUnplanned)}`, id: ReasonCode.MaintenanceUnplanned},
    { text: `${localizationService.toLanguageString('custom.operationalShutdownPlanned', enMessages.custom.operationalShutdownPlanned)}`, id: ReasonCode.OperationalShutdownPlanned},
    { text: `${localizationService.toLanguageString('custom.operationalShutdownUnplanned', enMessages.custom.operationalShutdownUnplanned)}`, id: ReasonCode.OperationalShutdownUnplanned} 
  ];

  const [offlinePeriod, setOfflinePeriod] = React.useState({
    fromDate: undefined,
    untilDate: undefined
  });

  const handleChange = (event) => {
    let name = event.target.name || event.target.element.current.name;
    setOfflinePeriod({ ...offlinePeriod, [name]: event.target.value });
  };

  const handleSubmit = async (dataItem) => {
    const values = {
      eventType: EventType[eventType.id],
      fromDate: dataItem.fromDate.toISOString(),
      untilDate: eventType.id === EventType.OfflinePeriod ? dataItem.untilDate.toISOString() : "1970-01-01T00:00:00.000Z",
      reasonCode: eventType.id === EventType.Online ? ReasonCode[ReasonCode.Unspecified] : ReasonCode[codeReason.id],
      comment: dataItem.comment
    }

    try{
      await props.updateStatus(props.selectedId, values)
      toast({
        status: "success",
        description: `${localizationService.toLanguageString("custom.successfullyUpdated", enMessages.custom.successfullyUpdated
        )}`,
      });
    }
    catch(error){
      console.log(error)
      toast({
        title: `${localizationService.toLanguageString("custom.error", enMessages.custom.error
        )}`,
        status: "error",
        description: `${localizationService.toLanguageString("custom.somethingWentWrong", enMessages.custom.somethingWentWrong
        )}`,
      });
    }
    props.onClose()
  };

  return (
    <div>
      <Dialog
        title={localizationService.toLanguageString("custom.addOnlineOffline", enMessages.custom.addOnlineOffline
        )}
        onClose={props.onClose}
        width={550}
        height={510}
      >
        <Form
          initialValues={
            {
              eventType: eventTypeData[offlinePeriodIndex], 
              fromDate: new Date(),
              comment: ""
            }
          }
          onSubmit={handleSubmit}
          render={(formRenderProps) => (
            <FormElement className="flex flex-col justify-between h-full">
              <fieldset
                className={"k-form-fieldset"}
                style={{ padding: "10px" }}
              >
                <div className="mb-3">
                  <Field
                    name={"eventType"}
                    type={"text"}
                    component={FormDropDownList}
                    label={localizationService.toLanguageString("custom.eventType", enMessages.custom.eventType)}
                    data={eventTypeData}
                    textField={"text"}
                    onChange={(e) => setEventType(e.value)}
                    validator={requiredValidator}
                  />
                </div>
                {eventType.id === 3 ? 
                  <div className="mb-3 flex gap-5">
                    <div>
                      <label>{localizationService.toLanguageString("custom.from", enMessages.custom.from)}</label>
                      <Field
                        name={"fromDate"}
                        component={FormDateTimePicker}
                        width={238}
                        validator={requiredValidator}
                        value={offlinePeriod.fromDate}
                        onChange={handleChange}
                      />
                    </div>
                    <div>
                      <label>{localizationService.toLanguageString("custom.until", enMessages.custom.until)}</label>
                      <Field
                        name={"untilDate"}
                        component={FormDateTimePicker}
                        width={238}
                        validator={requiredValidator}
                        value={offlinePeriod.untilDate}
                        onChange={handleChange}
                        min={moment(offlinePeriod.fromDate).add(1,'minutes').toDate()}
                      />  
                  </div>
                </div>
                : 
                  <div className="mb-3">
                    <label>{localizationService.toLanguageString("custom.date", enMessages.custom.date)}</label>
                    <Field
                      name={"fromDate"}
                      component={FormDateTimePicker}
                      validator={requiredValidator}
                    />
                  </div>
                }

                {(eventType.id === EventType.Offline || eventType.id === EventType.OfflinePeriod) && 
                  <div className="mb-3">
                  <Field
                    name={"reasonCode"}
                    type={"text"}
                    component={FormDropDownList}
                    label={localizationService.toLanguageString("custom.reasonCode", enMessages.custom.reasonCode
                    )}
                    data={codeReasonData}
                    onChange={(e) => setCodeReason(e.value)}
                    textField={"text"}
                    required={true}
                    validator={requiredValidator}
                  />
                </div>
                }

                <div className="mb-3">
                   <Field
                     name={"comment"}
                     type={"text"}
                     component={FormTextArea}
                     label={localizationService.toLanguageString("custom.comment", enMessages.custom.comment)}
                     rows={5}
                     validator={codeReason.id === ReasonCode.Other ? requiredValidator : undefined}
                   />
                </div>
                
              </fieldset>
              <DialogActionsBar>
                <Button onClick={props.onClose}>
                  {localizationService.toLanguageString(
                    "custom.cancel",
                    enMessages.custom.cancel
                  )}
                </Button>
                <Button
                  type={"submit"}
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                  style={{ background: "#35AC00", color: "white" }}
                  disabled={!formRenderProps.allowSubmit}
                >
                  {localizationService.toLanguageString("custom.save",enMessages.custom.save)}
                </Button>
              </DialogActionsBar>
            </FormElement>
          )}
        />
      </Dialog>
    </div>
  );
}
