import { useLocalization } from "@progress/kendo-react-intl";
import { enMessages } from "../../../messages/en-US";
import { Button } from "@progress/kendo-react-buttons";
import { useNavigate } from "react-router-dom";
import { ClientRoutes, ReactQueryKeys } from "../../../Constants/ClientRoutingConstants";
import { useQuery } from "react-query";
import dashboardApiService from "../Utilities/DashboardApiService";
import moment from "moment";
import AlertsMetricsReadDto from "../../../Models/Dashboard/AlertsMetricsReadDto";
import { useMemo } from "react";
import NoDataAvailablePage from "../Utilities/NoDataAvailablePage";
import { Loader } from "@progress/kendo-react-indicators";
import { useAccountSettingsStore } from "../../../Zustand/accountSettingsStore";


export default function AlertsTile( ) {
  const localizationService = useLocalization();
  let navigate = useNavigate(); 
  const accountTimezone = useAccountSettingsStore(state => state.accountTimezone);

  const criticalColour = "#780000";
  const highColour = "#DC0000";
  const mediumColour = "#FD8C00";
  const lowColour = "#FDC500";
  const greyColour = "rgba(211,211,211,0.2)";

  const endDate = moment()
  const startDate = moment().subtract(7, 'days')

  //Convert date to Utc using account timezone
  const dateRangeUtc = {
    startDate: moment.tz(startDate.format("YYYY-MM-DDT00:00:00"), accountTimezone).utc(), 
    endDate: moment.tz(endDate.format("YYYY-MM-DDT23:59:59"), accountTimezone).utc()
  };

  const alertsMetricsQuery = useQuery<AlertsMetricsReadDto>([ReactQueryKeys.DashboardAlertsMetricsQuery, dateRangeUtc], async () => {
    return dashboardApiService.getAlertsMetrics(dateRangeUtc);
  });

  const alertsMetrics = useMemo(() => {
    if(alertsMetricsQuery.data !== undefined) {
      return {
        alertCount: alertsMetricsQuery.data.alertCount,
        criticalAlertCount: alertsMetricsQuery.data.criticalAlertCount,
        highAlertCount: alertsMetricsQuery.data.highAlertCount,
        mediumAlertCount: alertsMetricsQuery.data.mediumAlertCount,
        lowAlertCount: alertsMetricsQuery.data.lowAlertCount
      }
    } else {
      return {}
    }
  }, [alertsMetricsQuery.data]);

  if(alertsMetricsQuery.isLoading || alertsMetricsQuery.isFetching) {
    return <Loader size="medium" type={"pulsing"} className="loading-dashboard"/>
  } else if (alertsMetricsQuery.isError || alertsMetrics.alertCount === -1) {
    return <NoDataAvailablePage title={localizationService.toLanguageString('custom.alerts', enMessages.custom.alerts)} period={localizationService.toLanguageString('custom.inLast7Days', enMessages.custom.inLast7Days)} clientRoute={ClientRoutes.NotificationsPage} />
  } else {
  return (
    <div className="m-2">
      <div className="flex justify-between align-middle">
        <div className="text-xl font-bold">{localizationService.toLanguageString('custom.alerts', enMessages.custom.alerts)}</div>
        <Button fillMode={"link"} style={{color:"blue", textDecoration: "underline" }} onClick={() => navigate(ClientRoutes.NotificationsPage)}>{localizationService.toLanguageString('custom.seeAll', enMessages.custom.seeAll)}</Button>
      </div>
        <div>
          <div className="mt-2">
            <div className="text-5xl">{alertsMetrics.alertCount}</div>
            <div className="ml-1">{localizationService.toLanguageString('custom.inLast7Days', enMessages.custom.inLast7Days)}</div>
          </div>
          <div className="flex gap-2">
            <div className="rounded m-auto w-full mt-5 px-2 py-3" style={{background: greyColour , color: 'black'}}>
              <div className="">{localizationService.toLanguageString('custom.critical', enMessages.custom.critical)}</div>
              <div className="flex justify-between items-center">
              <div className=" text-3xl">{alertsMetrics.criticalAlertCount === 0 ? "-" : alertsMetrics.criticalAlertCount}</div>
              <div className={`k-icon k-i-chevron-double-up cursor-pointer k-icon-xl`} style={{color: criticalColour, fontSize: "30px"}}></div>
            </div>
            </div>
            <div className="rounded m-auto w-full mt-5 px-2 py-3"  style={{background: greyColour, color: 'black'}}>
              <div className="">{localizationService.toLanguageString('custom.high', enMessages.custom.high)}</div>
              <div className="flex justify-between items-center">
              <div className=" text-3xl">{alertsMetrics.highAlertCount === 0 ? "-" : alertsMetrics.highAlertCount}</div>
              <div className={`k-icon k-i-arrow-chevron-up cursor-pointer k-icon-xl`} style={{color: highColour, fontSize: "30px"}}></div>
            </div> 
            </div>
          </div>
          <div className="flex gap-2">
            <div className="rounded m-auto w-full mt-2 px-2 py-3" style={{background: greyColour, color: 'black'}}>
              <div className="">{localizationService.toLanguageString('custom.medium', enMessages.custom.medium)}</div>
              <div className="flex justify-between items-center">
              <div className=" text-3xl">{alertsMetrics.mediumAlertCount === 0 ? "-" : alertsMetrics.mediumAlertCount}</div>
              <div className={`k-icon k-i-equal cursor-pointer k-icon-xl`} style={{color: mediumColour, fontSize: "30px"}}></div>
            </div>
            </div>
            <div className="rounded m-auto w-full mt-2 px-2 py-3"  style={{background: greyColour, color: 'black'}}>
              <div className="">{localizationService.toLanguageString('custom.low', enMessages.custom.low)}</div>
              <div className="flex justify-between items-center">
              <div className=" text-3xl">{alertsMetrics.lowAlertCount === 0 ? "-" : alertsMetrics.lowAlertCount}
          </div>
          <div className={`k-icon k-i-arrow-chevron-down cursor-pointer k-icon-xl`} style={{color: lowColour, fontSize: "30px"}}></div>
          </div>
          </div>
            </div> 
        </div>
    </div>
  );
}}
