import create from "zustand";

interface ISiteSetupStore {
  inventoryOpen: boolean;
  scheduleOpen: boolean;
  assetsOpen: boolean;
  setInventoryOpen: (value: boolean) => void;
  setScheduleOpen: (value: boolean) => void;
  setAssetsOpen: (value: boolean) => void;
}

/**
 * This store allows us to access the current user identity anywhere in the application.
 */
const useSiteSetupStore = create<ISiteSetupStore>((set) => ({
  inventoryOpen: false,
  scheduleOpen: false,
  assetsOpen: false,
  setAssetsOpen: (value: boolean) => {
    set((state) => {
      return {
        assetsOpen: value,
      };
    });
  },
  setInventoryOpen: (value: boolean) => {
    set((state) => {
      return {
        inventoryOpen: value,
      };
    });
  },
  setScheduleOpen: (value: boolean) => {
    set((state) => {
      return {
        scheduleOpen: value,
      };
    });
  },
}));

export default useSiteSetupStore;
