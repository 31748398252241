import create from "zustand";
import UserIdentity from "../Models/Admin/UserIdentity";

interface IIdentityStore {
  user: UserIdentity | null;
  setUser: (user: UserIdentity | null) => void;
}

/**
 * This store allows us to access the current user identity anywhere in the application.
 */
const useIdentityStore = create<IIdentityStore>((set) => ({
  user: null,
  setUser: (user: UserIdentity | null) => {
    set((state) => {
      return {
        user: user,
      };
    });
  },
}));

export default useIdentityStore;
