import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.scss';
import '@progress/kendo-theme-default/dist/all.css';
import { HashRouter, BrowserRouter as Router } from 'react-router-dom';

let root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

//@ts-ignore
root.render(
  //Strict Mode needs to be removed due to Telerikk Report Viewer https://docs.telerik.com/reporting/knowledge-base/react-viewer-does-not-load-dispose-error?_ga=2.13009015.1924139473.1674431428-945463639.1657681083&_gl=1*flnx6e*_ga*OTQ1NDYzNjM5LjE2NTc2ODEwODM.*_ga_9JSNBCSF54*MTY3NDYwNDEzMy4xNjEuMS4xNjc0NjA0Mzk1LjU0LjAuMA..
  
  // <React.StrictMode>
    <Router>
      <App />
    </Router>
  // </React.StrictMode>,
);