import {
  SciChartSurface,
  EAxisAlignment,
  NumericAxis,
  Thickness,
  DateTimeNumericAxis,
  CursorModifier,
  EXyDirection,
  NumberRange,
  ZoomExtentsModifier,
  ZoomPanModifier,
  EAutoRange,
  MouseWheelZoomModifier,
  XyDataSeries,
  FastLineRenderableSeries,
  } from "scichart";
import { TInitFunction } from "scichart-react";
import { GetChartTheme } from "../../../Shared/SciChart/ChartConfiguration";
import { EventFilter } from "../../../Events/Utilities/EventsApiService";
import moment from "moment";
import { DateTickProvider } from "../../../Shared/SciChart/SmartAxis/DateTickProvider";
import { SmartDateTZLabelProvider } from "../../../Shared/SciChart/SmartAxis/SmartDateTZLabelProvider";
import { axisLabelDateFormatter } from "../../../Shared/SciChart/SmartAxis/axisLabelDateFormatter";
import { createDateTzConverter } from "../../../Shared/SciChart/SmartAxis/timezones";
import { ChartSynchroniser } from "../../Utilities/ChartSynchroniser";

export type TEndpointReportOverviewChartConfigFunc = TInitFunction<SciChartSurface>;

export interface EndpointReportOverviewChartData {
  dateFilter: EventFilter;
  chartSynchroniser: ChartSynchroniser;
}

export const getChartAPI = (dateFilter: EventFilter, chartSynchroniser: ChartSynchroniser, currentLanguage: { locale: string; localeId: string; }) => {

  const dateFilterStartDateUnix = moment(dateFilter.startDate).unix();
  const dateFilterEndDateUnix = moment(dateFilter.endDate).unix(); 

  const theme = GetChartTheme();  

  const onDelete = (initResult) => {
    chartSynchroniser.removeAxis(initResult.sciChartSurface.xAxes.get(0));
    chartSynchroniser.removeSurface(initResult.sciChartSurface);
  } 

  const initChart : TEndpointReportOverviewChartConfigFunc = async (rootElement: string | HTMLDivElement) => {
  
      const {sciChartSurface, wasmContext} = await SciChartSurface.create(rootElement, {theme});

      // Set padding to allow the overview handles to be displayed correct. Order is Top, Right, Bottom, Left
      sciChartSurface.padding = new Thickness(0, 0, 0, 0);
    
      // #region Setup the XAxis
      const xAxis = new DateTimeNumericAxis(wasmContext, { 
        axisAlignment: EAxisAlignment.Top, 
        autoTicks:true,
        drawLabels: true,
        drawMajorBands: false, 
        drawMinorGridLines: false, 
        drawMajorGridLines: false, 
        autoRange: EAutoRange.Always,
        visibleRange: new NumberRange(dateFilterStartDateUnix, dateFilterEndDateUnix),
        visibleRangeLimit: new NumberRange(dateFilterStartDateUnix, dateFilterEndDateUnix),
        labelStyle: {fontFamily:"Roboto", fontSize:12,}
      });

      const updateSettings = (xAxis$: NumericAxis) => {
        const timezone = moment.tz.guess();
        // Calculate ticks which respect the time zone
        xAxis$.tickProvider = new DateTickProvider(wasmContext, timezone);
        // Draws nice axis labels timezone and locale specific
        xAxis$.labelProvider = new SmartDateTZLabelProvider(timezone,true, currentLanguage?.localeId);
        const dateTzConverter = createDateTzConverter(timezone);
        // This is needed for Cursor and Rollover chart modifiers
        xAxis$.labelProvider.formatCursorLabel = timestamp =>
            axisLabelDateFormatter.toFullDateTime(timestamp, dateTzConverter, currentLanguage?.localeId);
        };
        // Setting timezone to local system
        updateSettings(xAxis);

      // Overrides getDeltaFromRange to get nice major and minor deltas for the ticks
      xAxis.deltaCalculator.getDeltaFromRange = axisLabelDateFormatter.getDeltaFromRange;
    
      sciChartSurface.xAxes.add(xAxis);
      // #endregion

      // #region Setup the YAxis
      const yAxis = new NumericAxis(wasmContext,{isVisible: false,  visibleRange: new NumberRange(0, 1)});
      sciChartSurface.yAxes.add(yAxis);
      // #endregion

      // #region Setup the Chart Modifers
      sciChartSurface.chartModifiers.add(
        new ZoomPanModifier({xyDirection: EXyDirection.XDirection}),
        new ZoomExtentsModifier(),
        new MouseWheelZoomModifier(),
        new CursorModifier({crosshairStroke: "rgba(50, 53, 67,1)", axisLabelFill: "rgba(50, 53, 67,0.7)", showTooltip: false, showAxisLabels: true, showYLine: false, modifierGroup:"one"}),
        );
      // #endregion

      // #region Setup Series
      const volumeDispensed = new FastLineRenderableSeries(wasmContext, {
        dataSeries: new XyDataSeries(wasmContext, {xValues: [dateFilterStartDateUnix, dateFilterEndDateUnix], yValues: [0, 0]}),
        stroke: "rgba(50, 53, 67,1)", 
        id: "VolumeDispensedId",
        isVisible: false
      });
      
      sciChartSurface.renderableSeries.add(volumeDispensed);
      // #endregion

      chartSynchroniser.addAxis(xAxis);
      chartSynchroniser.addSurface(sciChartSurface);
        
    return { sciChartSurface};
  }

  return {initChart, onDelete};
}

