import React, { ReactElement, useMemo } from "react";
import DeviceTable from "../Components/DeviceTable";
import { useQuery, useQueryClient } from "react-query";
import { ReactQueryKeys } from "../../../Constants/ClientRoutingConstants";
import { useToast } from "@chakra-ui/react";
import devicesApiService from "../Utilities/DevicesApiService";
import areasApiService from "../../Area/Utilities/AreasApiService";
import ErrorCard from "../../Shared/Components/Cards/ErrorCard";
import LoadingSpinner from "../../Shared/Components/LoadingSpinner";
import AreaReadDto from "../../../Models/Area/AreaReadDto";
import { DeviceReadDto } from "../../../Models/Device/DeviceReadDto";
import { ConsumableReadDto } from "../../../Models/Consumable/ConsumableReadDto";
import consumablesApiService from "../../Consumables/Utilities/ConsumablesApiService";
import { DeviceUpdateDto } from "../../../Models/Device/DeviceUpdateDto";
import { DeviceReadTableDto } from "../../../Models/Device/DeviceReadTableDto";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";
import { useWindowSize } from "usehooks-ts";
import { Dialog } from "@progress/kendo-react-dialogs";

interface Props {
  isOpenDialog: any;
  setIsOpenDialog: any;
}

export default function DevicesPage(props: Props): ReactElement {
  //Server side state
  const client = useQueryClient();
  const toast = useToast({
    position: "bottom",
    duration: 5000,
    isClosable: true,
  });

  const localizationService = useLocalization();

  //get window size
  const { width, height } = useWindowSize()

  const devicesQuery = useQuery<Array<DeviceReadDto>>(ReactQueryKeys.AllDevicesQuery, async () => {
    return devicesApiService.getAllDevices();
  });

  const consumablesQuery = useQuery<Array<ConsumableReadDto>>(ReactQueryKeys.AllConsumablesQuery, async () => {
    return consumablesApiService.getAllConsumables();
  });

  const areasQuery = useQuery<Array<AreaReadDto>>(ReactQueryKeys.AllAreasQuery, async () => {
    return areasApiService.getAllAreas();
  });

  //update Query
  const updateDevices = async (devices: Array<DeviceUpdateDto>) => {
    try {
      let response;
      if (devices.length === 0) {
        response = await devicesApiService.updateDevice(devices[0], devices[0].id);
      } else {
        response = await devicesApiService.updateDevices(devices);
      }
      //If update is successful, refetch the updated list of areas and show success message
      client.refetchQueries(ReactQueryKeys.AllDevicesQuery);
      toast({
        status: "success",
        description: `${localizationService.toLanguageString('custom.deviceUpdated', enMessages.custom.deviceUpdated)}`,
      });
      return true;
    } catch (e: any) {
      var errorMessage;
      if (devices.length === 1) {
        errorMessage = `${localizationService.toLanguageString('custom.deviceUpdatedError', enMessages.custom.deviceUpdatedError)}\n
        ${e?.response?.data?.errors ? Object.values(e?.response?.data?.errors)[0] : ""}`;
      } else {
        errorMessage = `${localizationService.toLanguageString('custom.deviceIncorrectInput', enMessages.custom.deviceIncorrectInput)}\n
          ${e?.response?.data}
        `;
      }
      toast({
        status: "error",
        description: errorMessage,
      });

      return false;
    }
  };

  const deviceData = useMemo(() => {
    //Create hashmap of areaId -> name pairs.
    const areaIdtoName: { [id: string]: string } = {};
    const consumableIdToName: { [id: string]: string } = {};
    areasQuery.data?.forEach((area) => {
      areaIdtoName[area.id] = area.name;
    });
    consumablesQuery.data?.forEach((consumable) => {
      consumableIdToName[consumable.id] = consumable.name;
    });

    //Map over machines to inject relevant data
    const results = devicesQuery.data?.map((device) => {
      return {
        ...device,
        consumableName: consumableIdToName[device.consumableId],
        areaName: areaIdtoName[device.areaId] ?? null,
      } as DeviceReadTableDto;
    });

    return results;
  }, [devicesQuery.data, areasQuery.data, consumablesQuery.data]);

  if (areasQuery.isLoading || consumablesQuery.isLoading || devicesQuery.isLoading || areasQuery.isFetching || consumablesQuery.isFetching || devicesQuery.isFetching) {
    return <LoadingSpinner />;
  } else {
    if (devicesQuery.error || consumablesQuery.isError || areasQuery.isError) {
      return <ErrorCard />;
    } else {
      return (
        <div>
          {/* Areas Table */}
          <div className="mt-4">
            <DeviceTable
              devices={deviceData!}
              consumables={consumablesQuery.data!}
              areas={areasQuery.data!}
              updateDevice={updateDevices} initialSort={undefined} initialSortDescriptor={undefined} handleSubmit={undefined}
              height={690}
            />
          </div>
          {props.isOpenDialog.devices && (
            <Dialog
              title={localizationService.toLanguageString('custom.devices', enMessages.custom.devices)}
              onClose={() => props.setIsOpenDialog({...props.isOpenDialog, devices: false})}
            >
              <DeviceTable
                devices={deviceData!}
                consumables={consumablesQuery.data!}
                areas={areasQuery.data!}
                updateDevice={updateDevices} initialSort={undefined} initialSortDescriptor={undefined} handleSubmit={undefined}
                height={height - 200}
              />
            </Dialog>
            )}
        </div>
      );
    }
  }
}
