import { ReactElement, useState, useRef} from "react";
import { IInitResult, SciChartGroup } from "scichart-react";
import { ModifierGroup } from "../../../../Shared/SciChart/ModifierGroup";
import { ChartModifierBase2D, ISciChartSurfaceBase, SciChartVerticalGroup } from "scichart";
import { ActivityAssetChart } from "../../../Components/Charts/Activity/ActivityAssetChart";
import { synchronizeXVisibleRanges } from "../../../../Shared/SciChart/ChartConfiguration";
import { StatusChart } from "../../../Components/Charts/Status/StatusChart";
import ChartWaitLoader from "../../../../Shared/Components/ChartWaitLoader";
import useChartStore from "../../../../../Zustand/chartStore";
import {GetVisibleRangeMultipleSeries } from "../../../../../Utilities/ChartUtilities";
import moment from "moment";


interface Props {
  statusChartData: any;
  activityDataChart: any;
}

export default function EndpointStatusAsset(props: Props): ReactElement {

  const [modifierGroup] = useState(new ModifierGroup());
  const [isChartsInitialised, setIsChartsInitialised] = useState(false);
  const verticalGroup = new SciChartVerticalGroup();

  // Fetch the initial state, but we don't need to re-render when it changes as the chart will update itself
  const visibleRangeRef = useRef(useChartStore.getState().visibleRange);
 
  // Calculate the visible range for the data
  let visibleRange = GetVisibleRangeMultipleSeries(
    visibleRangeRef.current, 
    props.activityDataChart.dataChart, 
    moment(props.activityDataChart.dateFilter.startDate).unix(), 
    moment(props.activityDataChart.dateFilter.endDate).unix());

  const statusChartData = {...props.statusChartData, ...{modifierGroup: modifierGroup}, visibleRange};
  const activityDataChart = {...props.activityDataChart, ...{modifierGroup: modifierGroup}, visibleRange};
 
  const configureStatusAndAssetDataBindings = (initResults: IInitResult<ISciChartSurfaceBase>[] | [any, any]) => {
    const [statusChart, activityChart] = initResults;
    
    modifierGroup.clear();

    var statusRollOverModifier = statusChart.sciChartSurface.chartModifiers.getById("StatusRollOver");
    modifierGroup.add(statusRollOverModifier as ChartModifierBase2D);

    const activityModifierGroupModifier = activityChart.sciChartSurface.chartModifiers.getById("AssetModifierGroup");
    const activityRollOverModifier = activityChart.sciChartSurface.chartModifiers.getById("AssetRollOver");
    modifierGroup.add(activityModifierGroupModifier as ChartModifierBase2D, activityRollOverModifier as ChartModifierBase2D);

    synchronizeXVisibleRanges([statusChart.sciChartSurface, activityChart.sciChartSurface], () => true);

    verticalGroup.addSurfaceToGroup(statusChart.sciChartSurface);
    verticalGroup.addSurfaceToGroup(activityChart.sciChartSurface);
  };

  return (
    <>
      <SciChartGroup
        onInit={(initResults: IInitResult[]) => {
         configureStatusAndAssetDataBindings(initResults);
          setIsChartsInitialised(true);
        }}
      >
        {isChartsInitialised ? null : <ChartWaitLoader />}
        <StatusChart key={"status-chart-asset"} chartData={statusChartData}/>
        
        <ActivityAssetChart key={"asset-chart"} chartData={activityDataChart}/>
        <div className="flex flex-wrap">
          <div id="legend-asset"></div>
          <div id="legend-status"></div>
        </div>
      </SciChartGroup>
    </>
  )
}