//Within this page we expose non secret constants for backend api routing.

export const BaseUrl = process.env.REACT_APP_BACKEND_URL

export const IdentityRoutes = {
    Login : "/account/login",
    LoginQueryParameters: {
        ReturnUrl: "returnUrl"
    },
    ChangeOrganisation : "/organisation/change"
}

export const ApiRoutes = {
    //Areas Base
    Areas: "api/assets/areas",

    //Machines Base
    Machines: "api/assets/machines",

    //Points Base
    Points: "api/assets/points",

    //Consumables Base
    Consumables: "api/assets/consumables",

    //Devices Base
    Devices: "api/devices",
    ManageDevices: "api/devices/admin",

    //Alerts
    Alerts: "web/v1/alerts",
    EndpointsV1: "web/v1/endpoints",

    //Notifications
    NotificationsSummary: "web/v1/notifications/summary",
    Notifications: "web/v1/notifications",

    //Endpoints
    Endpoints: "api/assets/endpoints",
    ManageEndpoints: "api/devices/endpoints/admin",
    SiteEndpointDevices: "api/devices/endpoints",
    EndpointsCompliance: "api/analysis/endpoint/compliance/",
    ImportEndpointCSV: "api/devices/endpoints/import/csv",

    //Reservoirs
    GreaseReservoirs: "api/assets/reservoirs/grease",
    GreaseReservoirsReset: "api/assets/reservoirs/grease/reset",
    GreaseReservoirsLink: "api/assets/reservoirs/grease/link",
    GreaseReservoirsLinkedPoints: "api/assets/reservoirs/grease/linkedPoints",
    GreaseReservoirsHistory: "api/assets/reservoirs/grease/history",

    //Requirements
    Requirement: "api/requirement/endpoint",

    //Analysis
    GreaseReservoirsAnalysis: "api/analysis/reservoir/grease",

    //Treelist
    TreeList: "api/assets/site/treelist",

    //Charts
    PointsActivityChart: "api/events",
    EndpointsActivityChart: "api/endpoints/flow/chart-data",
    EndpointsEvents: "api/endpoints/flow/events",
    EndpointsMetricsChart: "api/endpoints/metrics",
    EndpointsDiagnosticsChart: "api/endpoints/diagnostics",
    EndpointsLastSeen: "api/endpoints/last-seen",
    EndpointsEventsBff: "web/v1/endpoint/events/flow",

    //Integration Base
    ImportXlsx: "api/import/xlsx",
    ExportXlsx: "api/export/xlsx",
    ExportXlsxTemplate: "api/export/xlsx/template",
    ExportXlsxTemplateCUID: "api/export/xlsx/cuid-template",

    //Schedules Base
    Schedules: "api/schedules",

    //Actions Base
    Actions: "api/report/compliance/site/actions",
    EventsPerAction: "api/report/compliance/site/action/events",

    //Tasks Base
    Tasks: "api/tasks",

    //Events Base
    Events: "api/events",

    //System Base
    AlertJobs: "/web/v1/alerts/hangfire",

    //Reports Base
    ReportCompliance: "/api/report/compliance/site/actions",
    ReportSchedules: "/api/report/schedules",
    PendingActionsReport: "/api/report/compliance/site/actions/pending",
    ReportSendEmail: "api/report/email/compliance",

    //Auth-Tokens
    AuthTokens: "api/settings/auth-token",

    //Dashboard
    Dashboard: "web/v1/dashboard",
    AccountSettings: "web/v1/settings/account",

    //Super Administration
    SuperAdministration : "/admin",
    SuperAdministrationRoutes: {
        GetAllSites: "/sites",
        CreateSite: "/sites",
        UpdateSite: "/sites",
        GetAllUsers: "/users",
        GetAllRoles : "/roles",
        PostInviteUser: "/users/invite",
        PostResendUserInvite: "/users/resendInvite",
        DeleteUser : "/users/delete"
    },

    //Site Administration
    SiteAdministration: "/site",
    SiteAdministrationRoutes: {
        GetAllUsers: "/users",
        GetAllRoles : "/roles",
        PostInviteUser: "/users/invite",
        PostResendUserInvite: "/users/resendInvite",
        DeleteUser : "/users/delete"
    }

}