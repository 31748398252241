import { ReactElement, useEffect, useState } from "react";
//@ts-ignore
import ConsumablesPage from "../../Consumables/Pages/ConsumablesPage";
import AreasPage from "../../Area/Pages/AreasPage";
import MachinesPage from "../../Machines/Pages/MachinesPage";
import PointsPage from "../../Points/Pages/PointsPage";
import DevicesPage from "../../Devices/Pages/DevicesPage";
import SchedulesPage from "../../Schedules/Pages/SchedulesPage";
import useSiteSetupStore from "../../../Zustand/siteSetupStore";
import { useLocation } from "react-router-dom";
import SiteSetupToolbar from "./SiteSetupToolbar";
import RouteEditorPage from "../../RouteEditor/Pages/RouteEditorPage";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";
import EndpointsPage from "../../Endpoints/Pages/EndpointsPage";
import ReservoirsPage from "../../Reservoir/Pages/ReservoirsPage";
import RequirementPage from "../../Requirements/Pages/RequirementPage";
import { SvgIcon } from '@progress/kendo-react-common';
import * as svgIcons from '@progress/kendo-svg-icons';

interface Props {}
export default function SiteSetupPage({}: Props): ReactElement {
  const { assetsOpen, scheduleOpen, inventoryOpen } = useSiteSetupStore();
  const location = useLocation();
  const menuIndexs = {
    Inventory: 0,
    Schedule: 1,
    Assets: 2,
  };

  const localizationService = useLocalization();

  useEffect(() => {
    var index : number[] = [];
    if (assetsOpen) index.push(menuIndexs.Assets);
    if (scheduleOpen) index.push(menuIndexs.Schedule);
    if (inventoryOpen) index.push(menuIndexs.Inventory);
  }, [assetsOpen, scheduleOpen, inventoryOpen]);

  useEffect(() => {
    //Pause for accordion to open... (a little dodgy)
    setTimeout(() => {
      //Look for hash, scroll to the corresponding div
      const offsetDueToHeader = 80;
      const elementLocation =
        window.document.getElementById(location.hash.replace("#", ""))?.offsetTop! - offsetDueToHeader;
      window.scrollTo({
        top: elementLocation,
        behavior: "smooth",
      });
    }, 500);
  }, [location.hash]);

  const [isOpenDialog, setIsOpenDialog] = useState({
    consumables: false,
    areas: false,
    machines: false,
    points: false,
    devices: false,
    schedules: false,
    endpoints: false,
    reservoirs: false,
    requirements: false,
    routeEditor: false
  });
  

  return (
    <>
      <div className="p-5">
        <div className="w-full z-0">
          {/* Toolbar */}
          <SiteSetupToolbar />
        </div>
        <div className="flex-1">
          {/* Header */}
          <div className="flex items-baseline gap-2">
            <h2 className="gb-h2">{localizationService.toLanguageString('custom.greaseTypes', enMessages.custom.greaseTypes)}</h2>
            <SvgIcon icon={svgIcons.hyperlinkOpenIcon} onClick={() => setIsOpenDialog({...isOpenDialog, consumables:true})} style={{cursor: "pointer"}} />
          </div>
          <div className="gb-text-normal">
          {localizationService.toLanguageString('custom.greaseTypesText', enMessages.custom.greaseTypesText)}
          </div>
        </div>
        {/* Consumables Page */}
        <div id="inventory" className="mt-6">
          <ConsumablesPage isOpenDialog={isOpenDialog} setIsOpenDialog={setIsOpenDialog} />
        </div>
        <div className="flex-1 my-5">
          <div className="flex items-baseline gap-2">
            <h2 className="gb-h2">{localizationService.toLanguageString('custom.locations', enMessages.custom.locations)}</h2>
            <SvgIcon icon={svgIcons.hyperlinkOpenIcon} onClick={() => setIsOpenDialog({...isOpenDialog, areas:true})} style={{cursor: "pointer"}} />
          </div>
          <div className="gb-text-normal">
          {localizationService.toLanguageString('custom.locationsText', enMessages.custom.locationsText)}
          </div>
        </div>
        {/* Locations Page */}
        <div id="areas">
          <AreasPage isOpenDialog={isOpenDialog} setIsOpenDialog={setIsOpenDialog} />
        </div>
        {/* Machines Page */}
        <div className="flex-1 my-5">
          <div className="flex items-baseline gap-2">
            <h2 className="gb-h2">{localizationService.toLanguageString('custom.assets', enMessages.custom.assets)}</h2>
            <SvgIcon icon={svgIcons.hyperlinkOpenIcon} onClick={() => setIsOpenDialog({...isOpenDialog, machines:true})} style={{cursor: "pointer"}} />
          </div>
          <div className="gb-text-normal">
          {localizationService.toLanguageString('custom.assetsText', enMessages.custom.assetsText)}
          </div>
        </div>
        <div id="machines" className="mt-6">
          <MachinesPage isOpenDialog={isOpenDialog} setIsOpenDialog={setIsOpenDialog} />
        </div>
        {/* Reservoir Page */}
        <div className="flex-1 my-5">
          <div className="flex items-baseline gap-2">
            <h2 className="gb-h2">{localizationService.toLanguageString('custom.reservoirs', enMessages.custom.reservoirs)}</h2>
            <SvgIcon icon={svgIcons.hyperlinkOpenIcon} onClick={() => setIsOpenDialog({...isOpenDialog, reservoirs:true})} style={{cursor: "pointer"}} />
          </div>
          <div className="gb-text-normal">
          {localizationService.toLanguageString('custom.reservoirText', enMessages.custom.reservoirText)}
          </div>
        </div>
        <div id="reservoir" className="mt-6">
          <ReservoirsPage isOpenDialog={isOpenDialog} setIsOpenDialog={setIsOpenDialog} />
        </div>
        <div className="flex-1 my-5">
          <div className="flex items-baseline gap-2">
            <h2 className="gb-h2">{localizationService.toLanguageString('custom.schedules', enMessages.custom.schedules)}</h2>
            <SvgIcon icon={svgIcons.hyperlinkOpenIcon} onClick={() => setIsOpenDialog({...isOpenDialog, schedules:true})} style={{cursor: "pointer"}} />
          </div>
          <div className="gb-text-normal">
          {localizationService.toLanguageString('custom.schedulesText', enMessages.custom.schedulesText)}
          </div>
        </div>
        {/* Schedules Page */}
        <div id="schedules" className="mt-6">
          <SchedulesPage isOpenDialog={isOpenDialog} setIsOpenDialog={setIsOpenDialog} />
        </div>
        {/* Points Page */}
        <div className="flex-1 my-5">
          <div className="flex items-baseline gap-2">
            <h2 className="gb-h2">{localizationService.toLanguageString('custom.greaseTags', enMessages.custom.greaseTags)}</h2>
            <SvgIcon icon={svgIcons.hyperlinkOpenIcon} onClick={() => setIsOpenDialog({...isOpenDialog, points: true})} style={{cursor: "pointer"}} />
          </div>
          <div className="gb-text-normal">
          {localizationService.toLanguageString('custom.greaseTagsText', enMessages.custom.greaseTagsText)}
          </div>
        </div>
        <div id="greaseTags" className="mt-6">
          <PointsPage isOpenDialog={isOpenDialog} setIsOpenDialog={setIsOpenDialog} />
        </div>
        {/* Route Editor Page */}
        <div className="flex-1 my-5">
          <div className="flex items-baseline gap-2">
            <h2 className="gb-h2">{localizationService.toLanguageString('custom.routeEditor', enMessages.custom.routeEditor)}</h2>
            <SvgIcon icon={svgIcons.hyperlinkOpenIcon} onClick={() => setIsOpenDialog({...isOpenDialog, routeEditor: true})} style={{cursor: "pointer"}} />
          </div>
          <div className="gb-text-normal">
          {localizationService.toLanguageString('custom.routeEditorText', enMessages.custom.routeEditorText)}
          </div>
        </div>
        <div id="routeEditor" className="mt-6">
          <RouteEditorPage isOpenDialog={isOpenDialog} setIsOpenDialog={setIsOpenDialog} />
        </div>
        {/* Devices Page */}
        <div className="flex-1 my-5">
          <div className="flex items-baseline gap-2">
            <h2 className="gb-h2">{localizationService.toLanguageString('custom.devices', enMessages.custom.devices)}</h2>
            <SvgIcon icon={svgIcons.hyperlinkOpenIcon} onClick={() => setIsOpenDialog({...isOpenDialog, devices: true})} style={{cursor: "pointer"}} />
          </div>
          <div className="gb-text-normal">{localizationService.toLanguageString('custom.devicesText', enMessages.custom.devicesText)}</div>
        </div>
        <div id="devices" className="mt-6">
          <DevicesPage isOpenDialog={isOpenDialog} setIsOpenDialog={setIsOpenDialog} />
        </div>
         {/* Requirements Page */}
        <div className="flex-1 my-5">
          <div className="flex items-baseline gap-2">
            <h2 className="gb-h2">{localizationService.toLanguageString('custom.requirements', enMessages.custom.requirements)}</h2>
            <SvgIcon icon={svgIcons.hyperlinkOpenIcon} onClick={() => setIsOpenDialog({...isOpenDialog, requirements: true})} style={{cursor: "pointer"}} />
          </div>
        <div className="gb-text-normal">{localizationService.toLanguageString('custom.requirementsText', enMessages.custom.requirementsText)}</div>
        </div>
        <div id="requirements" className="mt-6">
          <RequirementPage isOpenDialog={isOpenDialog} setIsOpenDialog={setIsOpenDialog} />
        </div>
        {/* Endpoints Page */}
        <div className="flex-1 my-5">
          <div className="flex items-baseline gap-2">
            <h2 className="gb-h2">{localizationService.toLanguageString('custom.endpoints', enMessages.custom.endpoints)}</h2>
            <SvgIcon icon={svgIcons.hyperlinkOpenIcon} onClick={() => setIsOpenDialog({...isOpenDialog, endpoints: true})} style={{cursor: "pointer"}} />
          </div>
          <div className="gb-text-normal">{localizationService.toLanguageString('custom.endpointsText', enMessages.custom.endpointsText)}</div>
        </div>
        <div id="endpoints" className="mt-6">
          <EndpointsPage isOpenDialog={isOpenDialog} setIsOpenDialog={setIsOpenDialog} />
        </div>
      </div>
    </>
  );
}
