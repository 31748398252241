import { ApiRoutes } from "../../../Constants/ApiRoutingConstants";
import axios, { AxiosInstance } from "axios";
import authorizeService from "../../Identity/Utilities/AuthorizeService";
import EventReadDto from "../../../Models/Event/EventReadDto";
/**
 * This service provides implementations for http calls to the api pertaining to event crud functionality
 */

export interface EventFilter {
  startDate?: Date | null;
  endDate?: Date | null;
}

class EventsApiService {
  axiosInstance: AxiosInstance;
  constructor() {
    //Subscribe to identity store the get token
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_EVENT_SERVICE_URL,
    });
  }

  async getAllPointsEvents(eventFilters: EventFilter) {
    //Covnert EventFilters to use Iso date strings
    const isoEventFilters = {...eventFilters, 
      startDate : eventFilters.startDate?.toISOString(),
      endDate : eventFilters.endDate?.toISOString()}

    const response = await this.axiosInstance({
      method: "GET",
      //@ts-ignore
      url: `${ApiRoutes.Events}?${new URLSearchParams(isoEventFilters).toString()}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
  let eventsData = response.data as Array<EventReadDto>
    eventsData.forEach(item => {
      item.occurred= new Date(item.occurred)
      item.created = new Date(item.created)
    })

    return eventsData;
  }

  async getAllEndpointsEvents(eventFilters: EventFilter) {
    //Covnert EventFilters to use Iso date strings
    const isoEventFilters = {...eventFilters, 
      startDate : eventFilters.startDate?.toISOString(),
      endDate : eventFilters.endDate?.toISOString()}

    const response = await this.axiosInstance({
      method: "GET",
      //@ts-ignore
      url: `${ApiRoutes.EndpointsEvents}?${new URLSearchParams(isoEventFilters).toString()}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    let eventsData = response.data
    eventsData.forEach(item => {
    item.timestamp= new Date(item.timestamp)
    item.synced = new Date(item.synced)
  })

    return eventsData;
  }

  async getAllFlowRateEvents(eventFilters: EventFilter) {
    //Covnert EventFilters to use Iso date strings
    const isoEventFilters = {...eventFilters, 
      startDate : eventFilters.startDate?.toISOString(),
      endDate : eventFilters.endDate?.toISOString()}

    const response = await this.axiosInstance({
      method: "GET",
      //@ts-ignore
      url: `${ApiRoutes.EndpointsV1}/events/flow-rate?${new URLSearchParams(isoEventFilters).toString()}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });

    return response.data;
  }

  async getAllGpsEvents(eventFilters: EventFilter) {
    //Covnert EventFilters to use Iso date strings
    const isoEventFilters = {...eventFilters, 
      startDate : eventFilters.startDate?.toISOString(),
      endDate : eventFilters.endDate?.toISOString()}

    const response = await this.axiosInstance({
      method: "GET",
      //@ts-ignore
      url: `${ApiRoutes.EndpointsV1}/events/gps?${new URLSearchParams(isoEventFilters).toString()}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });

    return response.data;
  }

  async getAllTemperatureEvents(eventFilters: EventFilter) {
    //Covnert EventFilters to use Iso date strings
    const isoEventFilters = {...eventFilters, 
      startDate : eventFilters.startDate?.toISOString(),
      endDate : eventFilters.endDate?.toISOString()}

    const response = await this.axiosInstance({
      method: "GET",
      //@ts-ignore
      url: `${ApiRoutes.EndpointsV1}/events/temperature?${new URLSearchParams(isoEventFilters).toString()}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });

    return response.data;
  }

}



const eventsApiService = new EventsApiService();
export default eventsApiService;
