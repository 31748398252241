import { ReactElement, useMemo, useState } from "react";
import AdministrationDeviceTable from "../Components/Tables/AdministrationDeviceTable";
import devicesApiService from "../../Devices/Utilities/DevicesApiService";
import sitesApiService from "../../Administration/Utilities/AdministrationAPIService";
import { ReactQueryKeys } from "../../../Constants/ClientRoutingConstants";
import DeviceCreatedModal from "../Components/Modals/DeviceCreatedModal";
import { useQuery } from "react-query";
import AdminDeviceReadDto from "../../../Models/Device/AdminDeviceReadDto";
import ErrorCard from "../../Shared/Components/Cards/ErrorCard";
import LoadingSpinner from "../../Shared/Components/LoadingSpinner";
import { SiteReadDto } from "../../../Models/Site/SiteReadDto";
import AdminDeviceUpdateDto from "../../../Models/Device/AdminDeviceUpdateDto";
import { useToast } from "@chakra-ui/react";
import AdminDeviceReadTableDto from "../../../Models/Device/AdminDeviceReadTableDto";
import { useLocalization } from '@progress/kendo-react-intl';
import {enMessages} from '../../../messages/en-US';
import { useWindowSize } from "usehooks-ts";

interface Props {}

/**
 * This component facilitates the device registration flow for super admin users - here they can view all devices registered in the system, assign them
 * to a site, and eventually manage firmware updates etc.
 */
export default function SuperAdministrationDevicesPage({}: Props): ReactElement {
  const toast = useToast({
    position: "bottom",
    duration: 5000,
    isClosable: true,
  });
  const localizationService = useLocalization();
  //get window size
  const { width, height } = useWindowSize()
  
  const devicesQuery = useQuery<Array<AdminDeviceReadDto>>(ReactQueryKeys.ManageDevicesQuery, async () => {
    return devicesApiService.getAllDevicesManage();
  });
  const sitesQuery = useQuery<Array<SiteReadDto>>(ReactQueryKeys.AllSitesQuery, async () => {
    return sitesApiService.getAllSites();
  });

  const [newDeviceCredentials, setNewDeviceCredentials] = useState<{
    guid: string;
    key: string;
  } | null>(null);

  const createDevice = async (values: any, setStatus: any) => {
    // Attempt to create the machine
    try {
      // const castValues = deviceValidationSchema.cast(values) as DeviceCreateDto;
      //Submit values to api
      const result = await devicesApiService.createDevice(values);
      await devicesQuery.refetch();

      //Set credentials for display
      setNewDeviceCredentials({
        guid: result.guid,
        key: result.apiKey,
      });

      //On success, show success toast, then refresh data
      toast({
        status: "success",
        description: `${localizationService.toLanguageString('custom.deviceCreated', enMessages.custom.deviceCreated)},`
      });
      return true;
    } catch (e: any) {
      //On failure show error toast, set status messages
      setStatus(e?.response?.data?.errors);

      toast({
        title: `${localizationService.toLanguageString('custom.error', enMessages.custom.error)}`,
        status: "error",
        description: `${localizationService.toLanguageString('custom.deviceCreatedError', enMessages.custom.deviceCreatedError)}`,
      });
      return false;
    }
  };

  const deleteDevices = async (deleteIds: Array<string>) => {
    try {
      const result = await devicesApiService.deleteDevices(deleteIds);
      devicesQuery.refetch();
      toast({
        title: `${localizationService.toLanguageString('custom.success', enMessages.custom.success)}`,
        status: "success",
        description:`${localizationService.toLanguageString('custom.deviceDeleted', enMessages.custom.deviceDeleted)}`,
      });
    } catch (e: any) {
      toast({
        title: `${localizationService.toLanguageString('custom.error', enMessages.custom.error)}`,
        status: "error",
        description: `${localizationService.toLanguageString('custom.deviceDeletedError', enMessages.custom.deviceDeletedError)}`,
      });
    }
  };

  const updateDevices = async (devices: Array<AdminDeviceUpdateDto>) => {
    try {
      let response;
      if (devices.length === 1) {
        response = await devicesApiService.updateDeviceSuper(devices[0], devices[0].id);
      } else if (devices.length > 1) {
        response = await devicesApiService.updateDevicesSuper(devices);
      }
      //If update is successful, refetch the updated list of areas and show success message
      devicesQuery.refetch();
      toast({
        status: "success",
        description: `${localizationService.toLanguageString('custom.deviceUpdated', enMessages.custom.deviceUpdated)}`,
      });
      return true;
    } catch (e: any) {
      var errorMessage;
      if (devices.length === 1) {
        errorMessage = `${localizationService.toLanguageString('custom.deviceUpdatedError', enMessages.custom.deviceUpdatedError)}\n
        ${e?.response?.data?.errors ? Object.values(e?.response?.data?.errors)[0] : ""}`;
      } else {
        errorMessage = `${localizationService.toLanguageString('custom.deviceIncorrectInput', enMessages.custom.deviceIncorrectInput)}\n
          ${e?.response?.data?.map((consumableId: string) => devices.find((a) => a.id === consumableId)?.id)}
        `;
      }
      toast({
        status: "error",
        description: errorMessage,
      });
      return false;
    }
  };

  const deviceData = useMemo(() => {
    //Create hashmap of areaId -> name pairs.
    const siteIdToName: { [id: string]: string } = {};
    sitesQuery.data?.forEach((site) => {
      siteIdToName[site.id] = site.name;
    });

    //Map over machines to inject relevant data
    return devicesQuery.data?.map((device) => {
      return { ...device, siteName: siteIdToName[device.siteId] };
    }) as Array<AdminDeviceReadTableDto>;
  }, [sitesQuery.data, devicesQuery.data]);

  if (devicesQuery.isLoading || sitesQuery.isLoading || devicesQuery.isFetching || sitesQuery.isFetching) {
    return <LoadingSpinner />;
  }
  if (devicesQuery.error || sitesQuery.error) {
    return <ErrorCard />;
  } else {
    return (
      <div className="m-5">
        <h1 className="gb-h1">{localizationService.toLanguageString('custom.manageDevices', enMessages.custom.manageDevices)}</h1>
        <h2 className="gb-text-normal mb-6">
        {localizationService.toLanguageString('custom.manageDevicesText', enMessages.custom.manageDevicesText)}
        </h2>

        <AdministrationDeviceTable
          createDevice={createDevice}
          deleteDevices={deleteDevices}
          updateDevices={updateDevices}
          devices={deviceData!}
          sites={sitesQuery.data!} extraInlineActions={null} initialSort={undefined} initialSortDescriptor={undefined}  height={height - 200}      />
        {/* On successful identity creation, show the credentials and offer to export as .csv */}
        <DeviceCreatedModal
          newDeviceCredentials={newDeviceCredentials}
          setNewDeviceCredentials={setNewDeviceCredentials}
        />
      </div>
    );
  }
}
