import { useToast } from "@chakra-ui/react";
import { Field, FieldProps, Form, Formik } from "formik";
import { useState } from "react";
import FieldErrorMessage from "../../Shared/Components/Forms/TableErrorMessage";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button, Chip } from "@progress/kendo-react-buttons";
import { ComboBox, DropDownList, } from "@progress/kendo-react-dropdowns";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";
import { Checkbox, NumericTextBox } from "@progress/kendo-react-inputs";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import { ReservoirResetDto } from "../../../Models/Reservoirs/ReservoirResetDto";
import ReservoirResetValidationSchema from "../Utilities/ReservoirResetValidationSchema";
import { FormTextArea } from "../../Shared/Components/Forms/FormsComponents";

interface Props {
  onClose: () => void;
  onReset: (reservoirResetDto: ReservoirResetDto) => void; 
  resetReservoir: boolean
  reservoir: any
}

export default function ReservoirResetModal(props: Props) {
  const toast = useToast({
    position: "bottom",
    duration: 5000,
    isClosable: true,
  });
  const localizationService = useLocalization()
  const [capacityUnit, setCapacityUnit] = useState<string>(props.reservoir.displayUnit);
  const [override, setOverride] = useState<boolean>();

  const handleSubmit = async (values: any, setStatus: any) => {
      const castValues = {
        resetDate : values.resetDate,
        resetLevel: values.resetLevel,
        unit: values.unit,
        comment: values.comments
      }  as ReservoirResetDto

      props.onReset(castValues);
  };

  const initialValueGenerator = (update: boolean) => {
    return {
      resetDate: new Date,
      resetLevel: props.reservoir.capacity,
      unit: props.reservoir.displayUnit,
      comments: ''
    }
  };
  
  return (
      <div>
        <Dialog title={localizationService.toLanguageString('custom.resetReservoir', enMessages.custom.resetReservoir)} onClose={props.onClose} >
        <Formik
            onSubmit={async (values, { setStatus }) => {
              await handleSubmit(values, setStatus);
            }}
            validationSchema={ReservoirResetValidationSchema}
            initialValues={initialValueGenerator(true)}
          >
            {({ submitForm, values, initialValues, setFieldValue }) => (
              <>
                <Form
                  style={{
                    minWidth: "500px",
                  }}
                >
                  <div className="p-2 w-full">
                    <label htmlFor="date">{localizationService.toLanguageString('custom.resetDate', enMessages.custom.resetDate)}</label>

                    <DateTimePicker
                      defaultValue={initialValues.resetDate}
                      format={"dd/MM/yyyy HH:mm:ss"}
                      onChange={(date) => {
                        setFieldValue("resetDate", date.value);
                      }}
                    />
                  </div>
                    <div className="p-2">
                      <label htmlFor="capacity">{localizationService.toLanguageString('custom.resetLevel', enMessages.custom.resetLevel)}</label>
                      <div className="flex gap-1 items-center">
            
                      <NumericTextBox
                      style={{ width: "110px" }}
                        onChange={(x) => {setFieldValue("resetLevel", x.value)}}
                        min={0}
                        max={props.reservoir.capacity}
                        defaultValue={initialValues.resetLevel}
                        disabled={!override}
                      />
                      <FieldErrorMessage name="capacity" />
                      <DropDownList
                      style={{ width: "70px" }}
                        onChange={(x) => {
                          setCapacityUnit(x.value)
                          setFieldValue("unit", x.value)}}
                        data={["kg", "cc", "g"]}
                        defaultValue={initialValues.unit}
                        disabled={!override}
                      />
                      <Checkbox onChange={(x) =>  {
                        setOverride(x.value)
                        setFieldValue("override", x.value)}}/>
                      <div>{localizationService.toLanguageString('custom.override', enMessages.custom.override)}</div>
                      </div>
                      {capacityUnit != 'cc' && (
                      <div className="pt-2">{localizationService.toLanguageString('custom.noteReservoir', enMessages.custom.noteReservoir)}</div>)}
                    </div>
                  <div className="p-2">
                    
                    <Field
                      className="k-input k-input-md k-rounded-md k-input-solid h-8"
                      type="text"
                      name="comment"
                      placeholder={"Comments"}
                      autocomplete="off"
                      component={FormTextArea}
                      style={{height:'80px'}}
                      label={'Comments'}
                      defaultValue={initialValues.comments}
                      onChange={(x) => {
                        setFieldValue("comments", x.value)}}
                    />
                    <FieldErrorMessage name="desciption" />
                  </div>

                  
                </Form>

                <DialogActionsBar>
                  <Button
                    themeColor={"success"}
                    onClick={() => {
                      submitForm();
                    }}
                  >
                    {localizationService.toLanguageString('custom.save', enMessages.custom.save)}
                  </Button>
                  <Button onClick={props.onClose}>{localizationService.toLanguageString('custom.cancel', enMessages.custom.cancel)}</Button>
                </DialogActionsBar>
              </>
            )}
          </Formik>
           
   
        </Dialog>
    </div>
  )
}
