import { ApiRoutes } from "../../../Constants/ApiRoutingConstants";
import axios, { AxiosInstance } from "axios";
import authorizeService from "../../Identity/Utilities/AuthorizeService";

import { TreeListReadDto } from "../../../Models/TreeList/TreeListReadDto";
import { EventFilter } from "../../Events/Utilities/EventsApiService";
import EndpointReadDto from "../../../Models/Endpoint/EndpointReadDto";
import EndpointFeatureDto from "../../../Models/Endpoint/EndpointFeatureDto";
import EndpointPressureEventsDto from "../../../Models/Endpoint/EndpointPressureEventsDto";
import EndpointPressureEventsTableDto from "../../../Models/Endpoint/EndpointPressureEventsTableDto";
import EndpointFlowRateEventsDto from "../../../Models/Endpoint/EndpointFlowRateEventsDto";
import EndpointFlowRateEventsTableDto from "../../../Models/Endpoint/EndpointFlowRateEventsTableDto";
import EndpointGpsEventsTableDto from "../../../Models/Endpoint/EndpointGpsEventsTableDto";
import EndpointTemperatureEventsTableDto from "../../../Models/Endpoint/EndpointTemperatureEventsTableDto";

/**
 * This service provides implementations for http calls to the api pertaining to point crud functionality
 */

class TreeListApiService {
  axiosInstance: AxiosInstance;
  constructor() {
    //Subscribe to identity store the get token
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_ASSET_SERVICE_URL,
    });
  }

  async getAllTreeList() {
    let response = await this.axiosInstance({
      method: "GET",
      url: `${ApiRoutes.TreeList}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data as Array<TreeListReadDto>;
  }

  async getAllEndpoints() {
    let response = await this.axiosInstance({
      method: "GET",
      url: `${ApiRoutes.Endpoints}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data as Array<EndpointReadDto>
  }

  async getAllPointsEvents(eventFilters: EventFilter, pointId: string | undefined) {
    eventFilters.endDate?.setHours(23,59,59)
    eventFilters.startDate?.setHours(0,0,0)
    //Covnert EventFilters to use Iso date strings
    const isoEventFilters = {...eventFilters, 
      startDate : eventFilters.startDate?.toISOString(),
      endDate : eventFilters.endDate?.toISOString()}

    const response = await this.axiosInstance({
      method: "GET",
      //@ts-ignore
      url: `${ApiRoutes.PointsActivityChart}/${pointId}?${new URLSearchParams(isoEventFilters).toString()}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data;
  }

  async getAllEndpointsEvents(eventFilters: EventFilter, serialNumber: string | undefined) {
    eventFilters.endDate?.setHours(23,59,59)
    eventFilters.startDate?.setHours(0,0,0)
    //Covnert EventFilters to use Iso date strings
    const isoEventFilters = {...eventFilters, 
      startDate : eventFilters.startDate?.toISOString(),
      endDate : eventFilters.endDate?.toISOString()}

    const response = await this.axiosInstance({
      method: "GET",
      //@ts-ignore
      url: `${ApiRoutes.EndpointsEvents}/${serialNumber}?${new URLSearchParams(isoEventFilters).toString()}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });

    return response.data.sort((a,b) => a.timestamp > b.timestamp ? -1 : 1);
  }

  async getAllEndpointsActivityChart(eventFilters: EventFilter, serialNumber: string | undefined) {
    eventFilters.endDate?.setHours(23,59,59)
    eventFilters.startDate?.setHours(0,0,0)
    //Covnert EventFilters to use Iso date strings
    const isoEventFilters = {...eventFilters, 
      startDate : eventFilters.startDate?.toISOString(),
      endDate : eventFilters.endDate?.toISOString()}

    const response = await this.axiosInstance({
      method: "GET",
      //@ts-ignore
      url: `${ApiRoutes.EndpointsActivityChart}/${serialNumber}?${new URLSearchParams(isoEventFilters).toString()}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data;
  }

  async getAllEndpointsMetricsChart(eventFilters: EventFilter, serialNumber: string | undefined) {
    eventFilters.endDate?.setHours(23,59,59)
    eventFilters.startDate?.setHours(0,0,0)
    //Covnert EventFilters to use Iso date strings
    const isoEventFilters = {...eventFilters, 
      startDate : eventFilters.startDate?.toISOString(),
      endDate : eventFilters.endDate?.toISOString()}

    const response = await this.axiosInstance({
      method: "GET",
      // @ts-ignore
      url: `${ApiRoutes.EndpointsMetricsChart}/${serialNumber}?${new URLSearchParams(isoEventFilters).toString()}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });

    return response.data;
  }

  async getAllEndpointsDiagnosticsChart(eventFilters: EventFilter, serialNumber: string | undefined) {
    eventFilters.endDate?.setHours(23,59,59)
    eventFilters.startDate?.setHours(0,0,0)
    //Covnert EventFilters to use Iso date strings
    const isoEventFilters = {...eventFilters, 
      startDate : eventFilters.startDate?.toISOString(),
      endDate : eventFilters.endDate?.toISOString()}

    const response = await this.axiosInstance({
      method: "GET",
      // @ts-ignore
      url: `${ApiRoutes.EndpointsDiagnosticsChart}/${serialNumber}?${new URLSearchParams(isoEventFilters).toString()}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });

    return response.data;
  }

  async getEndpointsLastSeen(serialNumber: string | undefined) {
    const response = await this.axiosInstance({
      method: "GET",
      //@ts-ignore
      url: `${ApiRoutes.EndpointsLastSeen}/${serialNumber}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data;
  }

  async getAllEndpointsEventsFlowChart(eventFilters: EventFilter, endpointId: string | undefined, dataAggregation: string) {
    //Covnert EventFilters to use Iso date strings
    const isoEventFilters = {...eventFilters, 
      startDate : eventFilters.startDate?.toISOString(),
      endDate : eventFilters.endDate?.toISOString()}

    const response = await this.axiosInstance({
      method: "GET",
      // @ts-ignore
      url: `${ApiRoutes.EndpointsEventsBff}/chart-data/${endpointId}?${new URLSearchParams(isoEventFilters).toString()}&dataAggregation=${dataAggregation}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });

    // This is requried for the checkboxes to work on the charts
     const data = {
       ...response.data,
       'endpointId': endpointId
     }

    return data;
  }

  async getAllEndpointsEventsFlowTable(eventFilters: EventFilter, endpointId: string | undefined, dataAggregation: string) {
    //Covnert EventFilters to use Iso date strings
    const isoEventFilters = {...eventFilters, 
      startDate : eventFilters.startDate?.toISOString(),
      endDate : eventFilters.endDate?.toISOString()}

    const response = await this.axiosInstance({
      method: "GET",
      // @ts-ignore
      url: `${ApiRoutes.EndpointsEventsBff}/${endpointId}?${new URLSearchParams(isoEventFilters).toString()}&dataAggregation=${dataAggregation}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });

    return response.data;
  }

  async getEndpointPressureEventsChart( endpointId: string, eventFilters: EventFilter, dataAggregation: string): Promise<EndpointPressureEventsDto[]> {

    const urlParams = {
      ...eventFilters,
      startDate: eventFilters.startDate?.toISOString(),
      endDate: eventFilters.endDate?.toISOString(),
      dataAggregation: dataAggregation,
    };

    const response = await this.axiosInstance({
      method: "GET",
      //@ts-ignore
      url: `${ApiRoutes.EndpointsV1}/events/pressure/chart-data/${endpointId}?${new URLSearchParams(urlParams).toString()}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });

    return response.data as EndpointPressureEventsDto[];
  }
  

  async getEndpointPressureEventsTable( endpointId: string, eventFilters: EventFilter, dataAggregation: string): Promise<EndpointPressureEventsTableDto[]> {

    const urlParams = {
      ...eventFilters,
      startDate: eventFilters.startDate?.toISOString(),
      endDate: eventFilters.endDate?.toISOString(),
      dataAggregation: dataAggregation,
    };

    const response = await this.axiosInstance({
      method: "GET",
      //@ts-ignore
      url: `${ApiRoutes.EndpointsV1}/events/pressure/${endpointId}?${new URLSearchParams(urlParams).toString()}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });

    return response.data;
  }

  async getEndpointFlowRateEventsTable( endpointId: string, eventFilters: EventFilter, dataAggregation: string): Promise<EndpointFlowRateEventsTableDto[]> {

    const urlParams = {
      ...eventFilters,
      startDate: eventFilters.startDate?.toISOString(),
      endDate: eventFilters.endDate?.toISOString(),
      dataAggregation: dataAggregation,
    };

    const response = await this.axiosInstance({
      method: "GET",
      //@ts-ignore
      url: `${ApiRoutes.EndpointsV1}/events/flow-rate/${endpointId}?${new URLSearchParams(urlParams).toString()}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });

    return response.data;
  }

  async getEndpointGpsEventsTable( endpointId: string, eventFilters: EventFilter, dataAggregation: string): Promise<EndpointGpsEventsTableDto[]> {

    const urlParams = {
      ...eventFilters,
      startDate: eventFilters.startDate?.toISOString(),
      endDate: eventFilters.endDate?.toISOString(),
      dataAggregation: dataAggregation,
    };

    const response = await this.axiosInstance({
      method: "GET",
      //@ts-ignore
      url: `${ApiRoutes.EndpointsV1}/events/gps/${endpointId}?${new URLSearchParams(urlParams).toString()}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });

    return response.data;
  }

  async getEndpointTemperatureEventsTable( endpointId: string, eventFilters: EventFilter, dataAggregation: string): Promise<EndpointTemperatureEventsTableDto[]> {

    const urlParams = {
      ...eventFilters,
      startDate: eventFilters.startDate?.toISOString(),
      endDate: eventFilters.endDate?.toISOString(),
      dataAggregation: dataAggregation,
    };

    const response = await this.axiosInstance({
      method: "GET",
      //@ts-ignore
      url: `${ApiRoutes.EndpointsV1}/events/temperature/${endpointId}?${new URLSearchParams(urlParams).toString()}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });

    return response.data;
  }

  async getEndpointTemperatureEventsChart( endpointId: string, eventFilters: EventFilter, dataAggregation: string): Promise<any> {

    const urlParams = {
      ...eventFilters,
      startDate: eventFilters.startDate?.toISOString(),
      endDate: eventFilters.endDate?.toISOString(),
      dataAggregation: dataAggregation,
    };

    const response = await this.axiosInstance({
      method: "GET",
      //@ts-ignore
      url: `${ApiRoutes.EndpointsV1}/events/temperature/chart-data/${endpointId}?${new URLSearchParams(urlParams).toString()}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });

    return response.data as [];
  }

  async getEndpointFlowRateEventsChart( endpointId: string, eventFilters: EventFilter, dataAggregation: string): Promise<EndpointFlowRateEventsDto[]> {

    const urlParams = {
      ...eventFilters,
      startDate: eventFilters.startDate?.toISOString(),
      endDate: eventFilters.endDate?.toISOString(),
      dataAggregation: dataAggregation,
    };

    const response = await this.axiosInstance({
      method: "GET",
      //@ts-ignore
      url: `${ApiRoutes.EndpointsV1}/events/flow-rate/chart-data/${endpointId}?${new URLSearchParams(urlParams).toString()}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });

    return response.data as EndpointFlowRateEventsDto[];
  }

  async getEndpointFeature( endpointId: string): Promise<EndpointFeatureDto> {

    const response = await this.axiosInstance({
      method: "GET",
      //@ts-ignore
      url: `${ApiRoutes.EndpointsV1}/features/${endpointId}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });

    return response.data as EndpointFeatureDto;
  }

  async getEndpointStatus( endpointId: string, eventFilters: EventFilter, yValue:number): Promise<[]> {

    const urlParams = {
      ...eventFilters,
      startDate: eventFilters.startDate?.toISOString(),
      endDate: eventFilters.endDate?.toISOString(),
      yValue: yValue,
    };

    const response = await this.axiosInstance({
      method: "GET",
      //@ts-ignore
      url: `web/v1/status/endpoint/${endpointId}?${new URLSearchParams(urlParams).toString()}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });

    return response.data as [];
  }


  async getAssetStatus( assetId: string, eventFilters: EventFilter, yValue:number): Promise<[]> {

    const urlParams = {
      ...eventFilters,
      startDate: eventFilters.startDate?.toISOString(),
      endDate: eventFilters.endDate?.toISOString(),
      yValue: yValue,
    };

    const response = await this.axiosInstance({
      method: "GET",
      //@ts-ignore
      url: `web/v1/status/assets/${assetId}?${new URLSearchParams(urlParams).toString()}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });

    return response.data as [];
  }

  async getEndpointNotifications( endpointId: string, eventFilters: EventFilter, yValue:number): Promise<[]> {

    const urlParams = {
      ...eventFilters,
      startDate: eventFilters.startDate?.toISOString(),
      endDate: eventFilters.endDate?.toISOString(),
      yValue: yValue,
    };

    const response = await this.axiosInstance({
      method: "GET",
      //@ts-ignore
      url: `${ApiRoutes.Notifications}/chart/${endpointId}?${new URLSearchParams(urlParams).toString()}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });

    return response.data as [];
  }

  async getEndpointsAssetNotifications( assetId: string, eventFilters: EventFilter, yValue:number): Promise<[]> {

    const urlParams = {
      ...eventFilters,
      startDate: eventFilters.startDate?.toISOString(),
      endDate: eventFilters.endDate?.toISOString(),
      yValue: yValue,
    };

    const response = await this.axiosInstance({
      method: "GET",
      //@ts-ignore
      url: `${ApiRoutes.Notifications}/assets/${assetId}/chart?${new URLSearchParams(urlParams).toString()}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });

    return response.data as [];
  }

  async updateStatus(assetId: string, status: any) {
    let response = await this.axiosInstance({
      method: "POST",
      url: `web/v1/status/assets/${assetId}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: status,
    });
    return response.data;
  }

}

const treeListApiService = new TreeListApiService();
export default treeListApiService;
