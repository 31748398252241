import { ApiRoutes } from "../../../Constants/ApiRoutingConstants";
import axios, { AxiosInstance } from "axios";
import authorizeService from "../../Identity/Utilities/AuthorizeService";
import { ReservoirReadDto } from "../../../Models/Reservoirs/ReservoirReadDto";
import { ReservoirCreateDto } from "../../../Models/Reservoirs/ReservoirCreateDto";
import { ReservoirUpdateDto } from "../../../Models/Reservoirs/ReservoirUpdateDto";
import { AlertReadTableDto } from "../../../Models/Alerts/AlertReadTableDto";
import { AlertCreateDto } from "../../../Models/Alerts/AlertCreateDto";
import { AlertUpdateDto } from "../../../Models/Alerts/AlertUpdateDto";
import { AlertReadDto } from "../../../Models/Alerts/AlertReadDto";
import EndpointWithRequirementReadDto from "../../../Models/Endpoint/EndpointWithRequirementReadDto";


/**
 * This service provides implementations for http calls to the api pertaining to alerts crud functionality
 */

class AlertsApiService {
  axiosInstance: AxiosInstance;
  constructor() {
    //Subscribe to identity store the get token
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_ASSET_SERVICE_URL,
    });
  }

  async getAllAlerts(): Promise<AlertReadTableDto[]> {
    let response = await this.axiosInstance({
      method: "GET",
      url: `${ApiRoutes.Alerts}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data;
  }

  async getAlert(alertId: string | null): Promise<AlertReadDto> {
    let response = await this.axiosInstance({
      method: "GET",
      url: `${ApiRoutes.Alerts}/${alertId}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data;
  }

  async createAlert(alert: AlertCreateDto) {
    let response = await this.axiosInstance({
      method: "POST",
      url: `${ApiRoutes.Alerts}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: alert,
    });
    return response.data as ReservoirReadDto;
  }

  async updateAlert(alert: AlertUpdateDto, alertId: string) {
    let response = await this.axiosInstance({
      method: "PUT",
      url: `${ApiRoutes.Alerts}/${alertId}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: alert,
    });
    return response.data;
  }

  async deleteAlert(alertId: string) {
    let response = await this.axiosInstance({
      method: "DELETE",
      url: `${ApiRoutes.Alerts}/${alertId}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      }
    });
    return response.data;
  }

  async resetAlert(alert: AlertUpdateDto, alertId: string) {
    let response = await this.axiosInstance({
      method: "PUT",
      url: `${ApiRoutes.Alerts}/${alertId}/reset`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: alert,
    });
    return response.data;
  }

  async getAllEndpointsWithRequirements(): Promise<EndpointWithRequirementReadDto[]> {
    let response = await this.axiosInstance({
      method: "GET",
      url: `${ApiRoutes.EndpointsV1}/tree-data?activeRequirementsOnly=true`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data;
  }

  static get instance() {
    return AlertsApiService;
  }
}

const alertsApiService = new AlertsApiService();
export default alertsApiService;
