import { ReactElement } from "react";
import { useQuery } from "react-query";
import { ReactQueryKeys } from "../../../Constants/ClientRoutingConstants";
import ErrorCard from "../../Shared/Components/Cards/ErrorCard";
import LoadingSpinner from "../../Shared/Components/LoadingSpinner";
import eventApiService from "../Utilities/EventsApiService";
import { useLocalization } from '@progress/kendo-react-intl';
import FlowRateEventTable from "../Components/FlowRateEventTable";
import FlowRateEventDto from "../../../Models/Endpoint/FlowRateEventDto";

interface Props {
  eventFilter: any
}

export default function FlowRateEventsPage(props: Props): ReactElement {

  const localizationService = useLocalization();
  const eventFilter = props.eventFilter

  const flowRateEventsQuery = useQuery<FlowRateEventDto[]>([ReactQueryKeys.AllFlowRateEventsQuery, eventFilter], async () => {
    return eventApiService.getAllFlowRateEvents(eventFilter);
  });
 
  if (flowRateEventsQuery.isLoading) {
    return <LoadingSpinner />;
  } else {
    if (flowRateEventsQuery.error ) {
      return <ErrorCard />;
    } else {
      return (
        <div className="">
          <FlowRateEventTable
            events={flowRateEventsQuery.data!}       
            />
         </div>
      );
    }
  }
}
