import * as yup from "yup";

export default yup.object().shape({
name: yup.string().min(2).max(48).required(),
//Transform parent to null if empty string
parentId: yup
    .string()
    .nullable()
    .min(2)
    .max(48)
    .transform((value, original) => {
        //We transform an empty string in parent to null.
        return value === "" ? null : value;
    }),
//Transform parent to null if empty string
areaId: yup
    .string()
    .nullable()
    .min(2)
    .max(48)
    .transform((value, original) => {
        //We transform an empty string in parent to null.
        return value === "" ? null : value;
    }),
description: yup.string(),
manufacturer: yup.string().min(2).max(48),
model: yup.string().min(2).max(48),
namePlate: yup.string(),
machineryType: yup.string(),
photoUrl: yup.string(),
status: yup.string().required(),
});
