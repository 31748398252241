import * as yup from "yup";
import { enMessages } from "../../../messages/en-US";


export default  yup.object().shape({
  type: yup.string(),
  name: yup.string().min(2).max(48).required(),
  photoUrl: yup.string().min(2).max(48),
  description: yup.string(),
  partNumber: yup.string(),
  manufacturer: yup.string(),
  cost: yup.string(),
});
