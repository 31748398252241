import { SciChartReact } from "scichart-react";
import React, {  } from "react";
import { getChartAPI } from "./ReservoirReportChartApi";
import { ReservoirChartData } from "./ReservoirReportChartApi";

export const ReservoirReportChart = (reservoir: ReservoirChartData) => {

  const [ReservoirReportChartApi] = React.useState(getChartAPI(reservoir));
  return (
    <div id={`res${reservoir.reservoirId}`} className="flex" style={{height: "20px"}}>
      <SciChartReact
        key={reservoir.reservoirId}
        initChart={ReservoirReportChartApi.initChart}
        style={{aspectRatio: 2, width:"100%", height: "20px"}}
        // customize size of the inner chart wrapper
        innerContainerProps={{ style: { height: "100%" } }}
        fallback={<div/>}
      >
      </SciChartReact>
    </div>
  );
}