import { ReactElement } from "react";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";
import { Icon } from "@progress/kendo-react-common";
import useIdentityStore from "../../../Zustand/identityStore";
import { Button } from "@progress/kendo-react-buttons";

export default function NoSettingsPage(): ReactElement {
  const localizationService = useLocalization();
  const userProfile = useIdentityStore((store) => store.user?.profile);

  const onGetInTouchClick = () => {
    window.open(`mailto:support@greaseboss.com.au?Subject=${userProfile?.current_site_name} Support Request`, "_blank", "noopener,noreferrer");
};


  return (
    <div className="gb-text-normal  text-lg text-center"
        style={{ borderRadius: '3px', padding: '40px 25px',margin: '30px auto'}}>
        <div className="text-center mb-5">
          <Icon name="k-icon k-i-warning" style={{fontSize:'72px'}}/>
        </div>
        <div>
        
            <div>{localizationService.toLanguageString('custom.settingsNotConfigured', enMessages.custom.settingsNotConfigured)}</div>
            <br></br>
            <div>{localizationService.toLanguageString('custom.contactSupport', enMessages.custom.contactSupport)}</div>
            <div>
            <Icon name="email" />
            <Button fillMode={"link"} style={{textDecoration:"underline", color: "blue"}} onClick={onGetInTouchClick}>support@greaseboss.com.au</Button> 
            </div>
              
        </div>
    </div> 
  )}
