import { Stack } from "@chakra-ui/react";
import DayPickerWeek from "./DayPickerWeek";
//Component renders Day Week Picker for 4 weeks of the month. 
//TODO: Try and make typesafe
interface Props {
  week1Selected: any;
  week2Selected: any;
  week3Selected: any;
  week4Selected: any;
  setWeek1Selected: any;
  setWeek2Selected: any;
  setWeek3Selected: any;
  setWeek4Selected: any;
}

function DayPickerMonth(props: Props) {
  return (
    <>
      <Stack spacing={4} align="center">
        <DayPickerWeek
          daysSelected={props.week1Selected}
          setDaysSelected={props.setWeek1Selected}
        />
        <DayPickerWeek
          daysSelected={props.week2Selected}
          setDaysSelected={props.setWeek2Selected}
        />
        <DayPickerWeek
          daysSelected={props.week3Selected}
          setDaysSelected={props.setWeek3Selected}
        />
        <DayPickerWeek
          daysSelected={props.week4Selected}
          setDaysSelected={props.setWeek4Selected}
        />
      </Stack>
    </>
  );
}

export default DayPickerMonth;
