import { Button } from "@progress/kendo-react-buttons";
import AlertsTable from "../Components/AlertsTable";
import { useEffect, useMemo, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import alertsApiService from "../Utilities/AlertsApiService";
import { ReactQueryKeys } from "../../../Constants/ClientRoutingConstants";
import { AlertReadTableDto } from "../../../Models/Alerts/AlertReadTableDto";
import { AlertCreateDto } from "../../../Models/Alerts/AlertCreateDto";
import { useToast } from "@chakra-ui/react";
import { AlertUpdateDto } from "../../../Models/Alerts/AlertUpdateDto";
import { useLocalization } from "@progress/kendo-react-intl";
import { enMessages } from "../../../messages/en-US";
import ErrorCard from "../../Shared/Components/Cards/ErrorCard";
import LoadingSpinner from "../../Shared/Components/LoadingSpinner";
import EditAlertViewModal from "../Components/EditAlertViewModal";
import AddAlertViewModal from "../Components/AddAlertViewModal";

interface Props {}

export default function AlertsPage(props: Props, ) {
  //Server side state
  const client = useQueryClient();
  const toast = useToast({
    position: "bottom",
    duration: 5000,
    isClosable: true,
  });
  const localizationService = useLocalization();


  const alertsQuery = useQuery<Array<AlertReadTableDto>>(ReactQueryKeys.AllAlertsQuery, async () => {
    return alertsApiService.getAllAlerts();
  });

  const createAlert = async (alert: AlertCreateDto) => {
    const result = await alertsApiService.createAlert(alert);
    client.refetchQueries(ReactQueryKeys.AllAlertsQuery);
  };

  const updateAlert = async (alertId: string, alert: AlertUpdateDto) => {
    let response = await alertsApiService.updateAlert(alert, alertId);
    //If update is successful, refetch the updated list of alerts and show success message
    client.invalidateQueries(ReactQueryKeys.AllAlertsQuery);
  };

  const resetAlert = async (alertId: string, alert: AlertUpdateDto) => {
    try {
      const result = await alertsApiService.resetAlert(alert, alertId);
      client.invalidateQueries(ReactQueryKeys.AllAlertsQuery);

      toast({
        status: "success",
        description: `Alert ${alert.name} was reset.`,
      });
    } catch (e) {
      toast({
        status: "error",
        description: `Alert ${alert.name} was not reset.`,
      });
    }
  };

  const deleteAlert = async (alertrId: string) => {
    try {
      let response = await alertsApiService.deleteAlert(alertrId);

      //If delete is successful, refetch the updated list of alerts and show success message
      client.refetchQueries(ReactQueryKeys.AllAlertsQuery);

      toast({
        status: "success",
        description: `${localizationService.toLanguageString('custom.alertSuccessfullydeleted', enMessages.custom.alertSuccessfullydeleted)}`,
      });
    } catch (e) {
      toast({
        status: "error",
        description: `${localizationService.toLanguageString('custom.errorDeletingAlert', enMessages.custom.errorDeletingAlert)}`,
      });
    }
  };

  const [isCreateAlert, setIsCreateAlert] = useState<boolean>(false);
  const [alertToUpdateId, setAlertToUpdateId] = useState<string | null>(null);
  const [alertToDelete, setAlertToDelete] = useState<AlertReadTableDto | null>(null);

  useEffect(() => {
    alertsQuery.refetch();
  }, [isCreateAlert, alertToUpdateId, alertToDelete]);
  
  const alertToUpdateHandler = (alert: any) => {
    setAlertToUpdateId(alert);
  }

  const onDelete = (alert) => {
    deleteAlert(alert!.id);
    setAlertToDelete(null);
  }
  
  const closeHandler = () => {
    setIsCreateAlert(false);
    setAlertToUpdateId(null);
    setAlertToDelete(null);
  };

  const alerts = useMemo(() => {
    if(alertsQuery.data === undefined) return new Array<AlertReadTableDto>();
    return alertsQuery.data.map((alert) => {
      return {
        ...alert,
        criticalityId: alert.criticality === "Critical" ? 1: alert.criticality === "High" ? 2 : alert.criticality === "Medium" ? 3 : 4
      };
    });
  }, [alertsQuery.data]);
    
  if (alertsQuery.isLoading || alertsQuery.isFetching) {
    return <LoadingSpinner />;
  } else {
  if (alertsQuery.error) {
    return <ErrorCard />;
  } else {
  return (
    <div className='m-5'>
      <div className="flex items-cente justify-between">
        <h2 className="gb-h2 mb-5">{localizationService.toLanguageString('custom.alerts', enMessages.custom.alerts)}</h2>
        <Button
          icon="plus"
          className="h-8 mr-2"
          themeColor={"primary"}
          onClick={() => setIsCreateAlert(true)}
        >{localizationService.toLanguageString('custom.createAlert', enMessages.custom.createAlert)}
        </Button>
      </div>
      <AlertsTable
        onClose={closeHandler}
        alertToUpdateHandler={alertToUpdateHandler}
        alerts={alerts}
        deleteAlert={deleteAlert}
        onDelete={onDelete}
        resetAlert={resetAlert}
      />

      {(isCreateAlert) && (
        <AddAlertViewModal onClose={closeHandler} createAlert={createAlert}/>
      )}

      {(alertToUpdateId !== null) && (
        <EditAlertViewModal onClose={closeHandler} alertId={alertToUpdateId} updateAlert={updateAlert}/>
      )}
    </div>
  );
}}}
