import { ReactElement, useEffect, useMemo, } from "react";
import { EventFilter } from "../../../../Events/Utilities/EventsApiService";
import { useQuery } from "react-query";
import moment from "moment";
import requirementApiService from "../../../../Requirements/Utilities/RequirementApiService";
import LoadingSpinner from "../../../../Shared/Components/LoadingSpinner";
import { RequirementAnalysis } from "../../../../../Models/Requirement/RequirementAnalysis";
import { rruleSummaryToText } from "../../../../Reporting/Utilities/ReportUtilities";
import { ConsumableReadDto } from "../../../../../Models/Consumable/ConsumableReadDto";
import consumablesApiService from "../../../../Consumables/Utilities/ConsumablesApiService";
import { ReactQueryKeys } from "../../../../../Constants/ClientRoutingConstants";
import { useLocalization } from "@progress/kendo-react-intl";
import { enMessages } from "../../../../../messages/en-US";
import NoRequirementPage from "../../NoRequirementPage";

import EndpointStatusCompliance from "./EndpointStatusCompliance";

import treeListApiService from "../../../Utilities/TreeListApiService";
import { ChartToolbar } from "../../../Utilities/ChartToolbar";


interface Props {
  eventFilter: EventFilter
  selectedItemId: string
  
}

export default function EndpointComplianceTabPage(props: Props): ReactElement {
  const localizationService = useLocalization();

  const requirementsQuery = useQuery<RequirementAnalysis>(["analysisRequirement", props.selectedItemId], async () => {
    return requirementApiService.getAnalysisRequirement(props.selectedItemId, props.eventFilter);
  });

  const consumablesQuery = useQuery<Array<ConsumableReadDto>>(ReactQueryKeys.AllConsumablesQuery, async () => {
    return consumablesApiService.getAllConsumables();
  });

  const notificationsQuery = useQuery<any>("notificationsStatus", async () => {
    return treeListApiService.getEndpointNotifications(props.selectedItemId, props.eventFilter, 1);
  });

  const statusQuery = useQuery<any>("statusChart", async () => {
    return treeListApiService.getEndpointStatus(props.selectedItemId, props.eventFilter, 0);
  });

  useEffect(() => {
    statusQuery.refetch();
  }, [props.eventFilter]);

  const requirementResult = useMemo(() => {
    const consumableIdToName: { [id: string]: string } = {};
    consumablesQuery.data?.forEach((consumable) => {
      consumableIdToName[consumable.id] = consumable.name;
    });
    //TODO: This is a hack to get around the fact that the API returns a 200 with an empty array when there is no requirement.
    if(requirementsQuery.data?.requirementName !== "") {

    return{
        timestamps: requirementsQuery.data?.results.length === 0 ? requirementsQuery.data?.results : requirementsQuery.data?.results?.map(x => moment(x.start).unix()),
        volumeDispensedData: requirementsQuery.data?.results.length === 0 ? requirementsQuery.data?.results : requirementsQuery.data?.results?.map(item => item.volumeDispensed),
        volumeRequirement: requirementsQuery.data?.requirementDetails?.rightAmountDetails?.volumeRequirement,
        thresholds: requirementsQuery.data?.requirementDetails?.rightAmountDetails?.thresholds,
        lastSeen: moment(requirementsQuery.data?.lastSeen).unix(),
        summary: requirementsQuery.data?.requirementDetails?.rightTimeDetails?.rRule === undefined || requirementsQuery.data?.requirementDetails?.rightTimeDetails?.rRule === '' ? "No Requirement" : rruleSummaryToText(requirementsQuery.data?.requirementDetails?.rightTimeDetails?.rRule),
        consumableName: requirementsQuery.data?.requirementDetails.rightGreaseDetails?.consumableId === undefined ? '' : consumableIdToName[requirementsQuery.data?.requirementDetails.rightGreaseDetails?.consumableId],
        startDate: moment(requirementsQuery.data?.requirementDetails?.rightTimeDetails?.startDate).unix() ,
        endDate: moment(requirementsQuery.data?.requirementDetails?.rightTimeDetails?.endDate).unix() === moment(new Date('0001-01-01T00:00:00Z')).unix() ? moment(props.eventFilter.endDate).unix() : moment(requirementsQuery.data?.requirementDetails?.rightTimeDetails?.endDate).unix(),
        unplannedEventsTimestamps: requirementsQuery.data?.unplannedEvents.map(x => moment(x.timestamp).unix()),
        unplannedEventsVolumes: requirementsQuery.data?.unplannedEvents.map(x => x.volume),
    }} else {
      return {
        timestamps: [],
        volumeDispensedData: [],
        volumeRequirement: 0,
        thresholds: [],
        lastSeen: moment().unix(),
        summary: '',
        consumableName: '',
        startDate: moment().unix(),
        endDate: moment().unix(),
        unplannedEvents: 0,
      }
    }
  },[requirementsQuery.data])


  useEffect(() => {
    requirementsQuery.refetch();
    statusQuery.refetch();
    notificationsQuery.refetch();
  }, [props.selectedItemId, props.eventFilter])

  const chartData = useMemo(() => {
    const lastSeen = requirementResult.lastSeen === 0 ? localizationService.toLanguageString('custom.noEndpointDeviceAssigned', enMessages.custom.noEndpointDeviceAssigned) : requirementResult.lastSeen < 0 ? localizationService.toLanguageString('custom.noEventsToDetermine', enMessages.custom.noEventsToDetermine) : moment.unix(requirementResult.lastSeen).format("DD/MM/YYYY @ HH:mm:ss");

    return {
      statusDataChart: {dateFilter: props.eventFilter, timestamps: requirementResult.timestamps, statusData:statusQuery.data,  alerts:notificationsQuery.data, },
      
      complianceDataChart: {dateFilter: props.eventFilter, strokeThickness: 1 , requirements:requirementResult,  lastSeen:moment(requirementsQuery.data?.lastSeen) ,lastSeenFormatted:lastSeen},

    }
  }, [notificationsQuery.data, statusQuery.data,requirementsQuery.data])

  if (requirementsQuery.isLoading || requirementsQuery.isFetching || consumablesQuery.isLoading || consumablesQuery.isFetching || notificationsQuery.isLoading || notificationsQuery.isFetching || statusQuery.isLoading || statusQuery.isFetching) {
    return <LoadingSpinner />;
  } else {
    if (requirementsQuery.isError || requirementsQuery.data?.requirementName === "" || notificationsQuery.isError || statusQuery.isError || consumablesQuery.isError) {
      return <NoRequirementPage />;
    } else { 
  return (
    <div>
      <ChartToolbar 
        title={localizationService.toLanguageString('custom.events', enMessages.custom.events)}
        lastSeen={chartData.complianceDataChart.lastSeenFormatted}
        showAutoRange={true} 
        showAggregatorOptions={false} 
        showChartOptions={true}
        showThresholds={true} 
        showSingleMultipleChart={false}
      />
      <EndpointStatusCompliance key={`${props.selectedItemId}PvAChart`} statusChartData={chartData.statusDataChart} complianceDataChart={chartData.complianceDataChart}/>
    </div>
  )
}}}