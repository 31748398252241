import { Stack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import SelectedWeeks from "../../../../Models/Requirement/SelectedWeeks";
import DayPickerWeek from "./DayPickerWeek";

//Component renders Day Week Picker for 4 weeks of the month. 
//TODO: Try and make typesafe
interface Props {
  selectedWeeks: SelectedWeeks;
  onHandleChange: any;
}

function DayPickerMonth2(props: Props) {
  
  const [week1Selected, setWeek1Selected] = useState<Array<number>>(props.selectedWeeks.week1Selected);
  const [week2Selected, setWeek2Selected] = useState<Array<number>>(props.selectedWeeks.week2Selected);
  const [week3Selected, setWeek3Selected] = useState<Array<number>>(props.selectedWeeks.week3Selected);
  const [week4Selected, setWeek4Selected] = useState<Array<number>>(props.selectedWeeks.week4Selected);

  const onWeek1Change = (event: Array<number>) => {
    setWeek1Selected(event);
    const weeks : SelectedWeeks = {week1Selected: event, week2Selected: week2Selected, week3Selected: week3Selected, week4Selected: week4Selected};
    props.onHandleChange(weeks);
  }

  const onWeek2Change = (event: Array<number>) => {
    setWeek2Selected(event);
    const weeks : SelectedWeeks = {week1Selected: week1Selected, week2Selected: event, week3Selected: week3Selected, week4Selected: week4Selected};
    props.onHandleChange(weeks);
  }

  const onWeek3Change = (event: Array<number>) => {
    setWeek3Selected(event);
    const weeks : SelectedWeeks = {week1Selected: week1Selected, week2Selected: week2Selected, week3Selected: event, week4Selected: week4Selected};
    props.onHandleChange(weeks);
  }

  const onWeek4Change = (event: Array<number>) => {
    setWeek4Selected(event);
    const weeks : SelectedWeeks = {week1Selected: week1Selected, week2Selected: week2Selected, week3Selected: week3Selected, week4Selected: event};
    props.onHandleChange(weeks);
  }

  return (
    <>
      <Stack spacing={4} align="center">
        <DayPickerWeek
          daysSelected={week1Selected}
          setDaysSelected={onWeek1Change}
        />
        <DayPickerWeek
          daysSelected={week2Selected}
          setDaysSelected={onWeek2Change}
        />
        <DayPickerWeek
          daysSelected={week3Selected}
          setDaysSelected={onWeek3Change}
        />
        <DayPickerWeek
          daysSelected={week4Selected}
          setDaysSelected={onWeek4Change}
        />
      </Stack>
    </>
  );
}

export default DayPickerMonth2;