import { Button } from "@progress/kendo-react-buttons";
import {
  ListView,
  ListViewFooter,
  ListViewHeader,
} from "@progress/kendo-react-listview";
import { Link, useNavigate } from "react-router-dom";
import { ClientRoutes } from "../../../Constants/ClientRoutingConstants";
import { enMessages } from "../../../messages/en-US";
import { useLocalization } from "@progress/kendo-react-intl";
import { Tooltip } from "@progress/kendo-react-tooltip";

interface Props {
  notifications: any;
  handleShow: () => void;
}

export default function NotificationsPopUp(props: Props) {
  const localizationService = useLocalization();
  const navigate = useNavigate();

  const MyHeader = () => {
    return (
      <ListViewHeader
        style={{
          fontSize: 19,
          borderBottom: "1px solid #dee2e6",
        }}
        className="pl-3 pb-2 pt-2"
      >
        <div>
          {localizationService.toLanguageString(
            "custom.notifications",
            enMessages.custom.notifications
          )}
        </div>
      </ListViewHeader>
    );
  };
  const MyFooter = () => {
    return (
      <ListViewFooter
        style={{
          borderTop: "1px solid #dee2e6",
        }}
        className="pb-2 pt-2 text-center"
      >
        <div>
          <Button
            className="mark-button"
            size={"small"}
            title="Notifications"
            onClick={() => {
              navigate(ClientRoutes.NotificationsPage);
              props.handleShow();
            }}
          >
            {localizationService.toLanguageString(
              "custom.showAllNotifications",
              enMessages.custom.showAllNotifications
            )}
          </Button>
        </div>
      </ListViewFooter>
    );
  };
  const MyItemRender = (props) => {
    let item = props.dataItem;
    const colour =
      item.criticality === "Critical"
        ? "#780000"
        : item.criticality === "High"
        ? "#dc0000"
        : item.criticality === "Medium"
        ? "#fd8c00"
        : "#fdc500";
    const icon =
      item.criticality === "Critical"
        ? "k-i-chevron-double-up"
        : item.criticality === "High"
        ? "k-i-arrow-chevron-up"
        : item.criticality === "Medium"
        ? "k-i-equal"
        : "k-i-arrow-chevron-down";
    return (
      <div
        className=" flex gap-2 items-center p-2 border-bottom align-middle"
        style={{
          margin: 0,
        }}
      >
        <Tooltip position={"bottom"} anchorElement={"target"}>
          <div className="col-2">
            <span
              className={`k-icon ${icon} cursor-pointer`}
              style={{ color: `${colour}` }}
              title={item.criticality}
            ></span>
          </div>
        </Tooltip>
        <div className="col-6 px-3">
          <div
            style={{
              marginBottom: 0,
            }}
          >
            {item.name}
          </div>
          <div
            style={{
              fontSize: 12,
              marginBottom: 0,
            }}
          >
            {item.created}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="mt-2" style={{ width: "300px" }}>
      <ListView
        className="max-h-96"
        data={props.notifications}
        item={MyItemRender}
        style={{
          width: "100%",
        }}
        header={MyHeader}
        footer={MyFooter}
      />
    </div>
  );
}
