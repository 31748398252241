import { TileLayout } from "@progress/kendo-react-layout";
import AlertsTile from "../Components/AlertsTile";
import ManualComplianceTile from "../Components/ManualComplianceTile";
import GreaseTagsTile from "../Components/GreaseTagsTile";

export default function ManualPage( ) {

  const dashboardTileData = [
    {
      body: <ManualComplianceTile />,
      reorderable: false,
    },
    {
      body:  <GreaseTagsTile />,
      reorderable: false,
    },
  ]
  
  const columnsDisplayPosition = [
    { col: 1, colSpan: 3, rowSpan: 1 },
    { col: 4, colSpan: 1, rowSpan: 1},
  ]
 
  return (
    <TileLayout
      columns={4}
      rowHeight={350}
      columnWidth={265}
      positions={columnsDisplayPosition}
      gap={{ rows: 10, columns: 10 }}
      items={dashboardTileData}
      className="dashboard-page-tile"
    />
  );
}
