import { SelectionRange } from "@progress/kendo-react-dateinputs";
import { DateRangePicker, DateRangePickerChangeEvent } from "@progress/kendo-react-dateinputs/dist/npm/daterangepicker/DateRangePicker";
import { TelerikReportViewer } from "@progress/telerik-react-report-viewer"
import { useEffect, useRef, useState } from "react";
import CustomEndDateInput from "../Components/CustomEndDateInput";
import CustomStartDateInput from "../Components/CustomStartDateInput";
import authorizeService from "../../Identity/Utilities/AuthorizeService";
import useIdentityStore from "../../../Zustand/identityStore";
import moment from "moment";
import { ApiRoutes } from "../../../Constants/ApiRoutingConstants";
import React from "react";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";
import { Button } from "@progress/kendo-react-buttons";
import { StringResources }from "../../../messages/stringResources"
import LoadingSpinner from "../../Shared/Components/LoadingSpinner";
import { Splitter } from "@progress/kendo-react-layout";
import ReportScheduleModal from "../Components/ReportModal";
import { useQuery, useQueryClient } from "react-query";
import { useToast } from "@chakra-ui/react";
import { ReportScheduleReadDto } from "../../../Models/Reporting/ReportScheduleReadDto";
import { ReactQueryKeys } from "../../../Constants/ClientRoutingConstants";
import reportScheduleApiService from "../Utilities/ReportApiService";
import { ReportScheduleUpdateDto } from "../../../Models/Reporting/ReportScheduleUpdateDto";
import { ReportScheduleCreateDto } from "../../../Models/Reporting/ReportScheduleCreateDto";
import { ScheduleReadDto } from "../../../Models/Schedule/ScheduleReadDto";
import schedulesApiService from "../../Schedules/Utilities/ScheduleApiService";
import ErrorCard from "../../Shared/Components/Cards/ErrorCard";

interface Props {}

export default function PendingActionsPage({}: Props, ) {

  const localizationService = useLocalization();
  const client = useQueryClient();
  const toast = useToast({
    position: "bottom",
    duration: 5000,
    isClosable: true,
  });

  const reportScheduleQuery = useQuery<ReportScheduleReadDto>(ReactQueryKeys.AllReportScheduleQuery, async () => {
    return reportScheduleApiService.getAllReportSchedule("PendingActions");
  });

  const createReportSchedule = async (ReportScheduleCreateDto: ReportScheduleCreateDto) => {
    const result = await reportScheduleApiService.createReportSchedule(ReportScheduleCreateDto);
    client.refetchQueries(ReactQueryKeys.AllReportScheduleQuery);
  };

  const deleteReportSchedule = async (id: any, type:any) => {
    try {
      let response = await reportScheduleApiService.deleteReportSchedule(id, type);

      //If delete is successful, refetch the updated list of devices and show success message
      client.refetchQueries(ReactQueryKeys.AllReportScheduleQuery);

      toast({
        status: "success",
        description: `${localizationService.toLanguageString('custom.schedulesDeleted', enMessages.custom.schedulesDeleted)}`,
      });
    } catch (e) {
      toast({
        status: "error",
        description: `${localizationService.toLanguageString('custom.schedulesDeletedError', enMessages.custom.schedulesDeletedError)}`,
      });
    }
    toggleDialog()
  };

  const updateComplianceReports = async (updateComplianceReport: ReportScheduleUpdateDto) => {
    let response = await reportScheduleApiService.updateReportSchedule(updateComplianceReport);
    //If update is successful, refetch the updated list of areas and show success message
    client.refetchQueries(ReactQueryKeys.AllReportScheduleQuery);
  }

  const schedulesQuery = useQuery<Array<ScheduleReadDto>>(ReactQueryKeys.AllSchedulesQuery, async () => {
    return schedulesApiService.getAllSchedules();
  });
  
  const viewerRef : any = useRef(null);
  const [token, setToken] = useState("");
  const [language, setLanguage] = useState(navigator.language);

  const serviceUrl = process.env.REACT_APP_TELERIK_REPORTING_URL + "/api/reports";
  const reportUrl = process.env.REACT_APP_REPORT_SERVICE_URL + ApiRoutes.PendingActionsReport;

  const userProfile = useIdentityStore((store) => store.user?.profile);
  const defaultStartDate = moment().startOf('isoWeek');
  const defaultDateRange = {start: new Date(defaultStartDate.toISOString()), end: new Date(defaultStartDate.endOf('isoWeek').toISOString())};
  const [dateRange, setDateRange] = useState<SelectionRange>(defaultDateRange);

  const [success, setSuccess] = React.useState(false);
  const [panes, setPanes] = React.useState<Array<any>>([{}]);
  const [disabled, setDisabled] =  React.useState(false);
  const [disableReport, setDisableReport] = React.useState(false);  

  const [visible, setVisible] = React.useState<boolean>(false);

  const toggleDialog = () => {
    setVisible(!visible);
  };


  const renderingEnd = () => {
    setSuccess(false);
    setDisabled(false)
  }

  const ready = () => {
    setSuccess(true);
  }

  const [disableButton, setDisableButton] = React.useState(true);   

  const getToken = async () => {
    try {
      const user = await authorizeService.getUser();
      setToken(user.access_token);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() =>  {
    getToken();
  }, [])

  useEffect(() =>  {
  }, [dateRange, token])

  const handleChange = (event: DateRangePickerChangeEvent) => {
    setDisableButton(false)
   if (event.value.end !== null)
   {
    setDateRange(event.value);
   }
  };

  //This function finds the most frequent timezone schedule
  const scheduleTimezone = () => {
    if(schedulesQuery.data !== undefined) {
      const timezone = schedulesQuery.data.map(item => item.timezone)

      // Check if we have at least 1 schedule
      if (timezone.length > 0)
      {
        const hashmap = timezone.reduce( (acc, val) => {
          acc[val] = (acc[val] || 0 ) + 1
          return acc
        },{})
        return Object.keys(hashmap).reduce((a, b) => hashmap[a] > hashmap[b] ? a : b)
      }
    }

    return "";
  }

  const dateInputSettings = {
    label: "",
    format: "d MMM, y"
  };

  const getFromDateInUtc = () => {
    const fromDate = moment(dateRange?.start).format("YYYY-MM-DDT00:00:00");
    return moment.tz(fromDate, scheduleTimezone()).utc().toISOString();
  }

  const timezoneOffset = () => {
    const fromDate = moment(dateRange?.start).format("YYYY-MM-DDT00:00:00");
    return moment.tz(fromDate, scheduleTimezone()).utcOffset();
  }

  const getToDateInUtc = () => {
    const toDate = moment(dateRange?.end).format("YYYY-MM-DDT23:59:59");
    return moment.tz(toDate, scheduleTimezone()).utc().toISOString();
  }

  const getReportDate = () => {
    return moment(dateRange?.start).format("DD MMM, YYYY") + " - " + moment(dateRange?.end).format("DD MMM, YYYY")
  }
  
  const handleApply = () => {
    setDisableReport(true)
    setSuccess(true);
    setDisabled(true)
    if (viewerRef) {
      const {viewerObject} = viewerRef.current;
      const reportDate = getReportDate();
      var fromDateInUtc = getFromDateInUtc();
      var toDateInUtc = getToDateInUtc();
      var timezone = scheduleTimezone()
      var timezoneOffsetInMinutes = timezoneOffset()
      
      viewerObject.reportSource({report: "PendingActionsReport.trdp", parameters: ({"Url":reportUrl, "Token": token, "SiteName": userProfile?.current_site_name, "ReportDate":reportDate, "FromDate": fromDateInUtc, "ToDate": toDateInUtc, "Timezone": timezone, "TimezoneOffsetInMinutes": timezoneOffsetInMinutes})})
      switch (language) {
        case "pt-BR":
          viewerObject.stringResources = Object.assign(viewerObject.stringResources, StringResources.portuguese);
          break;
        default:
          viewerObject.stringResources = Object.assign(viewerObject.stringResources, StringResources.english);
          break;
      }
  }}

      //Loading renderer function to ensure all Queries all loaded.
    if (reportScheduleQuery.isLoading || reportScheduleQuery.isFetching) {
      return <LoadingSpinner />;
    } else if (reportScheduleQuery.error) {
        return <ErrorCard />;
    } else {
      return (
        <div>
          <div className="flex items-center justify-between">
            <h2 className="m-5 gb-h2">{localizationService.toLanguageString('custom.pendingTasks', enMessages.custom.pendingTasks)}</h2>
            <div className="flex items-center justify-items-end">
              {disableReport && (
                  <Button
                  icon="graph"
                  className="h-8 mx-8"
                  themeColor={"primary"}
                  disabled={disabled}
                  onClick={handleApply}
                >{localizationService.toLanguageString('custom.generateReport', enMessages.custom.generateReport)}
                </Button>
                )}
                {/* {userProfile?.role === 'SuperAdmin' &&(
                  <Button
                  className="h-8 mr-8"
                  icon="email"
                  onClick={toggleDialog}
                  themeColor={"primary"}
                  disabled={disabled}
                >
                  {localizationService.toLanguageString('custom.scheduleEmail', enMessages.custom.scheduleEmail)}
                </Button>
                )}  */}
                </div>
           </div>
            <div className="m-5">
              <div className="mr-8 pr-8">
                  <DateRangePicker
                  startDateInput={CustomStartDateInput}
                  endDateInput={CustomEndDateInput}
                  startDateInputSettings={dateInputSettings}
                  endDateInputSettings={dateInputSettings}
                  defaultValue={defaultDateRange}
                  onChange={handleChange}
                  disabled={disabled}
                  />
              </div>
              <div className="m-1 text-sm">
                  ({scheduleTimezone()})
                </div>
            </div> 
            
            <div className={"appContainer"}>
                <div style={{position:'relative', height:'100vh'}}>

                {!disableReport && (
                    <Splitter style={{ height:'100%', zIndex:1, border:'none', }} panes={panes}>
                      <div className="gb-text-normal  text-lg text-center"
                        style={{width: '400px', borderRadius: '3px', padding: '40px 25px',margin: '30px auto'}}>
                        <div className="text-center mb-5">
                          <span className="k-icon k-i-graph k-icon-70"></span>
                        </div>
                        <div>
                        <span>{localizationService.toLanguageString('custom.setFiltersText', enMessages.custom.setFiltersText)}</span>
                        <ul className="mt-5">
                          <li>
                          <Button
                            icon="graph"
                            className="h-8"
                            themeColor={"primary"}
                            onClick={handleApply}
                          >{localizationService.toLanguageString('custom.generateReport', enMessages.custom.generateReport)}
                          </Button>
                          </li>
                        </ul>
                        </div>
                      </div> 
                    </Splitter>)
                  }


                  {disableReport && success && (
                    <div >
                    <Splitter style={{ height:'100%', zIndex:1 }} panes={panes}>
                      <LoadingSpinner />
                    </Splitter>
                  </div>)
                  }
                  
                  {token !== "" ? 
                  <TelerikReportViewer
                    ref={viewerRef}
                    serviceUrl={serviceUrl}
                    renderingEnd={renderingEnd}
                    ready={ready}
                    // reportSource={{
                    // report: 'PendingActionsReport.trdp',
                    // parameters: {"Url":reportUrl,"Token": token, 
                    //              "SiteName": userProfile?.current_site_name,
                    //              "ReportDate": getReportDate(), 
                    //              "FromDate": getFromDateInUtc(), 
                    //              "ToDate": getToDateInUtc(),
                    //              }
                    // }}
                    viewerContainerStyle = {{
                      position: 'absolute',
                      left: '10px',
                      right: '20px',
                      top: '10px',
                      bottom: '10px',
                      overflow: 'hidden',
                      clear: 'both',
                      fontFamily: 'Roboto',
                    }}
                    viewMode="INTERACTIVE"
                    scaleMode="SPECIFIC"
                    scale={1.0}
                    enableAccessibility={false} 
                    documentMapVisible={false}
                    pageMode="CONTINUOUS_SCROLL" />
                    : ""}
                    
                </div>
            </div>
            <ReportScheduleModal
              onClose={toggleDialog}
              visible={visible}
              reportSchedule={reportScheduleQuery.data!} 
              deleteReportSchedule={deleteReportSchedule}
              createReportSchedule={createReportSchedule}
              updateReportSchedule={updateComplianceReports}
              filters={null}
              type={'PendingActions'}
              reportName={'Pending Actions Report'}
              timezone={scheduleTimezone()}
              />
        </div>
    );
    }
  }
  
