import * as yup from "yup";

export default yup.object().shape({
type: yup.string().min(2).max(48).required(),
//Transform parent to null if empty string
areaId: yup
    .string()
    .nullable()
    .min(2)
    .max(48)
    .required()
    .transform((value, original) => {
        //We transform an empty string in parent to null.
        return value === "" ? null : value;
    }),
guid: yup.string().min(2).max(48),
modelNumber: yup.string().min(2).max(48),
serialNumber: yup.string().min(2).max(48),
firmwareVersion: yup.string().min(2).max(48),
//Transform parent to null if empty string
consumableId: yup
    .string()
    .nullable()
    .min(2)
    .max(48)
    .required()
    .transform((value, original) => {
        //We transform an empty string in parent to null.
        return value === "" ? null : value;
    }),
status: yup.string(),
});
