export const ptMessages = {
  custom: {
    language: "Idioma",
    profile: "Ir ao Perfil",
    changeAccount: "Trocar de Conta",
    logout: "Sair",
    accountSetup: "Configurações da Conta",
    uploadData: "Carregar dados da conta",
    step1: "Passo 1: Baixar e preencher o template da conta",
    step2: "Passo 2: Fazer o upload do arquivo de template da conta",
    seeUploadTemplate:
      "Para mais informações, acesse aqui o arquivo de como fazer o Upload do Template",
    downloadTemplate: "Baixar Template",
    errors: "Erros",
    downloadData: "Baixar dados da conta",
    inventory: "Inventário",
    schedules: "Agendamentos",
    locations: "Localizações",
    greaseTags: "Etiquetas de Lubrificação",
    routeEditor: "Editor de Rotas",
    devices: "Dispositivos",
    assets: "Ativos",
    events: "Eventos",
    compliance: "Complacência",
    administration: "Administração",
    manageUsers: "Gerenciar Usuários",
    manageDevices: "Gerenciar Dispositivos",
    manageAccounts: "Gerenciar Contas",
    supportCentre: "Suporte",
    cancel: "Cancelar",
    confirm: "Confirmar",
    confirmLeave: "Você tem certeza que deseja sair dessa página?",
    unsavedChanges:
      "Pode haver alterações não salvas nesta página, se você confirmar, você será navegado para outra página e todas as alterações não salvas serão perdidas",
    unsavedChangesShort: "Todas as alterações não salvas serão perdidas.",
    howTo: "Vídeos de Instruções",
    howToView: "Veja nossos últimos vídeos de intruções",
    howToBtn: "Acesse os vídeos",
    password: "Senha",
    supportDocumentation: "Documentação de Suporte",
    supportDocumentationView:
      "Veja nossa última documentação de suporte hospedada online.",
    supportDocumentatioBtn: "Acesse a documentação",
    support: "Suporte",
    supportText:
      "Contate nossa equipe de suporte para ajudas com problemas e questões relacionadas aos seus produtos.",
    supportRequest: "Faça uma solicitação para suporte",
    supportEmail: "Nos contate por email",
    greaseTypes: "Tipos de Lubrificação",
    greaseTypesText:
      "Por favor, registre os diferentes tipos de lubrificação requeridos pelas máquinas de sua conta.",
    schedulesText:
      "Os agendamentos definem a frequência e as datas em que as etiquetas em sua conta precisam ser lubrificadas.",
    locationsText:
      "Dferentes locais da sua conta que contém ativos e pontos para lubrificação.",
    assetsText:
      "Ativos são todas as peças de sua conta que contém etiquetas de lubrificação.",
    greaseTagsText:
      "Pontos de lubrificação associatos aos ativos de sua conta contendo as etiquetas de lubrificação da Greaseboss.",
    routeEditorText:
      "Representa a ordem em que os pontos de lubrificação devem ser lubrificados.",
    devicesText:
      "Representa as unidades principais de Greaseboss registradas em sua conta.",
    name: "Nome",
    description: "Descrição",
    manufacturer: "Fabricante",
    consumables: "Insumos",
    actions: "Ações",
    add: "Adicionar",
    exit: "Sair",
    edit: "Editar",
    delete: "Deletar",
    discardChanges: "Descartar Alterações",
    save: "Salvar",
    saveChanges: "Salvar Alterações?",
    saveChangesConfirmation:
      "Tem certeza que quer salvar as alterações feitas nos itens selecionados?",
    continue: "Continuar",
    deleteItems: "Deletar Itens?",
    deleteItemsConfirmation:
      "Tem certeza que quer deletar os itens selecionados?",
    exportCSV: "Exportar como CSV",
    search: "Procurar...",
    frequency: "Frequência",
    recurrence: "Recorrência",
    timezone: "Fuso horário",
    startDate: "Data de Início",
    summary: "Resumo",
    addSchedule: "Adicionar Agendamento",
    mustSelectDay:
      "Um dia deve ser selecionado para uma programação semanal ou mensal.",
    error: "Erro",
    scheduleSuccessfully: "agendamento foi com sucesso",
    updated: "atualizado",
    created: "criado",
    somethingWentWrong: "Alguma coisa deu errada na criação do agendamento.",
    dailyRecurrenceInterval: "Intervalo de Recorrência Diária",
    recurranceXDays: "Recorrente a cada X dias",
    weeklyRecurrenceInterval: "Recorrência Semanal",
    recurranceXWeeks: "Recorrente a cada  X Semanas",
    recurranceXMonths: "Recorrente a cada  X Meses",
    recurrenceInterval: "Intervalo de Recorrência",
    mustSelectWeek: "Pelo menos um dia deve ser selecionado para a semana",
    monthlyRecurrence: "Recorrência Mensal",
    mustSelectMonth: "Pelo menos um dia deve ser selecionado para o mês",
    updateSchedule: "Atualizar Agendamento",
    createSchedule: "Criar Agendamento",
    scheduleName: "Nome do Agendamento",
    daily: "Diário",
    weekly: "Semanal",
    monthly: "Mensal",
    endDate: "Data Final",
    parentLocation: "Localização Pai",
    descriptionNotes: "Descrição/Notas",
    model: "Modelo",
    parentAsset: "Ativo Pai",
    numTags: "# de Etiquetas",
    location: "Localização",
    order: "Ordem",
    asset: "Ativo",
    consumable: "Insumo",
    volume: "Volume (cc)",
    rfid: "RFID",
    schedule: "Agendamento",
    tagName: "Nome da Etiqueta",
    grease: "Lubrificante",
    modelNumber: "Número do Modelo",
    serialNumber: "Número de Série",
    firmwareVersion: "Versão do Firmware",
    occurred: "Ocorrência",
    point: "Ponto",
    volumeDispensed: "Volume Aplicado (cc)",
    eventsText:
      "Veja eventos postados pelos dispositivos da sua rede GreaseCloud.",
    showEventsFrom: "Mostrar os eventos de",
    until: "Até",
    email: "Email",
    currentRole: "Função Atual",
    confirmed: "Confirmado",
    currentAccount: "Conta Atual",
    user: "Usuário",
    updateRoles: "Atualizar Funções",
    resendInvite: "Reenviar Convite",
    manageUsersText:
      "Conceder acesso a novos usuários, revogar acesso e atribuir função de usuário.",
    id: "ID",
    account: "Conta",
    device: "Dispositivo",
    manageDevicesText:
      "Criar identidades para novos dispositivos, atualizar suas contas, e monitorar o uso.",
    manageAccountsText:
      "Adicionar, remover, e atualizar contas com acesso ao sistema GreaseCloud.",
    deviceCreation: "Sucesso na Criação do Dispositivo",
    deviceCreationText1:
      "O Dispositivo foi criado com sucesso com as seguintes credenciais a seguir, por favor revise os detalhes a seguir e guarde-os em um local seguro.",
    deviceCreationText2:
      "Você não conseguirá ver esses detalhes novamente depois de fechar essa janela.",
    secretKey: "CHAVE SECRETA",
    close: "Fechar",
    roleRemoved: "A função do usuário foi removida com sucesso",
    roleRemovedError: "Algo deu errado ao tentar remover a função de usuário.",
    roleAdded: "A função do usuário foi adicionada com sucesso",
    roleAddedError: "Algo deu errado ao tentar adicionar a função do usuário.",
    updateUserRoles: "Atualizar Funções do Usuário",
    role: "Função",
    remove: "Remover",
    userInvitation: "O convite do usuário foi enviado com sucesso",
    userInvitationError: "Erro ao enviar o convite ao usuário.",
    userReinvitation: "Sucesso ao reenviar o convite ao usuário.",
    userReinvitationError: "Falha o reenviar o convite ao usuário.",
    success: "Sucesso",
    userDeleted: "Usuário deletado da conta com sucesso.",
    userDeletedError:
      "Um erro ocorreu ao deletar o usuário da conta.An error occured whilst trying to delete the user from the account. Você pode não ter as permissões necessárias.",
    accountCreated: "A conta foi criada com sucesso.",
    accountCreatedError: "Um erro ocorreu ao criar a nova conta.",
    consumableCreated: "foi criado com sucesso",
    consumableCreatedError: "Algo deu errado ao tentar criar um insumo",
    consumableDeleted: "Os insumos selecionados foram deletados com sucesso.",
    consumableDeletedError:
      "Um erro ocorreu ao tentar deletar os insumos selecionados.",
    consumableUpdated: "O insumo selecionado foi atualizado com sucesso.",
    consumableUpdatedError:
      "O insumo modificado não foi atualizado com sucesso.",
    cosnumableIncorrectInput:
      "Os seguintes insumos falharam devido uma entrada incorreta:",
    deviceUpdated:
      "Os dispositivos selecionados foram atualizados com sucesso.",
    deviceUpdatedError:
      "O dispositivo alterado não foi atualizado com sucesso.",
    deviceIncorrectInput:
      "Os seguintes dispositivos falharam devido uma entrada incorreta:",
    assetsDeleted: "The selected assets were successfully deleted.",
    assetsDeletedError:
      "Um erro ocorreu ao tentar deletar os ativos selecionados.",
    assetsUpdated: "Os ativos selecionados foram atualizados com sucesso.",
    assetsUpdatedError: "Os ativos alterados não foram atualizados com sucesso",
    assetsIncorrectInput:
      "Os seguintes ativos falharam devido uma entrada incorreta:",
    assetsCreatedError: "Algo deu erro ao criar o ativo",
    pointsCreatedError: "Algo deu errado ao criar o ponto",
    pointsDeleted: "Os pontos selecionados foram deletados com sucesso.",
    pointsDeletedError:
      "Um erro ocorreu ao tentar delete os pontos selecionados.",
    pointsUpdated: "Os pontos selecionados foram atualizados com sucesso.",
    pointsUpdatedError: "Os pontos alterados não foram atualizados.",
    pointsIncorrectInput:
      "Os seguintes pontos falharam devido uma entrada incorreta:",
    routeUpdated: "A ordem da rota foi atualizada com sucesso.",
    routeUpdatedError: "Incapaz de atualizar a ordem da rota.",
    schedulesDeleted:
      "Os agendamentos selecionados foram deletados com sucesso.",
    schedulesDeletedError:
      "Um erro ocorreu ao tentar deletar os agendamentos selecionados.",
    errorOccured: "Um erro ocorreu.",
    errorPermissions:
      "O servidor retornou um erro informando que você não tem as permissões requeridas para acessar os recursos dessa página.",
    deleteItemLabel: "Tem certeza que quer deletar os itens selecionados?",
    selectOption: "Selecionar Opção",
    occurringAfter: "Ocorrendo Depois...",
    notFound: "Não Encontrado",
    fieldIsRequired: "Campo Obrigatório",
    volumeRequirementValidation:
      "Valor tem que ser -1, maior ou igual a 0, 'Purge' ou 'P'. Número depois da casa decimal precisa ser especificado",
    createNewAccount: "Criar Nova Conta",
    addAccount: "Criar nova conta para sua conta.",
    accountName: "Nome da Conta:",
    createAccount: "Criar Conta",
    addUser: "Adicionar Usuário",
    addNewUser: "Adicionar novo usuário em sua conta.",
    userId: "ID do Usuário:",
    siteId: "ID da Conta:",
    roleId: "ID da Função",
    inviteUser: "Convidar Usuário",
    currentFeed: "Seu feed atual",
    selectAccountId: "Uma conta precisa ser selecionada para um dispositivo.",
    emailRequired: "Email Obrigatório",
    invalidEmail: "Email Inválido",
    roleRequired: "Função Obrigatória",
    noSpecialCharacters: "Não pode conter nenhum caractere especial.",
    locationCreatedError: "Algo deu errado ao criar a localização",
    locationDeleted:
      "As localizações selecionadas foram deletadas com sucesso.",
    locationDeletedError:
      "Um erro ocorreu ao deletar as localizações selecionadas.",
    locationUpdated:
      "Todas as alterações nas localizações foram atualizadas com sucesso!",
    locationUpdatedError: "A localização alterada não foi atualizada",
    locationIncorrectInput:
      "As seguintas localizações falharam devido entrada incorreta:",
    invalidAction: "Ação inválida",
    invalidStatusResult: "Status do resultado inválido",
    noRedirect: "Não deveria redirecionar.",
    invalidAuthentication: "Status do resultado de autenticação inválido",
    deviceCreated: "Os dispositivos foram criados com sucesso",
    deviceCreatedError: "Algo deu errado ao criar o dispositivo",
    deviceDeleted: "Os dispositivos selecionados foram deletados com sucesso",
    deviceDeletedError: "Algo deu errado ao deletar o dispositivo",
    sunday: "Dom",
    monday: "Seg",
    tuesday: "Ter",
    wednesday: "Qua",
    thursday: "Qui",
    friday: "Sex",
    saturday: "Sáb",
    true: "VERDADEIRO",
    false: "FALSO",
    superAdmin: "SuperAdmin",
    accountAdmin: "ContaAdmin",
    manager: "Gerente",
    generalUser: "UsuárioGeral",
    onboardApp: "Onboard App",
    goToLink: "Abrir link",
    copyLink: "Copiar endereço do link",
    reports: "Relatórios",
    complianceReport: "Relatório de Complacência",
    selectAll: "Selecionar Todos",
    points: "Pontos",
    generateReport: "Gerar Relatório",
    clearFilters: "Limpar Filtros",
    selected: "Selecionados",
    loading: "Carregando...",
    pendingActions: "Relatório de Ações Pendentes",
    scheduleEmail: "Agendar Email",
    emailScheduleText: "Agendar entrega de e-mail para este relatório.",
    from: "De",
    to: "Para",
    subject: "Assunto",
    period: "Período",
    time: "Hora",
    endpointReport: "Relatório do Endpoints",
    setFiltersText: "Defina os filtros para gerar o relatório.",
    endpoints: "Endpoints",
    uploaded: "Carregado",
    detailTreeList: "Lista Detalhada",
    detailView: "Visão Detalhada",
    manualSystem: "Sistema Manual",
    automaticSystem: "Sistema Automático",
    site: "Local",
    expectedConsumable: "Consumo Esperado",
    volumeDelivered: "Vol. Entregue",
    dueDate: "Data de Vencimento",
    expiryDate: "Data de Validade",
    compliant: "Complacente",
    volumeRequirement: "Vol. Requerido",
    volumeTolerance: "Vol. Tolerado",
    status: "Status",
    viewActionsText: "Veja Ações em sua GreaseCloud network",
    showAllActionsFrom: "Ações desde",
    pending: "Pendente",
    closed: "Finalizado",
    endpointCreated: "O endpoint foi criado com sucesso",
    endpointCreatedError: "Algo deu errado ao criar o endpoint",
    endpointDeleted: "Os endpoints especificados foram excluídos com sucesso",
    endpointDeletedError: "Algo deu errado ao excluir os endpoints",
    endpointUpdated: "O endpoint selecionado foi atualizado com sucesso.",
    endpointUpdatedError:
      "O endpoint modificado não foi atualizado com sucesso.",
    endpointIncorrectInput:
      "Os seguintes endpoints falharam devido a entrada incorreta:",
    manageEndpoints: "Gerenciar Endpoints",
    manageendpointsText:
      "Crie identidades para novos endpoints, atualize suas contas e monitore o uso.",
    endpointsText:
      "Representa os endpoints Greaseboss registrados em sua conta.",
    hardwareVersion: "Versão do Hardware",
    schedulePattern: "Padrão de Programação",
    notes: "Notas",
    temperatureInC: "Temperatura (°C)",
    temperature: "Temperatura",
    voltage: "Voltagem (V)",
    rssiDbm: "RSSI (dBm)",
    endpointRssi: "Endpoint RSSI (dBm)",
    cumulative: "Cumulativa (cc)",
    list: "Lista",
    noSchedule: "Sem Programação",
    pointActivity: "Atividade do Ponto",
    endpointActivity: "Atividade do Endpoint",
    activity: "Atividade",
    metrics: "MetrMétricasics",
    selectPointEndpoint: "Selecione um Ativo ou um Ponto/Endpoint.",
    noDataFound: "Dados não encontrados",
    changeDateRangeAndPoint:
      "Altere o intervalo de datas ou selecione outro ponto/endpoint.",
    diagnostics: "Diagnóstico",
    changeDateRange: "Altere o intervalo de datas.",
    thisCouldBeBecause: "Isso pode ser porque",
    trySelectingDates:
      "O endpoint não postou diagnósticos por um tempo. Tente selecionar datas diferentes.",
    unableTocommunicate:
      "O endpoint não consegue se comunicar com o GreaseCloud. Entre em contato com o suporte.",
    siteExplorer: "Explorar Site",
    endpointMapping: "Mapeamento de Endpoint",
    tagMapping: "Mapeamento de Tag",
    sessionExpired: "Sua sessão expirou, você será desconectado agora.",
    none: "Nenhum",
    aggregator: "Agregador",
    complianceSent: "Relatório de Conformidade enviado com sucesso.",
    unableToSendEmail: "Não é possível enviar relatório por e-mail.",
    sendEmail: "Enviar Email",
    send: "Enviar",
    sendEmailText: "Enviar email para esse relatório",
    reportDate: "Data do Relatório",
    processing: "Processando...",
    last: "Últimos",
    days: "Dias",
    today: "Hoje",
    yesterday: "Ontem",
    reservoirs: "Reservatórios",
    reservoirSuccessfully: "reservatório foi com sucesso",
    reservoirDeleted: "O reservatório selecionado foi deletado com sucesso.",
    reservoirDeletedError:
      "Ocorreu um erro ao tentar excluir o reservatório selecionado.",
    addReservoir: "Adicionar reservatório",
    updateReservoir: "Atualizar Reservatório",
    deleteReservoir: "Excluir reservatório",
    linkedPoints: "Endpoints vinculados",
    addNote: "Adicionar ou editar uma nota:",
    reservoirText:
      "Os reservatórios podem ser usados para monitorar o nível de volume de autolubrificadores de graxa, baldes, barris e silos.",
    deviceNotConnected: "O dispositivo não se conectou ao GreaseCloud.",
    lastSeen: "Visto pela última vez",
    never: "Nunca",
    requirement: "Requisito",
    requirements: "Requisitos",
    requirementsText:
      "Os requisitos definem o volume de graxa necessário a ser dispensado por um ponto de extremidade.",
    createRequirements: "Criar Requisitos",
    requirementName: "Nome do Requisito",
    rightAmount: "Quantidade Correta",
    rightTime: "Hora Correta",
    rightGrease: "Graxa Correta",
    next: "Próximo",
    autoThreshold: "Limiar Automático",
    on: "Ligado",
    off: "Desligado",
    "Over-greased": "Sobre-lubrificado",
    "Under-greased": "Sub-lubrificado",
    submit: "Enviar",
    autoRange: "Auto-faixa",
    thresholds: "Limiares",
    barChart: "Gráfico de Barras",
    lineChart: "Gráfico de Linha",
    capacity: "Capacidade",
    estimatedVolume: "Volume Estimado",
    averageDailyConsumption: "Consumo Diário Médio",
    projectedDepletionDate: "Data Estimada de Esgotamento",
    lastReset: "Último Reset",
    available: "Disponível",
    consumed: "Consumido",
    lastResetLevel: "Nível do Último Reset",
    verifyLastResetDate:
      "passado vazio. Verifique a data do último reset do reservatório",
    viewReservoir: "Visualizar Reservatório",
    noteReservoir: "Observação: Todos os cálculos são realizados em cc.",
    outlets: "Saídas",
    inlets: "Entradas",
    date: "Data",
    resetLevel: "Nível de Reset",
    resetBy: "Resetado por (Data)",
    comments: "Comentários",
    resetDate: "Data de Reset",
    resetReservoir: "Resetar Reservatório",
    override: "Sobrescrever",
    reset: "Resetar",
    averageDailyConsumptionTooltip:
      "O consumo diário médio é calculado com base nos últimos",
    daysOfActivity: "dias de atividade.",
    requirementSuccessfully: "requisito foi criado com sucesso.",
    requirementDeleted: "O requisito selecionado foi excluído com sucesso.",
    requirementDeletedError:
      "Ocorreu um erro ao tentar excluir o requisito selecionado.",
    updateRequirements: "Atualizar Requisitos",
    requirementSummary: "Resumo do Requisito",
    linkReservoir: "Vincular Reservatório",
    noReservoirLinked: "Nenhum reservatório foi vinculado.",
    addLinkReservoir: "Adicionar e Vincular reservatório",
    unplannedVolumeDispensed: "Volume Não Planejado Distribuído",
    noRequirementAssigned: "Nenhum Requisito Atribuído",
    pleaseAssignRequirement:
      "Atribua um requisito para visualizar a conformidade.",
    reservoirsReport: "Relatório de Reservatórios",
    manageAuthTokens: "Gerenciar tokens de autenticação",
    noRequirementWithinTime: "Nenhuma exigência dentro do período de tempo",
    noRequirement: "Sem exigência",
    estimated: "Consumo",
    depletionDate: "Diário Médio",
    average: "Média",
    perDay: "por dia",
    linked: "Vinculados",
    futureReservoirRequirements: "Requisitos futuros dos reservatórios",
    currentReservoirUsage: "Uso atual do reservatórios",
    currentMonth: "Mês atual",
    nextMonth: "Próximo mês",
    nextNextMonth: "2 meses",
    january: "Janeiro",
    february: "Fevereiro",
    march: "Março",
    april: "Abril",
    may: "Maio",
    june: "Junho",
    july: "Julho",
    august: "Agosto",
    september: "Setembro",
    october: "Outubro",
    november: "Novembro",
    december: "Dezembro",
    alerts: "Alertas",
    alert: "Alerta",
    assignedTo: "Atribuído a",
    criticality: "Criticidade",
    type: "Tipo",
    enabled: "Ativado",
    deleteAlert: "Excluir Alerta",
    createAlert: "Criar Alerta",
    hours: "Horas",
    weeks: "Semanas",
    months: "Meses",
    successfullyUpdated: "atualizado com sucesso",
    successfullyCreated: "criado com sucesso",
    low: "Baixo",
    medium: "Médio",
    high: "Alto",
    critical: "Crítico",
    assignTo: "Atribuir a",
    condition: "Condição",
    field: "Campo",
    value: "Valor",
    operator: "Operador",
    unit: "Unidade",
    notifications: "Notificações",
    notifyUsers: "Notificar os seguintes usuários",
    emails: "E-mails",
    message: "Mensagem",
    updateAlert: "Atualizar Alerta",
    showAllNotifications: "Mostrar todas as notificações",
    result: "Resultado",
    emailSent: "E-mail enviado",
    acknowledged: "Aceito",
    lastEvaluated: "Última avaliação",
    lastNotified: "Última notificação",
    lastTriggered: "Último acionado",
    triggeredBy: "Acionado por",
    noDataDepletion:
      "Sem dados - Mínimo de 3 dias necessários para calcular o esgotamento",
    equalTo: "Igual a",
    notEqualTo: "Não igual a",
    greaterThan: "Maior que",
    greaterThanOrEqualTo: "Maior ou igual a",
    lessThan: "Menor que",
    lessThanOrEqualTo: "Menor ou igual a",
    alertSuccessfullydeleted: "O alerta selecionado foi deletado com sucessp.",
    errorDeletingAlert: "Ocorreu um erro ao tentar deletar o alerta selecionado.",
    flowEvents: "Eventos de Fluxo",
    pressureEvents: "Eventos de Pressão",
    singleOrMultipleChart: "Gráfico Único ou Múltiplo",
    pressureMean: "Média de Pressão",
    preassureMinMax: "Pressão Mín/Máx",
    pressureTemperature: "Pressão Temperatura",
    max: "Máx",
    min: "Mín",
    mean: "Média",
    pressureMin: "Pressão Mín",
    pressureMax: "Pressão Máx",
    pressure: "Pressão",
    flow: "Fluxo",
    diagnostic: "Diagnóstico",
    heartbeat: "Pulsação",
    endpointsEvents: "Eventos de Endpoints",
    pointsEvents: "Eventos de Pontos",
    resetHistory: "Redefinir Histórico",
    triggered: "Acionado",
    exportToExcel: "Baixar para Excel",
    reservoir: "Reservatório",
    endpoint: "Endpoint",
    noTagsAssigned: "Não há Grease Tags atribuídas a esta conta.",
    uploadCsv: "Upload do arquivo CSV",
    uploadEndpoints: "Upload dos Endpoints",
    uploadCSVText: "Upload o arquivo CSV:",
    online: "Online",
    offline: "Offline",
    offlinePeriod: "Período Offline",
    eventType: "Tipo de Evento",
    comment: "Comentário",
    reasonCode: "Código de Motivo",
    addOnlineOffline: "Adicionar Evento Online / Offline",
    manageOperationalState: "Gerenciar Estado Operacional",
    maintenancePlanned: "Manutenção (Planejada)",
    maintenanceUnplanned: "Manutenção (Não Planejada)",
    operationalShutdownPlanned: "Desligamento Operacional (Planejado)",
    operationalShutdownUnplanned: "Desligamento Operacional (Não Planejado)",
    other: "Outro",
    dashboard: "Painel",
    automaticCompliance: "Conformidade Automática",
    manualCompliance: "Conformidade Manual",
    inLast7Days: "nos últimos 7 dias",
    noDataAvailable: "Nenhum Dado Disponível.",
    seeAll: "Ver Todos",
    withoutRequirement: "Sem Requisito",
    tags: "Tags",
    unmapped: "Não Mapeado",
    withoutSchedule: "Sem Agendamento",
    nonCompliant: "Não Conforme",
    settingsNotConfigured: "As configurações não estão configuradas para esta conta.",
    contactSupport: "Por favor, entre em contato com o suporte no link abaixo:",
    noEndpointDeviceAssigned: "Nenhum Endpoint atribuído",
    noEventsToDetermine: "Nenhum evento para determinar o último visto",
    minFlowRate: "Taxa Mínima de Fluxo (L/min)",
    avgFlowRate: "Taxa Média de Fluxo (L/min)",
    maxFlowRate: "Taxa Máxima de Fluxo (L/min)",
    windowSize: "Tamanho da Janela (s)",
    samplePeriod: "Período de Amostragem (s)",
    volumeInL: "Volume (L)",
    minMaxFlowRate: "Taxa Mín./Máx. de Fluxo (L/min)",
    flowRate: "Taxa de Fluxo",
    copyToken: "Copie e armazene este token. Você não poderá vê-lo novamente.",
    tokenCreated: "Token de autenticação criado",
    latitude: "Latitude",
    longitude: "Longitude",
    altitude: "Altitude",
    accuracy: "Precisão",
    speed: "Velocidade",
    speedAccuracy: "Precisão da velocidade",
    heading: "Direção",
    gps: "GPS",
    gpsEvents: "Eventos GPS",
    tasks: "Tarefas",
    viewTasksText: "Ver tarefas na sua rede GreaseCloud",
    showAllTasksFrom: "Mostrar todas as tarefas de",
    pendingTasks: "Relatório de Tarefas Pendentes",
    untrackedGreasing: "LUBRIFICAÇÃO NÃO REGISTRADA",
    
  },
  grid: {
    groupPanelEmpty:
      "Arraste um cabeçalho de coluna e solte-o aqui para agrupar por essa coluna",
    pagerItemsPerPage: "itens por página",
    pagerInfo: "{0} - {1} de {2} itens",
    pagerFirstPage: "Vá para a primeira página",
    pagerPreviousPage: "Vá para a página anterior",
    pagerNextPage: "Vá para a página seguinte",
    pagerLastPage: "Vá para a última página",
    pagerPage: "Página",
    pagerOf: "de",
    filterClearButton: "Claro",
    filterEqOperator: "É igual a",
    filterNotEqOperator: "Não é igual a",
    filterIsNullOperator: "É nulo",
    filterIsNotNullOperator: "Não é nulo",
    filterIsEmptyOperator: "Está vazia",
    filterIsNotEmptyOperator: "Não está vazio",
    filterStartsWithOperator: "Começa com",
    filterContainsOperator: "Contém",
    filterNotContainsOperator: "Não contém",
    filterEndsWithOperator: "Termina com",
    filterGteOperator: "É maior que ou igual a",
    filterGtOperator: "É melhor que",
    filterLteOperator: "É menor ou igual a",
    filterLtOperator: "É menos do que",
    filterIsTrue: "É verdade",
    filterIsFalse: "É falso",
    filterBooleanAll: "(Tudo)",
    filterAfterOrEqualOperator: "É posterior ou igual a",
    filterAfterOperator: "É depois",
    filterBeforeOperator: "É antes",
    filterBeforeOrEqualOperator: "É anterior ou igual a",
    noRecords: "Nenhum registro disponível.",
    filterAriaLabel: "Filtro",
    filterCheckAll: "Verificar tudo",
    filterChooseOperator: "Escolha o operador",
    filterSelectedItems: "itens selecionados",
    filterSubmitButton: "Filtro",
    filterTitle: "Filtro",
    filterAndLogic: "E",
    filterOrLogic: "Ou",
    groupColumn: "Coluna de Grupo",
    pagerTotalPages: "{0}",
    searchPlaceholder: "Procurar",
    sortAriaLabel: "Classificável",
    sortAscending: "Ordernar ascendente",
    sortDescending: "Classificar em ordem decrescente",
    ungroupColumn: "Desagrupar coluna",
  },
  filter: {
    eqOperator: "É igual a",
    notEqOperator: "Não é igual a",
    isNullOperator: "É nulo",
    isNotNullOperator: "Não é nulo",
    isEmptyOperator: "Está vazia",
    isNotEmptyOperator: "Não está vazio",
    startsWithOperator: "Começa com",
    containsOperator: "Contém",
    notContainsOperator: "Não contém",
    endsWithOperator: "Termina com",
    gteOperator: "É maior que ou igual a",
    gtOperator: "É melhor que",
    lteOperator: "É menor ou igual a",
    ltOperator: "É menos do que",
    isTrue: "É verdade",
    isFalse: "É falso",
    afterOrEqualOperator: "É posterior ou igual a",
    afterOperator: "É depois",
    beforeOperator: "É antes",
    beforeOrEqualOperator: "É anterior ou igual a",
    andLogic: "E",
    orLogic: "Ou",
    addExpression: "Adicionar Expressão",
    addGroup: "Adicionar grupo",
    close: "Fechar",
    filterAriaLabel: "Filtro",
    alertSuccessfullydeleted: "O alerta selecionado foi excluído com sucesso.",
    errorDeletingAlert: "Ocorreu um erro ao tentar excluir o alerta selecionado.",
  },
  dateinput: {
    increment: "Aumente o valor",
    decrement: "Diminuir valor",
  },
  calendar: {
    today: "HOJE",
  },
  datepicker: {
    toggleCalendar: "Alternar calendário",
  },
  daterangepicker: {
    swapStartEnd: "Troque os valores inicial e final",
    start: "Começar",
    end: "Fim",
    separator: " ",
  },
  datetimepicker: {
    date: "Encontro",
    time: "Tempo",
    toggleDateTimeSelector: "Alternar seletor de data e hora",
    cancel: "Cancelar",
    set: "Definir",
  },
  editor: {
    bold: "Negrito",
    italic: "itálico",
    underline: "Sublinhado",
    strikethrough: "Tachado",
    subscript: "Subscrito",
    superscript: "Sobrescrito",
    unlink: "Remover hiperlink",
    undo: "Desfazer",
    redo: "Refazer",
    fontSize: "Tamanho da fonte",
    fontName: "Nome da fonte",
    format: "Formato",
    alignLeft: "Alinhar o texto à esquerda",
    alignRight: "Alinhar o texto à direita",
    alignCenter: "Texto central",
    alignJustify: "Justificar",
    indent: "Recuar",
    outdent: "Outdent",
    orderedList: "Inserir lista ordenada",
    bulletList: "Inserir lista não ordenada",
    createTable: "Crie uma mesa",
    insertTableHint: "Crie uma tabela {0} x {1}",
    addRowBefore: "Adicionar linha acima",
    addRowAfter: "Adicionar linha abaixo",
    addColumnBefore: "Adicionar coluna à esquerda",
    addColumnAfter: "Adicionar coluna à direita",
    deleteRow: "Apagar linha",
    deleteColumn: "Apagar coluna",
    deleteTable: "Apagar mesa",
    mergeCells: "Mesclar células",
    splitCell: "Dividir célula",
    hyperlink: "Insira o hyperlink",
    "hyperlink-dialog-title": "Insira o hyperlink",
    "hyperlink-dialog-content-address": "Endereço da web",
    "hyperlink-dialog-content-title": "Título",
    "hyperlink-dialog-content-newwindow": "Abrir link em nova janela",
    "hyperlink-dialog-cancel": "Cancelar",
    "hyperlink-dialog-insert": "Inserir",
    image: "Inserir Imagem",
    "image-dialog-title": "Inserir Imagem",
    "image-address": "Endereço da web",
    "image-title": "Título",
    "image-altText": "Texto alternativo",
    "image-width": "Largura (px)",
    "image-height": "Altura (px)",
    "image-cancel": "Cancelar",
    "image-insert": "Inserir",
    viewHtml: "Ver HTML",
    "viewHtml-dialog-title": "Ver HTML",
    "viewHtml-cancel": "Cancelar",
    "viewHtml-update": "Atualizar",
    print: "Imprimir",
    selectAll: "Selecionar tudo",
    cleanFormatting: "Limpar formatação",
    pdf: "Exportar como PDF",
    foregroundColor: "Cor",
    backgroundColor: "Cor de fundo",
    insertFile: "Inserir arquivo",
    insertFileDialogTitle: "Inserir arquivo",
    insertFileAddress: "Endereço da web",
    insertFileTitle: "Título",
    insertFileCancel: "Cancelar",
    insertFileInsert: "Inserir",
    findReplaceToolTitle: "Encontrar e substituir",
    findReplaceDialogTitle: "Encontrar e substituir",
    findReplaceTabFind: "Achar",
    findReplaceTabReplace: "Substituir",
    findReplaceFindWhat: "Encontre o que:",
    findReplaceReplaceWith: "Substituir com",
    findReplaceReplace: "Substituir",
    findReplaceReplaceAll: "Substitua tudo",
    findReplaceMatchCase: "Caso de compatibilidade",
    findReplaceMatchWord: "Corresponder apenas a palavra inteira",
    findReplaceMatchCyclic: "Match cyclic (Wrap around)",
    findReplaceUseRegExp: "Expressão regular",
    findReplacePrevMatch: "Anterior",
    findReplaceNextMatch: "Próximo",
    findReplaceMatches: "{0} de {1} partidas",
  },
  timepicker: {
    now: "AGORA",
    selectNow: "Selecione agora",
    cancel: "Cancelar",
    set: "Definir",
    toggleTimeSelector: "Alternar TimeSelector",
    toggleClock: "Alternar relógio",
  },
  dropdowns: {
    nodata: "NENHUM DADO ENCONTRADO.",
    clear: "Claro",
  },
  numerictextbox: {
    increment: "Aumente o valor",
    decrement: "Diminuir valor",
  },
  upload: {
    cancel: "Cancelar",
    clearSelectedFiles: "Claro",
    dropFilesHere: "Arraste e solte o arquivo aqui para enviar",
    headerStatusUploaded: "Feito",
    headerStatusUploading: "Enviando...",
    invalidFileExtension: "Tipo de arquivo não permitido.",
    invalidFiles:
      "Arquivo (s) inválido (s). Verifique os requisitos de upload de arquivo.",
    invalidMaxFileSize: "O tamanho do arquivo é muito grande.",
    invalidMinFileSize: "Tamanho do arquivo muito pequeno.",
    remove: "Remover",
    retry: "Tentar novamente",
    select: "Selecionar arquivo...",
    uploadSelectedFiles: "Envio",
    total: "Total",
    files: "Arquivos",
    dropZoneHint: "Arraste e solte os arquivos aqui para fazer o upload.",
    dropZoneNote: "Somente arquivos JPEG e PNG são permitidos.",
    statusUploadFailed: "Falha ao carregar arquivo (s).",
    statusUploaded: "Arquivo (s) carregado (s) com sucesso.",
  },
  sortable: {
    noData: "Sem dados",
  },
  pager: {
    itemsPerPage: "itens por página",
    info: "{0} - {1} de {2} itens",
    firstPage: "Vá para a primeira página",
    previousPage: "Vá para a página anterior",
    nextPage: "Vá para a página seguinte",
    lastPage: "Vá para a última página",
    page: "Página",
    of: "de",
    pagerTotalPages: "{0}",
  },
  treelist: {
    filterClearButton: "Claro",
    filterEqOperator: "É igual a",
    filterNotEqOperator: "Não é igual a",
    filterIsNullOperator: "É nulo",
    filterIsNotNullOperator: "Não é nulo",
    filterIsEmptyOperator: "Está vazia",
    filterIsNotEmptyOperator: "Não está vazio",
    filterStartsWithOperator: "Começa com",
    filterContainsOperator: "Contém",
    filterNotContainsOperator: "Não contém",
    filterEndsWithOperator: "Termina com",
    filterGteOperator: "É maior que ou igual a",
    filterGtOperator: "É melhor que",
    filterLteOperator: "É menor ou igual a",
    filterLtOperator: "É menos do que",
    filterIsTrue: "É verdade",
    filterIsFalse: "É falso",
    filterBooleanAll: "(Tudo)",
    filterAfterOrEqualOperator: "É posterior ou igual a",
    filterAfterOperator: "É depois",
    filterBeforeOperator: "É antes",
    filterBeforeOrEqualOperator: "É anterior ou igual a",
    noRecords: "Nenhum registro disponível",
  },
  scheduler: {
    editorValidationRequired: "Campo é obrigatório.",
    editorValidationStart:
      "O horário de início deve ser anterior ao horário de término.",
    editorValidationEnd:
      "O horário de término deve ser posterior ao horário de início.",
    allEvents: "Todos os eventos",
    allDay: "dia todo",
    today: "Hoje",
    dayViewTitle: "Dia",
    monthViewTitle: "Mês",
    weekViewTitle: "Semana",
    workWeekViewTitle: "Semana de trabalho",
    timelineViewTitle: "Linha do tempo",
    agendaViewTitle: "Agenda",
    deleteTitle: "Excluir",
    previousTitle: "Anterior",
    nextTitle: "Próximo",
    showFullDay: "Mostrar o dia inteiro",
    showWorkDay: "Mostrar horário comercial",
    editorOccurrence: "Editar ocorrência atual",
    editorSeries: "Edite a série",
    editorRecurringConfirmation:
      "Quer editar apenas esta ocorrência de evento ou toda a série?",
    editorRecurringDialogTitle: "Editar item recorrente",
    editorSave: "Salve ",
    editorCancel: "Cancelar",
    editorDelete: "Excluir",
    editorTitle: "Evento",
    editorEventTitle: "Título",
    editorEventStart: "Começar",
    editorEventStartTimeZone: "Fuso Horário de Início",
    editorEventEnd: "Fim",
    editorEventEndTimeZone: "Fuso Horário Final",
    editorEventAllDay: "Evento de dia inteiro",
    editorEventDescription: "Descrição",
    editorEventSeparateTimeZones: "Termina em fuso horário diferente",
    editorEventTimeZone: "Especifique o fuso horário",
    recurrenceEditorRepeat: "Repita",
    recurrenceEditorDailyInterval: "dias)",
    recurrenceEditorDailyRepeatEvery: "Repita a cada",
    recurrenceEditorWeeklyInterval: "semana (s)",
    recurrenceEditorWeeklyRepeatEvery: "Repita a cada",
    recurrenceEditorWeeklyRepeatOn: "Repita",
    recurrenceEditorMonthlyDay: "Dia",
    recurrenceEditorMonthlyInterval: "meses)",
    recurrenceEditorMonthlyRepeatEvery: "Repita a cada",
    recurrenceEditorMonthlyRepeatOn: "Repita",
    recurrenceEditorYearlyOf: "de",
    recurrenceEditorYearlyRepeatEvery: "Repita a cada",
    recurrenceEditorYearlyRepeatOn: "Repita",
    recurrenceEditorYearlyInterval: "anos)",
    recurrenceEditorFrequenciesDaily: "Diário",
    recurrenceEditorFrequenciesMonthly: "Por mês",
    recurrenceEditorFrequenciesNever: "Nunca",
    recurrenceEditorFrequenciesWeekly: "Semanalmente",
    recurrenceEditorFrequenciesYearly: "Anual",
    recurrenceEditorOffsetPositionsFirst: "Primeiro",
    recurrenceEditorOffsetPositionsSecond: "Segundo",
    recurrenceEditorOffsetPositionsThird: "Terceiro",
    recurrenceEditorOffsetPositionsFourth: "Quarto",
    recurrenceEditorOffsetPositionsLast: "Durar",
    recurrenceEditorWeekdaysDay: "Dia",
    recurrenceEditorWeekdaysWeekday: "Dia da semana",
    recurrenceEditorWeekdaysWeekendday: "Dia de fim de semana",
    recurrenceEditorEndAfter: "Depois de",
    recurrenceEditorEndOccurrence: "ocorrência (s)",
    recurrenceEditorEndLabel: "Fim",
    recurrenceEditorEndNever: "Nunca",
    recurrenceEditorEndOn: "Sobre",
    deleteConfirmation: "Tem certeza que deseja deletar este evento?",
    deleteRecurringConfirmation:
      "Quer excluir apenas esta ocorrência do evento ou toda a série?",
    deleteOccurrence: "Apagar a ocorrência atual",
    deleteSeries: "Exclua a série",
    deleteDialogTitle: "Apagar Evento",
    deleteRecurringDialogTitle: "Excluir item recorrente",
    dateTitle: "Encontro",
    timeTitle: "Tempo",
    eventTitle: "Evento",
    noEvents: "Sem eventos",
  },
  listbox: {
    moveUp: "Subir",
    moveDown: "Mover para Baixo",
    transferTo: "Transferir para",
    transferFrom: "Transferir de",
    transferAllTo: "Transferir tudo para",
    transferAllFrom: "Transferir tudo de",
    remove: "Excluir",
  },
  stepper: {
    optionalText: "(Opcional)",
  },
  labels: {
    labelsOptional: "(Opcional)",
  },
  slider: {
    increment: "Aumentar",
    decrement: "Diminuir",
    dragTitle: "Arrastar",
  },
  colorGradient: {
    r: "r",
    g: "g",
    b: "b",
    a: "uma",
    hex: "hex",
    contrastRatio: "Relação de contraste",
    colorGradientAALevel: "AA",
    colorGradientAAALevel: "AAA",
    colorGradientPass: "Passar",
    colorGradientFail: "Falhou",
  },
  checkbox: {
    validation: "Marque esta caixa se quiser continuar!",
    optionalText: "(Opcional)",
  },
  radioButton: {
    validation: "Selecione a opção se quiser continuar!",
  },
  switch: {
    validation: "Ligue se quiser continuar!",
  },
  gantt: {
    weekViewTitle: "Semana",
    dayViewTitle: "Dia",
    monthViewTitle: "Mês",
    yearViewTitle: "Ano",
    filterClearButton: "Claro",
    filterEqOperator: "É igual a",
    filterNotEqOperator: "Não é igual a",
    filterIsNullOperator: "É nulo",
    filterIsNotNullOperator: "Não é nulo",
    filterIsEmptyOperator: "Está vazia",
    filterIsNotEmptyOperator: "Não está vazio",
    filterStartsWithOperator: "Começa com",
    filterContainsOperator: "Contém",
    filterNotContainsOperator: "Não contém",
    filterEndsWithOperator: "Termina com",
    filterGteOperator: "É maior que ou igual a",
    filterGtOperator: "É melhor que",
    filterLteOperator: "É menor ou igual a",
    filterLtOperator: "É menos do que",
    filterIsTrue: "É verdade",
    filterIsFalse: "É falso",
    filterBooleanAll: "(Tudo)",
    filterAfterOrEqualOperator: "É posterior ou igual a",
    filterAfterOperator: "É depois",
    filterBeforeOperator: "É antes",
    filterBeforeOrEqualOperator: "É anterior ou igual a",
    noRecords: "Nenhum registro disponível",
    editorSave: "Salve ",
    editorCancel: "Cancelar",
    editorTitle: "Tarefa",
    editorTaskTitle: "Título",
    editorTaskStart: "Começar",
    editorTaskEnd: "Fim",
    editorTaskComplete: "Completo",
    editorValidationRequired: "Campo é obrigatório.",
    editorValidationStart:
      "O horário de início deve ser anterior ao horário de término.",
    editorValidationEnd:
      "O horário de término deve ser posterior ao horário de início.",
    addTask: "Adicionar Tarefa",
    addChild: "Adicionar criança",
    addAbove: "Adicionar Acima",
    addBelow: "Adicionar abaixo",
    editorDelete: "Excluir",
    deleteConfirmation: "Tem certeza que deseja deletar este evento?",
    deleteDialogTitle: "Apagar Evento",
  },
  multiviewcalendar: {
    prevView: "Navegue para a vista anterior",
    nextView: "Navegue para a próxima vista",
  },
  columnMenu: {
    columnMenuFilterClearButton: "Claro",
    columnMenuFilterSubmitButton: "Filtro",
    columnMenuFilterTitle: "Filtro",
    columnMenuSortAscending: "Ordernar ascendente",
    columnMenuSortDescending: "Classificar em ordem decrescente",
    columnMenuFilterEqOperator: "É igual a",
    columnMenuFilterNotEqOperator: "Não é igual a",
    columnMenuFilterIsNullOperator: "É nulo",
    columnMenuFilterIsNotNullOperator: "Não é nulo",
    columnMenuFilterIsEmptyOperator: "Está vazia",
    columnMenuFilterIsNotEmptyOperator: "Não está vazio",
    columnMenuFilterStartsWithOperator: "Começa com",
    columnMenuFilterContainsOperator: "Contém",
    columnMenuFilterNotContainsOperator: "Não contém",
    columnMenuFilterEndsWithOperator: "Termina com",
    columnMenuFilterGteOperator: "É maior que ou igual a",
    columnMenuFilterGtOperator: "É melhor que",
    columnMenuFilterLteOperator: "É menor ou igual a",
    columnMenuFilterLtOperator: "É menos do que",
    columnMenuFilterIsTrue: "É verdade",
    columnMenuFilterAfterOrEqualOperator: "É posterior ou igual a",
    columnMenuFilterAfterOperator: "É depois",
    columnMenuFilterBeforeOperator: "É antes",
    columnMenuFilterBeforeOrEqualOperator: "É anterior ou igual a",
    columnMenuFilterAndLogic: "E",
    columnMenuFilterOrLogic: "Ou",
  },
  pivotgrid: {
    fieldMenuReset: "Redefinir",
    fieldMenuApply: "Aplicar",
    configuratorCancel: "Cancelar",
    configuratorApply: "Aplicar",
    configuratorTitle: "Configurações",
    configuratorEditorSearchTitle: "Campos",
    configuratorEditorSearchPlaceholder: "Procurar...",
    configuratorColumnsTitle: "Colunas",
    configuratorRowsTitle: "Linhas",
    configuratorMeasuresTitle: "Valores",
    configuratorButtonLabel: "Mudar configurações",
    configuratorMissingFields:
      "Selecione alguns campos para iniciar a configuração",
  },
};
