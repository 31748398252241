import { ComboBoxItem } from "../../Shared/Components/Forms/ComboBox";
import { Frequency, rrulestr, RRule } from "rrule";
import {
  FrequencyTypes,
  ByWeekday,
  ByMonthDay1,
  ByMonthDay2,
  ByMonthDay3,
  ByMonthDay4,
} from "../../../Constants/SchedulerConstants";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";
import { RequirementReadDto } from "../../../Models/Requirement/RequirementReadDto";

const date = new Date();

export const greasePatternsToComboboxItems = (existingGreasePatterns: Array<RequirementReadDto>) => {
  return existingGreasePatterns
    ?.filter((data) => {
      return data.id;
    })
    .map((pattern) => {
      return {
        displayName: pattern.name,
        value: pattern.id,
      };
    }) as Array<ComboBoxItem>;
};

export const greasePatternToComboboxItem = (patternId: string, existingGreasePatterns: Array<RequirementReadDto>) => {
  return patternId != null
    ? ({
        displayName: existingGreasePatterns?.find((p) => p?.id === patternId)?.name,
        value: patternId,
      } as ComboBoxItem)
    : null;
};

export const rruleFrequencyToText = (rRule: string) => {
  // freq => 1:Monthly, 2: Weekly, 3: Daily
  let rule = rrulestr(rRule);

  if (rule.origOptions.freq === Frequency.MONTHLY) {
    return "Monthly";
  } else if (rule.origOptions.freq === Frequency.WEEKLY) {
    return "Weekly";
  } else if (rule.origOptions.freq === Frequency.DAILY) {
    return "Daily";
  } else return "";
};

export const rruleFrequencyToTextRRule = (rRule: string) => {
  // freq => 1:Monthly, 2: Weekly, 3: Daily
  let rule = rrulestr(rRule);
  if (rule.origOptions.freq === Frequency.MONTHLY) {
    return "RRule.MONTHLY";
  } else if (rule.origOptions.freq === Frequency.WEEKLY) {
    return "RRule.WEEKLY";
  } else if (rule.origOptions.freq === Frequency.DAILY) {
    return "RRule.DAILY";
  } else return "";
};

export const rruleRecurrenceToText = (rRule: string) => {
  let rule = rrulestr(rRule);
  if (rule.origOptions.freq === Frequency.MONTHLY) {
    return `Every ${rule.origOptions.interval ?? 1} Months`;
  } else if (rule.origOptions.freq === Frequency.WEEKLY) {
    return `Every ${rule.origOptions.interval ?? 1} Weeks`;
  } else if (rule.origOptions.freq === Frequency.DAILY) {
    return `Every ${rule.origOptions.interval ?? 1} Days`;
  } else return "";
};

export const rruleSummaryToText = (rRule: string) => {
  let rule = rrulestr(rRule);
  return rule.toText().replace(/^\w/, (c) => c.toUpperCase());
};

/**
 * This method returns a valid start date based on the frequency of a schedule if weekly, get start of week, if Monthly, get start of month
 * @param date The suggested start date
 * @param frequency The frequency of the RRULE for the schedule
 * @returns a valid start date
 */
export const getValidStartDateForFrequency = (date: Date | null | undefined, frequency: string) => {
  if (frequency === FrequencyTypes.weekly && date != null && date !== undefined) {
    let day = date!.getDay();
    let diff = date!.getDate() - day; //Assumes sunday is start of week
    date = new Date(date.setDate(diff));
  } else if (frequency === FrequencyTypes.monthly && date != null && date !== undefined) {
    date = new Date();
    const y = date.getFullYear();
    const m = date.getMonth();
    date = new Date(y, m, 1);
  }

  return date;
};

export const minEndDate = () => {
  const date = new Date();
  date.setDate(date.getDate() + 1);

  return date;
};

export const FrequencyStringToDisplayName = (frequency: string) => {
  const localizationService = useLocalization();
  if (frequency === "RRule.DAILY") {
    return `${localizationService.toLanguageString('custom.daily', enMessages.custom.daily)}`;
  }
  if (frequency === "RRule.WEEKLY") {
    return `${localizationService.toLanguageString('custom.weekly', enMessages.custom.weekly)}`;
  }
  if (frequency === "RRule.MONTHLY") {
    return `${localizationService.toLanguageString('custom.monthly', enMessages.custom.monthly)}`;
  }
  return "";
};

export const rRuleGenerator = (
  recurrence: number,
  startDate: Date | null | undefined,
  endDate: Date | null | undefined,
  frequency: string,
  daysSelected: Array<number>,
  week1Selected: Array<number>,
  week2Selected: Array<number>,
  week3Selected: Array<number>,
  week4Selected: Array<number>
) => {
  let rule = new RRule({});

  if (frequency === FrequencyTypes.daily) {
    rule = new RRule({
      freq: RRule.DAILY,
      interval: recurrence,
      dtstart: startDate,
      until: endDate,
    });
  } else if (frequency === FrequencyTypes.weekly) {
    let daySelect = daysSelected.sort().map((i: number) => ByWeekday[i]);

    rule = new RRule({
      freq: RRule.WEEKLY,
      interval: recurrence,
      byweekday: daySelect,
      dtstart: startDate,
      until: endDate,
    });
  } else if (frequency === FrequencyTypes.monthly) {
    let daySelect1 = week1Selected.sort().map((i: number) => ByMonthDay1[i]);
    let daySelect2 = week2Selected.sort().map((i: number) => ByMonthDay2[i]);
    let daySelect3 = week3Selected.sort().map((i: number) => ByMonthDay3[i]);
    let daySelect4 = week4Selected.sort().map((i: number) => ByMonthDay4[i]);

    let daySelectMonth = daySelect1.concat(daySelect2, daySelect3, daySelect4);

    rule = new RRule({
      freq: RRule.MONTHLY,
      interval: recurrence,
      byweekday: daySelectMonth,
      dtstart: startDate,
      until: endDate,
    });
  }

  return rule;
};
