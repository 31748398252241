import { ApiRoutes } from "../../../Constants/ApiRoutingConstants";
import axios, { AxiosInstance } from "axios";
import authorizeService from "../../Identity/Utilities/AuthorizeService";
import { ReservoirReadDto } from "../../../Models/Reservoirs/ReservoirReadDto";
import { ReservoirCreateDto } from "../../../Models/Reservoirs/ReservoirCreateDto";
import { ReservoirUpdateDto } from "../../../Models/Reservoirs/ReservoirUpdateDto";
import { ReservoirHistoryDto } from "../../../Models/Reservoirs/ReservoirHistoryDto";
import { ReservoirResetDto } from "../../../Models/Reservoirs/ReservoirResetDto";
import { LinkedPoints } from "../../../Models/Reservoirs/ReservoirLinkedPoints";

/**
 * This service provides implementations for http calls to the api pertaining to schedule crud functionality
 */

class GreaseReservoirsApiService {
  axiosInstance: AxiosInstance;
  constructor() {
    //Subscribe to identity store the get token
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_ASSET_SERVICE_URL,
    });
  }

  async getAllReservoirs(): Promise<ReservoirReadDto[]> {
    let response = await this.axiosInstance({
      method: "GET",
      url: `${ApiRoutes.GreaseReservoirs}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data;
  }

  async getReservoir(reservoirId: string): Promise<ReservoirReadDto> {
    let response = await this.axiosInstance({
      method: "GET",
      url: `${ApiRoutes.GreaseReservoirs}/${reservoirId}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data;
  }

  async getLinkedPoints(reservoirId: string): Promise<LinkedPoints[]> {
    let response = await this.axiosInstance({
      method: "GET",
      url: `${ApiRoutes.GreaseReservoirsLinkedPoints}/${reservoirId}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data;
  }

  async getResetHistory(reservoirId: string): Promise<ReservoirHistoryDto[]> {
    let response = await this.axiosInstance({
      method: "GET",
      url: `${ApiRoutes.GreaseReservoirsHistory}/${reservoirId}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    
    let resetEvents = response.data as Array<ReservoirHistoryDto>;
    resetEvents.forEach(item => {item.resetDate = new Date(item.resetDate)});
    return resetEvents;
  }

  async createReservoir(Reservoir: ReservoirCreateDto) {
    let response = await this.axiosInstance({
      method: "POST",
      url: `${ApiRoutes.GreaseReservoirs}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: Reservoir,
    });
    return response.data as ReservoirReadDto;
  }

  async updateReservoir(reservoir: ReservoirUpdateDto, reservoirId: string) {
    let response = await this.axiosInstance({
      method: "PUT",
      url: `${ApiRoutes.GreaseReservoirs}/${reservoirId}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: reservoir,
    });
    return response.data as ReservoirReadDto;
  }

  async updateReservoirs(Reservoirs: Array<ReservoirUpdateDto>) {
    let response = await this.axiosInstance({
      method: "PUT",
      url: `${ApiRoutes.GreaseReservoirs}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: { Reservoir: Reservoirs },
    });
    return response.data;
  }

  async linkReservoir(reservoirId: string, pointId: string) {
    let response = await this.axiosInstance({
      method: "PUT",
      url: `${ApiRoutes.GreaseReservoirsLink}/${reservoirId}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: { pointId: pointId },
    });
    return response.data;
  }

  async deleteReservoir(reservoirId: string) {
    let response = await this.axiosInstance({
      method: "DELETE",
      url: `${ApiRoutes.GreaseReservoirs}/${reservoirId}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      }
    });
    return response.data;
  }

  async resetReservoir(reservoirId: string, reservoirDto: ReservoirResetDto) {
    let response = await this.axiosInstance({
      method: "PUT",
      url: `${ApiRoutes.GreaseReservoirsReset}/${reservoirId}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: reservoirDto,
    });
    return response.data;
  }

  static get instance() {
    return GreaseReservoirsApiService;
  }
}

const greaseReservoirsApiService = new GreaseReservoirsApiService();
export default greaseReservoirsApiService;
