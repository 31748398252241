import { ReactElement, useEffect, useMemo, } from "react";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../../../messages/en-US";
import { useToast } from "@chakra-ui/react";
import { EventFilter } from "../../../../Events/Utilities/EventsApiService";
import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
import { ConsumableReadDto } from "../../../../../Models/Consumable/ConsumableReadDto";
import { useQuery, useQueryClient } from "react-query";
import consumablesApiService from "../../../../Consumables/Utilities/ConsumablesApiService";
import { ReactQueryKeys } from "../../../../../Constants/ClientRoutingConstants";
import endpointsApiService from "../../../../Endpoints/Utilities/EndpointsApiService";
import EndpointReadDto from "../../../../../Models/Endpoint/EndpointReadDto";
import greaseReservoirsApiService from "../../../../Reservoir/Utilities/GreaseReservoirApiService";
import { PointReadDto } from "../../../../../Models/Point/PointReadDto";
import pointsApiService from "../../../../Points/Utilities/PointsApiService";
import ReservoirAddModal from "../../../../Reservoir/Components/ReservoirAddModal";
import React from "react";
import LinkReservoirModal from "../../../Components/LinkReservoirModal";
import { ReservoirReadDto } from "../../../../../Models/Reservoirs/ReservoirReadDto";
import { ReservoirCreateDto } from "../../../../../Models/Reservoirs/ReservoirCreateDto";
import { ReservoirUpdateDto } from "../../../../../Models/Reservoirs/ReservoirUpdateDto";
import { TreeListReadDto } from "../../../../../Models/TreeList/TreeListReadDto";
import { LinkedPoints } from "../../../../../Models/Reservoirs/ReservoirLinkedPoints";
import LoadingSpinner from "../../../../Shared/Components/LoadingSpinner";
import ErrorCard from "../../../../Shared/Components/Cards/ErrorCard";


interface Props {
  selectedTreeNode: TreeListReadDto;
  OnLinkReservoir: (reservoidId: string) => void;
  OnAddAndLink: () => void; 
}

export default function EndpointReservoirTabAddLinkPage(props: Props): ReactElement {
  const localizationService = useLocalization();
  const client = useQueryClient();

  const greaseReservoirsQuery = useQuery<Array<ReservoirReadDto>>(ReactQueryKeys.AllGreaseReservoirsQuery, async () => {
    return greaseReservoirsApiService.getAllReservoirs();
  });

  const consumablesQuery = useQuery<Array<ConsumableReadDto>>(ReactQueryKeys.AllConsumablesQuery, async () => {
    return consumablesApiService.getAllConsumables();
  });

  const pointsQuery = useQuery<Array<PointReadDto>>(ReactQueryKeys.AllPointsQuery, async () => {
    return pointsApiService.getAllPoints();
  });

  const endpointsQuery = useQuery<Array<EndpointReadDto>>(ReactQueryKeys.AllEndpointsQuery, async () => {
    return endpointsApiService.getAllEndpoints();
  });

  const createReservoir = async (reservoirCreateDto: ReservoirCreateDto) => {
    const result = await greaseReservoirsApiService.createReservoir(reservoirCreateDto);
    client.refetchQueries(ReactQueryKeys.AllGreaseReservoirsQuery);
    props.OnAddAndLink();
  };

  const updateReservoirs = async (reservoirId: string, updateReservoir: ReservoirUpdateDto) => {
    let response = await greaseReservoirsApiService.updateReservoir(updateReservoir, reservoirId);
    //If update is successful, refetch the updated list of areas and show success message
    client.refetchQueries(ReactQueryKeys.AllGreaseReservoirsQuery);
  };

  const [reservoirToUpdate, setReservoirToUpdate] = React.useState<ReservoirReadDto | null>(null);
  const [addReservoir, setAddReservoir] = React.useState<boolean>(false);
  const [linkReservoir, setLinkReservoir] = React.useState<boolean>(false);
  const closeHandler = () => {
    setReservoirToUpdate(null);
    setAddReservoir(false);
    setLinkReservoir(false)
  };

  const item : LinkedPoints = {
    id: props.selectedTreeNode.id,
    name: props.selectedTreeNode.name,
    type: props.selectedTreeNode.type,
    flowType: "Outlet",
    data: props.selectedTreeNode.data
  }

  if(greaseReservoirsQuery.isLoading || consumablesQuery.isLoading || pointsQuery.isLoading || endpointsQuery.isLoading) {
    return <LoadingSpinner />
  } else if(greaseReservoirsQuery.isError || consumablesQuery.isError || pointsQuery.isError || endpointsQuery.isError) {
    return <ErrorCard />
  } else {
  return (
    <div style={{height:'100%', width:'100%'}}>
      <div style={{marginTop:'20px'}}>{localizationService.toLanguageString('custom.noReservoirLinked', enMessages.custom.noReservoirLinked)}</div>
      <div style={{display:'flex', gap:'20px', marginTop:'30px'}}>
        <Button style={{padding:'5px 20px'}} onClick={() => setLinkReservoir(true)}>{localizationService.toLanguageString('custom.linkReservoir', enMessages.custom.linkReservoir)}</Button>
        <Button style={{padding:'5px 20px'}} onClick={() => setAddReservoir(true)}>{localizationService.toLanguageString('custom.addLinkReservoir', enMessages.custom.addLinkReservoir)}</Button>
      </div>

      {addReservoir && (
        <ReservoirAddModal createReservoir={createReservoir} reservoir={reservoirToUpdate} updateReservoir={updateReservoirs} addReservoir={addReservoir} onClose={closeHandler } consumables={consumablesQuery.data!} points={pointsQuery.data!} endpoints={endpointsQuery.data!} linkedPoints={[item]} selectedItem={props.selectedTreeNode}/>
      )}

      {linkReservoir && (
        <LinkReservoirModal onClose={closeHandler} reservoirs={greaseReservoirsQuery.data!} OnLinkReservoir={props.OnLinkReservoir} />
      )}
      
    </div>
  )
}}