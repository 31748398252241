import { ReactElement, ReactNode, useEffect, useMemo, useState } from "react";
import {Breadcrumb, Splitter, SplitterOnChangeEvent, TabStripSelectEventArguments} from '@progress/kendo-react-layout';
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";
import { useQuery, useQueryClient } from "react-query";
import { ClientRoutes, ReactQueryKeys } from "../../../Constants/ClientRoutingConstants";
import LoadingSpinner from "../../Shared/Components/LoadingSpinner";
import ErrorCard from "../../Shared/Components/Cards/ErrorCard";
import { TreeListReadDto } from "../../../Models/TreeList/TreeListReadDto";
import treeListApiService from "../Utilities/TreeListApiService";
import TreeListComponent from "../Components/TreeList";
import { Calendar, CalendarProps, DatePicker } from "@progress/kendo-react-dateinputs";
import { EventFilter } from "../../Events/Utilities/EventsApiService";
import PointDetailsPage from "./Points/PointDetailsPage";
import EndpointDetailsPage from "./Endpoints/EndpointDetailsPage";
import LocationDetailsPage from "./Locations/LocationDetailsPage";
import AssetDetailsPage from "./Assets/AssetDetailsPage";
import AllEndpointsDetailsPage from "./Endpoints/AllEndpointsDetailsPage";
import AllPointsDetailsPage from "./Points/AllPointsDetailsPage";
import { useNavigate, useParams } from "react-router-dom";
import { PointDetailTabOrder } from "../../../Enums/PointDetailTabOrder";
import moment from "moment";
import { Button } from "@progress/kendo-react-buttons";
import StatusDialog from "../Components/Endpoints/StatusDialog";
import useIdentityStore from "../../../Zustand/identityStore";
import useChartStore from "../../../Zustand/chartStore";

// Workaround: Disable Fast Navigational Bar until this critical bug is fixed by Telerik (https://github.com/telerik/kendo-react/issues/1566)
const CustomCalendar = (props: CalendarProps) => {
  return <Calendar {...props} navigation={false} />
};


export default function DetailsPage(): ReactElement {
  const localizationService = useLocalization();
  const currentUserRole = useIdentityStore((x) => x.user?.profile.role);
  const client = useQueryClient();
  const [panes, setPanes] = useState<Array<any>>([
    { size: "20%", collapsible: true, keepMounted: true },
    {},
  ]);

  const {id} = useParams();
  const {tabId} = useParams();

 
  const onChange = (event: SplitterOnChangeEvent) => {
    setPanes(event.newState);
  };

  const onTreeListExpandChange = async (expandState: any) => {
    setExpandState(expandState);
  }

  const onTreeListSelectionChange = async (selectedItem: TreeListReadDto, selectedState: any) => {
    setSelectedItem(selectedItem);
    setSelectedState(selectedState);
    setSelectedTabId(PointDetailTabOrder.Activity);
    navigate(`${ClientRoutes.SiteExplorerPage}`);
  }
  const navigate = useNavigate();
  //TreeList
  const treeListQuery = useQuery<Array<TreeListReadDto>>(ReactQueryKeys.AllTreeListQuery, async () => {
    return treeListApiService.getAllTreeList();
  });

  const treeListData = useMemo(() =>{
    const treeList = treeListQuery.data !== undefined ? treeListQuery.data.slice() : new Array
    return treeList
  } ,[treeListQuery.data])

  const [selectedItem, setSelectedItem] = useState<TreeListReadDto>(); 
  const [expandState, setExpandState] = useState<any>({}); 
  const [selectedState, setSelectedState] = useState<any>({}); 
  const [selectedTabId, setSelectedTabId] = useState<number>(0);

  const setVisibleRangeChartStore = useChartStore(state => state.setVisibleRange);

  const [eventFilter, setEventFilter] = useState<EventFilter>({
    endDate: moment().endOf('day').toDate(), 
    startDate: (() => {
      //get current date
      const currentDate =  moment().toDate();
      return moment(currentDate).subtract(90, 'days').startOf('day').toDate()
    })(), 
  });

  const OnLinkReservoir = () => {
    client.refetchQueries(ReactQueryKeys.AllTreeListQuery);
  };


useEffect(() => {
  setVisibleRangeChartStore(undefined, undefined);
}, [])

  interface DataModel {
    id: string;
    text?: string;
    icon?: ReactNode;
    iconClass?: string;
  }


  useEffect(() => {

    if (id)
    {

      if (tabId)
      {
        setSelectedTabId(+tabId);
      }

      var item = treeListData.find(x => x.id === id);
      if (item)
      {

      setSelectedItem(item);
      var items = new Array<string>();

      var parentId = item.parentId;
      while(parentId !== null)
      {
        items.unshift(parentId);
        var index = treeListData.findIndex(x => x.id === parentId)
        var nextitem = treeListData[index];
        parentId = nextitem.parentId;
      }

      const result = {};
      items.forEach(key => result[key] = true); 
      setExpandState(result);
      setSelectedState({[id]:true}); 
    }
    }
    /* else
    {
       var locations = treeListData.filter((item) => item.type == "Location" && item.parentId == null);

      if (locations.length > 0)
      {
        const result = {};
        locations.forEach(key => result[key.id] = true); 
        setSelectedItem(locations[0]);
        setSelectedState({[locations[0].id]:true});
      } 
    } */
  
   }, [id]); 

  useEffect(() => {

    if (selectedItem)
    {
      var item = treeListData.find(x => x.id === selectedItem.id);
      setSelectedItem(item);
      return; 
    }
  
    if (id)
    {

      if (tabId)
      {
        setSelectedTabId(+tabId);
      }

      var item = treeListData.find(x => x.id === id);
      if (item)
      {

      setSelectedItem(item);
      var items = new Array<string>();

      var parentId = item.parentId;
      while(parentId !== null)
      {
        items.unshift(parentId);
        var index = treeListData.findIndex(x => x.id === parentId)
        var nextitem = treeListData[index];
        parentId = nextitem.parentId;
      }

      const result = {};
      items.forEach(key => result[key] = true); 
      setExpandState(result);
      setSelectedState({[id]:true}); 
    }
    }
    else
    {
       var locations = treeListData.filter((item) => item.type == "Location" && item.parentId == null);

      if (locations.length > 0)
      {
        const result = {};
        locations.forEach(key => result[key.id] = true); 
        setSelectedItem(locations[0]);
        setSelectedState({[locations[0].id]:true});
      } 
    }
  
   }, [treeListData]); 

 
  function CreateBreadcrumb()
  {

    var items = new Array<DataModel>();

    if (selectedItem !== undefined)
    {
      var parentId = selectedItem?.parentId;

      while(parentId !== null)
      {
        var index = treeListData.findIndex(x => x.id === parentId)
        var nextitem = treeListData[index];
        items.unshift({id:nextitem!.id, text:nextitem?.name})
        parentId = nextitem.parentId;
      }

      items.unshift({id:"Site", text:"Site"});
    }
    
    return <Breadcrumb className="ml-3 mt-3" data={items} disabled={true} />;
  }

  const parentItems = useMemo(() => {
    
    if(selectedItem?.type === 'Endpoints')  {
      return treeListData.filter(item => item.parentId === selectedItem?.id).map(x => x.id)}
    if(selectedItem?.type === 'Points')
      return treeListData.filter(item => item.parentId === selectedItem?.id).map(x => x.id)
    if(selectedItem?.type === 'Asset') {
      const items = treeListData.filter(item => item.parentId === selectedItem?.id).map(x => x.id)
      if(items.length === 2) {
        return[{
          points: treeListData.filter(item => item.parentId === items[0]).map(x => x.id),
          endpoints: treeListData.filter(item => item.parentId === items[1]).map(x => x.id)
        }]
      } else if(items[0].split('_')[1] === 'points') {
        return [{
          points: treeListData.filter(item => item.parentId === items[0]).map(x => x.id),
          endpoints: []
        }]
      } else {
        return [{
          points: [],
          endpoints: treeListData.filter(item => item.parentId === items[0]).map(x => x.id)
        }]
      }
    }
    if(selectedItem?.type === 'Location') {

      return []
    }
    return []

  },[treeListData, selectedItem])
  
  const [selectedEndpointTab, setSelectedEndpointTab] = useState<number>(0);
  const handleSelectEndpointTab = (e: TabStripSelectEventArguments) => setSelectedEndpointTab(e.selected);
  const [selectedPointTab, setSelectedPointTab] = useState<number>(0);
  const handleSelectPointTab = (e: TabStripSelectEventArguments) => setSelectedPointTab(e.selected);

  function PageSelector()
  {
  
    if (selectedItem)
    {
      switch (selectedItem.type) {
        case "Location":
          return <LocationDetailsPage selectedItem={selectedItem.id} eventFilter={eventFilter}/>
        
        case "Asset":
          return <AssetDetailsPage key={`${selectedItem.id}AssetDetailsPage`} selectedItem={selectedItem.id} eventFilter={eventFilter} parentItems={parentItems}/>
            
        case "Point":
          return <PointDetailsPage selectedItem={selectedItem} eventFilter={eventFilter} selectedItemId={selectedItem.id} reservoirId={selectedItem.reservoirId} onLinkReservoir={OnLinkReservoir} tabId={selectedTabId}  selectedPointTab={selectedPointTab} handleSelectPointTab={handleSelectPointTab} />
        
        case "Endpoint":
          return <EndpointDetailsPage key={`${selectedItem.id}EndpointDetailsPage`} selectedTreeNode={selectedItem} eventFilter={eventFilter} onLinkReservoir={OnLinkReservoir} tabId={selectedTabId}  handleSelectEndpointTab={handleSelectEndpointTab} selectedEndpointTab={selectedEndpointTab}/>

        case "Points":
          return <AllPointsDetailsPage selectedItem={selectedItem.id} eventFilter={eventFilter} ids={parentItems}/>
          
        case "Endpoints":
          return <AllEndpointsDetailsPage key={`${selectedItem.id}AllEndpointsDetailsPage`} selectedItemId={selectedItem.id} eventFilter={eventFilter} endpointsId={parentItems}/>
    
        default:
          return <p>Display {selectedItem.type} - {selectedItem.id} Page</p>
          
      }
    }

    return <p>No item selected</p>
  }

  const [isStatusDialogOpen, setIsStatusDialogOpen] = useState<boolean>(false);
  const [statusUpdated, setStatusUpdated] = useState<boolean>(false);

  const updateStatus= async (assetId: string, status: []) => {
    setStatusUpdated(true);
    let response = await treeListApiService.updateStatus(assetId, status);
    client.refetchQueries("StatusOnOffQuery");
    client.refetchQueries("StatusOnOffQueryEndpoint");
  };

  useEffect(() => {
    setStatusUpdated(false);
  }, [statusUpdated]);

  //Loading renderer function to ensure all Queries all loaded.
  if (treeListQuery.isLoading || treeListQuery.isFetching) {
    return <LoadingSpinner />;
  } else {
    if (treeListQuery.error) {
      return <ErrorCard />;
    } else {
  return (
    <div>
      <div>
        {CreateBreadcrumb()}
        <div className="flex items-center justify-between mb-3">
        {selectedItem?.type === 'Endpoint' && selectedItem?.id !== null ? 
          <h2 className="ml-4 gb-h1">{selectedItem?.name} <span style={{fontSize:'16px'}}>{"(" + selectedItem?.data + ")"}</span></h2> :
          <h2 className="ml-4 gb-h1">{selectedItem?.name}</h2>
        }
        <div className="flex gap-5">
          {currentUserRole !== "GeneralUser" && (selectedItem?.type === 'Asset') &&
            <Button themeColor={"primary"} onClick={() => setIsStatusDialogOpen(true)} >{localizationService.toLanguageString('custom.manageOperationalState', enMessages.custom.manageOperationalState)}</Button>
          }
         
          <div className="flex gap-2 mr-4">
            <DatePicker calendar={CustomCalendar} max={eventFilter.endDate!}
            onChange={(date) => {
              setEventFilter((prev) => {
                return { ...prev, startDate: date.target.value };
              });
            }}
            value={eventFilter.startDate}
            className="w-72"
          />
          <div>-</div>

          <DatePicker calendar={CustomCalendar} min={eventFilter.startDate!}
            onChange={(date) => {
              setEventFilter((prev) => {
                return { ...prev, endDate: date.target.value };
              });
            }}
            value={eventFilter.endDate}
            className="w-72"
            max={moment().endOf('day').toDate()}
          />
          </div>
          </div>
        </div>
      </div>
      <div className="treelist-container m-4" style={{height:'80vh'}}>
      <Splitter style={{ height: '100%',  background:'#FAFAFA' }} panes={panes} onChange={onChange} >
        <div className="treelist-grid">
          <TreeListComponent 
            treeListData={treeListData} 
            selectedState={selectedState} 
            expandState={expandState} 
            onExpandChange={onTreeListExpandChange} 
            onSelectionChange={onTreeListSelectionChange}/>
        </div>
        <div className="tabstrip h-full">
          {PageSelector()}
        </div>
      </Splitter>
      </div>

      {isStatusDialogOpen && (
        <StatusDialog
          onClose={() => setIsStatusDialogOpen(false)}
          updateStatus={updateStatus}
          selectedId={selectedItem?.id}
        />
      )}
    </div>

  )
}}}
