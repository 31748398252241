import { useToast } from "@chakra-ui/react";
import { Button } from "@progress/kendo-react-buttons";
import { useLocalization } from '@progress/kendo-react-intl';
import { Grid,  GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { useQuery } from "react-query";
import greaseReservoirsApiService from "../Utilities/GreaseReservoirApiService";
import LoadingSpinner from "../../Shared/Components/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import { ClientRoutes } from "../../../Constants/ClientRoutingConstants";
import { PointDetailTabOrder } from "../../../Enums/PointDetailTabOrder";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { useRef } from "react";
import { LinkedPoints } from "../../../Models/Reservoirs/ReservoirLinkedPoints";
import { enMessages } from "../../../messages/en-US";
import { Tooltip } from "@progress/kendo-react-tooltip";

interface Props {
  reservoirId: string;
}

export default function ReservoirLinked(props: Props) {
  const toast = useToast({
    position: "bottom",
    duration: 5000,
    isClosable: true,
  });
  const localizationService = useLocalization();

  const key = "LinkedPoints" + props.reservoirId;

  const greaseReservoirsQuery = useQuery<Array<LinkedPoints>>(key, async () => {
    return greaseReservoirsApiService.getLinkedPoints(props.reservoirId);
  });

  const _export = useRef<ExcelExport | null>(null);
  const exportExport = () => {
    if (_export.current !== null) {
      _export.current.save(greaseReservoirsQuery.data);
    }
  };

  const navigate = useNavigate();

  const NameCell = (props: any) => {
    return (
      <td>
        <Button fillMode={'link'} style={{ color: '#388DF9' }} onClick={() => navigate(`${ClientRoutes.SiteExplorerPage}/${props.dataItem.id}/${PointDetailTabOrder.Reservoir}`)}>
          {props.dataItem.name}
        </Button>
      </td>
    );
  };

  if (greaseReservoirsQuery.isLoading || greaseReservoirsQuery.isFetching) {
    return <div style={{height:"200px",  width: '500px' }}><LoadingSpinner/></div>
  } else {
    return (
      <div>
        <ExcelExport ref={_export} fileName={localizationService.toLanguageString('custom.linkedPoints', enMessages.custom.linkedPoints)}>
          <Grid style={{ height:"230px",  width: "650px" }} data={greaseReservoirsQuery.data}>
            <GridToolbar>
              <div className="flex-1 justify-between items-center">
                <div className="gb-h4">{localizationService.toLanguageString('custom.linkedPoints', enMessages.custom.linkedPoints)}</div>
                  <Tooltip anchorElement="target" position="bottom">
                    <Button
                      title={localizationService.toLanguageString('custom.exportToExcel', enMessages.custom.exportToExcel)}
                      icon="download"
                      onClick={exportExport}
                    />
                </Tooltip>
              </div>
            </GridToolbar>
            <GridColumn field="name" title="Name" cell={NameCell} />
            <GridColumn field="areaName" title="Location" />
            <GridColumn field="machineName" title="Asset" />
            <GridColumn field="type" title="Type" />
            <GridColumn field="flowType" title="Flow Direction" />
          </Grid>
        </ExcelExport>
      </div>
    )
  }
}
