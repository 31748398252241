import { ReactElement, useMemo, useState } from "react";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";
import { Icon } from "@progress/kendo-react-common";
import { ActionsLayout, Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { ClientRoutes } from "../../../Constants/ClientRoutingConstants";

export default function SessionExpiredPage(): ReactElement {
  const localizationService = useLocalization();
  let navigate = useNavigate(); 
  const [visible, setVisible] = React.useState<boolean>(true);

  return (
    <Dialog>
      <div className="my-8">
        <div className="text-center mb-5">
          <Icon name="k-icon k-i-warning" style={{fontSize:'72px'}}/>
        </div>
        <div style={{ textAlign: "center" }}>
        {`${localizationService.toLanguageString('custom.sessionExpired', enMessages.custom.sessionExpired)}`}
        </div>
      </div>
      <DialogActionsBar layout="center">
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
          style={{padding:'8px 20px'}}
          onClick={() => {
            navigate(ClientRoutes.Logout)
            setVisible(!visible)
          }}
        >
          OK
        </button>
      </DialogActionsBar>
    </Dialog>
  )}
