import React, { useRef, useEffect, useMemo, ReactElement } from "react";
import useIdentityStore from "../../../../Zustand/identityStore";
import useKendoTableState from "../../../Shared/Hooks/useKendoTableState";
import { Grid, GridFilterChangeEvent, GridPageChangeEvent, GridSortChangeEvent, GridToolbar, GridColumn as Column,  GridRowProps } from "@progress/kendo-react-grid";
import { CompositeFilterDescriptor, filterBy, orderBy } from "@progress/kendo-data-query";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import GBKendoToolbar from "../../../Shared/Components/Table/GBKendoToolbar";
import { Button } from "@progress/kendo-react-buttons";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../../messages/en-US";
import { useInternationalization } from "@progress/kendo-react-intl";
import AdminAuthTokenReadDto from "../../../../Models/AuthToken/AdminAuthTokenReadDto";
import moment from "moment";
import { Tooltip } from "@progress/kendo-react-tooltip";
import AuthTokenDeleteDialog from "../Modals/AuthTokenDeleteDialog";


interface Props {
  initialSort: any;
  initialSortDescriptor: any;
  authTokens: Array<AdminAuthTokenReadDto>;
  createAuthToken: (values: any, setStatus: any) => void;
  deleteAuthToken: (authTokenId: string) => void;
  addTokenDialog: (item: boolean) => void;
  height: number;
}

const initialFilter: CompositeFilterDescriptor = {
  logic: "and",
  filters: [],
};

function AdministrationAuthTokenTable(props: Props) {
  const [tokenToDelete, setTokenToDelete] = React.useState<AdminAuthTokenReadDto | null>(null);

  const currentUserRole = useIdentityStore((x) => x.user?.profile.role);
  const tableState = useKendoTableState<AdminAuthTokenReadDto>({
    key: "AuthTokens",
    initialAssets: props.authTokens,
  });
  const localizationService = useLocalization();
  const intl = useInternationalization();
  


  const stateRef = useRef(tableState);

  useEffect(() => {
    stateRef.current = tableState

   }, [tableState]);


  const [filter, setFilter] = React.useState(initialFilter);
  const [sort, setSort] = React.useState(props.initialSortDescriptor ?? props.initialSort);
  const _export = React.useRef<ExcelExport | null>(null);

  /**
   * Set the skip amount to zero if the number of temp rows (indicating an add row has been added).
   * This effectively ensures the add row is always scrolled into view
   */
  useEffect(() => {
    if (tableState.tempRows.length === 1) {
      setSkip(0);
    }
  }, [tableState.tempRows.length]);

  //The following state is required for dynamic scrolling
  const [skip, setSkip] = React.useState<number>(0);
  const pageChange = (event: GridPageChangeEvent) => {
    setSkip(event.page.skip);
  };

  useEffect(() => {
    // Reset skip when filter or sort changes
    setSkip(0);
  }, [filter, sort]);

  //Data is API data + current temporary rows that have not yet been persisted using the API
  const processedData = useMemo(() => {
    
    let initialRows = tableState.assets;

    if (filter) {
      initialRows = filterBy(initialRows, filter);
    }

    if (sort) {
      initialRows = orderBy(initialRows, sort);
    }
    //Add any temp rows to beggining
    return tableState.tempRows.concat(initialRows);
  }, [tableState.assets, tableState.tempRows, filter, sort]);

  const onDelete = () => {
  
    if (tokenToDelete != null)
    {
      props.deleteAuthToken(tokenToDelete.id);
    }
    
    setTokenToDelete(null);
  };

  const CreatedCell = (props) => {
    return (
      <td>
        <div>{moment(props.dataItem.created).format('DD/MM/YYYY @ HH:mm:ss')}</div>
      </td>
      );
  }

  const LastUsedCell = (props) => {
    const tooltip = React.useRef<Tooltip>(null);
    const TooltipContentTemplate = (props: any) => {
      return (
        <div >{props.title}</div>
      );
    };
    const lastSeen = moment(props.dataItem.lastUsed);
              let tootipText
              let lastSeenText
              if (lastSeen.year() !== 1970)
              {
                tootipText = moment(props.dataItem.lastUsed).format('DD/MM/YYYY @ HH:mm:ss').toString()
                lastSeenText = lastSeen.fromNow()

              } else {
                tootipText = `Auth Token not yet used`
                lastSeenText = `${localizationService.toLanguageString('custom.never', enMessages.custom.never)}`
              }
              return(
              <td>
              <div style={{display:'flex', alignItems:'center', }}>
              <Tooltip ref={tooltip} anchorElement="target" position="bottom" openDelay={2} content={(props) => <TooltipContentTemplate title={props.title} />}>
                <div style={{cursor:'pointer'}} title={tootipText} onMouseOver={(event) => tooltip.current && tooltip.current.handleMouseOver(event)}
                onMouseOut={(event) => tooltip.current && tooltip.current.handleMouseOut(event)}>{lastSeenText}</div>
              </Tooltip>
            </div>
            </td>);
  }

  return (
    <div className="m-5">
      <h1 className="gb-h1">Manage Auth Tokens</h1>
        <h2 className="gb-text-normal mb-6">
        Create new tokens.
        </h2>
        <Grid
          key={tableState.tempRows.length.toString()}
          rowHeight={46}
          total={processedData.length}
          data={processedData}
          scrollable="virtual"
          skip={skip}
          onPageChange={pageChange}
          expandField="expanded"
          filterable={true}
          pageSize={30}
          filter={filter}
          onFilterChange={(e: GridFilterChangeEvent) => setFilter(e.filter)}
          sortable={true}
          sort={sort}
          onSortChange={(e: GridSortChangeEvent) => {
            setSort(e.sort);
          }}
          style={{
            height: props.height,
          }}
          className="w-full  z-0 extra-filter-button"
          //Custom render row,
          rowRender={(trElement: ReactElement, props: GridRowProps) => {
            let baseStyle = { ...trElement.props.style };
            if (tableState.modifiedRowIds.includes(props.dataItem.id)) {
              baseStyle.backgroundColor = "rgba(255, 252, 88, 0.25)";
            }
            if (tableState.selectedRowsIds.includes(props.dataItem.id)) {
              baseStyle.backgroundColor = "rgba(255, 99, 88, 0.25)";
            }

            return React.cloneElement(
              trElement,
              {
                ...trElement,
                style: { ...baseStyle },
              },
              trElement.props.children
            );
          }}
        >
          <GridToolbar>  
            <GBKendoToolbar
              name="Auth Tokens"
              tableState={tableState}
              exportAction={null}
              addAction={null}
              updateAction={null}
              deleteAction={null}
              discardChanges={null}
              importAction={null}
              extraToolbarActions={[
                {
                  icon: "add",
                  text: "Create Auth Token",
                  onClick: () =>  props.addTokenDialog(true),
                  themeColor: "primary",
                },
              ]}
            />
          </GridToolbar>
          <Column field="name" title="Name" ></Column>
          <Column field="token" title="Token" ></Column>
          <Column field="createdBy" title="Created By" ></Column>
          <Column field="created" title="Created" cell={CreatedCell}></Column>
          <Column field="lastUsed" title={'Last Used'} cell={LastUsedCell} ></Column>

          {currentUserRole !== "GeneralUser" && (
          <Column
            title={localizationService.toLanguageString('custom.actions', enMessages.custom.actions)}
            width="110px"
            sortable={false}
            filterable={false}
            cell={(cellProps) => {
              return (
                <td className="flex justify-around">
                <Button
                  className=""
                  themeColor={"primary"}
                  onClick={async () => {setTokenToDelete(cellProps.dataItem)}}
                >
                  {localizationService.toLanguageString('custom.delete', enMessages.custom.edit)}
                </Button>
                </td>
              );
            }}
          />)}

        </Grid>
        {tokenToDelete != null && (
        <AuthTokenDeleteDialog 
        onClose={() =>setTokenToDelete(null)}
        deleteCallback={onDelete}
        deletingItemLabel={tokenToDelete?.name}
        />
        )}
    </div>
  );
}

export default AdministrationAuthTokenTable;