import { ReactElement, useEffect, useMemo, useState, } from "react";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../../../messages/en-US";
import { EventFilter } from "../../../../Events/Utilities/EventsApiService";
import { useQuery } from "react-query";
import { ReactQueryKeys } from "../../../../../Constants/ClientRoutingConstants";
import moment from "moment";
import treeListApiService from "../../../Utilities/TreeListApiService";
import LoadingSpinner from "../../../../Shared/Components/LoadingSpinner";
import ErrorCard from "../../../../Shared/Components/Cards/ErrorCard";
import NoDataPage from "../../NoDataPage";
import EndpointPressureEventsDto from "../../../../../Models/Endpoint/EndpointPressureEventsDto";
import EndpointActivityTabPageForTables from "./EndpointActivityTabPageForTables";
import EndpointStatusActivity from "./EndpointStatusActivity";
import EndpointStatusPressureActivity from "./EndpointStatusPressureActivity";
import useChartStore from "../../../../../Zustand/chartStore";
import { ChartToolbar } from "../../../Utilities/ChartToolbar";
import EndpointStatusFlowRateActivity from "./EndpointStatusFlowRateActivity";
import EndpointFlowRateEventsDto from "../../../../../Models/Endpoint/EndpointFlowRateEventsDto";

interface Props {
  eventFilter: EventFilter
  selectedItem: string
  selectedItemSerialNo: string;
  endpointFeature: any;
}

export default function EndpointActivityTabPage(props: Props): ReactElement {
  const localizationService = useLocalization();

  const aggregator = useChartStore((state) => state.aggregator);

  const endpointsLastSeenQuery = useQuery<any>([ReactQueryKeys.EndpointsLastSeenQuery, props.eventFilter, props.selectedItemSerialNo], async () => {
    return treeListApiService.getEndpointsLastSeen(props.selectedItemSerialNo);
  }, {enabled: props.selectedItemSerialNo !== ''});

  const endpointsQuery = useQuery<any>([`endpointChart${props.selectedItem}`, props.selectedItem, props.eventFilter, aggregator], async () => {
    return  treeListApiService.getAllEndpointsEventsFlowChart(props.eventFilter, props.selectedItem, aggregator) 
  });

  const lastSeenData = useMemo(() => {
    if(props.selectedItemSerialNo !== '') {
      return moment(endpointsLastSeenQuery.data).unix()
    } else {
        if(endpointsQuery.data?.timestamps.length === 0) {
          return 0
        }else {
          return endpointsQuery.data?.timestamps[endpointsQuery.data?.timestamps.length - 1]
        }
    }
  }, [endpointsLastSeenQuery.data, endpointsQuery.data])

  const lastSeenFormatted = lastSeenData === 0 ? localizationService.toLanguageString('custom.noEventsToDetermine', enMessages.custom.noEventsToDetermine) : aggregator === 'daily' && props.selectedItemSerialNo === '' ? moment.unix(lastSeenData).format('DD/MM/YYYY') : moment.unix(lastSeenData).format('DD/MM/YYYY @ HH:mm:ss')

  const endpointPressureEventsChartQuery = useQuery<EndpointPressureEventsDto[]>([`endpointPressureEventsChartQuery${props.selectedItem}`, props.selectedItem, props.eventFilter, aggregator], async () => {
    return  treeListApiService.getEndpointPressureEventsChart(props.selectedItem, props.eventFilter, aggregator) 
  }, {enabled: props.endpointFeature.isPressureSupported});

  const endpointFlowRateEventsChartQuery = useQuery<EndpointFlowRateEventsDto[] | any>([`endpointFlowRateEventsChartQuery${props.selectedItem}`, props.selectedItem, props.eventFilter, aggregator], async () => {
    return  treeListApiService.getEndpointFlowRateEventsChart(props.selectedItem, props.eventFilter, 'none') 
  }, {enabled: props.endpointFeature.isFlowRateSupported});

  const endpointTemperatureEventsChartQuery = useQuery<any>([`endpointTemperatureEventsChartQuery${props.selectedItem}`, props.selectedItem, props.eventFilter, aggregator], async () => {
    return  treeListApiService.getEndpointTemperatureEventsChart(props.selectedItem, props.eventFilter, 'none') 
  }, {enabled: props.endpointFeature.isTemperatureSupported});

  const notificationsQuery = useQuery<any>("notificationsStatus", async () => {
    return treeListApiService.getEndpointNotifications(props.selectedItem, props.eventFilter, 1);
  });

  const statusQuery = useQuery<any>("statusChart", async () => {
    return treeListApiService.getEndpointStatus(props.selectedItem, props.eventFilter, 0);
  });

  useEffect(() => {
    statusQuery.refetch();
  }, [props.eventFilter]);

  const chartData = useMemo(() => {

    if (props.endpointFeature.isPressureSupported)
    {
      return {
        statusDataChart: {
          dateFilter: props.eventFilter, 
          timestamps: endpointsQuery.data?.timestamps, 
          statusData:statusQuery.data, 
          alerts:notificationsQuery.data
        },
        activityPressureDataChart: {
          dateFilter: props.eventFilter, 
          timestamps: endpointsQuery.data?.timestamps, 
          volumeDispensedData:endpointsQuery.data?.volumeDispensedData, 
          strokeThickness: 1, 
          lastSeen:lastSeenData,
          lastSeenFormatted:lastSeenFormatted,
          pressureEvents:endpointPressureEventsChartQuery.data,
        }
      }
    }
    else if (props.endpointFeature.isFlowRateSupported )
    {
      return {
        statusDataChart: {dateFilter: props.eventFilter, timestamps: endpointFlowRateEventsChartQuery.data?.timestamps, statusData:statusQuery.data, alerts:notificationsQuery.data},
        activityFlowRateDataChart: {dateFilter: props.eventFilter,  timestamps: endpointFlowRateEventsChartQuery.data?.timestamps, minFlowRate: endpointFlowRateEventsChartQuery.data?.minFlowRate, maxFlowRate: endpointFlowRateEventsChartQuery.data?.maxFlowRate, avgFlowRate: endpointFlowRateEventsChartQuery.data?.avgFlowRate, volume: endpointFlowRateEventsChartQuery.data?.volume, strokeThickness: 1, lastSeen:lastSeenData ,lastSeenFormatted:lastSeenFormatted, temperatureData: endpointTemperatureEventsChartQuery.data}
      }
    } else
    {
      return {
        statusDataChart: {dateFilter: props.eventFilter, timestamps: endpointsQuery.data?.timestamps, statusData:statusQuery.data, alerts:notificationsQuery.data},
        activityDataChart: {dateFilter: props.eventFilter, timestamps: endpointsQuery.data?.timestamps, volumeDispensedData:endpointsQuery.data?.volumeDispensedData, strokeThickness: 1, lastSeen:lastSeenData ,lastSeenFormatted:lastSeenFormatted}
      }
  }
  }, [endpointsQuery.data, endpointsLastSeenQuery.data, notificationsQuery.data, statusQuery.data, endpointPressureEventsChartQuery.data,endpointFlowRateEventsChartQuery.data])

  if (endpointsQuery.isLoading || endpointsQuery.isFetching || endpointsLastSeenQuery.isLoading || endpointPressureEventsChartQuery.isLoading || notificationsQuery.isLoading || notificationsQuery.isFetching || statusQuery.isLoading || statusQuery.isFetching || endpointFlowRateEventsChartQuery.isLoading || endpointFlowRateEventsChartQuery.isFetching || endpointTemperatureEventsChartQuery.isLoading || endpointTemperatureEventsChartQuery.isFetching) {
    return <LoadingSpinner />;
  } else if (endpointsQuery.isError || endpointsLastSeenQuery.isError || endpointPressureEventsChartQuery.isError || notificationsQuery.isError || statusQuery.isError || endpointFlowRateEventsChartQuery.isError || endpointTemperatureEventsChartQuery.isError) {
    return <ErrorCard />;
  } else {
  return (
    <>
    {(endpointsQuery.data !== undefined && endpointsQuery.data?.timestamps.length !== 0) || (endpointFlowRateEventsChartQuery.data !== undefined) ? (
      endpointFlowRateEventsChartQuery.data?.timestamps.length !== 0 ?  
      <div>
        <ChartToolbar 
          title={localizationService.toLanguageString('custom.events', enMessages.custom.events)}
          lastSeen={lastSeenFormatted}
          showAutoRange={true} 
          showAggregatorOptions={!props.endpointFeature.isFlowRateSupported} 
          showChartOptions={!props.endpointFeature.isPressureSupported}
          showThresholds={false} 
          showSingleMultipleChart={props.endpointFeature.isPressureSupported}
        />
        {props.endpointFeature.isPressureSupported ? 
          <EndpointStatusPressureActivity statusChartData={chartData.statusDataChart} activityPressureChartData={chartData.activityPressureDataChart} />
          :
          props.endpointFeature.isFlowRateSupported ? 
          <EndpointStatusFlowRateActivity statusChartData={chartData.statusDataChart} activityFlowRateChartData={chartData.activityFlowRateDataChart} />
          :
          <EndpointStatusActivity statusChartData={chartData.statusDataChart} activityChartData={chartData.activityDataChart}/>
        }
        <EndpointActivityTabPageForTables aggregator={aggregator} eventFilter={props.eventFilter} selectedItem={props.selectedItem} endpointFeature={props.endpointFeature}/>
      </div> :
       <EndpointActivityTabPageForTables aggregator={aggregator} eventFilter={props.eventFilter} selectedItem={props.selectedItem} endpointFeature={props.endpointFeature}/>
    ) : (
      <NoDataPage/>
    )}
  </>
    
  )
}}
