import { PointWithScheduleUpdateDto } from "./../../../Models/Point/PointWithScheduleUpdateDto";
import { PointWithScheduleCreateDto } from "./../../../Models/Point/PointWithScheduleCreateDto";
import { ApiRoutes } from "../../../Constants/ApiRoutingConstants";
import axios, { AxiosInstance } from "axios";
import authorizeService from "../../Identity/Utilities/AuthorizeService";
import { PointCreateDto } from "../../../Models/Point/PointCreateDto";
import { PointReadDto } from "../../../Models/Point/PointReadDto";

/**
 * This service provides implementations for http calls to the api pertaining to point crud functionality
 */

class PointsApiService {
  axiosInstance: AxiosInstance;
  constructor() {
    //Subscribe to identity store the get token
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_ASSET_SERVICE_URL,
    });
  }

  async getAllPoints() {
    let response = await this.axiosInstance({
      method: "GET",
      url: `${ApiRoutes.Points}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data as Array<PointReadDto>;
  }

  async createPointWithSchedule(point: PointWithScheduleCreateDto) {
    let response = await this.axiosInstance({
      method: "POST",
      url: `${ApiRoutes.Points}/withSchedule`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: point,
    });
    return response.data as PointReadDto;
  }

  async updatePointWithSchedule(point: PointWithScheduleCreateDto, pointId: string) {
    let response = await this.axiosInstance({
      method: "PUT",
      url: `${ApiRoutes.Points}/withSchedule/${pointId}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: point,
    });
    return response.data as PointReadDto;
  }

  async updatePointsWithSchedule(points: Array<PointWithScheduleUpdateDto>) {
    let response = await this.axiosInstance({
      method: "PUT",
      url: `${ApiRoutes.Points}/withSchedule`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: { points },
    });
    return response.data;
  }


  async deletePoints(deletePointIds: Array<string>) {
    let response = await this.axiosInstance({
      method: "DELETE",
      url: `${ApiRoutes.Points}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: {
        ids: deletePointIds,
      },
    });
    return response.data;
  }

  static get instance() {
    return pointsApiService;
  }
}

const pointsApiService = new PointsApiService();
export default pointsApiService;
