import { ReactElement} from "react";
import { TabStrip,TabStripSelectEventArguments,TabStripTab,} from '@progress/kendo-react-layout';
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../../messages/en-US";
import React from "react";
import { EventFilter } from "../../../Events/Utilities/EventsApiService";
import NoDataPage from "../NoDataPage";
import AllEndpointActivityTabPage from "./Activity/AllEndpointsActivityTabPage";

interface Props {
  selectedItemId: string
  eventFilter: EventFilter
  endpointsId: string[]
}

export default function AllEndpointsDetailsPage(props: Props): ReactElement {
  const localizationService = useLocalization();

  //TabStrip
  const [selected, setSelected] = React.useState<number>(0);

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected);
  };
  
  return (
    <div className="tabstrip">
      {props.endpointsId?.length > 0 ?
      <TabStrip selected={selected} onSelect={handleSelect} className="">
        <TabStripTab title={`${localizationService.toLanguageString('custom.activity', enMessages.custom.activity)}`}>
        <AllEndpointActivityTabPage 
              key={`${props.selectedItemId}AllEndpointActivityTabPage`}
              eventFilter={props.eventFilter} 
              endpointsIds={props.endpointsId}
              selectedAssetId={props.selectedItemId}
              />
        </TabStripTab>
      </TabStrip>
      :
      <NoDataPage/>
      }
    </div>
  )
}
