import React, { ReactElement, useMemo } from "react";
import { useQuery, useQueryClient } from "react-query";
import { ReactQueryKeys } from "../../../Constants/ClientRoutingConstants";
import { useToast } from "@chakra-ui/react";
import machinesApiService from "../Utilities/MachinesApiService";
import { MachineCreateDto } from "../../../Models/Machine/MachineCreateDto";
import { MachineReadDto } from "../../../Models/Machine/MachinesReadDto";
import ErrorCard from "../../Shared/Components/Cards/ErrorCard";
import LoadingSpinner from "../../Shared/Components/LoadingSpinner";
import MachineTable from "../Components/MachineTable";
import AreaReadDto from "../../../Models/Area/AreaReadDto";
import areasApiService from "../../Area/Utilities/AreasApiService";
import { MachineUpdateDto } from "../../../Models/Machine/MachineUpdateDto";
import machineValidationSchema from "../Utilities/MachineValidationSchema";
import { MachineReadTableDto } from "../../../Models/Machine/MachineReadTableDto";
import { PointReadDto } from "../../../Models/Point/PointReadDto";
import pointsApiService from "../../Points/Utilities/PointsApiService";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";
import { useWindowSize } from "usehooks-ts";
import { Dialog } from "@progress/kendo-react-dialogs";

interface Props {
  isOpenDialog: any;
  setIsOpenDialog: any;
}

export default function MachinesPage(props: Props): ReactElement {
  //Server side state
  const client = useQueryClient();
  const toast = useToast({
    position: "bottom",
    duration: 5000,
    isClosable: true,
  });

  const localizationService = useLocalization();

  //get window size
  const { width, height } = useWindowSize()

  const pointsQuery = useQuery<Array<PointReadDto>>(ReactQueryKeys.AllPointsQuery, async () => {
    return pointsApiService.getAllPoints();
  });

  const machinesQuery = useQuery<Array<MachineReadDto>>(ReactQueryKeys.AllMachinesQuery, async () => {
    return machinesApiService.getAllMachines();
  });

  const areasQuery = useQuery<Array<AreaReadDto>>(ReactQueryKeys.AllAreasQuery, async () => {
    return areasApiService.getAllAreas();
  });

  const deleteMachines = async (selectedMachineIds: Array<string>) => {
    try {
      let response = await machinesApiService.deleteMachines(selectedMachineIds);
      //If delete is successful, refetch the updated list of areas and show success message
      client.refetchQueries(ReactQueryKeys.AllMachinesQuery);
      toast({
        status: "success",
        description: `${localizationService.toLanguageString('custom.assetsDeleted', enMessages.custom.assetsDeleted)}`,
      });
    } catch (e) {
      toast({
        status: "error",
        description: `${localizationService.toLanguageString('custom.assetsDeletedError', enMessages.custom.assetsDeletedError)}`,
      });
    }
  };

  const updateMachines = async (machines: Array<MachineUpdateDto>) => {
    try {
      let response;
      if (machines.length === 1) {
        response = await machinesApiService.updateMachine(machines[0], machines[0].id);
      } else if (machines.length > 1) {
        response = await machinesApiService.updateMachines(machines);
      }
      //If update is successful, refetch the updated list of areas and show success message
      client.refetchQueries(ReactQueryKeys.AllMachinesQuery);
      toast({
        status: "success",
        description: `${localizationService.toLanguageString('custom.assetsUpdated', enMessages.custom.assetsUpdated)}`,
      });
    } catch (e: any) {
      var errorMessage;
      if (machines.length === 1) {
        errorMessage = `${localizationService.toLanguageString('custom.assetsUpdatedError', enMessages.custom.assetsUpdatedError)}.\n
        ${e?.response?.data?.errors ? Object.values(e?.response?.data?.errors)[0] : ""}`;
      } else {
        errorMessage = `${localizationService.toLanguageString('custom.assetsIncorrectInput', enMessages.custom.assetsIncorrectInput)}\n
          ${e?.response?.data?.map((machineId: string) => machines.find((a) => a.id === machineId)?.name)}
        `;
      }
      toast({
        status: "error",
        description: errorMessage,
      });
    }
  };

  const createMachine = async (values: any, setStatus: any) => {
    //Attempt to create the machine
    try {
      //Apply validationSchemaTransform
      const castValues = machineValidationSchema.cast(values) as MachineCreateDto;

      //Submit values to api
      const result = await machinesApiService.createMachine(castValues);
      client.refetchQueries(ReactQueryKeys.AllMachinesQuery);

      //On success, show success toast, then refresh data
      toast({
        status: "success",
        description: `'${values.name}' ${localizationService.toLanguageString('custom.consumableCreated', enMessages.custom.consumableCreated)}`,
      });
      return true;
    } catch (e: any) {
      //On failure show error toast, set status messages
      setStatus(e?.response?.data?.errors);

      toast({
        title: `${localizationService.toLanguageString('custom.error', enMessages.custom.error)}`,
        status: "error",
        description: `${localizationService.toLanguageString('custom.assetsCreatedError', enMessages.custom.assetsCreatedError)}`,
      });
      return false;
    }
  };

  /**
   * This function prepares data for machines table, including injecting additional metadata.
   */
  const machineData = useMemo(() => {
    //Create hashmap of areaId -> name pairs.
    const areaIdtoName: { [id: string]: string } = {};
    const machineIdtoName: { [id: string]: string } = {};
    const pointTag: { [id: string]: number } = {};
    areasQuery.data?.forEach((area) => {
      areaIdtoName[area.id] = area.name;
    });
    machinesQuery.data?.forEach((machine) => {
      machineIdtoName[machine.id] = machine.name;
    });
    pointsQuery.data?.forEach((point) => {
      if(pointTag[point.machineId]) {
        pointTag[point.machineId] = pointTag[point.machineId] + 1
      } else {
        pointTag[point.machineId] = 1
      }
    });

    //Map over machines to inject relevant data
    const results =  machinesQuery.data?.map((machine) => {
      return {
        ...machine,
        parentName: machineIdtoName[machine.parentId] ?? null,
        areaName: areaIdtoName[machine.areaId] ?? null,
        pointTag: pointTag[machine.id] ?? 0,

      } as MachineReadTableDto;
    });
    
    return results;
  }, [areasQuery.data, machinesQuery.data, pointsQuery.data]);

  //Loading renderer function to ensure all Queries all loaded.
  if (machinesQuery.isLoading || areasQuery.isLoading || pointsQuery.isLoading || machinesQuery.isFetching || areasQuery.isFetching || pointsQuery.isFetching) {
    return <LoadingSpinner />;
  } else {
    if (machinesQuery.error || areasQuery.error || pointsQuery.error) {
      return <ErrorCard />;
    } else {
      return (
        <div>
          {/* Areas Table */}
          <div className="mt-4">
            <MachineTable
              machines={machineData!}
              areas={areasQuery.data!}
              points={pointsQuery.data!}
              createMachine={createMachine}
              deleteMachines={deleteMachines}
              updateMachines={updateMachines}
              height={690}
            />
          </div>

          {props.isOpenDialog.machines && (
            <Dialog
              title={localizationService.toLanguageString('custom.assets', enMessages.custom.assets)}
              onClose={() => props.setIsOpenDialog({...props.isOpenDialog, machines: false})}
            >
              <MachineTable
                machines={machineData!}
                areas={areasQuery.data!}
                points={pointsQuery.data!}
                createMachine={createMachine}
                deleteMachines={deleteMachines}
                updateMachines={updateMachines}
                height={height - 200}
              />
            </Dialog>
          )}
        </div>
      );
    }
  }
}
