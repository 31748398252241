import { useLocalization } from "@progress/kendo-react-intl";
import { enMessages } from "../../../messages/en-US";
import { Button } from "@progress/kendo-react-buttons";
import { ManualComplianceChart } from "./ManualComplianceChart";
import { useNavigate } from "react-router-dom";
import { ClientRoutes, ReactQueryKeys } from "../../../Constants/ClientRoutingConstants";
import moment from "moment";
import { useQuery } from "react-query";
import dashboardApiService from "../Utilities/DashboardApiService";
import ManualComplianceReadDto from "../../../Models/Dashboard/ManualComplianceReadDto";
import { useMemo } from "react";
import NoDataAvailablePage from "../Utilities/NoDataAvailablePage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { Loader } from "@progress/kendo-react-indicators";
import { useAccountSettingsStore } from "../../../Zustand/accountSettingsStore";

export default function ManualComplianceTile() {
  const localizationService = useLocalization();
  let navigate = useNavigate();
  const accountTimezone = useAccountSettingsStore(state => state.accountTimezone);

  const startDate = moment().subtract(7, 'days').startOf('isoWeek');
  const endDate = moment().subtract(7, 'days').startOf('isoWeek').endOf('isoWeek')
  const dateRangeFormatted = startDate.format('DD/MM/YYYY') + " - " + endDate.format('DD/MM/YYYY')

  //Convert date to Utc using account timezone
  const dateRangeUtc = {
    startDate: moment.tz(startDate.format("YYYY-MM-DDT00:00:00"), accountTimezone).utc(), 
    endDate: moment.tz(endDate.format("YYYY-MM-DDT23:59:59"), accountTimezone).utc()
  };

  const redColour = "#ff5034";
  const greenColour = "#1ab394";
  const greyColour = "rgba(211,211,211,0.2)";

  const manualComplianceQuery = useQuery<ManualComplianceReadDto>([ReactQueryKeys.DashboardManualComplianceQuery, dateRangeUtc], async () => {
    return dashboardApiService.getManualCompliance(dateRangeUtc);
  });

  const manualComplianceData = useMemo(() => {
    if(manualComplianceQuery.data !== undefined) {
      return {
        count: manualComplianceQuery.data.count,
        rightGrease: manualComplianceQuery.data.rightGrease,
        rightAmount: manualComplianceQuery.data.rightAmount,
        rightTime: manualComplianceQuery.data.rightTime,
        pendingCount: manualComplianceQuery.data.pendingCount,
        compliantCount: manualComplianceQuery.data.compliantCount,
        nonCompliantCount: manualComplianceQuery.data.nonCompliantCount,
        textColour: "black",
        backgroundColourRightGrease: manualComplianceQuery.data.rightGrease < 100 ? redColour : greenColour,
        backgroundColourRightAmount: manualComplianceQuery.data.rightAmount < 100 ? redColour : greenColour,
        backgroundColourRightTime: manualComplianceQuery.data.rightTime < 100 ? redColour : greenColour
      }
    } else {
      return {}
    }
  }, [manualComplianceQuery.data]);

    const getIcon = (backgroundColour: string | undefined) => {
    if(backgroundColour === redColour) {
      return <FontAwesomeIcon icon={faExclamationCircle} style={{color: backgroundColour, fontSize: "30px"}} />
    } else {
      return <FontAwesomeIcon icon={faCheckCircle} style={{color: backgroundColour, fontSize: "30px"}} />
    }
  }

  if(manualComplianceQuery.isLoading || manualComplianceQuery.isFetching) {
    return <Loader size="medium" type={"pulsing"} className="loading-dashboard"/>
  } else if (manualComplianceQuery.isError || manualComplianceData.count === 0 || manualComplianceData.rightGrease === -1 || manualComplianceData.rightAmount === -1 || manualComplianceData.rightTime === -1) {
    return <>
      <NoDataAvailablePage title={localizationService.toLanguageString('custom.manualCompliance', enMessages.custom.manualCompliance)} period={dateRangeFormatted} clientRoute={ClientRoutes.ComplianceReportPage} />
    </>
  } else {
  return (
    <div className="m-2">
      <div className="flex justify-between align-middle">
        <div className="text-xl font-bold">{localizationService.toLanguageString('custom.manualCompliance', enMessages.custom.manualCompliance)}</div>
        <Button fillMode={"link"} style={{color:"blue", textDecoration: "underline" }} onClick={() => navigate(ClientRoutes.ComplianceReportPage)}>{localizationService.toLanguageString('custom.seeAll', enMessages.custom.seeAll)}</Button>
      </div>
        <div>
          <div className="mt-1">
            <div className="ml-1">{dateRangeFormatted}</div>
          </div>
          <div className="mt-2 m-auto grid grid-cols-6 gap-6">
           <div className="w-full col-span-4">
            <ManualComplianceChart chartData={{manualComplianceData: manualComplianceQuery.data, localizationService: localizationService}} />
          </div>
  
            <div className="w-full col-span-2">
              <div className="rounded m-auto px-2 py-2 " style={{color: manualComplianceData.textColour, background: greyColour}}
              >
                <div className="">{localizationService.toLanguageString('custom.rightGrease', enMessages.custom.rightGrease)}</div>
                <div className="flex justify-between items-center">
                <div className=" text-3xl">
                  {manualComplianceData.rightGrease === 0
                    ? "-"
                    : manualComplianceData.rightGrease}%
                </div>
                {getIcon(manualComplianceData.backgroundColourRightGrease)}   
                </div>
              </div>
              <div className="rounded m-auto mt-2 px-2 py-2" style={{color: manualComplianceData.textColour, background: greyColour}}
              >
                <div className="">{localizationService.toLanguageString('custom.rightAmount', enMessages.custom.rightAmount)}</div>
                <div className="flex justify-between items-center">
                <div className=" text-3xl">
                  {manualComplianceData.rightAmount === 0
                    ? "-"
                    : manualComplianceData.rightAmount}%
                </div>
                {getIcon(manualComplianceData.backgroundColourRightAmount)}   
                </div>
              </div>
              <div className="rounded m-auto mt-2 px-2 py-2" style={{color: manualComplianceData.textColour, background: greyColour}}
              >
                <div className="">{localizationService.toLanguageString('custom.rightTime', enMessages.custom.rightTime)}</div>
                <div className="flex justify-between items-center">
                <div className=" text-3xl">
                  {manualComplianceData.rightTime === 0
                    ? "-"
                    : manualComplianceData.rightTime}%
                </div>
                {getIcon(manualComplianceData.backgroundColourRightTime)}
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
}}
