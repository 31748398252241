import { ApiRoutes } from "../../../Constants/ApiRoutingConstants";
import axios, { AxiosInstance } from "axios";
import authorizeService from "../../Identity/Utilities/AuthorizeService";
import { NotificationReadDto } from "../../../Models/Notifications/NotificationReadDto";
import { NotificationReadTableDto } from "../../../Models/Notifications/NotificationReadTableDto";


/**
 * This service provides implementations for http calls to the api pertaining to alerts crud functionality
 */

class NotificationsApiService {
  axiosInstance: AxiosInstance;
  constructor() {
    //Subscribe to identity store the get token
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_ASSET_SERVICE_URL,
    });
  }

  async getAllNotifications(): Promise<NotificationReadTableDto[]> {
    let response = await this.axiosInstance({
      method: "GET",
      url: `${ApiRoutes.Notifications}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data;
  }

  async getAllNotificationsSummary(): Promise<NotificationReadDto[]> {
    let response = await this.axiosInstance({
      method: "GET",
      url: `${ApiRoutes.NotificationsSummary}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data;
  }

  static get instance() {
    return NotificationsApiService;
  }
}

const notificationsApiService = new NotificationsApiService();
export default notificationsApiService;
