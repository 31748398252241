import { EAxisAlignment, NumericAxis, SciChartJSLightTheme, SciChartSurface, StackedColumnCollection, StackedColumnRenderableSeries, Thickness, XyDataSeries } from "scichart";

async function initSciChartReservoirsReport(
  reservoir: any
  ) 

  {
    const createReservoirChart = async (reservoir:any) => {
      const theme = {... new SciChartJSLightTheme()}
      theme.loadingAnimationForeground = "rgba(255, 54, 48, 1)";
      theme.loadingAnimationBackground = '#FAFAFA'; 

      const {sciChartSurface, wasmContext} = await SciChartSurface.create(`res${reservoir.reservoirId}`, {theme});

      sciChartSurface.padding = Thickness.fromNumber(-10);
  
      const xAxis = new NumericAxis(wasmContext, { 
        axisAlignment: EAxisAlignment.Left, 
        autoTicks:false,
      },);
        
      const yAxis = new NumericAxis(wasmContext,{
        isVisible:false,
        axisAlignment: EAxisAlignment.Top,
      });

      sciChartSurface.xAxes.add(xAxis);
      sciChartSurface.yAxes.add(yAxis);

      const percentage = reservoir.estimatedVolumeAsPercentage < 0 ? 0 : reservoir.estimatedVolumeAsPercentage ;
      const xValues = [0];
      const yValues1 = [1 - percentage];
      const yValues2 = [percentage];

      const reservoirLevel = new StackedColumnCollection(wasmContext,{isOneHundredPercent:true , dataPointWidth:0.9});
      reservoirLevel.add(new StackedColumnRenderableSeries(wasmContext, {
        dataSeries: new XyDataSeries(wasmContext, { xValues: xValues, yValues:yValues1, dataSeriesName: reservoir.reservoirId }),
        fill: "lightGrey",
        stackedGroupId: "StackedGroupId-First"
      }));
      

      reservoirLevel.add(new StackedColumnRenderableSeries(wasmContext, {
        dataSeries: new XyDataSeries(wasmContext, { xValues, yValues: yValues2, dataSeriesName: reservoir.reservoirId }),
        fill: "rgba(26, 179, 148, 1)",
        stackedGroupId: "StackedGroupId-First"
      }));

    sciChartSurface.renderableSeries.add(reservoirLevel);          

    return {sciChartSurface,};
    }

    let loadingChartsReservoir: any[] = [];
    for(let i = 0; i < reservoir.length; i++) {
      if(!Number.isNaN(reservoir[i].estimatedVolumeAsPercentage)) {
      loadingChartsReservoir.push(createReservoirChart(reservoir[i]))};
    }
  
    const allChartsReservoir = await Promise.all(loadingChartsReservoir);

    return {allChartsReservoir};
  }

export default initSciChartReservoirsReport;
