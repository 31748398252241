import { ReactElement } from "react";
import { useQuery } from "react-query";
import { ReactQueryKeys } from "../../../Constants/ClientRoutingConstants";
import ErrorCard from "../../Shared/Components/Cards/ErrorCard";
import LoadingSpinner from "../../Shared/Components/LoadingSpinner";
import eventApiService from "../Utilities/EventsApiService";
import { useLocalization } from '@progress/kendo-react-intl';
import GpsEventTable from "../Components/GpsEventTable";
import EndpointGpsEventsTableDto from "../../../Models/Endpoint/EndpointGpsEventsTableDto";

interface Props {
  eventFilter: any
}

export default function GpsEventsPage(props: Props): ReactElement {

  const localizationService = useLocalization();
  const eventFilter = props.eventFilter

  const gpsEventsQuery = useQuery<EndpointGpsEventsTableDto[]>([ReactQueryKeys.AllGpsEventsQuery, eventFilter], async () => {
    return eventApiService.getAllGpsEvents(eventFilter);
  });
 
  if (gpsEventsQuery.isLoading || gpsEventsQuery.isFetching) {
    return <LoadingSpinner />;
  } else {
    if (gpsEventsQuery.error ) {
      return <ErrorCard />;
    } else {
      return (
        <div className="">
          <GpsEventTable
            events={gpsEventsQuery.data!}       
            />
         </div>
      );
    }
  }
}
