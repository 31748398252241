import React from "react";
import { ReactElement } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import {  Routes, Route, Navigate, useLocation } from "react-router-dom";
import AuthenticationWrapper from "./Features/Identity/Components/AuthenticationWrapper";
import useIdentityStore from "./Zustand/identityStore";
import { ClientRoutes } from "./Constants/ClientRoutingConstants";
import { IdentityRoutes } from "./Constants/ApiRoutingConstants";
import { LoginActions } from "./Constants/IdentityConstants";
import LoginHandler from "./Features/Identity/Pages/LoginHandler";
import { ChakraProvider } from "@chakra-ui/react";
import ScrollToTop from "./Features/Shared/Components/ScrollToTop";
import { AppContext } from './AppContext';
import { IntlProvider, load, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';

import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';

import usNumbers from 'cldr-numbers-full/main/en/numbers.json';
import usLocalCurrency from 'cldr-numbers-full/main/en/currencies.json';
import usCaGregorian from 'cldr-dates-full/main/en/ca-gregorian.json';
import usDateFields from'cldr-dates-full/main/en/dateFields.json';

import esNumbers from 'cldr-numbers-full/main/es/numbers.json';
import esLocalCurrency from 'cldr-numbers-full/main/es/currencies.json';
import esCaGregorian from 'cldr-dates-full/main/es/ca-gregorian.json';
import esDateFields from'cldr-dates-full/main/es/dateFields.json';

import auNumbers from 'cldr-numbers-full/main/en-AU/numbers.json';
import auLocalCurrency from 'cldr-numbers-full/main/en-AU/currencies.json';
import auCaGregorian from 'cldr-dates-full/main/en-AU/ca-gregorian.json';
import auDateFields from'cldr-dates-full/main/en-AU/dateFields.json';

import ptNumbers from 'cldr-numbers-full/main/pt/numbers.json';
import ptLocalCurrency from 'cldr-numbers-full/main/pt/currencies.json';
import ptCaGregorian from 'cldr-dates-full/main/pt/ca-gregorian.json';
import ptDateFields from'cldr-dates-full/main/pt/dateFields.json';

import deNumbers from 'cldr-numbers-full/main/de/numbers.json';
import deLocalCurrency from 'cldr-numbers-full/main/de/currencies.json';
import deCaGregorian from 'cldr-dates-full/main/de/ca-gregorian.json';
import deDateFields from'cldr-dates-full/main/de/dateFields.json';

import { enMessages } from './messages/en-US';
import { esMessages } from './messages/es';
import { ptMessages } from "./messages/pt-BR";
import { locales } from "./messages/locales";
import { Home } from "./Features/Layout/Pages/Home";
import EventsPage from "./Features/Events/Pages/EventsPage";
import SupportPage from "./Features/Support/Pages/SupportPage";
import ComplianceReportPage from "./Features/Reporting/Pages/ComplianceReportPage";
import PendingActionsPage from "./Features/Reporting/Pages/PendingActionsPage";
import ActionsPage from "./Features/Actions/Pages/ActionsPage";
import SiteAdministrationUsersPage from "./Features/Administration/Pages/SiteAdministrationUsersPage";
import SuperAdministrationUsersPage from "./Features/Administration/Pages/SuperAdministrationUsersPage";
import SuperAdministrationDevicesPage from "./Features/Administration/Pages/SuperAdministrationDevicesPage";
import SiteSetupPage from "./Features/SiteSetup/Pages/SiteSetupPage";
import SuperAdministrationSitesPage from "./Features/Administration/Pages/SuperAdministrationSitesPage";
import authService from "./Features/Identity/Utilities/AuthorizeService";
import SuperAdministrationEndpointsPage from "./Features/Administration/Pages/SuperAdministrationEndpointsPage";
import DetailsPage from "./Features/TreeList/Pages/DetailsPage";
import SessionExpiredPage from "./Features/Identity/Pages/SessionExpiredPage";
import { SciChartSurface } from "scichart";
import { deMessages } from "./messages/de-DE";
import LoadingPage from "./Features/Shared/Pages/LoadingPage";
import AdministrationAuthTokenPage from "./Features/Administration/Pages/AdministrationAuthTokenPage";
import EndpointReportPage from "./Features/Reporting/Pages/EndpointReportPage";
import ReservoirsReportPage from "./Features/Reporting/Pages/ReservoirsReportPage";
import AlertsPage from "./Features/Alerts/Pages/AlertsPage";
import NotificationsPage from "./Features/Notifications/Pages/NotificationsPage";
import AlertJobsPage from "./Features/System/Pages/AlertJobsPage";
import ComplianceReportJobsPage from "./Features/System/Pages/ComplianceReportJobsPage";
import DashboardPage from "./Features/Dashboard/Pages/DashboardPage";
import AccountSettingsPage from "./Features/AccountSettings/AccountSettingsPage";

load(
  likelySubtags,
  currencyData,
  weekData,
  usNumbers,
  usLocalCurrency,
  usCaGregorian,
  usDateFields,
  esNumbers,
  esLocalCurrency,
  esCaGregorian,
  esDateFields,
  ptNumbers,
  ptLocalCurrency,
  ptCaGregorian,
  ptDateFields,
  auNumbers,
  auLocalCurrency,
  auCaGregorian,
  auDateFields,
  deNumbers,
  deLocalCurrency,
  deCaGregorian,
  deDateFields)

loadMessages(esMessages, 'es');
loadMessages(enMessages, 'en-US');
loadMessages(enMessages, 'en-AU');
loadMessages(ptMessages, 'pt-BR');
loadMessages(deMessages, 'de-DE');

function InitialiseScicharts()
{
  const scichartRuntimeLicenseKey = process.env.REACT_APP_SCICHART_RUNTIME_LICENSE_KEY;

  if (scichartRuntimeLicenseKey !== undefined)
  {
    SciChartSurface.setRuntimeLicenseKey(scichartRuntimeLicenseKey);
  }
  
  SciChartSurface.useWasmFromCDN();
}

/**
 * The App component deals with cross cutting concerns in the application including setting up routes,
 * authentication, and other provider style wrapper classes needed to provide functionality to all pages.
 */
export default function App(): ReactElement {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: 1,
        retryDelay: 2000,
      },
    },
  });

  InitialiseScicharts();

  const user = useIdentityStore((store) => store.user);

  /**
   * Generates a redirect Uri back to the specified route to be sent to the identity server
   */
  const generateRedirectUri = (route: any): string => {
    let link = document.createElement("a");
    link.href = route ?? "";
    const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
    const redirectUrl = `${ClientRoutes.LoginPage}?${
      IdentityRoutes.LoginQueryParameters.ReturnUrl
    }=${encodeURIComponent(returnUrl)}`;
    return redirectUrl;
  };

  const LogoutUser = () => {
    authService.signOut(null);
    return null;
  }; 



  const ProtectedRoute = ({ children }:any) => {
    const user = useIdentityStore((store) => store.user);
    const location = useLocation();
    const redirectUrl = generateRedirectUri(location.pathname);
   
    if (user == null) {
      return <Navigate to={redirectUrl} replace />;
    }
    else
    {
      if (user.expired) {
        return  <SessionExpiredPage />
      }
    }
  
    return children;
  };

  
  const NotFound = () => {
    //TODO: Need to make this into a formal "Page not found" component. (e.g. https://blog.fluidui.com/top-404-error-page-examples/)
    return (<div>Not Found</div>);
  }; 

  function getLang() {
    if(navigator.languages !== undefined){
      const localeArray: any = []
      for(let i = 0; i < locales.length; i++) {
        localeArray.push(locales[i].localeId)
      }
      if(localeArray.includes(navigator.languages[0])) {
        return navigator.languages[0]
      } else {
        return 'en-AU';  
      }

    } else {
      return 'en-AU';  
    }
  }
  
  const [contextState, setContextState] = React.useState<any>({
    localeId: getLang()
  });

  const onLanguageChange = React.useCallback(
      (event) =>  setContextState({...contextState, localeId: event.value.localeId}) ,
      [contextState, setContextState]
  );

  return (
    <ChakraProvider>
      <AuthenticationWrapper>
        <QueryClientProvider client={queryClient}>
          <LocalizationProvider language={contextState.localeId}>
            <IntlProvider locale={contextState.localeId}>
              <AppContext.Provider value={{...contextState, onLanguageChange}}> 
                <ScrollToTop/>
                <Routes>
                  <Route path={ClientRoutes.LoginCallbackPage} element={<LoginHandler action={LoginActions.LoginCallback}></LoginHandler>}/>
                  <Route path={ClientRoutes.LoginPage} element={<LoginHandler action={LoginActions.Login}></LoginHandler>}/>
                  <Route path={ClientRoutes.DefaultPage} element={<Navigate to={ClientRoutes.DashboardPage} replace/>}/>
                  <Route path={ClientRoutes.HomePage} element={<ProtectedRoute><AccountSettingsPage /></ProtectedRoute>}/>
                  <Route path={ClientRoutes.Home} element={<Home />}>
                    <Route index element={<ProtectedRoute><EventsPage /></ProtectedRoute>} />
                    <Route path={ClientRoutes.EventsPage} element={<ProtectedRoute><EventsPage /></ProtectedRoute>}/>
                    <Route path={ClientRoutes.SupportPage} element={<ProtectedRoute><SupportPage /></ProtectedRoute>}/>
                    <Route path={ClientRoutes.ComplianceReportPage} element={<ProtectedRoute><ComplianceReportPage /></ProtectedRoute>}/> 
                    <Route path={ClientRoutes.PendingActionsPage} element={<ProtectedRoute><PendingActionsPage /></ProtectedRoute>}/> 
                    <Route path={ClientRoutes.SiteExplorerPage} element={<ProtectedRoute><DetailsPage /></ProtectedRoute>}/> 
                    <Route path={ClientRoutes.SiteExplorerPageWithId} element={<ProtectedRoute><DetailsPage /></ProtectedRoute>}/> 
                    <Route path={ClientRoutes.SiteExplorerPageWithIdAndTabId} element={<ProtectedRoute><DetailsPage /></ProtectedRoute>}/> 
                    <Route path={ClientRoutes.ActionsPage} element={<ProtectedRoute><ActionsPage /></ProtectedRoute>}/> 
                    <Route path={ClientRoutes.SiteSetupPage} element={<ProtectedRoute><SiteSetupPage /></ProtectedRoute>}/> 
                    <Route path={ClientRoutes.SiteAdministrationUsersPage} element={<ProtectedRoute><SiteAdministrationUsersPage /></ProtectedRoute>}/> 
                    <Route path={ClientRoutes.SuperAdministrationUsersPage} element={<ProtectedRoute><SuperAdministrationUsersPage /></ProtectedRoute>}/> 
                    <Route path={ClientRoutes.SuperAdministrationDevicesPage} element={<ProtectedRoute><SuperAdministrationDevicesPage /></ProtectedRoute>}/> 
                    <Route path={ClientRoutes.SiteAdministrationSitesPage} element={<ProtectedRoute><SiteAdministrationUsersPage /></ProtectedRoute>}/> 
                    <Route path={ClientRoutes.SuperAdministrationSitesPage} element={<ProtectedRoute><SuperAdministrationSitesPage /></ProtectedRoute>}/>
                    <Route path={ClientRoutes.SuperAdministrationEndpointsPage} element={<ProtectedRoute><SuperAdministrationEndpointsPage /></ProtectedRoute>}/>
                    <Route path={ClientRoutes.AuthToken} element={<ProtectedRoute><AdministrationAuthTokenPage /></ProtectedRoute>}/>
                    <Route path={ClientRoutes.EndpointReportPage} element={<ProtectedRoute><EndpointReportPage /></ProtectedRoute>}/>
                    <Route path={ClientRoutes.ReservoirsReportPage} element={<ProtectedRoute><ReservoirsReportPage /></ProtectedRoute>}/>
                    <Route path={ClientRoutes.AlertsPage} element={<ProtectedRoute><AlertsPage /></ProtectedRoute>}/>
                    <Route path={ClientRoutes.NotificationsPage} element={<ProtectedRoute><NotificationsPage /></ProtectedRoute>}/>
                    <Route path={ClientRoutes.AlertsJobsPage} element={<ProtectedRoute><AlertJobsPage /></ProtectedRoute>}/>
                    <Route path={ClientRoutes.ComplianceReportJobsPage} element={<ProtectedRoute><ComplianceReportJobsPage /></ProtectedRoute>}/>
                    <Route path={ClientRoutes.DashboardPage} element={<ProtectedRoute><DashboardPage /></ProtectedRoute>}/>
                  </Route>
                  <Route path={ClientRoutes.Logout} element={<LogoutUser/>} />
                  <Route path={ClientRoutes.LoadingPage} element={<LoadingPage/>} />
                  <Route path={ClientRoutes.ProfilePage} element={<Navigate to={ClientRoutes.EventsPage} replace/>}/>
                  <Route path="*" element={<NotFound/>} />
                </Routes> 
              </AppContext.Provider>
            </IntlProvider>
          </LocalizationProvider>
        </QueryClientProvider>
      </AuthenticationWrapper>
    </ChakraProvider>
  );
}
