import { ReactElement, useState, } from "react";
import { useLocalization } from '@progress/kendo-react-intl';
import EndpointEventTable from "../../../Components/Endpoints/EndpointEventsTable";
import { EventFilter } from "../../../../Events/Utilities/EventsApiService";
import { useQuery } from "react-query";
import treeListApiService from "../../../Utilities/TreeListApiService";
import LoadingSpinner from "../../../../Shared/Components/LoadingSpinner";
import ErrorCard from "../../../../Shared/Components/Cards/ErrorCard";
import EndpointPressureEventsTableDto from "../../../../../Models/Endpoint/EndpointPressureEventsTableDto";
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from "@progress/kendo-react-layout";
import EndpointPressureEventsTable from "../../../Components/Endpoints/EndpointPressureEventsTable";
import { enMessages } from "../../../../../messages/en-US";
import EndpointDiagnosticsTable from "../../../Components/Endpoints/EndpointDiagnosticsTable";
import EndpointMetricsTable from "../../../Components/Endpoints/EndpointMetricsTable";


interface Props {
  eventFilter: EventFilter
  selectedItem: string
  aggregator: string;
  endpointFeature: any;
  diagnosticsData: any;
  metricsData: any;
  endpointSerialNo: string;
}

export default function EndpointDiagnoticsTabPageForTables(props: Props): ReactElement {
  const localizationService = useLocalization();

  const [selected, setSelected] = useState<number>(0);

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected);
  };

  const endpointsQueriesTable = useQuery<any>(['endpointTable', props.selectedItem, props.eventFilter, props.aggregator], 
    async () => treeListApiService.getAllEndpointsEventsFlowTable(props.eventFilter, props.selectedItem, props.aggregator), {enabled: selected === 0} );


  const endpointPressureEventsTableQuery = useQuery<EndpointPressureEventsTableDto[]>([`endpointPressureEventsTableQuery${props.selectedItem}`, props.selectedItem, props.eventFilter, props.aggregator], async () => {
    return  treeListApiService.getEndpointPressureEventsTable(props.selectedItem, props.eventFilter, props.aggregator) 
  }, {enabled: selected === 1});

  if (endpointPressureEventsTableQuery.isLoading || endpointsQueriesTable.isLoading) {
    return <LoadingSpinner />;
  } else if (endpointPressureEventsTableQuery.isError || endpointsQueriesTable.isError) {
    return <ErrorCard />;
  } else {
  return (
    <div className="mt-5" >
      <TabStrip selected={selected} onSelect={handleSelect} className="h-full">
        <TabStripTab title={localizationService.toLanguageString('custom.flow', enMessages.custom.flow)} >
          <EndpointEventTable events={endpointsQueriesTable.data!} aggregator={props.aggregator} endpointSerialNo={props.endpointSerialNo}/>
        </TabStripTab>
        {props.endpointFeature.isPressureSupported && 
          <TabStripTab title={localizationService.toLanguageString('custom.pressure', enMessages.custom.pressure)} >
            <EndpointPressureEventsTable events={endpointPressureEventsTableQuery.data!} aggregator={props.aggregator} endpointSerialNo={props.endpointSerialNo}/>   
          </TabStripTab>
        }
         <TabStripTab title={localizationService.toLanguageString('custom.diagnostic', enMessages.custom.diagnostic)} >
          <EndpointDiagnosticsTable events={props.diagnosticsData} aggregator={props.aggregator} endpointSerialNo={props.endpointSerialNo}/>
        </TabStripTab>
        <TabStripTab title={localizationService.toLanguageString('custom.heartbeat', enMessages.custom.heartbeat)} >
          <EndpointMetricsTable events={props.metricsData} aggregator={props.aggregator} endpointSerialNo={props.endpointSerialNo}/>
        </TabStripTab>
      </TabStrip>
    </div>
  )
}}