import { Button, DropDownButtonItemClickEvent, ToolbarSpacer } from "@progress/kendo-react-buttons";
import axios from "axios";
import { ReactElement, useEffect, useState } from "react";
import { ApiRoutes } from "../../../Constants/ApiRoutingConstants";
import { Loader } from "@progress/kendo-react-indicators";
import authorizeService from "../../Identity/Utilities/AuthorizeService";
import { Upload, UploadOnRemoveEvent, UploadOnStatusChangeEvent } from "@progress/kendo-react-upload";
import { useQueryClient } from "react-query";
import { ReactQueryKeys } from "../../../Constants/ClientRoutingConstants";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import useIdentityStore from "../../../Zustand/identityStore";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import React from "react";
import { Grid,  GridColumn as Column, GridToolbar} from "@progress/kendo-react-grid";
import { ImportErrorDto } from "../../../Models/Import/ImportErrorDto";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";
import { DropDownButton } from "@progress/kendo-react-buttons";
import ProcessingSpinner from "../../Reporting/Components/ProcessingSpinner";

interface Props {}

export default function SiteSetupToolbar({}: Props): ReactElement {
  const _export = React.useRef<ExcelExport | null>(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  const localizationService = useLocalization();

  const [token, setToken] = useState("");
  const [download, setDownload] = useState(false);
  const [downloadTemplate, setDownloadTemplate] = useState(false);
  const [downloadTemplateCUID, setDownloadTemplateCUID] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isTemplateDownloading, setIsTemplateDownloading] = useState(false);
  const [isTemplateCUIDDownloading, setIsTemplateCUIDDownloading] = useState(false);
  const [disableCloseButton, setDisableCloseButton] = useState(false);
  const [isImportDialogVisible, setIsImportDialogVisible] = useState(false);
  const userRole = useIdentityStore((store) => store.user!.profile.role);
  const [isImportVisible, setIsImportVisible] = useState(false);
  const [importErrors, setImportErrors] = useState<ImportErrorDto[]>([]);

  const client = useQueryClient();

  const getToken = async () => {
    try {
      const user = await authorizeService.getUser();
      setToken(user.access_token);
    } catch (error) {
      console.log(error);
    }
  }

  const onStatusChange = (event: UploadOnStatusChangeEvent) => {

    // Once the file has been uploaded, we need to refetch the assets which forces an update of the tables. 
    if (event.affectedFiles[0].status === 4)
    {
      client.refetchQueries(ReactQueryKeys.AllAreasQuery);
      client.refetchQueries(ReactQueryKeys.AllMachinesQuery);
      client.refetchQueries(ReactQueryKeys.AllConsumablesQuery);
      client.refetchQueries(ReactQueryKeys.AllPointsQuery);
      client.refetchQueries(ReactQueryKeys.AllTasksQuery);
      client.refetchQueries(ReactQueryKeys.AllSchedulesQuery);
      setDisableCloseButton(false);
    }
    else if (event.affectedFiles[0].status === 0)
    {
      // Enable the dialog close button if the upload failed
      setDisableCloseButton(false);
      setImportErrors(event.response?.response?.data);
    }
    else
    {
      // Disable the dialog close button while the upload is in progress
      setDisableCloseButton(true);
    }
  };

  useEffect(() => {
    getToken();
    if (userRole === "SiteAdmin" || userRole === "SuperAdmin")
    {
      setIsImportVisible(true);
    }

  }, [userRole]);

  useEffect(() => {
    async function downloadTemplateApi() {
      setIsTemplateDownloading(true);
      try {
        // It doesn't matter whether this api responds with the Content-Disposition header or not
        const response = await axios.get(
          `${process.env.REACT_APP_INTEGRATION_SERVICE_URL}/${ApiRoutes.ExportXlsxTemplate}`,
          {
            responseType: "blob",
            headers: { Authorization: `Bearer ${token}` },
          }
        ).finally(() => setIsTemplateDownloading(false));
        const url = window.URL.createObjectURL(new Blob([response.data])); // you can mention a type if you wish
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "account_upload_template.xlsx"); //this is the name with which the file will be downloaded
        link.click();
        // no need to append link as child to body.
        setTimeout(() => window.URL.revokeObjectURL(url), 0); // this is important too, otherwise we will be unnecessarily spiking memory!
        setDownloadTemplate(false);
      } catch (e) {
        console.log(e);
      } //error handling }
    }

    if (downloadTemplate) {
      downloadTemplateApi();
    }
  }, [downloadTemplate, token]);

  useEffect(() => {
    async function downloadTemplateCUIDApi() {
      setIsTemplateCUIDDownloading(true);
      try {
        // It doesn't matter whether this api responds with the Content-Disposition header or not
        const response = await axios.get(
          `${process.env.REACT_APP_INTEGRATION_SERVICE_URL}/${ApiRoutes.ExportXlsxTemplateCUID}`,
          {
            responseType: "blob",
            headers: { Authorization: `Bearer ${token}` },
          }
        ).finally(() => setIsTemplateCUIDDownloading(false));
        const url = window.URL.createObjectURL(new Blob([response.data])); // you can mention a type if you wish
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "account_upload_cuid_template.xlsx"); //this is the name with which the file will be downloaded
        link.click();
        // no need to append link as child to body.
        setTimeout(() => window.URL.revokeObjectURL(url), 0); // this is important too, otherwise we will be unnecessarily spiking memory!
        setDownloadTemplateCUID(false);
      } catch (e) {
        console.log(e);
      } //error handling }
    }

    if (downloadTemplateCUID) {
      downloadTemplateCUIDApi();
    }
  }, [downloadTemplateCUID, token]);


  useEffect(() => {
    async function downloadApi() {
      setIsDownloading(true);
      try {
        // It doesn't matter whether this api responds with the Content-Disposition header or not
        const response = await axios.get(
          `${process.env.REACT_APP_INTEGRATION_SERVICE_URL}/${ApiRoutes.ExportXlsx}`,
          {
            responseType: "blob",
            headers: { Authorization: `Bearer ${token}` },
          }
        ).finally(() => setIsDownloading(false));
        const url = window.URL.createObjectURL(new Blob([response.data])); // you can mention a type if you wish
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "GreaseBoss Site Export.xlsx"); //this is the name with which the file will be downloaded
        link.click();
        // no need to append link as child to body.
        setTimeout(() => window.URL.revokeObjectURL(url), 0); // this is important too, otherwise we will be unnecessarily spiking memory!
        setDownload(false);
      } catch (e) {
        console.log(e);
      } //error handling }
    }

    if (download) {
      downloadApi();
    }
  }, [download, token]);

  const onImportClick = () => {
    setIsImportDialogVisible(true);
    clearImportErrors();
  };

  const onDownloadTemplate = () => {
    setDownloadTemplate(true);
  };

  const onDownloadTemplateCUID = () => {
    setDownloadTemplateCUID(true);
  };

  const onClose = () => {
    setIsImportDialogVisible(false);
  };

  const clearImportErrors = () => {
    setImportErrors([]);
  };

  const onRemove = (event: UploadOnRemoveEvent) => {
    clearImportErrors();
    setDisableCloseButton(false);
  };

  const onboardAppLink = () => {
    window.open("https://onboard.greasecloud.io", "_blank", "noopener,noreferrer");
};

const titleMenuItems = [
  {
    text: localizationService.toLanguageString('custom.goToLink', enMessages.custom.goToLink), 
    onClick: () =>  window.open("https://onboard.greasecloud.io", "_blank", "noopener,noreferrer")
  }, 
  {
    text: localizationService.toLanguageString('custom.copyLink', enMessages.custom.copyLink), 
    onClick: () =>  navigator.clipboard.writeText("https://onboard.greasecloud.io")
  },
  ]

const handleItemClick = (event: DropDownButtonItemClickEvent) => {
  titleMenuItems.map(i => { return(
    i.text == event.item.text ? i.onClick() : null
  )})
}

  return (
    <>
      <div className="k-d-flex-col my-2">
      <div className="header k-d-flex">
        <div className="gb-h1 k-align-items-center" >{localizationService.toLanguageString('custom.accountSetup', enMessages.custom.accountSetup)}</div>
          <span className="k-spacer" />
          <div className="k-d-flex k-align-items-center gap-x-1">
              <DropDownButton
              icon="launch"
              text="Onboard App"
              themeColor={"primary"}
              onItemClick={handleItemClick}
              items={titleMenuItems}
              >
              </DropDownButton>
            { isImportVisible && (
              <Button themeColor={"primary"} onClick={onImportClick} icon="upload">
                {localizationService.toLanguageString('custom.uploadData', enMessages.custom.uploadData)}
              </Button>
            )}
            { isImportDialogVisible && (
              <Dialog title={localizationService.toLanguageString('custom.uploadData', enMessages.custom.uploadData)} width={800} height="80%" onClose={() => setIsImportDialogVisible(false)}>
              
              
                <div className="gb-text-normal"><strong>{localizationService.toLanguageString('custom.step1', enMessages.custom.step1)}</strong></div>
                <div className="flex gap-2">
                {isTemplateDownloading ? 
                  <div>
                    <Button className="my-3" themeColor={"primary"} onClick={onDownloadTemplate} disabled={isTemplateDownloading}>
                      <Loader themeColor={"light"} size="small" type="converging-spinner"/>
                      &nbsp;{localizationService.toLanguageString('custom.downloadTemplate', enMessages.custom.downloadTemplate)}
                    </Button>
                  </div> : 
                  <div>
                    <Button className="my-3" themeColor={"primary"} icon="download" onClick={onDownloadTemplate} disabled={isTemplateDownloading}>{localizationService.toLanguageString('custom.downloadTemplate', enMessages.custom.downloadTemplate)}</Button>
                  </div>}

                  {isTemplateCUIDDownloading ? 
                  <div>
                    <Button className="my-3" themeColor={"primary"} onClick={onDownloadTemplateCUID} disabled={isTemplateDownloading}>
                      <Loader themeColor={"light"} size="small" type="converging-spinner"/>
                      &nbsp;Download CUID Template
                    </Button>
                  </div> : 
                  <div>
                    <Button className="my-3" themeColor={"primary"} icon="download" onClick={onDownloadTemplateCUID} disabled={isTemplateDownloading}>Download CUID Template</Button>
                  </div>}
                </div>
                  

                <div className="gb-text-normal">
                <a href="https://greaseboss.atlassian.net/l/c/8oNCo93M" target={"_blank"} rel={"noopener, noreferrer"}>{localizationService.toLanguageString('custom.seeUploadTemplate', enMessages.custom.seeUploadTemplate)}</a>
                </div>
                
                <div className="gb-text-normal my-3"><strong>{localizationService.toLanguageString('custom.step2', enMessages.custom.step2)}</strong></div>  
                  <Upload 
                  multiple={false}
                  batch={false}
                  autoUpload={false}
                  actionsLayout={"start"}
                  restrictions={{
                    allowedExtensions: [".xlsx"],
                  }}
                  defaultFiles={[]}
                  saveUrl={`${process.env.REACT_APP_INTEGRATION_SERVICE_URL}/${ApiRoutes.ImportXlsx}`}
                  saveHeaders={{['Authorization']:`Bearer ${token}`}}
                  withCredentials={false}
                  onStatusChange={onStatusChange}
                  onRemove={onRemove}
                  onBeforeUpload={clearImportErrors}
                  onAdd={clearImportErrors}
                  />

                {disableCloseButton && (<ProcessingSpinner/>)}

                {importErrors?.length > 0 && (                 
                <div style={{height:"70%"}}>
                  <div className="k-flex k-vbox my-2" style={{height:"100%"}}>
                    <ExcelExport data={importErrors} ref={_export}>
                    <Grid 
                      style={{height: "100%"}}
                      data={importErrors} 
                      rowHeight={46}
                    >
                    <GridToolbar>  
                      <strong>{localizationService.toLanguageString('custom.errors', enMessages.custom.errors)}:</strong>
                      <ToolbarSpacer/>
                      <Button icon="download" onClick={excelExport}/>
                    </GridToolbar>
                    <Column field="row" width="55px" title="Row"/>
                    <Column field="error" title="Reason"/>
                    </Grid>
                    </ExcelExport>
                    </div>
                    </div> 
                )}

                <DialogActionsBar layout="end">
                  <button disabled={disableCloseButton}
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                    onClick={onClose}
                  >
                    {localizationService.toLanguageString('filter.close', enMessages.filter.close)}
                  </button>
                </DialogActionsBar>
              </Dialog>

            )}
            {isDownloading ? <Button themeColor={"primary"} onClick={() => setDownload(true)} disabled={isDownloading}>
            <Loader themeColor={"light"} size="small" type="converging-spinner"/>
              &nbsp;{localizationService.toLanguageString('custom.downloadData', enMessages.custom.downloadData)}
            </Button> : <Button themeColor={"primary"} icon="download" onClick={() => setDownload(true)} disabled={isDownloading}>{localizationService.toLanguageString('custom.downloadData', enMessages.custom.downloadData)}</Button>}
          </div>
        </div>
      </div>
    </>      
  ); 
}
