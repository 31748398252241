import React, { ReactElement } from "react";
import { useLocalization } from '@progress/kendo-react-intl';
import { NumberRange } from "scichart";
import { Grid, GridColumn, GridDataStateChangeEvent } from "@progress/kendo-react-grid";
import { EventFilter } from "../../../Events/Utilities/EventsApiService";
import { enMessages } from "../../../../messages/en-US";
import { DataResult, State,process } from "@progress/kendo-data-query";
import { CustomColumnMenu } from "../../Utilities/customColumnMenu";
import { rruleSummaryToText } from "../../Utilities/ReportUtilities";
import { Button } from "@progress/kendo-react-buttons";
import EndpointPvADialogPage from "../../Pages/EndpointPvADialogPage";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useRefState } from "../../../Shared/Hooks/useRefState";
import { EndpointReportChart } from "./EndpointReportChart";
import { EndpointReportOverviewChart } from "./EndpointReportOverviewChart";
import moment from "moment";
import { ChartSynchroniser } from "../../Utilities/ChartSynchroniser";

interface Props {
 requirements: any;
 dateFilter: EventFilter;
 statusData: any;
}
interface Page {
  skip: number;
  take: number;
}

const page: Page = { skip: 0, take: 30 };

export default function EndpointReportTable(props: Props): ReactElement {

  const localizationService = useLocalization();
  const endpointsData = [...props.requirements]
  const [viewComplianceChart, setComplianceChart] = React.useState<boolean>(false);
  const [endpointId, setEndpointId] = React.useState<string>('');
  const [endpointName, setEndpointName] = React.useState<string>('');
  const [endpointSerialNumber, setEndpointSerialNumber] = React.useState<string>('');

  const chartSynchroniserRef = React.useRef<ChartSynchroniser>(new ChartSynchroniser(
    new NumberRange(moment(props.dateFilter.startDate).unix(), moment(props.dateFilter.endDate).unix())));

  const eventFilter = props.dateFilter;
  const statusData = props.statusData;

  const closeHandler = () => {
    setComplianceChart(false);
    setEndpointId('')
    setEndpointName('')
    setEndpointSerialNumber('')
  };

  const grid = React.useRef<any>(null);

  React.useEffect(() => {
    const handleResize = () => {

      let columns = [...stateColumnsRef.current];
      for(let i = 0; i < stateColumns.length; i++) {
        if (columns[i].show) 
        {
          if (columns[i].minWidth > grid.current.offsetWidth) {
            columns[i].isHidden = true;
          }
          else {
            columns[i].isHidden = false;
          }
        }
      }
  
     setStateColumns(columns); 
    };

    grid.current = document.querySelector(".k-grid");
    window.addEventListener("resize", handleResize);

    handleResize()

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const createDataState = (dataState: State) => {
    return {
      result: process(endpointsData.slice(0), dataState),
      dataState: dataState,
    };
  };

  let initialState = createDataState({
    take: page.take,
    skip: page.skip,
  });

  const [result, setResult] = React.useState<DataResult>(initialState.result);
  const [dataState, setDataState] = React.useState<any>(initialState.dataState );

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    let updatedState = createDataState(event.dataState);
    setResult(updatedState.result);
    setDataState(updatedState.dataState);
  };
  

  const PlanVsActualCell = (props: any) => {
    let styles = props.style;
    let classNames = props.className;
    let endpointStatusData = statusData.filter((item: any) => item.data.id === props.dataItem.endpointId)[0].data;

      if (endpointStatusData === undefined) {
        console.error("Endpoint status data not found for endpointId: ", props.dataItem.endpointId);
      }
    if(props.dataItem.timestamps?.length === 0) {
      return (
        <td  style={{ textAlign: "center", alignItems: "center", ...styles }}
        className={classNames}><div>{localizationService.toLanguageString('custom.noRequirementWithinTime', enMessages.custom.noRequirementWithinTime)}</div></td>
      );
    } else {
      return (
        <td  style={{ textAlign: "center", ...styles }} className={classNames}>
          <EndpointReportChart key={props.dataItem.endpointId} requirementsData={props.dataItem} chartSynchroniser={chartSynchroniserRef.current} dateFilter={eventFilter} statusData={endpointStatusData} />
        </td>
      );
    }
  };

  const RequirementSummary = (props: any) => {
    let styles = props.style;
    let classNames = props.className;
    if(props.dataItem.timestamps?.length === 0) {
      return (
        <td  style={{ textAlign: "left", alignItems: "center", ...styles }}
        className={classNames}><div style={{ height: '40px', display:"grid", alignItems:"center"}}>{localizationService.toLanguageString('custom.noRequirement', enMessages.custom.noRequirement)}</div></td>
      );
    } else {
      return (
        <td>
          <div style={{textAlign: "left"}} >{props.dataItem.requirementSummary?.requirementName} - {props.dataItem.requirementSummary?.consumableName}, {props.dataItem.requirementSummary?.volumeRequirement} cc, {rruleSummaryToText(props.dataItem.requirementSummary?.rRule)}</div>
        </td>
      );
    }
  };

  const NameCell = (props: any) => {
    let styles = props.style;
    let classNames = props.className;
  
    return (
      <td style={{...styles,  }} className={classNames}>
       <Button fillMode={"link"} style={{color:"#388DF9", textAlign:'left'}} onClick={() => {
              setComplianceChart(true)
              setEndpointId(props.dataItem.endpointId)
              setEndpointName(props.dataItem.endpointName)
              setEndpointSerialNumber(props.dataItem.endpointSerialNumber)
              }} ><div>{props.dataItem.endpointName}</div><div style={{fontSize:'12px'}}>({props.dataItem.endpointSerialNumber})</div></Button>
      </td>
    );
  };
 
  const ComplianceHeaderCustomCell = (props: { title: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; }) => (
    <div className="grid grid-cols-1 gap-3">
      <div>{props.title}</div>
      <div>
        <EndpointReportOverviewChart dateFilter={eventFilter} chartSynchroniser={chartSynchroniserRef.current} />
      </div>
    </div>
  );

  // Columns for the table
  const columns: Array<any> = [
    {
      title: `${localizationService.toLanguageString('custom.name', enMessages.custom.name)}`,
      field: "endpointNameSerialNumber",
      show: true,
      filter: "text",
      cell: NameCell,
      filterable: true,
      isHidden: false,
      canHide: true,
      width: 150
    },
    {
      title: `${localizationService.toLanguageString('custom.location', enMessages.custom.location)}`,
      field: "locationName",
      show: true,
      filter: "text",
      filterable: true,
      width:150,
      isHidden: false,
      canHide: true,
      minWidth:900,
    },
    {
      title: `${localizationService.toLanguageString('custom.asset', enMessages.custom.asset)}`,
      field: "assetName",
      show: true,
      filter: "text",
      width:150,
      isHidden: false,
      filterable: true,
      canHide: true,
      minWidth:1050,
    },
    {
      title: `${localizationService.toLanguageString('custom.requirement', enMessages.custom.requirement)}`,
      field: "requirementSummary.requirementName",
      show: true,
      filter: "text",
      width:150,
      isHidden: false,
      cell: RequirementSummary,
      canHide: true,
      filterable: true
    },
    {
      title: `${localizationService.toLanguageString('custom.compliance', enMessages.custom.compliance)}`,
      field: "",
      show: true,
      filter: "text",
      isHidden: false,
      cell: PlanVsActualCell,
      filterable: false,
      canHide: false,
      headercell: ComplianceHeaderCustomCell
    },
  ];

  const [stateColumns, stateColumnsRef, setStateColumns] = useRefState<any>(columns);
  const onColumnsSubmit = (columnsState: Array<any>) => {
    setStateColumns(columnsState);
  };

 
return (
  <div className=''>
    <Grid
      key={'EndpointsReportTable'}
      data={result.data}
      total={result.total}
      scrollable={"virtual"} 
      take={page.take}
      skip={page.skip}
      style={{height:"700px"}} 
      rowHeight={50}
      {...dataState}
        onDataStateChange={dataStateChange}
      pageSize={15}
      sortable={true}
      >
      {stateColumns.map(
        (column, idx) => 
          (column.show && !column.isHidden) && (
            <GridColumn
              key={idx}
              field={column.field}
              title={column.title}
              filter={column.filter}
              cell={column.cell}
              width={column.width}
              headerCell={column.headercell}
              columnMenu={(props) => (
                <CustomColumnMenu
                  {...props}
                  columns={stateColumns}
                  onColumnsSubmit={onColumnsSubmit}
                  data={endpointsData}
                  filterable={column.filterable}
                />
              )}
            />
          )
        )}
    </Grid> 

    {viewComplianceChart && (
       <Dialog title={`${localizationService.toLanguageString('custom.compliance', enMessages.custom.compliance)} - ${endpointName} (${endpointSerialNumber})`} onClose={closeHandler} height="85%"  width="900px" className="reservoir-view">
        <EndpointPvADialogPage
          endpointId={endpointId.substring(3)} 
          eventFilter={props.dateFilter}
          />
        <DialogActionsBar>
        <Button onClick={closeHandler}>{localizationService.toLanguageString('custom.close', enMessages.custom.close)}</Button>
      </DialogActionsBar>
    </Dialog>
      )}
   
  </div>
  )
}