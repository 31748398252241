import { ConsumableCreateDto } from "./../../../Models/Consumable/ConsumableCreateDto";
import { ConsumableReadDto } from "./../../../Models/Consumable/ConsumableReadDto";
import { ApiRoutes } from "../../../Constants/ApiRoutingConstants";
import axios, { AxiosInstance } from "axios";
import authorizeService from "../../Identity/Utilities/AuthorizeService";
import { ConsumableUpdateDto } from "../../../Models/Consumable/ConsumableUpdateDto";

/**
 * This service provides implementations for http calls to the api pertaining to consumable crud functionality
 */

class ConsumablesApiService {
  axiosInstance: AxiosInstance;
  constructor() {
    //Subscribe to identity store the get token
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_ASSET_SERVICE_URL,
    });
  }

  async getAllConsumables(): Promise<ConsumableReadDto[]> {
    let response = await this.axiosInstance({
      method: "GET",
      url: `${ApiRoutes.Consumables}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data;
  }

  async createConsumable(consumable: ConsumableCreateDto) {
    let response = await this.axiosInstance({
      method: "POST",
      url: `${ApiRoutes.Consumables}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: consumable,
    });
    return response.data as ConsumableReadDto;
  }

  async updateConsumable(consumable: ConsumableCreateDto, consumableId: string) {
    let response = await this.axiosInstance({
      method: "PUT",
      url: `${ApiRoutes.Consumables}/${consumableId}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: consumable,
    });
    return response.data as ConsumableReadDto;
  }

  async updateConsumables(consumables: Array<ConsumableUpdateDto>) {
    let response = await this.axiosInstance({
      method: "PUT",
      url: `${ApiRoutes.Consumables}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: { consumables },
    });
    return response.data;
  }

  async deleteConsumables(consumablesDeleteIds: Array<string>) {
    let response = await this.axiosInstance({
      method: "DELETE",
      url: `${ApiRoutes.Consumables}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: {
        ids: consumablesDeleteIds,
      },
    });
    return response.data;
  }

  static get instance() {
    return consumablesApiService;
  }
}

const consumablesApiService = new ConsumablesApiService();
export default consumablesApiService;
