/**
 * This dialog modal provides a list of the existing sites and roles within each site
 * of the selected user. It also provides functionality for removing user roles and
 * adding new ones.
 *
 * Date: 01/04/2022
 * Author: Max Wroe
 */

import { ReactElement } from "react";
import { SiteRoleMapping, UserReadDto } from "../../../../Models/Admin/UserReadDto";
import { OrganisationRole } from "../../../../Models/Admin/Role";
import { Field, FieldProps, Formik, FormikHelpers } from "formik";
import TableErrorMessage from "../../../Shared/Components/Forms/TableErrorMessage";
import { sitesToComboboxItems } from "../../Utilities/AdministrationUtilities";
import { useToast } from "@chakra-ui/toast";
import { useQueryClient } from "react-query";
import { ReactQueryKeys } from "../../../../Constants/ClientRoutingConstants";
import { SiteReadDto } from "../../../../Models/Site/SiteReadDto";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { ComboBox, ComboBoxFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../../messages/en-US";
import React from "react";
import { filterBy, FilterDescriptor } from "@progress/kendo-data-query";
import { ComboBoxItem } from "../../../Shared/Components/Forms/ComboBox";

interface Props {
  user: UserReadDto | null;
  roles: Array<OrganisationRole>;
  sites?: Array<SiteReadDto>;
  updateUserRole: (id: string, siteId: string, roleId: string) => Promise<any>;
  removeUserRole: (id: string, siteId: string, roleId: string) => Promise<any>;
  onClose: () => void;
}

export default function UpdateDialog({
  user,
  sites,
  roles,
  onClose,
  updateUserRole: updateUserRoles,
  removeUserRole,
}: Props): ReactElement {
  const toast = useToast({
    position: "bottom",
    duration: 5000,
    isClosable: true,
  });
  const localizationService = useLocalization();

  const listRoles = roles.filter((role) => role.id !== "a4f91bf1-0fc8-41ca-8770-9583385dbf65");

  const client = useQueryClient();
  const handleRemoveClick = async (user: UserReadDto, mapping: SiteRoleMapping) => {
    try {
      await removeUserRole!(user.id, mapping.siteId, mapping.roleId);
      toast({
        status: "success",
        description: `${localizationService.toLanguageString('custom.roleRemoved', enMessages.custom.roleRemoved)}`,
      });
      client.refetchQueries(ReactQueryKeys.AllUsersQuery);
    } catch (e: any) {
      console.log(e);
      toast({
        title: `${localizationService.toLanguageString('custom.error', enMessages.custom.error)}`,
        status: "error",
        description: `${localizationService.toLanguageString('custom.roleRemovedError', enMessages.custom.roleRemovedError)} \n
  ${Object.values(e?.response?.data?.errors)[0]}`,
      });
    }
  };

  const handleAddRoleSubmit = async (values: any, { setStatus }: FormikHelpers<any>) => {
    //Attempt to create the area
    try {
      //Submit values to api
      await updateUserRoles!(user!.id, values.siteId.value, values.roleId.value);
      //On success, show success toast, then refresh data
      toast({
        status: "success",
        description: `${localizationService.toLanguageString('custom.roleAdded', enMessages.custom.roleAdded)}`,
      });

      client.refetchQueries(ReactQueryKeys.AllUsersQuery);
    } catch (e: any) {
      //On failure show error toast, set status messages
      console.log(e);
      setStatus(e?.response?.data?.errors);
      toast({
        title: `${localizationService.toLanguageString('custom.error', enMessages.custom.error)}`,
        status: "error",
        description: `${localizationService.toLanguageString('custom.roleAddedError', enMessages.custom.roleAddedError)}`,
      });
    }
  };

  const RolesToComboboxItems = (existingRoles: Array<OrganisationRole>) => {   
    return existingRoles!
      .filter((role) => {
        return role.id!;
      })
      .map((role) => {
        if(role.name === "SuperAdmin") {
          return {
            displayName: `${localizationService.toLanguageString('custom.superAdmin', enMessages.custom.superAdmin)}`,
            value: role.id!,
          }
        } else if(role.name === "SiteAdmin") {
          return {
            displayName: `${localizationService.toLanguageString('custom.accountAdmin', enMessages.custom.accountAdmin)}`,
            value: role.id!,
          }
        } else if(role.name === "Manager") {
          return {
            displayName: `${localizationService.toLanguageString('custom.manager', enMessages.custom.manager)}`,
            value: role.id!,
          }
        } else if(role.name === "GeneralUser") {
          return {
            displayName: `${localizationService.toLanguageString('custom.generalUser', enMessages.custom.generalUser)}`,
            value: role.id!,
          }
        } else {
          return {
            displayName: `${localizationService.toLanguageString('custom.device', enMessages.custom.device)}`,
            value: role.id!,
          }
        }
       
      }).filter(role => role.displayName !== "Device") as Array<ComboBoxItem>;
  };

  const [filterAccount, setFilterAccount] = React.useState(sitesToComboboxItems(sites!)?.slice());

  const filterDataAccount = (filter: FilterDescriptor) => {
    const dataAccount = sitesToComboboxItems(sites!).slice()
    return filterBy(dataAccount, filter);
  };

  const filterChangeAccount = (event: ComboBoxFilterChangeEvent) => {
    setFilterAccount(filterDataAccount(event.filter));
  };

  const [filterRole, setFilterRole] = React.useState(RolesToComboboxItems(listRoles).slice());

  const filterDataRole = (filter: FilterDescriptor) => {
    const dataRole = RolesToComboboxItems(listRoles).slice()
    return filterBy(dataRole, filter);
  };

  const filterChangeRole = (event: ComboBoxFilterChangeEvent) => {
    setFilterRole(filterDataRole(event.filter));
  };

  return (
    <>
      {user != null && (
        <Dialog onClose={onClose} title={localizationService.toLanguageString('custom.updateUserRoles', enMessages.custom.updateUserRoles)} className={'manageDialog'}>
          {/* Show all current site role mappings */}
          <table className="k-widget k-grid k-grid-virtual w-full z-0">
  

            <tbody>
              {/* Last Row is Form */}
              <Formik
                initialValues={{
                  roleId: "",
                  siteId: "",
                }}
                onSubmit={handleAddRoleSubmit}
              >
                {({ submitForm }) => (
                  <>
                    {user?.siteRoleMappings.map((mapping) => {
                      return (
                        <tr className="k-master-row">
                          {sites && <td>{sites?.find((x) => x.id === mapping.siteId)?.name}</td>}
                          <td>{listRoles?.find((x) => x.id === mapping.roleId)?.name ==='SiteAdmin' ? 'AccountAdmin' : listRoles?.find((x) => x.id === mapping.roleId)?.name}</td>
                          <td>
                            {listRoles?.find((x) => x.id === mapping.roleId)?.name && (
                              <Button themeColor={"primary"} onClick={() => handleRemoveClick(user, mapping)}>
                                {localizationService.toLanguageString('custom.remove', enMessages.custom.remove)}
                              </Button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                    <tr className="k-master-row">
                      {sites && (
                        <td>
                          <Field
                            name="siteId"
                            render={({ form }: FieldProps) => (
                              <ComboBox
                                className="form-field"
                                name="siteId"
                                placeholder={localizationService.toLanguageString('custom.account', enMessages.custom.account)}
                                data={filterAccount}
                                textField="displayName"
                                onChange={(event) => {
                                  form.setFieldValue("siteId", event.value);
                                }}
                                filterable={true}
                                onFilterChange={filterChangeAccount}
                              />
                            )}
                          />
                          <TableErrorMessage name="siteId" />
                        </td>
                      )}
                      <td>
                        <Field
                          name="roleId"
                          render={({ form }: FieldProps) => (
                            <ComboBox
                              className="form-field z-50"
                              name="roleId"
                              placeholder={localizationService.toLanguageString('custom.role', enMessages.custom.role)}
                              data={filterRole}
                              textField="displayName"
                              onChange={(event) => {
                                form.setFieldValue("roleId", event.value);
                              }}
                              filterable={true}
                              onFilterChange={filterChangeRole}
                            />
                          )}
                        />
                        <TableErrorMessage name="roleId" />
                      </td>
                      <td>
                        <Button className="w-full" onClick={submitForm}>
                        {localizationService.toLanguageString('custom.add', enMessages.custom.add)}
                        </Button>
                      </td>
                    </tr>
                  </>
                )}
              </Formik>
            </tbody>
          </table>
          <DialogActionsBar>
            <Button themeColor="base" onClick={onClose}>
            {localizationService.toLanguageString('custom.close', enMessages.custom.close)}
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
    </>
  );
}
