

import { ApiRoutes } from "../../../Constants/ApiRoutingConstants";
import axios, { AxiosInstance } from "axios";
import authorizeService from "../../Identity/Utilities/AuthorizeService";
import { RequirementReadDto } from "../../../Models/Requirement/RequirementReadDto";
import { RequirementCreateDto } from "../../../Models/Requirement/RequirementCreateDto";
import { RequirementUpdateDto } from "../../../Models/Requirement/RequirementUpdateDto";

/**
 * This service provides implementations for http calls to the api pertaining to schedule crud functionality
 */

class RequirementApiService {
  axiosInstance: AxiosInstance;
  constructor() {
    //Subscribe to identity store the get token
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_REQUIREMENT_SERVICE_URL,
    });
  }
  

  async getAllRequirements(): Promise<RequirementReadDto[]> {
    let response = await this.axiosInstance({
      method: "GET",
      url: `${ApiRoutes.Requirement}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data;
  }

  async getRequirement(requirementId: string): Promise<RequirementReadDto[]> {
    let response = await this.axiosInstance({
      method: "GET",
      url: `${ApiRoutes.Requirement}/${requirementId}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data;
  }

  async getAnalysisRequirement(endpointId: string, eventFilters){
     //Covnert EventFilters to use Iso date strings
     const isoEventFilters = {...eventFilters, 
      startDate : eventFilters.startDate?.toISOString(),
      endDate : eventFilters.endDate?.toISOString()}

    let response = await this.axiosInstance({
      method: "GET",
      url: `api/analysis/endpoint/requirement/${endpointId}?${new URLSearchParams(isoEventFilters).toString()}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data;
  }

  async createRequirement(requirement: RequirementCreateDto) {
    let response = await this.axiosInstance({
      method: "POST",
      url: `${ApiRoutes.Requirement}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: requirement,
    });
    return response.data as RequirementReadDto;
  }

  async updateRequirement(requirement: RequirementCreateDto, requirementId: string) {
    let response = await this.axiosInstance({
      method: "PUT",
      url: `${ApiRoutes.Requirement}/${requirementId}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: requirement,
    });
    return response.data as RequirementReadDto;
  }

  async updateRequirements(requirement: Array<RequirementUpdateDto>) {
    let response = await this.axiosInstance({
      method: "PUT",
      url: `${ApiRoutes.Requirement}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: { requirement: requirement },
    });
    return response.data;
  }

  static get instance() {
    return requirementApiService;
  }
}

const requirementApiService = new RequirementApiService();
export default requirementApiService;
