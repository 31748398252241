import React, { useRef, useEffect, useMemo, ReactElement } from "react";
import ScheduleAddModal from "./ScheduleAddModal";
import { ScheduleReadDto } from "../../../Models/Schedule/ScheduleReadDto";
import { ScheduleCreateDto } from "../../../Models/Schedule/ScheduleCreateDto";
import useIdentityStore from "../../../Zustand/identityStore";
import { ScheduleReadTableDto } from "../../../Models/Schedule/ScheduleReadTableDto";
import { ColumnMetaData } from "../../Shared/Interfaces/ColumnMetaData";
import useKendoTableState from "../../Shared/Hooks/useKendoTableState";
import GBKendoDataCell from "../../Shared/Components/Table/GBKendoDataCell";
import { GBGridCellProps } from "../../Shared/Interfaces/GBGridCellProps";
import { Grid, GridFilterChangeEvent, GridPageChangeEvent, GridSortChangeEvent, GridToolbar, GridColumn as Column, GridRowProps } from "@progress/kendo-react-grid";
import { CompositeFilterDescriptor, filterBy, orderBy } from "@progress/kendo-data-query";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { isRequiredBySchema } from "../../Shared/Utilities/YupValidationUtilities";
import SchedulesValidationSchema from "../Utilities/SchedulesValidationSchema";
import { Formik } from "formik";
import GBKendoToolbar from "../../Shared/Components/Table/GBKendoToolbar";
import { CustomDateFilterCell } from "../../Shared/Components/Table/CustomDateFilterCell";
import { CustomTextFilterCell } from "../../Shared/Components/Table/CustomTextFilterCell";
import { Button } from "@progress/kendo-react-buttons";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";
import { useInternationalization } from "@progress/kendo-react-intl";

interface Props {
  extraInlineActions: null;
  handleSubmit: any;
  initialSort: any;
  initialSortDescriptor: any;
  schedules: Array<ScheduleReadTableDto>;
  createSchedule: (scheduleCreateDto: ScheduleCreateDto) => void;
  updateSchedule: (scheduleId: string, updateSchedule: ScheduleCreateDto) => void;
  deleteSchedule: (deleteIds: Array<string>) => void;
  height: number;
}

const initialFilter: CompositeFilterDescriptor = {
  logic: "and",
  filters: [],
};

function SchedulesTable(props: any) {
  const [addSchedule, setAddSchedule] = React.useState<boolean>(false);
  const [scheduleToUpdate, setScheduleToUpdate] = React.useState<ScheduleReadDto | null>(null);
  const currentUserRole = useIdentityStore((x) => x.user?.profile.role);
  const tableState = useKendoTableState<ScheduleReadTableDto>({
    key: "Schedules",
    initialAssets: props.schedules,
  });
  const localizationService = useLocalization();
  const intl = useInternationalization();

  const closeHandler = () => {
    setScheduleToUpdate(null);
    setAddSchedule(false);
  };

  const stateRef = useRef(tableState);

  useEffect(() => {
    stateRef.current = tableState
   }, [tableState]);

  const [filter, setFilter] = React.useState(initialFilter);
  const [sort, setSort] = React.useState(props.initialSortDescriptor ?? props.initialSort);

  const columns = React.useMemo<Array<ColumnMetaData<ScheduleReadTableDto>>>(
    () => [
      {
        header: `${localizationService.toLanguageString('custom.name', enMessages.custom.name)}`,
        displayField: "name",
        filterType: "text",
        Cell: (cellProps: GBGridCellProps<ScheduleReadTableDto>) => (
          <GBKendoDataCell<ScheduleReadTableDto>
            dataFieldName="name"
            cellProps={cellProps}
            fieldAccessor={(x) => x.name}
            isAddMode={cellProps.dataIndex < tableState.tempRows.length}
            isEditMode={tableState.canModifyRow}
            onCellChanged={tableState.onCellChanged}
          />
        ),
      },
      {
        header: `${localizationService.toLanguageString('custom.frequency', enMessages.custom.frequency)}`,
        displayField: "frequency",
        filterType: "text",
        Cell: (cellProps: GBGridCellProps<ScheduleReadTableDto>) => (
          <GBKendoDataCell<ScheduleReadTableDto>
            dataFieldName="frequency"
            cellProps={cellProps}
            fieldAccessor={(x) => x.frequency}
            isAddMode={cellProps.dataIndex < tableState.tempRows.length}
            isEditMode={tableState.canModifyRow}
            onCellChanged={tableState.onCellChanged}
          />
        ),
      },
      {
        header: `${localizationService.toLanguageString('custom.recurrence', enMessages.custom.recurrence)}`,
        displayField: "recurrence",
        filterType: "text",
        Cell: (cellProps: GBGridCellProps<ScheduleReadTableDto>) => (
          <GBKendoDataCell<ScheduleReadTableDto>
            dataFieldName="recurrence"
            cellProps={cellProps}
            fieldAccessor={(x) => x.recurrence}
            isAddMode={cellProps.dataIndex < tableState.tempRows.length}
            isEditMode={tableState.canModifyRow}
            onCellChanged={tableState.onCellChanged}
          />
        ),
      },
      {
        header: `${localizationService.toLanguageString('custom.timezone', enMessages.custom.timezone)}`,
        displayField: "timezone",
        filterType: "text",
        Cell: (cellProps: GBGridCellProps<ScheduleReadTableDto>) => (
          <GBKendoDataCell<ScheduleReadTableDto>
            dataFieldName="timezone"
            cellProps={cellProps}
            fieldAccessor={(x) => x.timezone}
            isAddMode={cellProps.dataIndex < tableState.tempRows.length}
            isEditMode={tableState.canModifyRow}
            onCellChanged={tableState.onCellChanged}
          />
        ),
      },
      {
        header: `${localizationService.toLanguageString('custom.startDate', enMessages.custom.startDate)}`,
        displayField: "startDateFormatted",
        filterType: "text",
        Cell: (cellProps: GBGridCellProps<ScheduleReadTableDto>) => (
          <GBKendoDataCell<ScheduleReadTableDto>
            dataFieldName="startDateFormatted"
            cellProps={cellProps}
            fieldAccessor={(x) => x.startDateFormatted}
            // fieldAccessor={(x) => new Date(x.startDate).toLocaleDateString()}
            isAddMode={cellProps.dataIndex < tableState.tempRows.length}
            isEditMode={tableState.canModifyRow}
            onCellChanged={tableState.onCellChanged}
          />
        ),
      },
      {
        header: `${localizationService.toLanguageString('custom.summary', enMessages.custom.summary)}`,
        displayField: "summary",
        filterType: "text",
        Cell: (cellProps: GBGridCellProps<ScheduleReadTableDto>) => (
          <GBKendoDataCell<ScheduleReadTableDto>
            dataFieldName="summary"
            cellProps={cellProps}
            fieldAccessor={(x) => x.summary}
            isAddMode={cellProps.dataIndex < tableState.tempRows.length}
            isEditMode={tableState.canModifyRow}
            onCellChanged={tableState.onCellChanged}
          />
        ),
      },
    ],

    [currentUserRole, filter, sort]
  );

  const _export = React.useRef<ExcelExport | null>(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };
  const ref = useRef<any>();

  /**The following use effects are required to fix a race condition created by updating formik fields
   * Programatically. They ensure that validation is performed after the values update instead of before.
   **/
  useEffect(() => {
    ref.current.validateForm();
  }, [ref.current?.values]);
  useEffect(() => {
    ref.current.validateForm();
  }, [ref.current]);

  /**
   * Set the skip amount to zero if the number of temp rows (indicating an add row has been added).
   * This effectively ensures the add row is always scrolled into view
   */
  useEffect(() => {
    if (tableState.tempRows.length === 1) {
      setSkip(0);
    }
  }, [tableState.tempRows.length]);

  //The following state is required for dynamic scrolling
  const [skip, setSkip] = React.useState<number>(0);
  const pageChange = (event: GridPageChangeEvent) => {
    setSkip(event.page.skip);
  };

  const isColumnForRequiredField = (displayField: string | undefined): boolean => {
    return (
      isRequiredBySchema(displayField?.replace("Name", "Id"), SchedulesValidationSchema) &&
      (tableState.tempRows.length > 0 || tableState.canModifyRow)
    );
  };

  useEffect(() => {
    // Reset skip when filter or sort changes
    setSkip(0);
  }, [filter, sort]);

  //Data is API data + current temporary rows that have not yet been persisted using the API
  const processedData = useMemo(() => {

    let initialRows = tableState.assets;

    if (filter) {
      initialRows = filterBy(initialRows, filter);
    }

    if (sort) {
      initialRows = orderBy(initialRows, sort);
    }
    //Add any temp rows to beggining
    return tableState.tempRows.concat(initialRows);
  }, [tableState.assets, tableState.tempRows, filter, sort]);

  //Initial row added
  const initialisedRowData = {
    frequency: "",
    recurrence: "",
    summary: "",
    id : "",
    name : "",
    description : "",
    rRule : "",
    timezone : "",
    expanded: true
  };

  return (
    <div>
      <Formik
        innerRef={ref}
        key={props.schedules.length}
        onSubmit={props.handleSubmit}
        validationSchema={SchedulesValidationSchema}
        initialValues={initialisedRowData}
      >
        {({ submitForm, values, resetForm, isSubmitting }) => (
          <ExcelExport data={processedData} ref={_export} collapsible={true} fileName={localizationService.toLanguageString('custom.schedules', enMessages.custom.schedules)}>
            <Grid
              key={tableState.tempRows.length.toString()}
              rowHeight={46}
              pageSize={13}
              total={processedData.length}
              data={processedData.slice(skip, skip + 13)}
              scrollable="virtual"
              skip={skip}
              onPageChange={pageChange}
              expandField="expanded"
              filterable={true}
              filter={filter}
              onFilterChange={(e: GridFilterChangeEvent) => setFilter(e.filter)}
              sortable={true}
              sort={sort}
              onSortChange={(e: GridSortChangeEvent) => {
                setSort(e.sort);
              }}
              style={{
                height: props.height,
              }}
              className="w-full  z-0"
              //Custom render row,
              rowRender={(trElement: ReactElement, props: GridRowProps) => {
                let baseStyle = { ...trElement.props.style };
                if (tableState.modifiedRowIds.includes(props.dataItem.id)) {
                  baseStyle.backgroundColor = "rgba(255, 252, 88, 0.25)";
                }
                if (tableState.selectedRowsIds.includes(props.dataItem.id)) {
                  baseStyle.backgroundColor = "rgba(255, 99, 88, 0.25)";
                }

                return React.cloneElement(
                  trElement,
                  {
                    ...trElement,
                    style: { ...baseStyle },
                  },
                  trElement.props.children
                );
              }}
            >
              <GridToolbar>  
                <GBKendoToolbar
                  name={localizationService.toLanguageString('custom.schedules', enMessages.custom.schedules)}
                  tableState={tableState}
                  exportAction={excelExport}
                  importAction={null}
                  addAction={null}
                  updateAction={null}
                  deleteAction={
                    props.deleteSchedule ? async () => await props.deleteSchedule(tableState.selectedRowsIds) : null
                  }
                  discardChanges={null}
                  extraToolbarActions={[
                    {
                      icon: "add",
                      text: `${localizationService.toLanguageString('custom.addSchedule', enMessages.custom.addSchedule)}`,
                      onClick: () => setAddSchedule(true),
                      themeColor: "primary",
                    },
                  ]}
                />
              </GridToolbar>

               {/* From Column Array */}
               {columns.map((column, index) => {
                return (
                  //Create wrapper/HOC with editable cell and add cell definitions,
                  <Column
                    width="auto"
                    key={index}
                    cell={column.Cell}
                    // Show asterix in header if required TODO: pretty hacky - assumes that the name of the data field is the same as the display field with Name replaced.
                    title={`${column.header}${isColumnForRequiredField(column.displayField) ? "*" : ""}`}
                    field={column.displayField}
                    filter={column.filterType}
                    filterCell={column.filterType === "date" ? CustomDateFilterCell : CustomTextFilterCell}
                  />
                );
              })}

              {currentUserRole !== "GeneralUser" && (
              <Column
                title={localizationService.toLanguageString('custom.actions', enMessages.custom.actions)}
                width="110px"
                sortable={false}
                filterable={false}
                cell={(cellProps) => {
                  return (
                    <td className="flex flex-wrap justify-around gap-1">
                    <Button
                      className=""
                      themeColor={"primary"}
                      onClick={() => {setScheduleToUpdate(cellProps.dataItem)} }
                    >
                      {localizationService.toLanguageString('custom.edit', enMessages.custom.edit)}
                    </Button>
                    </td>
                  );
                }}
              />)}

            </Grid>
          </ExcelExport>
          )}
      </Formik>
      <ScheduleAddModal
        key={(scheduleToUpdate || addSchedule).toString()}
        schedules={props.schedules}
        createSchedule={props.createSchedule}
        schedule={scheduleToUpdate}
        updateSchedule={props.updateSchedule}
        addSchedule={addSchedule}
        onClose={() => closeHandler()}
      />
    </div>
  );
}

export default SchedulesTable;