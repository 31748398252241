import {
  SciChartPieSurface,
  EPieType,
  SciChartJSLightTheme,
  PieSegment,
  } from "scichart";
import { SciChartReact } from "scichart-react";
import { enMessages } from "../../../messages/en-US";

interface Props {
  chartData: any
}

export const AutoComplianceChart = (props: Props) => (
  <div className="flex">
  <SciChartReact
    initChart={async rootElement => {
      const theme = {...new SciChartJSLightTheme()}
      theme.sciChartBackground = 'transparent';

      const sciChartPieSurface = await SciChartPieSurface.create(rootElement, {
          theme: theme,
          pieType: EPieType.Donut,
          holeRadius: 0.75,
      });

      const compliantSegment = new PieSegment({
          color: "#1ab394",
          value: props.chartData.autoComplianceData.compliantCount === 0 ? 0.0001 : props.chartData.autoComplianceData.compliantCount,
          text: props.chartData.localizationService.toLanguageString('custom.compliant', enMessages.custom.compliant),
          labelStyle: {color: "black"}
      });
      compliantSegment.labelProvider.formatLabel = (segment) => segment.toFixed(0) + "%";

      const nonCompliantSegement = new PieSegment({
          color: "#ff5034",
          value: props.chartData.autoComplianceData.nonCompliantCount === 0 ? 0.0001 : props.chartData.autoComplianceData.nonCompliantCount,
          text: props.chartData.localizationService.toLanguageString('custom.nonCompliant', enMessages.custom.nonCompliant),
          labelStyle: {color: "black"}
      });
      nonCompliantSegement.labelProvider.formatLabel = (segment) => segment.toFixed(0) + "%";

      // Set this to adjust the radial position of the labels
      // When positioning outside the pie, you may want to make further fine adjustments to label positions using the labelOffset as shown below
      sciChartPieSurface.labelRadiusAdjustment = 1.35;
  
      sciChartPieSurface.pieSegments.add(compliantSegment, nonCompliantSegement);

      sciChartPieSurface.legend.placementDivId = "legendAutoDiv"

      return { sciChartSurface: sciChartPieSurface };
    }}
    style={{
      aspectRatio: 1,
      width:"100%", height: "250px"
    }}
  />
  <div id="legendAutoDiv"></div>
  </div>
);