import { ReactElement, useRef, useState, } from "react";
import { useLocalization } from '@progress/kendo-react-intl';
import { StatusChart } from "../../../Components/Charts/Status/StatusChart";
import { IInitResult, SciChartGroup } from "scichart-react";
import { ModifierGroup } from "../../../../Shared/SciChart/ModifierGroup";
import { ChartModifierBase2D, ISciChartSurfaceBase, SciChartVerticalGroup } from "scichart";
import { synchronizeXVisibleRanges } from "../../../../Shared/SciChart/ChartConfiguration";
import { ComplianceChart } from "../../../Components/Charts/Compliance/ComplianceChart";
import ChartWaitLoader from "../../../../Shared/Components/ChartWaitLoader";
import moment from "moment";
import { GetVisibleRangeComplianceSeries } from "../../../../../Utilities/ChartUtilities";
import useChartStore from "../../../../../Zustand/chartStore";


interface Props {
  statusChartData: any;
  complianceDataChart: any;
}

export default function EndpointStatusCompliance(props: Props): ReactElement {
  const localizationService = useLocalization();

  const [modifierGroup] = useState(new ModifierGroup());
  const verticalGroup = new SciChartVerticalGroup();
 // Fetch the initial state, but we don't need to re-render when it changes as the chart will update itself
  const visibleRangeRef = useRef(useChartStore.getState().visibleRange);

  const [isChartsInitialised, setIsChartsInitialised] = useState(false);

  const latestVoulmeDispensendTimestampUnix = props.complianceDataChart.requirements.timestamps.length === 0 ? 0 : props.complianceDataChart.requirements.timestamps[props.complianceDataChart.requirements.timestamps.length - 1];
  const latestUnplannedEventTimestampUnix = props.complianceDataChart.requirements.unplannedEventsTimestamps.length === 0 ? 0 : props.complianceDataChart.requirements.unplannedEventsTimestamps[props.complianceDataChart.requirements.unplannedEventsTimestamps.length - 1];


  let visibleRange = GetVisibleRangeComplianceSeries(
    visibleRangeRef.current, 
    latestVoulmeDispensendTimestampUnix,
    latestUnplannedEventTimestampUnix, 
    moment(props.complianceDataChart.dateFilter.startDate).unix(), 
    moment(props.complianceDataChart.dateFilter.endDate).unix());

  const statusChartData = {...props.statusChartData, ...{modifierGroup: modifierGroup}, visibleRange};
  const complianceDataChart = {...props.complianceDataChart, ...{modifierGroup: modifierGroup},visibleRange};

  const configureStatusAndComplianceDataBindings = (initResults: IInitResult<ISciChartSurfaceBase>[] | [any, any]) => {
    const [statusChart, complianceChart] = initResults;
    
    modifierGroup.clear();

    var statusRollOverModifier = statusChart.sciChartSurface.chartModifiers.getById("StatusRollOver");
    modifierGroup.add(statusRollOverModifier as ChartModifierBase2D);

    const complianceModifierGroupModifier = complianceChart.sciChartSurface.chartModifiers.getById("ComplianceModifierGroup");
    const complianceRollOverModifier = complianceChart.sciChartSurface.chartModifiers.getById("ComplianceRollOver");
    modifierGroup.add(complianceModifierGroupModifier as ChartModifierBase2D, complianceRollOverModifier as ChartModifierBase2D);

    synchronizeXVisibleRanges([statusChart.sciChartSurface, complianceChart.sciChartSurface], () => true);

    verticalGroup.addSurfaceToGroup(statusChart.sciChartSurface);
    verticalGroup.addSurfaceToGroup(complianceChart.sciChartSurface);

  };

  return (
    <>
  
      <SciChartGroup
        onInit={(initResults: IInitResult[]) => {
          configureStatusAndComplianceDataBindings(initResults);
          setIsChartsInitialised(true);
        }}
      >
        {isChartsInitialised ? null : <ChartWaitLoader /> }
        <StatusChart key={"status-chart-compliance"} chartData={statusChartData}/>
        
        <ComplianceChart key={"compliance-chart"} chartData={complianceDataChart}/>
       
        <div className="flex flex-wrap">
          <div id="legend-compliance"></div>
          <div id="legend-status" ></div>
        </div>
      </SciChartGroup>
      </>
    
  )
}