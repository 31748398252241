import React, { ReactElement, useEffect, useMemo, useRef } from "react";
import AreaReadDto from "../../../Models/Area/AreaReadDto";
import { MachineReadDto } from "../../../Models/Machine/MachinesReadDto";
import { ConsumableReadDto } from "../../../Models/Consumable/ConsumableReadDto";
import { ScheduleReadDto } from "../../../Models/Schedule/ScheduleReadDto";
import { PointReadTableDto } from "../../../Models/Point/PointReadTableDto";
import { ColumnMetaData } from "../../Shared/Interfaces/ColumnMetaData";
import GBKendoDataCell from "../../Shared/Components/Table/GBKendoDataCell";
import useKendoTableState from "../../Shared/Hooks/useKendoTableState";
import PointValidationSchema from "../Utilities/PointValidationSchema";
import { Formik, FormikProps } from "formik";
import { Button } from "@progress/kendo-react-buttons";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { Grid, GridPageChangeEvent, GridToolbar, GridColumn as Column, GridFilterChangeEvent, GridSortChangeEvent, GridRowProps } from "@progress/kendo-react-grid";
import { isRequiredBySchema } from "../../Shared/Utilities/YupValidationUtilities";
import GBKendoToolbar from "../../Shared/Components/Table/GBKendoToolbar";
import { Checkbox } from "@progress/kendo-react-inputs";
import { CustomDateFilterCell } from "../../Shared/Components/Table/CustomDateFilterCell";
import { CustomTextFilterCell } from "../../Shared/Components/Table/CustomTextFilterCell";
import { CompositeFilterDescriptor, filterBy, orderBy } from "@progress/kendo-data-query";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNoteSticky } from "@fortawesome/free-solid-svg-icons";
import NotesDialog from "../../Shared/Components/Dialogs/NotesDialog";

interface Props {
  points: Array<PointReadTableDto>;
  schedules: Array<ScheduleReadDto>;
  areas: Array<AreaReadDto>;
  machines: Array<MachineReadDto>;
  consumables: Array<ConsumableReadDto>;
  createPoint: (values: any, setStatus: any) => void;
  deletePoints: (deleteIds: Array<string>) => void;
  updatePoints: (points: Array<PointReadTableDto>) => void;
  height: number;
}

const initialFilter: CompositeFilterDescriptor = {
  logic: "and",
  filters: [],
}


function PointTable(props: any) {
  const PURGE_POINT = -1;

  const localizationService = useLocalization();
  const [openNotes, setOpenNotes] = React.useState<boolean>(false);
  const [selectedItem, setSelectedItem] = React.useState<PointReadTableDto | null>(null);
  const closeHandler = () => {
    setOpenNotes(false);
    setSelectedItem(null)
  };

  const updateNotes = (notes: string) => {

     // Check if we are adding a new row, if so then we need to apply the description to the current temp row
    // instead of doing an update as the object does not exist yet. 
    if (tableState.tempRows.length > 0) {
      tableState.tempRows[0].description = notes;
      return;
    }

    if(selectedItem) {
      const castValues = {
        name: selectedItem.name,
        id: selectedItem.id,
        description: notes,
        status: selectedItem.status,
        consumableId : selectedItem.consumableId,
        pointType: selectedItem.pointType,
        machineId: selectedItem.machineId,
        areaId: selectedItem.areaId,
        photo: selectedItem.photo,
        crewType: selectedItem.crewType,
        criticality: selectedItem.criticality,
        securityClassification : selectedItem.securityClassification,
        currentTagRfid :  selectedItem.currentTagRfid,
        volumeRequirement : selectedItem.volumeRequirement,
        routeOrder : selectedItem.routeOrder,
        scheduleId: selectedItem.scheduleId,
        expanded: selectedItem.expanded,
        consumableName: selectedItem.consumableName,
        machineName: selectedItem.machineName,
        areaName: selectedItem.areaName,
        scheduleName: selectedItem.scheduleName,
        initialRouteOrder: selectedItem.initialRouteOrder,
            }  as PointReadTableDto

      props.updatePoints([castValues])
    }
  }

  const tableState = useKendoTableState<PointReadTableDto>({
    key: "Points",
    initialAssets: props.points,
  });

  const stateRef = useRef(tableState);

  useEffect(() => {
    stateRef.current = tableState
   }, [tableState]);
  
  const [filter, setFilter] = React.useState(initialFilter);
  const [sort, setSort] = React.useState(props.initialSortDescriptor ?? props.initialSort);
 
  // Table columns
  const columns: Array<ColumnMetaData<PointReadTableDto>> = React.useMemo(
      () =>
        [
          {
            header: `${localizationService.toLanguageString('custom.notes', enMessages.custom.notes)}`,
            displayField: "description",
            filterType: "boolean",
            Cell: (cellProps) => (
              <GBKendoDataCell<PointReadTableDto>
                className={"text-center"}
                dataFieldName="description"
                cellProps={cellProps}
                fieldAccessor={(x) => {
                   if(x.description === '' || x.description === null) {
                    return <Button fillMode={'flat'} icon="plus-outline"  onClick={()=> {
                      setOpenNotes(true)
                      setSelectedItem(cellProps.dataItem)
                    }}/>
                  } else {
                    return <Button fillMode={'flat'} onClick={()=> {
                      setOpenNotes(true)
                      setSelectedItem(cellProps.dataItem)
                    }}  ><FontAwesomeIcon icon={faNoteSticky} 
                    /></Button>
                  }
                }}
                isAddMode={false}
                isEditMode={false}
                onCellChanged={tableState.onCellChanged}
              />
            ),
          },
          {
            header: `${localizationService.toLanguageString('custom.name', enMessages.custom.name)}`,
            displayField: "name",
            filterType: "text",
            Cell: (cellProps) => (
              <GBKendoDataCell<PointReadTableDto>
                dataFieldName="name"
                cellProps={cellProps}
                fieldAccessor={(x) => x.name}
                isAddMode={cellProps.dataIndex < tableState.tempRows.length}
                isEditMode={tableState.canModifyRow}
                onCellChanged={tableState.onCellChanged}
              />
            ),
          },
          {
            header: `${localizationService.toLanguageString('custom.order', enMessages.custom.order)}`,
            displayField: "routeOrder",
            Cell: (cellProps) => (
              <GBKendoDataCell<PointReadTableDto>
                dataFieldName="routeOrder"
                cellProps={cellProps}
                fieldAccessor={(x) => x.routeOrder}
                isAddMode={cellProps.dataIndex < tableState.tempRows.length}
                isEditMode={tableState.canModifyRow}
                onCellChanged={tableState.onCellChanged}
              />
            ),
          },
          {
            header: `${localizationService.toLanguageString('custom.asset', enMessages.custom.asset)}`,
            displayField: "machineName",
            Cell: (cellProps) => (
              <GBKendoDataCell<PointReadTableDto>
                dataFieldName="machineId"
                fieldAccessor={(x) => x.machineId}
                displayTextAccessor={(x) => x.name}
                cellProps={cellProps}
                isAddMode={cellProps.dataIndex < tableState.tempRows.length}
                isEditMode={tableState.canModifyRow}
                onCellChanged={tableState.onCellChanged}
                assets={props.machines.filter(
                  (x: { areaId: string; }) =>
                    cellProps.dataItem.areaId == null ||
                    cellProps.dataItem.areaId === "" ||
                    x.areaId === cellProps.dataItem.areaId
                )} //Only show valid machines if area selected
                inputType="combobox"
                initialDisplayText={cellProps.dataItem.machineName}
                onChange={(newValue, form) => onMachineSelected(cellProps.dataItem.id, newValue, form)}
              />
            )
          },
          {
            header: `${localizationService.toLanguageString('custom.location', enMessages.custom.location)}`,
            displayField: "areaName",
            Cell: (cellProps) => (
              <GBKendoDataCell<PointReadTableDto>
                dataFieldName="areaId"
                fieldAccessor={(x) => x.areaId}
                displayTextAccessor={(x) => x.name}
                cellProps={cellProps}
                isAddMode={cellProps.dataIndex < tableState.tempRows.length}
                isEditMode={tableState.canModifyRow}
                onCellChanged={tableState.onCellChanged}
                assets={props.areas}
                inputType="combobox"
                initialDisplayText={cellProps.dataItem.areaName}
              />
            ),
          },
          {
            header: `${localizationService.toLanguageString('custom.consumable', enMessages.custom.consumable)}`,
            displayField: "consumableName",
            Cell: (cellProps) => (
              <GBKendoDataCell<PointReadTableDto>
                dataFieldName="consumableId"
                fieldAccessor={(x) => x.consumableId}
                displayTextAccessor={(x) => x.name}
                cellProps={cellProps}
                isAddMode={cellProps.dataIndex < tableState.tempRows.length}
                isEditMode={tableState.canModifyRow}
                onCellChanged={tableState.onCellChanged}
                assets={props.consumables}
                inputType="combobox"
                initialDisplayText={cellProps.dataItem.consumableName}
              />
            ),
          },
          {
            header: `${localizationService.toLanguageString('custom.volume', enMessages.custom.volume)}`,
            displayField: "volumeRequirement",
            filterType: "text",
            Cell: (cellProps) => (
              <GBKendoDataCell<PointReadTableDto>
                dataFieldName="volumeRequirement"
                cellProps={cellProps}
                fieldAccessor={(x) => x.volumeRequirement === PURGE_POINT ? x.volumeRequirement="Purge" : x.volumeRequirement}
                isAddMode={cellProps.dataIndex < tableState.tempRows.length}
                isEditMode={tableState.canModifyRow}
                onCellChanged={tableState.onCellChanged}
              />
            ),
          },
          {
            header: `${localizationService.toLanguageString('custom.rfid', enMessages.custom.rfid)}`,
            displayField: "currentTagRfid",
            filterType: "text",
            Cell: (cellProps) => (
              <GBKendoDataCell<PointReadTableDto>
                dataFieldName="currentTagRfid"
                cellProps={cellProps}
                fieldAccessor={(x) => x.currentTagRfid}
                isAddMode={cellProps.dataIndex < tableState.tempRows.length}
                isEditMode={tableState.canModifyRow}
                onCellChanged={tableState.onCellChanged}
              />
            ),
          },
          {
            header: `${localizationService.toLanguageString('custom.schedule', enMessages.custom.schedule)}`,
            displayField: "scheduleName",
            Cell: (cellProps) => (
              <GBKendoDataCell<PointReadTableDto>
                dataFieldName="scheduleId"
                fieldAccessor={(x) => x.scheduleId}
                displayTextAccessor={(x) => x.name}
                cellProps={cellProps}
                isAddMode={cellProps.dataIndex < tableState.tempRows.length}
                isEditMode={tableState.canModifyRow}
                onCellChanged={tableState.onCellChanged}
                assets={props.schedules}
                inputType="combobox"
                placeholder="No Schedule"
                initialDisplayText={cellProps.dataItem.scheduleName}
              />
            ),
          },
        ],
        [
          tableState.canModifyRow,
          tableState.tempRows.length,
          props.machines,
          props.consumables,
          props.schedules,
          props.areas,
          props.points,
          filter,
          sort
        ]
      );

  const _export = React.useRef<ExcelExport | null>(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };
  const ref = useRef<any>();

  /**The following use effects are required to fix a race condition created by updating formik fields
   * Programatically. They ensure that validation is performed after the values update instead of before.
   **/
  useEffect(() => {
    ref.current.validateForm();
  }, [ref.current?.values]);
  useEffect(() => {
    ref.current.validateForm();
  }, [ref.current]);

  /**
   * Set the skip amount to zero if the number of temp rows (indicating an add row has been added).
   * This effectively ensures the add row is always scrolled into view
   */
  useEffect(() => {
    if (tableState.tempRows.length === 1) {
      setSkip(0);
    }
  }, [tableState.tempRows.length]);

  //The following state is required for dynamic scrolling
  const [skip, setSkip] = React.useState<number>(0);
  const pageChange = (event: GridPageChangeEvent) => {
    setSkip(event.page.skip);
  };

  const isColumnForRequiredField = (displayField: string | undefined): boolean => {
    return (
      isRequiredBySchema(displayField?.replace("Name", "Id"), PointValidationSchema(localizationService)) &&
      (tableState.tempRows.length > 0 || tableState.canModifyRow)
    );
  };

  useEffect(() => {
    // Reset skip when filter or sort changes
    setSkip(0);
  }, [filter, sort]);

  //Data is API data + current temporary rows that have not yet been persisted using the API
  let processedData = useMemo(() => {

    let initialRows = tableState.assets;

    if (filter) {
      initialRows = filterBy(initialRows, filter);
    }

    if (sort) {
      initialRows = orderBy(initialRows, sort);
    }
    //Add any temp rows to beggining
    return tableState.tempRows.concat(initialRows);
  }, [tableState.assets, tableState.tempRows, filter, sort]);

  /**
   * When machine selected, auto populate parent area to that of machine
   */
   const onMachineSelected = async (rowId: string, newValue: any, form: FormikProps<any>) => {
    const selectedMachine = props.machines.find((x: { id: any; }) => x.id === newValue);
    const selectedArea = props.areas.find((x: { id: any; }) => x.id === selectedMachine?.areaId);
    //If the field being edited is an addrow
    if (rowId == null || rowId === "") {
      tableState.setTempRows((old: Array<PointReadTableDto>) => {
        let updatedAssets = [...old];
        updatedAssets[0] = {
          ...updatedAssets[0],
          areaId: selectedArea?.id ?? "",
          areaName: selectedArea?.name ?? "",
        };
        return updatedAssets;
      });
      form.setFieldValue("areaId", selectedMachine?.areaId ?? "", false);
      await form.setFieldError("areaId", "undefined"); //No errors on selection
      return;
    }

    //Now update current machine to have same area as selected
    tableState.setAssets((old: Array<PointReadTableDto>) => {
      const modifiedRowIndex = old.findIndex((x) => x.id === rowId);
      let updatedAssets = [...old];
      updatedAssets[modifiedRowIndex] = {
        ...updatedAssets[modifiedRowIndex],
        areaId: selectedArea?.id ?? "",
        areaName: selectedArea?.name ?? "",
      };
      return updatedAssets;
    });
  };

  //Initial row added
  const initialisedRowData = {
    routeOrder: 0,
    name: "",
    id: "",
    pointType: "GreasingPoint",
    machineId: "",
    areaId: "",
    consumableId: "",
    volumeRequirement: 0,
    greasingTagRfid: "",
    scheduleId: null,
    status: "Active",
    consumableName: "",
    machineName: "",
    areaName: "",
    scheduleName: "",
    initialRouteOrder: 0,
    photo: "",
    description: "",
    crewType: "",
    criticality: "Normal",
    securityClassification: "Unclassified",
    currentTagRfid : "",
    volumeTolerance: 0,
    expanded: true
  };

  return (
    <div>
      <Formik
        innerRef={ref}
        key={props.points.length}
        onSubmit={async (values, { setStatus, resetForm }) => {
          if (tableState.tempRows.length > 0)
          {
            values.description = tableState.tempRows[0].description;
          }
          const res = await props.createPoint(values, setStatus);
          if (res) {
            resetForm();
            tableState.setTempRows([]);
          }
        }}
        validationSchema={PointValidationSchema(localizationService)}
        initialValues={initialisedRowData}
      >
        {({ submitForm, values, resetForm, isSubmitting }) => (
          <>
            <ExcelExport data={processedData} ref={_export} collapsible={true} fileName={localizationService.toLanguageString('custom.greaseTags', enMessages.custom.greaseTags)}>
              <ExcelExportColumn field="name" title={localizationService.toLanguageString('custom.name', enMessages.custom.name)} />
              <ExcelExportColumn field="routeOrder" title={localizationService.toLanguageString('custom.order', enMessages.custom.order)} />
              <ExcelExportColumn field="machineName" title={localizationService.toLanguageString('custom.asset', enMessages.custom.asset)} />
              <ExcelExportColumn field="areaName" title={localizationService.toLanguageString('custom.location', enMessages.custom.location)} />
              <ExcelExportColumn field="consumableName" title={localizationService.toLanguageString('custom.consumable', enMessages.custom.consumable)} />
              <ExcelExportColumn field="volumeRequirement" title={localizationService.toLanguageString('custom.volumeRequirement', enMessages.custom.volumeRequirement)} />
              <ExcelExportColumn field="currentTagRfid" title={localizationService.toLanguageString('custom.rfid', enMessages.custom.rfid)} />
              <ExcelExportColumn field="scheduleName" title={localizationService.toLanguageString('custom.schedule', enMessages.custom.schedule)} />
              <ExcelExportColumn field="description" title={localizationService.toLanguageString('custom.notes', enMessages.custom.notes)} />
            </ExcelExport>
            <Grid
              key={tableState.tempRows.length.toString()}
              rowHeight={46}
              pageSize={50}
              total={processedData.length}
              data={processedData}
              expandField="expanded"
              scrollable="virtual"
              skip={skip}
              onPageChange={pageChange}
              filterable={true}
              filter={filter}
              onFilterChange={(e: GridFilterChangeEvent) => setFilter(e.filter)}
              sortable={true}
              sort={sort}
              onSortChange={(e: GridSortChangeEvent) => {setSort(e.sort);}}
              style={{
                height: props.height,
              }}
              className="w-full  z-0"
              //Custom render row,
              rowRender={(trElement: ReactElement, props: GridRowProps) => {
                let baseStyle = { ...trElement.props.style };
                if (tableState.modifiedRowIds.includes(props.dataItem.id)) {
                  baseStyle.backgroundColor = "rgba(255, 252, 88, 0.25)";
                }
                if (tableState.selectedRowsIds.includes(props.dataItem.id)) {
                  baseStyle.backgroundColor = "rgba(255, 99, 88, 0.25)";
                }

                return React.cloneElement(
                  trElement,
                  {
                    ...trElement,
                    style: { ...baseStyle },
                  },
                  trElement.props.children
                );
              }}
            >
              <GridToolbar>  
                <GBKendoToolbar
                  name={`${localizationService.toLanguageString('custom.tagMapping', enMessages.custom.tagMapping)}`}
                  tableState={tableState}
                  exportAction={excelExport}
                  importAction={null}
                  addAction={
                    props.createPoint
                      ? () => {
                        if (tableState.tempRows.length === 1) {
                          tableState.setTempRows([]);
                          return;
                        }
                        tableState.setTempRows([...tableState.tempRows, initialisedRowData]);
                        }
                      : null
                  }
                  updateAction={
                    props.updatePoints
                      ? async () => {
                          let updatedAssets = tableState.assets.filter((x) => tableState.modifiedRowIds.includes(x.id));
                          await props.updatePoints(updatedAssets);
                        }
                      : null
                  }
                  deleteAction={
                    props.deletePoints ? async () => await props.deletePoints(tableState.selectedRowsIds) : null
                  }
                  discardChanges={() => tableState.resetState()}
                />
              </GridToolbar>
             
              {/* Selection - only if in edit mode*/}
              {tableState.canModifyRow && (
                <Column  
                  key={"Selection"}
                  cell={(props) => (
                    (props.rowType === 'groupHeader' ? 
                    null :
                    <td>  
                        <Checkbox
                          onChange={() => {
                            if (tableState.selectedRowsIds.includes(props.dataItem.id)) {
                              tableState.setSelectedRowIds(
                                tableState.selectedRowsIds.filter((id) => id !== props.dataItem?.id)
                              );
                            } else {
                              tableState.setSelectedRowIds([...tableState.selectedRowsIds, props.dataItem?.id]);
                            }
                          }}
                          checked={tableState.selectedRowsIds.includes(props.dataItem.id)}
                        />
                    </td>)
                  )}
                  width="50px"
                  filterable={false}
                  sortable={false} />) 
                }
              

               {/* From Column Array */}
               {columns.map((column, index) => {
                if(column.displayField === "description") {
                  return (
                    //Create wrapper/HOC with editable cell and add cell definitions,
                    <Column
                      width="65px"
                      className="text-center"
                      key={index}
                      cell={column.Cell}
                      title={`${column.header}${isColumnForRequiredField(column.displayField) ? "*" : ""}`}
                      field={column.displayField}
                      filterable={false}
                      sortable={true}
                    />
                  );
                }else {
                  return (
                    //Create wrapper/HOC with editable cell and add cell definitions,
                    <Column
                      width="auto"
                      key={index}
                      cell={column.Cell}
                      // Show asterix in header if required TODO: pretty hacky - assumes that the name of the data field is the same as the display field with Name replaced.
                      title={`${column.header}${isColumnForRequiredField(column.displayField) ? "*" : ""}`}
                      field={column.displayField}
                      filter={column.filterType}
                      filterCell={column.filterType === "date" ? CustomDateFilterCell : CustomTextFilterCell}
                    />
                  );
                }
              })}

              {/* Inline Action options (If ActionDefinitions are provided) */}
              {props.extraInlineActions != null && props.extraInlineActions.length > 0 && (
                <Column
                  title={localizationService.toLanguageString('custom.actions', enMessages.custom.actions)}
                  sortable={false}
                  filterable={false}
                  cell={(cellProps) => {
                    return (
                      <td className="flex flex-wrap justify-around gap-1">
                        {tableState.tempRows.length <= cellProps.dataIndex &&
                          props.extraInlineActions.map((action: any) => {
                            return (
                              <Button
                                className=""
                                themeColor={action.themeColor}
                                onClick={() => action.onClick(cellProps.dataItem)}
                              >
                                {action.text}
                              </Button>
                            );
                          })}
                      </td>
                    );
                  }}
                />
              )}

              {/* Command Cell (For add rows only)*/}
              {tableState.tempRows.length > 0 && (
                <Column
                  width="150px"
                  sortable={false}
                  filterable={false}
                  key={"Commands"}
                  cell={(props) => // Add Button for new assets
                  (props.rowType === 'groupHeader' ?
                    null :
                    (
                      <td>
                        {props.dataIndex === 0 && (
                          <div className="flex space-x-1">
                            <Button onClick={submitForm} disabled={isSubmitting} themeColor={"primary"}>
                            {localizationService.toLanguageString('custom.add', enMessages.custom.add)}
                            </Button>
                            <Button
                              onClick={() => {
                                tableState.setTempRows([]);
                                resetForm();
                              } }
                              themeColor={"base"}
                            >
                              {localizationService.toLanguageString('custom.exit', enMessages.custom.exit)}
                            </Button>
                          </div>
                        )}
                      </td>)
                  )}
                />
              )}
            </Grid>
          </>
          )}
      </Formik>
      {openNotes && (
        <NotesDialog onClose={closeHandler} notes={selectedItem} updateNotes={updateNotes} asset={selectedItem?.machineName}/>
      )}
    </div>
  );
};

export default PointTable;