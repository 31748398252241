import React, { useRef, useEffect, useMemo, ReactElement } from "react";
import AreaReadDto from "../../../Models/Area/AreaReadDto";
import { ConsumableReadDto } from "../../../Models/Consumable/ConsumableReadDto";
import { DeviceUpdateDto } from "../../../Models/Device/DeviceUpdateDto";
import useKendoTableState from "../../Shared/Hooks/useKendoTableState";
import { ColumnMetaData } from "../../Shared/Interfaces/ColumnMetaData";
import { DeviceReadTableDto } from "../../../Models/Device/DeviceReadTableDto";
import GBKendoDataCell from "../../Shared/Components/Table/GBKendoDataCell";
import DeviceValidationSchema from "../Utilities/DeviceValidationSchema";
import { CompositeFilterDescriptor, filterBy, orderBy } from "@progress/kendo-data-query";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Grid, GridFilterChangeEvent, GridPageChangeEvent, GridSortChangeEvent, GridToolbar, GridColumn as Column, GridRowProps } from "@progress/kendo-react-grid";
import { isRequiredBySchema } from "../../Shared/Utilities/YupValidationUtilities";
import { Formik } from "formik";
import GBKendoToolbar from "../../Shared/Components/Table/GBKendoToolbar";
import { Checkbox } from "@progress/kendo-react-inputs";
import { CustomDateFilterCell } from "../../Shared/Components/Table/CustomDateFilterCell";
import { CustomTextFilterCell } from "../../Shared/Components/Table/CustomTextFilterCell";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";
import moment from "moment";
import {  Tooltip } from "@progress/kendo-react-tooltip";

interface Props {
  handleSubmit: any;
  initialSort: any;
  initialSortDescriptor: any;
  devices: Array<DeviceReadTableDto>;
  consumables: Array<ConsumableReadDto>;
  areas: Array<AreaReadDto>;
  updateDevice: (devices: Array<DeviceUpdateDto>) => void;
  height: number;
}

const initialFilter: CompositeFilterDescriptor = {
  logic: "and",
  filters: [],
};

function DeviceTable(props: Props) {
  const tableState = useKendoTableState({key: "Devices", initialAssets: props.devices });
  
  const stateRef = useRef(tableState);

  const localizationService = useLocalization();

  useEffect(() => {
    stateRef.current = tableState
   }, [tableState]);

  const [filter, setFilter] = React.useState(initialFilter);
  const [sort, setSort] = React.useState(props.initialSortDescriptor ?? props.initialSort);

    // Table columns
  const columns = React.useMemo<Array<ColumnMetaData<DeviceReadTableDto>>>(
    () => [
      {
        header: `${localizationService.toLanguageString('custom.location', enMessages.custom.location)}`,
        displayField: "areaName",
        Cell: (cellProps) => (
          <GBKendoDataCell<DeviceReadTableDto>
            placeholder="All Locations"
            dataFieldName="areaId"
            fieldAccessor={(x) => x.areaId}
            displayTextAccessor={(x) => x.name}
            cellProps={cellProps}
            isAddMode={false}
            isEditMode={tableState.canModifyRow}
            onCellChanged={tableState.onCellChanged}
            assets={props.areas}
            inputType="combobox"
            initialDisplayText={cellProps.dataItem.areaName}
          />
        ),
      },
      {
        header: `${localizationService.toLanguageString('custom.consumable', enMessages.custom.consumable)}`,
        displayField: "consumableName",
        Cell: (cellProps) => (
          <GBKendoDataCell<DeviceReadTableDto>
            placeholder="All Consumables"
            dataFieldName="consumableId"
            fieldAccessor={(x) => x.consumableId}
            displayTextAccessor={(x) => x.name}
            cellProps={cellProps}
            isAddMode={false}
            isEditMode={tableState.canModifyRow}
            onCellChanged={tableState.onCellChanged}
            assets={props.consumables}
            inputType="combobox"
            initialDisplayText={cellProps.dataItem.consumableName}
          />
        ),
      },
      {
        header: `${localizationService.toLanguageString('custom.modelNumber', enMessages.custom.modelNumber)}`,
        displayField: "modelNumber",
        filterType: "text",
        Cell: (cellProps) => (
          <GBKendoDataCell<DeviceReadTableDto>
            dataFieldName="modelNumber"
            cellProps={cellProps}
            fieldAccessor={(x) => x.modelNumber}
            isAddMode={false}
            isEditMode={false}
            onCellChanged={tableState.onCellChanged}
          />
        ),
      },
      {
        header: `${localizationService.toLanguageString('custom.serialNumber', enMessages.custom.serialNumber)}`,
        displayField: "serialNumber",
        filterType: "text",
        Cell: (cellProps) => (
          <GBKendoDataCell<DeviceReadTableDto>
            dataFieldName="serialNumber"
            cellProps={cellProps}
            fieldAccessor={(x) => x.serialNumber}
            isAddMode={false}
            isEditMode={false}
            onCellChanged={tableState.onCellChanged}
          />
        ),
      },
      {
        header: `${localizationService.toLanguageString('custom.firmwareVersion', enMessages.custom.firmwareVersion)}`,
        displayField: "firmwareVersion",
        filterType: "text",
        Cell: (cellProps) => (
          <GBKendoDataCell<DeviceReadTableDto>
            dataFieldName="firmwareVersion"
            cellProps={cellProps}
            fieldAccessor={(x) => x.firmwareVersion}
            isAddMode={false}
            isEditMode={false}
            onCellChanged={tableState.onCellChanged}
          />
        ),
      },
      {
        header: `${localizationService.toLanguageString('custom.lastSeen', enMessages.custom.lastSeen)}`,
        displayField: "lastConnected",
        filterType: "text",
        Cell: (cellProps) => (
          <GBKendoDataCell<DeviceReadTableDto>
            dataFieldName="lastConnected"
            cellProps={cellProps}
           fieldAccessor={(x) => {
              const lastSeen = moment(x.lastConnected);
              let tootipText
              let lastSeenText
              if (lastSeen.year() !== 1970)
              {
                tootipText = moment(x.lastConnected).format('DD/MM/YYYY @ HH:mm:ss').toString()
                lastSeenText = lastSeen.fromNow()

              } else {
                tootipText = `${localizationService.toLanguageString('custom.deviceNotConnected', enMessages.custom.deviceNotConnected)}`
                lastSeenText = `${localizationService.toLanguageString('custom.never', enMessages.custom.never)}`
              }
              return <div style={{display:'flex', alignItems:'center', }}>
              <Tooltip ref={tooltip} anchorElement="target" position="bottom" openDelay={2} content={(props) => <TooltipContentTemplate title={props.title} />}>
                <div style={{cursor:'pointer'}} title={tootipText} onMouseOver={(event) => tooltip.current && tooltip.current.handleMouseOver(event)}
                onMouseOut={(event) => tooltip.current && tooltip.current.handleMouseOut(event)}>{lastSeenText}</div>
              </Tooltip>
            </div>;
            }}
            isAddMode={false}
            isEditMode={false}
            onCellChanged={tableState.onCellChanged}
          />
        ),
      },
    ],
    [tableState.canModifyRow, tableState.tempRows.length, filter, sort]
  );

    const tooltip = React.useRef<Tooltip>(null);
  const TooltipContentTemplate = (props: any) => {
    return (
      <div>{props.title}</div>
    );
  };

  const _export = React.useRef<ExcelExport | null>(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };
  const ref = useRef<any>();

  /**The following use effects are required to fix a race condition created by updating formik fields
   * Programatically. They ensure that validation is performed after the values update instead of before.
   **/
  useEffect(() => {
    ref.current.validateForm();
  }, [ref.current?.values]);
  useEffect(() => {
    ref.current.validateForm();
  }, [ref.current]);

  /**
   * Set the skip amount to zero if the number of temp rows (indicating an add row has been added).
   * This effectively ensures the add row is always scrolled into view
   */
  useEffect(() => {
    if (tableState.tempRows.length === 1) {
      setSkip(0);
    }
  }, [tableState.tempRows.length]);

  //The following state is required for dynamic scrolling
  const [skip, setSkip] = React.useState<number>(0);
  const pageChange = (event: GridPageChangeEvent) => {
    setSkip(event.page.skip);
  };

  const isColumnForRequiredField = (displayField: string | undefined): boolean => {
    return (
      isRequiredBySchema(displayField?.replace("Name", "Id"), DeviceValidationSchema) &&
      (tableState.tempRows.length > 0 || tableState.canModifyRow)
    );
  };

  useEffect(() => {
    // Reset skip when filter or sort changes
    setSkip(0);
  }, [filter, sort]);

  //Data is API data + current temporary rows that have not yet been persisted using the API
  const processedData = useMemo(() => {

    let initialRows = tableState.assets;

    if (filter) {
      initialRows = filterBy(initialRows, filter);
    }

    if (sort) {
      initialRows = orderBy(initialRows, sort);
    }
    //Add any temp rows to beggining
    return tableState.tempRows.concat(initialRows);
  }, [tableState.assets, tableState.tempRows, filter, sort]);

  const initialisedRowData = {
    id: "",
    type: "",
    areaId: "",
    guid: "",
    modelNumber: "",
    serialNumber: "",
    firmwareVersion: "",
    consumableId: "",
    status: "",
    reaName: "",
    consumableName: "",
    areaIds: "",
    description: "",
    lastConnected: "" ,
  };

  return (
    <div>
      <Formik
        innerRef={ref}
        key={props.devices.length}
        onSubmit={props.handleSubmit}
        validationSchema={DeviceValidationSchema}
        initialValues={initialisedRowData}
      >
        {({ submitForm, values, resetForm, isSubmitting }) => (
          <ExcelExport data={processedData} ref={_export} collapsible={true} fileName={localizationService.toLanguageString('custom.devices', enMessages.custom.devices)}>
            <Grid
              key={tableState.tempRows.length.toString()}
              rowHeight={46}
              pageSize={30}
              total={processedData.length}
              data={processedData}
              scrollable="virtual"
              skip={skip}
              onPageChange={pageChange}
              filterable={true}
              filter={filter}
              onFilterChange={(e: GridFilterChangeEvent) => setFilter(e.filter)}
              sortable={true}
              sort={sort}
              onSortChange={(e: GridSortChangeEvent) => {
                setSort(e.sort);
              }}
              style={{
                height: props.height,
              }}
              className="w-full  z-0"
              //Custom render row,
              rowRender={(trElement: ReactElement, props: GridRowProps) => {
                let baseStyle = { ...trElement.props.style };
                if (tableState.modifiedRowIds.includes(props.dataItem.id)) {
                  baseStyle.backgroundColor = "rgba(255, 252, 88, 0.25)";
                }
                if (tableState.selectedRowsIds.includes(props.dataItem.id)) {
                  baseStyle.backgroundColor = "rgba(255, 99, 88, 0.25)";
                }

                return React.cloneElement(
                  trElement,
                  {
                    ...trElement,
                    style: { ...baseStyle },
                  },
                  trElement.props.children
                );
              }}
            >
              <GridToolbar>  
                <GBKendoToolbar
                  name={localizationService.toLanguageString('custom.devices', enMessages.custom.devices)}
                  tableState={tableState}
                  exportAction={excelExport}
                  importAction={null}
                  addAction={null}
                  updateAction={
                    props.updateDevice
                      ? async () => {
                          let updatedAssets = tableState.assets.filter((x) => tableState.modifiedRowIds.includes(x.id));
                          await props.updateDevice(updatedAssets);
                        }
                      : null
                  }
                  deleteAction={null}
                  discardChanges={() => tableState.resetState()}
                />
              </GridToolbar>

              {/* Selection - only if in edit mode*/}
              {tableState.canModifyRow && (
                <Column  
                  key={"Selection"}
                  cell={(props) => (
                    <td>
                        <Checkbox
                          onChange={() => {
                            if (tableState.selectedRowsIds.includes(props.dataItem.id)) {
                              tableState.setSelectedRowIds(
                                tableState.selectedRowsIds.filter((id) => id !== props.dataItem?.id)
                              );
                            } else {
                              tableState.setSelectedRowIds([...tableState.selectedRowsIds, props.dataItem?.id]);
                            }
                          }}
                          checked={tableState.selectedRowsIds.includes(props.dataItem.id)}
                        />
                    </td>
                  )}
                  width="50px"
                  filterable={false}
                  sortable={false} />)}

               {/* From Column Array */}
               {columns.map((column, index) => {
                return (
                  //Create wrapper/HOC with editable cell and add cell definitions,
                  <Column
                    width="auto"
                    key={index}
                    cell={column.Cell}
                    // Show asterix in header if required TODO: pretty hacky - assumes that the name of the data field is the same as the display field with Name replaced.
                    title={`${column.header}${isColumnForRequiredField(column.displayField) ? "*" : ""}`}
                    field={column.displayField}
                    filter={column.filterType}
                    filterCell={column.filterType === "date" ? CustomDateFilterCell : CustomTextFilterCell}
                  />
                );
              })}
            </Grid>
          </ExcelExport>
        )}
      </Formik>
    </div> 
  );
};

export default DeviceTable;