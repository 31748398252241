import { useToast } from "@chakra-ui/react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button, Chip } from "@progress/kendo-react-buttons";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";
import { Grid,  GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { Popover, PopoverActionsBar, Tooltip } from "@progress/kendo-react-tooltip";
import moment from "moment";
import greaseReservoirsApiService from "../Utilities/GreaseReservoirApiService";
import { ReservoirHistoryDto } from "../../../Models/Reservoirs/ReservoirHistoryDto";
import LoadingSpinner from "../../Shared/Components/LoadingSpinner";
import { useQuery } from "react-query";
import { useEffect, useMemo, useRef, useState } from "react";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { SortDescriptor, orderBy } from "@progress/kendo-data-query";

interface Props {
  reservoirId: string;
  reservoirDisplayUnit: string;
}

export default function ReservoirResetHistory(props: Props) {
  const toast = useToast({
    position: "bottom",
    duration: 5000,
    isClosable: true,
  });
  const localizationService = useLocalization()

  const initialSort: Array<SortDescriptor> = [
    { field: "timestamp", dir: "desc" },
  ];
  const [sort, setSort] = useState(initialSort);
  const key = "ResetHistory" + props.reservoirId;
  const greaseReservoirsQuery = useQuery<Array<ReservoirHistoryDto>>(key, async () => {
    return greaseReservoirsApiService.getResetHistory(props.reservoirId);
  });

  const _export = useRef<ExcelExport | null>(null);
  const exportExport = () => {
    if (_export.current !== null) {
      _export.current.save(processedData);
    }
  };

  const DateCell = (props: any) => {
    return (
      <td>
          <div>{moment(props.dataItem.resetDate).format('DD/MM/YY HH:mm:ss')}</div>
      </td>
    );
  };

  const processedData = useMemo(() => {
    
    let items = greaseReservoirsQuery.data;
    
    if (items != undefined)
    {
      items = items.map(item => {
        const timestamp = (moment(item.timestamp).format('DD/MM/YY HH:mm:ss'));
        return {...item, resetBy: (`${item.user} (${timestamp})`)}
      });

      return orderBy(items, sort);
    }

    return items;
  }, [greaseReservoirsQuery.data]);

  if (greaseReservoirsQuery.isLoading || greaseReservoirsQuery.isFetching) {
    return <div style={{height:"220px",  width: '800px' }}><LoadingSpinner/></div>
  } else {
    return (
      <div>
        <ExcelExport data={processedData} ref={_export} fileName={localizationService.toLanguageString('custom.resetHistory', enMessages.custom.resetHistory)}>
          <ExcelExportColumn field="resetDate" title={localizationService.toLanguageString('custom.date', enMessages.custom.date)} cellOptions={{ format: "dd/mm/yy hh:mm:ss" }} width={150}/>
          <ExcelExportColumn field="resetLevel" title={`${localizationService.toLanguageString('custom.resetLevel', enMessages.custom.resetLevel)}(${props.reservoirDisplayUnit})`} />
          <ExcelExportColumn field="resetBy" title={localizationService.toLanguageString('custom.resetBy', enMessages.custom.resetBy)} /> 
          <ExcelExportColumn field="comments" title={localizationService.toLanguageString('custom.comments', enMessages.custom.comments)} />
        </ExcelExport>
        <Grid style={{height:"220px",  width: '800px' }} data={processedData}>
          <GridToolbar>
            <div className="flex-1 justify-between items-center">
              <div className="gb-h4">Reset History</div>
                <Tooltip anchorElement="target" position="bottom">
                  <Button
                    title={localizationService.toLanguageString('custom.exportToExcel', enMessages.custom.exportToExcel)}
                    icon="download"
                    onClick={exportExport}
                  />
              </Tooltip>
            </div>
          </GridToolbar>
          <GridColumn field="resetDate" title={localizationService.toLanguageString('custom.resetDate', enMessages.custom.resetDate)} cell={DateCell} width={140}/>
          <GridColumn field="resetLevel" title={`${localizationService.toLanguageString('custom.resetLevel', enMessages.custom.resetLevel)} (${props.reservoirDisplayUnit})`} width={130} />
          <GridColumn field="resetBy" title={localizationService.toLanguageString('custom.resetBy', enMessages.custom.resetBy)} width={280}/>
          <GridColumn field="comment" title={localizationService.toLanguageString('custom.comments', enMessages.custom.comments)}/>
        </Grid>
    </div>
  )
  }
  
}
