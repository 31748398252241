import { ReactElement, useEffect } from "react";
import { useLocalization } from '@progress/kendo-react-intl';
import {SciChartSurface} from "scichart/Charting/Visuals/SciChartSurface";
import { MouseWheelZoomModifier} from "scichart/Charting/ChartModifiers/MouseWheelZoomModifier";
import {ZoomExtentsModifier} from "scichart/Charting/ChartModifiers/ZoomExtentsModifier";
import { SciChartJSLightTheme} from "scichart/Charting/Themes/SciChartJSLightTheme";
import { ZoomPanModifier } from "scichart/Charting/ChartModifiers/ZoomPanModifier";
import { EAxisAlignment } from "scichart/types/AxisAlignment";
import { NumericAxis } from "scichart/Charting/Visuals/Axis/NumericAxis";
import { AnnotationDragDeltaEventArgs, EExecuteOn, ELabelPlacement, EXyDirection, FastBandRenderableSeries, HorizontalLineAnnotation, ModifierMouseArgs, NumberRange, RubberBandXyZoomModifier, XyyDataSeries } from "scichart";


interface Props {
    threshold: Array<{id: number, name: string, value: number, colour: string, unit: string}>
    volume: number
    updateChart: (item) => void;
    updateVolume: (item) => void;
    checked: boolean
}

export default function ThresholdChart(props: Props): ReactElement {
  const localizationService = useLocalization();

  useEffect(() => {
    initSciChart()
  },[props.threshold, props.volume, props.checked])
  
  const allLines = new Array
  const allValues = props.threshold.map(x => x.value)

 for(let i=0; i < props.threshold.length; i++) {
  allLines.push([props.threshold[i].value, props.threshold[i].value])
 }
 allLines.unshift([Math.max(...allValues) * 1.8,Math.max(...allValues) * 1.8])

  class HorizontalLine extends HorizontalLineAnnotation {
    public lineThreshold
    public lower
    public upper
    public onDragAdorner(args: ModifierMouseArgs): void {
      const xyValues = this.getValuesFromCoordinates(args.mousePoint, true);
      this.y1 = xyValues.y
      if(this.y1 > this.upper) {
        this.y1= this.upper
      }
      if(this.y1 < this.lower) {
        this.y1= this.lower
      }
      this.dragDelta.raiseEvent(new AnnotationDragDeltaEventArgs());
    }
  }

  async function initSciChart() {
  
    const theme = {... new SciChartJSLightTheme()}
    theme.loadingAnimationForeground = "rgba(255, 54, 48, 1)";
    theme.loadingAnimationBackground = '#FAFAFA'; 

    const {sciChartSurface, wasmContext} = await SciChartSurface.create("scichart-root", {theme});
    
    const xAxis = new NumericAxis(wasmContext, { 
      drawMajorBands: false, 
      drawMinorGridLines: false, 
      drawMajorGridLines: false, 
      isVisible: false,
      visibleRange: new NumberRange(0,1)
,     growBy: new NumberRange(0.05, 0.05),
      labelStyle: {fontFamily:"Roboto", fontSize:12}},
      );
    
    const yAxis = new NumericAxis(wasmContext,{
      axisAlignment: EAxisAlignment.Right, 
      drawMajorBands: false,
      drawMinorGridLines: false, 
      drawMajorGridLines: true,  
      maxAutoTicks:5,
      labelPrecision: 2,
      visibleRange: new NumberRange(0,Math.max(...allValues) * 1.8) ,     
      zoomExtentsToInitialRange: true,
      labelStyle: {fontFamily:"Roboto", fontSize:12}});

    sciChartSurface.xAxes.add(xAxis);
    sciChartSurface.yAxes.add(yAxis);

    sciChartSurface.chartModifiers.add(
    new ZoomPanModifier({xyDirection: EXyDirection.XDirection}),
    new MouseWheelZoomModifier({xyDirection:EXyDirection.XDirection}),
    new ZoomExtentsModifier(),
    new RubberBandXyZoomModifier({ executeOn: EExecuteOn.MouseRightButton }),
  );

    for(let i = 0; i < props.threshold.length; i++) {
      let max
      let min = 0
      if(i != 0) {
        max = props.threshold[i - 1].value -0.1
        
      }
      if( i != props.threshold.length - 1) {
        min =props.threshold[i + 1].value + 0.1
      }

      const line =  new HorizontalLine({
        labelPlacement: ELabelPlacement.Axis ,
        showLabel: true,
        stroke: props.threshold[i].colour,
        strokeThickness: 2,
        y1: props.threshold[i].value,
        x1: 0,
        axisLabelFill: props.threshold[i].colour,
        isEditable: !props.checked,
        onDragStarted: (()=> {
          line.lineThreshold = props.threshold[i].value
          line.lower = min
          line.upper = max
        }),
        onDragEnded: (()=> {
          props.threshold[i].value = line.y1
          props.updateChart(props.threshold)
         })
        })
      if(i != props.threshold.length -1) {
        sciChartSurface.annotations.add(line)
      }
      
      const dataSeries = new XyyDataSeries(wasmContext);
      dataSeries.appendRange([0,1], allLines[i+1], allLines[i]);
      const bandSeries = new FastBandRenderableSeries(wasmContext, {fill: props.threshold[i].colour, stroke: "transparent", strokeY1: "transparent", isDigitalLine: true});
      bandSeries.dataSeries = dataSeries;
      sciChartSurface.renderableSeries.add(bandSeries);
  }

  const line =  new HorizontalLine({
    labelPlacement: ELabelPlacement.TopLeft ,
    showLabel: true,
    stroke: 'rgba(50,53,67,1)',
    strokeThickness: 4,
    y1: props.volume,
    x1: 0,
    axisLabelFill: 'rgba(50,53,67,1)',
    isEditable: true,
    onDragEnded: (()=> {
      props.updateVolume(line.y1)
     })
    })
    sciChartSurface.annotations.add(line)

    return {sciChartSurface, wasmContext};
}
  
  return (
    <div> 
        <div id="scichart-root" style={{ height: '100%', marginBottom:'5px'}}></div>
    </div>

  )
}
