import PanelBarNavContainer from "./Layout"
import Layout from "./Layout"


export const Home = () => {
    return (
      <div>
         <PanelBarNavContainer
 />
      </div>
    )
   }