import { ReactElement} from "react";
import {TabStrip,TabStripSelectEventArguments,TabStripTab,} from '@progress/kendo-react-layout';
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../../messages/en-US";
import React from "react";
import { EventFilter } from "../../../Events/Utilities/EventsApiService";

interface Props {
  selectedItem: string
  eventFilter: EventFilter
}

export default function LocationDetailsPage(props: Props): ReactElement {
  const localizationService = useLocalization();

//TabStrip
const [selected, setSelected] = React.useState<number>(0);

const handleSelect = (e: TabStripSelectEventArguments) => {
  setSelected(e.selected);
};

  return (
        <div className="tabstrip h-full">
          {/* <TabStrip selected={selected} onSelect={handleSelect} className="h-full">
            <TabStripTab title='Activity'> */}
            <div className="gb-text-normal  text-lg text-center"
              style={{width: '400px', borderRadius: '3px', padding: '40px 25px',margin: '30px auto'}}>
              <div className="text-center mb-5">
                <span className="k-icon k-i-graph k-icon-70"></span>
              </div>
              <div>
                <span>{`${localizationService.toLanguageString('custom.selectPointEndpoint', enMessages.custom.selectPointEndpoint)}`}</span>
              </div>
            </div> 
            {/* </TabStripTab>
          </TabStrip>b */}
        </div>
  )
}
