import {
  SciChartPieSurface,
  EPieType,
  SciChartJSLightTheme,
  PieSegment,
  } from "scichart";
import { SciChartReact } from "scichart-react";
import { enMessages } from "../../../messages/en-US";

interface Props {
  chartData: any
}

export const ManualComplianceChart = (props: Props) => (
  <div className="flex">
    <SciChartReact
      initChart={async rootElement => {
        const theme = {...new SciChartJSLightTheme()}
        theme.sciChartBackground = 'transparent';

        const sciChartPieSurface = await SciChartPieSurface.create(rootElement, {
            theme: theme,
            pieType: EPieType.Donut,
            holeRadius: 0.75,
        });

        const compliantSegment = new PieSegment({
            color: "#1ab394",
            value: props.chartData.manualComplianceData.compliantCount === 0 ? 0.0001 : props.chartData.manualComplianceData.compliantCount,
            text: props.chartData.localizationService.toLanguageString('custom.compliant', enMessages.custom.compliant),
            labelStyle: {color: "black"}
        });
        compliantSegment.labelProvider.formatLabel = (segment) => segment.toFixed(0) + "%";

        const nonCompliantSegment = new PieSegment({
            color: "#ff5034",
            value: props.chartData.manualComplianceData.nonCompliantCount === 0 ? 0.0001 : props.chartData.manualComplianceData.nonCompliantCount,
            text: props.chartData.localizationService.toLanguageString('custom.nonCompliant', enMessages.custom.nonCompliant),
            labelStyle: {color: "black"}
        });
        nonCompliantSegment.labelProvider.formatLabel = (segment) => segment.toFixed(0) + "%";

        const pendingSegment = new PieSegment({
            color: "#d3d3d3",
            value: props.chartData.manualComplianceData.pendingCount === 0 ? 0.0001 : props.chartData.manualComplianceData.pendingCount,
            text: props.chartData.localizationService.toLanguageString('custom.pending', enMessages.custom.pending),
            labelStyle: {color: "black"}
        });
        pendingSegment.labelProvider.formatLabel = (segment) => segment.toFixed(0) + "%";

        // Set this to adjust the radial position of the labels
        // When positioning outside the pie, you may want to make further fine adjustments to label positions using the labelOffset as shown below
        sciChartPieSurface.labelRadiusAdjustment = 1.35;

        sciChartPieSurface.pieSegments.add(compliantSegment, nonCompliantSegment, pendingSegment);

        sciChartPieSurface.legend.placementDivId = "legendManualDiv";

        return { sciChartSurface: sciChartPieSurface };
      }}
      style={{
        aspectRatio: 1,
        height: "250px",
        width: "100%"
      }}
    />
    <div id="legendManualDiv"></div>
  </div>
);