import { useMemo } from "react";
import RouteTable from "../Components/RouteTable";
import { useQuery, useQueryClient } from "react-query";
import { ReactQueryKeys } from "../../../Constants/ClientRoutingConstants";
import { useToast } from "@chakra-ui/react";
import pointsApiService from "../Utilities/PointsApiService";
import machinesApiService from "../../Machines/Utilities/MachinesApiService";
import areasApiService from "../../Area/Utilities/AreasApiService";
import tasksApiService from "../../Tasks/Utilities/TasksApiService";
import consumablesApiService from "../../Consumables/Utilities/ConsumablesApiService";
import scheduleApiService from "../../Schedules/Utilities/ScheduleApiService";
import ErrorCard from "../../Shared/Components/Cards/ErrorCard";
import LoadingSpinner from "../../Shared/Components/LoadingSpinner";
import AreaReadDto from "../../../Models/Area/AreaReadDto";
import { PointReadDto } from "../../../Models/Point/PointReadDto";
import { MachineReadDto } from "../../../Models/Machine/MachinesReadDto";
import { ConsumableReadDto } from "../../../Models/Consumable/ConsumableReadDto";
import { TaskReadDto } from "../../../Models/Task/TaskReadDto";
import { ScheduleReadDto } from "../../../Models/Schedule/ScheduleReadDto";
import { PointReadTableDto } from "../../../Models/Point/PointReadTableDto";
import { PointUpdateRouteOrderDto } from "../../../Models/Point/PointUpdateRouteOrderDto";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";
import { useWindowSize } from "usehooks-ts";
import { Dialog } from "@progress/kendo-react-dialogs";

interface Props {
  isOpenDialog: any;
  setIsOpenDialog: any;
}

export default function RouteEditorPage(props: Props) {

  const localizationService = useLocalization();
  //get window size
  const { width, height } = useWindowSize()
 
  const client = useQueryClient();
  const toast = useToast({
    position: "bottom",
    duration: 5000,
    isClosable: true,
  });

  const pointsQuery = useQuery<Array<PointReadDto>>(ReactQueryKeys.AllPointsQuery, async () => {
    return pointsApiService.getAllPoints();
  });

  const schedulesQuery = useQuery<Array<ScheduleReadDto>>(ReactQueryKeys.AllSchedulesQuery, async () => {
    return scheduleApiService.getAllSchedules();
  });

  const tasksQuery = useQuery<Array<TaskReadDto>>(ReactQueryKeys.AllTasksQuery, async () => {
    return tasksApiService.getAllTasks();
  });

  const machinesQuery = useQuery<Array<MachineReadDto>>(ReactQueryKeys.AllMachinesQuery, async () => {
    return machinesApiService.getAllMachines();
  });

  const areasQuery = useQuery<Array<AreaReadDto>>(ReactQueryKeys.AllAreasQuery, async () => {
    return areasApiService.getAllAreas();
  });

  const consumablesQuery = useQuery<Array<ConsumableReadDto>>(ReactQueryKeys.AllConsumablesQuery, async () => {
    return consumablesApiService.getAllConsumables();
  });

  //update Query
  const updatePoints = async (updatedPoints: Array<PointReadTableDto>) => {

    try {
      //Cast the values into the structure expected by the api
      let points: Array<PointUpdateRouteOrderDto> = updatedPoints.map((x) => {
        return {
          id: x.id,
          routeOrder: x.routeOrder
        };
      });

      let response;
      response = await pointsApiService.updateRouteOrder(points);

      //If update is successful, refetch the updated list of areas and show success message
      client.refetchQueries(ReactQueryKeys.AllPointsQuery);
      client.refetchQueries(ReactQueryKeys.AllTasksQuery);
      toast({
        status: "success",
        description: `${localizationService.toLanguageString('custom.routeUpdated', enMessages.custom.routeUpdated)}`,
      });
      return true;
    } catch (e: any) {
      var errorMessage = `${localizationService.toLanguageString('custom.routeUpdatedError', enMessages.custom.routeUpdatedError)}`;
      toast({
        status: "error",
        description: errorMessage,
      });
      return false;
    }
  };

  //Collate and flatten data required for points table
  const pointData = useMemo(() => {
    //Create hashmap of id -> name pairs for foreign references.
    const areaIdtoName: { [id: string]: string } = {};
    const machineIdToName: { [id: string]: string } = {};
    const scheduleIdToName: { [id: string]: string } = {};
    const consumableIdToName: { [id: string]: string } = {};
    const pointIdToTask: { [id: string]: TaskReadDto } = {};
    areasQuery.data?.forEach((area) => {
      areaIdtoName[area.id] = area.name;
    });
    machinesQuery.data?.forEach((machine) => {
      machineIdToName[machine.id] = machine.name;
    });
    schedulesQuery.data?.forEach((schedule) => {
      scheduleIdToName[schedule.id] = schedule.name;
    });
    consumablesQuery.data?.forEach((consumable) => {
      consumableIdToName[consumable.id] = consumable.name;
    });
    tasksQuery.data?.forEach((task) => {
      pointIdToTask[task.point.id] = task;
    });

    //Construct Point Read Table dtos from data
    return pointsQuery.data?.map((point) => {
      return {
        ...point,
        scheduleId: pointIdToTask[point.id]?.scheduleId,
        scheduleName: scheduleIdToName[pointIdToTask[point.id]?.scheduleId],
        areaName: areaIdtoName[point.areaId],
        consumableName: consumableIdToName[point.consumableId],
        machineName: machineIdToName[point.machineId],
        initialRouteOrder: point.routeOrder
      } as PointReadTableDto;
    });
  }, [
    tasksQuery.data,
    pointsQuery.data,
    machinesQuery.data,
    areasQuery.data,
    schedulesQuery.data,
    consumablesQuery.data,
  ]);

  if (
    machinesQuery.isLoading ||
    areasQuery.isLoading ||
    pointsQuery.isLoading ||
    consumablesQuery.isLoading ||
    schedulesQuery.isLoading ||
    tasksQuery.isLoading ||
    machinesQuery.isFetching ||
    areasQuery.isFetching ||
    pointsQuery.isFetching ||
    consumablesQuery.isFetching ||
    schedulesQuery.isFetching ||
    tasksQuery.isFetching
  ) {
    return <LoadingSpinner />;
  } else {
    if (pointsQuery.error || machinesQuery.error || areasQuery.error || consumablesQuery.error || schedulesQuery.error || tasksQuery.error) {
      return <ErrorCard />;
    } else {
      return (
        <div>
          {/* Route Order Table */}
          <div className="mt-4">
            <RouteTable
              points={pointData!}
              updatePoints={updatePoints}
              height={690}
              />
          </div>
          {props.isOpenDialog.routeEditor && (
            <Dialog
              title={localizationService.toLanguageString('custom.routeEditor', enMessages.custom.routeEditor)}
              onClose={() => props.setIsOpenDialog({...props.isOpenDialog, routeEditor: false})}
            >
              <RouteTable
                points={pointData!}
                updatePoints={updatePoints}
                height={height - 200}
              />
            </Dialog>
          )}
        </div>
  );

    }
  }
}
