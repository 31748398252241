import { SciChartReact } from "scichart-react";
import { useLocalization } from "@progress/kendo-react-intl";
import React, { useContext, useRef, useState } from "react";
import { AppContext } from "../../../../../AppContext";
import { ChartOptions, Props, getChartAPI } from "./StatusChartApi";
import { getCurrentLanguage } from "../../../../Shared/SciChart/ChartConfiguration";
import useChartStore from "../../../../../Zustand/chartStore";

export const StatusChart = (props: Props) => {
  const localizationService = useLocalization();
  const {localeId} = useContext<any>(AppContext);
  const currentLanguage = getCurrentLanguage(localeId);
  const setVisibleSeriesChartStore = useChartStore(state => state.setVisibleSeriesStatus);

  // Fetch the initial state, but we don't need to re-render when it changes as the chart will update itself
  const visibleSeriesRef = useRef(useChartStore.getState().visibleSeriesStatus);

  const setVisibleSeries = (id: string, isVisible: boolean) => {
    setVisibleSeriesChartStore(id, isVisible);
  };

  var chartOptions : ChartOptions = {setVisibleSeriesStatus: setVisibleSeries, visibleRange: props.chartData.visibleRange, visibleSeriesStatus: visibleSeriesRef.current };


  const [statusChartAPI] = useState(getChartAPI(props, chartOptions, localizationService, currentLanguage));

  return (
    <div className="flex">
      <SciChartReact
        onInit={statusChartAPI.configAfterInit}
        initChart={statusChartAPI.initChart}
        style={{aspectRatio: 1, width:"100%", height: "65px"}}
        // Create a fallback component with an empty div to prevent a black background initially appearing. 
        fallback={<div/>}
      />
    </div>
  );
}
