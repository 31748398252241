import { FieldWrapper } from '@progress/kendo-react-form';
import { InputPrefix, InputSuffix, NumericTextBox, NumericTextBoxChangeEvent, Switch } from '@progress/kendo-react-inputs';
import { useEffect, useState } from 'react';
import MyItemRender from './myItemRender';
import { ListView, ListViewItemProps } from '@progress/kendo-react-listview';
import { Button } from '@progress/kendo-react-buttons';
import ThresholdChart from '../ThresholdChart';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import React from 'react';
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from '../../../../messages/en-US';

export default function RightAmountComponent(props)
{
  const localizationService = useLocalization();

    const [cardData, setCardData] = useState<any>(props.value && props.value.thresholds !== undefined ? props.value.thresholds : [])
    const [cardDataAutoOff, setCardDataAutoOff] = useState<any>(props.value && props.value.thresholds !== undefined ? props.value.thresholds : [])
    const [volumeRequirement, setVolumeRequirement] = useState<any>(props.value ? props.value.volumeRequirement : 2.0);
    const [autoThresholds, setAutoThresholds] = useState<boolean>(props.value && props.value.autoThresholds !== undefined ? props.value.autoThresholds : true);
    const [autoThresholdPercentage, setAutoThresholdPercentage] = useState<number>(props.value ? props.value.autoThresholdPercentage : 20.0);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    
    const complianceThresholdIdInitial = () => 
      props.value && props.value.complianceThresholdId !== undefined && cardData.find(x => x.id === props.value.complianceThresholdId) !== undefined? {value: cardData.find(x => x.id === props.value.complianceThresholdId)}: {
        value: cardData[0]
      }
    const [complianceThresholdId, setComplianceThresholdId] = useState<any>(complianceThresholdIdInitial());

    useEffect(() => {
      setComplianceThresholdId(complianceThresholdIdInitial())
    },[cardData.length])

    const suffix = () => (<InputSuffix>cc&nbsp;&nbsp;</InputSuffix>);
    const prefix = () => (<InputPrefix>&nbsp;&nbsp;{'±'}</InputPrefix>);

    useEffect(() => {

        const rightAmount = {volumeRequirement: volumeRequirement, autoThresholds: autoThresholds, autoThresholdPercentage: autoThresholdPercentage, thresholds: cardData, complianceThresholdId: complianceThresholdId.value.id};
        props.onChange({ value: rightAmount});

    },[volumeRequirement, autoThresholds, autoThresholdPercentage, cardData, complianceThresholdId]);

    const onVolumeChange = (item: any) => {
        setVolumeRequirement(item)
      };

    const VolumeRequirementInput = () => {
        return (
            <NumericTextBox
                key={"volumeRequirementInput"}
                label={""}
                width={151}
                value={volumeRequirement}
                onChange={(event:NumericTextBoxChangeEvent) => {
                  setTimeout(() => {
                    setVolumeRequirement(event.value)
                  }, 350)}}
                format={"n2"}
                min={0.1}
                step={0.1}
                suffix={suffix}
            />
        );
    };

    useEffect(() => {
      if (autoThresholds === true) {
        if (volumeRequirement < cardData[0].value && volumeRequirement > cardData[cardData.length - 2].value) {
          const newData = cardData.map((item) => {
            let value = item.value;
    
            if (item.value > volumeRequirement) {
              value = volumeRequirement * (1 + autoThresholdPercentage);
            }
            if (item.value <= volumeRequirement && item.value > 0) {
              value = volumeRequirement * (1 - autoThresholdPercentage);
            }
    
            return { ...item, value };
          });
          setCardData(newData);
        } else if(volumeRequirement > cardData[0].value || volumeRequirement < cardData[cardData.length - 2].value){
          const newData = [...cardData];
          newData[0].value = volumeRequirement * (1 + autoThresholdPercentage);
          cardData[cardData.length - 2].value = volumeRequirement * (1 - autoThresholdPercentage);
          setCardData(newData);
        }
      } else {
        const newData = [...cardDataAutoOff];
        setCardData(newData);
        setCardDataAutoOff(newData);
      }
    }, [autoThresholds, autoThresholdPercentage, volumeRequirement]);
    


    const onThresholdChange = (event:any) => {
        setAutoThresholds(event.target.value);
    };

    const AutoThresholdInput = () => {

        return (
          <Switch
            size={"small"}
            trackRounded={"medium"}
            thumbRounded={"medium"}
            checked={autoThresholds}
            onChange={onThresholdChange}
          />
        );
    };

    const PercentageInput = () => {

        return (
            <NumericTextBox
                width={100}
                format="p"
                value={autoThresholdPercentage}
                min={0}
                max={1}
                step={0.01}
                onChange={(event) => setAutoThresholdPercentage(event.target.value ? event.target.value : 0.2)}
                disabled={!autoThresholds}
                prefix={prefix}
            />
        );
    };

    const updateCard = (props: any) => {
        setCardData([...props])
      };
      
      const addCard = (e: { preventDefault: () => void; }) =>{
        e.preventDefault();
        const newData = [...cardData];
     
        const lastId = newData[0].id
        const lastValue = newData[0].value > volumeRequirement ? newData[0].value : volumeRequirement
        const newaddCard =  {
            id: lastId + 1,
            name: "",
            value: (lastValue * (1 + autoThresholdPercentage)),
            colour: "#FF5034",
            unit: 'cc'
          };
       
        setCardData([...newData , newaddCard])
      } 
      
      const MyCustomItem = (props: ListViewItemProps) => {
        return(
        <MyItemRender
          {...props}
          updateCard={updateCard}
          cardData={cardData}
          checked={autoThresholds}
          volumeRequirement={volumeRequirement}
          percentage={autoThresholdPercentage}
          isVisible={isVisible}
        />
        
      )};
      
      const ThresholdListInput = () => {
    
        return (
          <ListView
          style={{height:"100%",}}
          data={cardData.sort((a: { value: number; },b: { value: number; }) => b.value - a.value)}
          item={MyCustomItem}
            />
        );
      };

      const handlSelectCompliantChange = (event: DropDownListChangeEvent) => {
        setComplianceThresholdId({value: event.value});
      };

    const SelectCompliant = () => {
      
      return(
      <DropDownList
      style={{width:'140px'}}
      data={cardData}
      textField="name"
      dataItemKey="id"
      onChange={handlSelectCompliantChange}
      value={complianceThresholdId.value}
      />
    )}

    return (
        <FieldWrapper>
            <div>
                <div style={{display:'flex', alignItems:'baseline', gap:'5px', margin:'20px 0'}}>
                    <div style={{display:'flex', alignItems:'baseline', gap:'5px'}}>
                        <label className=''>{localizationService.toLanguageString('custom.volumeRequirement', enMessages.custom.volumeRequirement)}:</label>
                        {VolumeRequirementInput()}
                    </div>

                    <div style={{display:'flex', alignItems:'center', gap:'5px', margin:'0 10px'}}>
                        <label className=''>{localizationService.toLanguageString('custom.autoThreshold', enMessages.custom.autoThreshold)}:</label>
                        <AutoThresholdInput />
                        <PercentageInput />
                    </div>
                    {isVisible && (
                       <div style={{display:'flex', alignItems:'center', gap:'5px'}}>
                       <label className=''>Compliance Threshold:</label>
                       <SelectCompliant />
                   </div>
                    )}
                   
                </div>
            </div>

            <div className='' style={{display:"grid", gridTemplateColumns:'2fr 1fr', gap:'10px'}}>
                <div style={{textAlign:'center'}}>
                    <div style={{ maxHeight:'400px'}}>
                    {isVisible && (
                        <Button onClick={addCard} style={{marginBottom:'10px', width:"100%"}} size={'small'} themeColor={"base"} disabled={autoThresholds} icon='add'>Add Threshold</Button>
                    )}
                        <ThresholdListInput />
                    </div>
                </div>
                <ThresholdChart threshold={cardData} volume={volumeRequirement} updateChart ={updateCard} updateVolume={onVolumeChange} checked={autoThresholds}/>
            </div>
        </FieldWrapper>
    );
}