import { useLocalization } from "@progress/kendo-react-intl";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FrequencyTypes } from "../../../../Constants/SchedulerConstants";
import SelectedWeeks from "../../../../Models/Requirement/SelectedWeeks";
import { FieldWrapper } from '@progress/kendo-react-form';
import { ComboBox, ComboBoxFilterChangeEvent, DropDownList } from "@progress/kendo-react-dropdowns";
import { FrequencyStringToDisplayName, getValidStartDateForFrequency, rRuleGenerator, rruleFrequencyToTextRRule } from "../../Utilities/RequirementUtilities";
import { enMessages } from "../../../../messages/en-US";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import DayPickerMonth2 from "../../../Shared/Components/Forms/DayPickerMonth2";
import { Calendar, CalendarProps, DatePicker } from "@progress/kendo-react-dateinputs";
import { timezoneToComboboxItem, timezonesToComboboxItems } from "../../../Area/Utilities/AreaUtilities";
import { Weekday, rrulestr } from "rrule";
import DayPickerWeek2 from "../../../Shared/Components/Forms/DayPickerWeek2";
import { Error } from "@progress/kendo-react-labels";
import { FilterDescriptor, filterBy } from "@progress/kendo-data-query";

export default function RecurrenceRuleInput(props)
{
    const { validationMessage, visited} = props;
    const [interval, setInterval] = React.useState<number | undefined>(1);
    const [timezone, setTimezone] = useState<string>(props.value !== undefined && props.value.timezone !== "" ? props.value.timezone : moment.tz.guess());
    const [startDate, setStartDate] = React.useState<Date | null>(props.value !== undefined && props.value.startDate != null ? new Date(new Date(props.value?.startDate!).setHours(0,0,0,0)) : new Date(new Date().setHours(0,0,0,0)));
    const [endDate, setEndDate] = React.useState<Date | null>(props.value !== undefined && props.value.endDate != null ? new Date(new Date(props.value?.endDate!).setHours(23,59,59,59)) : null);

    const [selectedWeeks, setSelectedWeeks] = useState<SelectedWeeks>(
    {week1Selected:[], week2Selected: [], week3Selected: [], week4Selected: []});

    const localizationService = useLocalization();
    const [frequency, setFrequency] = useState<string>(FrequencyTypes.daily);
    const [showSelectionErrorMessage, setShowSelectionErrorMessage] = useState<boolean>(false);
    const [daysSelected, setDaysSelected] = useState<Array<number>>([]);

    // Disable Fast Navigational Bar until this critical bug is fixed by Telerik (https://github.com/telerik/kendo-react/issues/1566)
    const CustomCalendar = (props: CalendarProps) => {
      return <Calendar {...props} navigation={false} />
    };

    useEffect(() => {
        if(props.value !== undefined) {
            const rRuleAsString = props.value.rRule.split('RRULE:')[1];
            const rRule = rrulestr(rRuleAsString);
            setFrequency(rruleFrequencyToTextRRule(rRuleAsString));
            setInterval(rRule.origOptions.interval)

            if (rRule.origOptions.byweekday !== undefined || rRule.origOptions.byweekday != null) {

                var weekDays = rRule.origOptions.byweekday as Array<Weekday>;

                setDaysSelected(weekDays.filter(weekDay => weekDay.n === undefined).map(weekDays => weekDays.getJsWeekday()));
                var week1SelectedDays = weekDays.filter(weekDay => weekDay.n === 1).map(weekDay => weekDay.getJsWeekday());
                var week2SelectedDays = weekDays.filter(weekDay => weekDay.n === 2).map(weekDay => weekDay.getJsWeekday());
                var week3SelectedDays = weekDays.filter(weekDay => weekDay.n === 3).map(weekDay => weekDay.getJsWeekday());
                var week4SelectedDays = weekDays.filter(weekDay => weekDay.n === 4).map(weekDay => weekDay.getJsWeekday());   

                setSelectedWeeks({
                    week1Selected: week1SelectedDays, 
                    week2Selected: week2SelectedDays, 
                    week3Selected: week3SelectedDays, 
                    week4Selected: week4SelectedDays
                });
            }
        }
    },[]);

    useEffect(() => {
        let recurrence = 1
        if(interval !== undefined) {
        recurrence = interval
        }

        const rRule = rRuleGenerator(
        recurrence,
            startDate,
            endDate,
            frequency,
            daysSelected,
            selectedWeeks.week1Selected,
            selectedWeeks.week2Selected,
            selectedWeeks.week3Selected,
            selectedWeeks.week4Selected
        );

        const validDate = getValidStartDateForFrequency(startDate, frequency);
        const recurrenceRule = {rRule: rRule.toString(), startDate: validDate, endDate: endDate, timezone: timezone};
        props.onChange({ value: recurrenceRule});

    },[frequency, interval, daysSelected, selectedWeeks, startDate, endDate, timezone]);


const onRecurrenceChange = (event) => {
  setInterval(event.value);
}

  const FrequencyInput = (fieldRenderProps) => {

    return (

        <div className="py-2">
        <label htmlFor="frequency">{localizationService.toLanguageString('custom.frequency', enMessages.custom.frequency)}</label>
       
      <DropDownList
      style={{ height: "30px" }}
      textField="displayName"
      defaultValue={{
        value: frequency,
        displayName: FrequencyStringToDisplayName(frequency),
      }}
      onChange={(event) => setFrequency(event.target.value.value)}
      data={[
        {
          displayName: `${localizationService.toLanguageString('custom.daily', enMessages.custom.daily)}`,
          value: "RRule.DAILY",
        },
        {
          displayName: `${localizationService.toLanguageString('custom.weekly', enMessages.custom.weekly)}`,
          value: "RRule.WEEKLY",
        },
      ]}
    />
    
    </div>

    );
};

const TimezoneInput = () => {
  const [data, setData] = React.useState(timezonesToComboboxItems().slice());
  const filterData = (filter: FilterDescriptor) => {
    const data = timezonesToComboboxItems().slice();
    return filterBy(data, filter);
  };

  const filterChange = (event: ComboBoxFilterChangeEvent) => {
    setData(filterData(event.filter));
  };

    return (
            <div className="py-2 w-full">
            <label htmlFor="timezone">{localizationService.toLanguageString('custom.timezone', enMessages.custom.timezone)}</label>
        
            <ComboBox
                data={data}
                textField="displayName"
                name="timezone"
                className="form-field"
                placeholder={localizationService.toLanguageString('custom.timezone', enMessages.custom.timezone)}
                defaultValue={timezoneToComboboxItem(timezone)}
                onChange={(event) => {
                  event.value === null ? setTimezone('') :
                  setTimezone(event.value.value)}}
                filterable={true}
                onFilterChange={filterChange}
                required={true}
            />

            
            {visited && validationMessage && validationMessage === 'Timezone is required' &&<Error>{validationMessage}</Error>}
        </div>
    );
};

const StartDateInput = () => {

    return (
        <div className="py-2 w-full">
        <label htmlFor="startDate">{localizationService.toLanguageString('custom.startDate', enMessages.custom.startDate)}</label>
    
        <DatePicker
            onChange={(event) => {setStartDate(event.target.value)}}
            defaultValue={startDate}
            value={startDate}
            calendar={CustomCalendar}
        />
      </div>
    );
  };

  const EndDateInput = () => {
    return (
        <div className="py-2 w-full">
        <label htmlFor="endDate">{localizationService.toLanguageString('custom.endDate', enMessages.custom.endDate)}</label>
      
      <DatePicker
        onChange={(event) =>{setEndDate(event.target.value)}}
        defaultValue={props.value !== undefined && props.value.endDate != null ? new Date(props.value?.endDate!) : null}
        placeholder={localizationService.toLanguageString('custom.endDate', enMessages.custom.endDate)}
        min={startDate !== null ? startDate : new Date()}
        value={endDate}
        calendar={CustomCalendar}
      />
      </div>
    );
  };

  const MonthlyRecurrenceInput = () => {
    return (
    <div className="py-2">
    <label htmlFor="weeklyRecurrence">{localizationService.toLanguageString('custom.monthlyRecurrence', enMessages.custom.monthlyRecurrence)}</label>
    <DayPickerMonth2 selectedWeeks={selectedWeeks} onHandleChange={setSelectedWeeks} />

    {showSelectionErrorMessage && (
        <div className="text-xs text-red-600">* {localizationService.toLanguageString('custom.mustSelectMonth', enMessages.custom.mustSelectMonth)}</div>
    )}
    <label htmlFor="recurrenceInterval">{localizationService.toLanguageString('custom.recurrenceInterval', enMessages.custom.recurrenceInterval)}</label>
    <NumericTextBox
       onChange={(onRecurrenceChange)}
       defaultValue={interval}
       value={interval}
       min={1}
       name="recurrence"
       className="k-input k-input-md k-rounded-md k-input-solid h-8"
        placeholder={localizationService.toLanguageString('custom.recurranceXMonths', enMessages.custom.recurranceXMonths)} />
    </div>
    );
    }

    const WeeklyRecurrenceInput = () => {
        return (
            <div className="py-2">
            <label htmlFor="weeklyRecurrence">{localizationService.toLanguageString('custom.weeklyRecurrenceInterval', enMessages.custom.weeklyRecurrenceInterval)}</label>
            <DayPickerWeek2 daysSelected={daysSelected} setDaysSelected={setDaysSelected}/>
            
            {showSelectionErrorMessage && (
              <div className="text-xs text-red-600">* {localizationService.toLanguageString('custom.mustSelectWeek', enMessages.custom.mustSelectWeek)}</div>
            )}
            <label htmlFor="recurrenceInterval">{localizationService.toLanguageString('custom.recurrenceInterval', enMessages.custom.recurrenceInterval)}</label>
             <NumericTextBox
             onChange={(onRecurrenceChange)}
             defaultValue={interval}
             value={interval}
             min={1}
             name="recurrence"
             className="k-input k-input-md k-rounded-md k-input-solid h-8"
              placeholder={localizationService.toLanguageString('custom.recurranceXWeeks', enMessages.custom.recurranceXWeeks)}
            />
          </div>
        );
        }

        const DailyRecurrenceInput = () => {
            return (
                <div className="py-2">
            <label htmlFor="dailyRecurrence">{localizationService.toLanguageString('custom.dailyRecurrenceInterval', enMessages.custom.dailyRecurrenceInterval)}</label>
            <NumericTextBox
                onChange={(onRecurrenceChange)}
                defaultValue={interval}
                value={interval}
                min={1}
                name="recurrence"
                className="k-input k-input-md k-rounded-md k-input-solid h-8"
                placeholder={localizationService.toLanguageString('custom.recurranceXDays', enMessages.custom.recurranceXDays)}
            />
            </div>       
            );
            }

  return (
    <FieldWrapper>
    
        <FrequencyInput />

        {frequency === FrequencyTypes.daily && (
            DailyRecurrenceInput()
        )}

        {frequency === FrequencyTypes.weekly && (
             WeeklyRecurrenceInput()
        )}

        {frequency === FrequencyTypes.monthly && (
            MonthlyRecurrenceInput()
        )}

        {TimezoneInput()}
        {StartDateInput()}
        {EndDateInput()}

        {visited && validationMessage && validationMessage === 'End Date must be greater than Start Date' && <Error>{validationMessage}</Error>}
       
    </FieldWrapper>
  );
}