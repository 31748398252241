import { title } from "process";
import { AUTO_COLOR, DefaultPaletteProvider, EFillPaletteMode, EStrokePaletteMode, IFillPaletteProvider, IPointMarkerPaletteProvider, IPointMetadata, IRenderableSeries, IStrokePaletteProvider, parseColorToUIntArgb, TPointMarkerArgb } from "scichart";

const getColor = (yValue: number) => {
    if (yValue == 0) {
        return "rgba(255, 99, 88,1)";
    } else {
      return "rgba(50, 53, 67,0)"
    }
};

class ColumnPaletteProvider implements IStrokePaletteProvider, IFillPaletteProvider, IPointMarkerPaletteProvider {
    public readonly strokePaletteMode = EStrokePaletteMode.SOLID;
    public readonly fillPaletteMode = EFillPaletteMode.SOLID;
    private readonly orange = parseColorToUIntArgb("rgba(255, 99, 88,1)")
    private color = "rgba(50, 53, 67,1)";

    // Add constructor to set alpha
    constructor(color: string) {
        this.color = color;
    }

    // tslint:disable-next-line:no-empty
    public onAttached(parentSeries: IRenderableSeries): void {}
    // tslint:disable-next-line:no-empty
    public onDetached(): void {}
    public overrideFillArgb(
        xValue: number,
        yValue: number,
        index: number,
        opacity?: number,
        metadata?: IPointMetadata
    ): number {
        if (yValue == 0) {
            return this.orange;
        } else if(yValue > 0){
            return parseColorToUIntArgb(this.color)
        } else {
            return parseColorToUIntArgb(this.color)
        }
    }
    public overrideStrokeArgb(
        xValue: number,
        yValue: number,
        index: number,
        opacity?: number,
        metadata?: IPointMetadata
    ): number {
        if (yValue == 0) {
            return this.orange;
        } else if(yValue > 0){
            return parseColorToUIntArgb(this.color)
        } else {
            return parseColorToUIntArgb(this.color)
        }
    }

    public overridePointMarkerArgb(xValue: number, yValue: number, index: number, opacity?: number | undefined, metadata?: IPointMetadata | undefined): TPointMarkerArgb {
      const color = getColor(yValue);
      return { stroke: parseColorToUIntArgb(color), fill: parseColorToUIntArgb(color) };
      
    } 
  }

  const getColorPvA = (yValue: number) => {
    if (yValue == 0) {
        return "rgba(0, 0, 0, 0)";
    } else {
      return "rgba(50, 53, 67,0)"
    }
};

  class ColumnPaletteProviderPvA implements IStrokePaletteProvider, IFillPaletteProvider, IPointMarkerPaletteProvider {
    public readonly strokePaletteMode = EStrokePaletteMode.SOLID;
    public readonly fillPaletteMode = EFillPaletteMode.SOLID;
    private readonly orange = parseColorToUIntArgb("rgba(0, 0, 0, 0)")
    // tslint:disable-next-line:no-empty
    public onAttached(parentSeries: IRenderableSeries): void {}
    // tslint:disable-next-line:no-empty
    public onDetached(): void {}
    public overrideFillArgb(
        xValue: number,
        yValue: number,
        index: number,
        opacity?: number,
        metadata?: IPointMetadata
    ): number {
        if (yValue == 0) {
            return this.orange;
        } else if(yValue > 0){
            return parseColorToUIntArgb("rgba(50, 53, 67,1)")
        } else {
            return parseColorToUIntArgb("rgba(50, 53, 67,1)")
        }
    }
    public overrideStrokeArgb(
        xValue: number,
        yValue: number,
        index: number,
        opacity?: number,
        metadata?: IPointMetadata
    ): number {
        if (yValue == 0) {
            return this.orange;
        } else if(yValue > 0){
            return parseColorToUIntArgb("rgba(50, 53, 67,1)")
        } else {
            return parseColorToUIntArgb("rgba(50, 53, 67,1)")
        }
    }

    public overridePointMarkerArgb(xValue: number, yValue: number, index: number, opacity?: number | undefined, metadata?: IPointMetadata | undefined): TPointMarkerArgb {
      const color = getColorPvA(yValue);
      return { stroke: parseColorToUIntArgb(color), fill: parseColorToUIntArgb(color) };
      
    } 
  }

  class ColumnPaletteProviderPvAUnplanned  implements IStrokePaletteProvider, IFillPaletteProvider, IPointMarkerPaletteProvider {
    public readonly strokePaletteMode = EStrokePaletteMode.SOLID;
    public readonly fillPaletteMode = EFillPaletteMode.SOLID;
    private readonly orange = parseColorToUIntArgb("rgba(0, 0, 0, 0)")
    // tslint:disable-next-line:no-empty
    public onAttached(parentSeries: IRenderableSeries): void {}
    // tslint:disable-next-line:no-empty
    public onDetached(): void {}
    public overrideFillArgb(
        xValue: number,
        yValue: number,
        index: number,
        opacity?: number,
        metadata?: IPointMetadata
    ): number {
        if (yValue == 0) {
            return this.orange;
        } else if(yValue > 0){
            return parseColorToUIntArgb("rgba(169,169,169,1)")
        } else {
            return parseColorToUIntArgb("rgba(169,169,169,1)")
        }
    }
    public overrideStrokeArgb(
        xValue: number,
        yValue: number,
        index: number,
        opacity?: number,
        metadata?: IPointMetadata
    ): number {
        if (yValue == 0) {
            return this.orange;
        } else if(yValue > 0){
            return parseColorToUIntArgb("rgba(169,169,169,1)")
        } else {
            return parseColorToUIntArgb("rgba(169,169,169,1)")
        }
    }

    public overridePointMarkerArgb(xValue: number, yValue: number, index: number, opacity?: number | undefined, metadata?: IPointMetadata | undefined): TPointMarkerArgb {
      const color = getColorPvA(yValue);
      return { stroke: parseColorToUIntArgb(color), fill: parseColorToUIntArgb(color) };
      
    } 
  }

  class ColumnPaletteProviderActivityChartAsset implements IPointMarkerPaletteProvider {
    public readonly strokePaletteMode = EStrokePaletteMode.SOLID;
    public readonly fillPaletteMode = EFillPaletteMode.SOLID;
    
    // tslint:disable-next-line:no-empty
    public onAttached(parentSeries: IRenderableSeries): void {}
    // tslint:disable-next-line:no-empty
    public onDetached(): void {}
    
    public overridePointMarkerArgb(xValue: number, yValue: number, index: number, opacity?: number | undefined, metadata?: IPointMetadata | undefined): TPointMarkerArgb {
      const color = getColorPvA(yValue);
      return { stroke: parseColorToUIntArgb(color), fill: parseColorToUIntArgb(color) };
      
    } 
  }

    // PaletteProvider to use data from metadata to color the line segments
    class LinePaletteProvider extends DefaultPaletteProvider {
        constructor() {
        super();
        this.strokePaletteMode = EStrokePaletteMode.SOLID;
        }
        overrideStrokeArgb(xValue, yValue, index, opacity, metadata): any {
            if (metadata && metadata.color) {
                // if metadata.color exists, parse this from HTML Hex code to a UInt ARGB value
                // Note: for performance reasons its better to preconvert hex to UInt rather than do it on the fly here
                return parseColorToUIntArgb(metadata.color);
            } else {
                // Returning undefined means use default series stroke color
                return undefined;
            }
            
        }
    }

    const getColorNotifications = (type) => {
        const criticalColour = "rgba(120, 0, 0, 1)";
        const highColour = "rgba(220, 0, 0, 1)";
        const mediumColour = "rgba(253, 140, 0, 1)";
        const lowColour = "rgba(253, 197, 0, 1)";
        if (type.alert === "Critical") {
          return criticalColour;
        } else if(type.alert === "High") {
          return highColour;
        } else if(type.alert === "Medium") {
          return mediumColour;
        } else {
          return lowColour;
        }
    };
      // PaletteProvider implementing IStrokePaletteProvider
      class TriangleProvider extends DefaultPaletteProvider {
        constructor() {
            super();
            this.strokePaletteMode = EStrokePaletteMode.SOLID;
            this.fillPaletteMode = EFillPaletteMode.SOLID;
        }
        
        overridePointMarkerArgb(xValue, yValue, index, opacity, metadata): any {
          const color = getColorNotifications(metadata);
            if (metadata && color) {
                return {fill: parseColorToUIntArgb(color), stroke: parseColorToUIntArgb(color)};
            } else {
                return undefined;
            }
            
        }
      }



  export  {ColumnPaletteProvider, ColumnPaletteProviderPvA, ColumnPaletteProviderPvAUnplanned, ColumnPaletteProviderActivityChartAsset, TriangleProvider, LinePaletteProvider}


  