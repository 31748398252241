import { useToast } from "@chakra-ui/react";
import { Field, FieldProps, Form, Formik } from "formik";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button, Chip } from "@progress/kendo-react-buttons";
import { ComboBox, ComboBoxChangeEvent, DropDownList, MultiColumnComboBox, } from "@progress/kendo-react-dropdowns";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";
import ReservoirValidationSchema from "../../Reservoir/Utilities/ReservoirValidationSchema";

interface Props {
  onClose: () => void;
  OnLinkReservoir: (reservoidId: string) => void;
  reservoirs: any;
}

export default function LinkReservoirModal(props: Props) {
  const toast = useToast({
    position: "bottom",
    duration: 5000,
    isClosable: true,
  });
  const localizationService = useLocalization()

  const handleSubmit = async (values: any, setStatus: any) => {
    props.OnLinkReservoir(values.linkReservoir.id);
  };

  const initialValueGenerator = (update: boolean) => {
    return {
    }
  };
  
  
  const columns = [
    { field: "name", header: "Name", width: "300px" },
    { field: "description", header: "Description", width: "300px" },
  ];

  return (
      <div>
        <Dialog title="Link Reservoir" onClose={props.onClose}  >
        <Formik
            onSubmit={async (values, { setStatus }) => {
              await handleSubmit(values, setStatus);
            }}
            initialValues={initialValueGenerator(true)}
          >
            {({ submitForm, values, initialValues, setFieldValue }) => (
              <>
                <Form
                  style={{
                    minWidth: "500px",
                  }}
                >
                  <div className="p-2 w-full">
                    <label htmlFor="linkReservoir">Available Reservoirs</label>

                    <MultiColumnComboBox
                      data={props.reservoirs}
                      columns={columns}
                      textField={"name"}
                      placeholder="Please select ..."
                      onChange={(event: ComboBoxChangeEvent) => setFieldValue("linkReservoir", event.target.value)}
                    />
                  </div>
                </Form>
                <DialogActionsBar>
                  <Button
                    themeColor={"success"}
                    onClick={() => {
                      submitForm();
                    }}
                  >
                    {localizationService.toLanguageString('custom.save', enMessages.custom.save)}
                  </Button>
                  <Button onClick={props.onClose}>{localizationService.toLanguageString('custom.cancel', enMessages.custom.cancel)}</Button>
                </DialogActionsBar>
              </>
            )}
          </Formik>
           
   
        </Dialog>
    </div>
  )
}
