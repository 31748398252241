import { TaskCreateDto } from "./../../../Models/Task/TaskCreateDto";
import { TaskReadDto } from "./../../../Models/Task/TaskReadDto";
import { ApiRoutes } from "../../../Constants/ApiRoutingConstants";
import axios, { AxiosInstance } from "axios";
import authorizeService from "../../Identity/Utilities/AuthorizeService";

/**
 * This service provides implementations for http calls to the api pertaining to task crud functionality
 */

class TasksApiService {
  axiosInstance: AxiosInstance;
  constructor() {
    //Subscribe to identity store the get token
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_SCHEDULE_SERVICE_URL,
    });
  }

  async getAllTasks(): Promise<TaskReadDto[]> {
    let response = await this.axiosInstance({
      method: "GET",
      url: `${ApiRoutes.Tasks}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data;
  }

  async createTask(task: TaskCreateDto) {
    let response = await this.axiosInstance({
      method: "POST",
      url: `${ApiRoutes.Tasks}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: task,
    });
    return response.data as TaskReadDto;
  }

  async updateTasks(task: TaskCreateDto, taskId: string) {
    let response = await this.axiosInstance({
      method: "PUT",
      url: `${ApiRoutes.Tasks}/${task}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: task,
    });
    return response.data as TaskReadDto;
  }

  async deleteTasks(taskDeleteIds: Array<string>) {
    let response = await this.axiosInstance({
      method: "DELETE",
      url: `${ApiRoutes.Tasks}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: {
        taskIds: taskDeleteIds,
      },
    });
    return response.data;
  }

  static get instance() {
    return tasksApiService;
  }
}

const tasksApiService = new TasksApiService();
export default tasksApiService;
