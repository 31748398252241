import { useLocalization } from "@progress/kendo-react-intl";
import { useQuery } from "react-query";
import { ReactQueryKeys } from "../../../Constants/ClientRoutingConstants";
import notificationsApiService from "../Utilities/NotificationsApiService";
import LoadingSpinner from "../../Shared/Components/LoadingSpinner";
import ErrorCard from "../../Shared/Components/Cards/ErrorCard";
import NotificationsTable from "../Components/NotificationsTable";
import { NotificationReadTableDto } from "../../../Models/Notifications/NotificationReadTableDto";
import { enMessages } from "../../../messages/en-US";
import { useMemo } from "react";
import moment from "moment";

interface Props {}

export default function NotificationsPage(props: Props) {
  const localizationService = useLocalization();

  const notificationsQuery = useQuery<Array<NotificationReadTableDto>>(
    ReactQueryKeys.AllNotificationsQuery,
    async () => {
      return notificationsApiService.getAllNotifications();
    }
  );

  const notifications = useMemo(() => {
    return notificationsQuery.data?.map((notification) => {
      return {
        ...notification,
        createdFormatted: moment(notification.created)
          .local()
          .format("DD/MM/YYYY HH:mm:ss Z"),
      };
    });
  }, [notificationsQuery.data]);

  if (notificationsQuery.isLoading || notificationsQuery.isFetching) {
    return <LoadingSpinner />;
  } else if (notificationsQuery.isError) {
    return <ErrorCard />;
  } else {
    return (
      <div className="m-5">
        <h2 className="gb-h2 mb-5">
          {localizationService.toLanguageString(
            "custom.notifications",
            enMessages.custom.notifications
          )}
        </h2>
        <NotificationsTable notifications={notifications} />
      </div>
    );
  }
}
