/* eslint-disable no-mixed-operators */
/**
 * This component renders the GreaseCloud Side Navigation Bar. Styles for this component are specifically
 * defined in NavBar.module.scss in the Shared/Styles folder.
 *
 */
import { CSSProperties, ReactElement, useEffect, useState } from "react";
import useIdentityStore from "../../../Zustand/identityStore";
import { useNavigate, useLocation } from "react-router-dom";
import {PanelBar,PanelBarSelectEventArguments,PanelBarUtils} from "@progress/kendo-react-layout";
import useLayoutStore from "../../../Zustand/layoutStore";
import { Transition } from "react-transition-group";
import GenericConfirmationDialog from "../../Shared/Components/Dialogs/GenericConfirmationDialog";
import { useLocalization } from "@progress/kendo-react-intl";
import { enMessages } from "../../../messages/en-US";
import { GetSideBarPanelItems } from "./SideBarPanelItems";
import { distinct } from "@progress/kendo-data-query";
import { ClientRoutes } from "../../../Constants/ClientRoutingConstants";

//Divider function that renders the divider line between sections as neccessary.
export default function SideBar(): ReactElement {
  const localizationService = useLocalization();
  const isOpen = useLayoutStore((x) => x.isSidebarOpen);
  const isModifiedData = useLayoutStore((x) => x.isModifiedData);
  const setModifiedData = useLayoutStore((x) => x.setModifiedDataCounts);
  const user = useIdentityStore((store) => store.user);
  const [expanded, setExpanded] = useState<string[]>([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [confirmAccessPage, setConfirmAccessPage] = useState<string | null>(null);
  const [selected, setSelected] = useState<string>();
  var [panelItems, setPanelItems] = useState<any[]>([]);

  useEffect(() => {

    // Create the panel items based on the user role
    const panelItems = GetSideBarPanelItems(localizationService);

    var panelItemsForUserRole: any[] = [];

    if (user?.profile.role !== undefined) {

      for (let i = 0; i < panelItems.length; i++) {
        var panelItem = {...panelItems[i]};

        if (panelItem.role.length === 0 || panelItem.role.includes(user?.profile.role)) {
          var children: any[] = [];

          if (panelItem.children !== undefined) {

            for (let j = 0; j < panelItem.children.length; j++) {
              if (panelItem.children[j].role.length === 0 || panelItem.children[j].role.includes(user?.profile.role)) {
                children.push(panelItem.children[j]);
              }
            }

            panelItem.children = children;
          }
          else
          {
            panelItem.children = undefined;
          }
          panelItemsForUserRole.push(panelItem);
        }
      }
    }

    setPanelItems(PanelBarUtils.mapItemsToComponents(panelItemsForUserRole));

  }, [user?.profile.role, localizationService]);


  //Select the correct panel item when navigating to a page, for notifications page select nothing
  useEffect(() => {

    var route = location.pathname + location.hash;

    // Site explorer route can contain additional parameters, but we only need to check the base route
    if (route.includes("siteexplorer")) {
      route = ClientRoutes.SiteExplorerPage;
    }

    if (route.includes("notifications")) {
      setSelected("");
    } else {
      var selectedId = ""
      var expandedId: string[] = [...expanded];

      // Try to find the route in the top level panel items
      var child = panelItems.find((x: any) => x.props.route.includes(route));

      if (child !== undefined) {
        selectedId = child.props.id;
      } else {
        // Route was not found in the top level panel items, try to find it in the children
        for (let i = 0; i < panelItems.length && selectedId === ""; i++) {
      
          if (panelItems[i].props.children !== undefined) {
            child = panelItems[i].props.children.find((x: any) => x.props.route.includes(route));

            if (child !== undefined) {
              selectedId = child.props.id;
              expandedId.push(panelItems[i].props.id);
            } else {
              // Route was not found in the children, try to find it in the sub children
              for (let j = 0; j < panelItems[i].props.children.length && selectedId === ""; j++) {
                if (panelItems[i].props.children[j].props.children !== undefined) 
                {
                  var subChild = panelItems[i].props.children[j].props.children.find((x: any) => x.props.route.includes(route));

                  if (subChild !== undefined) {
                    selectedId = subChild.props.id;
                    expandedId.push(panelItems[i].props.id);
                    expandedId.push(panelItems[i].props.children[j].props.id);
                  }
                }
              }
            }
          }
        }
      }

      if (selectedId !== "")
      {
        setSelected(selectedId);
        setExpanded(distinct(expandedId));
        setModifiedData({});
      }
    }
  }, [location, panelItems]);

  

  const handlePanelSelect = (e: PanelBarSelectEventArguments) => {
    //If user is trying to leave page, show confirmation modal
    if (isModifiedData() && !e.target.key!.toString().includes("Heading")) {
      setConfirmAccessPage(e.target.props.route);
    }
    //If there is no unmodified data route user directly to page
    else {
      if (e.target.props.route !== "") {
        setModifiedData({});
        navigate(e.target.props.route);
      }
    }

    if (e.expandedItems) {
      setExpanded(e.expandedItems);
    }
  };

  const defaultStyle: CSSProperties = {
    transition: `transform 200ms ease`,
    transform: "translateX(0%)",
  };

  const transitionStyles = {
    entering: { transform: "translateX(0%)" } as CSSProperties,
    entered: { transform: "translateX(0%)" } as CSSProperties,
    exiting: { transform: "translateX(-100%)" } as CSSProperties,
    exited: { transform: "translateX(-100%)" } as CSSProperties,
  };


  /**
   * RENDER
   */
  return (
    
    <>
      <Transition in={isOpen} timeout={10}>
        {(state) => (
          <div
          className={`border-r-2 z-20 bg-white absolute h-full md:h-auto  md:sticky`}
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
            minWidth: "230px",
            }}
          >
            <PanelBar
              className="border-0 border-collapse"
              style={{ position: "sticky", top: "68px" }}
              isControlled={true}
              onSelect={handlePanelSelect}
              expanded={expanded}
              selected={selected}
              children={panelItems}
              animation={true}
            />
          </div>
        )}
      </Transition>
      {/* Are you sure you want to leave modal */}
      <GenericConfirmationDialog open={confirmAccessPage != null} onClose={function (): void { setConfirmAccessPage(null);}}
        onConfirm={function (): void {navigate(confirmAccessPage!);}}
        header={localizationService.toLanguageString("custom.confirmLeave",enMessages.custom.confirmLeave)}
        body={localizationService.toLanguageString("custom.unsavedChanges",enMessages.custom.unsavedChanges)}
        confirmButtonTheme="primary"
        cancel={localizationService.toLanguageString("custom.cancel", enMessages.custom.cancel)}
        confirm={localizationService.toLanguageString("custom.confirm",enMessages.custom.confirm
        )}
      />
    </>
  );
}

