import { Button } from "@progress/kendo-react-buttons";
import { Avatar, Card, CardActions, CardHeader, CardSubtitle, CardTitle, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import React, { ReactElement } from "react";
import { Icon } from "@progress/kendo-react-common";
import useIdentityStore from "../../../Zustand/identityStore";
import { useLocalization } from '@progress/kendo-react-intl';
import useBreakpoint from "use-breakpoint";
import { GRID_CONFIG } from "../../../Constants/SupportCentreConstants";
import { BREAKPOINTS } from "../../../Constants/SupportCentreConstants";
import { enMessages } from "../../../messages/en-US";

const getGridConfig = (breakpoint: string | number, GRID_CONFIG: { [x: string]: any; }) => GRID_CONFIG[breakpoint];

interface Props {}

export default function SupportPage({}: Props): ReactElement {
  const userProfile = useIdentityStore((store) => store.user?.profile);
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "desktop");

  const localizationService = useLocalization();

  const {outerGrid, mainContainer, supportCardContainer, supportCard, supportCardGap} = getGridConfig(breakpoint, GRID_CONFIG);

    const onGetSupportClick = () => {
        window.open("https://greaseboss.atlassian.net/servicedesk/customer/portal/1", "_blank", "noopener,noreferrer");
    };
    
    const onViewSupportDocumentationClick = () => {
        window.open("https://greaseboss.atlassian.net/wiki/x/goAD", "_blank", "noopener,noreferrer");
    };
    
    const onGetInTouchClick = () => {
         window.open(`mailto:support@greaseboss.com.au?Subject=${userProfile?.current_site_name} Support Request`, "_blank", "noopener,noreferrer");
    };
    
    const onViewVideosClick = () => {
        window.open("https://vimeo.com/showcase/greaseboss", "_blank", "noopener,noreferrer");
    };

    return (
        <div>
        <div className="m-5">
        <h1 className="gb-h1">{localizationService.toLanguageString('custom.supportCentre', enMessages.custom.supportCentre)}</h1>
        </div>

        <div className={"appContainer"}>
      
    

        <GridLayout 
          gap={{rows: 0, cols: 0}}
          rows={outerGrid.rows}
          cols={outerGrid.cols}
        >
          <GridLayoutItem row={mainContainer.row} col={mainContainer.col}>
            <GridLayout
              gap={{ rows: supportCardGap.row, cols: supportCardGap.col}}
              cols={supportCardContainer.cols}
              style={{justifyItems: "center", width: "80%", margin: "0 auto"}}
            >
              <GridLayoutItem className="box" row={Math.floor(0 / supportCard.divider) + 1} col={(0 % supportCard.divider) + 1}>
              <Card orientation="horizontal" style={{width: 480}}>
                        <div className="k-vbox">
                            <CardHeader className="k-hbox" style={{width: 480, height: 200}}>   
                                <Avatar type="icon" size="medium" rounded="small" fillMode="outline"><Icon size="large" name="play"/></Avatar>
                                <div>
                                    <CardTitle>{localizationService.toLanguageString('custom.howTo', enMessages.custom.howTo)}</CardTitle>
                                    <CardSubtitle>
                                    <p>{localizationService.toLanguageString('custom.howToView', enMessages.custom.howToView)}</p>
                                    <p>({localizationService.toLanguageString('custom.password', enMessages.custom.password)}: ilovegrease)</p>
                                    </CardSubtitle>
                                    <Button style={{position: 'absolute', bottom: 25}} themeColor={"primary"} onClick={() => onViewVideosClick()}>{localizationService.toLanguageString('custom.howToBtn', enMessages.custom.howToBtn)}</Button>
                                </div>
                            </CardHeader>
                           
                        </div>
                   </Card>
              </GridLayoutItem>
  
              <GridLayoutItem className="box" row={Math.floor(1 / supportCard.divider) + 1} col={(1 % supportCard.divider) + 1}>   
              <Card orientation="horizontal" style={{width: 480}}>
                        <div className="k-vbox">
                            <CardHeader className="k-hbox" style={{width: 480, height: 200}}>   
                                <Avatar type="icon" size="medium" rounded="small" fillMode="outline"><Icon size="large" name="file-txt"/></Avatar>
                                <div>
                                    <CardTitle>{localizationService.toLanguageString('custom.supportDocumentation', enMessages.custom.supportDocumentation)}</CardTitle>
                                    <CardSubtitle>{localizationService.toLanguageString('custom.supportDocumentationView', enMessages.custom.supportDocumentationView)}</CardSubtitle>
                                    <Button themeColor={"primary"} onClick={() => onViewSupportDocumentationClick()} style={{position: 'absolute', bottom: 25}}>{localizationService.toLanguageString('custom.supportDocumentatioBtn', enMessages.custom.supportDocumentatioBtn)}</Button>
                                </div>
                            </CardHeader>
                        </div>
                    </Card>
              </GridLayoutItem>

              <GridLayoutItem className="box" row={Math.floor(2 / supportCard.divider) + 1} col={(2 % supportCard.divider) + 1}>   
              <Card orientation="horizontal" style={{width: 480}}>
                        <div className="k-vbox">
                            <CardHeader className="k-hbox" style={{width: 480, height: 200}}>   
                            <Avatar type="icon" size="medium" rounded="small" fillMode="outline"><Icon size="large" name="help"/></Avatar>
                            <div>
                                <CardTitle>{localizationService.toLanguageString('custom.support', enMessages.custom.support)}</CardTitle>
                                <CardSubtitle>
                                  <p>{localizationService.toLanguageString('custom.supportText', enMessages.custom.supportText)}</p>

                                  <div className="flex flex-row items-center py-3 gap-x-2">
                                    <Icon name="email" themeColor="primary"/>
                                    <p>support@greaseboss.com.au</p>
                                    <Button icon="copy" size="small" fillMode="flat" themeColor="primary" onClick={() => {navigator.clipboard.writeText("support@greaseboss.com.au")}}/>
                                  </div>
                                </CardSubtitle>
                                <Button themeColor={"primary"} onClick={() => onGetSupportClick()} style={{position: 'absolute', bottom: 25}}>{localizationService.toLanguageString('custom.supportRequest', enMessages.custom.supportRequest)}</Button>
                                <Button themeColor={"primary"} onClick={() => onGetInTouchClick()} style={{position: 'absolute', left: 250, bottom: 25}}>{localizationService.toLanguageString('custom.supportEmail', enMessages.custom.supportEmail)}</Button>
                            </div>
                            </CardHeader>
                        </div>
                    </Card>
              </GridLayoutItem>
  
            </GridLayout>
          </GridLayoutItem>
        </GridLayout>        
      </div>
      </div>
    );
}