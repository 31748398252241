import React, { ReactElement, useEffect, useMemo, useRef  } from "react";
import { Formik, FormikProps } from "formik";
import AreaReadDto from "../../../Models/Area/AreaReadDto";
import { MachineUpdateDto } from "../../../Models/Machine/MachineUpdateDto";
import { MachineReadTableDto } from "../../../Models/Machine/MachineReadTableDto";
import { ColumnMetaData } from "../../Shared/Interfaces/ColumnMetaData";
import GBKendoDataCell from "../../Shared/Components/Table/GBKendoDataCell";
import useKendoTableState from "../../Shared/Hooks/useKendoTableState";
import MachineValidationSchema from "../Utilities/MachineValidationSchema";
import { CompositeFilterDescriptor, filterBy, orderBy } from "@progress/kendo-data-query";
import { Checkbox, } from '@progress/kendo-react-inputs';
import { Grid, GridColumn as Column, GridToolbar, GridFilterChangeEvent, GridPageChangeEvent, GridSortChangeEvent, GridRowProps } from "@progress/kendo-react-grid";
import GBKendoToolbar from "../../Shared/Components/Table/GBKendoToolbar";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { CustomTextFilterCell } from "../../Shared/Components/Table/CustomTextFilterCell";
import { isRequiredBySchema } from "../../Shared/Utilities/YupValidationUtilities";
import { CustomDateFilterCell } from "../../Shared/Components/Table/CustomDateFilterCell";
import { Button } from "@progress/kendo-react-buttons";
import { PointReadTableDto } from "../../../Models/Point/PointReadTableDto";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNoteSticky } from "@fortawesome/free-solid-svg-icons";
import { MachineReadDto } from "../../../Models/Machine/MachinesReadDto";
import NotesDialog from "../../Shared/Components/Dialogs/NotesDialog";

interface Props {
  machines: Array<MachineReadTableDto>;
  areas: Array<AreaReadDto>;
  points: Array<PointReadTableDto>;
  createMachine: (values: any, setStatus: any) => void;
  deleteMachines: (deleteIds: Array<string>) => void;
  updateMachines: (machines: Array<MachineUpdateDto>) => void;
  height: number;
}

const initialFilter: CompositeFilterDescriptor = {
  logic: "and",
  filters: [],
};

function MachineTable(props: any) {
  const tableState = useKendoTableState<MachineReadTableDto>({
    key: "Machines",
    initialAssets: props.machines,
  });

  const localizationService = useLocalization();
  const [openNotes, setOpenNotes] = React.useState<boolean>(false);
  const [selectedItem, setSelectedItem] = React.useState<MachineReadDto | null>(null);
  const closeHandler = () => {
    setOpenNotes(false);
    setSelectedItem(null)
  };

  const updateNotes = (notes: string) => {

     // Check if we are adding a new row, if so then we need to apply the description to the current temp row
    // instead of doing an update as the object does not exist yet. 
    if (tableState.tempRows.length > 0) {
      tableState.tempRows[0].description = notes;
      return;
    }

    if(selectedItem) {
      const castValues = {
        name: selectedItem.name,
        id: selectedItem.id,
        siteId: selectedItem.siteId,
        description: notes,
        parentId: selectedItem.parentId,
        status: selectedItem.status,
        photoUrl: selectedItem.photoUrl,
        areaId : selectedItem.areaId,
        model : selectedItem.model,
        manufacturer : selectedItem.manufacturer,
        namePlate : selectedItem.namePlate,
        machineryType : selectedItem.machineryType,
         }  as MachineReadTableDto

      props.updateMachines([castValues])
    }
  }

  const stateRef = useRef(tableState);

  useEffect(() => {
    stateRef.current = tableState
   }, [tableState]);

  const [filter, setFilter] = React.useState(initialFilter);
  const [sort, setSort] = React.useState(props.initialSortDescriptor ?? props.initialSort);

  // Table columns
  const columns: Array<ColumnMetaData<MachineReadTableDto>> = React.useMemo(
      () =>
        [
          {
            header: `${localizationService.toLanguageString('custom.notes', enMessages.custom.notes)}`,
            displayField: "description",
            filterType: "boolean",
            Cell: (cellProps) => (
              <GBKendoDataCell<MachineReadTableDto>
                className={"text-center"}
                dataFieldName="description"
                cellProps={cellProps}
                fieldAccessor={(x) => {
                  if(x.description === '' || x.description === null) {
                    return <Button fillMode={'flat'} icon="plus-outline"  onClick={()=> {
                      setOpenNotes(true)
                      setSelectedItem(cellProps.dataItem)
                    }}/>
                  } else {
                    return <Button fillMode={'flat'} onClick={()=> {
                      setOpenNotes(true)
                      setSelectedItem(cellProps.dataItem)
                    }}  ><FontAwesomeIcon icon={faNoteSticky} 
                    /></Button>
                  }
                }}
                isAddMode={false}
                isEditMode={false}
                onCellChanged={tableState.onCellChanged}
              />
            ),
          },
          {
            header: `${localizationService.toLanguageString('custom.name', enMessages.custom.name)}`,
            displayField: "name",
            filterType: "text",
            Cell: (cellProps) => (
              <GBKendoDataCell<MachineReadTableDto>
                dataFieldName="name"
                cellProps={cellProps}
                fieldAccessor={(x) => x.name}
                isAddMode={cellProps.dataIndex < tableState.tempRows.length}
                isEditMode={tableState.canModifyRow}
                onCellChanged={tableState.onCellChanged}
              />
            ),
          },
          {
            header: `${localizationService.toLanguageString('custom.model', enMessages.custom.model)}`,
            displayField: "string",
            Cell: (cellProps) => (
              <GBKendoDataCell<MachineReadTableDto>
                dataFieldName="model"
                cellProps={cellProps}
                fieldAccessor={(x) => x.model}
                isAddMode={cellProps.dataIndex < tableState.tempRows.length}
                isEditMode={tableState.canModifyRow}
                onCellChanged={tableState.onCellChanged}
              />
            ),
          },
          {
            header: `${localizationService.toLanguageString('custom.manufacturer', enMessages.custom.manufacturer)}`,
            displayField: "manufacturer",
            Cell: (cellProps) => (
              <GBKendoDataCell<MachineReadTableDto>
                inputType="autocomplete"
                dataFieldName="manufacturer"
                cellProps={cellProps}
                fieldAccessor={(x) => x.manufacturer}
                isAddMode={cellProps.dataIndex < tableState.tempRows.length}
                isEditMode={tableState.canModifyRow}
                onCellChanged={tableState.onCellChanged}
                assets={tableState.assets}
              />
            ),
          },
          {
            header: `${localizationService.toLanguageString('custom.parentAsset', enMessages.custom.parentAsset)}`,
            displayField: "parentName",
            Cell: (cellProps) => (
              <GBKendoDataCell<MachineReadTableDto>
                dataFieldName="parentId"
                fieldAccessor={(x) => x.parentId}
                displayTextAccessor={(x) => x.name}
                cellProps={cellProps}
                isAddMode={cellProps.dataIndex < tableState.tempRows.length}
                isEditMode={tableState.canModifyRow}
                onCellChanged={tableState.onCellChanged}
                assets={props.machines.filter(
                  (x: { areaId: string; id: string; }) =>
                    (cellProps.dataItem.areaId == null ||
                      cellProps.dataItem.areaId === "" ||
                      x.areaId === cellProps.dataItem.areaId) &&
                    x.id !== cellProps.dataItem.id
                )}
                inputType="combobox"
                initialDisplayText={cellProps.dataItem.parentName}
                onChange={(newValue, form) => onParentMachineSelected(cellProps.dataItem.id, newValue, form)}
              />
            ),
          },
          {
            header: `${localizationService.toLanguageString('custom.numTags', enMessages.custom.numTags)}`,
            displayField: "pointTag",
            Cell: (cellProps) => (
              <GBKendoDataCell<MachineReadTableDto>
                dataFieldName="id"
                fieldAccessor={(x) => x.id}
                displayTextAccessor={(x) => x.name}
                cellProps={cellProps}
                isAddMode={false}
                isEditMode={false}
                onCellChanged={tableState.onCellChanged}
                assets={props.points}
                inputType="combobox"
                initialDisplayText={cellProps.dataItem.pointTag}
              />
            ),
          },
          {
            header: `${localizationService.toLanguageString('custom.location', enMessages.custom.location)}`,
            displayField: "areaName",
            Cell: (cellProps) => (
              <GBKendoDataCell<MachineReadTableDto>
                dataFieldName="areaId"
                fieldAccessor={(x) => x.areaId}
                displayTextAccessor={(x) => x.name}
                cellProps={cellProps}
                isAddMode={cellProps.dataIndex < tableState.tempRows.length}
                isEditMode={tableState.canModifyRow}
                onCellChanged={tableState.onCellChanged}
                assets={props.areas}
                inputType="combobox"
                initialDisplayText={cellProps.dataItem.areaName}
              />
            ),
          },
        ] as Array<ColumnMetaData<MachineReadTableDto>>,
      [props.machines, tableState.canModifyRow, tableState.tempRows.length, filter, sort]
    );

  const _export = React.useRef<ExcelExport | null>(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };
  const ref = useRef<any>();

  /**The following use effects are required to fix a race condition created by updating formik fields
   * Programatically. They ensure that validation is performed after the values update instead of before.
   **/
  useEffect(() => {
    ref.current.validateForm();
  }, [ref.current?.values]);
  useEffect(() => {
    ref.current.validateForm();
  }, [ref.current]);

  /**
   * Set the skip amount to zero if the number of temp rows (indicating an add row has been added).
   * This effectively ensures the add row is always scrolled into view
   */
  useEffect(() => {
    if (tableState.tempRows.length === 1) {
      setSkip(0);
    }
  }, [tableState.tempRows.length]);

  //The following state is required for dynamic scrolling
  const [skip, setSkip] = React.useState<number>(0);
  const pageChange = (event: GridPageChangeEvent) => {
    setSkip(event.page.skip);
  };

  const isColumnForRequiredField = (displayField: string | undefined): boolean => {
    return (
      isRequiredBySchema(displayField?.replace("Name", "Id"), MachineValidationSchema) &&
      (tableState.tempRows.length > 0 || tableState.canModifyRow)
    );
  };

  useEffect(() => {
    // Reset skip when filter or sort changes
    setSkip(0);
  }, [filter, sort]);

  //Data is API data + current temporary rows that have not yet been persisted using the API
  const processedData = useMemo(() => {

    let initialRows = tableState.assets;

    if (filter) {
      initialRows = filterBy(initialRows, filter);
    }

    if (sort) {
      initialRows = orderBy(initialRows, sort);
    }
    //Add any temp rows to beggining
    return tableState.tempRows.concat(initialRows);
  }, [tableState.assets, tableState.tempRows, filter, sort]);

  // When parent machine selected, auto populate parent area to that of parent machine
  // Find area of selected
  const onParentMachineSelected = (rowId: string, newValue: any, form: FormikProps<any>) => {
    const selectedMachine = tableState.assets.find((x) => x.id === newValue);

    //If the field being edited is an addrow
    if (rowId == null || rowId === "") {
      form.setFieldValue("areaId", selectedMachine?.areaId ?? "");
      tableState.setTempRows((old: Array<MachineReadTableDto>) => {
        let updatedAssets = [...old];
        updatedAssets[0] = {
          ...updatedAssets[0],
          areaId: selectedMachine?.areaId ?? "",
          areaName: selectedMachine?.areaName ?? "",
        };
        return updatedAssets;
      });
      return;
    }

    //Now update current machine to have same area as selected
    tableState.setAssets((old: Array<MachineReadTableDto>) => {
      const modifiedRowIndex = old.findIndex((x) => x.id === rowId);
      let updatedAssets = [...old];
      updatedAssets[modifiedRowIndex] = {
        ...updatedAssets[modifiedRowIndex],
        areaId: selectedMachine?.areaId ?? "",
        areaName: selectedMachine?.areaName ?? "",
      };
      return updatedAssets;
    });
  };

  //Initial row added
  const initialisedRowData = {
    name: "",
    parentId: "",
    areaId: "",
    description: "",
    model: "",
    manufacturer: "",
    namePlate: "",
    machineryType: "",
    photoUrl: "",
    status: "Active",
    id: "",
    siteId: "",
    parentName: "",
    areaName: "",
    pointTag: "",
    expanded: true
  };

  return (
    <div>
      <Formik
        innerRef={ref}
        key={props.machines.length}
        onSubmit={async (values, { setStatus, resetForm }) => {
          if (tableState.tempRows.length > 0)
          {
            values.description = tableState.tempRows[0].description;
          }
          const res = await props.createMachine(values, setStatus);
          if (res) {
            resetForm();
            tableState.setTempRows([]);
          }
        }}
        validationSchema={MachineValidationSchema}
        initialValues={initialisedRowData}
      >
        {({ submitForm, values, resetForm, isSubmitting }) => (
          <>
            <ExcelExport data={processedData} ref={_export} collapsible={true} fileName={localizationService.toLanguageString('custom.assets', enMessages.custom.assets)}>
              <ExcelExportColumn field="name" title={localizationService.toLanguageString('custom.name', enMessages.custom.name)} />
              <ExcelExportColumn field="model" title={localizationService.toLanguageString('custom.model', enMessages.custom.model)} />
              <ExcelExportColumn field="manufacturer" title={localizationService.toLanguageString('custom.manufacturer', enMessages.custom.manufacturer)} />
              <ExcelExportColumn field="parentName" title={localizationService.toLanguageString('custom.parentAsset', enMessages.custom.parentAsset)} />
              <ExcelExportColumn field="pointTag" title={localizationService.toLanguageString('custom.numTags', enMessages.custom.numTags)} />
              <ExcelExportColumn field="areaName" title={localizationService.toLanguageString('custom.location', enMessages.custom.location)} />
              <ExcelExportColumn field="description" title={localizationService.toLanguageString('custom.notes', enMessages.custom.notes)} />
            </ExcelExport>
            <Grid
              key={tableState.tempRows.length.toString()}
              rowHeight={46}
              pageSize={30}
              total={processedData.length}
              data={processedData}
              expandField="expanded"
              scrollable="virtual"
              skip={skip}
              onPageChange={pageChange}
              filterable={true}
              filter={filter}
              onFilterChange={(e: GridFilterChangeEvent) => setFilter(e.filter)}
              sortable={true}
              sort={sort}
              onSortChange={(e: GridSortChangeEvent) => {
                setSort(e.sort);
              }}
              style={{
                height: props.height,
              }}
              className="w-full  z-0"
              //Custom render row,
              rowRender={(trElement: ReactElement, props: GridRowProps) => {
                let baseStyle = { ...trElement.props.style };
                if (tableState.modifiedRowIds.includes(props.dataItem.id)) {
                  baseStyle.backgroundColor = "rgba(255, 252, 88, 0.25)";
                }
                if (tableState.selectedRowsIds.includes(props.dataItem.id)) {
                  baseStyle.backgroundColor = "rgba(255, 99, 88, 0.25)";
                }

                return React.cloneElement(
                  trElement,
                  {
                    ...trElement,
                    style: { ...baseStyle },
                  },
                  trElement.props.children
                );
              }}
            >
              <GridToolbar>  
                <GBKendoToolbar
                  name={localizationService.toLanguageString('custom.assets', enMessages.custom.assets)}
                  tableState={tableState}
                  exportAction={excelExport}
                  importAction={null}
                  addAction={
                    props.createMachine
                      ? () => {
                        if (tableState.tempRows.length === 1) {
                          tableState.setTempRows([]);
                          return;
                        }
                        tableState.setTempRows([...tableState.tempRows, initialisedRowData]);
                        }
                      : null
                  }
                  updateAction={
                    props.updateMachines
                      ? async () => {
                          let updatedAssets = tableState.assets.filter((x) => tableState.modifiedRowIds.includes(x.id));
                          await props.updateMachines(updatedAssets);
                        }
                      : null
                  }
                  deleteAction={
                    props.deleteMachines ? async () => await props.deleteMachines(tableState.selectedRowsIds) : null
                  }
                  discardChanges={() => tableState.resetState()}
                />
              </GridToolbar>

              {/* Selection - only if in edit mode*/}
              {tableState.canModifyRow && (
                <Column  
                  key={"Selection"}
                  cell={(props) => (
                    <td>
                      
                        <Checkbox
                          onChange={() => {
                            if (tableState.selectedRowsIds.includes(props.dataItem.id)) {
                              tableState.setSelectedRowIds(
                                tableState.selectedRowsIds.filter((id) => id !== props.dataItem?.id)
                              );
                            } else {
                              tableState.setSelectedRowIds([...tableState.selectedRowsIds, props.dataItem?.id]);
                            }
                          }}
                          checked={tableState.selectedRowsIds.includes(props.dataItem.id)}
                        />
                    </td>
                  )}
                  width="50px"
                  filterable={false}
                  sortable={false} />)}

               {/* From Column Array */}
               {columns.map((column, index) => {
                 if(column.displayField === "description") {
                  return (
                    //Create wrapper/HOC with editable cell and add cell definitions,
                    <Column
                      width="65px"
                      className="text-center"
                      key={index}
                      cell={column.Cell}
                      title={`${column.header}${isColumnForRequiredField(column.displayField) ? "*" : ""}`}
                      field={column.displayField}
                      filterable={false}
                      sortable={true}
                    />
                  );
                } else {
                  return (
                    //Create wrapper/HOC with editable cell and add cell definitions,
                    <Column
                      width="auto"
                      key={index}
                      cell={column.Cell}
                      // Show asterix in header if required TODO: pretty hacky - assumes that the name of the data field is the same as the display field with Name replaced.
                      title={`${column.header}${isColumnForRequiredField(column.displayField) ? "*" : ""}`}
                      field={column.displayField}
                      filter={column.filterType}
                      filterCell={column.filterType === "date" ? CustomDateFilterCell : CustomTextFilterCell}
                    />
                  );
                }
              })}


              {/* Inline Action options (If ActionDefinitions are provided) */}
              {props.extraInlineActions != null && props.extraInlineActions.length > 0 && (
                <Column
                  title={localizationService.toLanguageString('custom.actions', enMessages.custom.actions)}
                  sortable={false}
                  filterable={false}
                  cell={(cellProps) => {
                    return (
                      <td className="flex flex-wrap justify-around gap-1">
                        {tableState.tempRows.length <= cellProps.dataIndex &&
                          props.extraInlineActions.map((action: any) => {
                            return (
                              <Button
                                className=""
                                themeColor={action.themeColor}
                                onClick={() => action.onClick(cellProps.dataItem)}
                              >
                                {action.text}
                              </Button>
                            );
                          })}
                      </td>
                    );
                  }}
                />
              )}

              {/* Command Cell (For add rows only)*/}
              {tableState.tempRows.length > 0 && (
                <Column
                  width="150px"
                  sortable={false}
                  filterable={false}
                  key={"Commands"}
                  cell={(props) => {
                    // Add Button for new assets
                    return (
                      <td>
                        {props.dataIndex === 0 && (
                          <div className="flex space-x-1">
                            <Button onClick={submitForm} disabled={isSubmitting} themeColor={"primary"}>
                            {localizationService.toLanguageString('custom.add', enMessages.custom.add)}
                            </Button>
                            <Button
                              onClick={() => {
                                tableState.setTempRows([]);
                                resetForm();
                              }}
                              themeColor={"base"}
                            >
                              {localizationService.toLanguageString('custom.exit', enMessages.custom.exit)}
                            </Button>
                          </div>
                        )}
                      </td>
                    );
                  }}
                />
              )}
            </Grid>
          </>
          )}
      </Formik>
      {openNotes && (
        <NotesDialog onClose={closeHandler} notes={selectedItem} updateNotes={updateNotes}/>
      )}
    </div>
  );
};

export default MachineTable;