import { ReactElement } from "react";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@progress/kendo-react-buttons";
import { useNavigate } from "react-router-dom";

interface Props {
  title?: string;
  period?: string | null;
  clientRoute?: string;
}

export default function NoDataAvailablePage(props: Props): ReactElement {
  const localizationService = useLocalization();
  let navigate = useNavigate();
  let test = props.clientRoute !== undefined ? props.clientRoute : "/"
  
  return (
    <div className="m-2">
      <div className="flex justify-between align-middle">
        <div className="text-xl font-bold">{props.title}</div>
        <Button fillMode={"link"} style={{color:"blue", textDecoration: "underline" }} onClick={() => navigate(test)}>{localizationService.toLanguageString('custom.seeAll', enMessages.custom.seeAll)}</Button>
      </div>
      {props.period !== null && <div className="ml-1 mt-1">{props.period}</div> }

      <div className="text-4xl m-auto text-center mt-24">
        <FontAwesomeIcon icon={faWarning} style={{color: "#ff3333",}}  />
        <div className="text-base font-semibold">{localizationService.toLanguageString('custom.noDataAvailable', enMessages.custom.noDataAvailable)}</div>
      </div>
    </div>
  );
}
