import { ReactElement, useContext, useEffect, useRef, useState } from "react";
import { useLocalization } from '@progress/kendo-react-intl';
import { SciChartOverview, TWebAssemblyChart } from "scichart";
import moment from "moment";
import { AppContext } from "../../../../AppContext";
import { locales } from "../../../../messages/locales";
import { Grid, GridColumn, GridColumnMenuWrapper, GridHeaderCellProps, GridToolbar } from "@progress/kendo-react-grid";
import ReservoirViewModal from "../../../Reservoir/Components/ReservoirViewModal";
import { Button } from "@progress/kendo-react-buttons";
import { SortDescriptor, process } from "@progress/kendo-data-query";
import { CustomColumnMenu } from "../../Utilities/customColumnMenu";
import { Popover, PopoverActionsBar, Tooltip } from "@progress/kendo-react-tooltip";
import ReservoirLinked from "../../../Reservoir/Components/ReservoirLinked";
import { enMessages } from "../../../../messages/en-US";
import initSciChartReservoirsReport from "./ReservoirsReportChart";
import { useRefState } from "../../../Shared/Hooks/useRefState";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { ReservoirReportChart } from "./ReservoirReportChart";

interface Props {
 reservoir: any;
}

interface Page {
  skip: number;
  take: number;
}

const page: Page = { skip: 0, take: 30 };

export default function ReservoirsReportTable(props: Props): ReactElement {

  const localizationService = useLocalization();
  const {localeId, onLanguageChange } = useContext<any>(AppContext);

  const [viewReservoir, setViewReservoir] = useState<boolean>(false);
  const [reservoirId, setReservoirId] = useState<string>('');
  const closeHandler = () => {
    setViewReservoir(false);
    setReservoirId('')
  };
  const currentLanguage = locales.find(item => item.localeId === localeId);
  const reservoirData = props.reservoir;

  const _export = useRef<ExcelExport | null>(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };
  const initialSort: Array<SortDescriptor> = [
    { field: "estimatedEmptyDate", dir: "asc" },
  ];
  const [sort, setSort] = useState(initialSort);

  const grid = useRef<any>(null);

  useEffect(() => {

    const debounce = (func, wait) => {
      let timeout;
      return function executedFunction(...args) {
        const later = () => {
          timeout = null;
          func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
      };
    }

    const handleResize = () => {
      let columns = [...stateColumnsRef.current];
      for(let i = 0; i < stateColumns.length; i++) {
        if (columns[i].show) 
        {
          if (columns[i].minWidth > grid.current.offsetWidth)
          {
            columns[i].isHidden = true;
          }
          else 
          {
            columns[i].isHidden = false;
          }
        }
      }
  
     setStateColumns(columns); 
    };

    grid.current = document.querySelector(".k-grid");
    window.addEventListener("resize", debounce(handleResize, 500));

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };

    
  }, []);

    //Creating dataState to prevent issues with virtual scrolling
    const createAppState = (dataState) => {
      return {
        result: process(reservoirData, dataState),
        dataState: dataState,
      };
    };
  
    const [dataState, setDataState] = useState<any>(
      createAppState({
        skip: page.skip,
        take: page.take,
        sort: sort
      })
    );
   
    const dataStateChange = (event) => {
      setDataState(createAppState(event.dataState));
    };

    useEffect(() => {
      setDataState( createAppState({
        skip: page.skip,
        take: page.take,
        sort: sort
      }));
    }, [props]);
  
  // Custom cells for the table
  const ProjectedDateCell = (props: any) => {
    const projectedDate = moment(props.dataItem.estimatedEmptyDate)
      if (projectedDate.year() !== 1970)
      {
        const differenceInYears = projectedDate.diff(moment(), "years", false);

        // Display projected date differently if it exceeds a number of years
        if (differenceInYears > 5)
        {
          return <td><div>5 years+</div></td>
        }

        return <td><div>{projectedDate.format('DD/MM/YYYY')}</div><div> ({projectedDate.fromNow()})</div></td>
      }

    return <td><div>-</div></td>;
    }

    const EstimatedVolumeCell = (props: any) => {
      const reservoirLevel = Number.isNaN(props.dataItem.estimatedVolume) ? '-' : `${props.dataItem.estimatedVolume} ${props.dataItem.displayUnit}`;
      let reservoirPercentage = Number.isNaN(props.dataItem.estimatedVolume) ? '' : `${(props.dataItem.estimatedVolumeAsPercentage * 100).toFixed(0)}%`;

        if (props.dataItem.estimatedVolumeAsPercentage < 0)
        {
          reservoirPercentage = "0%"
        }

        return <td>
          <div className="">
            <div>{reservoirLevel}<span> ({reservoirPercentage})</span></div>
            <ReservoirReportChart key={props.dataItem.reservoirId} reservoirId={props.dataItem.reservoirId} estimatedVolumeAsPercentage={props.dataItem.estimatedVolumeAsPercentage} />

          {/* <div id={`res${props.dataItem.reservoirId}`} style={{ height: '20px'}}></div> */}
          </div>
         
        </td>
    };

    const NameCell = (props: any) => {
      return (
        <td>
         <Button fillMode={"link"} style={{color:"#388DF9", whiteSpace:'normal', lineBreak:'anywhere', textAlign:'left'}} onClick={() => {
                setViewReservoir(true)
                setReservoirId(props.dataItem.reservoirId)
                }} >{props.dataItem.name}</Button>
        </td>
      );
    };

    const AverageConsumptionCell = (props: any) => {
      return (
        <td>
          <div>{props.dataItem.averageDailyConsumption} {props.dataItem.displayUnit}</div>
        </td>
      );
    };

    const CapacityCell = (props: any) => {
      return (
        <td>
          <div>{props.dataItem.capacity} {props.dataItem.displayUnit}</div>
        </td>
      );
    };

    const LastResetDateCell = (props: any) => {
      var lastResetDate = moment(new Date(props.dataItem.lastResetDate)).format('DD/MM/YYYY');
      var resetSinceNow = moment(new Date(props.dataItem.lastResetDate)).fromNow();
      return <td><div>{lastResetDate}</div><div> ({resetSinceNow})</div></td>
    };

    const LinkedCell = (props: any) => {
      const anchor = useRef<Button>(null);
      const [show, setShow] = useState(false);
      return (
        <td>
          <Button
            fillMode={'link'}
            ref={anchor}
            style={{ color: '#388DF9' }}
            onClick={() => setShow(!show)}
          >
            {props.dataItem.linkedPointCount}
          </Button>
          <Popover
            show={show}
            anchor={anchor.current && anchor.current.element}
            position={'left'}
            collision={{vertical: "flip", horizontal:"flip"}}
          >
            <ReservoirLinked reservoirId={props.dataItem.reservoirId}/>
            <PopoverActionsBar>
              <div className="grid justify-items-end">
                <Button onClick={() => setShow(false)}>{localizationService.toLanguageString('custom.close', enMessages.custom.close)}</Button>
              </div>
            </PopoverActionsBar>
          </Popover>
        </td>
      );
    };

    const HeaderCell = (props: any) => {
      return <div style={{height:'60px'}}><div>{props.title}</div><div style={{height:'25px', width:'20px'}}></div>{props.children}
      <GridColumnMenuWrapper {...props.columnMenuWrapperProps}/> </div>
    };

    const HeaderConsumptionCell = (props: GridHeaderCellProps) => {
      return (
      <div style={{height:'60px'}}>
        <div>{localizationService.toLanguageString('custom.average', enMessages.custom.average)}</div>
        <div>({localizationService.toLanguageString('custom.perDay', enMessages.custom.perDay)})</div>
        {props.children}
        <GridColumnMenuWrapper {...props.columnMenuWrapperProps} />
      </div>)
    };
    const HeaderDepletionCell = (props: any) => {
      return <div style={{height:'60px'}}><div>{localizationService.toLanguageString('custom.estimated', enMessages.custom.estimated)}</div><div>{localizationService.toLanguageString('custom.depletionDate', enMessages.custom.depletionDate)}</div>  {props.children}
      <GridColumnMenuWrapper {...props.columnMenuWrapperProps} /></div>
    };

    const HeaderLinkedCell = (props: any) => {
      return <div style={{height:'60px'}}><div>{localizationService.toLanguageString('custom.linked', enMessages.custom.linked)}</div><div>{localizationService.toLanguageString('custom.endpoints', enMessages.custom.endpoints)}</div>  {props.children}
      <GridColumnMenuWrapper {...props.columnMenuWrapperProps} /></div>
    };

    // Columns for the table
    const columns: Array<any> = [
      {
        title: `${localizationService.toLanguageString('custom.name', enMessages.custom.name)}`,
        field: "name",
        show: true,
        filter: "text",
        cell: NameCell,
        width: 200,
        filterable: true,
        headerCell: HeaderCell,
        isHidden: false,
      },
      {
        title: `${localizationService.toLanguageString('custom.capacity', enMessages.custom.capacity)}`,
        field: "capacity",
        show: true,
        filter: "text",
        width: 100,
        filterable: true,
        cell: CapacityCell,
        headerCell: HeaderCell,
        isHidden: true,
        minWidth:800,
      },
      {
        title: `${localizationService.toLanguageString('custom.estimatedVolume', enMessages.custom.estimatedVolume)}`,
        field: "estimatedVolume",
        show: true,
        filter: "text",
        filterable: true,
        cell: EstimatedVolumeCell,
        headerCell: HeaderCell,
        isHidden: false,

      },
      {
        title: `${localizationService.toLanguageString('custom.projectedDepletionDate', enMessages.custom.projectedDepletionDate)}`,
        field: "estimatedEmptyDate",
        show: true,
        width: 160,
        filterable: false,
        cell: ProjectedDateCell,
        headerCell: HeaderDepletionCell,
        isHidden: false,
      },
      {
        title: `${localizationService.toLanguageString('custom.consumable', enMessages.custom.consumable)}`,
        field: "consumableName",
        show: true,
        filter: "text",
        width: 150,
        filterable: true,
        headerCell: HeaderCell,
        isHidden: true,
        minWidth:800,
      },
      
      {
        title: `${localizationService.toLanguageString('custom.averageDailyConsumption', enMessages.custom.averageDailyConsumption)}`,
        field: "averageDailyConsumption",
        show: true,
        filter: "text",
        width: 100,
        filterable: true,
        cell: AverageConsumptionCell,
        headerCell: HeaderConsumptionCell,
        isHidden: true,
        minWidth:900,
      },
      {
        title: `${localizationService.toLanguageString('custom.lastReset', enMessages.custom.lastReset)}`,
        field: "lastResetDate",
        show: true,
        width: 140,
        filterable: false,
        cell: LastResetDateCell,
        headerCell: HeaderCell,
        isHidden: true,
        minWidth:1040,
      },
      {
        title: `${localizationService.toLanguageString('custom.linkedPoints', enMessages.custom.linkedPoints)}`,
        field: "linkedPointCount",
        show: true,
        filter: "text",
        cell: LinkedCell,
        width: 110,
        filterable: true,
        headerCell: HeaderLinkedCell,
        isHidden: true,
        minWidth:1150,
      },
    ];

    const [stateColumns, stateColumnsRef, setStateColumns] = useRefState<any>(columns);

    const onColumnsSubmit = (columnsState: Array<any>) => {
      setStateColumns(columnsState);
    };

    useEffect(() => {
      let sciChartOverview: SciChartOverview;
      let allChartsReservoirDelete: TWebAssemblyChart[];
    
      (async () => {
        const {allChartsReservoir} = await initSciChartReservoirsReport(dataState.result.data);
        allChartsReservoirDelete = allChartsReservoir;
      })();
  
      return () => {
        sciChartOverview?.delete();
        allChartsReservoirDelete?.forEach(chart => chart?.sciChartSurface?.delete());
      };
    }, [props, dataState.result, stateColumns, viewReservoir]);

    const getExcelExportData = () => {
      return process(reservoirData, {filter: dataState.dataState.filter, sort: dataState.dataState.sort}).data;
    };

return (
  <div className=''>
     <ExcelExport data={getExcelExportData()} ref={_export} collapsible={true} fileName={localizationService.toLanguageString('custom.currentReservoirUsage', enMessages.custom.currentReservoirUsage)}>
        <ExcelExportColumn field="name" title={localizationService.toLanguageString('custom.name', enMessages.custom.name)} />
        <ExcelExportColumn field="capacity" title={localizationService.toLanguageString('custom.capacity', enMessages.custom.capacity)} />
        <ExcelExportColumn field="estimatedVolume" title={localizationService.toLanguageString('custom.estimatedVolume', enMessages.custom.estimatedVolume)} />
        <ExcelExportColumn field="estimatedVolumeAsPercentage" title={`${localizationService.toLanguageString('custom.estimatedVolume', enMessages.custom.estimatedVolume)} (%)`} cellOptions={{ format: "0.00%" }}/>
        <ExcelExportColumn field="estimatedEmptyDate" title={localizationService.toLanguageString('custom.projectedDepletionDate', enMessages.custom.projectedDepletionDate)} cellOptions={{ format: "dd/mm/yyyy" }} width={150}/>
        <ExcelExportColumn field="consumableName" title={localizationService.toLanguageString('custom.consumable', enMessages.custom.consumable)} />
        <ExcelExportColumn field="averageDailyConsumption" title={localizationService.toLanguageString('custom.averageDailyConsumption', enMessages.custom.averageDailyConsumption)} />
        <ExcelExportColumn field="lastResetDate" title={localizationService.toLanguageString('custom.lastReset', enMessages.custom.lastReset)} cellOptions={{ format: "dd/mm/yyyy" }}  width={150}/>
        <ExcelExportColumn field="linkedPointCount" title={localizationService.toLanguageString('custom.linkedPoints', enMessages.custom.linkedPoints)} />
     </ExcelExport>
    <Grid
      key={'ReservoirsReportTable'}
      data={dataState.result}
      scrollable={"virtual"} 
      style={{height:"700px"}} 
      take={page.take}
      rowHeight={50}
      onDataStateChange={dataStateChange}
      {...dataState.dataState}
      sortable={true}
      >
      <GridToolbar >
        <div style={{width:'100%', display:'flex', justifyContent:'space-between'}}>
          <div className="gb-h3 pl-1">{localizationService.toLanguageString('custom.currentReservoirUsage', enMessages.custom.currentReservoirUsage)}</div>
            <Tooltip anchorElement="target" position="bottom">
              <Button
                title={localizationService.toLanguageString('custom.exportToExcel', enMessages.custom.exportToExcel)}
                icon="download"
                disabled={dataState.result.data.length === 0}
                onClick={excelExport}
              />
            </Tooltip>
        </div>
      </GridToolbar>
         {stateColumns.map(
        (column, idx) =>
          (column.show && !column.isHidden) &&  (
            <GridColumn
              key={idx}
              field={column.field}
              title={column.title}
              filter={column.filter}
              cell={column.cell}
              width={column.width}
              headerCell={column.headerCell}
              columnMenu={(props) => (
                <CustomColumnMenu
                  {...props}
                  columns={stateColumns}
                  onColumnsSubmit={onColumnsSubmit}
                  data={reservoirData}
                  filterable={column.filterable}
                />
              )}
            />
          )
        )}
    </Grid> 
    {viewReservoir && (
        <ReservoirViewModal
        key={'ReservoirView'}
        reservoirId={reservoirId}
        viewReservoir={viewReservoir}
        onClose={() => closeHandler()}/>
      )}
   
  </div>
  )
}