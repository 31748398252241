//Within this file we define constant identitifiers for different client side routes within our application.

export const ClientRoutes = {
  //Auth
  LoginPage: "/login",
  LoginCallbackPage: "/login-callback",
  Logout: '/logout',

  //Admin
  SiteAdministrationSitesPage: "/home/siteadmin/sites",
  SiteAdministrationUsersPage: "/home/siteadmin/users",
  SuperAdministrationSitesPage: "/home/admin/sites",
  SuperAdministrationUsersPage: "/home/admin/users",
  SuperAdministrationDevicesPage: "/home/admin/managedevices",
  SuperAdministrationEndpointsPage: "/home/admin/manageendpoints",
  AuthToken: "/home/api/settings/auth-token",

  //Assets
  SiteSetupPage: "/home/sitesetup",
  SchedulePage: "/schedule",
  AreasPage: "/areas",
  MachinesPage: "/machines",
  DevicesPage: "/devices",
  PointsPage: "/points",
  ConsumablesPage: "/consumables",
  RouteEditorPage: "/routeeditor",
  EndpointsPage: "/endpoints",

  //Operations
  //Events Page
  EventsPage: "/home/events",

  //Actions Page
  DashboardPage: '/home/dashboard',


  //Actions Page
  ActionsPage: '/home/actions',

  //Alets Page
  AlertsPage: "/home/alerts",

  //Sytem
  AlertsJobsPage: "/home/system/alertjobs",
  ComplianceReportJobsPage:  "/home/system/compliancereportjobs",

  //Notifications Page
  NotificationsPage: "/home/notifications",

  //Reports
  ComplianceReportPage: "/home/reports/compliancereport",
  PendingActionsPage: "/home/reports/pendingactions",
  EndpointReportPage: "/home/reports/endpointreport",
  PlanVsActualReportPage: "/home/reports/planvsactual",
  ReservoirsReportPage: "/home/reports/reservoirs",

  //TreeView Page
  SiteExplorerPage: "/home/siteexplorer",
  SiteExplorerPageWithId: "/home/siteexplorer/:id",
  SiteExplorerPageWithIdAndTabId: "/home/siteexplorer/:id/:tabId",

  //Schedule Page
  //Tasks Page

  //Other
  LoadingPage : "/loading",
  SupportPage: "/home/support",
  ProfilePage: "/profile",
  HomePage: "/",
  Home: '/home',
  DefaultPage: "/home/default",

};

export const ReactQueryKeys = {
  AllSitesQuery: "sites",
  AllUsersQuery: "users",
  AllEventsQuery: "events",
  AllRolesQuery: "roles",
  AllAreasQuery: "areas",
  AllMachinesQuery: "machines",
  AllPointsQuery: "points",
  AllDevicesQuery: "devices",
  AllConsumablesQuery: "consumables",
  AllSchedulesQuery: "schedules",
  AllTasksQuery: "tasks",
  ManageDevicesQuery: "manageDevices",
  AllReportScheduleQuery: "complianceReport",
  AllTreeListQuery: "treelist",
  AllActivityChart: 'activityChart',
  AllActionsQuery: 'actions',
  ManageEndpointsQuery: 'manageEndpoints',
  AllEndpointsQuery: 'endpoints',
  AllEndpointEventsQuery: 'endpointEvents',
  AllFlowRateEventsQuery: 'flowRateEvents',
  AllTemperatureEventsQuery: 'temperatureEvents',
  AllGpsEventsQuery: 'gpsEvents',
  AllGreaseReservoirsQuery: 'greaseReservoirs',
  GreaseReservoirsAnalysisQuery: 'greaseReservoirsAnalysis',
  ManageAuthTokens: 'manageAuthTokens' ,
  AllRequirementQuery: 'requirements',
  EndpointsLastSeenQuery: 'endpointsLastSeen',
  AllAlertsQuery: 'alerts',
  AllNotificationsQuery: 'notifications',
  AllNotificationsSummartQuery: 'notificationsSummary',
  AllEndpointsWithRequirementsQuery: 'endpointsWithRequirements',
  AllAlertJobsQuery: 'alertJobs',
  AllNotificatiosStatusQuery: 'notificationsStatus',
  AllStatusChartQuery: 'statusChart',
  DashboardEndpointsMetricsQuery: 'dashboardEndpointsMetrics',
  DashboardManualComplianceQuery: 'dashboardManualCompliance',
  DashboardAutoComplianceQuery: 'dashboardAutoCompliance',
  DashboardTagsMetricsQuery: 'dashboardTagsMetrics',
  DashboardAlertsMetricsQuery: 'dashboardAlerts',
  SoftwareFeaturesQuery: 'softwareFeatures'
};
