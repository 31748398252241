import {
  Grid,
  GridColumn,
  GridDataStateChangeEvent,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { ReactElement, useEffect, useRef, useState } from "react";
import useIdentityStore from "../../../Zustand/identityStore";
import { Button } from "@progress/kendo-react-buttons";
import { useLocalization } from "@progress/kendo-react-intl";
import { enMessages } from "../../../messages/en-US";
import { AlertReadTableDto } from "../../../Models/Alerts/AlertReadTableDto";
import {
  CompositeFilterDescriptor,
  DataResult,
  FilterDescriptor,
  SortDescriptor,
  State,
  process,
} from "@progress/kendo-data-query";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import DeleteDialog from "../../Shared/Components/Dialogs/DeleteDialog";
import { Tooltip } from "@progress/kendo-react-tooltip";
import moment from "moment";
import { InputClearValue, TextBox, InputSeparator, TextBoxProps } from "@progress/kendo-react-inputs";
import {
  MultiSelect,
  MultiSelectChangeEvent,
} from "@progress/kendo-react-dropdowns";
import React from "react";
import { Icon } from "@progress/kendo-react-common";

interface Props {
  onClose: () => void;
  alertToUpdateHandler: (alert: any) => void;
  alerts: AlertReadTableDto[];
  deleteAlert: (alertId: string) => void;
  onDelete: (alert) => void;
  resetAlert: (alertId: string, alert: any) => void;
}

interface Item {
  text: string;
  fields: string[]; 
}

const ColumnsOptions: Item[] = [
  { text: 'Alert', fields: ["name", "description"] },
  { text: 'Assigned To', fields: ["assignedTo.name", "assignedTo.details"] },
  { text: 'Criticality', fields: ["criticality"] },
  { text: 'Type', fields: ["type"] }
];

export default function AlertsTable(props: Props): ReactElement {
  const currentUserRole = useIdentityStore((x) => x.user?.profile.role);
  const localizationService = useLocalization();
  const [alertToDelete, setAlertToDelete] = useState<AlertReadTableDto | null>(
    null
  );

  const initialSort: Array<SortDescriptor> = [
    {field:"alertStatus.isTriggered", dir:"desc"},
    { field: "criticalityId", dir: "asc" },
  ];
  const [sort, setSort] = useState(initialSort);

  const _export = useRef<ExcelExport | null>(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  const alerts = props.alerts;

  //Creating dataState to prevent issues with virtual scrolling
  const createDataState = (dataState: State) => {
    return {
      result: process(alerts.slice(0), dataState),
      dataState: dataState,
    };
  };

  let initialState = createDataState({
    take: 30,
    skip: 0,
    sort: sort,
  });


  const [result, setResult] = useState<DataResult>(initialState.result);
  const [dataState, setDataState] = useState<State>(initialState.dataState);


  const dataStateChange = (event: GridDataStateChangeEvent) => {
    let updatedState = createDataState(event.dataState);
    setResult(updatedState.result);
    setDataState(updatedState.dataState);
  };

  const AlertCell = (props) => {
    return (
      <td>
        <div>{props.dataItem.name}</div>
        <div className="text-xs">{props.dataItem.description}</div>
      </td>
    );
  };

  const EnabledCell = (props) => {
    const enabledIcon = props.dataItem.enabled ? "k-i-check" : "k-i-close";

    return (
      <td>
        <div className={`k-icon ${enabledIcon}`}></div>
      </td>
    );
  };

  const CriticalityCell = (props) => {
    let item = props.dataItem;
    const colour =
      item.criticality === "Critical"
        ? "#780000"
        : item.criticality === "High"
        ? "#dc0000"
        : item.criticality === "Medium"
        ? "#fd8c00"
        : "#fdc500";
    const icon =
      item.criticality === "Critical"
        ? "k-i-chevron-double-up"
        : item.criticality === "High"
        ? "k-i-arrow-chevron-up"
        : item.criticality === "Medium"
        ? "k-i-equal"
        : "k-i-arrow-chevron-down";
    return (
      <td>
        <Tooltip position={"bottom"} anchorElement={"target"}>
          <div
            className={`k-icon ${icon} cursor-pointer k-icon-xl`}
            style={{
              color: `${colour}`,
            }}
            title={item.criticality}
          ></div>
        </Tooltip>
      </td>
    );
  };

  const TriggeredCell = (props) => {
    const lastEvaluated =
      props.dataItem.alertStatus.lastEvaluated === undefined
        ? "-"
        : moment(props.dataItem.alertStatus.lastEvaluated).format(
            " DD/MM/YYYY @ HH:mm:ss"
          );
    const lastModified = moment(props.dataItem.alertStatus.lastNotified).format(
      " DD/MM/YYYY @ HH:mm:ss"
    );
    const lastReset = moment(props.dataItem.alertStatus.lastReset).format(
      " DD/MM/YYYY @ HH:mm:ss"
    );
    const lastTriggered = moment(
      props.dataItem.alertStatus.lastTriggered
    ).format(" DD/MM/YYYY @ HH:mm:ss");

    const TooltipContentTemplate = (props: any) => {
      return (
        <div>
          <div>
            {localizationService.toLanguageString(
              "custom.lastEvaluated",
              enMessages.custom.lastEvaluated
            )}
            : {moment(lastEvaluated).year() === 1970 ? " -" : lastEvaluated}
          </div>
          <div>
            {localizationService.toLanguageString(
              "custom.lastNotified",
              enMessages.custom.lastNotified
            )}
            : {moment(lastModified).year() === 1970 ? " -" : lastModified}
          </div>
          <div>
            {localizationService.toLanguageString(
              "custom.lastReset",
              enMessages.custom.lastReset
            )}
            : {moment(lastReset).year() === 1970 ? " -" : lastReset}
          </div>
          <div>
            {localizationService.toLanguageString(
              "custom.lastTriggered",
              enMessages.custom.lastTriggered
            )}
            : {moment(lastTriggered).year() === 1970 ? " -" : lastTriggered}
          </div>
        </div>
      );
    };

    const enabledIcon = props.dataItem.alertStatus.isTriggered
      ? "k-i-check"
      : "k-i-close";

    return (
      <td>
        <Tooltip
          position={"bottom"}
          anchorElement={"target"}
          content={(props) => <TooltipContentTemplate title={props.title} />}
        >
          <div
            className={`k-icon ${enabledIcon} cursor-pointer `}
            title={"Triggered"}
          ></div>
        </Tooltip>
      </td>
    );
  };

  const TypeCell = (props) => {

    return (
      <td>
         <Tooltip position={"bottom"} anchorElement={"target"}>
          {props.dataItem.type === "Reservoir" ?
            <span className="k-icon k-i-blur k-icon-xl cursor-pointer" title={`${localizationService.toLanguageString("custom.reservoir", enMessages.custom.reservoir)} ${localizationService.toLanguageString("custom.alert", enMessages.custom.alert)}`}></span> :
            <span className="k-icon k-i-track-changes-accept k-icon-xl cursor-pointer"  title={`${localizationService.toLanguageString("custom.requirement", enMessages.custom.requirement)} ${localizationService.toLanguageString("custom.alert", enMessages.custom.alert)}`}></span>
          }
        </Tooltip>
      </td>
    );
  };

  const AssignedToCell = (props) => {
    return (
      <td>
        <div>{props.dataItem.assignedTo.name}</div>
        <div className="text-xs">{props.dataItem.assignedTo.details}</div>
      </td>
    );
  }

  const HeaderAssignedToCell = (props) => {
    const TooltipContentTemplate = (props: any) => {
      return (
        <div>
          <div className="underline">{localizationService.toLanguageString("custom.requirement", enMessages.custom.requirement)} {localizationService.toLanguageString("custom.alert", enMessages.custom.alert)}</div>
         <div>{localizationService.toLanguageString("custom.endpoint", enMessages.custom.endpoint)} ({localizationService.toLanguageString("custom.serialNumber", enMessages.custom.serialNumber)})</div>
         <div>{localizationService.toLanguageString("custom.location", enMessages.custom.location)} &gt; {localizationService.toLanguageString("custom.asset", enMessages.custom.asset)}</div>
        </div>
      );
    };
    return (
      <span onClick={props.onClick} className={'k-cell-inner'}>
        <span className={'k-link'}>
          <span className={'k-column-title'}>{props.title}</span>{' '}
          <Tooltip anchorElement="target" position="bottom" content={(props) => <TooltipContentTemplate title={props.title}  />}>
          <span className="k-icon k-i-information ml-3" style={{cursor:"pointer"}} title="title"></span>
          </Tooltip>
          {props.children}
        </span>       
      </span>);
  };

  const HeaderCriticalityCell = (props) => {

    const TooltipContentTemplate = (props: any) => {
      return (
        <div>
          <div>
            <span className="k-icon k-i-chevron-double-up"></span>
            <span>: {localizationService.toLanguageString("custom.critical",enMessages.custom.critical)}</span>
          </div>
          <div>
            <span className="k-icon k-i-arrow-chevron-up"></span>
            <span>: {localizationService.toLanguageString("custom.high",enMessages.custom.high)}</span>
          </div>
          <div>
            <span className="k-icon k-i-equal"></span>
            <span>: {localizationService.toLanguageString("custom.medium",enMessages.custom.medium)}</span>
          </div>
          <div>
            <span className="k-icon k-i-arrow-chevron-down"></span>
            <span>: {localizationService.toLanguageString("custom.low",enMessages.custom.low)}</span>
          </div>
        </div>
      );
    };
    return (
      <span onClick={props.onClick} className={'k-cell-inner'}>
        <span className={'k-link'}>
          <span className={'k-column-title'}>{props.title}</span>{' '}
          <Tooltip anchorElement="target" position="bottom" content={(props) => <TooltipContentTemplate title={props.title}  />}>
          <span className="k-icon k-i-information ml-3" style={{cursor:"pointer"}} title="title"></span>
          </Tooltip>
          {props.children}
        </span>       
      </span>);
  };

  const HeaderTypeCell = (props) => {

    const TooltipContentTemplate = (props: any) => {
      return (
        <div>
          <div>
            <span className="k-icon k-i-blur"></span>
            <span>: {localizationService.toLanguageString("custom.reservoir", enMessages.custom.reservoir)} {localizationService.toLanguageString("custom.alert", enMessages.custom.alert)}</span>
          </div>
          <div>
            <span className="k-icon k-i-track-changes-accept"></span>
            <span>: {localizationService.toLanguageString("custom.requirement", enMessages.custom.requirement)} {localizationService.toLanguageString("custom.alert", enMessages.custom.alert)}</span>
          </div>
        </div>
      );
    };
    return (
      <span onClick={props.onClick} className={'k-cell-inner'}>
        <span className={'k-link'}>
          <span className={'k-column-title'}>{props.title}</span>{' '}
          <Tooltip anchorElement="target" position="bottom" content={(props) => <TooltipContentTemplate title={props.title}  />}>
          <span className="k-icon k-i-information ml-3" style={{cursor:"pointer"}} title="title"></span>
          </Tooltip>
          {props.children}
        </span>       
      </span>);
  };


  // Columns for the table
  const columns: Array<any> = [
    {
      field: "name",
      title: `${localizationService.toLanguageString("custom.alert",enMessages.custom.alert)}`,
      cell: AlertCell,
      show: true,
    },
    {
      field: "assignedTo.name",
      title: `${localizationService.toLanguageString("custom.assignedTo",enMessages.custom.assignedTo)}`,
      show: true,
      cell: AssignedToCell,
      headerCell: HeaderAssignedToCell
    },
    {
      field: "criticalityId",
      title: `${localizationService.toLanguageString("custom.criticality",enMessages.custom.criticality)}`,
      show: true,
      width: 150,
      cell: CriticalityCell,
      headerCell: HeaderCriticalityCell
    },
    {
      field: "type",
      title: `${localizationService.toLanguageString("custom.type",enMessages.custom.type)}`,
      cell: TypeCell,
      width: 150,
      show: true,
      headerCell: HeaderTypeCell
    },

    {
      field: "enabled",
      title: `${localizationService.toLanguageString("custom.enabled",enMessages.custom.enabled)}`,
      cell: EnabledCell,
      show: true,
      width: 150,
    },
    {
      field: "alertStatus.isTriggered",
      title: localizationService.toLanguageString("custom.triggered", enMessages.custom.triggered),
      cell: TriggeredCell,
      show: true,
      width: 150,
    },
  ];

  useEffect(() => {
    let updatedState = createDataState(dataState);
    setResult(updatedState.result);
    setDataState(updatedState.dataState);
  }, [props.alerts]);

  const onDelete = () => {
    if (alertToDelete != null) {
      props.onDelete(alertToDelete);
    }

    setAlertToDelete(null);
  };

  const getExcelExportData = () => {
    return process(props.alerts, {
      filter: dataState.filter,
      sort: dataState.sort,
    }).data;
  };

  const [fieldsData, setFieldsData] = useState<string[]>(["name", "description", "assignedTo.name", "assignedTo.details", "criticality", "type"]);
  const [searchText, setSearchText] = useState<TextBoxProps["value"]>("");
  const [value, setValue] = useState<Item[]>(ColumnsOptions);
  
  useEffect(() => {
  
    if (searchText !== undefined && searchText !== null) {
      let filters: FilterDescriptor[] = [];

      fieldsData.map((field) => filters.push({field: field, operator: "contains", value: searchText}))

      let compositeFilter: CompositeFilterDescriptor = {
        logic: "or",
        filters: filters,
      };

      var newState = dataState; 
      newState.filter = compositeFilter;

      let updatedState = createDataState(newState);
      setResult(updatedState.result);
      setDataState(updatedState.dataState);
    }

   }, [fieldsData, searchText]);

  const handleFieldChange = (event: MultiSelectChangeEvent) => {
    const values = event.target.value;
        
    var selectedFields : string[] = []; 

    values.map((selectedItem) => {
      return selectedItem.fields?.map((field) => {
        return selectedFields.push(field);
      })
    })
      setFieldsData(selectedFields);
      setValue(values)
      
  };

  const handleSeachChange = React.useCallback((event) => setSearchText(event.target.value), []);

  const handleClear = React.useCallback(() => {
    setSearchText("");
  }, []);

  return (
    <div>
      <ExcelExport
        data={getExcelExportData()}
        ref={_export}
        collapsible={true}
        fileName={localizationService.toLanguageString("custom.alerts", enMessages.custom.alerts)}
      >
        <ExcelExportColumn field="name" title={localizationService.toLanguageString("custom.alert", enMessages.custom.alert)} />
        <ExcelExportColumn field="description" title={localizationService.toLanguageString("custom.description", enMessages.custom.description)} />
        <ExcelExportColumn field="assignedTo.name" title={localizationService.toLanguageString("custom.assignedTo", enMessages.custom.assignedTo)} />
        <ExcelExportColumn field="assignedTo.details" title={`${localizationService.toLanguageString("custom.location", enMessages.custom.location)} > ${localizationService.toLanguageString("custom.asset", enMessages.custom.asset)}`} />
        <ExcelExportColumn field="criticality" title={localizationService.toLanguageString("custom.criticality", enMessages.custom.criticality)} />
        <ExcelExportColumn field="type" title={localizationService.toLanguageString("custom.type", enMessages.custom.type)} />
        <ExcelExportColumn field="enabled" title={localizationService.toLanguageString("custom.enabled", enMessages.custom.enabled)} />
        <ExcelExportColumn field="alertStatus.isTriggered" title={localizationService.toLanguageString("custom.triggered", enMessages.custom.triggered)} />
      </ExcelExport>

      <Grid
        key={"AlertsTable"}
        data={result}
        scrollable={"virtual"}
        take={30}
        rowHeight={50}
        onDataStateChange={dataStateChange}
        {...dataState}
        sortable={{
          mode: "multiple",
        }}
        style={{
          height: "690px",
        }}
      >
        <GridToolbar className="">
        
          <div className="w-full flex justify-between items-center">
            <div>
              <TextBox placeholder="Search..." onChange={handleSeachChange} style={{width:300, height: 40}}
              value={searchText} 
              suffix={() => (
                <React.Fragment>
                        {searchText !== "" && (
                          <InputClearValue onClick={handleClear}>
                            <Icon name="x" />
                          </InputClearValue>
                        )}
                        <InputSeparator />
                      </React.Fragment>
              )} />
              <MultiSelect
                data={ColumnsOptions}
                textField="text"
                onChange={handleFieldChange}
                value={value}
                size={"large"}
                style={{width:470, overflow:"auto", marginLeft: "5px",}}
              />
            </div>
            <Tooltip anchorElement="target" position="bottom">
              <Button
                title={localizationService.toLanguageString('custom.exportToExcel', enMessages.custom.exportToExcel)}
                onClick={excelExport}
                themeColor={"base"}
                icon="download"
                style={{ marginRight: "10px" }}
                
              />
            </Tooltip>
          </div>
        </GridToolbar>

        {columns.map(
          (column, idx) =>
            column.show && (
              <GridColumn
                key={idx}
                field={column.field}
                title={column.title}
                filter={column.filter}
                cell={column.cell}
                width={column.width}
                filterable={column.filterable}
                headerCell={column.headerCell}
              />
            )
        )}

        {currentUserRole !== "GeneralUser" && (
          <GridColumn
            title={localizationService.toLanguageString(
              "custom.actions",
              enMessages.custom.actions
            )}
            width="150px"
            sortable={false}
            filterable={false}
            cell={(cellProps) => {
              return (
                <td className="" style={{ textAlign: "left" }}>
                  <Button
                    className="mr-1"
                    themeColor={"primary"}
                    icon="edit"
                    onClick={async () => {
                      props.alertToUpdateHandler(cellProps.dataItem.id);
                    }}
                    title={localizationService.toLanguageString(
                      "custom.edit",
                      enMessages.custom.edit
                    )}
                  />
                  <Button
                    className=""
                    icon="delete"
                    themeColor={"primary"}
                    onClick={async () => {
                      setAlertToDelete(cellProps.dataItem);
                    }}
                    title={localizationService.toLanguageString(
                      "custom.delete",
                      enMessages.custom.delete
                    )}
                  />
                   <Button
                    className="ml-1"
                    icon="reset"
                    themeColor={"primary"}
                    disabled={!cellProps.dataItem.alertStatus.isTriggered}
                    onClick={async () => {
                      props.resetAlert(cellProps.dataItem.id, cellProps.dataItem);
                    }}
                    title={"Reset"}
                  />
                </td>
              );
            }}
          />
        )}
      </Grid>

      {alertToDelete != null && (
        <DeleteDialog
          title={localizationService.toLanguageString(
            "custom.deleteAlert",
            enMessages.custom.deleteAlert
          )}
          deletingItemLabel={alertToDelete?.name}
          itemCount={0}
          open={alertToDelete != null}
          onClose={() => setAlertToDelete(null)}
          deleteCallback={onDelete}
          children={undefined}
        />
      )}
    </div>
  );
}
