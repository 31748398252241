/**
 * This component handles high level logic for events, providing implementation of queries and mutations
 * via the eventApiService. It gathers data and provides it to the event table for display when all relevant
 * data has been retrieved.
 *
 * Date: 10/03/2022
 */
import { ReactElement, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { ReactQueryKeys } from "../../../Constants/ClientRoutingConstants";
import ErrorCard from "../../Shared/Components/Cards/ErrorCard";
import LoadingSpinner from "../../Shared/Components/LoadingSpinner";
import eventApiService from "../Utilities/EventsApiService";
import { MachineReadDto } from "../../../Models/Machine/MachinesReadDto";
import AreaReadDto from "../../../Models/Area/AreaReadDto";
import { ConsumableReadDto } from "../../../Models/Consumable/ConsumableReadDto";
import consumablesApiService from "../../Consumables/Utilities/ConsumablesApiService";
import areasApiService from "../../Area/Utilities/AreasApiService";
import machinesApiService from "../../Machines/Utilities/MachinesApiService";
import { useLocalization } from '@progress/kendo-react-intl';
import EndpointsEventTable from "../Components/EndpointsEventTable";
import EndpointReadDto from "../../../Models/Endpoint/EndpointReadDto";
import endpointsApiService from "../../Endpoints/Utilities/EndpointsApiService";
import FlowEventDto from "../../../Models/Endpoint/FlowEventDto";

interface Props {
  eventFilter: any
}

export default function EndpointsEventsPage(props: Props): ReactElement {

  const localizationService = useLocalization();
  const eventFilter = props.eventFilter

  const eventsQuery = useQuery<Array<FlowEventDto>>([ReactQueryKeys.AllEndpointEventsQuery, eventFilter], async () => {
    return eventApiService.getAllEndpointsEvents(eventFilter);
  });

  const endpointsQuery = useQuery<Array<EndpointReadDto>>(ReactQueryKeys.AllEndpointsQuery, async () => {
    return endpointsApiService.getAllEndpoints();
  });

  const machinesQuery = useQuery<Array<MachineReadDto>>(ReactQueryKeys.AllMachinesQuery, async () => {
    return machinesApiService.getAllMachines();
  });

  const areasQuery = useQuery<Array<AreaReadDto>>(ReactQueryKeys.AllAreasQuery, async () => {
    return areasApiService.getAllAreas();
  });

  const consumablesQuery = useQuery<Array<ConsumableReadDto>>(ReactQueryKeys.AllConsumablesQuery, async () => {
    return consumablesApiService.getAllConsumables();
  });

  /**
   * This function prepares data for the event table, including injecting additional metadata.
   */
  const eventData = useMemo(() => {
    const endpointSerialtoName: { [id: string]: string } = {};
    const consumableSerialtoId: { [id: string]: string } = {};
    const consumableIdtoName: { [id: string]: string } = {};
    const machineIdToName: { [id: string]: string } = {};
    const areaIdToName: { [id: string]: string } = {};
    const machineSerialtoId: { [id: string]: string } = {};
    const areaSerialtoId: { [id: string]: string } = {};

    machinesQuery.data?.forEach((machine) => {
      machineIdToName[machine.id] = machine.name;
    });

    areasQuery.data?.forEach((area) => {
      areaIdToName[area.id] = area.name;
    });
    
    endpointsQuery.data?.forEach((point) => {
      endpointSerialtoName[point.endpointDeviceSerialNumber] = point.name;
      consumableSerialtoId[point.endpointDeviceSerialNumber] = point.consumableId;
      machineSerialtoId[point.endpointDeviceSerialNumber] = point.machineId;
      areaSerialtoId[point.endpointDeviceSerialNumber] = point.areaId;
    });

    const parcialResults = eventsQuery.data?.map((event) => {
      return {
        ...event,
        endpointName: endpointSerialtoName[event.serialNo] ?? null,
        consumableId: consumableSerialtoId[event.serialNo] ?? null,
        machineId: machineSerialtoId[event.serialNo] ?? null,
        areaId: areaSerialtoId[event.serialNo] ?? null,
        timestampWithoutTime: new Date(new Date(event.timestamp).setHours(0,0,0)),
        syncedWithoutTIme: new Date(new Date(event.synced).setHours(0,0,0)),
        volumeDispensedRound: Number(event.volumeDispensed).toFixed(2)
      } 
    });

    consumablesQuery.data?.forEach((consumable) => {
      consumableIdtoName[consumable.id] = consumable.name;
    });

    const results = parcialResults?.map((event) => {
      return {
        ...event,
        consumableName: consumableIdtoName[event.consumableId] ?? null,
        machineName: machineIdToName[event.machineId] ?? null,
        areaName: areaIdToName[event.areaId] ?? null,
      } 
    });

    return results;
  }, [eventsQuery.data, areasQuery.data, consumablesQuery.data, machinesQuery.data, endpointsQuery.data]);

  //If the events filters are modified then refetch events.
  useEffect(() => {
    eventsQuery.refetch();
  }, [eventFilter]);

  /** RENDER **/
  if (
    eventsQuery.isLoading ||
    areasQuery.isLoading ||
    endpointsQuery.isLoading ||
    machinesQuery.isLoading ||
    consumablesQuery.isLoading ||
    eventsQuery.isFetching ||
    areasQuery.isFetching ||
    endpointsQuery.isFetching ||
    machinesQuery.isFetching ||
    consumablesQuery.isFetching
  ) {
    return <LoadingSpinner />;
  } else {
    if (eventsQuery.error || areasQuery.error || endpointsQuery.error || machinesQuery.error || consumablesQuery.error ) {
      return <ErrorCard />;
    } else {
      return (
        <div className="">
          <EndpointsEventTable
            events={eventData!}       
            />
         </div>
      );
    }
  }
}
