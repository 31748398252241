import { MultiSelectTreeChangeEvent, getMultiSelectTreeValue, MultiSelectTree } from "@progress/kendo-react-dropdowns";
import { useLocalization } from "@progress/kendo-react-intl";
import React, { useEffect } from "react";
import { enMessages } from "../../../messages/en-US";
import { expandedState, processMultiSelectTreeData,  } from "../Pages/MultiselectTree-Data-Operations";

interface Props {
  data: any[] | undefined,
  disabled: boolean,
  onChange: (selectedData: any) => void,
  tag: string,
  initialData: any[],
  textField: string
}

export default function ReportFilter(props: Props) {

  const localizationService = useLocalization();

  const [reportList, setrReportList] = React.useState<any[]>([]);
  const [selectedItems, setSelectedItems] = React.useState<any[]>([]);
  const [filterList, setFilterList] = React.useState(null);
  const [expanded, setExpanded] = React.useState<any[]>([]);

  useEffect(() => {
    props.initialData[0].items = props.data !== undefined ? props.data.slice() : new Array
    const selectAll = props.initialData[0].items.slice()
    props.initialData[0].items.shift()
    selectAll.unshift({name: 'Select All', id: 1})

    setrReportList(props.initialData)
    setSelectedItems(selectAll)
    
    setExpanded([props.initialData[0][dataItemKey]])
  },[props.data])

  const dataItemKey = "id";
  const checkField = "checkField";
  const checkIndeterminateField = "checkIndeterminateField";
  const subItemsField = "items";
  const expandField = "expanded";
  const textField = props.textField;
  const fields = {
    dataItemKey,
    checkField,
    checkIndeterminateField,
    expandField,
    subItemsField,
  };

  const onChange= (event: MultiSelectTreeChangeEvent) => {
    const allSelected = getMultiSelectTreeValue(reportList, { ...fields, ...event, value: selectedItems})

    setSelectedItems(
      getMultiSelectTreeValue(reportList, {
        ...fields,
        ...event,
        value: selectedItems,
      })
    );

    setExpanded([props.initialData[0][dataItemKey]]);

    props.onChange(allSelected)
  }

  const onExpandChange = React.useCallback(
    (event) => setExpanded(expandedState(event.item, dataItemKey, expanded)),
    [expanded]
  );

  const treeData = React.useMemo(
    () =>
      processMultiSelectTreeData(reportList, {
        expanded: expanded,
        value: selectedItems,
        filter: filterList,
        ...fields,
      }),
    [expanded, selectedItems, filterList]
  );

  const onFilterChange = (event) => {
    setFilterList(event.filter)
  };

  return (
    <>
      <div className="w-full mr-2.5">
        <MultiSelectTree
          disabled={props.disabled}
          data={treeData}
          value={selectedItems}
          onChange={onChange}
          textField={textField}
          dataItemKey={dataItemKey}
          checkField={checkField}
          checkIndeterminateField={checkIndeterminateField}
          expandField={expandField}
          subItemsField={subItemsField}
          onExpandChange={onExpandChange}
          filterable={true}
          onFilterChange={onFilterChange}
          style={{maxHeight:'60px' , overflow:'auto',}}
          tags={
            [{ text: `${selectedItems.filter(item => item.name !== 'Select All').length} ${props.tag} ${localizationService.toLanguageString('custom.selected', enMessages.custom.selected)}`, data: [...selectedItems] }]
        }
        />
      </div>
    </>
  )
  
}