import {
    XyDataSeries,
    HorizontalLineAnnotation,
    ELabelPlacement,
    EAnnotationLayer,
    VerticalLineAnnotation,
    SciChartSurface,
    ZoomPanModifier,
    MouseWheelZoomModifier,
    ZoomExtentsModifier,
    RolloverModifier,
    RubberBandXyZoomModifier,
    EXyDirection,
    EExecuteOn,
    FastImpulseRenderableSeries,
    LegendModifier,
    EAutoRange,
    NumberRange,
    EAxisAlignment,
    NumericAxis,
    DateTimeNumericAxis,
    CursorModifier,
    FastLineRenderableSeries,
    EllipsePointMarker,
    IDataSeries,
    ISciChartSurfaceBase,
    UpdateSuspender,
    } from "scichart";
  import { IInitResult, TInitFunction } from "scichart-react";
  import { enMessages } from "../../../../../messages/en-US";
  import { ColumnPaletteProviderPvA } from "../PaletteProvider";
  import moment from "moment";
  import { axisLabelDateFormatter } from "../../../../Shared/SciChart/SmartAxis/axisLabelDateFormatter";
  import { GetChartTheme, getTooltipDataTemplate } from "../../../../Shared/SciChart/ChartConfiguration";
import { LocalizationService } from "@progress/kendo-react-intl";
import { DateTickProvider } from "../../../../Shared/SciChart/SmartAxis/DateTickProvider";
import { SmartDateTZLabelProvider } from "../../../../Shared/SciChart/SmartAxis/SmartDateTZLabelProvider";
import { createDateTzConverter } from "../../../../Shared/SciChart/SmartAxis/timezones";
  
  export interface TActivityChartConfigResult<TSurface extends ISciChartSurfaceBase> extends IInitResult<TSurface> {
    setAutoRange: (value: boolean) => void
    setChartType: (barChartOn: boolean) => void
  }
  
  export type TActivityConfigFunc = TInitFunction<SciChartSurface, TActivityChartConfigResult<SciChartSurface>>;

  export interface Props {
    chartData: any;
  }

  export interface ChartOptions {
    isAutoRange: boolean;
    isBarChartOn: boolean;
    visibleRange: { min: number | undefined; max: number | undefined; };
    setVisibleRange: (range: any) => void;
    setVisibleSeriesActivity: (series: any, isChecked: boolean) => void;
    visibleSeriesActivity: { [key: string]: boolean;}
    aggregator: string;
  }
  
  export const getChartAPI = (props:Props, chartOptions:ChartOptions, localizationService: LocalizationService, currentLanguage: { locale: string; localeId: string; }) => {

    const endFilterDateUnix = moment(props.chartData.dateFilter.endDate).unix();
    const startFilterDateUnix = moment(props.chartData.dateFilter.startDate).unix();

    const theme = GetChartTheme();  
    let setAutoRangeCallback: (arg0: boolean) => void;
    let changeChartTypeCallback: (arg0: boolean) => void;
  
    const configAfterInit = (initResult) => {
        setAutoRangeCallback = initResult.setAutoRange;
        changeChartTypeCallback = initResult.setChartType;
    } ;
  
    const setAutoRange = (value: boolean) => {
        if (setAutoRangeCallback !== undefined) {
            setAutoRangeCallback(value);
        }
    };

    const setChartType = (barChartOn: boolean) => {
        if (changeChartTypeCallback !== undefined) {
            changeChartTypeCallback(barChartOn);
        }
    };

    const initChart : TActivityConfigFunc = async (rootElement: string | HTMLDivElement) => {
        const {sciChartSurface, wasmContext} = await SciChartSurface.create(rootElement, {theme: theme});
  
        // #region Setup the XAxis
        const xAxis = new DateTimeNumericAxis(wasmContext, { 
          drawMajorBands: false, 
          drawMinorGridLines: false, 
          drawMajorGridLines: false, 
          visibleRange: new NumberRange(chartOptions.visibleRange?.min, chartOptions.visibleRange?.max),
          visibleRangeLimit: new NumberRange(startFilterDateUnix, endFilterDateUnix),
          growBy: new NumberRange(0.05, 0.05),
          labelStyle: {fontFamily:"Roboto", fontSize:12,}},
          );
  
        // Overrides getDeltaFromRange to get nice major and minor deltas for the ticks
        xAxis.deltaCalculator.getDeltaFromRange = axisLabelDateFormatter.getDeltaFromRange;
  
        const updateSettings = (xAxis: NumericAxis) => {
          const timezone = moment.tz.guess();
          // Calculate ticks which respect the time zone
          xAxis.tickProvider = new DateTickProvider(wasmContext, timezone);
          // Draws nice axis labels timezone and locale specific
          xAxis.labelProvider = new SmartDateTZLabelProvider(timezone,true, currentLanguage?.localeId);
          const dateTzConverter = createDateTzConverter(timezone);
          // This is needed for Cursor and Rollover chart modifiers
          xAxis.labelProvider.formatCursorLabel = timestamp =>
              chartOptions.aggregator === 'none' ? axisLabelDateFormatter.toFullDateTime(timestamp, dateTzConverter, currentLanguage?.localeId) : axisLabelDateFormatter.toFullDateOnly(timestamp, dateTzConverter, currentLanguage?.localeId);
          };
          // Setting timezone to local system
          updateSettings(xAxis);
  
        xAxis.visibleRangeChanged.subscribe((args) => { chartOptions.setVisibleRange(args?.visibleRange); });

        sciChartSurface.xAxes.add(xAxis);
        // #endregion

        // #region Setup the YAxis
        const yAxis = new NumericAxis(wasmContext,{
          axisAlignment: EAxisAlignment.Right, 
          autoRange: EAutoRange.Never,
          drawMajorBands: false,
          drawMinorGridLines: false, 
          drawMajorGridLines: true, 
          labelPostfix: " cc", 
          labelPrecision: 2,
          growBy: new NumberRange(0, 0.2),
          labelStyle: {fontFamily:"Roboto", fontSize:12}});

        sciChartSurface.yAxes.add(yAxis);

        // #endregion

        // #region Setup the Chart Modifers
        const legend = new LegendModifier({
          placementDivId: "legend-activity-flow",
          showCheckboxes: true,
          showSeriesMarkers: true,
          showLegend: true,
          isCheckedChangedCallback(series, isChecked) {
            chartOptions.setVisibleSeriesActivity(series.id, isChecked);
          },
        });
        sciChartSurface.chartModifiers.add(legend);
 
        sciChartSurface.chartModifiers.add(
          new CursorModifier({ showAxisLabels: false,  id: "ActivityModifierGroup" }),
          new ZoomPanModifier({xyDirection: EXyDirection.XDirection}),
          new MouseWheelZoomModifier({xyDirection:EXyDirection.XDirection}),
          new ZoomExtentsModifier({xyDirection:EXyDirection.XDirection}),
          new RolloverModifier({tooltipDataTemplate: getTooltipDataTemplate, id: "ActivityRollOver" }),
          new RubberBandXyZoomModifier({ executeOn: EExecuteOn.MouseRightButton }),
        );
        // #endregion

        // #region Create the Data Series 

        const volumeDispensedDataSeries = new XyDataSeries(wasmContext, {
          xValues: props.chartData.timestamps, 
          yValues: props.chartData.volumeDispensedData, 
          dataSeriesName: `${localizationService.toLanguageString('custom.volumeDispensed', enMessages.custom.volumeDispensed)}` 
        });

        const filterPeriodDataSeries = new XyDataSeries(wasmContext, {
          xValues: [startFilterDateUnix, endFilterDateUnix],
          yValues: [0,0], 
          dataSeriesName: "filterPeriodSeries"
        });
        // #endregion

        // #region Setup the RenderableSeries
  
        // Create a series that represents the entire filter period so the chart displays the entire range. 
        const filterPeriodSeries = new FastLineRenderableSeries(wasmContext, {
          id: "FilterPeriodSeriesId", 
          isVisible: false,
          dataSeries: filterPeriodDataSeries
        });  
        sciChartSurface.renderableSeries.add(filterPeriodSeries);
        legend.includeSeries(filterPeriodSeries, false);

        const createVolumeDispensedSeries = (barChartOn: boolean, isVisible: boolean, dataSeries: IDataSeries) => {
  
          const volumeDispensed = barChartOn === true ? new FastImpulseRenderableSeries(wasmContext, {
            stroke: "rgba(50, 53, 67,1)", 
            fill: "rgba(50, 53, 67,1)", 
            strokeThickness:props.chartData.strokeThickness,
            paletteProvider: new ColumnPaletteProviderPvA(),
            id: "VolumeDispensedId",
            dataSeries: dataSeries,
            isVisible: isVisible
          }) : new FastLineRenderableSeries(wasmContext, {
            dataSeries: dataSeries,
            stroke: "rgba(50, 53, 67,1)", 
            id: "VolumeDispensedId",
            isVisible: isVisible,
            pointMarker:  new EllipsePointMarker(wasmContext, {width: 6, height: 6, stroke: "rgba(50, 53, 67,1)", fill: "rgba(50, 53, 67,1)" 
          }),
          })
  
          volumeDispensed.rolloverModifierProps.tooltipTextColor = 'White'
          volumeDispensed.rolloverModifierProps.showRollover = true
          volumeDispensed.rolloverModifierProps.markerColor = 'red'
      
          return volumeDispensed;
        }

        const volumeDispensed = createVolumeDispensedSeries(chartOptions.isBarChartOn, chartOptions.visibleSeriesActivity.VolumeDispensedId, volumeDispensedDataSeries);
        sciChartSurface.renderableSeries.add(volumeDispensed);

        const lastSeenSeries = new FastImpulseRenderableSeries(wasmContext, {
          stroke: "grey", 
          fill: "grey", 
          strokeThickness: props.chartData.strokeThickness,
          id: "lastSeenId",
          dataSeries: new XyDataSeries(wasmContext, {
            xValues: [props.chartData.lastSeen], 
            yValues: [0], 
            dataSeriesName: `${localizationService.toLanguageString('custom.lastSeen', enMessages.custom.lastSeen)}` 
          })
        });

       lastSeenSeries.rolloverModifierProps.tooltipTextColor = 'White'
        lastSeenSeries.rolloverModifierProps.showRollover = true

        // #endregion

        // #region Setup Annotations

        // Add 0 line annotation
        const horizontalLine = new HorizontalLineAnnotation({
          labelPlacement: ELabelPlacement.Axis,
          stroke: 'grey',
          strokeThickness: 3,
          y1: 0,
          annotationLayer: EAnnotationLayer.BelowChart
          });
          sciChartSurface.annotations.add(horizontalLine)
    
          // Add LastSeen line annotation
          const lastSeenLine = new VerticalLineAnnotation({
            labelPlacement: ELabelPlacement.TopRight,
            stroke: 'grey',
            strokeThickness: 3,
            strokeDashArray: [12,5],
            x1: props.chartData.lastSeen,
            showLabel:true,
            labelValue: 'Last Seen',
            axisLabelFill: 'grey',
            annotationLayer: EAnnotationLayer.BelowChart
            });
          sciChartSurface.annotations.add(lastSeenLine)

          if(props.chartData.requirements?.lastSeen >= startFilterDateUnix && props.chartData.requirements?.lastSeen <= endFilterDateUnix) {
            sciChartSurface.annotations.add(lastSeenLine)
            sciChartSurface.renderableSeries.add(lastSeenSeries);
            legend.includeSeries(lastSeenSeries, false);
          }
  
          // #endregion

        // #region Setup the API's 
        const setAutoRange = (value: boolean) => {

          const yAxisCurrent = sciChartSurface.yAxes.get(0);
            
          if (yAxisCurrent !== undefined) {
            if (value) {
              yAxisCurrent.autoRange = EAutoRange.Always;
            } else {
              yAxisCurrent.autoRange = EAutoRange.Never;

              const visibleSeries = sciChartSurface.renderableSeries.asArray().filter(x => x.isVisible);

              if (visibleSeries.length === 0) {
                yAxisCurrent.visibleRange = new NumberRange(0, 10 * 1.20);
              } else {

                var minYRange = 0
                var maxYRange = 0;
                
                visibleSeries.forEach((series) => {
                  const dataSeries = series.dataSeries as XyDataSeries;
                  const yRange = dataSeries.getWindowedYRange(dataSeries.xRange, true, false);
                
                  if (yRange !== undefined) {
                    if (yRange.min < minYRange) {
                      minYRange = yRange.min;
                    }

                    if (yRange.max > maxYRange) {
                      maxYRange = yRange.max;
                    }
                  }
                });

                yAxisCurrent.visibleRange = new NumberRange(minYRange, maxYRange * 1.20);
              }
            }
          }
        };
        setAutoRange(chartOptions.isAutoRange);

        const setChartType = (barChartOn: boolean) => {
          UpdateSuspender.using(sciChartSurface, () => {
            const index = sciChartSurface.renderableSeries.asArray().findIndex(x => x.id === "VolumeDispensedId");
            const newVolumeDispensedSeries = createVolumeDispensedSeries(barChartOn, volumeDispensed.isVisible, volumeDispensed.dataSeries);
            sciChartSurface.renderableSeries.set(index, newVolumeDispensedSeries);
          });
        }
        // #endregion
  
      return { sciChartSurface: sciChartSurface, setAutoRange, setChartType};
    }

    return {initChart, configAfterInit, setAutoRange, setChartType};
}