import { ApiRoutes } from "../../../Constants/ApiRoutingConstants";
import axios, { AxiosInstance } from "axios";
import authorizeService from "../../Identity/Utilities/AuthorizeService";
import AreaReadDto from "../../../Models/Area/AreaReadDto";
import AreaCreateDto from "../../../Models/Area/AreaCreateDto";
import AreaUpdateDto from "../../../Models/Area/AreaUpdateDto";
/**
 * This service provides implementations for http calls to the api pertaining to area crud functionality
 */

class AreasApiService {
  axiosInstance: AxiosInstance;
  constructor() {
    //Subscribe to identity store the get token
    this.axiosInstance = axios.create({
      //TODO: This should be updated to an env. variable in the future (once app is placed behind api gateway)
      baseURL: process.env.REACT_APP_ASSET_SERVICE_URL,
    });
  }

  async getAllAreas() {
    let response = await this.axiosInstance({
      method: "GET",
      url: `${ApiRoutes.Areas}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data;
  }

  async createArea(area: AreaCreateDto) {
    let response = await this.axiosInstance({
      method: "POST",
      url: `${ApiRoutes.Areas}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: area,
    });
    return response.data;
  }

  async updateArea(area: AreaCreateDto, areaId: string) {
    let response = await this.axiosInstance({
      method: "PUT",
      url: `${ApiRoutes.Areas}/${areaId}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: area,
    });
    return response.data;
  }

  async updateAreas(areas: Array<AreaUpdateDto>) {
    let response = await this.axiosInstance({
      method: "PUT",
      url: `${ApiRoutes.Areas}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: { areas },
    });
    return response.data;
  }

  async deleteAreas(deleteAreaIds: Array<string>) {
    let response = await this.axiosInstance({
      method: "DELETE",
      url: `${ApiRoutes.Areas}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: {
        ids: deleteAreaIds,
      },
    });
    return response.data;
  }

  static get instance() {
    return areasApiService;
  }
}

const areasApiService = new AreasApiService();
export default areasApiService;
