import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { useLocalization } from '@progress/kendo-react-intl';
import { Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { enMessages } from "../../../../messages/en-US";
import AdminAuthTokenCreateDto from "../../../../Models/AuthToken/AdminAuthTokenCreateDto";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Icon } from "@chakra-ui/icons";
import AdminAuthTokenReadDto from "../../../../Models/AuthToken/AdminAuthTokenReadDto";


interface Props {
  createAuthToken: (description: string) => void;
  onClose: () => void;
}

export default function AuthTokenCreateModal(props: Props) {

  const localizationService = useLocalization();

  const handleSubmit = (dataItem) => {
    props.createAuthToken(dataItem.description)
    props.onClose()
  };
  

  return (
    <div>
      <Dialog title={"Create Auth Token"} 
        onClose={props.onClose}  className="notes-dialog">
          <Form
          onSubmit={handleSubmit}
          // initialValues={['test']}
          render={(formRenderProps) => (
          <FormElement style={{width: 500,}}>
            <fieldset className={"k-form-fieldset"} style={{padding:'10px'}}>
              <legend>
                Add a description to help you and your team know where this token is used:
              </legend>
              <div className="mb-3 flex gap-2 items-center">
                <label>Description:</label>
                <Field
                  name={"description"}
                  type={"text"}
                  component={Input}
                  height={10}
                  style={{height:35}}
                />
              </div>
            </fieldset>
            <div className="p-2" style={{textAlign:'right', borderTop:'1px solid #EBEBEB'}}>
              <Button
                type={"submit"}
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                style={{background:'#35AC00', color:'white',width:' 62px', marginRight:'5px' }}

                disabled={!formRenderProps.allowSubmit}
                // onClick={() => {props.confirmationTokenDialog(true);}}
              >
                OK
              </Button>
              <Button onClick={props.onClose}>{localizationService.toLanguageString('custom.cancel', enMessages.custom.cancel)}</Button>
            </div>
          </FormElement>
          )}
        />
      </Dialog>
    </div>
  );
}
