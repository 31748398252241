import * as React from "react";

import { DateInput, DateInputProps } from "@progress/kendo-react-dateinputs";
import { enMessages } from "../../../messages/en-US";
import { useLocalization } from "@progress/kendo-react-intl";

const CustomEndDateInput = (props: DateInputProps) => {
  const localizationService = useLocalization();

  return (
    <div style={{ display: "flex"}}>
        <DateInput {...props} label={localizationService.toLanguageString('custom.endDate', enMessages.custom.endDate)} />
    </div>
  );
};

export default CustomEndDateInput;