import { ReactElement } from "react";
import { useQuery, useQueryClient } from "react-query";
import AdministrationSiteTable from "../Components/Tables/AdministrationSiteTable";
import { ReactQueryKeys } from "../../../Constants/ClientRoutingConstants";
import LoadingSpinner from "../../Shared/Components/LoadingSpinner";
import ErrorCard from "../../Shared/Components/Cards/ErrorCard";
import administrationQueryService from "../Utilities/AdministrationAPIService";
import { SiteReadDto } from "../../../Models/Site/SiteReadDto";
import { SiteCreateDto } from "../../../Models/Site/SiteCreateDto";
import axios from "axios";
import { ApiRoutes } from "../../../Constants/ApiRoutingConstants";
import { useToast } from "@chakra-ui/react";
import { SiteUpdateDto } from "../../../Models/Site/SiteUpdateDto";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";
import { useWindowSize } from "usehooks-ts";

interface Props {}

export default function SuperAdministrationSitesPage({}: Props): ReactElement {
  const client = useQueryClient();
  const toast = useToast({
    position: "bottom",
    duration: 5000,
    isClosable: true,
  });

  const localizationService = useLocalization();
  //get window size
  const { width, height } = useWindowSize()

  //Query for sites
  const sitesQuery = useQuery<Array<SiteReadDto>>(ReactQueryKeys.AllSitesQuery, async () => {
    return await administrationQueryService.getAllSites();
  });

  const createSite = async (values: any, setStatus: any) => {
    //Post data
    try {
      //Attempt to get response
      let response = await administrationQueryService.createSite(values);

      //Now update the gui to show the new data
      client.refetchQueries(ReactQueryKeys.AllSitesQuery);
      toast({
        title: `${localizationService.toLanguageString('custom.success', enMessages.custom.success)}`,
        description: `${localizationService.toLanguageString('custom.accountCreated', enMessages.custom.accountCreated)}`,
        status: "success",
      });
      return true;
    } catch (e: any) {
      toast({
        title: `${localizationService.toLanguageString('custom.error', enMessages.custom.error)}`,
        description: `${localizationService.toLanguageString('custom.accountCreatedError', enMessages.custom.accountCreatedError)}`,
        status: "error",
      });
      return false;
    }
  };

  const updateSite = async (sites: Array<SiteUpdateDto>) => {
    //Post data
    try {
      //Attempt to get response
      let response = await administrationQueryService.updateSite(sites[0]);

      //Now update the gui to show the new data
      await client.refetchQueries(ReactQueryKeys.AllSitesQuery);
      toast({
        title: `${localizationService.toLanguageString('custom.success', enMessages.custom.success)}`,
        description: `${localizationService.toLanguageString('custom.accountCreated', enMessages.custom.accountCreated)}`,
        status: "success",
      });
    } catch (e: any) {
      toast({
        title: `${localizationService.toLanguageString('custom.error', enMessages.custom.error)}`,
        description: `${localizationService.toLanguageString('custom.accountCreatedError', enMessages.custom.accountCreatedError)}`,
        status: "error",
      });
      throw e;
    }
  };

  const RenderQuery = () => {
    if (sitesQuery.error) {
      return <ErrorCard />;
    } else {
      return (
        <div  className="m-5" id="SitesPage">
          {/* TODO: Abstract into reusable 'PageTitle' component */}
          <div>
            <h1 className="gb-h1">{localizationService.toLanguageString('custom.manageAccounts', enMessages.custom.manageAccounts)}</h1>
            <h2 className="gb-text-normal mb-6">{localizationService.toLanguageString('custom.manageAccountsText', enMessages.custom.manageAccountsText)}</h2>
            <AdministrationSiteTable updateSite={updateSite} createSite={createSite} sites={sitesQuery.data ?? []} 
            height={height - 200}
            />
          </div>
        </div>
      );
    }
  };

  return sitesQuery.isLoading || sitesQuery.isFetching ? <LoadingSpinner /> : <RenderQuery />;
}
