import { ReactElement } from "react";
import { useQuery } from "react-query";
import { ReactQueryKeys } from "../../../Constants/ClientRoutingConstants";
import ErrorCard from "../../Shared/Components/Cards/ErrorCard";
import LoadingSpinner from "../../Shared/Components/LoadingSpinner";
import eventApiService from "../Utilities/EventsApiService";
import { useLocalization } from '@progress/kendo-react-intl';
import EndpointTemperatureEventsTableDto from "../../../Models/Endpoint/EndpointTemperatureEventsTableDto";
import TemperatureEventTable from "../Components/TemperatureEventTable";

interface Props {
  eventFilter: any
}

export default function TemperatureEventsPage(props: Props): ReactElement {

  const localizationService = useLocalization();
  const eventFilter = props.eventFilter

  const temperatureEventsQuery = useQuery<EndpointTemperatureEventsTableDto[]>([ReactQueryKeys.AllTemperatureEventsQuery, eventFilter], async () => {
    return eventApiService.getAllTemperatureEvents(eventFilter);
  });

  if (temperatureEventsQuery.isLoading || temperatureEventsQuery.isFetching) {
    return <LoadingSpinner />;
  } else {
    if (temperatureEventsQuery.error ) {
      return <ErrorCard />;
    } else {
      return (
        <div className="">
          <TemperatureEventTable
            events={temperatureEventsQuery.data!}       
            />
         </div>
      );
    }
  }
}
