/**
 * Defines a component that renders a filter cell for a specific date field, provides a simpler version of
 * the provided kendo table filter to help save usage of screen real estate and simplify use.
 *
 * Author: Max Wroe
 * Date : 04/04/2022
 */

import { DatePicker, DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { GridFilterCellProps } from "@progress/kendo-react-grid";
import { Input, InputChangeEvent } from "@progress/kendo-react-inputs";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../../messages/en-US";

export const CustomDateFilterCell: React.ComponentType<GridFilterCellProps> = (props: any) => {

  const localizationService = useLocalization();
  let hasValue: any = (value: any) => Boolean(value && value !== props.defaultItem);

  const onChange = (event: DatePickerChangeEvent) => {
    hasValue = hasValue(event.target.value);
    props.onChange({
      value: hasValue ? event.target.value : "",
      operator: hasValue ? "gt" : "",
      syntheticEvent: event.syntheticEvent,
    });
  };

  return (
    <div className="k-filtercell">
      <DatePicker
        format={"dd/MM/yyyy"}
        onChange={onChange}
        value={props.value || props.defaultItem}
        placeholder={localizationService.toLanguageString('custom.search', enMessages.custom.search)}
      />
    </div>
  );
};
