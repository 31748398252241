import { useEffect, useMemo, useState } from 'react';
import { useQueries, useQuery } from 'react-query';
import moment from 'moment';
import LoadingSpinner from '../Components/ProcessingSpinner';
import endpointsApiService from '../../Endpoints/Utilities/EndpointsApiService';
import EndpointReadDto from '../../../Models/Endpoint/EndpointReadDto';
import { ReactQueryKeys } from '../../../Constants/ClientRoutingConstants';
import { Calendar, CalendarProps, DatePicker, end } from '@progress/kendo-react-dateinputs';
import { EventFilter } from '../../Events/Utilities/EventsApiService';
import EndpointReportTable from '../Components/Endpoints/EndpointsReportTable';
import { enMessages } from '../../../messages/en-US';
import { useLocalization } from '@progress/kendo-react-intl';
import treeListApiService from '../../TreeList/Utilities/TreeListApiService';
import useChartStore from '../../../Zustand/chartStore';

interface Props {}

export default function EndpointReportPage(props: Props, ) {
  const localizationService = useLocalization();
   // Disable Fast Navigational Bar until this critical bug is fixed by Telerik (https://github.com/telerik/kendo-react/issues/1566)
  const CustomCalendar = (props: CalendarProps) => {
    return <Calendar {...props} navigation={false} />
  };

  // Use lazy initial state to ensure the function is only executed once on the initial render
  const [dateFilter, setDateFilter] = useState<EventFilter>(() => {
    let initalState: EventFilter = {
    endDate: moment().endOf('day').toDate(), //From now
    startDate: moment().subtract(30,"days").startOf('day').toDate()};
    return initalState;
  });

  // Reset the visible range when page is loaded
  const setVisibleRangeChartStore = useChartStore(state => state.setVisibleRange);

  useEffect(() => {
    setVisibleRangeChartStore(undefined, undefined);
  }, [])

  const endpointsQuery = useQuery<Array<EndpointReadDto>>(ReactQueryKeys.AllEndpointsQuery, async () => {
    return endpointsApiService.getAllEndpoints();
  });

  const endpointsQueries = useQueries(
    endpointsQuery.data?.map((endpoint) => ({
      queryKey: ['endpoint', endpoint.id, dateFilter],
      queryFn: () => endpointsApiService.endpointsCompliance(endpoint.id, dateFilter), enabled: !!endpointsQuery.data,
    })) || []
  );

  const statusQueries = useQueries(
    endpointsQuery.data?.map((endpoint) => ({
      queryKey: ['statusChart', endpoint.id, dateFilter],
      queryFn: () => treeListApiService.getEndpointStatus(endpoint.id, dateFilter, 2), enabled: !!endpointsQuery.data
    })) || []
  );

  const endpointRequirements = useMemo(() => {
    endpointsQueries.forEach((endpoint, index) => {
      
      if (endpoint.data?.requirementSummary !== undefined && endpoint.data?.requirementSummary.requirementName === '') {
        endpoint.data.requirementSummary.requirementName = localizationService.toLanguageString('custom.noRequirement', enMessages.custom.noRequirement);      
      }
    })
    return endpointsQueries.map(endpoint => {
      const serialNumber = endpoint.data?.endpointSerialNumber === '' ? 'No Serial Number' : endpoint.data?.endpointSerialNumber;
      return {
        ...endpoint.data,
        locked: false,
        endpointNameSerialNumber: endpoint.data?.endpointName + " (" + serialNumber + ")",
        endpointSerialNumber: serialNumber,
      }}).filter(item => item?.timestamps?.length !== 0);
    }, [endpointsQueries])

  return (
    <div className='m-5'>
      <h2 className="gb-h2">{`${localizationService.toLanguageString('custom.endpointReport', enMessages.custom.endpointReport)}`}</h2>
      {/* Date Picker for returned range */}
      <div className="flex flex-wrap my-5">
        <div>
          <DatePicker calendar={CustomCalendar} max={dateFilter.endDate!}
            onChange={(date) => {
              setDateFilter((prev) => {
                return { ...prev, startDate: date.target.value };
              });
            }}
            value={dateFilter.startDate}
            className="w-72"
            disabled={endpointsQueries.some(result => result.isLoading)}
          />
        </div>
        <div className="ml-4">
          <DatePicker calendar={CustomCalendar} min={dateFilter.startDate!}
            onChange={(date) => {
              setDateFilter((prev) => {
                return { ...prev, endDate: date.target.value };
              });
            }}
            value={dateFilter.endDate}
            className="w-72"
            disabled={endpointsQueries.some(result => result.isLoading)}
            max={moment().endOf('day').toDate()}
          />
        </div>
      </div>

      {(endpointsQueries.some(result => result.isLoading || result.isFetching) || statusQueries.some(result => result.isLoading || result.isFetching) || endpointsQuery.isLoading || endpointsQuery.isFetching )? 
        <LoadingSpinner />
        : <EndpointReportTable key={`PlanVsActualChart`} requirements={endpointRequirements} dateFilter={dateFilter} statusData={statusQueries}/>}
    </div>
  );
}
