import { ReactElement } from "react";
import { Loader } from "@progress/kendo-react-indicators";

export default function ChartWaitLoader(): ReactElement {
  return (
    <>
      <div className="flex justify-center items-center h-screen ">
        <Loader size="medium" type={"pulsing"}/> 
      </div>
    </>
  );
}
