// import ThresholdChart from '../../../TreeList/Components/Charts/ThresholdChart';
import { Field } from "@progress/kendo-react-form";
import RightAmountComponent from './RightAmountComponent';
import { FormInput } from "./FormComponents";
import { nameValidator } from "../../Utilities/Validators";

export const RightAmount = (
  <div style={{height:'450px'}}>
     <Field
      key={'name'}
      id={'name'}
      name={'name'}
      label={"Requirement Name"}
      component={FormInput}
      validator={nameValidator}
    />
    <Field  key={"rightAmount"} name={"rightAmount"} component={RightAmountComponent}/>
  </div>
);