/**
 * This component renders the header bar at the top of the sites layout, it displays a link to the home page (embedded in logo),
 * current user info such as name, role and current site and provides an option menu for profile settings (log out, change site, etc.)
 *
 * Author : Max Wroe
 * Date: 30/03/2022
 */

import { ReactElement } from "react";
import useIdentityStore from "../../../Zustand/identityStore";
// import { Link, useNavigate } from "react-router-dom";
import { AppBar, AppBarSection, AppBarSpacer, Avatar } from "@progress/kendo-react-layout";
import { DropDownButton } from "@progress/kendo-react-buttons";
import { BaseUrl, IdentityRoutes } from "../../../Constants/ApiRoutingConstants";
import { ClientRoutes } from "../../../Constants/ClientRoutingConstants";
import authService from "../../Identity/Utilities/AuthorizeService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import MiniLogo from "../../../Assets/logo-mini.png";
import LogoImage from "../../../Assets/logo-red.png";
import useLayoutStore from "../../../Zustand/layoutStore";
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { useLocalization } from '@progress/kendo-react-intl';
import {locales} from '../../../messages/locales'
import { AppContext } from '../../../AppContext'
import React from "react";
import { enMessages } from "../../../messages/en-US";
import { Link, useNavigate } from "react-router-dom";
import { Popup } from "@progress/kendo-react-popup";
import NotificationsPopUpPage from "../../Notifications/Pages/NotificationsPopUpPage";

interface Props {}
export default function HeaderBar({}: Props): ReactElement {
  const userProfile = useIdentityStore((store) => store.user?.profile);
  const userRoles = userProfile?.role === 'SiteAdmin' ? 'AccountAdmin' : userProfile?.role
  const layoutStore = useLayoutStore();
  const navigate = useNavigate();
  const {localeId, onLanguageChange } = React.useContext<any>(AppContext);
  const localizationService = useLocalization();
  const currentLanguage = locales.find(item => item.localeId === localeId);

  //define header colour depending on evironment
  const environment = process.env.REACT_APP_GB_ENV
  const headerColour = environment !== 'production' ? "rgb(230,230,230)" : "rgb(255,255,255)"

  const anchor = React.useRef<HTMLButtonElement | null>(null);
  const [show, setShow] = React.useState(false);
  const handleShow = () => setShow(false);

  const userId = process.env.REACT_APP_GB_HIDE_ACCOUNT_NAME_IN_HEADER_USER_ID;
  var accountName = userProfile?.current_site_name;

  if (userProfile?.sub === userId)
  {
    accountName = "Demo Account";
  }

  const titleMenuItems = [
    {
      text: localizationService.toLanguageString('custom.profile', enMessages.custom.profile), 
      onClick: () => window.location.replace(`${process.env.REACT_APP_PROFILE_URL}`)
    }, 
    {
      text: localizationService.toLanguageString('custom.changeAccount', enMessages.custom.changeAccount), 
      onClick:  () => {
        navigate(ClientRoutes.LoadingPage);
         window.location.assign(
          `${BaseUrl}${IdentityRoutes.ChangeOrganisation}?returnUrl=${window.location.origin}${ClientRoutes.LoginPage}`
        );
      }
    },
    {
      text: localizationService.toLanguageString('custom.logout', enMessages.custom.logout), 
      onClick: () =>  authService.signOut(null)
    }
    ]

    const handleItemClick = (event) => {
      titleMenuItems.map(i => { return(
        i.text === event.item.text ? i.onClick() : null
      )})
    }

    const valueRender = (
      element: React.ReactElement,
      value: any
    ) => {
      if (!value) {
        return element;
      }
  
      const children = [
        <span
        key={1}
        className="k-icon k-i-globe"
        >
        </span>,
        <span key={2}>&nbsp; {element.props.children}</span>,
      ];
  
      return React.cloneElement(element, { ...element.props }, children);
    };
  
  

  return (
    <div className="sticky top-0 bg-white z-50" style={{background: headerColour}}>
      <AppBar themeColor="inherit">
        {/* Hamburger Bar for small mobile devices */}

        {/* Logo */}
        <AppBarSection>
          <Link className="hidden md:block" to="/">
            <img src={LogoImage} alt="Grease Cloud Logo" className="px-2 lg:h-12 md:h-10 h-8" />
          </Link>
          <FontAwesomeIcon onClick={() => {layoutStore.setIsSidebarOpen(!layoutStore.isSidebarOpen)}} size="2x" icon={faBars} className="md:hidden text-gray-600 px-4" />
          <Link className="md:hidden" to="/">
            <img src={MiniLogo} alt="Grease Cloud Logo" className="h-10" />
          </Link>
        </AppBarSection>

        {/* Page Description */}
        <AppBarSection>
          <h1 className="hidden md:block gb-h2 pl-8 font-bold">{`${accountName}`}</h1>
        </AppBarSection>

        {/* Remaining assets float right */}
        <AppBarSpacer />
          <button
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
            onClick={() => setShow(!show)}
            ref={anchor}
            style={{height: '30px'}}
          >
            <span className="k-icon k-i-notification"></span>
          </button>
          <Popup anchor={anchor.current} show={show} popupClass={'popup-content'}>
            <NotificationsPopUpPage handleShow={handleShow} />
          </Popup>
        <div className="settings ">
            <DropDownList
                textField={'locale'}
                dataItemKey={'localeId'}
                data={locales}
                value={currentLanguage}
                onChange={onLanguageChange}
                valueRender={valueRender}
            />
        </div>

        {/* User Details and settings */}
        <AppBarSection className="border-l-2 p-2 pl-3">
          <Avatar type="text">{userProfile?.preferred_username?.match(/\b(\w)/g)?.join("")}</Avatar>
          <div className="flex flex-col ml-3 hidden md:block">
            <div className="font-sans">{userProfile?.preferred_username}</div>
            <div className="text-xs text-gray-600 font-sans">{userRoles?.replace(/[A-Z]/g, " $&").trim()}</div>
          </div>

          {/* Excuse very strange method of invoking onclick events for items - quirk with Kendo */}
          <DropDownButton
            className="pl-3"
            buttonClass="bg-transparent text-red-600 "
            rounded="full"
            fillMode="flat"
            icon="more-vertical"
            onItemClick={handleItemClick}
            items={titleMenuItems}
          >
          </DropDownButton>
        </AppBarSection>
      </AppBar>
    </div>
  );
}
