import React, { ReactElement } from "react";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";

interface Props {}

export default function ErrorPage(props: Props): ReactElement {
  const localizationService = useLocalization();
  return <div>{localizationService.toLanguageString('custom.error', enMessages.custom.error)}</div>;
}
