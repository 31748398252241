import { ReactElement, useEffect, useState } from "react";
import moment from "moment";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { useLocalization } from "@progress/kendo-react-intl";
import { enMessages } from "../../../messages/en-US";
import { useToast } from "@chakra-ui/react";
import ComplianceReportValidationSchema from "../Utilities/ComplianceReportValidationSchema";
import { Field, FieldProps, Form, Formik } from "formik";
import FieldErrorMessage from "../../Shared/Components/Forms/TableErrorMessage";
import { isEmail, ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import { SendComplianceReportByEmailCommand } from "../../../Models/Reporting/SendComplianceReportByEmailCommand";
import ProcessingSpinner from "./ProcessingSpinner";
import axios from "axios";
import { ApiRoutes } from "../../../Constants/ApiRoutingConstants";
import authorizeService from "../../Identity/Utilities/AuthorizeService";

interface Props {
  onClose: () => void;
  visible: boolean;
  toAddresses: string[];
  filters: any;
  timezone: string;
}

export default function ReportSendEmailModal(props: Props): ReactElement {
  const toast = useToast({
    position: "bottom",
    duration: 5000,
    isClosable: true,
  });

  const localizationService = useLocalization();

  const today = new Date();

  const [to, setTo] = useState<any>({ to: new Array() });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async (
    values: SendComplianceReportByEmailCommand,
    setStatus: any
  ) => {
    setIsLoading(true);
    const token = (await authorizeService.getUser()).access_token;

    try {
      const response = await axios
        .post(
          `${process.env.REACT_APP_REPORT_SERVICE_URL}/${ApiRoutes.ReportSendEmail}`,
          {
            subject: values.subject,
            period: values.period,
            to: values.to,
            timezone: values.timezone,
            reportDateUtc: values.reportDateUtc,
            filters: values.filters,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .finally(() => {
          setIsLoading(false);
        });

      if (response.data) {
        toast({
          status: "success",
          description: `${localizationService.toLanguageString(
            "custom.complianceSent",
            enMessages.custom.complianceSent
          )}`,
        });
      } else {
        toast({
          title: `${localizationService.toLanguageString(
            "custom.error",
            enMessages.custom.error
          )}`,
          status: "error",
          description: `${localizationService.toLanguageString(
            "custom.unableToSendEmail",
            enMessages.custom.unableToSendEmail
          )}`,
        });
      }
    } catch (e: any) {
      //On failure show error toast, set status messages
      setStatus(e?.response?.data?.errors);

      toast({
        title: `${localizationService.toLanguageString(
          "custom.error",
          enMessages.custom.error
        )}`,
        status: "error",
        description: `${localizationService.toLanguageString(
          "custom.somethingWentWrong",
          enMessages.custom.somethingWentWrong
        )} \n
          ${
            e?.response?.data?.errors
              ? Object.values(e?.response?.data?.errors)[0]
              : ""
          }`,
      });
    }

    props.onClose();
  };

  const initialValueGenerator = () => {
    return {
      subject: `[GreaseBoss] Compliance Report`,
      period: `${localizationService.toLanguageString(
        "custom.last",
        enMessages.custom.last
      )} 7 ${localizationService.toLanguageString(
        "custom.days",
        enMessages.custom.days
      )}`,
      to: props.toAddresses,
      timezone: props.timezone,
      reportDateUtc: today,
      filters: JSON.stringify(props.filters),
    };
  };

  return (
    <>
      {props.visible && (
        <Dialog
          title={localizationService.toLanguageString(
            "custom.sendEmail",
            enMessages.custom.sendEmail
          )}
          onClose={props.onClose}
          className="schedule-emails"
        >
          <Formik
            onSubmit={async (values, { setStatus }) => {
              await handleSubmit(values, setStatus);
            }}
            validationSchema={ComplianceReportValidationSchema}
            initialValues={initialValueGenerator()}
          >
            {({ submitForm, values, initialValues, setFieldValue }) => (
              <>
                <Form
                  style={{
                    width: "600px",
                    height: "370px",
                  }}
                >
                  {isLoading === false ? (
                    <>
                      <div className="flex justify-between mx-3">
                        <div className="my-3">
                          {localizationService.toLanguageString(
                            "custom.sendEmailText",
                            enMessages.custom.sendEmailText
                          )}
                        </div>
                      </div>
                      <div className="p-2 flex items-center">
                        <label htmlFor="name" className="w-24">
                          {localizationService.toLanguageString(
                            "custom.from",
                            enMessages.custom.from
                          )}
                        </label>
                        <Field
                          className="k-input k-input-md k-rounded-md k-input-solid h-8"
                          value={"noreply@greasecloud.io"}
                          disabled={true}
                        />
                      </div>

                      <div className="p-2">
                        <div className="flex items-center">
                          <label htmlFor="to" className="w-20">
                            {localizationService.toLanguageString(
                              "custom.to",
                              enMessages.custom.to
                            )}
                          </label>
                          <Field
                            name="to"
                            type="email"
                            // className="schedule-emails"
                            render={({ form }: FieldProps) => (
                              <>
                                <ReactMultiEmail
                                  placeholder={"Email"}
                                  emails={props.toAddresses}
                                  onChange={(_to: string[]) => {
                                    setTo(_to);
                                    form.setFieldValue("to", _to);
                                  }}
                                  validateEmail={(email) => {
                                    return isEmail(email);
                                  }}
                                  getLabel={(email, index, removeEmail) => {
                                    return (
                                      <div data-tag key={index}>
                                        {email}
                                        <span
                                          data-tag-handle
                                          onClick={() => removeEmail(index)}
                                        >
                                          ×
                                        </span>
                                      </div>
                                    );
                                  }}
                                />
                              </>
                            )}
                          />
                        </div>
                        <div className="ml-20">
                          <FieldErrorMessage name="to" />
                        </div>
                      </div>

                      <div className="p-2 ">
                        <div className="flex items-center">
                          <label htmlFor="subject" className="w-24">
                            {localizationService.toLanguageString(
                              "custom.subject",
                              enMessages.custom.subject
                            )}
                          </label>
                          <Field
                            name="subject"
                            className="k-input k-input-md k-rounded-md k-input-solid h-8"
                            type="text"
                            defaultValue={initialValues.subject}
                          />
                        </div>
                        <div className="ml-20">
                          <FieldErrorMessage name="subject" />
                        </div>
                      </div>

                      <div className="p-2 flex items-center">
                        <label htmlFor="reportDateUtc" className="w-24">
                          {localizationService.toLanguageString(
                            "custom.reportDate",
                            enMessages.custom.reportDate
                          )}
                        </label>

                        <DatePicker
                          onChange={(date) => {
                            setFieldValue("reportDateUtc", date.value);
                          }}
                          defaultValue={initialValues.reportDateUtc}
                        />
                      </div>

                      <div className="p-2 flex items-center">
                        <label htmlFor="period" className="w-24">
                          {localizationService.toLanguageString(
                            "custom.period",
                            enMessages.custom.period
                          )}
                        </label>
                        <Field
                          name={"period"}
                          render={({ field, form, meta }: FieldProps) => (
                            <DropDownList
                              style={{ height: "30px" }}
                              defaultValue={initialValues.period}
                              onChange={(e: any) => {
                                form.setFieldValue("period", e.value);
                              }}
                              data={[
                                `${localizationService.toLanguageString(
                                  "custom.last",
                                  enMessages.custom.last
                                )} 7 ${localizationService.toLanguageString(
                                  "custom.days",
                                  enMessages.custom.days
                                )}`,
                                `${localizationService.toLanguageString(
                                  "custom.last",
                                  enMessages.custom.last
                                )} 14 ${localizationService.toLanguageString(
                                  "custom.days",
                                  enMessages.custom.days
                                )}`,
                                `${localizationService.toLanguageString(
                                  "custom.last",
                                  enMessages.custom.last
                                )} 28 ${localizationService.toLanguageString(
                                  "custom.days",
                                  enMessages.custom.days
                                )}`,
                                `${localizationService.toLanguageString(
                                  "custom.last",
                                  enMessages.custom.last
                                )} 30 ${localizationService.toLanguageString(
                                  "custom.days",
                                  enMessages.custom.days
                                )}`,
                                `${localizationService.toLanguageString(
                                  "custom.today",
                                  enMessages.custom.today
                                )}`,
                                `${localizationService.toLanguageString(
                                  "custom.yesterday",
                                  enMessages.custom.yesterday
                                )}`,
                              ]}
                            />
                          )}
                        />
                      </div>

                      <div className="p-2 flex items-center">
                        <label htmlFor="timezone" className="w-24">
                          {localizationService.toLanguageString(
                            "custom.timezone",
                            enMessages.custom.timezone
                          )}
                        </label>
                        <Field
                          className="k-input k-input-md k-rounded-md k-input-solid h-8"
                          value={initialValues.timezone}
                          disabled={true}
                        />
                      </div>
                    </>
                  ) : (
                    <ProcessingSpinner />
                  )}
                </Form>
                <DialogActionsBar>
                  <Button
                    themeColor={"success"}
                    onClick={() => {
                      submitForm();
                    }}
                    disabled={isLoading}
                  >
                    {localizationService.toLanguageString(
                      "custom.send",
                      enMessages.custom.send
                    )}
                  </Button>

                  <Button onClick={props.onClose}>
                    {localizationService.toLanguageString(
                      "custom.cancel",
                      enMessages.custom.cancel
                    )}
                  </Button>
                </DialogActionsBar>
              </>
            )}
          </Formik>
        </Dialog>
      )}
    </>
  );
}
