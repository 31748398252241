import React, { ReactElement, useEffect, useState } from "react";
import axios from "axios";
import authService from "../Utilities/AuthorizeService";
import useIdentityStore from "../../../Zustand/identityStore";
import LoadingPage from "../../Shared/Pages/LoadingPage";
interface Props {
  children: React.ReactNode;
}

/**
 *  This wrapper sits on top of the application and listens for changes in authentication state. It
 *  pushes any updates to the zustand store so that they can be accessed in all components.
 */
export default function AuthenticationWrapper({
  children,
}: Props): ReactElement {
  const setUser = useIdentityStore((store) => store.setUser);
  const [initialised, setInitalised] = useState(false);

  useEffect(() => {
    //Initilalise
    handleAuthChanged();
    //On mount, subscribe to authentication state changes
    const subscription_id = authService.subscribe(() => {
      //State has changed, so attempt to refresh the user
      handleAuthChanged();
    });

    //Add response interceptor, tries to refresh current user on bad request.
    const authInterceptor = axios.interceptors.response.use(
      (response) => {
        //Do nothing with Ok response
        return Promise.resolve(response);
      },
      async (error) => {
        //If 401 error, ensure the user is up to date
        console.log("401")
        const user = await authService.getUser();
        setUser(user);
        return Promise.reject(error);
      }
    );

    //On dismount, delete subscription
    return () => {
      authService.unsubscribe(subscription_id);
      axios.interceptors.request.eject(authInterceptor);
    };
  }, []);

  const handleAuthChanged = async () => {
    //Auth changed, so get and set the new auth state
    const newUser = await authService.getUser();
    setUser(newUser);
    setInitalised(true);
  };

  return (
    //If user is undefined, we show a loading spinner, once loaded we can show the component children.
    <div>{initialised ? children : <LoadingPage />}</div>
  );
}
