import {
    FastLineRenderableSeries,
    IOverviewOptions,
    IRenderableSeries,
    Thickness,
} from "scichart";
import { GetChartTheme } from "./ChartConfiguration";

const theme = GetChartTheme();  

// options used for current example
export const overviewOptions: IOverviewOptions = {
    theme: theme,
    disableAspect: true,
    padding: Thickness.fromNumber(5),
   
    transformRenderableSeries: (renderableSeries: IRenderableSeries) => {
   
        const copiedSeries = new FastLineRenderableSeries(renderableSeries.parentSurface.webAssemblyContext2D, {
            dataSeries: renderableSeries.dataSeries,
            stroke: "rgba(50, 53, 67, 0.65)",
        });

        return copiedSeries;
    },
};

// options used for current example
export const endpointReportOverviewOptions: IOverviewOptions = {
  theme: theme,
  disableAspect: true,
  padding: new Thickness(0, 5, 0, 5),

};