import { Form, FormElement } from "@progress/kendo-react-form";
import React, { useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { Stepper } from "@progress/kendo-react-layout";
import { RightGrease } from "./RightGrease";
import  {RightAmount}  from "./RightAmount";
import { enMessages } from "../../../../messages/en-US";
import { useLocalization } from "@progress/kendo-react-intl";
import { RequirementCreateDto } from "../../../../Models/Requirement/RequirementCreateDto";
import moment from "moment";
import { useToast } from "@chakra-ui/react";
import { RequirementReadDto } from "../../../../Models/Requirement/RequirementReadDto";
import { RightTime } from "./RightTime";
import requirements from './../requirements.json'
import { ConsumableReadTableDto } from "../../../../Models/Consumable/ConsumableReadTableDto";

interface Props {
  requirement: RequirementReadDto | null;
  addRequirement: boolean;
  consumables: Array<ConsumableReadTableDto>;
  onClose: () => void;
  updateRequirement: (scheduleId: string, schedule: RequirementCreateDto) => void;
  createRequirement: (scheduleCreateDto: RequirementCreateDto) => void;
}

export default function RequirementAddModal(props: Props) {

const initialValueGenerator = (update: boolean) => {
  if (update)
  {
    return {
      name: props.requirement?.name,
      consumable: props.consumables.find(x=>x.id === props.requirement?.requirementDetails.rightGreaseDetails.consumableId),
      rightAmount: {
        volumeRequirement: props.requirement?.requirementDetails.rightAmountDetails.volumeRequirement,
        autoThresholds: props.requirement?.requirementDetails.rightAmountDetails.autoThresholds,
        autoThresholdPercentage: props.requirement?.requirementDetails.rightAmountDetails.autoThresholdPercentage,
        thresholds: props.requirement?.requirementDetails.rightAmountDetails.thresholds,
        complianceThresholdId: props.requirement?.requirementDetails.rightAmountDetails.complianceThresholdId,
      },
      aggregationType: props.requirement?.requirementDetails.aggregationType,
      recurrenceRule: {
        startDate: props.requirement?.requirementDetails.rightTimeDetails.startDate, 
        endDate: props.requirement?.requirementDetails.rightTimeDetails.endDate, 
        rRule: props.requirement?.requirementDetails.rightTimeDetails.rRule, 
        timezone: props.requirement?.requirementDetails.rightTimeDetails.timezone },
    }
  }
  else
  {
    return {
      name: "",
      consumable:"",
      rightAmount: {
        volumeRequirement: 10.0,
        autoThresholds: true,
        autoThresholdPercentage: 0.20,
        thresholds: requirements,
        complianceThresholdId: 2
    },
      aggregationType: "Sum",
      recurrenceRule: {
        startDate: new Date(), 
        endDate: null, 
        rRule: "RRULE:FREQ=DAILY;INTERVAL=1",
        timezone: moment.tz.guess() }
    }
  }
 }

  const localizationService = useLocalization();
  const [step, setStep] = React.useState(0);
  const [formState, setFormState] = React.useState<Object>(initialValueGenerator(props.requirement ? true : false));
  const toast = useToast({
    position: "bottom",
    duration: 5000,
    isClosable: true,
  });


  const stepPages = [<RightGrease consumables={props.consumables}/>, RightAmount, <RightTime/>];
  const [steps, setSteps] = React.useState([
    { label: localizationService.toLanguageString('custom.rightGrease', enMessages.custom.rightGrease), isValid: undefined },
    { label: localizationService.toLanguageString('custom.rightAmount', enMessages.custom.rightAmount), isValid: undefined},
    { label: localizationService.toLanguageString('custom.rightTime', enMessages.custom.rightTime), isValid: undefined},
  ]);

  const lastStepIndex = steps.length - 1;
  const isLastStep = lastStepIndex === step;
  const isPreviousStepsValid =
    steps
      .slice(0, step)
      .findIndex((currentStep) => currentStep.isValid === false) === -1;

  const onStepSubmit = React.useCallback(
    (event) => {
      const { isValid, values } = event;

      const currentSteps = steps.map((currentStep, index) => ({
        ...currentStep,
        isValid: index === step ? isValid : currentStep.isValid
      }));

      setSteps(currentSteps);

      if (!isValid) {
        return;
      }

      setStep(() => Math.min(step + 1, lastStepIndex));
      setFormState(values);

      if (isLastStep && isPreviousStepsValid && isValid) {
        handleSubmit(values )
      }
    },
    [steps, isLastStep, isPreviousStepsValid, step, lastStepIndex]
  );

  const onPrevClick = React.useCallback(
    (event) => {
      event.preventDefault();
      setStep(() => Math.max(step - 1, 0));
    },
    [step, setStep]
  );

  const [showSelectionErrorMessage, setShowSelectionErrorMessage] = useState<boolean>(false);

  const handleSubmit = async (values: any) => {

    if (showSelectionErrorMessage) {
      toast({
        status: "error",
        title: `${localizationService.toLanguageString('custom.error', enMessages.custom.error)}`,
        description: `${localizationService.toLanguageString('custom.mustSelectDay', enMessages.custom.mustSelectDay)}`,
      });
    } else {
      try {
        const castValues = {
          name: values.name,
          description: values.description,
          status: values.status,
          requirementDetails: {
            start: values.recurrenceRule.start,
            end: values.recurrenceRule.end,
            aggregationType: values.aggregationType,
            rightGreaseDetails: {
              consumableId:values.consumable.id,
            },
            rightAmountDetails: {
              volumeRequirement:values.rightAmount.volumeRequirement,
              autoThresholds:values.rightAmount.autoThresholds,
              autoThresholdPercentage:values.rightAmount.autoThresholdPercentage,
              thresholds: values.rightAmount.thresholds,
              complianceThresholdId: values.rightAmount.complianceThresholdId
            },
            rightTimeDetails: {
              rRule: values.recurrenceRule.rRule,
              timezone: values.recurrenceRule.timezone,
              startDate: values.recurrenceRule.startDate,
              endDate: values.recurrenceRule.endDate,
            }
        }} as RequirementCreateDto;

        if (props.requirement) {
          await props.updateRequirement(props.requirement.id, castValues);
        } else {
          await props.createRequirement(castValues);
        }
        
        toast({
          status: "success",
          title: "sucess",
          description: `'${values.name}' ${localizationService.toLanguageString('custom.requirementSuccessfully', enMessages.custom.scheduleSuccessfully)} ${
            props.requirement ? localizationService.toLanguageString('custom.updated', enMessages.custom.updated) : localizationService.toLanguageString('custom.created', enMessages.custom.created)
          }`,
        });
      } catch (e: any) {
        //On failure show error toast, set status messages
        // setStatus(e?.response?.data?.errors);

        toast({
          title: `${localizationService.toLanguageString('custom.error', enMessages.custom.error)}`,
          status: "error",
          description: `${localizationService.toLanguageString('custom.somethingWentWrong', enMessages.custom.somethingWentWrong)} \n
          ${e?.response?.data?.errors ? Object.values(e?.response?.data?.errors)[0] : ""}`,
        });
      }
    }
  };

  return (
    <div >
    <Dialog title={props.requirement ? localizationService.toLanguageString('custom.updateRequirements', enMessages.custom.updateRequirements) : localizationService.toLanguageString('custom.createRequirements', enMessages.custom.createRequirements)} onClose={props.onClose} className={"requirements-dialog"} width={"800px"} style={{overflow:'scroll'}}>
    <div>
      <Stepper value={step} items={steps} />
      <Form
        onSubmitClick={onStepSubmit}
        initialValues={formState}
        render={(formRenderProps) => (
          <div style={{ alignSelf: "center" }}>
            <FormElement>
              {stepPages[step]}
              <span
                style={{ marginTop: "40px", marginBottom:"10px" }}
                className={"k-form-separator"}
              />
              <div
                style={{
                  justifyContent: "space-between",
                  alignContent: "center",
                }}
                className={
                  "k-form-buttons k-button k-button-md k-rounded-md k-button-solid k-button-solid-bases-end"
                }
              >
                <span style={{ alignSelf: "center" }}>
                  Step {step + 1} of 3
                </span>
                <div>
                <Button
                    themeColor={"primary"}
                    disabled={isLastStep ? !isPreviousStepsValid : false}
                    onClick={props.onClose} 
                    style={{ marginRight: "16px" }}
                  >
                    {isLastStep ? localizationService.toLanguageString('custom.cancel', enMessages.custom.cancel) : localizationService.toLanguageString('custom.cancel', enMessages.custom.cancel)}
                  </Button>
                  {step !== 0 ? (
                    <Button
                      style={{ marginRight: "5px" }}
                      onClick={onPrevClick}
                    >
                      Previous
                    </Button>
                  ) : undefined}
                  
                  <Button
                    themeColor={"primary"}
                    disabled={isLastStep ? !isPreviousStepsValid : false}
                    onClick={
                      formRenderProps.onSubmit}
                  >
                    {isLastStep ? localizationService.toLanguageString('custom.submit', enMessages.custom.submit) : localizationService.toLanguageString('custom.next', enMessages.custom.next)}
                  </Button>
                </div>
              </div>
            </FormElement>
          </div>
        )}
      />
    </div>
    </Dialog>
    </div>
  );
};
