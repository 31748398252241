import { ReactElement } from "react";
import GenericConfirmationDialog from "./GenericConfirmationDialog";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../../messages/en-US";

interface Props {
  title: string
  deletingItemLabel: string | undefined;
  itemCount: number;
  open: boolean;
  onClose: () => void;
  deleteCallback: () => void;
  children: React.ReactNode;
}

export default function DeleteDialog({
  title,
  deletingItemLabel,
  open,
  onClose,
  deleteCallback,
}: Props): ReactElement {
  const localizationService = useLocalization();
  return (
    <GenericConfirmationDialog
      open={open}
      cancel={localizationService.toLanguageString('custom.cancel', enMessages.custom.cancel)}
      confirm={localizationService.toLanguageString('custom.delete', enMessages.custom.delete)}
      onClose={onClose}
      body={`${deletingItemLabel} ${localizationService.toLanguageString('custom.deleteItemLabel', enMessages.custom.deleteItemLabel)}`}
      header={title}
      onConfirm={deleteCallback}
      confirmButtonTheme={"primary"}
    />
  );
}
