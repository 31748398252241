import { ReactElement, useMemo } from "react";
import { useQuery, useQueryClient } from "react-query";
import { ReactQueryKeys } from "../../../Constants/ClientRoutingConstants";
import { useToast } from "@chakra-ui/react";
import schedulesApiService from "../Utilities/ScheduleApiService";
import ErrorCard from "../../Shared/Components/Cards/ErrorCard";
import LoadingSpinner from "../../Shared/Components/LoadingSpinner";
import { ScheduleCreateDto } from "../../../Models/Schedule/ScheduleCreateDto";
import { ScheduleReadDto } from "../../../Models/Schedule/ScheduleReadDto";
import SchedulesTable from "../Components/SchedulesTable";
import { ScheduleReadTableDto } from "../../../Models/Schedule/ScheduleReadTableDto";
import { rruleFrequencyToText, rruleRecurrenceToText, rruleSummaryToText } from "../Utilities/ScheduleUtilities";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";
import { useWindowSize } from "usehooks-ts";
import { Dialog } from "@progress/kendo-react-dialogs";
import moment from "moment";

interface Props {
  isOpenDialog: any;
  setIsOpenDialog: any;
}

export default function SchedulesPage(props: Props): ReactElement {
  //Server side state
  const client = useQueryClient();
  const toast = useToast({
    position: "bottom",
    duration: 5000,
    isClosable: true,
  });

  const localizationService = useLocalization();

    //get window size
    const { width, height } = useWindowSize()

  const schedulesQuery = useQuery<Array<ScheduleReadDto>>(ReactQueryKeys.AllSchedulesQuery, async () => {
    return schedulesApiService.getAllSchedules();
  });

  const createSchedule = async (scheduleCreateDto: ScheduleCreateDto) => {
    //Convert the date to the correct time zone 
    // TODO: Convert the time  to be in the timezone of the schedule itself !

    const result = await schedulesApiService.createSchedule(scheduleCreateDto);
    client.refetchQueries(ReactQueryKeys.AllSchedulesQuery);
  };

  const deleteSchedules = async (selectedScheduleIds: Array<string>) => {
    try {
      let response = await schedulesApiService.deleteSchedules(selectedScheduleIds);

      //If delete is successful, refetch the updated list of devices and show success message
      client.refetchQueries(ReactQueryKeys.AllSchedulesQuery);

      toast({
        status: "success",
        description: `${localizationService.toLanguageString('custom.schedulesDeleted', enMessages.custom.schedulesDeleted)}`,
      });
    } catch (e) {
      toast({
        status: "error",
        description: `${localizationService.toLanguageString('custom.schedulesDeletedError', enMessages.custom.schedulesDeletedError)}`,
      });
    }
  };

  const updateSchedules = async (scheduleId: string, updateSchedule: ScheduleCreateDto) => {
    let response = await schedulesApiService.updateSchedule(updateSchedule, scheduleId);
    //If update is successful, refetch the updated list of areas and show success message
    client.refetchQueries(ReactQueryKeys.AllSchedulesQuery);
  };

  /**
   * This function prepares data for schedules table, including injecting additional metadata.
   */
  const scheduleData = useMemo(() => {
    //Map over areas to inject relevant data, currently requires not extra metadata
    return schedulesQuery.data?.map((schedule) => {
      return {
        ...schedule,
        frequency: rruleFrequencyToText(schedule.rRule),
        recurrence: rruleRecurrenceToText(schedule.rRule),
        summary: rruleSummaryToText(schedule.rRule),
        startDateFormatted: moment(schedule.startDate).format("DD/MM/YYYY")
      } as ScheduleReadTableDto;
    });
  }, [schedulesQuery.data]);



  //Render the Schedules Table
  const RenderQuery = () => {
    if (schedulesQuery.error) {
      return <ErrorCard />;
    } else {
      return (
        <div>
          {/* Schedules Table */}
          <SchedulesTable
            schedules={scheduleData!}
            deleteSchedule={deleteSchedules}
            createSchedule={createSchedule}
            updateSchedule={updateSchedules} handleSubmit={undefined} initialSort={undefined} initialSortDescriptor={undefined} extraInlineActions={null}
            height={690}
          />

          {props.isOpenDialog.schedules && (
            <Dialog
              title={localizationService.toLanguageString('custom.schedules', enMessages.custom.schedules)}
              onClose={() => props.setIsOpenDialog({...props.isOpenDialog, schedules: false})}
            >
              <SchedulesTable
                schedules={scheduleData!}
                deleteSchedule={deleteSchedules}
                createSchedule={createSchedule}
                updateSchedule={updateSchedules} handleSubmit={undefined} initialSort={undefined} initialSortDescriptor={undefined} extraInlineActions={null}
                height={height - 200}
              />
            </Dialog>
          )}
        </div>
      );
    }
  };

  return schedulesQuery.isLoading || schedulesQuery.isFetching ? <LoadingSpinner /> : <RenderQuery />;
}
