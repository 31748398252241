import ManualPage from "./ManualPage";
import AutomaticPage from "./AutomaticPage";
import AutomaticManualPage from "./AutomaticManualPage";
import NoSettingsPage from "./NoSettingsPage";
import AccountSettingsReadDto from "../../../Models/Dashboard/AccountSettingsReadDto";
import { ReactQueryKeys } from "../../../Constants/ClientRoutingConstants";
import dashboardApiService from "../Utilities/DashboardApiService";
import { useQuery } from "react-query";
import LoadingSpinner from "../../Shared/Components/LoadingSpinner";
import ErrorCard from "../../Shared/Components/Cards/ErrorCard";

export default function DashboardPage( ) {

  const accountSettingsQuery = useQuery<AccountSettingsReadDto>([ReactQueryKeys.SoftwareFeaturesQuery], async () => {
    return dashboardApiService.getAccountSettings();
  });

  if(accountSettingsQuery.isLoading || accountSettingsQuery.isFetching){
    return <LoadingSpinner />
  } else if(accountSettingsQuery.isError){
    return <ErrorCard />
  }
  return (
    <div className='m-5'>
      {(accountSettingsQuery.data?.softwareFeatures.isAutomaticSystemEnabled && !accountSettingsQuery.data?.softwareFeatures.isManualSystemEnabled) ?
        <AutomaticPage />
      :
      (accountSettingsQuery.data?.softwareFeatures.isManualSystemEnabled && !accountSettingsQuery.data?.softwareFeatures.isAutomaticSystemEnabled) ?
        <ManualPage />
      :
      (accountSettingsQuery.data?.softwareFeatures.isManualSystemEnabled && accountSettingsQuery.data?.softwareFeatures.isAutomaticSystemEnabled) ?
        <AutomaticManualPage />
      :
      <NoSettingsPage />
      }
  
    </div>
  );
}
