import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { useLocalization } from '@progress/kendo-react-intl';
import { TextBox } from "@progress/kendo-react-inputs";
import { enMessages } from "../../../../messages/en-US";


interface Props {
  onClose: () => void;
  token: string
}

export default function AuthTokenConfirmationModal(props: Props) {

  const localizationService = useLocalization();
  const Title = () => 
    <div className="flex items-center gap-3">
      <div>{localizationService.toLanguageString('custom.tokenCreated', enMessages.custom.tokenCreated)}</div>
      <div className="k-icon k-i-check-circle" style={{color:'green',}}></div>
    </div>
  

  return (
    <div>
      <Dialog title={Title()} 
        onClose={props.onClose} >
        <div className="p-2">
          <div className="mb-5">{localizationService.toLanguageString('custom.copyToken', enMessages.custom.copyToken)}</div>
          <div className="flex items-center">
            <TextBox readOnly={true} value={props.token}/>
            {/* <div>{props.token}</div> */}
            <Button icon="copy" size="small" fillMode="flat" themeColor="primary" onClick={() => {navigator.clipboard.writeText(props.token)}}/>
          </div>
        </div>
        <div className="p-2" style={{textAlign:'right', borderTop:'1px solid #EBEBEB'}}>
          <Button
            themeColor={"success"}
            onClick={props.onClose} 
            style={{width:' 60px'}}
            >
            OK
          </Button>
          </div> 
      </Dialog>
    </div>
  );
}
