/**
 * Defines a component that renders a filter cell for a specific field, provides a simpler version of
 * the provided kendo table filter to help save usage of screen real estate and simplify use.
 *
 * Author: Max Wroe
 * Date : 04/04/2022
 */

import { Button } from "@progress/kendo-react-buttons";
import { GridFilterCellProps } from "@progress/kendo-react-grid";
import { Input, InputChangeEvent } from "@progress/kendo-react-inputs";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../../messages/en-US";

export const CustomTextFilterCell: React.ComponentType<GridFilterCellProps> = (props: any) => {
  let hasValue: any = (value: any) => Boolean(value && value !== props.defaultItem);
  const localizationService = useLocalization();

  const onChange = (event: InputChangeEvent) => {
    hasValue = hasValue(event.target.value);
    props.onChange({
      value: hasValue ? event.target.value : "",
      operator: hasValue ? "contains" : "",
      syntheticEvent: event.syntheticEvent,
    });
  };

  const onPasteEvent = (event) => {
    event.currentTarget.value = event.clipboardData.getData("text").trim();
    hasValue = hasValue(event.currentTarget.value);
    props.onChange({
      value: hasValue ? event.currentTarget.value : "",
      operator: hasValue ? "contains" : "",
      syntheticEvent: event.syntheticEvent,
    });
    event.preventDefault();
  };


  const onClearButtonClick = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    props.onChange({
      value: null,
      operator: "",
      syntheticEvent: event,
    });
  };

  return (
    <div className="k-filtercell">
      <Input onChange={onChange} value={props.value} placeholder={localizationService.toLanguageString('custom.search', enMessages.custom.search)}  onPaste={onPasteEvent}/>
      <Button
        title="Clear"
        disabled={!hasValue(props.value)}
        onClick={onClearButtonClick}
        icon="filter-clear"
      />
    </div>
  );
};
