import { useLocalization } from "@progress/kendo-react-intl";
import { enMessages } from "../../../messages/en-US";
import { FieldWrapper} from "@progress/kendo-react-form";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FilterDescriptor } from "@progress/kendo-data-query";
import { DropDownList, DropDownListChangeEvent, MultiSelectTree, MultiSelectTreeChangeEvent, MultiSelectTreeExpandEvent, MultiSelectTreeFilterChangeEvent, getMultiSelectTreeValue,
} from "@progress/kendo-react-dropdowns";
import { expandedState, processMultiSelectTreeData} from "../../Reporting/Pages/MultiselectTree-Data-Operations";
import { Tooltip } from "@progress/kendo-react-tooltip";
import EndpointWithRequirementReadDto from "../../../Models/Endpoint/EndpointWithRequirementReadDto";
import React from "react";
import { SvgIcon } from '@progress/kendo-react-common';
import { globeIcon, gearIcon } from '@progress/kendo-svg-icons';
import { GetEndpointIcon } from "../../../Utilities/CustomIcons";
import { useQuery } from "react-query";
import { ReactQueryKeys } from "../../../Constants/ClientRoutingConstants";
import alertsApiService from "../Utilities/AlertsApiService";
import { createDataTree } from "@progress/kendo-react-treelist";
import { Loader } from "@progress/kendo-react-indicators";
import ErrorCard from "../../Shared/Components/Cards/ErrorCard";
import { NumericTextBox } from "@progress/kendo-react-inputs";

interface Props {
  isAddModal: boolean;
  condition: any;
  onChange: (value:any) => void;
}

const dataItemKey = "id";
const checkField = "checkField";
const checkIndeterminateField = "checkIndeterminateField";
const subItemsField = "items";
const expandField = "expanded";
const textField = "displayName";

const fields = {
  dataItemKey,
  checkField,
  checkIndeterminateField,
  expandField,
  subItemsField,
};

const requirementTypeName = "Requirement";
const conditionFields = [
  { 
    fieldName: "NumberOfConsecutiveFailures", 
    dataOperator: [{ text: "≥", value: "gte"}],
    valueFormat: {format: "n0", dataType:"IntegerCondition" },
    dataUnit: [{ unitName: "Failures", max: 30, min: 1}],
    default: {value: 3, unitName: "Failures", operator: "gte"},
    isDefault: true  // Ensure that there is only one default field
  }
];

export const RequirementConditionComponent = (props: Props) => {

  const localizationService = useLocalization();
  const [assignedTo, setAssignedTo] = useState<any>([]);
  const [conditions, setConditions] = useState<any>([]);

  const endpointWithRequirementsQuery = useQuery<Array<EndpointWithRequirementReadDto>>(
    ReactQueryKeys.AllEndpointsWithRequirementsQuery,
    async () => {
      return alertsApiService.getAllEndpointsWithRequirements();
    }
  );

  useEffect(() => {
    if (props.isAddModal) 
    {
      const defaultField = conditionFields.find((item) => item.isDefault === true);

      setConditions(
        {
          type: { typeName: requirementTypeName},
          field: defaultField,
          unit: defaultField?.dataUnit.find((unit) => unit.unitName === defaultField?.default.unitName),
          operator: defaultField?.dataOperator.find((operator) => operator.value === defaultField?.default.operator),
          value: defaultField?.default.value,
          dataType: defaultField?.valueFormat.dataType
        }
      )
    }
    else
    {
      const condition = JSON.parse(props.condition.conditions);
      const field = conditionFields.find((item) => item.fieldName === condition.field);
      const units = field?.dataUnit.find((unit) => unit.unitName === condition.unit);
      const operators = field?.dataOperator.find((operator) => operator.value === condition.operator); 

      setConditions(
        {
          type: { typeName: requirementTypeName },
          field: conditionFields.find((item) => item.fieldName === condition.field),
          unit: units,
          operator: operators,
          value: condition.value,
          dataType: condition.dataType
        }
      )
    }

  }, []);

  useEffect(() => {

    if (conditions.length !== 0)
    {
      const filteredAssignedTo = props.isAddModal ? 
        assignedTo.filter((item) => item.type === "Endpoint").map(({ id, name }) => ({ id, name })) : {id: props.condition.assignedTo.id, name: props.condition.assignedTo.name};
      const filteredConditions = {field: conditions?.field.fieldName, operator: conditions?.operator.value, value: conditions?.value, unit: conditions?.unit.unitName, dataType: conditions?.dataType};
      props.onChange({value: {assignedTo: filteredAssignedTo, conditions: [JSON.stringify(filteredConditions)]}})
    }
  }, [conditions, assignedTo]);

  const assignToData = useMemo(() => {
    if(endpointWithRequirementsQuery.data === undefined) 
      return []
    else
      return createDataTree(endpointWithRequirementsQuery.data,(i) => i.id, (i) => i.parentId,"items")
  }, [endpointWithRequirementsQuery.data]);

  const fieldChange = (event: DropDownListChangeEvent) => {

    const fieldChange = conditionFields.find((item) => item.fieldName === event.target.value.fieldName);

    setConditions(
      {
        type: { typeName: requirementTypeName},
        field: fieldChange,
        unit: fieldChange?.dataUnit.find((unit) => unit.unitName === fieldChange?.default.unitName),
        operator: fieldChange?.dataOperator.find((operator) => operator.value === fieldChange?.default.operator),
        value: fieldChange?.default.value,
        dataType: fieldChange?.valueFormat.dataType
      }
    )
};

  const unitChange = (event: DropDownListChangeEvent) => {
    setConditions({ ...conditions, unit: event.target.value });
  };

  const operatorChange = (event: DropDownListChangeEvent) => {
    setConditions({ ...conditions, operator: event.target.value });
  };

  const TooltipContentTemplate = (props: any) => {
    return (
      <>
        <div>= :{localizationService.toLanguageString("custom.equalTo", enMessages.custom.equalTo)}</div>
        <div>≠ :{localizationService.toLanguageString("custom.notEqualTo", enMessages.custom.notEqualTo)}</div>
        <div>&gt; :{localizationService.toLanguageString("custom.greaterThan", enMessages.custom.greaterThan)}</div>
        <div>&lt; :{localizationService.toLanguageString("custom.lessThan", enMessages.custom.lessThan)}</div>
        <div>≥ :{localizationService.toLanguageString("custom.greaterThanOrEqualTo", enMessages.custom.greaterThanOrEqualTo)}</div>
        <div>≤ :{localizationService.toLanguageString("custom.lessThanOrEqualTo", enMessages.custom.lessThanOrEqualTo)}</div>
      </>
    );
  };

  const [expanded, setExpanded] = useState([assignToData[dataItemKey]]);
  const [filter, setFilter] = useState<FilterDescriptor | null>(null);

  const onChangeAssignedTo = (event: MultiSelectTreeChangeEvent) => {
   setAssignedTo(
      getMultiSelectTreeValue(assignToData, {
        ...fields,
        ...event,
        value: assignedTo,
      })
    );
  }
  
  const onExpandChangeAssignedTo = useCallback(
    (event: MultiSelectTreeExpandEvent) =>
      setExpanded(expandedState(event.item, dataItemKey, expanded)),
    [expanded]
  );

  const onFilterChangeAssignedTo = (event: MultiSelectTreeFilterChangeEvent) =>
    setFilter(event.filter);

  const treeData = useMemo(
    () =>
      processMultiSelectTreeData(assignToData, {
        expanded,
        value: assignedTo,
        filter,
        ...fields,
      }),
    [expanded, assignedTo, filter, endpointWithRequirementsQuery.data]
  );

  const ItemAssignToTree = (props) => {
      switch(props.item.type) {
        case "Endpoint":
          return (
            <React.Fragment>
              {GetEndpointIcon(16,16)}
              {props.item.displayName}
            </React.Fragment>
          );
        case "Asset":
          return (
            <React.Fragment>
              <SvgIcon icon={gearIcon} />
              {props.item.displayName}
            </React.Fragment>
          );
        case "Location":
          return (
            <React.Fragment>
              <SvgIcon icon={globeIcon} />
              {props.item.displayName}
            </React.Fragment>
          );
        default:
          return (
            <React.Fragment>
              {props.item.displayName}
            </React.Fragment>
          );
      }
  };

  if(endpointWithRequirementsQuery.isLoading || endpointWithRequirementsQuery.isFetching || conditions.length === 0) 
    return <Loader type={"pulsing"} />
  else if(endpointWithRequirementsQuery.isError) 
    return <ErrorCard/>
  else
    return (
      <FieldWrapper>
      <div>
        <div className="mt-4">
          <div className="mt-3">
            <label>
              {localizationService.toLanguageString("custom.assignTo",enMessages.custom.assignTo)}
            </label>
            {!props.isAddModal ? (
              <div>
                <div>{props.condition.assignedTo.name}</div>
                <div className="text-xs">{props.condition.assignedTo.details}</div>
              </div>
            ) : (
              <MultiSelectTree
                className="alerts-page-multiselect"
                data={treeData === undefined ? [] : treeData.length === 0 ? [] : treeData}
                value={assignedTo}
                onChange={onChangeAssignedTo}
                textField={textField}
                dataItemKey={dataItemKey}
                checkField={checkField}
                checkIndeterminateField={checkIndeterminateField}
                subItemsField={subItemsField}
                expandField={expandField}
                onExpandChange={onExpandChangeAssignedTo}
                filterable={true}
                onFilterChange={onFilterChangeAssignedTo}
                required={true}
                validityStyles={false}
                style={{maxHeight: "60px", overflowY: "auto"}}
                item={ItemAssignToTree}
              />
            )
          }
              
          </div>

          <div className="flex gap-3 mt-3">
            <div style={{ width: "40%" }}>
              <label>
                {localizationService.toLanguageString("custom.field",enMessages.custom.field)}
              </label>
              <DropDownList
                name="fieldCondition"
                data={conditionFields}
                textField="fieldName"
                onChange={fieldChange}
                value={conditions?.field}
                size={"small"}
              />
            </div>
            
            <div style={{ width: "15%" }}>
              <label className="flex gap-1 items-end">
                {localizationService.toLanguageString("custom.operator", enMessages.custom.operator)}
                <Tooltip position={"bottom"} anchorElement={"target"} content={(props) => (<TooltipContentTemplate title={props.title} />)}>
                  <span
                    className="k-icon k-i-information k-icon-xs cursor-pointer mb-1"
                    title="Operator"
                  ></span>
                </Tooltip>
              </label>
              <DropDownList
                name="unit"
                data={conditions.field.dataOperator}
                textField="text"
                onChange={operatorChange}
                value={conditions?.operator}
                disabled={conditions?.type === null}
                size={"small"}
              />
            </div>
            <div style={{ width: "25%" }}>
            <label>
            {localizationService.toLanguageString("custom.value",enMessages.custom.value)}
              </label>
              <NumericTextBox
                validityStyles={false}
                name={"value"}
                value={conditions?.value}
                format={conditions.field.valueFormat.format}
                min={conditions.field.valueFormat.min}
                onChange={(event) => setConditions({...conditions, value: event.value})}
                size={"small"}
                required={true}
                valid={conditions?.value <= conditions.field.dataUnit.find((unit) => unit.unitName === conditions?.unit.unitName)?.max && conditions?.value >= conditions.field.dataUnit.find((unit) => unit.unitName === conditions?.unit.unitName)?.min}
                validationMessage={conditions?.value !== null ? `Maximum ${conditions.field.dataUnit.find((unit) => unit.unitName === conditions?.unit.unitName)?.max} Allowed` : "Please enter a value!" }
              />
            </div>
            <div style={{ width: "25%" }}>
              <label>
                {localizationService.toLanguageString("custom.unit",enMessages.custom.unit)}
              </label>
              <DropDownList
                name="unit"
                data={conditions.field.dataUnit}
                textField="unitName"
                onChange={unitChange}
                value={conditions?.unit}
                size={"small"}
              />
            </div> 
          </div>
        </div>
      </div>
      </FieldWrapper>
    )
};