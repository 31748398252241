import { useToast } from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import { useMemo, useState } from "react";
import FieldErrorMessage from "../../Shared/Components/Forms/TableErrorMessage";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList, } from "@progress/kendo-react-dropdowns";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";
import { ConsumableReadTableDto } from "../../../Models/Consumable/ConsumableReadTableDto";
import { Input, NumericTextBox } from "@progress/kendo-react-inputs";
import React from "react";
import { PointReadDto } from "../../../Models/Point/PointReadDto";
import EndpointReadDto from "../../../Models/Endpoint/EndpointReadDto";
import { ReservoirReadDto } from "../../../Models/Reservoirs/ReservoirReadDto";
import { ReservoirCreateDto } from "../../../Models/Reservoirs/ReservoirCreateDto";
import greaseReservoirsApiService from "../Utilities/GreaseReservoirApiService";
import { QueryClient } from "react-query";

import ReservoirValidationSchema from "../Utilities/ReservoirValidationSchema";
import { ListBox, ListBoxToolbar, processListBoxData,
  processListBoxDragAndDrop, ListBoxDragEvent,
  ListBoxItemClickEvent, ListBoxToolbarClickEvent
} from "@progress/kendo-react-listbox";
import { SvgIcon } from "@progress/kendo-react-common";
import { ReservoirUpdateDto } from "../../../Models/Reservoirs/ReservoirUpdateDto";
import { LinkedPoints } from "../../../Models/Reservoirs/ReservoirLinkedPoints";

interface Props {
  createReservoir: (reservoirCreateDto: ReservoirCreateDto) => void;
  reservoir: ReservoirReadDto | null;
  updateReservoir: any
  addReservoir: boolean;
  onClose: () => void;
  consumables: Array<ConsumableReadTableDto>;
  points: Array<PointReadDto>;
  endpoints: Array<EndpointReadDto>;
  linkedPoints: Array<LinkedPoints>;
  selectedItem: any;
  
}

const dataItemKey = "id";
const checkField = "checkField";
const checkIndeterminateField = "checkIndeterminateField";
const subItemsField = "items";
const expandField = "expanded";
const textField = "name";

const fields = {
  dataItemKey,
  checkField,
  checkIndeterminateField,
  expandField,
  subItemsField,
};

const SELECTED_FIELD = "selected";

const toolbarTools = [
  "transferTo",
  "transferFrom",
  "transferAllTo",
  "transferAllFrom"
];


function GetIcon (type: string)
{
    switch(type)
    {
      case "Endpoint":
        return <SvgIcon viewBox="0 0 638 769" style={{width: '16px', height: '16px'}}>
                  <path d="M226.972,76.989L226.972,60.763C226.972,53.496 232.872,47.596 240.139,47.596L247.347,47.596L247.347,9.92C247.347,4.445 251.793,0 257.268,
                  0L367.8,0C373.276,0 377.721,4.445 377.721,9.92L377.721,47.596L384.929,47.596C392.196,47.596 398.096,53.496 398.096,60.763L398.096,76.989C370.864,
                  69.408 342.169,65.356 312.534,65.356C282.9,65.356 254.204,69.408 226.972,76.989ZM398.096,691.291L398.096,707.289C398.096,714.556 392.196,720.456 
                  384.929,720.456L377.721,720.456L377.721,758.132C377.721,763.607 373.276,768.052 367.8,768.052L257.268,768.052C251.793,768.052 247.347,763.607 
                  247.347,758.132L247.347,720.456L240.139,720.456C232.872,720.456 226.972,714.556 226.972,707.289L226.972,691.291C254.204,698.872 282.9,702.924 
                  312.534,702.924C342.169,702.924 370.864,698.872 398.096,691.291ZM226.972,684.797C188.967,673.987 153.904,656.158 123.276,632.804C110.275,637.264 
                  95.285,634.311 84.918,623.944L73.755,612.781C63.446,602.472 60.468,587.59 64.822,574.639C24.181,521.889 0,455.816 0,384.14C0,312.482 24.169,246.424 
                  64.792,193.68C60.482,180.75 63.47,165.913 73.755,155.627L84.918,144.464C95.254,134.128 110.186,131.162 123.16,135.565C153.815,112.167 188.919,94.306 
                  226.972,83.483C254.168,75.747 282.871,71.606 312.534,71.606C342.197,71.606 370.9,75.747 398.096,83.483C436.486,94.402 471.873,112.483 502.719,136.187C516.07,
                  130.916 531.867,133.675 542.656,144.464L553.819,155.627C564.697,166.505 567.412,182.474 561.965,195.893C601.569,248.266 625.068,313.48 625.068,384.14C625.068,
                  454.82 601.555,520.051 561.931,572.432C567.423,585.869 564.719,601.881 553.819,612.781L542.656,623.944C531.838,634.763 515.983,637.507 502.609,
                  632.178C471.789,655.84 436.44,673.891 398.096,684.797C370.9,692.533 342.197,696.674 312.534,696.674C282.871,696.674 254.168,692.533 226.972,
                  684.797ZM312.75,184.945C202.612,184.945 113.195,274.362 113.195,384.5C113.195,494.638 202.612,584.055 312.75,584.055C422.888,584.055 512.305,
                  494.638 512.305,384.5C512.305,274.362 422.888,184.945 312.75,184.945ZM393.502,474.489C375.611,474.497 364.137,455.639 372.487,439.958L422.246,
                  346.565C427.074,337.492 422.016,326.348 411.977,323.907L273.483,290.266C256.475,286.144 247.848,267.294 255.937,251.882L432.631,294.326C456.824,
                  300.137 469.212,326.808 457.929,348.813L393.502,474.489ZM249.212,385.335L259.819,365.118C264.393,356.391 263.196,352.569 255.682,352.569C249.242,
                  352.569 244.768,355.754 240.976,363L199.759,441.526C197.303,446.216 199.575,450.038 205.171,450.038C210.328,450.038 216.614,446.63 218.51,443.015L229.471,
                  422.161L215.732,422.161L228.91,397.055L275.914,397.055L251.345,443.867C243.302,459.195 237.691,465.788 229.9,469.196C221.788,472.811 211.011,474.515 195.139,
                  474.515C176.895,474.515 166.211,472.811 161.037,468.766C156.386,465.358 157.177,457.698 163.44,445.778L210.029,357.044C217.067,343.627 222.34,337.679 230.138,
                  334.263C238.481,330.648 248.06,329.152 264.155,329.152C284.764,329.152 294.703,330.648 300.306,334.678C304.428,337.449 303.898,342.975 298.303,
                  353.621L281.655,385.335L249.212,385.335ZM382.94,332.558C407.938,332.351 411.093,335.751 401.898,354.479L387.153,382.563C381.351,393.201 379.048,
                  395.112 367.174,399.364C376.3,403.616 376.584,405.527 368.426,421.492L353.228,450.436C341.869,470.437 334.385,474.49 308.404,474.49L253.902,474.49L328.422,
                  332.543L382.94,332.543L382.94,332.558ZM342.544,387.037C348.876,386.83 354.526,383.422 357.55,377.673L362.569,368.102C367.052,359.161 365.494,355.96 357.02,
                  355.753L350.803,355.753L334.385,387.037L342.544,387.037ZM310.753,450.451C316.878,450.244 323.74,445.331 327.539,438.109L335.353,423.211C338.592,
                  417.048 335.997,413.003 328.805,412.78L320.869,412.78L301.098,450.451L310.753,450.451ZM438.415,417.062L459.469,417.062C465.118,417.062 468.771,
                  423.019 466.139,427.984L444.648,468.763C442.79,472.294 439.114,474.482 435.107,474.482L407.76,474.459L438.415,417.062Z"/>
                </SvgIcon>
  
      default:
        return <SvgIcon viewBox="0 0 535 842" style={{width: '14px', height: '14px'}}><g transform="matrix(-1,-1.22465e-16,1.22465e-16,-1,779.094,932.835)">
          <path d="M663.363,693.332C673.166,714.55 678.637,738.177 678.637,763.073C678.637,855.042 603.969,929.71 512,929.71C420.031,929.71 345.363,855.042 
          345.363,763.073C345.363,734.918 352.361,708.384 364.713,685.113C372.873,669.741 382.77,650.954 382.77,630.056C382.77,592.906 355.721,575.128 329.452,
          548.859C279.286,500.797 248.031,433.149 248.031,358.259C248.031,212.571 366.312,94.29 512,94.29C657.688,94.29 775.969,212.571 775.969,358.259C775.969,
          433.088 744.765,500.686 694.687,548.728C667.72,575.695 640.754,593.654 640.754,631.791C640.754,655.703 654.107,673.297 663.363,693.332ZM411.648,
          799.184C426.86,839.828 466.074,868.795 512,868.795C558.21,868.795 597.624,839.469 612.631,798.429L612.631,788.835L411.648,788.835L411.648,
          799.184ZM612.631,724.942C597.624,683.901 558.21,654.575 512,654.575C466.074,654.575 426.86,683.542 411.648,724.187L411.648,736.339L612.631,
          736.339L612.631,724.942ZM481.236,306.196L429.545,254.504C424.37,249.33 415.969,249.33 410.794,254.504C405.62,259.679 405.62,268.08 410.794,
          273.255L462.486,324.946C456.921,333.878 453.705,344.421 453.705,355.71C453.705,366.999 456.921,377.542 462.486,386.474L410.794,438.166C405.62,
          443.34 405.62,451.741 410.794,456.916C415.969,462.09 424.37,462.09 429.545,456.916L481.236,405.224C490.168,410.789 500.711,414.005 512,
          414.005C523.289,414.005 533.832,410.789 542.764,405.224L594.455,456.916C599.63,462.09 608.031,462.09 613.206,456.916C618.38,451.741 
          618.38,443.34 613.206,438.166L561.514,386.474C567.079,377.542 570.295,366.999 570.295,355.71C570.295,344.421 567.079,333.878 561.514,324.946L613.206,
          273.255C618.38,268.08 618.38,259.679 613.206,254.504C608.031,249.33 599.63,249.33 594.455,254.504L542.764,306.196C533.832,300.631 523.289,
          297.415 512,297.415C500.711,297.415 490.168,300.631 481.236,306.196Z"/></g>
          </SvgIcon>
    }
  }

const MyCustomItem = (props) => {
  let { dataItem, selected, ...others } = props;
  return (
    <li {...others}>
    <div className="grid grid-cols-2 gap-x-1 grid-cols-[15px_auto]">
          
          {/* First Row */}
         <div>{GetIcon(props.dataItem.type)}</div>
         <div>{props.dataItem.name}</div>
       
         {/* Second Row */}
        <div> </div>
        <div style={{fontSize:"12px"}}>S/N: {props.dataItem.data}</div>
         </div>
    </li>
  );
};

export default function ReservoirAddModal(props: Props) {
  const toast = useToast({
    position: "bottom",
    duration: 5000,
    isClosable: true,
  });
  const localizationService = useLocalization();


  const [state, setState] = React.useState<any>({
    available: [],
    linkedOutlets: [],
    linkedInlets: [],
    draggedItem: {},
  });

  const [availableFilter, setAvailableFilter] = useState<string>("");
  const [linkedOutletFilter, setLinkedOutletFilter] = useState<string>("");
  const [linkedInletFilter, setLinkedInletFilter] = useState<string>("");

  const handleItemClick = (event, data, connectedData, secondConnectedData) => {
    setState({
      ...state,
      [data]: state[data].map((item) => {
        if (item.id === event.dataItem.id) {
          item[SELECTED_FIELD] = !item[SELECTED_FIELD];
        } else if (!event.nativeEvent.ctrlKey) {
          item[SELECTED_FIELD] = false;
        }
        return item;
      }),
      [connectedData]: state[connectedData].map((item) => {
        item[SELECTED_FIELD] = false;
        return item;
      }),
      [secondConnectedData]: state[secondConnectedData].map((item) => {
        item[SELECTED_FIELD] = false;
        return item;
      }),
    });
  };

  const handleToolBarClick = (e: any, data: string, connectedData: string) => {
    let result = processListBoxData(
      state[data],
      state[connectedData],
      e.toolName,
      SELECTED_FIELD
    );
    setState({
      ...state,
      [data]: result.listBoxOneData,
      [connectedData]: result.listBoxTwoData,
    });
  };

  const handleDragStart = (e: ListBoxDragEvent) => {
    let target: any = e.target;
    e.dataItem.dataCollection = target.props.name || "";
    setState({
      ...state,
      draggedItem: e.dataItem,
    });
  };

  const handleDrop = (e: ListBoxDragEvent) => {
    let target: any = e.target;
    let dragItemData = state.draggedItem.dataCollection;
    let dropItemData = target.props.name;
    let result = processListBoxDragAndDrop(
      state[dragItemData],
      state[dropItemData],
      state.draggedItem,
      e.dataItem,
      "id"
    );
    setState({
      ...state,
      [dragItemData]: result.listBoxOneData,
      [dropItemData]: result.listBoxTwoData,
    });
  };

  const [showSelectionErrorMessage, setShowSelectionErrorMessage] = useState<boolean>(false);
  const [capacityUnit, setCapacityUnit] = useState<string>('cc');
  const queryClient = new QueryClient();

  const setListBoxState = (endpoints: EndpointReadDto[], linkedPoints: LinkedPoints[]) => {
    var endpointsAsLinkedPoints = endpoints.map(x => {return {id: x.id, name: x.name, type: x.pointType, flowType: "Outlet", data: x.endpointDeviceSerialNumber}}) as LinkedPoints[];
    var availableEndpoints = endpointsAsLinkedPoints.filter(f => !linkedPoints.some(item => item.id === f.id));

    var linkedOutlets = endpointsAsLinkedPoints.filter(f => linkedPoints.some(item => item.flowType === "Outlet" && item.id === f.id));
    var linkedInlets = endpointsAsLinkedPoints.filter(f => linkedPoints.some(item => item.flowType === "Inlet" && item.id === f.id));

    setState({
      ...state,
      available: availableEndpoints,
      linkedOutlets: linkedOutlets,
      linkedInlets: linkedInlets
    });
  }

  React.useEffect(() => {

    const fetchData = async (id: string) => {
      const linkedPoints = await queryClient.fetchQuery("History", async () => {return greaseReservoirsApiService.getLinkedPoints(id);});
      setListBoxState(props.endpoints, linkedPoints);
    }

    if (props.reservoir) {
      setCapacityUnit(props.reservoir.displayUnit);
      fetchData(props.reservoir.id);
    }
    else
    {
      setListBoxState(props.endpoints, props.linkedPoints);
    }
  }, [props.reservoir]);

  
  const handleSubmit = async (values: any, setStatus: any) => {
    if (showSelectionErrorMessage) {
      toast({
        status: "error",
        title: `${localizationService.toLanguageString('custom.error', enMessages.custom.error)}`,
        description: `${localizationService.toLanguageString('custom.mustSelectDay', enMessages.custom.mustSelectDay)}`,
      });
    } else {
      try {
        if (props.reservoir) {

          var linkedEndPointsInlet = state.linkedInlets.map(x => {return {id: x.id, name: x.name, type: x.type, flowType: "Inlet"}}) as LinkedPoints[];
          var linkedEndPointsOutlet = state.linkedOutlets.map(x => {return {id: x.id, name: x.name, type: x.type, flowType: "Outlet"}}) as LinkedPoints[];
          var linkedPoints = [...linkedEndPointsInlet, ...linkedEndPointsOutlet];

          const castValues = {
            name: values.name,
            description: values.description,
            capacity: values.capacity,
            displayUnit: values.capacityUnit,
            consumableId: values.consumable.id,
            consumableName: values.consumable.name,
            linkedPoints : linkedPoints
          }  as ReservoirUpdateDto
          const updateId = props.reservoir.id;
          await props.updateReservoir(updateId, castValues);
        }
        //If creating an entirely new schedule
        else {

          var linkedEndPointsInlet = state.linkedInlets.map(x => {return {id: x.id, name: x.name, type: x.type, flowType: "Inlet"}}) as LinkedPoints[];
          var linkedEndPointsOutlet = state.linkedOutlets.map(x => {return {id: x.id, name: x.name, type: x.type, flowType: "Outlet"}}) as LinkedPoints[];
          var linkedPoints = [...linkedEndPointsInlet, ...linkedEndPointsOutlet];

          const castValues = {
            name: values.name,
            description: values.description,
            capacity: values.capacity,
            displayUnit: values.capacityUnit,
            consumableId: values.consumable.id,
            consumableName: values.consumable.name,
            linkedPoints : linkedPoints
          } as ReservoirCreateDto;

          await props.createReservoir(castValues);
        }

        toast({
          status: "success",
          description: `'${values.name}' ${localizationService.toLanguageString('custom.reservoirSuccessfully', enMessages.custom.reservoirSuccessfully)} ${
            props.reservoir ? localizationService.toLanguageString('custom.updated', enMessages.custom.updated) : localizationService.toLanguageString('custom.created', enMessages.custom.created)
          }`,
        });
      } catch (e: any) {
        //On failure show error toast, set status messages
        setStatus(e?.response?.data?.errors);

        toast({
          title: `${localizationService.toLanguageString('custom.error', enMessages.custom.error)}`,
          status: "error",
          description: `${localizationService.toLanguageString('custom.somethingWentWrong', enMessages.custom.somethingWentWrong)} \n
          ${e?.response?.data?.errors ? Object.values(e?.response?.data?.errors)[0] : ""}`,
        });
      }
    }
  };

  const initialValueGenerator = (update: boolean) => {
    if (update) {
      return {
        name: props.reservoir?.name,
        description: props.reservoir?.description,
        capacity: props.reservoir !== null ? props.reservoir.capacity : 0,
        capacityUnit: props.reservoir?.displayUnit,
        consumable: props.consumables.find(x => x.id == props.reservoir?.consumableId)
      }
    } else {
      return {
        name:'',
        description:'',
        capacity: 0,
        capacityUnit:'cc',
        consumable: '',
        linked:'',
      }
    }
  };

  const defaultValue = {name: props.consumables.find(x => x.id == props.reservoir?.consumableId)?.name};

  const processedAvailableData = useMemo(() => {

    if (availableFilter)
    {
      return state.available.filter(item => item.name.toLowerCase().includes(availableFilter.toLowerCase()));
    }
    
    return state.available;

  }, [availableFilter, state.linkedOutlets, state.linkedInlets]);

  const processedLinkedOutletData = useMemo(() => {

    if (linkedOutletFilter)
    {
      return state.linkedOutlets.filter(item => item.name.toLowerCase().includes(linkedOutletFilter.toLowerCase()));
    }
    
    return state.linkedOutlets;

  }, [linkedOutletFilter, state.available, state.linkedInlets]);

  const processedLinkedInletData = useMemo(() => {

    if (linkedInletFilter)
    {
      return state.linkedInlets.filter(item => item.name.toLowerCase().includes(linkedInletFilter.toLowerCase()));
    }
    
    return state.linkedInlets;

  }, [linkedInletFilter, state.available, state.linkedOutlets]);

  
  const onAvailableFilterChange = (event: any) => {
    setAvailableFilter(event.value);
  };

  const onLinkedOutletFilterChange = (event: any) => {
    setLinkedOutletFilter(event.value);
  };

  const onLinkedInletFilterChange = (event: any) => {
    setLinkedInletFilter(event.value);
  };

  const onAvailableFilterClearButtonClick = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    setAvailableFilter("");
  };

  const onLinkedOutletFilterClearButtonClick = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    setLinkedOutletFilter("");
  };

  const onLinkedInletFilterClearButtonClick = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    setLinkedInletFilter("");
  };

  return (
    <div>
      {(props.addReservoir || props.reservoir !== null) && (
        <Dialog title={props.reservoir ? 
          `${localizationService.toLanguageString('custom.updateReservoir', enMessages.custom.updateReservoir)}` : 
          `${localizationService.toLanguageString('custom.addReservoir', enMessages.custom.addReservoir)}`} 
          onClose={props.onClose} >
          <Formik
            onSubmit={async (values, { setStatus }) => {
              await handleSubmit(values, setStatus);
            }}
            validationSchema={ReservoirValidationSchema}
            initialValues={initialValueGenerator(props.reservoir ? true : false)}
          >
            {({ submitForm, values, initialValues, setFieldValue }) => (
              <>
                <Form
                  style={{
                    minWidth: "800px",
                  }}
                >
                  <div className="p-2">
                    <label htmlFor="name">Name</label>
                    <Field
                      className="k-input k-input-md k-rounded-md k-input-solid h-8"
                      type="text"
                      name="name"
                      placeholder={localizationService.toLanguageString('custom.name', enMessages.custom.name)}
                      autocomplete="off"
                    />
                    <FieldErrorMessage name="name" />
                  </div>
                  <div className="p-2">
                    <label htmlFor="description">Description</label>
                    <Field
                      className="k-input k-input-md k-rounded-md k-input-solid h-8"
                      type="text"
                      name="description"
                      placeholder={localizationService.toLanguageString('custom.description', enMessages.custom.description)}
                      autocomplete="off"
                    />
                    <FieldErrorMessage name="desciption" />
                  </div>

                  <div className="p-2">
                    <label htmlFor="capacity">Capacity</label>
                    <div className="flex gap-1">
          
                    <NumericTextBox
                    style={{ width: "110px" }}
                       onChange={(x) => {setFieldValue("capacity", x.value)}}
                       min={0}
                       defaultValue={initialValues.capacity}
                    />
                    <FieldErrorMessage name="capacity" />
                     <DropDownList
                     style={{ width: "70px" }}
                      onChange={(x) => {
                        setCapacityUnit(x.value)
                        setFieldValue("capacityUnit", x.value)}}
                      data={["kg", "cc", "g"]}
                      defaultValue={'cc'}
                      value={capacityUnit}
                    />
                    <FieldErrorMessage name="capacityUnit" />
                    </div>
                  </div>
                  {capacityUnit != 'cc' && (
                    <div className="pl-2 pb-2">{localizationService.toLanguageString('custom.noteReservoir', enMessages.custom.noteReservoir)}</div>
                  )}
                  <div className="p-2 w-full">
                    <label htmlFor="consumable">{localizationService.toLanguageString('custom.consumable', enMessages.custom.consumable)}</label>

                    <DropDownList
                      onChange={(x) => {setFieldValue("consumable", x.value)}}
                      data={props.consumables}
                      textField="name"
                      defaultValue={defaultValue}
                    />
                  </div>

                  <div className="p-2 w-full">
                    <label htmlFor="linked">{localizationService.toLanguageString('custom.linkedPoints', enMessages.custom.linkedPoints)}</label>

                  </div>

        <div className="grid grid-cols-3 gap-x-5 gap-y-2 p-2">
          
           {/* First Row */}
          <div>{localizationService.toLanguageString('custom.available', enMessages.custom.available)}</div>
          <div>{localizationService.toLanguageString('custom.outlets', enMessages.custom.outlets)}</div>
          <div>{localizationService.toLanguageString('custom.inlets', enMessages.custom.inlets)}</div>
          
          {/* Second Row */}
          <div className="flex space-x-2">
            <Input  onChange={onAvailableFilterChange} value={availableFilter} placeholder={localizationService.toLanguageString('custom.search', enMessages.custom.search)}/>
            <Button title="Clear" disabled={!availableFilter} onClick={onAvailableFilterClearButtonClick} icon="filter-clear" style={{width:"30px"}}/>
          </div>

          <div className="flex space-x-2">
            <Input onChange={onLinkedOutletFilterChange} value={linkedOutletFilter} placeholder={localizationService.toLanguageString('custom.search', enMessages.custom.search)}/>
            <Button title="Clear" disabled={!linkedOutletFilter} onClick={onLinkedOutletFilterClearButtonClick} icon="filter-clear" style={{width:"30px"}}/>
          </div>

          <div className="flex space-x-2">
            <Input  onChange={onLinkedInletFilterChange} value={linkedInletFilter} placeholder={localizationService.toLanguageString('custom.search', enMessages.custom.search)}/>
            <Button title="Clear" disabled={!linkedInletFilter} onClick={onLinkedInletFilterClearButtonClick} icon="filter-clear" style={{width:"30px"}}/>
          </div>

          {/* Third Row */}          
          <div>
            <ListBox
              style={{ height: "250px", width: '100%' }}
              data={processedAvailableData}
              textField="name"
              selectedField={SELECTED_FIELD}
              onItemClick={(e: ListBoxItemClickEvent) => handleItemClick(e, 'available', 'linkedOutlets', 'linkedInlets')}
              onDragStart={handleDragStart}
              onDrop={handleDrop}
              item={MyCustomItem}
              // @ts-ignore: for specific use
              name='available'
              toolbar={() => {
                  return (
                    <ListBoxToolbar
                      tools={toolbarTools}
                      data={state.available}
                      dataConnected={state.linkedOutlets}
                      onToolClick={(e: ListBoxToolbarClickEvent) => handleToolBarClick(e, 'available', 'linkedOutlets')}
                      />
                  );
              }}
            />
          </div>
          <div>
            <ListBox
              style={{ height: "250px", width: '100%' }}
              data={processedLinkedOutletData}
              textField="name"
              selectedField={SELECTED_FIELD}
              onItemClick={(e: ListBoxItemClickEvent) => handleItemClick(e, 'linkedOutlets', 'available', 'linkedInlets')}
              onDragStart={handleDragStart}
              onDrop={handleDrop}
              item={MyCustomItem}
              // @ts-ignore: for specific use
              name='linkedOutlets'
              toolbar={() => {
                  return (
                    <ListBoxToolbar
                      tools={toolbarTools}
                      data={state.linkedOutlets}
                      dataConnected={state.linkedInlets}
                      onToolClick={(e: ListBoxToolbarClickEvent) => handleToolBarClick(e, 'linkedOutlets', 'linkedInlets')}
                    />
                  );
              }}
             />
          </div>
          <div>
            <ListBox
              style={{ height: "250px", width: '85%' }}
              data={processedLinkedInletData}
              textField="name"
              selectedField={SELECTED_FIELD}
              onItemClick={(e: ListBoxItemClickEvent) => handleItemClick(e, 'linkedInlets', 'linkedOutlets', 'available')}
              onDragStart={handleDragStart}
              onDrop={handleDrop}
              item={MyCustomItem}
              // @ts-ignore: for specific use
              name='linkedInlets'
             
            />
          </div>
        </div>

                </Form>

                <DialogActionsBar>
                  <Button
                    themeColor={"success"}
                    onClick={() => {
                      submitForm();
                    }}
                  >
                    {localizationService.toLanguageString('custom.save', enMessages.custom.save)}
                  </Button>
                  <Button onClick={props.onClose}>{localizationService.toLanguageString('custom.cancel', enMessages.custom.cancel)}</Button>
                </DialogActionsBar>
              </>
            )}
          </Formik>
        </Dialog>
      )}
    </div>
  );
}
