import { useLocalization } from '@progress/kendo-react-intl';

import { useMemo } from 'react';
import { useQueries, useQuery } from 'react-query';
import LoadingSpinner from '../Components/ProcessingSpinner';
import { ReactQueryKeys } from '../../../Constants/ClientRoutingConstants';
import ReservoirsReportTable from '../Components/Reservoirs/ReservoirsReportTable';
import { ReservoirReadDto } from '../../../Models/Reservoirs/ReservoirReadDto';
import greaseReservoirsApiService from '../../Reservoir/Utilities/GreaseReservoirApiService';
import analysisApiService from '../../Reservoir/Utilities/AnalysisApiService';
import { enMessages } from '../../../messages/en-US'; 
import ReservoirsStockManagementTable from '../Components/Reservoirs/ReservoirsStockManagementTable';

interface Props {}

export default function ReservoirsReportPage(props: Props, ) {
  const localizationService = useLocalization();

  const greaseReservoirsQuery = useQuery<Array<ReservoirReadDto>>(ReactQueryKeys.AllGreaseReservoirsQuery, async () => {
    return greaseReservoirsApiService.getAllReservoirs();
  });

  const reservoirQueries = useQueries(
    greaseReservoirsQuery.data?.map((reservoir) => ({
      queryKey: ['reservoir', reservoir.id],
      queryFn: () => analysisApiService.getReservoirStatus(reservoir.id), enabled: !!greaseReservoirsQuery.data
    })) || []
  );

    const reservoirs = useMemo(() => {
      return reservoirQueries.map(reservoir => {
        return {
          ...reservoir.data,
          estimatedVolume: !Number.isNaN(reservoir.data?.estimatedVolume) ? reservoir.data?.estimatedVolume?.toFixed(2) : NaN,
          averageDailyConsumption: reservoir.data?.averageDailyConsumption?.toFixed(2),
          estimatedEmptyDate: reservoir.data?.estimatedEmptyDate ? new Date(reservoir.data?.estimatedEmptyDate) : null,
          lastResetDate: reservoir.data?.lastResetDate ? new Date(reservoir.data?.lastResetDate) : null,
        }})
      }, [reservoirQueries]);

  return (
    <div className='m-5'>
      <h2 className="gb-h2 mb-5">{localizationService.toLanguageString('custom.reservoirsReport', enMessages.custom.reservoirsReport)}</h2>
      
      {(reservoirQueries.some(result => result.isLoading || result.isFetching) || greaseReservoirsQuery.isLoading || greaseReservoirsQuery.isFetching) ? 
        <LoadingSpinner /> 
        : 
        <div>
          <ReservoirsStockManagementTable key={`ReservoirsStockManagementTable`} reservoir={reservoirs}/>
          <ReservoirsReportTable key={`ReservoirsReportTable`} reservoir={reservoirs}/>
        </div>
        
        }
    </div>
  );
}
