import { ReactElement} from "react";
import {TabStrip, TabStripSelectEventArguments, TabStripTab,} from '@progress/kendo-react-layout';
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../../messages/en-US";
import React from "react";
import { EventFilter } from "../../../Events/Utilities/EventsApiService";
import AllPointsActivityTabPage from "../Points/AllPointsActivityTabPage";
import AllEndpointActivityTabPage from "../Endpoints/Activity/AllEndpointsActivityTabPage";
import NoDataPage from "../NoDataPage";

interface Props {
  selectedItem: string
  eventFilter: EventFilter
  parentItems: any
}

export default function AssetDetailsPage(props: Props): ReactElement {
  const localizationService = useLocalization();
  const points = props.parentItems[0].points
  const endpoints = props.parentItems[0].endpoints

  //TabStrip
  const [selected, setSelected] = React.useState<number>(0);

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected);
  };

  return (
    <div className="tabstrip">
      {points.length ===0 && endpoints.length === 0 && 
      <div className="h-full">
        <NoDataPage />
      </div>
        
      }
      
      <TabStrip selected={selected} onSelect={handleSelect} className="h-full">
      {endpoints.length > 0 &&
        <TabStripTab title={`${localizationService.toLanguageString('custom.endpointActivity', enMessages.custom.endpointActivity)}`}>
          <AllEndpointActivityTabPage 
              key={`${props.selectedItem}AllEndpointActivityTabPage`}
              eventFilter={props.eventFilter} 
              endpointsIds={endpoints}
              selectedAssetId={props.selectedItem} 
              />
              
        </TabStripTab>
      }
        
      {points.length > 0  &&
        <TabStripTab title={`${localizationService.toLanguageString('custom.pointActivity', enMessages.custom.pointActivity)}`}>
          <AllPointsActivityTabPage 
              points={points} eventFilter={props.eventFilter} />
        </TabStripTab>
       }
      
      </TabStrip> 
    </div>
  )
}
