import { ReactElement, useEffect, useMemo, useRef, useState } from "react";
import { useLocalization } from '@progress/kendo-react-intl';
import moment from "moment";
import { Grid, GridColumn, GridColumnMenuWrapper, GridHeaderCellProps, GridToolbar } from "@progress/kendo-react-grid";
import { enMessages } from "../../../../messages/en-US";
import { SortDescriptor, process } from "@progress/kendo-data-query";
import { useRefState } from "../../../Shared/Hooks/useRefState";
import { CustomColumnMenu } from "../../Utilities/customColumnMenu";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { Button } from "@progress/kendo-react-buttons";
import { Tooltip } from "@progress/kendo-react-tooltip";

interface Props {
 reservoir: any;
}

export default function ReservoirsStockManagementTable(props: Props): ReactElement {

  const localizationService = useLocalization();
  const _export = useRef<ExcelExport | null>(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };
  const initialSort: Array<SortDescriptor> = [
    { field: "currentMonth", dir: "desc" },
  ];
  const [sort, setSort] = useState(initialSort);

  const months = {
    1: localizationService.toLanguageString('custom.january', enMessages.custom.january), 
    2: localizationService.toLanguageString('custom.february', enMessages.custom.february), 
    3: localizationService.toLanguageString('custom.march', enMessages.custom.march), 
    4: localizationService.toLanguageString('custom.april', enMessages.custom.april), 
    5: localizationService.toLanguageString('custom.may', enMessages.custom.may), 
    6: localizationService.toLanguageString('custom.june', enMessages.custom.june), 
    7: localizationService.toLanguageString('custom.july', enMessages.custom.july), 
    8: localizationService.toLanguageString('custom.august', enMessages.custom.august), 
    9: localizationService.toLanguageString('custom.september', enMessages.custom.september), 
    10: localizationService.toLanguageString('custom.october', enMessages.custom.october), 
    11: localizationService.toLanguageString('custom.november', enMessages.custom.november), 
    12: localizationService.toLanguageString('custom.december', enMessages.custom.december)
  }

  const currentMonth = moment().format('MMMM/YYYY');
  const nextMonth = moment().add(1, 'months').format('MMMM/YYYY');
  const nextNextMonth = moment().add(2, 'months').format('MMMM/YYYY');

  const currentMonthNumber = moment().format('M');
  const currentMonthYear = moment().format('YYYY');
  const nextMonthNumber = moment().add(1, 'months').format('M');
  const nextMonthYear = moment().add(1, 'months').format('YYYY');
  const nextNextMonthNumber = moment().add(2, 'months').format('M');
  const nextNextMonthYear = moment().add(2, 'months').format('YYYY');

  const stockManagement = useMemo(() => {
    return props.reservoir.map(reservoir => {
      return {
        consumableName: reservoir.consumableName,
        consumableId: reservoir.consumableId,
        capacity: reservoir.capacity + ' ' + reservoir.displayUnit,
        depletionMonth: moment(reservoir.estimatedEmptyDate).format('MMMM/YYYY') ,
        currentMonth: moment(reservoir.estimatedEmptyDate).format('MMMM/YYYY') === currentMonth ? 1 : 0,
        nextMonth: moment(reservoir.estimatedEmptyDate).format('MMMM/YYYY') === nextMonth ? 1 : 0,
        nextNextMonth: moment(reservoir.estimatedEmptyDate).format('MMMM/YYYY') === nextNextMonth ? 1 : 0,
      }})
  }, [props.reservoir]);

  const aggregatedDataMap = new Map<string, any>();

  // Aggregate data
  stockManagement.forEach(item => {
    const key = `${item.consumableId}-${item.capacity}-${item.depletionMonth}`;
    if (aggregatedDataMap.has(key)) {
      const existingEntry = aggregatedDataMap.get(key)!;
      existingEntry.currentMonth += item.currentMonth;
      existingEntry.nextMonth += item.nextMonth;
      existingEntry.nextNextMonth += item.nextNextMonth;
    } else {
      aggregatedDataMap.set(key, { ...item });
    }
  });

  // Convert the aggregated map values back to an array
  const aggregatedData: any[] = Array.from(aggregatedDataMap.values()).filter((item) => item.depletionMonth === currentMonth || item.depletionMonth === nextMonth || item.depletionMonth === nextNextMonth);

   //Creating dataState to prevent issues with virtual scrolling
   const createAppState = (dataState) => {
    return {
      result: process(aggregatedData, dataState),
      dataState: dataState,
    };
  };

  const [dataState, setDataState] = useState<any>(
    createAppState({
      skip:0,
      take: 30,
      sort: sort,
    })
  );
 
  const dataStateChange = (event) => {
    setDataState(createAppState(event.dataState));
  };

  useEffect(() => {
    setDataState( createAppState({
      skip: 0,
      take: 30,
      sort: sort,
    }));
  }, [props]);


  const getExcelExportData = () => {
    return process(aggregatedData, {filter: dataState.dataState.filter, sort: dataState.dataState.sort}).data;
  };

  const HeaderCurrentCell = (props: GridHeaderCellProps) => {
    return (
    <div style={{height:'60px'}}>
      <div>{localizationService.toLanguageString('custom.currentMonth', enMessages.custom.currentMonth)}</div>
      <div>({months[currentMonthNumber]}/{currentMonthYear})</div>
      {props.children}
      <GridColumnMenuWrapper {...props.columnMenuWrapperProps} />
    </div>)
  };
  const HeaderNextCell = (props: GridHeaderCellProps) => {
    return (
    <div style={{height:'60px'}}>
      <div>{localizationService.toLanguageString('custom.nextMonth', enMessages.custom.nextMonth)}</div>
      <div>({months[nextMonthNumber]}/{nextMonthYear})</div>
      {props.children}
      <GridColumnMenuWrapper {...props.columnMenuWrapperProps} />
    </div>)
  };

  const HeaderNextNextCell = (props: GridHeaderCellProps) => {
    return (
    <div style={{height:'60px'}}>
      <div>{localizationService.toLanguageString('custom.nextNextMonth', enMessages.custom.nextNextMonth)}</div>
      <div>({months[nextNextMonthNumber]}/{nextNextMonthYear})</div>
      {props.children}
      <GridColumnMenuWrapper {...props.columnMenuWrapperProps} />
    </div>)
  };

  const HeaderCell = (props: any) => {
    return <div style={{height:'60px'}}><div>{props.title}</div><div style={{height:'25px', width:'20px'}}></div>{props.children}
    <GridColumnMenuWrapper {...props.columnMenuWrapperProps}/> </div>
  };


    // Columns for the table
    const columns: Array<any> = [
      {
        field:"consumableName", 
        title:localizationService.toLanguageString('custom.consumable', enMessages.custom.consumable),
        headerCell: HeaderCell,
        filterable: true,
      },
      {
        field:"capacity", 
        title:localizationService.toLanguageString('custom.capacity', enMessages.custom.name),
        headerCell: HeaderCell,
        filterable: true,
      },
      {
        field: "currentMonth",
        title:`Current Month (${currentMonth})`,
        headerCell: HeaderCurrentCell,
        filterable: true,
        filter: "text",
      },
      {
        field: "nextMonth",
        title:`Next Month (${nextMonth})`,
        headerCell: HeaderNextCell,
        filterable: true,
        filter: "text",
      },
      {
        field: "nextNextMonth",
        title:`2 Months (${nextNextMonth})`,
        headerCell: HeaderNextNextCell,
        filterable: true,
        filter: "text",
      },
    ]

    const [stateColumns, stateColumnsRef, setStateColumns] = useRefState<any>(columns);

    const onColumnsSubmit = (columnsState: Array<any>) => {
      setStateColumns(columnsState);
    };

return (
  <div className='mb-5'>
     <ExcelExport data={getExcelExportData()} ref={_export} collapsible={true} fileName={localizationService.toLanguageString('custom.futureReservoirRequirements', enMessages.custom.futureReservoirRequirements)}>
        <ExcelExportColumn field="consumableName" title={localizationService.toLanguageString('custom.consumable', enMessages.custom.consumable)} />
        <ExcelExportColumn field="capacity" title={localizationService.toLanguageString('custom.capacity', enMessages.custom.capacity)}/>
        <ExcelExportColumn field={"currentMonth"} title={currentMonth} />
        <ExcelExportColumn field={"nextMonth"} title={nextMonth} />
        <ExcelExportColumn field={"nextNextMonth"} title={nextNextMonth} />
     </ExcelExport>

    <Grid
    key={'ReservoirsStockManagementTable'}
    data={dataState.result}
    scrollable={"virtual"} 
    style={{height:"330px"}} 
    take={30}
    rowHeight={50}
    onDataStateChange={dataStateChange}
    {...dataState.dataState}
    sortable={true}
    >
    <GridToolbar >
      <div style={{width:'100%', display:'flex', justifyContent:'space-between'}}>
        <div className="gb-h3 pl-1">{localizationService.toLanguageString('custom.futureReservoirRequirements', enMessages.custom.futureReservoirRequirements)}</div>
          <Tooltip anchorElement="target" position="bottom">
            <Button
              title={localizationService.toLanguageString('custom.exportToExcel', enMessages.custom.exportToExcel)}
              icon="download"
              disabled={dataState.result.data.length === 0}
              onClick={excelExport}
            />
          </Tooltip>
      </div>
    </GridToolbar>
    {stateColumns.map(
      (column, idx) =>
        <GridColumn
          key={idx}
          field={column.field}
          title={column.title}
          filter={column.filter}
          cell={column.cell}
          filterable={column.filterable}
          headerCell={column.headerCell}
          columnMenu={(props) => (
            <CustomColumnMenu
              {...props}
              columns={stateColumns}
              onColumnsSubmit={onColumnsSubmit}
              data={aggregatedData}
              filterable={column.filterable}
            />
          )}
      />
      )}

    </Grid>
    
  </div>
  )
}