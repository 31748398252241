import { ReactElement, useMemo, useState } from "react";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";
import { Icon } from "@progress/kendo-react-common";

export default function NoDataPage(): ReactElement {
  const localizationService = useLocalization();


  return (
    <div className="gb-text-normal  text-lg text-center"
        style={{ borderRadius: '3px', padding: '40px 25px',margin: '30px auto'}}>
        <div className="text-center mb-5">
          <Icon name="k-icon k-i-warning" style={{fontSize:'72px'}}/>
        </div>
        <div>
        
            <div>{`${localizationService.toLanguageString('custom.noDataFound', enMessages.custom.noDataFound)}`}</div>
            <br></br>
            <div>{`${localizationService.toLanguageString('custom.changeDateRangeAndPoint', enMessages.custom.changeDateRangeAndPoint)}`}</div>
        </div>
    </div> 
  )}
