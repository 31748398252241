import { ReactElement, useEffect, useMemo, useState, } from "react";
import { useLocalization } from '@progress/kendo-react-intl';
import EndpointReservoirTabAddLinkPage from "./EndpointReservoirTabAddLinkPage";
import EndpointReservoirPage from "./EndpointReservoirPage";
import greaseReservoirsApiService from "../../../../Reservoir/Utilities/GreaseReservoirApiService";
import { TreeListReadDto } from "../../../../../Models/TreeList/TreeListReadDto";


interface Props {
  selectedTreeNode : TreeListReadDto;
  onLinkReservoir: () => void;
}

export default function EndpointReservoirTabPage(props: Props): ReactElement {
  const localizationService = useLocalization();

  const [reservoirId, setReservoirId] = useState<string>(props.selectedTreeNode.reservoirId);
  
  useEffect(() => {
    setReservoirId(props.selectedTreeNode.reservoirId);
  }, [props.selectedTreeNode.reservoirId]);

  const OnLinkReservoir = async (reservoirId: string) => {
    await greaseReservoirsApiService.linkReservoir(reservoirId, props.selectedTreeNode.id);
    props.onLinkReservoir();
  };

  const OnAddAndLinkReservoir = () => {
    props.onLinkReservoir();
  };

  return (
    <div style={{height:'100%', width:'100%'}}>
      {reservoirId !== "" && reservoirId !== undefined ? 
        <EndpointReservoirPage selectedItem={props.selectedTreeNode.data} reservoirId={reservoirId} /> :
          <EndpointReservoirTabAddLinkPage selectedTreeNode={props.selectedTreeNode} OnLinkReservoir={OnLinkReservoir} OnAddAndLink={OnAddAndLinkReservoir}/>}
    </div>
  )
}