import { Button, ButtonGroup, Toolbar, ToolbarItem, ToolbarSpacer } from "@progress/kendo-react-buttons";
import { useLocalization } from "@progress/kendo-react-intl";
import useChartStore from "../../../Zustand/chartStore";
import { enMessages } from "../../../messages/en-US";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsUpDown, faBarsProgress, faChartLine } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { Tooltip } from "@progress/kendo-react-tooltip";

const TooltipContentTemplate = (props: any) => {
  return (
    <div>
      {props.title}
    </div>
  );
};

export interface ChartToolbarConfig {
  title: string;
  lastSeen: string | null;
  showAutoRange: boolean;
  showThresholds: boolean;
  showAggregatorOptions: boolean;
  showChartOptions: boolean;
  showSingleMultipleChart: boolean;    
};

const aggregatorNone = 'none';
const aggregatorDaily = 'daily';

export const ChartToolbar = (props: ChartToolbarConfig) => {

  const localizationService = useLocalization();
  const {isAutoRange, setAutoRange} = useChartStore((state) => ({isAutoRange: state.isAutoRange, setAutoRange: state.setAutoRange}));
  const {isBarChartOn, setBarChartOn} = useChartStore((state) => ({isBarChartOn: state.isBarChartOn, setBarChartOn: state.setBarChartOn}));
  const {aggregator, setAggregator} = useChartStore((state) => ({aggregator: state.aggregator, setAggregator: state.setAggregator}));
  const {isSinglePressureChart, setSinglePressureChart} = useChartStore((state) => ({isSinglePressureChart: state.isSinglePressureChart, setSinglePressureChart: state.setSinglePressureChart}));
  const {isThresholdsOn, setThresholdsOn} = useChartStore((state) => ({isThresholdsOn: state.isThresholdsOn, setThresholdsOn: state.setThresholdsOn}));

  const autoRangeButton = () => {
    return (
      <Button 
        title={localizationService.toLanguageString('custom.autoRange', enMessages.custom.autoRange)} 
        togglable={true} 
        selected={isAutoRange} 
        onClick={() => { setAutoRange(!isAutoRange)}}><FontAwesomeIcon 
        title={localizationService.toLanguageString('custom.autoRange', enMessages.custom.autoRange)} 
        icon={faArrowsUpDown}/>
      </Button>
    )
  }

  const thresholdButton = () => {
    return (
      <Button 
        title={localizationService.toLanguageString('custom.thresholds', enMessages.custom.thresholds)} 
        togglable={true} selected={isThresholdsOn} 
        onClick={() => setThresholdsOn(!isThresholdsOn)}><FontAwesomeIcon 
        title={localizationService.toLanguageString('custom.thresholds', enMessages.custom.thresholds)} 
        icon={faBarsProgress}
        />
      </Button>
    )
  }

  return(
    <div>
      <Toolbar>
        <ToolbarItem>
          <div>
            <div className="text-base" style={{fontWeight:'bold'}}>{props.title}</div>
            {props.lastSeen !== null && <div>{localizationService.toLanguageString('custom.lastSeen', enMessages.custom.lastSeen)}: {props.lastSeen}</div>}
          </div>
        </ToolbarItem>
        <ToolbarSpacer />
          {props.showSingleMultipleChart && 
          <ToolbarItem>
            <ButtonGroup >
              <Button 
                title={localizationService.toLanguageString('custom.singleOrMultipleChart', enMessages.custom.singleOrMultipleChart)} 
                togglable={true} 
                selected={!isSinglePressureChart} 
                onClick={() => setSinglePressureChart(!isSinglePressureChart)} icon="windows">
              </Button>
            </ButtonGroup >
          </ToolbarItem>
          }
          {(props.showAutoRange && props.showThresholds) ?
          <ToolbarItem>
            <ButtonGroup >
              {props.showAutoRange &&
               autoRangeButton()
              }
              {props.showThresholds &&
               thresholdButton()
              }
            </ButtonGroup >
          </ToolbarItem>
          :
          <ToolbarItem>
              {props.showAutoRange &&
                autoRangeButton()
              }
              {props.showThresholds &&
                thresholdButton()
              }
          </ToolbarItem>
          }
          {props.showAggregatorOptions &&
          <ToolbarItem>
            <ButtonGroup >
              <Button 
                togglable={true} 
                selected={aggregator === aggregatorNone} 
                onClick={() => {setAggregator(aggregatorNone)}}>{localizationService.toLanguageString('custom.none', enMessages.custom.none)}
              </Button>
              <Button 
                togglable={true} 
                selected={aggregator === aggregatorDaily} 
                onClick={() => {setAggregator(aggregatorDaily)}}>{localizationService.toLanguageString('custom.daily', enMessages.custom.daily)}
              </Button>
            </ButtonGroup >
          </ToolbarItem>
          }
          {props.showChartOptions && 
          <ToolbarItem>
            <Tooltip position={'bottom'} anchorElement={'target'} content={(props) => <TooltipContentTemplate title={props.title} />} openDelay={2}>
              <ButtonGroup >
                <Button 
                  title={localizationService.toLanguageString('custom.barChart', enMessages.custom.barChart)} 
                  icon="chart-column-clustered" 
                  togglable={true} 
                  selected={isBarChartOn} 
                  onClick={() => {setBarChartOn(true)}}
                />
                <Button 
                  title={localizationService.toLanguageString('custom.lineChart', enMessages.custom.lineChart)} 
                  togglable={true} selected={!isBarChartOn} 
                  onClick={() => {setBarChartOn(false)}}>
                    <FontAwesomeIcon title={localizationService.toLanguageString('custom.lineChart', enMessages.custom.lineChart)} icon={faChartLine}/>
                </Button>
              </ButtonGroup >
            </Tooltip>  
          </ToolbarItem>
          }
      </Toolbar>
    </div>
  );
}