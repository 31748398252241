import { ReactElement, useRef, useState, } from "react";
import { StatusChart } from "../../../Components/Charts/Status/StatusChart";
import { IInitResult, SciChartGroup } from "scichart-react";
import { ModifierGroup } from "../../../../Shared/SciChart/ModifierGroup";
import { ChartModifierBase2D, ISciChartSurfaceBase, SciChartVerticalGroup } from "scichart";
import { synchronizeXVisibleRanges } from "../../../../Shared/SciChart/ChartConfiguration";
import ChartWaitLoader from "../../../../Shared/Components/ChartWaitLoader";
import useChartStore from "../../../../../Zustand/chartStore";
import moment from "moment";
import { GetVisibleRangeSingleSeries } from "../../../../../Utilities/ChartUtilities";
import { ActivityFlowRateChart } from "../../../Components/Charts/Activity/ActivityFlowRateChart";


interface Props {
  statusChartData: any;
  activityFlowRateChartData: any;
}

export default function EndpointStatusFlowRateActivity(props: Props): ReactElement {
  
  const [modifierGroup] = useState(new ModifierGroup());
  const verticalGroup = new SciChartVerticalGroup();
  const [isChartsInitialised, setIsChartsInitialised] = useState(false);

  // Fetch the initial state, but we don't need to re-render when it changes as the chart will update itself
  const visibleRangeRef = useRef(useChartStore.getState().visibleRange);

  let visibleRange = GetVisibleRangeSingleSeries(
    visibleRangeRef.current, 
    props.activityFlowRateChartData.timestamps, 
    moment(props.activityFlowRateChartData.dateFilter.startDate).unix(), 
    moment(props.activityFlowRateChartData.dateFilter.endDate).unix());
  
  const statusChartData = {...props.statusChartData, ...{modifierGroup: modifierGroup}, visibleRange};
  const activityFlowRateChartData = {...props.activityFlowRateChartData, ...{modifierGroup: modifierGroup}, visibleRange};
  
  const configureStatusAndActivityDataBindings = (initResults: IInitResult<ISciChartSurfaceBase>[] | [any, any]) => {
    const [statusChart, activityFlowRateChart] = initResults;
    
    modifierGroup.clear();

    var statusRollOverModifier = statusChart.sciChartSurface.chartModifiers.getById("StatusRollOver");
    modifierGroup.add(statusRollOverModifier as ChartModifierBase2D);

    const activityModifierGroupModifier = activityFlowRateChart.sciChartSurface.chartModifiers.getById("ActivityModifierGroup");
    const activityRollOverModifier = activityFlowRateChart.sciChartSurface.chartModifiers.getById("ActivityRollOver");
    modifierGroup.add(activityModifierGroupModifier as ChartModifierBase2D, activityRollOverModifier as ChartModifierBase2D);

    synchronizeXVisibleRanges([statusChart.sciChartSurface, activityFlowRateChart.sciChartSurface], () => true);

    verticalGroup.addSurfaceToGroup(statusChart.sciChartSurface);
    verticalGroup.addSurfaceToGroup(activityFlowRateChart.sciChartSurface);
  };

  return (
    <>
      <SciChartGroup
        onInit={(initResults: IInitResult[]) => {
          configureStatusAndActivityDataBindings(initResults);
          setIsChartsInitialised(true);
        }}
      >
        {isChartsInitialised ? null : <ChartWaitLoader />}
        <StatusChart key={"status-chart"} chartData={statusChartData}/>
        <ActivityFlowRateChart key={"activity-flowRate-chart"} chartData={activityFlowRateChartData}/>

        <div className="flex flex-wrap">
          <div id="legend-activity-flowRate"></div>
          <div id="legend-status" ></div>
        </div>
      </SciChartGroup>
    </>
    
  )
}