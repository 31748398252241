import { ReportScheduleUpdateDto } from "../../../Models/Reporting/ReportScheduleUpdateDto";
import { ReportScheduleCreateDto } from "../../../Models/Reporting/ReportScheduleCreateDto";
import { ReportScheduleReadDto } from "../../../Models/Reporting/ReportScheduleReadDto";
import { ApiRoutes } from "../../../Constants/ApiRoutingConstants";
import axios, { AxiosInstance } from "axios";
import authorizeService from "../../Identity/Utilities/AuthorizeService";
import { ReportScheduleGetDto } from "../../../Models/Reporting/ReportScheduleGetDto";
import { SendComplianceReportByEmailCommand } from "../../../Models/Reporting/SendComplianceReportByEmailCommand";

/**
 * This service provides implementations for http calls to the api pertaining to schedule crud functionality
 */

class ReportScheduleApiService {
  axiosInstance: AxiosInstance;
  constructor() {
    //Subscribe to identity store the get token
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_REPORT_SERVICE_URL,
    });
  }

  async getAllReportSchedule(type: string): Promise<ReportScheduleReadDto> {
    let response = await this.axiosInstance({
      method: "GET",
      url: `${ApiRoutes.ReportSchedules}/${type}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data as ReportScheduleReadDto;
  }

  async createReportSchedule(scheduleReport: ReportScheduleCreateDto) {
    let response = await this.axiosInstance({
      method: "POST",
      url: `${ApiRoutes.ReportSchedules}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: scheduleReport,
    });
    return response.data;
  }

  async updateReportSchedule(scheduleReport: ReportScheduleUpdateDto) {
    let response = await this.axiosInstance({
      method: "PUT",
      url: `${ApiRoutes.ReportSchedules}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: scheduleReport,
    });
    return response.data;
  }

  async deleteReportSchedule(id: any, type: any) {
    let response = await this.axiosInstance({
      method: "DELETE",
      url: `${ApiRoutes.ReportSchedules}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: {
        id: id,
        type: type
      },
    });
    return response.data;
  }

  static get instance() {
    return reportScheduleApiService;
  }

}

const reportScheduleApiService = new ReportScheduleApiService();
export default reportScheduleApiService;
