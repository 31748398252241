import { ReactElement, useRef, useState, } from "react";
import { StatusChart } from "../../../Components/Charts/Status/StatusChart";
import { IInitResult, SciChartGroup } from "scichart-react";
import { ModifierGroup } from "../../../../Shared/SciChart/ModifierGroup";
import { ChartModifierBase2D, ISciChartSurfaceBase, SciChartVerticalGroup } from "scichart";
import { synchronizeXVisibleRanges } from "../../../../Shared/SciChart/ChartConfiguration";
import { ActivityPressureChart } from "../../../Components/Charts/Activity/ActivityPressureChart";
import ChartWaitLoader from "../../../../Shared/Components/ChartWaitLoader";
import useChartStore from "../../../../../Zustand/chartStore";
import moment from "moment";
import { GetVisibleRangePressureSeries } from "../../../../../Utilities/ChartUtilities";

interface Props {
  statusChartData: any;
  activityPressureChartData: any;
}

export default function EndpointStatusPressureActivity(props: Props): ReactElement {
  
  const [modifierGroup] = useState(new ModifierGroup());
  const verticalGroup = new SciChartVerticalGroup();
  const [isChartsInitialised, setIsChartsInitialised] = useState(false);

  // Fetch the initial state, but we don't need to re-render when it changes as the chart will update itself
  const visibleRangeRef = useRef(useChartStore.getState().visibleRange);

  const visibleRange = GetVisibleRangePressureSeries(
    visibleRangeRef.current, 
    props.activityPressureChartData.pressureEvents.timestamps[props.activityPressureChartData.pressureEvents.timestamps.length - 1],
    props.activityPressureChartData.timestamps[props.activityPressureChartData.timestamps.length - 1], 
    moment(props.activityPressureChartData.dateFilter.startDate).unix(), 
    moment(props.activityPressureChartData.dateFilter.endDate).unix());
  
  const statusChartData = {...props.statusChartData, ...{modifierGroup: modifierGroup}, visibleRange};
  const activityPressureChartData = {...props.activityPressureChartData, ...{modifierGroup: modifierGroup}, visibleRange};
  
  const configureStatusAndActivityDataBindings = (initResults: IInitResult<ISciChartSurfaceBase>[] | [any, any]) => {
    const [statusChart, activityPressureChart] = initResults;
    
    modifierGroup.clear();

    var statusRollOverModifier = statusChart.sciChartSurface.chartModifiers.getById("StatusRollOver");
    modifierGroup.add(statusRollOverModifier as ChartModifierBase2D);

    const activityModifierGroupModifier = activityPressureChart.sciChartSurface.chartModifiers.getById("ActivityModifierGroup");
    const activityRollOverModifier = activityPressureChart.sciChartSurface.chartModifiers.getById("ActivityRollOver");
    modifierGroup.add(activityModifierGroupModifier as ChartModifierBase2D, activityRollOverModifier as ChartModifierBase2D);

    synchronizeXVisibleRanges([statusChart.sciChartSurface, activityPressureChart.sciChartSurface], () => true);

    verticalGroup.addSurfaceToGroup(statusChart.sciChartSurface);
    verticalGroup.addSurfaceToGroup(activityPressureChart.sciChartSurface);
  };

  return (
    <>
      <SciChartGroup
        onInit={(initResults: IInitResult[]) => {
          configureStatusAndActivityDataBindings(initResults);
          setIsChartsInitialised(true);
        }}
      >
        {isChartsInitialised ? null : <ChartWaitLoader />}
        <StatusChart key={"status-chart"} chartData={statusChartData}/>
        <ActivityPressureChart key={"activity-pressure-chart"} chartData={activityPressureChartData}/>

        <div className="flex flex-wrap">
          <div id="legend-activity-flow-pressure"></div>
          <div id="legend-status" ></div>
        </div>
      </SciChartGroup>
    </>
    
  )
}