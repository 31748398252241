
import * as yup from "yup";
import { enMessages } from "../../../messages/en-US";

export default function PointValidationSchema(locationService:any) {
    return yup.object().shape({
    machineId: yup.string().required(`${locationService.toLanguageString('custom.fieldIsRequired', enMessages.custom.fieldIsRequired)}`).nullable(),
    areaId: yup.string().required(`${locationService.toLanguageString('custom.fieldIsRequired', enMessages.custom.fieldIsRequired)}`).nullable(),
    name: yup.string().min(2).max(48).required(`${locationService.toLanguageString('custom.fieldIsRequired', enMessages.custom.fieldIsRequired)}`),
    //@ts-ignore
    serialNumber: yup.string().max(24).optional(),
})};
