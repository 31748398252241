import { ReactElement, useState } from "react";
import { Dialog, DialogActionsBar, Window } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  header: string;
  body: string;
  cancel: string;
  confirm: string;
  confirmButtonTheme?:
    | "base"
    | "info"
    | "dark"
    | "light"
    | "primary"
    | "secondary"
    | "tertiary"
    | "success"
    | "warning"
    | "inverse"
    | null
    | undefined;
  cancelButtonTheme?:
    | "base"
    | "info"
    | "dark"
    | "light"
    | "primary"
    | "secondary"
    | "tertiary"
    | "success"
    | "warning"
    | "inverse"
    | null
    | undefined;
}

export default function GenericConfirmationDialog({
  header,
  body,
  cancel,
  confirm,
  open,
  onClose,
  onConfirm,
  cancelButtonTheme,
  confirmButtonTheme,
}: Props): ReactElement {
  return (
    <>
      {open && (
        <Dialog title={header} onClose={onClose}>
          <div className="text-lg max-w-md" style={{
            minHeight: "100px",
          }}>{body}</div>
          {/* Footer */}
          <DialogActionsBar>
            <Button
              themeColor={cancelButtonTheme}
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={onClose}
            >
              {cancel}
            </Button>
            <Button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              themeColor={confirmButtonTheme}
              onClick={() => {
                onConfirm();
                onClose();
              }}
            >
              {confirm}
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
    </>
  );
}
