import { useToast } from "@chakra-ui/react";
import { Button } from "@progress/kendo-react-buttons";
import { useLocalization } from '@progress/kendo-react-intl';
import { Grid,  GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { useQuery } from "react-query";
import LoadingSpinner from "../../Shared/Components/LoadingSpinner";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { useRef } from "react";
import { enMessages } from "../../../messages/en-US";
import actionsApiService from "../Utilities/ActionsApiService";
import EventPerActionReadDto from "../../../Models/Action/EventPerActionReadDto";
import moment from "moment";
import { Tooltip } from "@progress/kendo-react-tooltip";

interface Props {
  actionId: string;
}

export default function EventsPerAction(props: Props) {
  const toast = useToast({
    position: "bottom",
    duration: 5000,
    isClosable: true,
  });
  const localizationService = useLocalization();

  const key = "EventsPerAction"

  const eventsPerActionQuery = useQuery<Array<EventPerActionReadDto>>([key, props.actionId], async () => {
    return actionsApiService.getEventsPerAction(props.actionId)
  });

  const _export = useRef<ExcelExport | null>(null);
  const exportExport = () => {
    if (_export.current !== null) {
      _export.current.save(eventsPerActionQuery.data);
    }
  };

  const OccurredCell = (props: any) => {
    const formattedOccurred = moment(new Date(props.dataItem.occurred)).format('DD/MM/YYYY @ HH:mm:ss')
    return (
      <td>
        <div>{formattedOccurred}</div>
      </td>
      );
  }

  const UploadedCell = (props: any) => {
    const formattedUploaded = moment(new Date(props.dataItem.uploaded)).format('DD/MM/YYYY @ HH:mm:ss')
    if(props.dataItem.uploaded === null) {
      return(
        <td>
        <div>Not Available</div>
      </td>
      )
    } else {
      return (
        <td>
          <div>{formattedUploaded}</div>
        </td>
        );
    } 
  }

  const RfidCell = (props: any) => {
    if(props.dataItem.rfid === null) {
      return(
        <td>
        <div>Not Available</div>
      </td>
      )
    } else {
      return (
        <td>
          <div>{props.dataItem.rfid}</div>
        </td>
        );
    } 
  }

  const VolumeDispensedCell = (props: any) => {
    return (
      <td >
        <div>{Number(props.dataItem.volumeDispensed).toFixed(1)}</div>
      </td>
      );
  }

  if (eventsPerActionQuery.isLoading || eventsPerActionQuery.isFetching) {
    return <div style={{height:"200px",  width: '500px' }}><LoadingSpinner/></div>
  } else {
    return (
      <div>
        <ExcelExport ref={_export}>
          <Grid style={{ height:"230px",  width: "700px" }} data={eventsPerActionQuery.data}>
            <GridToolbar>
              <div className="flex-1 justify-between items-center">
                <div className="gb-h4">{localizationService.toLanguageString('custom.events', enMessages.custom.events)}</div>
                <Tooltip anchorElement="target" position="bottom">
                  <Button
                    title={localizationService.toLanguageString('custom.exportToExcel', enMessages.custom.exportToExcel)}
                    className="pl-2 border-l-2"
                    icon="download"
                    onClick={exportExport}
                  />
                </Tooltip>
              </div>
            </GridToolbar>
            <Column field="occurred" title={localizationService.toLanguageString('custom.occurred', enMessages.custom.occurred)} cell={OccurredCell}/>
              <Column field="uploaded" title={localizationService.toLanguageString('custom.uploaded', enMessages.custom.uploaded)}  cell={UploadedCell}/>
              <Column field="deviceSerialNumber" title={localizationService.toLanguageString('custom.device', enMessages.custom.device)}  /> 
              <Column field="rfid" title={localizationService.toLanguageString('custom.rfid', enMessages.custom.rfid)} cell={RfidCell} />
              <Column field="volumeDispensed" title={localizationService.toLanguageString('custom.volumeDispensed', enMessages.custom.volumeDispensed)} cell={VolumeDispensedCell} />
              <Column field="consumableName" title={localizationService.toLanguageString('custom.consumable', enMessages.custom.consumable)}  />
          </Grid>
        </ExcelExport>
      </div>
    )
  }
}
