import {
    XyDataSeries,
    SciChartSurface,
    ZoomPanModifier,
    MouseWheelZoomModifier,
    ZoomExtentsModifier,
    RolloverModifier,
    RubberBandXyZoomModifier,
    EXyDirection,
    EExecuteOn,
    FastImpulseRenderableSeries,
    LegendModifier,
    EAutoRange,
    NumberRange,
    EAxisAlignment,
    NumericAxis,
    DateTimeNumericAxis,
    CursorModifier,
    FastLineRenderableSeries,
    SeriesInfo,
    IRenderableSeries,
    TrianglePointMarker,
    FastBandRenderableSeries,
    XyyDataSeries,
    EDataSeriesType,
    ENumericFormat,
    } from "scichart";
  import { TInitFunction } from "scichart-react";
  import { enMessages } from "../../../../../messages/en-US";
  import { ColumnPaletteProvider } from "../PaletteProvider";
  import moment from "moment";
  import { axisLabelDateFormatter } from "../../../../Shared/SciChart/SmartAxis/axisLabelDateFormatter";
  import { GetChartTheme } from "../../../../Shared/SciChart/ChartConfiguration";
import { LocalizationService } from "@progress/kendo-react-intl";
import { DateTickProvider } from "../../../../Shared/SciChart/SmartAxis/DateTickProvider";
import { SmartDateTZLabelProvider } from "../../../../Shared/SciChart/SmartAxis/SmartDateTZLabelProvider";
import { createDateTzConverter } from "../../../../Shared/SciChart/SmartAxis/timezones";
  
  export type TDiagnosticConfigFunc = TInitFunction<SciChartSurface>;

  export interface Props {
    chartData: any
  }

  export interface ChartOptions {
    visibleRange: { min: number | undefined; max: number | undefined; };
    setVisibleRange: (range: any) => void;
    setVisibleSeriesDiagnostics: (series: any, isChecked: boolean) => void;
    visibleSeriesDiagnostics: { [key: string]: boolean;}
    aggregator: string;
  }
  
  export const getChartAPI = (props:Props, chartOptions:ChartOptions, localizationService: LocalizationService, currentLanguage: { locale: string; localeId: string; }) => {

    const endFilterDateUnix = moment(props.chartData.dateFilter.endDate).unix();
    const startFilterDateUnix = moment(props.chartData.dateFilter.startDate).unix();

    const theme = GetChartTheme();  
  
    const configAfterInit = (initResult) => {
    } 
  
    const initChart : TDiagnosticConfigFunc = async (rootElement: string | HTMLDivElement) => {
        const {sciChartSurface, wasmContext} = await SciChartSurface.create(rootElement, {theme: theme});

      // #region Setup Tooltip Data Template
      const getTooltipDataTemplate = (seriesInfo: SeriesInfo, tooltipTitle: string, tooltipLabelX: string, tooltipLabelY: string) => {
        // Lines here are returned to the tooltip and displayed as text-line per tooltip
        const lines: string[] = [];
        lines.push(tooltipTitle);
        lines.push(`x: ${seriesInfo.formattedXValue}, y: ${seriesInfo.formattedYValue}`)
    
        return lines;
    } ;

      const getTooltipLegendTemplate2 = (seriesInfo: any, tooltipTitle: string, tooltipLabelX: string, tooltipLabelY: string) => {
        const lines: string[] = [];
        if (seriesInfo.dataSeriesType === EDataSeriesType.Xy) {
          const hlcSeriesInfo = seriesInfo as any;
          lines.push(`x: ${seriesInfo.formattedXValue}`);
          if(hlcSeriesInfo.pointMetadata !== null) {
          if (typeof hlcSeriesInfo.pointMetadata === 'object' && 'label' in hlcSeriesInfo.pointMetadata) {
            const labelParts =  hlcSeriesInfo.pointMetadata.label.split(',');
            lines.push(`${labelParts[0]}`);
            lines.push(`${localizationService.toLanguageString('custom.mean', enMessages.custom.mean)}: ${seriesInfo.formattedYValue}`);
            lines.push(`${labelParts[1]}`);
            lines.push(`${labelParts[2]}`);
          }
        }}
        return lines;
      };
      // #endregion

      // #region Setup the XAxis
        const xAxis = new DateTimeNumericAxis(wasmContext, { 
        drawMajorBands: false, 
        drawMinorGridLines: false, 
        drawMajorGridLines: false, 
        visibleRange: new NumberRange(chartOptions.visibleRange.min, chartOptions.visibleRange.max),
        visibleRangeLimit: new NumberRange(startFilterDateUnix, endFilterDateUnix),
        growBy: new NumberRange(0.05, 0.05),
        labelFormat: ENumericFormat.Date_DDMMYY,
        cursorLabelFormat: ENumericFormat.Date_DDMMYY,
        labelStyle: {fontFamily:"Roboto", fontSize:12}});
  
      xAxis.deltaCalculator.getDeltaFromRange = axisLabelDateFormatter.getDeltaFromRange;
      
      const updateSettings = (xAxis: NumericAxis) => {
        const timezone = moment.tz.guess();
        // Calculate ticks which respect the time zone
        xAxis.tickProvider = new DateTickProvider(wasmContext, timezone);
        // Draws nice axis labels timezone and locale specific
        xAxis.labelProvider = new SmartDateTZLabelProvider(timezone,true, currentLanguage?.localeId);
        const dateTzConverter = createDateTzConverter(timezone);
        // This is needed for Cursor and Rollover chart modifiers
        xAxis.labelProvider.formatCursorLabel = timestamp =>
          chartOptions.aggregator === 'none' ? axisLabelDateFormatter.toFullDateTime(timestamp, dateTzConverter, currentLanguage?.localeId) : axisLabelDateFormatter.toFullDateOnly(timestamp, dateTzConverter, currentLanguage?.localeId);
        };
        // Setting timezone to local system
      updateSettings(xAxis);

      sciChartSurface.xAxes.add(xAxis);
  
      xAxis.visibleRangeChanged.subscribe((args) => { chartOptions.setVisibleRange(args?.visibleRange); });
      
      // #endregion

      // #region Setup the YAxis
      const yAxisVoltage = new NumericAxis(wasmContext,{
        axisAlignment: EAxisAlignment.Left, 
        drawMajorBands: false,
        drawMinorGridLines: false, 
        drawMajorGridLines: false, 
        labelPostfix: " V", 
        maxAutoTicks:10,
        visibleRange: new NumberRange(0,4),
        growBy: new NumberRange(0, 0.2),
        zoomExtentsToInitialRange: true,
        labelStyle: {fontFamily:"Roboto", fontSize:12}});
  
      const yAxisTemperature = new NumericAxis(wasmContext,{
        id: "Temp",
        axisAlignment: EAxisAlignment.Right, 
        drawMajorBands: false, 
        drawMinorGridLines: false, 
        drawMajorGridLines: false, 
        labelPostfix:" °C",
        autoRange: EAutoRange.Always, 
        labelStyle: {fontFamily:"Roboto", fontSize:12}});
  
      const yAxisRssi = new NumericAxis(wasmContext,{
        id: "Rssi",
        axisAlignment: EAxisAlignment.Left, 
        drawMajorBands: false, 
        drawMinorGridLines: false, 
        drawMajorGridLines: false, 
        labelPostfix:" dBm",
        autoRange: EAutoRange.Always, 
        growBy: new NumberRange(0, 0.2),
        labelStyle: {fontFamily:"Roboto", fontSize:12}});
  
      const yAxisCumulative = new NumericAxis(wasmContext,{
        id: "Cumulative",
        axisAlignment: EAxisAlignment.Right, 
        drawMajorBands: false, 
        drawMinorGridLines: false, 
        drawMajorGridLines: false, 
        labelPostfix:" cc",
        autoRange: EAutoRange.Always, 
        growBy: new NumberRange(0, 0.2),
        labelPrecision: 2,
        labelStyle: {fontFamily:"Roboto", fontSize:12}});
  
      sciChartSurface.yAxes.add(yAxisVoltage);
      sciChartSurface.yAxes.add(yAxisTemperature);
      sciChartSurface.yAxes.add(yAxisRssi);
      sciChartSurface.yAxes.add(yAxisCumulative);
      // #endregion

      // #region Setup the Chart Modifers
      sciChartSurface.chartModifiers.add(
        new CursorModifier({ showAxisLabels: false,  id: "DiagnosticsModifierGroup" }),
        new ZoomPanModifier({xyDirection: EXyDirection.XDirection}),
        new MouseWheelZoomModifier({xyDirection:EXyDirection.XDirection}),
        new ZoomExtentsModifier(),
        new RolloverModifier({tooltipDataTemplate: getTooltipDataTemplate, id: "DiagnosticsRollOver"}),
        new RubberBandXyZoomModifier({ executeOn: EExecuteOn.MouseRightButton }),
      );
  
      const legend = new LegendModifier({
        placementDivId: "legend-diagnostics",
        showCheckboxes: true,
        showSeriesMarkers: true,
        showLegend: true,
        // Subscribe to checked changed here
        isCheckedChangedCallback: (series: IRenderableSeries, isChecked: boolean) => {
          chartOptions.setVisibleSeriesDiagnostics(series.id, isChecked)  
        }
      });

      sciChartSurface.chartModifiers.add(legend);

      // #endregion

      // #region Setup the Series and Renderable Series
      if(props.chartData.pressureEvents !== undefined) {
        const yAxisPressure = new NumericAxis(wasmContext,{
          id:"yAxisPressureId",
          axisAlignment: EAxisAlignment.Right, 
          drawMajorBands: false,
          drawMinorGridLines: false, 
          drawMajorGridLines: true, 
          labelPostfix:" psi",
          labelPrecision: 0,
          autoRange: EAutoRange.Always,
          growBy: new NumberRange(0, 0.2),
          labelStyle: {fontFamily:"Roboto", fontSize:12}});
        yAxisPressure.visibleRangeChanged.subscribe(args => {
          if (args?.visibleRange.min !== 0) {
            yAxisPressure.visibleRange = new NumberRange(0, args?.visibleRange.max);
          }
        });
      
        sciChartSurface.yAxes.add(yAxisPressure);
    
        const metadataPressure = props.chartData.pressureEvents?.pressureDataMax.map((maxValue, index) => ({
          label: `${localizationService.toLanguageString('custom.max', enMessages.custom.max)}: ${maxValue} psi,${localizationService.toLanguageString('custom.min', enMessages.custom.min)}: ${props.chartData.pressureEvents?.pressureDataMin[index]} psi,${localizationService.toLanguageString('custom.temperatureInC', enMessages.custom.temperatureInC)}: ${Number(props.chartData.pressureEvents?.temperatureData[index]).toFixed(2)}`
        }));
    
        const pressureMean = new FastLineRenderableSeries(wasmContext, {  stroke: "#3F48CC", yAxisId: "yAxisPressureId", id:"PressureMeanId", isVisible: chartOptions.visibleSeriesDiagnostics.PressureMeanId});
    
        pressureMean.dataSeries = new XyDataSeries(wasmContext, {
          xValues: props.chartData.pressureEvents?.timestamps,
          yValues: props.chartData.pressureEvents?.pressureDataMean,
          dataSeriesName: `${localizationService.toLanguageString('custom.pressureMean', enMessages.custom.pressureMean)} (psi)`,
          metadata: metadataPressure,
          
        })
  
        pressureMean.rolloverModifierProps.tooltipTextColor = "white"
    
        const dataSeries = new XyyDataSeries(wasmContext, { xValues: props.chartData.pressureEvents.timestamps, yValues: props.chartData.pressureEvents.pressureDataMax, y1Values: props.chartData.pressureEvents.pressureDataMin , dataSeriesName:  `${localizationService.toLanguageString('custom.preassureMinMax', enMessages.custom.preassureMinMax)} (psi)`});
    
        const pressureMinMax = new FastBandRenderableSeries(wasmContext, {
          dataSeries,
          stroke: "#C3C3C3",
          strokeY1: "#C3C3C3",
          fill: "#C3C3C3",
          fillY1: "#C3C3C3",
          strokeThickness: 2,
          yAxisId: "yAxisPressureId",
          id: "PressureMinMaxId",
          isVisible: chartOptions.visibleSeriesDiagnostics.PressureMinMaxId
        });
    
        pressureMinMax.rolloverModifierProps.showRollover= false
        sciChartSurface.renderableSeries.add(pressureMinMax);
    
        sciChartSurface.renderableSeries.add(pressureMean);
        pressureMean.rolloverModifierProps.tooltipDataTemplate = getTooltipLegendTemplate2;
    
        const pressureTemp = new FastLineRenderableSeries(wasmContext, {  stroke: "rgba(136,0,21,1)", yAxisId: "Temp",  id:"PressureTempId", isVisible: chartOptions.visibleSeriesDiagnostics.PressureTempId  });
        pressureTemp.dataSeries = new XyDataSeries(wasmContext, {
          xValues: props.chartData.pressureEvents?.timestamps,
          yValues: props.chartData.pressureEvents?.temperatureData,
          dataSeriesName:  `${localizationService.toLanguageString('custom.pressureTemperature', enMessages.custom.pressureTemperature)} (°C)`,
        })
    
        pressureTemp.rolloverModifierProps.tooltipColor = "transparent"
        pressureTemp.rolloverModifierProps.tooltipTextColor = "transparent"
        sciChartSurface.renderableSeries.add(pressureTemp);
      }

      // Create a series that represents the entire filter period so the chart displays the entire range. 
      const filterPeriodSeries = new FastLineRenderableSeries(wasmContext, {
        id: "filterPeriodSeriesId", 
        yAxisId: "Cumulative",
        isVisible: false,
        dataSeries: new XyDataSeries(wasmContext, {xValues: [startFilterDateUnix, endFilterDateUnix],yValues: [0,0], dataSeriesName: "filterPeriodSeries"})
      });  

      sciChartSurface.renderableSeries.add(filterPeriodSeries);
      legend.includeSeries(filterPeriodSeries, false);

      const volumeDispensed = new FastImpulseRenderableSeries(wasmContext, {stroke: "rgba(50, 53, 67,1)", fill: "rgba(50, 53, 67,1)", strokeThickness: props.chartData.strokeThickness,
      pointMarker: new TrianglePointMarker(wasmContext, {width: 12, height: 12, stroke: "rgba(50, 53, 67,1)", fill: "rgba(50, 53, 67,1)"}),
      paletteProvider: new ColumnPaletteProvider("rgba(50, 53, 67,1)"),
      yAxisId: "Cumulative",
      id: "VolumeDispensedId",
      isVisible: chartOptions.visibleSeriesDiagnostics.VolumeDispensedId
      });
      volumeDispensed.dataSeries = new XyDataSeries(wasmContext, {xValues: props.chartData.timestamps, yValues: props.chartData.volumeDispensedData, dataSeriesName: `${localizationService.toLanguageString('custom.volumeDispensed', enMessages.custom.volumeDispensed)}` });
      volumeDispensed.rolloverModifierProps.tooltipTextColor = 'White'
      sciChartSurface.renderableSeries.add(volumeDispensed);
  
      const temperatureChart = new FastLineRenderableSeries(wasmContext, { stroke: "#A349A4" , yAxisId: "Temp", id: "TemperatureId", isVisible: chartOptions.visibleSeriesDiagnostics.TemperatureId});
      temperatureChart.dataSeries = new XyDataSeries(wasmContext, {xValues: props.chartData.timestampsDiagnostics, yValues: props.chartData.temperatureData, dataSeriesName: `${localizationService.toLanguageString('custom.temperatureInC', enMessages.custom.temperatureInC)}`});
      temperatureChart.rolloverModifierProps.tooltipTextColor = 'White'
      sciChartSurface.renderableSeries.add(temperatureChart);
  
      const voltageChart = new FastLineRenderableSeries(wasmContext, { stroke: "#ED1C24", id: "VoltageId", isVisible: chartOptions.visibleSeriesDiagnostics.VoltageId});
      voltageChart.dataSeries = new XyDataSeries(wasmContext, {xValues: props.chartData.timestampsDiagnostics, yValues:props.chartData.voltageData, dataSeriesName: `${localizationService.toLanguageString('custom.voltage', enMessages.custom.voltage)}`});
      voltageChart.rolloverModifierProps.tooltipTextColor = 'White'
      sciChartSurface.renderableSeries.add(voltageChart);
  
      const rssiChart = new FastLineRenderableSeries(wasmContext, { stroke: "#22B14C",  yAxisId: "Rssi", id: "RssiId", isVisible: chartOptions.visibleSeriesDiagnostics.RssiId});
      rssiChart.dataSeries = new XyDataSeries(wasmContext, {xValues: props.chartData.timestampsMetrics, yValues: props.chartData.rssiData, dataSeriesName: `${localizationService.toLanguageString('custom.rssiDbm', enMessages.custom.rssiDbm)}`});
      rssiChart.rolloverModifierProps.tooltipTextColor = 'White'
      sciChartSurface.renderableSeries.add(rssiChart);
  
      const endpointRssiChart = new FastLineRenderableSeries(wasmContext, { stroke: "#FF7F27", yAxisId: "Rssi", id: "EndpointRssiId", isVisible: chartOptions.visibleSeriesDiagnostics.EndpointRssiId});
      endpointRssiChart.dataSeries = new XyDataSeries(wasmContext, {xValues: props.chartData?.timestampsMetrics, yValues: props.chartData.endpointRssiData, dataSeriesName: `${localizationService.toLanguageString('custom.endpointRssi', enMessages.custom.endpointRssi)}`});
      endpointRssiChart.rolloverModifierProps.tooltipTextColor = 'White'
      sciChartSurface.renderableSeries.add(endpointRssiChart);
  
      const cumulativeChart = new FastLineRenderableSeries(wasmContext, { stroke: "#7F7F7F", yAxisId: "Cumulative" , id:'CumulativeId', isVisible: chartOptions.visibleSeriesDiagnostics.CumulativeId});
      cumulativeChart.dataSeries = new XyDataSeries(wasmContext, {
        xValues: props.chartData.timestampsMetrics, 
        yValues: props.chartData.cumulativeData, 
        dataSeriesName: `${localizationService.toLanguageString('custom.cumulative', enMessages.custom.cumulative)}`,
      });
      
      cumulativeChart.rolloverModifierProps.tooltipTextColor = 'White'
      sciChartSurface.renderableSeries.add(cumulativeChart);

      // #endregion
  
      return { sciChartSurface: sciChartSurface};
    }

    return {initChart, configAfterInit}
}

