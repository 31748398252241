import { ReactElement} from "react";
import { TabStrip,TabStripSelectEventArguments,TabStripTab,} from '@progress/kendo-react-layout';
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../../messages/en-US";
import { EventFilter } from "../../../Events/Utilities/EventsApiService";
import { TreeListReadDto } from "../../../../Models/TreeList/TreeListReadDto";
import EndpointReservoirTabPage from "./Reservoirs/EndpointReservoirTabPage";
import useIdentityStore from "../../../../Zustand/identityStore";
import EndpointDiagnosticsTabPage from "./Diagnostics/EndpointDiagnosticsTabPage";
import { useQuery } from "react-query";
import treeListApiService from "../../Utilities/TreeListApiService";
import LoadingSpinner from "../../../Shared/Components/LoadingSpinner";
import ErrorCard from "../../../Shared/Components/Cards/ErrorCard";
import EndpointFeatureDto from "../../../../Models/Endpoint/EndpointFeatureDto";
import EndpointActivityTabPage from "./Activity/EndpointActivityTabPage";
import EndpointComplianceTabPage from "./Compliance/EndpointComplianceTabPage";
import EndpointActivityTabPageGpsOnly from "./Activity/EndpointActivityTabPageGpsOnly";

interface Props {
  eventFilter: EventFilter
  tabId: number;
  selectedTreeNode : TreeListReadDto;
  onLinkReservoir: () => void;
  handleSelectEndpointTab: (e: any) => void;
  selectedEndpointTab: number;
}

export default function EndpointDetailsPage(props: Props): ReactElement {
  const localizationService = useLocalization();
  const userProfile = useIdentityStore((store) => store.user?.profile);
  
  const endpointFeatureQuery = useQuery<EndpointFeatureDto>("endpointFeatureQuery", async () => {
    return treeListApiService.getEndpointFeature(props.selectedTreeNode.id);
  });


  if(endpointFeatureQuery.isLoading || endpointFeatureQuery.isFetching) {
    return <LoadingSpinner></LoadingSpinner>
  } else if (endpointFeatureQuery.isError) {
    return <ErrorCard></ErrorCard>
  } else {
  return (
    <div className="tabstrip">
      {(endpointFeatureQuery.data !== undefined && endpointFeatureQuery.data.isGpsSupported && !endpointFeatureQuery.data.isBiDirectional && !endpointFeatureQuery.data.isFlowRateSupported && !endpointFeatureQuery.data.isFlowSupported && !endpointFeatureQuery.data.isPressureSupported && !endpointFeatureQuery.data.isTemperatureSupported) ?
        <TabStrip selected={0} onSelect={props.handleSelectEndpointTab} className="h-full">
        <TabStripTab title={`${localizationService.toLanguageString('custom.activity', enMessages.custom.activity)}`}>
        <EndpointActivityTabPageGpsOnly aggregator={"none"} eventFilter={props.eventFilter} selectedItem={props.selectedTreeNode.id} endpointFeature={endpointFeatureQuery.data}/>
        </TabStripTab>
      </TabStrip>
     :
      <TabStrip selected={props.selectedEndpointTab} onSelect={props.handleSelectEndpointTab} className="h-full">
         <TabStripTab title={localizationService.toLanguageString('custom.compliance', enMessages.custom.compliance)} >
          <EndpointComplianceTabPage key={`${props.selectedTreeNode.id}EndpointPvATabPage`}  eventFilter={props.eventFilter} selectedItemId={props.selectedTreeNode.id} />
        </TabStripTab>
        <TabStripTab title={`${localizationService.toLanguageString('custom.activity', enMessages.custom.activity)}`}>
          <EndpointActivityTabPage   eventFilter={props.eventFilter} selectedItem={props.selectedTreeNode.id} selectedItemSerialNo={props.selectedTreeNode.data} endpointFeature={endpointFeatureQuery.data!} />
        </TabStripTab>
        <TabStripTab title={localizationService.toLanguageString('custom.reservoirs', enMessages.custom.reservoirs)} >
          <EndpointReservoirTabPage selectedTreeNode={props.selectedTreeNode} onLinkReservoir={props.onLinkReservoir}/>
        </TabStripTab>
        {(userProfile?.role === 'SuperAdmin' || userProfile?.role === "SiteAdmin") && (
          <TabStripTab title={`${localizationService.toLanguageString('custom.diagnostics', enMessages.custom.diagnostics)}`}>
            <EndpointDiagnosticsTabPage eventFilter={props.eventFilter} selectedItem={props.selectedTreeNode.data} selectedEndpointId={props.selectedTreeNode.id} endpointFeature={endpointFeatureQuery.data!}/>
          </TabStripTab>
        )}        
      </TabStrip>
  }
    </div>
  )
}}
