import { SciChartNestedOverview, SciChartReact } from "scichart-react";
import { useLocalization } from "@progress/kendo-react-intl";
import React, { useEffect, useRef } from "react";
import { AppContext } from "../../../../../AppContext";
import { ChartOptions, Props, getChartAPI } from "./ActivityAssetChartApi";
import { getCurrentLanguage } from "../../../../Shared/SciChart/ChartConfiguration";
import { overviewOptions } from "../../../../Shared/SciChart/Overview";
import useChartStore from "../../../../../Zustand/chartStore";

export const ActivityAssetChart = (props: Props) => {

  const localizationService = useLocalization();
  const {localeId} = React.useContext<any>(AppContext);
  const currentLanguage = getCurrentLanguage(localeId);
  const isAutoRange = useChartStore(state => state.isAutoRange);
  const aggregator = useChartStore(state => state.aggregator);
  const isBarChartOn = useChartStore(state => state.isBarChartOn);
  const setVisibleRangeChartStore = useChartStore(state => state.setVisibleRange);
  const setVisibleSeriesChartStore = useChartStore(state => state.setVisibleSeriesAsset);
  
  // Fetch the initial state, but we don't need to re-render when it changes as the chart will update itself
  const visibleSeriesRef = useRef(useChartStore.getState().visibleSeriesAsset);

  const setVisibleRange = (range:  {min: number | undefined; max: number | undefined; }) => {
    setVisibleRangeChartStore(range.min, range.max);
  };

  const setVisibleSeries = (id: string, isVisible: boolean) => {
    setVisibleSeriesChartStore(id, isVisible);
  };
  
  var chartOptions : ChartOptions = {isAutoRange: isAutoRange, isBarChartOn: isBarChartOn, setVisibleRange: setVisibleRange, visibleRange: props.chartData.visibleRange, setVisibleSeriesAsset: setVisibleSeries, visibleSeriesAsset: visibleSeriesRef.current, aggregator: aggregator};
  const [activityAssetChartAPI] = React.useState(getChartAPI(props, chartOptions, localizationService, currentLanguage));

  useEffect(() => {
    activityAssetChartAPI.changeChartType(isBarChartOn, visibleSeriesRef.current);
  }, [activityAssetChartAPI, isBarChartOn]);

  useEffect(() => {
    activityAssetChartAPI.setAutoRange(isAutoRange);
  }, [activityAssetChartAPI, isAutoRange]);

  return (
    <div className="flex">
      <SciChartReact
        key={"activityAssetChart"}
        onInit={activityAssetChartAPI.configAfterInit}
        initChart={activityAssetChartAPI.initChart}
        style={{aspectRatio: 2, width:"100%", height: "450px"}}
          //  customize size of the inner chart wrapper
          innerContainerProps={{ style: { height: "85%" } }}
      >
        <SciChartNestedOverview style={{ height: "14%" }} options={overviewOptions}/>
      </SciChartReact>
    </div>
  );
}