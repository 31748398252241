import { useToast } from "@chakra-ui/react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";
import ReservoirChart from "./ReservoirChart";
import ReservoirViewPage from "../Pages/ReservoirViewPage";
import { ReservoirStatusDto } from "../../../Models/Reservoirs/ReservoirStatusDto";
import { useQuery, useQueryClient } from "react-query";
import analysisApiService from "../Utilities/AnalysisApiService";
import { ReactQueryKeys } from "../../../Constants/ClientRoutingConstants";
import { ReservoirResetDto } from "../../../Models/Reservoirs/ReservoirResetDto";
import greaseReservoirsApiService from "../Utilities/GreaseReservoirApiService";
import { ReservoirReadDto } from "../../../Models/Reservoirs/ReservoirReadDto";

interface Props {
  reservoirId: string;
  onClose: () => void;
  viewReservoir: boolean
}

export default function ReservoirViewModal(props: Props) {
  const toast = useToast({
    position: "bottom",
    duration: 5000,
    isClosable: true,
  });

  const localizationService = useLocalization();
  const client = useQueryClient();

  const analysisReservoirsQuery = useQuery<ReservoirStatusDto>([ReactQueryKeys.GreaseReservoirsAnalysisQuery, props.reservoirId], async () => {
    return analysisApiService.getReservoirStatus(props.reservoirId);
  });

  const key = "Reservoir" + props.reservoirId;

  const greaseReservoirsQuery = useQuery<ReservoirReadDto>(key, async () => {
    return greaseReservoirsApiService.getReservoir(props.reservoirId);
  });

  const onReset = async (reservoirResetDto: ReservoirResetDto) => {
    let response = await greaseReservoirsApiService.resetReservoir(props.reservoirId, reservoirResetDto);
    client.refetchQueries(ReactQueryKeys.GreaseReservoirsAnalysisQuery);
    client.refetchQueries(key);
  };

  return (
    <>
    {props.viewReservoir && !analysisReservoirsQuery.isLoading && !greaseReservoirsQuery.isLoading &&(
      <div >
        <Dialog title={localizationService.toLanguageString('custom.viewReservoir', enMessages.custom.viewReservoir)} onClose={props.onClose}  className="reservoir-view" width="900px">
          <div style={{display:'grid', gridTemplateColumns:'1fr 1fr', gap:'50px'}} >
            <ReservoirViewPage reservoir={greaseReservoirsQuery.data} reservoirStatus={analysisReservoirsQuery.data!} onClose={props.onClose} onReset={onReset} viewReservoir={props.viewReservoir}/>
            <div>
              <ReservoirChart reservoir={greaseReservoirsQuery.data} reservoirStatus={analysisReservoirsQuery.data!}/>
            </div>
          </div>
          <DialogActionsBar>
            <Button onClick={props.onClose}>{localizationService.toLanguageString('custom.close', enMessages.custom.close)}</Button>
          </DialogActionsBar>
        </Dialog>
      </div>
    )}
    </>
   
  );
}
