import {
    XyDataSeries,
    HorizontalLineAnnotation,
    ELabelPlacement,
    SciChartSurface,
    LegendModifier,
    EAutoRange,
    NumberRange,
    EAxisAlignment,
    NumericAxis,
    AxisMarkerAnnotation,
    CategoryAxis,
    TextLabelProvider,
    StackedColumnRenderableSeries,
    StackedColumnCollection,
    } from "scichart";
import { TInitFunction } from "scichart-react";
import { enMessages } from "../../../messages/en-US";
import { LocalizationService } from "@progress/kendo-react-intl";
import { GetChartTheme } from "../../Shared/SciChart/ChartConfiguration";
  
export type TReservoirChageConfigFunc = TInitFunction<SciChartSurface>;

export interface Props {
  reservoir: any
  reservoirStatus: any
}
  
export const getChartAPI = (props:Props, localizationService: LocalizationService) => {

  const theme = GetChartTheme();  

  const initChart : TReservoirChageConfigFunc = async (rootElement: string | HTMLDivElement) => {

    const {sciChartSurface, wasmContext} = await SciChartSurface.create(rootElement, {theme: theme});

    // #region Setup the XAxis
    const xAxis = new CategoryAxis(wasmContext, { 
      drawMajorBands: false, 
      drawMinorGridLines: false, 
      drawMajorGridLines: false, 
      labelProvider: new TextLabelProvider({labels: [props.reservoir.name]}),
      autoRange: EAutoRange.Always,
      labelStyle: {fontFamily:"Roboto", fontSize:12}},
      );

      sciChartSurface.xAxes.add(xAxis);
    // #endregion

    // #region Setup the YAxis
    // Add an extra offet to the capacity to ensure the "Last Reset Level" label is displayed on top if reset level is set 
    // to full capacity.  Through trial and error determined a percentage (5%) of the total capacity worked best.    
    const maxVisibleRange = props.reservoir.capacity + (props.reservoir.capacity * 0.05);

    const yAxis = new NumericAxis(wasmContext,{
      axisAlignment: EAxisAlignment.Left, 
      drawMajorBands: false,
      drawMinorGridLines: false, 
      drawMajorGridLines: true, 
      visibleRange: new NumberRange(0, maxVisibleRange),
      autoRange: EAutoRange.Always,
      growBy: new NumberRange(0.05, 0.05),
      axisTitle: props.reservoir.displayUnit,
      labelStyle: {fontFamily:"Roboto", fontSize:12}});
    
    const yAxisLastResetLevel = new NumericAxis(wasmContext,{
      id:"ID_Y_AXIS_LAST_RESET_LEVEL",
      axisAlignment: EAxisAlignment.Right, 
      drawMajorBands: false,
      drawMinorGridLines: false, 
      drawMajorGridLines: true, 
      drawLabels: true,
      visibleRange: yAxis.getMaximumRange(),
      growBy: new NumberRange(0.05, 0.05),
      axisTitle: " ",
      labelStyle: {fontFamily:"Roboto", fontSize:12, color:"Transparent"}});

    var available = props.reservoirStatus.estimatedVolume;
    var consumed = props.reservoir.capacity - props.reservoirStatus.estimatedVolume;

    if (available < 0)
    {
      // Required to ensure 0.0 is not displayed as -0.0; 
      available = 0.00001; 
    }

    sciChartSurface.yAxes.add(yAxis);
    sciChartSurface.yAxes.add(yAxisLastResetLevel);
    // #endregion

    // #region Setup Series
    const reservoirConsumed= new StackedColumnRenderableSeries(wasmContext, { stroke: "transparent", fill: "rgba(133, 133, 133, 0.3)",stackedGroupId: "StackedGroupId" });
    reservoirConsumed.dataSeries = new XyDataSeries(wasmContext, {xValues: [0], yValues: [consumed], dataSeriesName:  `${localizationService.toLanguageString('custom.consumed', enMessages.custom.consumed)}`  });

    const reservoirAvailable= new StackedColumnRenderableSeries(wasmContext, { fill: "rgba(38, 166, 62, 0.6)", stroke: "transparent", stackedGroupId: "StackedGroupId"});
    reservoirAvailable.dataSeries = new XyDataSeries(wasmContext, {xValues: [0], yValues: [available], dataSeriesName: `${localizationService.toLanguageString('custom.available', enMessages.custom.available)}` });

    // To add the series to the chart, put them in a StackedColumnCollection
    const stackedColumnCollection = new StackedColumnCollection(wasmContext);
    stackedColumnCollection.dataPointWidth = 0.6;
    stackedColumnCollection.add(reservoirAvailable, reservoirConsumed);

    // Add the Stacked Column collection to the chart
    sciChartSurface.renderableSeries.add(stackedColumnCollection);

    sciChartSurface.yAxes.get(0).visibleRangeChanged.subscribe((args) => {
      yAxisLastResetLevel.visibleRange = yAxis.getMaximumRange();
    });

    // #endregion

    // #region Setup Annotations

    var estimatedStroke = (props.reservoirStatus.estimatedVolume < 0) ? "rgba(255, 80, 52, 1)" : "rgba(38, 166, 62, 1)";

    sciChartSurface.annotations.add(
      new HorizontalLineAnnotation({
        stroke: "rgba(50, 53, 67,1)",
        strokeThickness: 2,
        strokeDashArray: [3, 3],
        labelPlacement: ELabelPlacement.Top,
        y1: props.reservoir.lastResetLevel,
        yAxisId:"ID_Y_AXIS_LAST_RESET_LEVEL",
        labelValue:  `${localizationService.toLanguageString('custom.lastResetLevel', enMessages.custom.lastResetLevel)}`,
        axisLabelFill: "rgba(50, 53, 67,1)",
        axisFontSize: 14,
        showLabel: true,
      }),
      new HorizontalLineAnnotation({
        stroke: estimatedStroke,
        strokeThickness: 2,
        strokeDashArray: [3, 3],
        y1: available,
      }),
      new AxisMarkerAnnotation({
        y1: available,
        fontSize: 12,
        fontStyle: "Bold",
        backgroundColor: estimatedStroke,
        
      }),
      new AxisMarkerAnnotation({
        y1: props.reservoir.lastResetLevel,
        yAxisId:"ID_Y_AXIS_LAST_RESET_LEVEL",
        fontSize: 12,
        fontStyle: "Bold",
        backgroundColor:"rgba(50, 53, 67,1)"
      })
      );
    // #endregion

    sciChartSurface.chartModifiers.add(new LegendModifier({placementDivId: "legend-reservoir", showSeriesMarkers: true, showLegend: true,}));

    return {sciChartSurface};
  }

  return {initChart};
}