import { Dialog } from "@progress/kendo-react-dialogs";
import { useLocalization } from "@progress/kendo-react-intl";
import { AlertUpdateDto } from "../../../Models/Alerts/AlertUpdateDto";
import { useToast } from "@chakra-ui/react";
import { useQuery } from "react-query";
import alertsApiService from "../Utilities/AlertsApiService";
import LoadingSpinner from "../../Shared/Components/LoadingSpinner";
import { AddEditAlertForm } from "./AddEditAlertForm";
import { enMessages } from "../../../messages/en-US";

interface Props {
  onClose: () => void;
  alertId: string;
  updateAlert: (alertId: string, alert: AlertUpdateDto) => void;
}

export default function EditAlertViewModal(props: Props) {
  const localizationService = useLocalization();
  const toast = useToast({
    position: "bottom",
    duration: 5000,
    isClosable: true,
  });

  const alertQuery = useQuery<any>(
    ["AlertQueryId", props.alertId],
    async () => {
      return alertsApiService.getAlert(props.alertId);
    }
  );

  return (
    <div className="alerts-page">
      <Dialog
        key={props.alertId}
        title={`${
          props.alertId === null
            ? localizationService.toLanguageString(
                "custom.createAlert",
                enMessages.custom.createAlert
              )
            : localizationService.toLanguageString(
                "custom.updateAlert",
                enMessages.custom.updateAlert
              )
        }`}
        onClose={props.onClose}
        className="reservoir-view"
        width="700px"
        height="717px"
      >
        {alertQuery.isLoading || alertQuery.isFetching ? (
          <LoadingSpinner />
        ) : (
          <AddEditAlertForm
            alert={alertQuery.data}
            onClose={props.onClose}
            isAddModal={false}
            alertId={props.alertId}
            updateAlert={props.updateAlert}
          />
        )}
      </Dialog>
    </div>
  );
}
