import {
  XyDataSeries,
  SciChartSurface,
  EAxisAlignment,
  NumericAxis,
  Thickness,
  DateTimeNumericAxis,
  CursorModifier,
  DefaultPaletteProvider,
  EStrokePaletteMode,
  EXyDirection,
  FastLineRenderableSeries,
  FastMountainRenderableSeries,
  NumberRange,
  ZoomExtentsModifier,
  ZoomPanModifier,
  parseColorToUIntArgb,
  } from "scichart";
import { TInitFunction } from "scichart-react";
import { GetChartTheme } from "../../../Shared/SciChart/ChartConfiguration";
import { EventFilter } from "../../../Events/Utilities/EventsApiService";
import moment from "moment";
import { DateTickProvider } from "../../../Shared/SciChart/SmartAxis/DateTickProvider";
import { SmartDateTZLabelProvider } from "../../../Shared/SciChart/SmartAxis/SmartDateTZLabelProvider";
import { axisLabelDateFormatter } from "../../../Shared/SciChart/SmartAxis/axisLabelDateFormatter";
import { createDateTzConverter } from "../../../Shared/SciChart/SmartAxis/timezones";
import { ChartSynchroniser } from "../../Utilities/ChartSynchroniser";

export type TEndpointReportStatusChartConfigFunc = TInitFunction<SciChartSurface>;

export interface EndpointReportChartData {
  requirementsData: any;
  statusData: any;
  dateFilter: EventFilter;
  chartSynchroniser: ChartSynchroniser;
}

export const getChartAPI = (requirementsData: any, statusData: any, chartSynchroniser: ChartSynchroniser, dateFilter: EventFilter, currentLanguage: { locale: string; localeId: string; }) => {

  const theme = GetChartTheme();  

  const onDelete = (initResult) => {
    chartSynchroniser.removeAxis(initResult.sciChartSurface.xAxes.get(0));
    chartSynchroniser.removeSurface(initResult.sciChartSurface);
  } 

  const initChart : TEndpointReportStatusChartConfigFunc = async (rootElement: string | HTMLDivElement) => {
  
    const {sciChartSurface, wasmContext} = await SciChartSurface.create(rootElement, {theme});

    sciChartSurface.padding = Thickness.fromNumber(0);

    // #region Setup the XAxis
    const xAxis = new DateTimeNumericAxis(wasmContext, { 
      axisAlignment: EAxisAlignment.Top, 
      drawMajorBands: false, 
      drawMinorGridLines: false, 
      drawMajorGridLines: false, 
      drawLabels: false,
      autoTicks: false,
      visibleRange: new NumberRange(Math.floor(moment(dateFilter.startDate).valueOf()/1000), Math.floor(moment(dateFilter.endDate).valueOf()/1000)),
      visibleRangeLimit: new NumberRange(Math.floor(moment(dateFilter.startDate).valueOf()/1000), Math.floor(moment(dateFilter.endDate).valueOf()/1000)),
      labelStyle: {fontFamily:"Roboto", fontSize:12,}},
      );

    // Overrides getDeltaFromRange to get nice major and minor deltas for the ticks
    xAxis.deltaCalculator.getDeltaFromRange = axisLabelDateFormatter.getDeltaFromRange;

    const updateSettings = (xAxis: NumericAxis) => {
      const timezone = moment.tz.guess();
      // Calculate ticks which respect the time zone
      xAxis.tickProvider = new DateTickProvider(wasmContext, timezone);
      // Draws nice axis labels timezone and locale specific
      xAxis.labelProvider = new SmartDateTZLabelProvider(timezone,true, currentLanguage?.localeId);
      const dateTzConverter = createDateTzConverter(timezone);
      // This is needed for Cursor and Rollover chart modifiers
      xAxis.labelProvider.formatCursorLabel = timestamp =>
          axisLabelDateFormatter.toFullDateTime(timestamp, dateTzConverter, currentLanguage?.localeId);
      };
    // Setting timezone to local system
    updateSettings(xAxis);

    sciChartSurface.xAxes.add(xAxis);

    // #endregion
    
    // #region Setup the YAxis
    const maxY = statusData !== undefined && (statusData.timestamps !== undefined && statusData.timestamps.length) > 0 ? 2 : 1.2;

    const yAxis = new NumericAxis(wasmContext,{isVisible:false,visibleRange: new NumberRange(0, maxY)});

    sciChartSurface.yAxes.add(yAxis);
    // #endregion

    // #region create PalletteProvider
    class LinePaletteProvider extends DefaultPaletteProvider {
      constructor() {
          super();
          this.strokePaletteMode = EStrokePaletteMode.SOLID;
      }
      overrideStrokeArgb(xValue, yValue, index, opacity, metadata): any {
          if (metadata && metadata.color) {
              return parseColorToUIntArgb(metadata.color);
          } else {
              return undefined;
          }
          
      }
      overrideFillArgb(xValue, yValue, index, opacity, metadata): any {
        if (metadata && metadata.fill) {
            return parseColorToUIntArgb(metadata.fill);
        } else {
            return undefined;
        }
      }}
      // #endregion
      
    // #region Setup the Chart Modifers
    sciChartSurface.chartModifiers.add(
      new ZoomPanModifier({xyDirection: EXyDirection.XDirection}),
      new ZoomExtentsModifier(),
      new CursorModifier({ crosshairStroke: "rgba(50, 53, 67,1)", axisLabelFill: "rgba(50, 53, 67,0.7)", modifierGroup:"one", showYLine: false, showAxisLabels:false, showTooltip:false}),
      );
    // #endregion
    
    // #region Add the Series and etup the RenderableSeries
    //Creating an array with the height of the bars 
    let heightY : number[] = [];
    if(requirementsData.timestamps.length <=2){
      heightY.push(1);
      heightY.push(1);
    } else {
      heightY = requirementsData.timestampData
    }

    const dataSeries = new XyDataSeries(wasmContext, {xValues: requirementsData.timestamps, yValues: heightY, metadata: requirementsData.timestampMetadata, 
    });

    const requirements = new FastMountainRenderableSeries(wasmContext, 
      {
        paletteProvider: new LinePaletteProvider(),
        strokeThickness:1, 
        stroke: "#FFFFFF", 
        isDigitalLine:true, 
        dataSeries: dataSeries,
      });

    sciChartSurface.renderableSeries.add(requirements);

    if(statusData !== undefined) {
      if(statusData.timestamps !== undefined && statusData.timestamps.length > 0) {
        const onOffChart= new FastLineRenderableSeries(wasmContext, { paletteProvider: new LinePaletteProvider(), id:"OnlineOfflineId", stroke:"rgba(0,162,232,1)", strokeThickness: 15,});
          onOffChart.dataSeries = new XyDataSeries(wasmContext, {
          xValues: statusData.timestamps,
          yValues: statusData.yValues,
          metadata: statusData.metadata,
          dataSeriesName: "Online/Offline",
        })
  
      sciChartSurface.renderableSeries.add(onOffChart);
      }

    }
   
    // #endregion
    
    chartSynchroniser.addAxis(xAxis);
    chartSynchroniser.addSurface(sciChartSurface);

    return { sciChartSurface};
  }

  return {initChart, onDelete};
}

