import { ReactElement, useEffect, useMemo, useRef, } from "react";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../../../messages/en-US";
import { EventFilter } from "../../../../Events/Utilities/EventsApiService";
import treeListApiService from "../../../Utilities/TreeListApiService";
import { useQueries, useQuery } from "react-query";
import LoadingSpinner from "../../../../Shared/Components/LoadingSpinner";
import EndpointEventsTableAssets from "../../../Components/Endpoints/EndpointEventsTableAssets";
import NoDataPage from "../../NoDataPage";
import { ReactQueryKeys } from "../../../../../Constants/ClientRoutingConstants";
import EndpointStatusAsset from "./EndpointStatusAsset";
import { ChartToolbar } from "../../../Utilities/ChartToolbar";
import useChartStore from "../../../../../Zustand/chartStore";

interface Props {
  eventFilter: EventFilter
  endpointsIds: string[]
  selectedAssetId: string;
}

export default function AllEndpointActivityTabPage(props: Props): ReactElement {

  const localizationService = useLocalization();

  const aggregator = useChartStore((state) => state.aggregator);

  const [assetId] = props.selectedAssetId.split('_');
  const notificationsQuery = useQuery<any>([ReactQueryKeys.AllAlertsQuery, props.eventFilter], async () => {
    return treeListApiService.getEndpointsAssetNotifications(assetId, props.eventFilter, 1);
  });
  
  const statusQuery = useQuery<any>(["StatusOnOffQuery", props.eventFilter], async () => {
    return treeListApiService.getAssetStatus(assetId, props.eventFilter, 0);
  });

  const endpointsQueriesTable = useQueries<any>(
    props.endpointsIds.map((endpointId) => ({
      queryKey: ['endpointTable', endpointId, props.eventFilter, aggregator],
      queryFn: () => treeListApiService.getAllEndpointsEventsFlowTable(props.eventFilter, endpointId, aggregator) 
    })) || []
  );

  const endpointsQueries = useQueries<any>(
    props.endpointsIds.map((endpointId) => ({
      queryKey: ['endpointChart', endpointId, props.eventFilter, aggregator],
      queryFn: () => treeListApiService.getAllEndpointsEventsFlowChart(props.eventFilter, endpointId, aggregator) 
    })) || []
  );

  const eventTableData = useMemo(() => {
    const endpointsArray = endpointsQueriesTable.map(endpoint => {
      return endpoint.data
      
      })
      return [].concat(...endpointsArray)?.map((event: any) => {
        return {
          ...event,
          volumeDispensedRound: event?.volumeDispensed.toFixed(2),
        };
      })
  },[endpointsQueriesTable]).filter(x => x !== undefined) 

const isLoadingTable = endpointsQueriesTable.some(result => result.isLoading)
const isLoadingChart = endpointsQueries.some(result => result.isLoading)
const isFetchingTable = endpointsQueriesTable.some(result => result.isFetching)
const isFetchingChart = endpointsQueries.some(result => result.isFetching)


const getChartData = () => {

  var result = endpointsQueries.map(endpoint => {
    const volume = endpoint.data !== undefined ? endpoint.data?.volumeDispensedData : []
    return {
      ...endpoint.data,
      maxVolume: Math.max(...volume),
      minVolume: Math.min(...volume),
    }
    }).sort((a,b) => a.maxVolume > b.maxVolume ? -1 : 1);

    var eventChartData = result.filter(endpoint => endpoint.timestamps?.length > 0);
    const visibleRange = {timestamps: eventChartData.map(endpoint => endpoint.timestamps).sort((a,b) => a > b ? 1 : -1)};

  return {dateFilter: props.eventFilter, dataChart: eventChartData, visibleRange: visibleRange, aggregator: aggregator}
};

const getStateChartData = () => {

  var result = endpointsQueries.map(endpoint => {
    const volume = endpoint.data !== undefined ? endpoint.data?.volumeDispensedData : []
    return {
      ...endpoint.data,
      maxVolume: Math.max(...volume),
      minVolume: Math.min(...volume),
    }
    }).sort((a,b) => a.maxVolume > b.maxVolume ? -1 : 1);

  var eventChartData = result.filter(endpoint => endpoint.timestamps?.length > 0);

  const visibleRange = {timestamps: eventChartData.map(endpoint => endpoint.timestamps).sort((a,b) => a > b ? 1 : -1)};

  return {dateFilter: props.eventFilter, alerts: notificationsQuery.data, statusData: statusQuery.data, timestamps: visibleRange}
}

  if (isLoadingTable || isLoadingChart || notificationsQuery.isLoading || notificationsQuery.isFetching || statusQuery.isLoading || statusQuery.isFetching || isFetchingTable || isFetchingChart) {
    return <LoadingSpinner />;
  } else {
  return (
    <div>   
    {endpointsQueries.length === 0 || eventTableData.length === 0 ? 
      <NoDataPage />
    :
    <div>
      <ChartToolbar 
        title={localizationService.toLanguageString('custom.events', enMessages.custom.events)}
        lastSeen={null}
        showAutoRange={true} 
        showAggregatorOptions={true} 
        showChartOptions={true}
        showThresholds={false} 
        showSingleMultipleChart={false}
      />
      <div>
        <EndpointStatusAsset key={`ActivityChartAsset`} statusChartData={getStateChartData()} activityDataChart={getChartData()} />
      </div>
      <div className="">
        <EndpointEventsTableAssets 
          events={eventTableData}
          aggregator={aggregator}
        />
      </div>
    </div>
    }
    </div> 
    )
}}
