import React, { ReactElement, useEffect, useMemo, useRef } from "react";
import AreaReadDto from "../../../Models/Area/AreaReadDto";
import { MachineReadDto } from "../../../Models/Machine/MachinesReadDto";
import { ConsumableReadDto } from "../../../Models/Consumable/ConsumableReadDto";
import { ColumnMetaData } from "../../Shared/Interfaces/ColumnMetaData";
import GBKendoDataCell from "../../Shared/Components/Table/GBKendoDataCell";
import useKendoTableState from "../../Shared/Hooks/useKendoTableState";
import { Formik, FormikProps } from "formik";
import { Button } from "@progress/kendo-react-buttons";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import {
  Grid,
  GridPageChangeEvent,
  GridToolbar,
  GridColumn as Column,
  GridFilterChangeEvent,
  GridSortChangeEvent,
  GridRowProps,
} from "@progress/kendo-react-grid";
import { isRequiredBySchema } from "../../Shared/Utilities/YupValidationUtilities";
import GBKendoToolbar from "../../Shared/Components/Table/GBKendoToolbar";
import { Checkbox } from "@progress/kendo-react-inputs";
import { CustomDateFilterCell } from "../../Shared/Components/Table/CustomDateFilterCell";
import { CustomTextFilterCell } from "../../Shared/Components/Table/CustomTextFilterCell";
import {
  CompositeFilterDescriptor,
  filterBy,
  orderBy,
} from "@progress/kendo-data-query";
import { useLocalization } from "@progress/kendo-react-intl";
import { enMessages } from "../../../messages/en-US";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNoteSticky } from "@fortawesome/free-solid-svg-icons";
import EndpointReadTableDto from "../../../Models/Endpoint/EndpointReadTableDto";
import EndpointValidationSchema from "../Utilities/EndpointValidationSchema";
import AdminEndpointReadDto from "../../../Models/Endpoint/AdminEndpointReadDto";
import NotesDialog from "../../Shared/Components/Dialogs/NotesDialog";
import { RequirementReadDto } from "../../../Models/Requirement/RequirementReadDto";

interface Props {
  initialSort: any;
  initialSortDescriptor: any;
  endpoints: Array<EndpointReadTableDto>;
  endpointDevices: Array<AdminEndpointReadDto>;
  requirements: Array<RequirementReadDto>;
  machines: Array<MachineReadDto>;
  areas: Array<AreaReadDto>;
  consumables: Array<ConsumableReadDto>;
  createEndpoint: (values: any, setStatus: any) => void;
  deleteEndpoints: (deleteIds: Array<string>) => void;
  updateEndpoints: (points: Array<EndpointReadTableDto>) => void;
  height: number;
}

const initialFilter: CompositeFilterDescriptor = {
  logic: "and",
  filters: [],
};

function EndpointTable(props: any) {
  const localizationService = useLocalization();
  const [openNotes, setOpenNotes] = React.useState<boolean>(false);
  const [selectedItem, setSelectedItem] =
    React.useState<EndpointReadTableDto | null>(null);
  const closeHandler = () => {
    setOpenNotes(false);
    setSelectedItem(null);
  };

  const updateNotes = (notes: string) => {
    // Check if we are adding a new row, if so then we need to apply the description to the current temp row
    // instead of doing an update as the object does not exist yet.
    if (tableState.tempRows.length > 0) {
      tableState.tempRows[0].description = notes;
      return;
    }

    if (selectedItem) {
      const castValues = {
        name: selectedItem.name,
        id: selectedItem.id,
        description: notes,
        status: selectedItem.status,
        consumableId: selectedItem.consumableId,
        pointType: selectedItem.pointType,
        machineId: selectedItem.machineId,
        areaId: selectedItem.areaId,
        photo: selectedItem.photo,
        crewType: selectedItem.crewType,
        criticality: selectedItem.criticality,
        securityClassification: selectedItem.securityClassification,
        schedulePattern: selectedItem.schedulePattern,
        endpointDeviceId: selectedItem.endpointDeviceId,
        endpointDeviceSerialNumber: selectedItem.endpointDeviceSerialNumber,
        requirementId: selectedItem.requirementId,
        requirementSummary: selectedItem.requirementSummary,
        requirementName: selectedItem.requirementName,
      } as EndpointReadTableDto;

      props.updateEndpoints([castValues]);
    }
  };

  const tableState = useKendoTableState<EndpointReadTableDto>({
    key: "Endpoints",
    initialAssets: props.endpoints,
  });

  const stateRef = useRef(tableState);

  useEffect(() => {
    stateRef.current = tableState;
  }, [tableState]);

  const [filter, setFilter] = React.useState(initialFilter);
  const [sort, setSort] = React.useState(
    props.initialSortDescriptor ?? props.initialSort
  );

  // Table columns
  const columns: Array<ColumnMetaData<EndpointReadTableDto>> = React.useMemo(
    () => [
      {
        header: `${localizationService.toLanguageString(
          "custom.notes",
          enMessages.custom.notes
        )}`,
        displayField: "description",
        filterType: "boolean",
        Cell: (cellProps) => (
          <GBKendoDataCell<EndpointReadTableDto>
            className={"text-center"}
            dataFieldName="description"
            cellProps={cellProps}
            fieldAccessor={(x) => {
              if (x.description === "" || x.description === null) {
                return (
                  <Button
                    fillMode={"flat"}
                    icon="plus-outline"
                    onClick={() => {
                      setOpenNotes(true);
                      setSelectedItem(cellProps.dataItem);
                    }}
                  />
                );
              } else {
                return (
                  <Button
                    fillMode={"flat"}
                    onClick={() => {
                      setOpenNotes(true);
                      setSelectedItem(cellProps.dataItem);
                    }}
                  >
                    <FontAwesomeIcon icon={faNoteSticky} />
                  </Button>
                );
              }
            }}
            isAddMode={false}
            isEditMode={false}
            onCellChanged={tableState.onCellChanged}
          />
        ),
      },
      {
        header: `${localizationService.toLanguageString(
          "custom.name",
          enMessages.custom.name
        )}`,
        displayField: "name",
        filterType: "text",
        Cell: (cellProps) => (
          <GBKendoDataCell<EndpointReadTableDto>
            dataFieldName="name"
            cellProps={cellProps}
            fieldAccessor={(x) => x.name}
            isAddMode={cellProps.dataIndex < tableState.tempRows.length}
            isEditMode={tableState.canModifyRow}
            onCellChanged={tableState.onCellChanged}
          />
        ),
      },
      {
        header: `${localizationService.toLanguageString(
          "custom.asset",
          enMessages.custom.asset
        )}`,
        displayField: "machineName",
        Cell: (cellProps) => (
          <GBKendoDataCell<EndpointReadTableDto>
            dataFieldName="machineId"
            fieldAccessor={(x) => x.machineId}
            displayTextAccessor={(x) => x.name}
            cellProps={cellProps}
            isAddMode={cellProps.dataIndex < tableState.tempRows.length}
            isEditMode={tableState.canModifyRow}
            onCellChanged={tableState.onCellChanged}
            assets={props.machines?.filter(
              (x: { areaId: string }) =>
                cellProps.dataItem.areaId == null ||
                cellProps.dataItem.areaId === "" ||
                x.areaId === cellProps.dataItem.areaId
            )} //Only show valid machines if area selected
            inputType="combobox"
            initialDisplayText={cellProps.dataItem.machineName}
            onChange={(newValue, form) =>
              onMachineSelected(cellProps.dataItem.id, newValue, form)
            }
          />
        ),
      },
      {
        header: `${localizationService.toLanguageString(
          "custom.location",
          enMessages.custom.location
        )}`,
        displayField: "areaName",
        Cell: (cellProps) => (
          <GBKendoDataCell<EndpointReadTableDto>
            dataFieldName="areaId"
            fieldAccessor={(x) => x.areaId}
            displayTextAccessor={(x) => x.name}
            cellProps={cellProps}
            isAddMode={cellProps.dataIndex < tableState.tempRows.length}
            isEditMode={tableState.canModifyRow}
            onCellChanged={tableState.onCellChanged}
            assets={props.areas}
            inputType="combobox"
            initialDisplayText={cellProps.dataItem.areaName}
          />
        ),
      },
      {
        header: `${localizationService.toLanguageString(
          "custom.serialNumber",
          enMessages.custom.serialNumber
        )}`,
        displayField: "endpointDeviceSerialNumber",
        filterType: "text",
        Cell: (cellProps) => (
          <GBKendoDataCell<EndpointReadTableDto>
            dataFieldName="endpointDeviceId"
            cellProps={cellProps}
            fieldAccessor={(x) => x.endpointDeviceId}
            displayTextAccessor={(x) => x.serialNumber}
            isAddMode={cellProps.dataIndex < tableState.tempRows.length}
            isEditMode={tableState.canModifyRow}
            assets={
              // If in edit mode then we need to append the current endpoint device to the list
              tableState.canModifyRow
                ? props.endpointDevices
                    .filter(
                      (x) =>
                        !stateRef.current.assets.some(
                          (item) => item.endpointDeviceId === x.id
                        )
                    )
                    .concat(
                      props.endpointDevices.filter(
                        (x) => cellProps.dataItem.endpointDeviceId === x.id
                      )
                    )
                : props.endpointDevices.filter(
                    (x) =>
                      !stateRef.current.assets.some(
                        (item) => item.endpointDeviceId === x.id
                      )
                  )
            }
            inputType="combobox"
            initialDisplayText={cellProps.dataItem.endpointDeviceSerialNumber}
            onCellChanged={tableState.onCellChanged}
          />
        ),
      },
      {
        header: `${localizationService.toLanguageString(
          "custom.requirement",
          enMessages.custom.requirement
        )}`,
        displayField: "requirementName",
        Cell: (cellProps) => (
          <GBKendoDataCell<EndpointReadTableDto>
            dataFieldName="requirementId"
            fieldAccessor={(x) => x.requirementId}
            displayTextAccessor={(x) => x.name}
            cellProps={cellProps}
            isAddMode={cellProps.dataIndex < tableState.tempRows.length}
            isEditMode={tableState.canModifyRow}
            onCellChanged={tableState.onCellChanged}
            assets={props.requirements}
            inputType="combobox"
            placeholder="No Requirement"
            initialDisplayText={cellProps.dataItem.requirementName}
          />
        ),
      },
      {
        header: `${localizationService.toLanguageString(
          "custom.requirementSummary",
          enMessages.custom.requirementSummary
        )}`,
        displayField: "requirementConsumable",
        filterType: "text",
        Cell: (cellProps) => (
          <GBKendoDataCell<EndpointReadTableDto>
            dataFieldName="requirementId"
            cellProps={cellProps}
            fieldAccessor={(x) =>
              x.requirementName !== undefined ? (
                <div>{x.requirementSummary}</div>
              ) : (
                ""
              )
            }
            isAddMode={false}
            isEditMode={false}
            onCellChanged={tableState.onCellChanged}
          />
        ),
      },
    ],
    [
      tableState.tempRows.length,
      tableState.canModifyRow,
      props.machines,
      props.areas,
      props.endpointDevices,
      props.requirements,
      filter,
      sort
    ]
  );

  const _export = React.useRef<ExcelExport | null>(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };
  const ref = useRef<any>();

  /**The following use effects are required to fix a race condition created by updating formik fields
   * Programatically. They ensure that validation is performed after the values update instead of before.
   **/
  useEffect(() => {
    ref.current.validateForm();
  }, [ref.current?.values]);
  useEffect(() => {
    ref.current.validateForm();
  }, []);

  /**
   * Set the skip amount to zero if the number of temp rows (indicating an add row has been added).
   * This effectively ensures the add row is always scrolled into view
   */
  useEffect(() => {
    if (tableState.tempRows.length === 1) {
      setSkip(0);
    }
  }, [tableState.tempRows.length]);

  //The following state is required for dynamic scrolling
  const [skip, setSkip] = React.useState<number>(0);
  const pageChange = (event: GridPageChangeEvent) => {
    setSkip(event.page.skip);
  };

  const isColumnForRequiredField = (
    displayField: string | undefined
  ): boolean => {
    return (
      isRequiredBySchema(
        displayField?.replace("Name", "Id"),
        EndpointValidationSchema(localizationService)
      ) &&
      (tableState.tempRows.length > 0 || tableState.canModifyRow)
    );
  };

  useEffect(() => {
    // Reset skip when filter or sort changes
    setSkip(0);
  }, [filter, sort]);

  //Data is API data + current temporary rows that have not yet been persisted using the API
  let processedData = useMemo(() => {

    let initialRows = tableState.assets;

    if (filter) {
      initialRows = filterBy(initialRows, filter); 
    }

    if (sort) {
      initialRows = orderBy(initialRows, sort);
    }

    //Add any temp rows to beggining
    return tableState.tempRows.concat(initialRows);

  }, [tableState.assets, tableState.tempRows, filter, sort]);

  /**
   * When machine selected, auto populate parent area to that of machine
   */
  const onMachineSelected = async (
    rowId: string,
    newValue: any,
    form: FormikProps<any>
  ) => {
    const selectedMachine = props.machines.find(
      (x: { id: any }) => x.id === newValue
    );
    const selectedArea = props.areas.find(
      (x: { id: any }) => x.id === selectedMachine?.areaId
    );
    //If the field being edited is an addrow
    if (rowId == null || rowId === "") {
      tableState.setTempRows((old: Array<EndpointReadTableDto>) => {
        let updatedAssets = [...old];
        updatedAssets[0] = {
          ...updatedAssets[0],
          areaId: selectedArea?.id ?? "",
          areaName: selectedArea?.name ?? "",
        };
        return updatedAssets;
      });
      form.setFieldValue("areaId", selectedMachine?.areaId ?? "", false);
      await form.setFieldError("areaId", "undefined"); //No errors on selection
      return;
    }

    //Now update current machine to have same area as selected
    tableState.setAssets((old: Array<EndpointReadTableDto>) => {
      const modifiedRowIndex = old.findIndex((x) => x.id === rowId);
      let updatedAssets = [...old];
      updatedAssets[modifiedRowIndex] = {
        ...updatedAssets[modifiedRowIndex],
        areaId: selectedArea?.id ?? "",
        areaName: selectedArea?.name ?? "",
      };
      return updatedAssets;
    });
  };

  //Initial row added
  const initialisedRowData = {
    consumableName: "",
    machineName: "",
    areaName: "",
    expanded: true,
    id: "",
    consumableId: "",
    pointType: "Endpoint",
    machineId: "",
    areaId: "",
    name: "",
    photo: "",
    description: "",
    crewType: "",
    criticality: "Normal",
    securityClassification: "Unclassified",
    status: "Active",
    schedulePattern: "",
    endpointDeviceId: "",
    endpointDeviceSerialNumber: "",
    requirementId: "",
    requirementName: "",
    requirementSummary: "",
  };

  return (
    <div>
      <Formik
        innerRef={ref}
        key={props.endpoints.length}
        onSubmit={async (values, { setStatus, resetForm }) => {
          if (tableState.tempRows.length > 0) {
            values.description = tableState.tempRows[0].description;
          }
          const res = await props.createEndpoint(values, setStatus);
          if (res) {
            resetForm();
            tableState.setTempRows([]);
          }
        }}
        validationSchema={EndpointValidationSchema(localizationService)}
        initialValues={initialisedRowData}
      >
        {({ submitForm, values, resetForm, isSubmitting }) => (
          <>
            <ExcelExport data={processedData} ref={_export} collapsible={true} fileName={localizationService.toLanguageString('custom.endpoints', enMessages.custom.endpoints)}>
              <ExcelExportColumn field="name" title={localizationService.toLanguageString('custom.name', enMessages.custom.name)} /> 
              <ExcelExportColumn field="machineName" title={localizationService.toLanguageString('custom.asset', enMessages.custom.asset)} />
              <ExcelExportColumn field="areaName" title={localizationService.toLanguageString('custom.location', enMessages.custom.location)} />
              <ExcelExportColumn field="endpointDeviceSerialNumber" title={localizationService.toLanguageString('custom.serialNumber', enMessages.custom.serialNumber)} />
              <ExcelExportColumn field="requirementName" title={localizationService.toLanguageString('custom.requirement', enMessages.custom.requirement)} />
              <ExcelExportColumn field="requirementSummary" title={localizationService.toLanguageString('custom.requirementSummary', enMessages.custom.requirementSummary)}/>
              <ExcelExportColumn field="description" title={localizationService.toLanguageString('custom.notes', enMessages.custom.notes)}/>
            </ExcelExport>
            <Grid
              key={tableState.tempRows.length.toString()}
              rowHeight={46}
              pageSize={30}
              total={processedData.length}
              data={processedData}
              expandField="expanded"
              scrollable="virtual"
              skip={skip}
              onPageChange={pageChange}
              filterable={true}
              filter={filter}
              onFilterChange={(e: GridFilterChangeEvent) => setFilter(e.filter)}
              sortable={true}
              sort={sort}
              onSortChange={(e: GridSortChangeEvent) => {
                setSort(e.sort);
              }}
              style={{
                height: props.height,
              }}
              className="w-full  z-0"
              //Custom render row,
              rowRender={(trElement: ReactElement, props: GridRowProps) => {
                let baseStyle = { ...trElement.props.style };
                if (tableState.modifiedRowIds.includes(props.dataItem.id)) {
                  baseStyle.backgroundColor = "rgba(255, 252, 88, 0.25)";
                }
                if (tableState.selectedRowsIds.includes(props.dataItem.id)) {
                  baseStyle.backgroundColor = "rgba(255, 99, 88, 0.25)";
                }

                return React.cloneElement(
                  trElement,
                  {
                    ...trElement,
                    style: { ...baseStyle },
                  },
                  trElement.props.children
                );
              }}
            >
              <GridToolbar>
                <GBKendoToolbar
                  name={`${localizationService.toLanguageString(
                    "custom.endpointMapping",
                    enMessages.custom.endpointMapping
                  )}`}
                  tableState={tableState}
                  exportAction={excelExport}
                  importAction={null}
                  addAction={
                    props.createEndpoint
                      ? () => {
                          if (tableState.tempRows.length === 1) {
                            tableState.setTempRows([]);
                            return;
                          }
                          tableState.setTempRows([
                            ...tableState.tempRows,
                            initialisedRowData,
                          ]);
                        }
                      : null
                  }
                  updateAction={
                    props.updateEndpoints
                      ? async () => {
                          let updatedAssets = tableState.assets.filter((x) =>
                            tableState.modifiedRowIds.includes(x.id)
                          );
                          await props.updateEndpoints(updatedAssets);
                        }
                      : null
                  }
                  deleteAction={
                    props.deleteEndpoints
                      ? async () =>
                          await props.deleteEndpoints(
                            tableState.selectedRowsIds
                          )
                      : null
                  }
                  discardChanges={() => tableState.resetState()}
                />
              </GridToolbar>

              {/* Selection - only if in edit mode*/}
              {tableState.canModifyRow && (
                <Column
                  key={"Selection"}
                  cell={(props) =>
                    props.rowType === "groupHeader" ? null : (
                      <td>
                        <Checkbox
                          onChange={() => {
                            if (
                              tableState.selectedRowsIds.includes(
                                props.dataItem.id
                              )
                            ) {
                              tableState.setSelectedRowIds(
                                tableState.selectedRowsIds.filter(
                                  (id) => id !== props.dataItem?.id
                                )
                              );
                            } else {
                              tableState.setSelectedRowIds([
                                ...tableState.selectedRowsIds,
                                props.dataItem?.id,
                              ]);
                            }
                          }}
                          checked={tableState.selectedRowsIds.includes(
                            props.dataItem.id
                          )}
                        />
                      </td>
                    )
                  }
                  width="50px"
                  filterable={false}
                  sortable={false}
                />
              )}

              {/* From Column Array */}
              {columns.map((column, index) => {
                if (column.displayField === "description") {
                  return (
                    //Create wrapper/HOC with editable cell and add cell definitions,
                    <Column
                      width="65px"
                      className="text-center"
                      key={index}
                      cell={column.Cell}
                      title={`${column.header}${
                        isColumnForRequiredField(column.displayField) ? "*" : ""
                      }`}
                      field={column.displayField}
                      filterable={false}
                      sortable={true}
                    />
                  );
                } else {
                  return (
                    //Create wrapper/HOC with editable cell and add cell definitions,
                    <Column
                      width="auto"
                      key={index}
                      cell={column.Cell}
                      // Show asterix in header if required TODO: pretty hacky - assumes that the name of the data field is the same as the display field with Name replaced.
                      title={`${column.header}${
                        isColumnForRequiredField(column.displayField) ? "*" : ""
                      }`}
                      field={column.displayField}
                      filter={column.filterType}
                      filterCell={
                        column.filterType === "date"
                          ? CustomDateFilterCell
                          : CustomTextFilterCell
                      }
                    />
                  );
                }
              })}
              {/* <Column field="requirements.name" title={'Requirements'}/> */}

              {/* Inline Action options (If ActionDefinitions are provided) */}
              {props.extraInlineActions != null &&
                props.extraInlineActions.length > 0 && (
                  <Column
                    title={localizationService.toLanguageString(
                      "custom.actions",
                      enMessages.custom.actions
                    )}
                    sortable={false}
                    filterable={false}
                    cell={(cellProps) => {
                      return (
                        <td className="flex flex-wrap justify-around gap-1">
                          {tableState.tempRows.length <= cellProps.dataIndex &&
                            props.extraInlineActions.map((action: any) => {
                              return (
                                <Button
                                  className=""
                                  themeColor={action.themeColor}
                                  onClick={() =>
                                    action.onClick(cellProps.dataItem)
                                  }
                                >
                                  {action.text}
                                </Button>
                              );
                            })}
                        </td>
                      );
                    }}
                  />
                )}

              {/* Command Cell (For add rows only)*/}
              {tableState.tempRows.length > 0 && (
                <Column
                  width="150px"
                  sortable={false}
                  filterable={false}
                  key={"Commands"}
                  cell={(
                    props // Add Button for new assets
                  ) =>
                    props.rowType === "groupHeader" ? null : (
                      <td>
                        {props.dataIndex === 0 && (
                          <div className="flex space-x-1">
                            <Button
                              onClick={submitForm}
                              disabled={isSubmitting}
                              themeColor={"primary"}
                            >
                              {localizationService.toLanguageString(
                                "custom.add",
                                enMessages.custom.add
                              )}
                            </Button>
                            <Button
                              onClick={() => {
                                tableState.setTempRows([]);
                                resetForm();
                              }}
                              themeColor={"base"}
                            >
                              {localizationService.toLanguageString(
                                "custom.exit",
                                enMessages.custom.exit
                              )}
                            </Button>
                          </div>
                        )}
                      </td>
                    )
                  }
                />
              )}
            </Grid>
            </>

        )}
      </Formik>
      {openNotes && (
        <NotesDialog
          onClose={closeHandler}
          notes={selectedItem}
          updateNotes={updateNotes}
          asset={selectedItem?.machineName}
        />
      )}
    </div>
  );
}

export default EndpointTable;
