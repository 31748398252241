import { ReactElement } from "react";
import { useLocalization } from '@progress/kendo-react-intl';
import { Icon } from "@progress/kendo-react-common";
import { enMessages } from "../../../messages/en-US";
import { SciChartReact } from "scichart-react";
import React from "react";
import { Props, getChartAPI } from "./ReservoirChartApi";

export default function ReservoirChart(props: Props): ReactElement {
  const localizationService = useLocalization();
  const [reservoirChartAPI] = React.useState(getChartAPI(props, localizationService));

    return (
      <div style={{height: props.reservoirStatus.estimatedVolume < 0 ? "90%" : "95%", width:"100%"}}>
          <SciChartReact 
            initChart={reservoirChartAPI.initChart} 
            style={{aspectRatio: 1, width:"100%", height:"100%"}}
            // customize size of the inner chart wrapper
            innerContainerProps={{ style: { height: "100%" } }}
            // Create a fallback component with an empty div to prevent a black background initially appearing. 
            fallback={<div/>}
          />
          <div className="reservoir-chart" style={{  width:'100%'}}>
            {props.reservoirStatus.estimatedVolume < 0 && (
              <div className="flex" style={{width:"100%", alignItems: "center", justifyContent: "center"}}>
              <div className="flex justify-between gap-1 p-1" style={{backgroundColor:"#FAC339", width:"100%",height:"26px", alignItems:"center", borderRadius:"3px"}}>
                  {/* First Row */}
                  <Icon name="warning"/>
                <div style={{fontSize:"13px", fontWeight:""}}>{Math.abs(props.reservoirStatus.estimatedVolume).toFixed(1)} {props.reservoir.displayUnit}  {localizationService.toLanguageString('custom.verifyLastResetDate', enMessages.custom.verifyLastResetDate)}</div>
                <Icon name="warning"/>
              </div>
              </div>
            )}
            <div className="flex" id="legend-reservoir" style={{width: '100%', alignItems: "center", justifyContent: "center"}}></div>
          </div>
      </div>
  );
}
 