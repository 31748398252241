import { ReactElement, useEffect, useMemo } from "react";
import {TabStrip,TabStripTab,} from '@progress/kendo-react-layout';
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../../messages/en-US";
import { useQuery } from "react-query";
import { ReactQueryKeys } from "../../../../Constants/ClientRoutingConstants";
import LoadingSpinner from "../../../Shared/Components/LoadingSpinner";
import ErrorCard from "../../../Shared/Components/Cards/ErrorCard";
import treeListApiService from "../../Utilities/TreeListApiService";
import EventReadDto from "../../../../Models/Event/EventReadDto";
import { PointReadDto } from "../../../../Models/Point/PointReadDto";
import eventApiService, { EventFilter } from "../../../Events/Utilities/EventsApiService";
import { MachineReadDto } from "../../../../Models/Machine/MachinesReadDto";
import AreaReadDto from "../../../../Models/Area/AreaReadDto";
import { ConsumableReadDto } from "../../../../Models/Consumable/ConsumableReadDto";
import { DeviceReadDto } from "../../../../Models/Device/DeviceReadDto";
import pointsApiService from "../../../Points/Utilities/PointsApiService";
import machinesApiService from "../../../Machines/Utilities/MachinesApiService";
import areasApiService from "../../../Area/Utilities/AreasApiService";
import consumablesApiService from "../../../Consumables/Utilities/ConsumablesApiService";
import devicesApiService from "../../../Devices/Utilities/DevicesApiService";
import PointActivityTabPage from "./PointActivityTabPage";
import { TaskReadDto } from "../../../../Models/Task/TaskReadDto";
import { ScheduleReadDto } from "../../../../Models/Schedule/ScheduleReadDto";
import tasksApiService from "../../../Tasks/Utilities/TasksApiService";
import scheduleApiService from "../../../Schedules/Utilities/ScheduleApiService";
import NoDataPage from "../NoDataPage";

interface Props {
  selectedItem: any
  eventFilter: EventFilter
  selectedItemId: string
  reservoirId: string
  tabId: number;
  onLinkReservoir: () => void;
  selectedPointTab: number;
  handleSelectPointTab:any;
}

export default function PointDetailsPage(props: Props): ReactElement {
  const localizationService = useLocalization();

  const pointsActivityQuery = useQuery<any>(["Activity", props.eventFilter, props.selectedItemId], async () => {
    return treeListApiService.getAllPointsEvents(props.eventFilter, props.selectedItemId);
  });

  const pointsQuery = useQuery<Array<PointReadDto>>(ReactQueryKeys.AllPointsQuery, async () => {
    return pointsApiService.getAllPoints();
  });

  useEffect(() => {
    pointsActivityQuery.refetch();
  }, [props.selectedItemId, props.eventFilter])

  //QueriesForPage
  const eventsQuery = useQuery<Array<EventReadDto>>([ReactQueryKeys.AllEventsQuery, props.eventFilter], async () => {
    return eventApiService.getAllPointsEvents(props.eventFilter);
  });

  const machinesQuery = useQuery<Array<MachineReadDto>>(ReactQueryKeys.AllMachinesQuery, async () => {
    return machinesApiService.getAllMachines();
  });

  const areasQuery = useQuery<Array<AreaReadDto>>(ReactQueryKeys.AllAreasQuery, async () => {
    return areasApiService.getAllAreas();
  });

  const consumablesQuery = useQuery<Array<ConsumableReadDto>>(ReactQueryKeys.AllConsumablesQuery, async () => {
    return consumablesApiService.getAllConsumables();
  });

  const devicesQuery = useQuery<Array<DeviceReadDto>>(ReactQueryKeys.AllDevicesQuery, async () => {
    return devicesApiService.getAllDevices();
  });

  const schedulesQuery = useQuery<Array<ScheduleReadDto>>(ReactQueryKeys.AllSchedulesQuery, async () => {
    return scheduleApiService.getAllSchedules();
  });

  const tasksQuery = useQuery<Array<TaskReadDto>>(ReactQueryKeys.AllTasksQuery, async () => {
    return tasksApiService.getAllTasks();
  });
  
  const eventData = useMemo(() => {
    //Create hashmap of relevant id -> name pairs.
    const areaIdtoName: { [id: string]: string } = {};
    const consumableIdtoName: { [id: string]: string } = {};
    const machineIdtoName: { [id: string]: string } = {};
    const pointIdtoName: { [id: string]: string } = {};
    const deviceIdtoName: { [id: string]: string } = {};
    const scheduleIdToName: { [id: string]: string } = {};
    const pointIdToTask: { [id: string]: TaskReadDto } = {};
    areasQuery.data?.forEach((area) => {
      areaIdtoName[area.id] = area.name;
    });
    consumablesQuery.data?.forEach((consumable) => {
      consumableIdtoName[consumable.id] = consumable.name;
    });
    machinesQuery.data?.forEach((machine) => {
      machineIdtoName[machine.id] = machine.name;
    });
    pointsQuery.data?.forEach((point) => {
      pointIdtoName[point.id] = point.name;
    });
    devicesQuery.data?.forEach((device) => {
      deviceIdtoName[device.id] = device.serialNumber;
    });
    schedulesQuery.data?.forEach((schedule) => {
      scheduleIdToName[schedule.id] = schedule.name;
    });
    tasksQuery.data?.forEach((task) => {
      pointIdToTask[task.point.id] = task;
    });
    
    //Map over machines to inject relevant data
    const results = eventsQuery.data?.map((event) => {
      return {
        ...event,
        occurred: new Date(event.occurred),
        areaName: areaIdtoName[event.areaId] ?? null,
        consumableName: consumableIdtoName[event.consumableId] ?? null,
        machineName: machineIdtoName[event.machineId] ?? null,
        pointName: pointIdtoName[event.pointId] ?? null,
        serialNumber: deviceIdtoName[event.deviceId] ?? null,
        scheduleId: pointIdToTask[event.pointId]?.scheduleId === null ? "" : pointIdToTask[event.pointId]?.scheduleId,
        scheduleName: scheduleIdToName[pointIdToTask[event.pointId]?.scheduleId] === undefined ? "No Schedule" : scheduleIdToName[pointIdToTask[event.pointId]?.scheduleId],
      } 
    });

    return results;
  }, [areasQuery.data, consumablesQuery.data, machinesQuery.data, pointsQuery.data, devicesQuery.data, schedulesQuery.data, tasksQuery.data, eventsQuery.data]);
  
  const eventDataItem = eventData?.filter(item => item.pointId === props.selectedItemId)

  const [assetId] = props.selectedItem.parentId.split('_');

  //Loading renderer function to ensure all Queries all loaded.
  if (eventsQuery.isLoading || areasQuery.isLoading || pointsQuery.isLoading || machinesQuery.isLoading || consumablesQuery.isLoading || devicesQuery.isLoading || pointsActivityQuery.isLoading || tasksQuery.isLoading || schedulesQuery.isLoading || eventsQuery.isFetching || areasQuery.isFetching || pointsQuery.isFetching || machinesQuery.isFetching || consumablesQuery.isFetching || devicesQuery.isFetching || pointsActivityQuery.isFetching || tasksQuery.isFetching || schedulesQuery.isFetching) {
    return <LoadingSpinner />;
  } else {
    if (eventsQuery.error || areasQuery.error || pointsQuery.error || machinesQuery.error || consumablesQuery.error || devicesQuery.error || pointsActivityQuery.error) {
      return <ErrorCard />;
    } else {
  return (
        <div className="tabstrip">
          {eventDataItem?.length !== 0 ?
          <TabStrip selected={props.selectedPointTab} onSelect={props.handleSelectPointTab} className="h-full">
            <TabStripTab title={`${localizationService.toLanguageString('custom.activity', enMessages.custom.activity)}`}>
             <PointActivityTabPage key={`pointActivityTabPage${props.selectedItemId}`}
                events={eventDataItem} selectedItemId={props.selectedItemId} eventFilter={props.eventFilter} assetId={assetId} />
            </TabStripTab>
          </TabStrip>
          :
          <NoDataPage/> 
          }
        </div>
  )
}}}
