import { ReactElement, useMemo, useState } from "react";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";
import { Icon } from "@progress/kendo-react-common";

export default function NoDataPage(): ReactElement {
  const localizationService = useLocalization();


  return (
    <div className="gb-text-normal  text-lg text-center"
      style={{ borderRadius: '3px', padding: '40px 25px',margin: '30px auto', width:'550px'}}>
      <div className="text-center mb-5">
        <Icon name="k-icon k-i-warning" style={{fontSize:'72px'}}/>    
      </div>
      <div>{`${localizationService.toLanguageString('custom.noDataFound', enMessages.custom.noDataFound)}`}</div>
      <br/>
      <div style={{textAlign:'left'}}>{`${localizationService.toLanguageString('custom.thisCouldBeBecause', enMessages.custom.thisCouldBeBecause)}`}:</div>  
      <br />
      <ul style={{listStyleType: 'disc', textAlign:'left', display:'inline-block', listStylePosition:'inside'}}>
        <li style={{ paddingLeft: '1.5em', textIndent: '-1.5em'}}>{`${localizationService.toLanguageString('custom.trySelectingDates', enMessages.custom.trySelectingDates)}`}</li>
        <li style={{ paddingLeft: '1.5em', textIndent: '-1.5em'}}>{`${localizationService.toLanguageString('custom.unableTocommunicate', enMessages.custom.unableTocommunicate)}`}</li>
      </ul>
    </div> 
  )}
