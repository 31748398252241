import { SciChartNestedOverview, SciChartReact } from "scichart-react";
import { useLocalization } from "@progress/kendo-react-intl";
import React, { useEffect, useRef } from "react";
import { AppContext } from "../../../../../AppContext";
import { ChartOptions, Props, getChartAPI } from "./ActivityChartApi";
import { getCurrentLanguage } from "../../../../Shared/SciChart/ChartConfiguration";
import { overviewOptions } from "../../../../Shared/SciChart/Overview";
import useChartStore from "../../../../../Zustand/chartStore";

export const ActivityChart = (props: Props) => {

  const localizationService = useLocalization();
  const {localeId} = React.useContext<any>(AppContext);
  const currentLanguage = getCurrentLanguage(localeId);
  const isAutoRange = useChartStore(state => state.isAutoRange);
  const aggregator = useChartStore(state => state.aggregator);
  const isBarChartOn = useChartStore(state => state.isBarChartOn);
  const setVisibleRangeChartStore = useChartStore(state => state.setVisibleRange);
  const setVisibleSeriesChartStore = useChartStore(state => state.setVisibleSeriesActivity);
  
  // Fetch the initial state, but we don't need to re-render when it changes as the chart will update itself
  const visibleSeriesRef = useRef(useChartStore.getState().visibleSeriesActivity);

  const setVisibleRange = (range:  {min: number | undefined; max: number | undefined; }) => {
    setVisibleRangeChartStore(range.min, range.max);
  };

  const setVisibleSeries = (id: string, isVisible: boolean) => {
    setVisibleSeriesChartStore(id, isVisible);
  };

  var chartOptions : ChartOptions = {isAutoRange: isAutoRange, isBarChartOn: isBarChartOn, setVisibleRange: setVisibleRange, visibleRange: props.chartData.visibleRange, setVisibleSeriesActivity: setVisibleSeries, visibleSeriesActivity: visibleSeriesRef.current, aggregator: aggregator};
  const [activityChartAPI] = React.useState(getChartAPI(props, chartOptions, localizationService, currentLanguage));

  useEffect(() => {
    activityChartAPI.setAutoRange(isAutoRange);
  }, [activityChartAPI, isAutoRange]);

  useEffect(() => {
    activityChartAPI.setChartType(isBarChartOn);
  }, [activityChartAPI, isBarChartOn]);

  return (
    <div className="flex">
      <SciChartReact
        key={"activityChart"}
        onInit={activityChartAPI.configAfterInit}
        initChart={activityChartAPI.initChart}
        style={{aspectRatio: 2, width:"100%", height: "450px"}}
          //  customize size of the inner chart wrapper
          innerContainerProps={{ style: { height: "85%" } }}
      >
        <SciChartNestedOverview style={{ height: "14%" }} options={overviewOptions}/>
      </SciChartReact>
    </div>
  );
}
